export const generateEntitiesList = (entities = [], previousEntities = []) => {
  /**
   * Generates tree list based on previous list
   * to keep view tree properties
   */
  let newEntities = [...entities];
  if (previousEntities && previousEntities.length) {
    newEntities = newEntities.map(entity => {
      const previousEntity = previousEntities.find(previousEntity => entity.id === previousEntity.id);
      if (previousEntity) {
        return {
          ...previousEntity,
          ...entity,
        };
      } else {
        return {
          ...entity,
          expanded: false,
        };
      }
    });
  }

  return newEntities;
};

const expandEntity = (entities, entityId) =>
  entities.map(entity => ({
    ...entity,
    expanded: entity.id === entityId ? true : entity.expanded,
  }));

const findEntity = (entities, entityId) => entities.find(entity => entity.id === entityId);

export const generateInitEntities = (entities = [], ownerId = null) => {
  // target: expand entities to the entity which owns the banner-set, if any
  try {
    if (!ownerId) return entities;
    let closestEntity = findEntity(entities, ownerId);
    if (!closestEntity) return entities; // the owner of the bannerset is not the entity or one of its child => the bannerset should be read only
    // throw new Error('there should be an entity owner of the bannerset, it is strange, please inquiry');
    if (!closestEntity.parent) return entities; // no need to expand
    let isParent = true;
    let newEntities = entities;
    while (isParent) {
      let parentEntityId = closestEntity.parent;
      const parentEntity = findEntity(entities, parentEntityId);
      if (!parentEntity) break;
      newEntities = expandEntity(newEntities, parentEntityId);
      closestEntity = parentEntity;
      if (!parentEntity.parent) isParent = false;
    }
    return newEntities;
  } catch (e) {
    console.error(e);
    return entities;
  }
};
