import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { setElementOpacity } from '../../banner/bannerActionsCreators';
import { getSelectedElementOpacity } from '../../banner/bannerSelectors';
import { round2DigitsHelper, opacityInputProps } from '../constants';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementOpacity extends React.Component {
  onOpacityChange = ({ value, id }) => this.props.setElementOpacity({ opacity: value, id }); //tracked

  render() {
    const { id, opacity, intl } = this.props;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        inputProps={opacityInputProps}
        label={intl.formatMessage({ id: 'properties.opacity' })}
        value={opacity}
        helper={round2DigitsHelper}
        fullWidth
        onChangeMethod={this.onOpacityChange}
      />
    );
  }
}
/* <FormControl fullWidth>
  <Slider
    classes={{ root: classes.slider }}
    value={element.opacity * 100}
    onChange={(e, value) => setElementOpacity({ opacity: Math.round(value) / 100 })}
  />
</FormControl> */

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  opacity: makeSelectorInstance(getSelectedElementOpacity)(state),
});

const dispatchToProps = {
  setElementOpacity,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
)(ElementOpacity);
