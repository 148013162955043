import React from 'react';
import TextFieldDoped from '../../components/TextFieldDoped';
import { connect } from 'react-redux';
import { getSelectedElementDropShadowOffsetY } from '../../banner/bannerSelectors';
import { updateElementShadow } from '../../banner/bannerActionsCreators';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { pixelEndornment } from '../constants';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

class ElementDropShadowOffsetY extends React.Component {
  onShadowOffsetYChange = ({ value: offsetY, id }) => this.props.updateElementShadow({ offsetY, id }); //tracked

  render() {
    const { id, offsetY, intl } = this.props;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        label={intl.formatMessage({ id: 'properties.shadow.offset-y' })}
        value={offsetY}
        fullWidth
        InputProps={pixelEndornment}
        onChangeMethod={this.onShadowOffsetYChange}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  offsetY: makeSelectorInstance(getSelectedElementDropShadowOffsetY)(state),
});

const dispatchToProps = {
  updateElementShadow,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
)(ElementDropShadowOffsetY);
