import { createSelector } from 'reselect';
import { Set, List } from 'immutable';
import {
  getSelectedBannerIndex,
  getBannerSetProperties,
  getUserEntityId,
  getBanners,
  getBannerSetWorkspacePath,
  isUserAuthenticated,
  needToSaveState,
  getBannerPresent,
} from '../shared-selectors/sharedSelectors';
import {
  getBannerUniqueIdentification,
  getSlideIndex,
  getElementsBySlide,
  getSlideAtIndex,
  getBannerDuration,
} from '../banner/bannerSelectors';
import { getBannerSize } from '../banner/bannerSelectorsShared';
import {
  getEntities,
  isNoEntity,
  isManyEntity,
  getToggledEntitiesPermission,
  moveOrSaveAsDialogIsOpenForSaveAs,
  getSelectedEntityIdForSave,
  moveOrSaveAsDialogIsOpenForShare,
} from '../entities/entitiesSelectors';
import { checkBannerIsDissociated, checkBannerIsDissociatedByDefault } from './bannersUtils';
/* BANNERS STATE SELECTORS */

export const getBannerSetUid = createSelector([getBannerSetProperties], bannerSet => bannerSet.uid);

export const getNumberOfBanners = createSelector([getBanners], banners => banners.size);

export const getBannersPresent = createSelector(
  [getBanners],
  banners => (banners && banners.map(banner => banner.present)) || List(),
);

export const getBannersMasterIndex = createSelector([getBannersPresent], banners => {
  let bannerIndex = 0;
  for (let banner of banners) {
    if (banner.isMaster) return bannerIndex;
    bannerIndex++;
  }
  return null;
});

export const getSelectedBanner = createSelector([getBanners, getSelectedBannerIndex], (banners, selectedIndex) =>
  banners.get(selectedIndex),
);

export const checkIfCanExistBannerMaster = createSelector([getNumberOfBanners], size => size > 1);

export const isCurrentBannerMasterBanner = createSelector(
  [getSelectedBannerIndex, getBannersMasterIndex],
  (selectedIndex, masterIndex) => selectedIndex === masterIndex,
);

export const getBannerMaster = createSelector(
  [getBannersPresent, getBannersMasterIndex, getBannerPresent, isCurrentBannerMasterBanner],
  (banners, masterIndex, currentBanner, isCurrentBannerMaster) => {
    if (isCurrentBannerMaster) return currentBanner;
    if (masterIndex !== null) return banners.get(masterIndex);
    return null;
  },
);

export const getBannerSlavesIndexes = createSelector(
  [getBannerMaster, getBannersMasterIndex, getBannersPresent],
  (bannerMaster, bannerMasterIndex, banners) =>
    banners
      .map((banner, ind) => ({ banner, ind }))
      .filter((_, ind) => ind !== bannerMasterIndex)
      .filter(({ banner }) => !checkBannerIsDissociated({ bannerSlave: banner, bannerMaster }))
      .map(({ ind }) => ind),
);

export const getBannerMasterFromReducer = createSelector(
  [checkIfCanExistBannerMaster, getBannersPresent],
  (canExistBannerMaster, banners) => {
    if (!canExistBannerMaster) return null;
    const bannersMasters = banners.filter(banner => banner.isMaster);
    if (bannersMasters.size) return bannersMasters.first();
    return null;
  },
);

/* BANNERS PROPERTIES SELECTORS */
export const getBannersThumbnailIds = createSelector([getBannersPresent], banners =>
  banners.map(banner => banner.thumbnailResourceId),
);

export const getBannersNames = createSelector([getBannersPresent], banners => banners.map(banner => banner.name));

export const getBannersFormats = createSelector([getBannersPresent], banners => banners.map(banner => banner.format));

export const getOtherBannersNames = createSelector([getBannersNames, getSelectedBannerIndex], (names, selectedIndex) =>
  names.filter((_, ind) => ind !== selectedIndex),
);

export const getOtherBannersFormats = createSelector(
  [getBannersFormats, getSelectedBannerIndex],
  (formats, selectedIndex) => formats.filter((_, ind) => ind !== selectedIndex),
);

export const getBannersNextIds = createSelector([getBannersPresent], banners => banners.map(banner => banner.nextId));

export const getAllBannersElements = createSelector([getBannersPresent], banners =>
  banners
    .map(({ elements }) => elements.toList())
    .flatten()
    .toSet(),
);

export const getBannersUsedResources = createSelector([getBannersPresent], banners =>
  banners
    .map(({ elements }) => elements.map(element => element.resourceId))
    .flatten()
    .filter(resourceId => resourceId),
);

/* BANNERS SELECTORS AT INDEX */
export const getBannerAtIndex = index => createSelector([getBanners], banners => banners.get(index));

export const getIndexOfBanner = banner => createSelector([getBanners], banners => banners.indexOf(banner));

export const getBannerPresentAtIndex = index => createSelector([getBannersPresent], banners => banners.get(index));

export const getBannerNameAtIndex = index => createSelector([getBannerPresentAtIndex(index)], banner => banner.name);

export const getBannerThumbnailAtIndex = index =>
  createSelector([getBannerPresentAtIndex(index)], banner => banner.thumbnailResourceId);

export const getSlidesOfBannerAtIndex = index =>
  createSelector([getBannerPresentAtIndex(index)], banner => banner.slides);

export const getSlideElementsCount = (slideIndex, bannerIndex) =>
  createSelector(
    [getSlidesOfBannerAtIndex(bannerIndex)],
    slides => slides.get(slideIndex).elementIds.size, // TESTME (if needed)
  );

export const getSlideForSnapshotOfSlideIndex = (bannerIndex, slideIndex) =>
  createSelector([getBannerAtIndex(bannerIndex)], banner => [
    {
      slideDurationForAnimation: undefined,
      slide: getSlideAtIndex(slideIndex)({ banner }),
      slideIndex,
      animationGlobalDelay: undefined,
    },
  ]);

export const getElementAtBannerIndex = (bannerIndex, elementId) =>
  createSelector([getBannerPresentAtIndex(bannerIndex)], banner => banner.elements.get(elementId));

/* OTHER SELECTORS */
export const getBannerSetFramesToRender = framesPerSecond =>
  createSelector([getBanners], banners => {
    const durations = banners.map(banner => getBannerDuration({ banner })).reduce((a, b) => a + b);
    return durations * framesPerSecond;
  });

export const isCurrentBannerDissociatedByDefault = createSelector(
  [getSelectedBannerIndex, getBannersMasterIndex, getBannersPresent, getBannerPresent],
  (selectedIndex, bannerMasterIndex, bannersPresents, bannerSlave) => {
    if (bannerMasterIndex === null) return true;
    if (bannerMasterIndex === selectedIndex) return false;
    const bannerMaster = bannersPresents.get(bannerMasterIndex);
    return checkBannerIsDissociatedByDefault({ bannerMaster, bannerSlave });
  },
);

export const getPastableBanners = createSelector(
  [getSelectedBannerIndex, getBannersMasterIndex, getBanners],
  (selectedIndex, bannerMasterIndex, banners) => {
    if (banners.size <= 1) return false;
    if (bannerMasterIndex === null)
      return banners
        .map((banner, index) => ({ banner, index }))
        .map(({ banner: { present }, index }) => ({ present, name: present.name, index }));
    const bannerMaster = banners.get(bannerMasterIndex).present;
    const bannersToBePasteOn = banners
      .map((banner, index) => ({ banner, index }))
      .filter(
        ({ banner, index }) =>
          index !== selectedIndex && // no copy on itself
          index !== bannerMasterIndex && // no copy on master banner
          checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster }), // no copy on slave banners
      )
      .map(({ banner: { present }, index }) => ({ present, name: present.name, index }));
    return bannersToBePasteOn;
  },
);

export const selectedBannerCanCopySomethingOnOtherBanners = createSelector(
  [getPastableBanners],
  banners => banners.size > 0,
);

export const isCurrentBannerSlaveBanner = createSelector(
  [getSelectedBannerIndex, getBannersMasterIndex, getBannersPresent, getBannerPresent],
  (selectedIndex, bannerMasterIndex, bannersPresents, bannerSlave) => {
    if (bannerMasterIndex === selectedIndex) return false;
    if (bannerMasterIndex === null) return false;
    if (bannerSlave.dissociateFromMaster) return false;
    const bannerMaster = bannersPresents.get(bannerMasterIndex);
    return !checkBannerIsDissociated({ bannerSlave, bannerMaster });
  },
);

/* BANNERSET PROPERTIES */

export const getBannerSetName = createSelector([getBannerSetProperties], bannerSet => bannerSet.name);
export const getBannerSetPermissions = createSelector([getBannerSetProperties], bannerSet => bannerSet.permissions);
export const getBannerSetLockOwner = createSelector([getBannerSetProperties], bannerSet => bannerSet.lockOwner);
export const getBannerSetExpirationDate = createSelector(
  [getBannerSetProperties],
  bannerSet => bannerSet.expirationDate,
);
export const getBannerExpirationDateAtIndex = index =>
  createSelector([getBannerPresentAtIndex(index)], banner => banner.expirationDate);

export const getBannerSetOwnerEntityId = createSelector([getBannerSetWorkspacePath], workspacePath => {
  if (!workspacePath) return null;
  const workspacePathSplit = workspacePath.split('/');
  return workspacePathSplit[workspacePathSplit.length - 3];
});

export const isBannerSetIsReadOnly = createSelector(
  [getBannerSetPermissions, getUserEntityId, getBannerSetLockOwner],
  (permissions, entityId, lockOwner) => {
    if (lockOwner && lockOwner.length) return true; // if the bannerset is locked, it is readonly
    if (!permissions || !permissions.length) return false; // if we can't know, we allow anything
    if (!entityId) return false; // if we can't know, we allow anything
    return (
      permissions.find(p => p.includes(`READ_${entityId}`)) && !permissions.find(p => p.includes(`WRITE_${entityId}`))
    );
  },
);

export const isBannerSetIsReadWrite = createSelector(
  [getBannerSetPermissions, getUserEntityId, getBannerSetLockOwner],
  (permissions, entityId, lockOwner) => {
    if (lockOwner && lockOwner.length) return false; // if the bannerset is locked, it is readonly
    if (!permissions || !permissions.length) return true; // if we can't know, we allow anything
    if (!entityId) return true; // if we can't know, we allow anything
    return Boolean(permissions.find(p => p.includes(`WRITE_${entityId}`)));
  },
);

export const isBannerSetSavable = createSelector(
  [isBannerSetIsReadWrite, isUserAuthenticated, needToSaveState, getBannerSize, getBannerSetUid],
  (readWrite, authenticated, needToSave, bannerSize, bannerSetUid) =>
    Boolean(readWrite && authenticated && needToSave && bannerSize.size <= bannerSize.maxSize && bannerSetUid),
);

export const isBannerSetMovable = createSelector(
  [isBannerSetIsReadWrite, isUserAuthenticated, isNoEntity, isManyEntity, getBannerSetOwnerEntityId],
  (readWrite, authenticated, noEntity, manyEntities, ownerExists) =>
    readWrite && authenticated && !noEntity && manyEntities && Boolean(ownerExists),
);

export const isBannerSetSharable = createSelector(
  [isBannerSetIsReadWrite, isUserAuthenticated, isNoEntity, isManyEntity, getBannerSetOwnerEntityId],
  (readWrite, authenticated, noEntity, manyEntities, ownerExists) =>
    readWrite && authenticated && !noEntity && manyEntities && Boolean(ownerExists),
);

export const isBannerSetSaveAsable = createSelector(
  [isUserAuthenticated, isNoEntity, getBannerSize],
  (authenticated, noEntity, bannerSize) => authenticated && !noEntity && bannerSize.size <= bannerSize.maxSize,
);

export const isBannerSetSharableWithEntity = entityId =>
  createSelector([getBannerSetPermissions], permissions => !permissions.find(p => p.includes(`WRITE_${entityId}`)));

export const isBannerSetSharedWithEntity = entityId =>
  createSelector(
    [getBannerSetPermissions, isBannerSetSharableWithEntity(entityId), getToggledEntitiesPermission],
    (permissions, sharable, toggledEntitiesPermission) => {
      if (!sharable) return false;
      if (toggledEntitiesPermission[entityId] !== undefined) return toggledEntitiesPermission[entityId];
      return Boolean(permissions.find(p => p.includes(`READ_${entityId}`)));
    },
  );

export const isEntitySetSelected = entityId =>
  createSelector(
    [
      moveOrSaveAsDialogIsOpenForShare,
      getSelectedEntityIdForSave,
      isBannerSetSharedWithEntity(entityId),
      getToggledEntitiesPermission,
    ],
    (isOpenForShare, selectedEntityForSave, sharedWithEntity, toggledEntitiesPermission) => {
      if (isOpenForShare && selectedEntityForSave === undefined) {
        if (toggledEntitiesPermission[entityId] !== undefined) return toggledEntitiesPermission[entityId];
        if (sharedWithEntity) return true;
      }
      if (selectedEntityForSave !== undefined && selectedEntityForSave === entityId) return true;
    },
  );

export const isEntitySelectable = entityId =>
  createSelector(
    [
      moveOrSaveAsDialogIsOpenForSaveAs,
      isBannerSetSharableWithEntity(entityId),
      getBannerSetOwnerEntityId,
      moveOrSaveAsDialogIsOpenForShare,
    ],
    (isOpenForSaveAs, sharable, ownerEntityId, isOpenForShare) => {
      if (isOpenForSaveAs || isOpenForShare) return true;

      return ownerEntityId !== entityId; // cannot move or share with owner
    },
  );

export const isEntityNotSelectable = entityId =>
  createSelector([moveOrSaveAsDialogIsOpenForSaveAs, getBannerSetOwnerEntityId], (isOpenForSaveAs, ownerEntityId) => {
    if (isOpenForSaveAs) return false; // selectable
    return ownerEntityId === entityId; // cannot move or share with owner
  });

export const getBannerSetOwnerName = createSelector([getBannerSetProperties], bannerSet => bannerSet.ownerName); // TESTME

const notEntities = ['default-domain', 'workspaces', 'assets'];

export const getBannerSetBreadcrumb = createSelector(
  [getEntities, getBannerSetWorkspacePath, isBannerSetIsReadOnly, getBannerSetOwnerName],
  (entities, path, bannerSetIsReadOnly, ownerName) => {
    if (bannerSetIsReadOnly) {
      return `${ownerName} > `;
    }
    const crumb =
      !path || !path.length || !entities
        ? undefined
        : path
            .split('/')
            .filter(crumb => !notEntities.includes(crumb))
            .filter((_, ind, arr) => ind !== arr.length - 1) // remove title
            .map(entityId => entities.find(entity => entity.id === entityId))
            .filter(entity => entity)
            .map(entity => entity.name)
            .join(' > ');
    return crumb ? crumb.concat(' > ') : undefined;
  },
);

export const getBannerSetFPS = createSelector([getBannerSetProperties], bannerSet => bannerSet.framesPerSecond); // TESTME

export const getBannerSetThumbnailResourceId = createSelector(
  [getBannerSetProperties],
  bannerSet => bannerSet.thumbnailResourceId,
);

export const getBannerSetCreationDate = createSelector([getBannerSetProperties], bannerSet => bannerSet.creationDate);

/* BANNER UNIQUE IDENTIFICATION */

export const getBannerFromUniqueIdentification = bannerUniqueId =>
  createSelector([getBanners], banners =>
    banners.filter(banner => getBannerUniqueIdentification({ banner }) === bannerUniqueId).first(),
  );

export const getBannersFromUniqueIdentifications = bannerUniqueIds =>
  createSelector([getBanners], banners =>
    Set(
      bannerUniqueIds.map(bannerUniqueId =>
        banners.find(banner => getBannerUniqueIdentification({ banner }) === bannerUniqueId),
      ),
    ).toList(),
  );

export const getSlideElements = (bannerUniqueId, slide) => state => {
  if (!bannerUniqueId) {
    throw new Error(`please give a bannerUniqueId`);
  }
  if (!slide) {
    throw new Error(`please give a slide`);
  }
  const banner = getBannerFromUniqueIdentification(bannerUniqueId)(state);
  if (!banner) {
    throw new Error(`no banner with the banner Unique id ${bannerUniqueId}`);
  }
  const slideIndex = getSlideIndex(slide)({ banner });
  if (slideIndex < 0) {
    throw new Error(`no slide ${slide.id} with these elements within the banner with Unique id ${bannerUniqueId}`);
  }
  const allElements = getElementsBySlide({ banner });
  return allElements.get(slideIndex);
};
