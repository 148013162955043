// Actions

export const MESSAGE_ADD = 'adbuilder/MESSAGE_ADD';
const MESSAGE_CLOSE = 'adbuilder/MESSAGE_CLOSE';
const MESSAGE_NEXT = 'adbuilder/MESSAGE_NEXT';

// Action creators

export const addInfo = (message, params) => ({
  type: MESSAGE_ADD,
  payload: {
    level: 'info',
    message,
    params,
  },
});

export const addWarning = (message, params) => ({
  type: MESSAGE_ADD,
  payload: {
    level: 'warning',
    message,
    params,
  },
});

export const addError = (message, params) => ({
  type: MESSAGE_ADD,
  payload: {
    level: 'error',
    message,
    params,
  },
});

export const closeMessage = () => ({
  type: MESSAGE_CLOSE,
});

export const nextMessage = () => ({
  type: MESSAGE_NEXT,
});

//Selector

export const getMessages = ({ messages }) => messages;

/**
 * The initial reducer state.
 */
export const initialState = {
  message: '',
  params: {},
  level: 'info',
  open: false,
  queue: [],
};

/**
 * The reducer.
 */
export default function(state = initialState, action = null) {
  switch (action.type) {
    case MESSAGE_ADD:
      if (state.open) {
        return {
          ...state,
          queue: [...state.queue, { params: {}, ...action.payload }],
        };
      }
      return {
        ...state,
        open: true,
        ...action.payload,
      };
    case MESSAGE_CLOSE:
      return {
        ...state,
        open: false,
      };
    case MESSAGE_NEXT:
      if (state.queue.length > 0) {
        return {
          ...state,
          ...state.queue[0],
          open: true,
          queue: state.queue.slice(1),
        };
      }
      return state;
    default:
      return state;
  }
}
