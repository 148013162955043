import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    height: '100%',
    width: '100%',
    pointerEvents: 'none',
    borderStyle: 'solid',
    boxSizing: 'border-box', // for rendering in animated GIF
    position: 'relative',
    zIndex: 0,
  },
};

class RectangleElement extends React.Component {
  // we choose to make a stateful component to prevent re-renderings due to style calculations,
  // inspired by https://marmelab.com/blog/2017/02/06/react-is-slow-react-is-fast.html#beware-of-object-literals-in-jsx
  state = { style: {} };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      backgroundColor,
      borderWidth,
      borderColor,
      borderRadius,
      borderRadiusUnit,
      opacity,
      dropShadowEnabled,
      dropShadow,
    } = nextProps;

    const style = {
      backgroundColor,
      borderWidth,
      borderColor,
      borderRadius: `${borderRadius}${borderRadiusUnit}`,
      opacity,
      boxShadow:
        dropShadowEnabled &&
        `${dropShadow.offsetX}px ${dropShadow.offsetY}px ${dropShadow.blurRadius}px ${dropShadow.color}`,
    };

    return { style };
  }

  render() {
    const { classes, className } = this.props;
    const { style } = this.state;

    return <div className={classNames(classes.root, className, 'rectangle')} style={style} />;
  }
}

export default withStyles(styles)(RectangleElement);
