import { locale } from '../i18n';
import { doWithToken } from 'drb-commons-front';

const baseUrl = '/user/imagelibrary';
const searchBaseUrl = baseUrl + '/search123rf';
const importUrl = baseUrl + '/downloadInWorkspace';
const pageSize = 30;

/*------------------------------------*/
/*THUMBNAILS*/
/*------------------------------------*/

export const fetchThumbnailsFrom123RF = (apiUrl, keyword, pageNumber = 0) => {
  if (keyword.length === 0) return new Promise().resolve();

  const uri = `${apiUrl}${searchBaseUrl}?languageLocale=${locale}&pageNumber=${pageNumber}&pageSize=${pageSize}&keywords=${keyword}`;

  return doWithToken(token =>
    fetch(uri, {
      headers: new Headers({
        Accept: 'application/json',
        'X-TENANT.IDENTIFIER': window.adbConfig.api.drb,
        Authorization: `Bearer ${token}`,
      }),
    }),
  )
    .then(res => res.json())
    .then(({ elements, totalPages }) => ({
      images: elements
        ? elements.map(({ imageAddress: id, thumbnailUrl }) => ({
            id,
            thumbnailUrl,
            name: thumbnailUrl.replace(/^.*\/([^/]+)$/, '$1'),
          }))
        : [],
      totalPages,
    }))
    .catch(err => console.error(err));
};

export const importFrom123RFToNuxeo = (apiUrl, imageId) =>
  doWithToken(token =>
    fetch(`${apiUrl}${importUrl}`, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'X-TENANT.IDENTIFIER': process.env.REACT_APP_SSO_REALM,
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: `imageAddress=${imageId}&languageLocale=${locale}`,
    }),
  )
    .then(res => res.json())
    .then(json => json.thumbnailUrl); // "thumbnailUrl" is actually the full-size image
