// Number (in Ko) (NOTEST)
import { createSelector } from 'reselect';
import { getBannerPresent, getUserFonts } from '../shared-selectors/sharedSelectors';
import { getBestSize } from '../resources/resourcesDucks';
import { exportBannerToPlayer, getBannerDataToZip } from '../export/import-export-helpers';
import { allBannerFormats } from '../reference/bannerFormats';

export const getBannerSize = createSelector([getBannerPresent, getUserFonts], (banner, userFonts) => {
  // get images sizes
  const { elements, format } = banner;
  const imagesSize = elements
    .toList()
    .filter(element => element.id && element.id.includes('image'))
    .filter(
      ({ resourceId }, ind, arr) =>
        !arr
          .map(img => img.resourceId)
          .filter((_, i) => i < ind)
          .includes(resourceId),
    ) // unique images
    .map(({ resourceId, optimizedResourceId }) => getBestSize(optimizedResourceId, resourceId) || 0)
    .reduce((a, b) => a + b);

  // get index.html size
  const { jsBanner } = getBannerDataToZip(banner, userFonts);
  const indexHtml = new Blob([exportBannerToPlayer(jsBanner)], { type: 'text/plain' });
  const indexHtmlSize = indexHtml.size / 1000;
  return {
    size: Math.ceil(indexHtmlSize + imagesSize || 0),
    maxSize: allBannerFormats.filter(({ name }) => name === format.name)[0].maxSize,
  };
});
