import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElements } from '../../banner/bannerSelectors';
import { updateElements } from '../../banner/bannerActionsCreators';
import { round2DigitsHelper, opacityInputProps } from '../constants';
import { allElementsHasSameProperty, elementsPropertyAverage } from '../helpers';
import { styles } from '../propertyEditorStyles';
import { getSelectedElementIds } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

const message = { id: 'properties.opacity' };

class ElementsOpacity extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { elements, classes } = nextProps;
    return {
      classesState: {
        InputProps: {
          classes: {
            root: allElementsHasSameProperty(elements, 'opacity') === false && classes.averageInput,
          },
        },
      },
    };
  }

  onOpacityChange = ({ value: opacity }) => {
    const { ids, updateElements } = this.props;
    updateElements({ opacity, ids }); //tracked
  };

  render() {
    const { elements, intl } = this.props;
    const { classesState } = this.state;
    return elementsPropertyAverage(elements, 'opacity', false).map(average => (
      <TextFieldDoped
        key={1}
        inputProps={opacityInputProps}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={classesState.InputProps}
        type="number"
        label={intl.formatMessage(message)}
        value={average}
        helper={round2DigitsHelper}
        fullWidth
        onChangeMethod={this.onOpacityChange} //tracked
      />
    ));
  }
}
/* <Grid item xs={12}>
    <FormControl fullWidth>
      <InputLabel shrink>
        <FormattedMessage id="properties.opacity" />
      </InputLabel>
      {elementsPropertyAverage(elements, 'opacity', false).map(average => (
        <Slider
          key={1}
          classes={{ root: classes.slider }}
          value={average * 100}
          onChange={(e, value) => updateElements({ opacity: Math.round(value) / 100 }, true)} //untrack
          onDragStart={(e, value) => updateElements({ opacity: average }, false)} //track
        />
      ))}
    </FormControl>
  </Grid>
*/

const makeStateToProps = () => state => ({
  elements: makeSelectorInstance(getSelectedElements)(state),
  ids: makeSelectorInstance(getSelectedElementIds)(state),
});

const dispatchToProps = {
  updateElements,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(ElementsOpacity);
