import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { FormControl, InputLabel, RadioGroup, Radio, FormControlLabel, withStyles } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import TextFieldDoped from '../components/TextFieldDoped';
import { defineDistance } from '../reference/transitions';
import { styles } from './propertyEditorStyles';
import { getBannerFormat } from '../shared-selectors/sharedSelectors';
import { getSelectedElement, getSelectedElements } from '../banner/bannerSelectors';
import {
  toggleElementTransitionDistanceDefault,
  setElementTransitionTranslationDistance,
  setElementsTransitionTranslationDistance,
  toggleElementsTransitionDistanceDefault,
} from '../banner/bannerActionsCreators';
import { pixelEndornment } from './constants';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { allElementsHasDeepPropertyChecked, allElementsHasDeepPropertyUnchecked } from './helpers';
import { getSelectedElementId, getSelectedElementIds } from '../temporary-status/temporaryStatusDucks';

class DistanceChooser extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return {
      classesState: {
        radio: { root: classes.radioButton },
      },
    };
  }

  toggleTransitionDistanceType = (e, transitionDistance) => {
    const {
      toggleElementTransitionDistanceDefault,
      toggleElementsTransitionDistanceDefault,
      element,
      id,
      elements,
      transitionName,
      ids,
    } = this.props;
    if (element) {
      toggleElementTransitionDistanceDefault({ id, transitionName, defaultDistance: transitionDistance === 'default' }); // tracked
    }
    if (elements.size > 0) {
      toggleElementsTransitionDistanceDefault({
        ids,
        transitionName,
        defaultDistance: transitionDistance === 'default',
      }); // tracked
    }
  };

  onChangeDistance = ({ value: distance, id }) => {
    const {
      transitionName,
      setElementTransitionTranslationDistance,
      setElementsTransitionTranslationDistance,
      elements,
      element,
    } = this.props;
    if (element) {
      setElementTransitionTranslationDistance({ distance, transitionName, id }); // tracked
    }
    if (elements.size > 0) {
      setElementsTransitionTranslationDistance({ ids: id, distance, transitionName }); // tracked
    }
  };

  render() {
    const {
      classes,
      element,
      elements,
      format: { bannerHeight, bannerWidth },
      transitionName,
      intl,
      ids,
    } = this.props;
    const { classesState } = this.state;
    let distance = null;
    let defaultDistance = null;
    let customDistance = null;
    if (element) {
      const transition = element[transitionName];
      defaultDistance = transition.defaultDistance ? 'default' : 'custom';
      distance = defineDistance(transition, bannerHeight, bannerWidth, true);
      customDistance = element[transitionName].distance;
    }
    if (elements.size) {
      defaultDistance = null;
      if (allElementsHasDeepPropertyChecked(elements, transitionName, 'defaultDistance')) {
        defaultDistance = 'default';
      }
      if (allElementsHasDeepPropertyUnchecked(elements, transitionName, 'defaultDistance')) {
        defaultDistance = 'custom';
      }
      customDistance = 100;
    }

    return (
      <React.Fragment>
        <FormControl fullWidth>
          <InputLabel shrink>
            <FormattedMessage id="properties.transition.translation.distance" />
          </InputLabel>
          <RadioGroup
            name="default distance"
            className={classes.radioGroup}
            value={defaultDistance}
            onChange={this.toggleTransitionDistanceType}
          >
            <FormControlLabel
              value="default"
              //eslint-disable-next-line
              control={<Radio color="primary" fontSize="10" classes={classesState.radio} />}
              label={
                distance
                  ? intl.formatMessage({ id: 'properties.transition.translation.distance.default' }, { distance })
                  : intl.formatMessage({ id: 'properties.transition.translation.distance.default-no-value' })
              }
            />
            <FormControlLabel
              value="custom"
              //eslint-disable-next-line
              control={<Radio color="primary" fontSize="10" classes={classesState.radio} />}
              label={intl.formatMessage({ id: 'properties.transition.translation.distance.custom' })}
            />
          </RadioGroup>
          <TextFieldDoped
            key={element ? element.id : ids}
            id={element ? element.id : ids}
            focus={defaultDistance === 'custom'}
            disabled={defaultDistance !== 'custom'}
            type="number"
            autoFocus
            value={customDistance}
            fullWidth
            InputProps={pixelEndornment}
            onChangeMethod={this.onChangeDistance}
          />
        </FormControl>
      </React.Fragment>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  format: makeSelectorInstance(getBannerFormat)(state),
  element: makeSelectorInstance(getSelectedElement)(state),
  elements: makeSelectorInstance(getSelectedElements)(state),
  ids: makeSelectorInstance(getSelectedElementIds)(state),
});

const dispatchToProps = {
  setElementTransitionTranslationDistance,
  setElementsTransitionTranslationDistance,
  toggleElementTransitionDistanceDefault,
  toggleElementsTransitionDistanceDefault,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(DistanceChooser);
