import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementPositionX, getSelectedElementPositionY } from '../../banner/bannerSelectors';
import { setElementPosition } from '../../banner/bannerActionsCreators';
import { getSelectedElementId, getSelectedElementCroppingStatus } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementPositionY extends React.Component {
  onChangePositionY = ({ value: y, untrack, id }) => {
    const { setElementPosition, x, isCropping } = this.props;
    setElementPosition({ x, y, id, untrack, isCropping }); //tracked
  };

  render() {
    const { id, y, intl } = this.props;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        label={intl.formatMessage({ id: 'properties.position.y' })}
        value={Math.round(y)}
        fullWidth
        onChangeMethod={this.onChangePositionY}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  x: makeSelectorInstance(getSelectedElementPositionX)(state),
  y: makeSelectorInstance(getSelectedElementPositionY)(state),
  isCropping: makeSelectorInstance(getSelectedElementCroppingStatus)(state),
});

const dispatchToProps = {
  setElementPosition,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
)(ElementPositionY);
