import React from 'react';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { ToggleButtonGroup } from '@material-ui/lab';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import {
  getSelectedElementWidth,
  getSelectedElementHeight,
  getSelectedElementOriginalSize,
} from '../../banner/bannerSelectors';
import { setElementBackToOriginalSize } from '../../banner/bannerActionsCreators';
import ToggleButtonWithTooltip from '../../components/ToggleButton/ToggleButtonWithTooltip';
import { styles } from '../propertyEditorStyles';
import { disableBackToOriginalDimensions } from '../helpers';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

const emptyArray = [];

class ElementResetDimensions extends React.Component {
  state = { classesState: {}, disabled: false };

  static getDerivedStateFromProps(nextProps) {
    const { classes, width, height, originalSize } = nextProps;
    return {
      classesState: {
        toggleSmallButton: {
          root: classNames(classes.toggleButton, classes.smallButton),
          disabled: classes.toggleButtonDisabled,
          container: classes.smallButtonContainer,
          selected: classes.toggleButtonsSelected,
        },
        toggleButtonGroup: { root: classes.toggleButtons },
      },
      disabled: disableBackToOriginalDimensions({ width, height, originalSize }),
    };
  }

  onResetDimensionsClick = () => this.props.setElementBackToOriginalSize(this.props.id);

  render() {
    const { intl } = this.props;
    const { classesState, disabled } = this.state;

    return (
      <ToggleButtonGroup
        classes={classesState.toggleButtonGroup}
        selected
        value={emptyArray}
        onChange={this.onResetDimensionsClick}
      >
        <ToggleButtonWithTooltip
          value="resetDimensions"
          noFlex={false}
          tooltip={intl.formatMessage({ id: 'properties.image.backToOriginalDimensions' })}
          classes={classesState.toggleSmallButton}
          disabled={disabled}
        >
          <RestoreIcon />
        </ToggleButtonWithTooltip>
      </ToggleButtonGroup>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  originalSize: makeSelectorInstance(getSelectedElementOriginalSize)(state),
  height: makeSelectorInstance(getSelectedElementHeight)(state),
  width: makeSelectorInstance(getSelectedElementWidth)(state),
});

const dispatchToProps = {
  setElementBackToOriginalSize,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(ElementResetDimensions);
