import React from 'react';
import { connect } from 'react-redux';
import ElementRoot from './ElementRoot';
import Crop from './Crop';
import RectangleElement from './RectangleElement';
import ImageElement from './ImageElement';
import TextElement from './TextElement';
import ButtonElement from './ButtonElement';
import { isElementSelected } from '../banner/bannerSelectors';
import { setImagepicWhileCroppingPropertiesPosition } from '../banner/bannerActionsCreators';
import { getBgProperties } from './interactions-helpers';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getSelectedElementStatus } from '../temporary-status/temporaryStatusDucks';

class Element extends React.Component {
  state = {
    selectedWhileCropping: 'mask', //'mask' or 'picture'
  };

  componentDidUpdate(prevProps) {
    /* while cropping, there are two elements : the real element, but with no background, and
    the image in a fake other element. when cropping is over, we need to update the real element
    with the background properly positioned*/

    const wasNotCropping = !prevProps.status.isCropping && this.props.status.isCropping;
    const wasCropping = prevProps.status.isCropping && !this.props.status.isCropping;
    const wasSelected = prevProps.selected && !this.props.selected;
    const isSelected = prevProps.selected && this.props.selected;
    if (wasNotCropping && isSelected) {
      // start cropping: reset crop parameters
      this.topLeftCornerElement = {};
      this.topLeftCornerImage = {};
    }
    if (wasCropping && (wasSelected || isSelected)) {
      const { zoom, rotation, x, y, id, setImagepicWhileCroppingPropertiesPosition } = this.props;
      const newBackgroundProperties = getBgProperties({
        elementPosition: {
          x: this.topLeftCornerElement.x / zoom,
          y: this.topLeftCornerElement.y / zoom,
        },
        imagePosition: {
          x: this.topLeftCornerImage.x / zoom,
          y: this.topLeftCornerImage.y / zoom,
        },
        rotation: -Math.abs(rotation),
      });
      setImagepicWhileCroppingPropertiesPosition({
        id,
        x: x + newBackgroundProperties.x,
        y: y + newBackgroundProperties.y,
      });
    }
  }

  saveTopLeftCornerImage = (corner, from) => (this.topLeftCornerImage = corner.getBoundingClientRect());
  saveTopLeftCornerElement = (corner, from) => (this.topLeftCornerElement = corner.getBoundingClientRect());
  setImageSelectedWhileCropping = () => this.setState({ selectedWhileCropping: 'image' });
  setMaskSelectedWhileCropping = () => this.setState({ selectedWhileCropping: 'mask' });

  renderElement = temporaryElementState => {
    const elementProps = { ...this.props, ...temporaryElementState };
    if (this.props.type === 'rectangle') return <RectangleElement {...elementProps} />;
    if (this.props.type === 'text') return <TextElement {...elementProps} />;
    if (this.props.type === 'button') return <ButtonElement {...elementProps} />;
    return null;
  };

  renderCrop = temporaryElementState => {
    const { type, status, ...otherProps } = this.props;
    const elementProps = { ...otherProps, ...temporaryElementState };
    return <ImageElement {...elementProps} />;
  };

  renderImageElement = temporaryElementState => {
    const { type, status, ...otherProps } = this.props;
    const elementProps = { ...otherProps, ...temporaryElementState };
    return <ImageElement {...elementProps} />;
  };

  renderMask = () => {
    return null;
  };

  render() {
    const { props, state } = this;
    const { type, status, selected } = props;
    const { selectedWhileCropping } = state;
    if (type === 'image') {
      if (!selected || !status.isCropping) {
        return <ElementRoot {...props} render={this.renderImageElement} />;
      } else {
        return (
          <React.Fragment>
            {/* Crop element is the image with the white draggable corners */}
            <Crop
              {...props}
              cornersSelectable={selectedWhileCropping === 'image'}
              selected
              saveTopLeftCornerImage={this.saveTopLeftCornerImage}
              onHandleMouseDown={this.setImageSelectedWhileCropping}
              render={this.renderCrop}
            />
            {/* ElementRoot element is the mask with the black draggable corners */}
            <ElementRoot
              {...props}
              cornersSelectable={selectedWhileCropping === 'mask'}
              selected
              lockAspectRatio={false}
              onHandleMouseDown={this.setMaskSelectedWhileCropping}
              saveTopLeftCornerElement={this.saveTopLeftCornerElement}
              render={this.renderMask}
            />
          </React.Fragment>
        );
      }
    } else {
      return <ElementRoot ref={el => (this.element = el)} {...props} render={this.renderElement} />;
    }
  }
}

const makeStateToProps = () => (state, { id, elementForExport }) => ({
  status: makeSelectorInstance(getSelectedElementStatus)(state),
  selected: makeSelectorInstance(isElementSelected(id, elementForExport))(state),
});

const dispatchToProps = {
  setImagepicWhileCroppingPropertiesPosition,
};

export default connect(
  makeStateToProps,
  dispatchToProps,
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...dispatchProps,
    ...stateProps,
  }),
)(Element);
