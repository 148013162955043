import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementBorderWidth } from '../../banner/bannerSelectors';
import { updateElement } from '../../banner/bannerActionsCreators';
import { pixelEndornment, minZeroInputProps } from '../constants';
import { styles } from '../propertyEditorStyles';
import TextFieldDoped from '../../components/TextFieldDoped';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

class ElementBorderWidth extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return { classes: { shrink: classes.labelShrinkXL } };
  }

  onBorderWidthChange = ({ value: borderWidth, id }) => this.props.updateElement({ borderWidth, id }); //tracked

  render() {
    const { id, borderWidth, intl } = this.props;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        label={intl.formatMessage({ id: 'properties.rectangle.border.width' })}
        value={borderWidth}
        fullWidth
        inputProps={minZeroInputProps}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={pixelEndornment}
        InputLabelProps={this.state}
        onChangeMethod={this.onBorderWidthChange}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  borderWidth: makeSelectorInstance(getSelectedElementBorderWidth)(state),
});

const dispatchToProps = {
  updateElement,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(ElementBorderWidth);
