import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementDropShadowOffsetX } from '../../banner/bannerSelectors';
import { updateElementShadow } from '../../banner/bannerActionsCreators';
import { pixelEndornment } from '../constants';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementDropShadowOffsetX extends React.Component {
  onShadowOffsetXChange = ({ value: offsetX, id }) => this.props.updateElementShadow({ offsetX, id }); //tracked

  render() {
    const { id, offsetX, intl } = this.props;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        label={intl.formatMessage({ id: 'properties.shadow.offset-x' })}
        value={offsetX}
        fullWidth
        InputProps={pixelEndornment}
        onChangeMethod={this.onShadowOffsetXChange}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  offsetX: makeSelectorInstance(getSelectedElementDropShadowOffsetX)(state),
});

const dispatchToProps = {
  updateElementShadow,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
)(ElementDropShadowOffsetX);
