import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DimensionsIcon = props => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <g>
      <rect x="24" y="16" width="2" height="2" />
      <rect x="24" y="20" width="2" height="2" />
      <rect x="24" y="24" width="2" height="2" />
      <rect x="20" y="24" width="2" height="2" />
      <rect x="16" y="24" width="2" height="2" />
      <polygon points="9,9 24,9 24,12 29,7.5 24,3 24,6 6,6 6,24 3,24 7.5,29 12,24 9,24  " />
    </g>
  </SvgIcon>
);

export default DimensionsIcon;
