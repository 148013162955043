import fetchNuxeo from './fetchNuxeo';

const fetchNuxeoImageAsBlob = baseUrl =>
  fetchNuxeo({
    baseUrl,
  })
    .then(res => res.blob())
    .catch(err => console.error('fetchNuxeoImageAsBlob', err));

export default fetchNuxeoImageAsBlob;
