import { createSelector } from 'reselect';

export const getEntities = ({ entities }) => {
  if (!entities.collection || !entities.collection.length) return null;
  return entities.collection;
};
export const getDCO = ({ entities }) => {
  if (!entities.dco || !entities.dco.length) return null;
  return entities.dco;
};

export const getUserEntity = ({ entities }) => {
  if (!entities.userEntity || !Object.keys(entities.userEntity).length) return null;
  return entities.userEntity;
};

export const getCustomizationIsEnabled = createSelector(
  [getEntities, getDCO, getUserEntity],
  (entities, dco, userEntity) => Boolean(entities) && Boolean(dco) && Boolean(userEntity),
);

export const isNoEntity = createSelector(getEntities, entities => !entities || !entities.length);

export const isManyEntity = createSelector(getEntities, entities => entities && entities.length > 1);

export const moveOrSaveAsDialogIsOpenForSaveAs = ({ entities }) => entities.for === 'save-as';

export const moveOrSaveAsDialogIsOpenForMove = ({ entities }) => entities.for === 'move';

export const moveOrSaveAsDialogIsOpenForShare = ({ entities }) => entities.for === 'share';

export const getToggledEntitiesPermission = ({ entities }) => entities.toggledEntitiesPermission;

export const isPermissionToggled = createSelector([getToggledEntitiesPermission], perm =>
  Boolean(Object.entries(perm).length),
);

export const moveOrSaveAsDialogIsOpen = ({ entities }) => entities.open;

export const moveOrSaveAsDialogIsOpenFor = ({ entities }) => entities.for;

export const moveOrSaveAsDialogkey = ({ entities }) => entities.key;

export const getSelectedEntityIdForSave = ({ entities }) => entities.selectedEntityForSave;
export const getSelectedEntityIdForCusto = ({ entities }) => entities.selectedEntityForCusto;

export const getCustomTestChoice = ({ entities }) => entities.customTestChoice;

export const getCustomFields = createSelector([getDCO], dco => {
  if (!dco) return null;
  return [
    ...dco.reduce((keys, entity) => {
      for (let key of Object.keys(entity)) {
        keys.add(key);
      }
      return keys;
    }, new Set()),
  ];
});

export const getCustomFieldsAndValuesFromUserEntity = createSelector([getUserEntity], entity => {
  if (!entity) return null;
  const fieldsAndValues = {};
  Object.keys(entity).forEach(key => (fieldsAndValues[key] = entity[key]));
  return fieldsAndValues;
});
