import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, onlyUpdateForKeys } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElement, getSelectedElementTransitionInDelay } from '../../banner/bannerSelectors';
import { setElementTransitionInDelay } from '../../banner/bannerActionsCreators';
import { durationInputProps, secondEndornment, parseFloatHelper } from '../constants';
import { addError } from '../../messages/messagesDucks';
import { verifyElementsTransitionInDelay } from '../helpers';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementTransitionInDelay extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { element } = nextProps;
    return {
      durationAdditionalData: {
        elementSelected: element,
      },
    };
  }

  onTransitionInDelayChange = ({ value: delay, id }) => this.props.setElementTransitionInDelay({ delay, id }); //tracked
  onTransitionInDelayVerify = ({ firstValue: oldTransitionInDelay, additionalData: { elementSelected } }) => {
    const { addError, setElementTransitionInDelay } = this.props;
    verifyElementsTransitionInDelay({
      elements: elementSelected,
      setElementTransitionInDelay,
      addError,
      oldTransitionInDelays: [oldTransitionInDelay],
    });
  };

  render() {
    const { id, transitionInDelay, intl } = this.props;
    const { durationAdditionalData } = this.state;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        label={intl.formatMessage({ id: 'properties.transition.delay' })}
        value={transitionInDelay}
        fullWidth
        inputProps={durationInputProps}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={secondEndornment}
        helper={parseFloatHelper}
        additionalData={durationAdditionalData}
        onChangeMethod={this.onTransitionInDelayChange}
        onVerifyMethod={this.onTransitionInDelayVerify}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  transitionInDelay: makeSelectorInstance(getSelectedElementTransitionInDelay)(state),
  element: makeSelectorInstance(getSelectedElement)(state),
});

const dispatchToProps = {
  addError,
  setElementTransitionInDelay,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  onlyUpdateForKeys(['id', 'transitionInDelay']),
  injectIntl,
)(ElementTransitionInDelay);
