import { put, takeEvery } from 'redux-saga/effects';
import { initAuth } from '../security/securityDucks';
import { addError } from '../messages/messagesDucks';

// Actions

export const CONFIG_LOAD_REQUESTED = 'adbuilder/CONFIG_LOAD_REQUESTED';
export const CONFIG_LOAD_SUCCESS = 'adbuilder/CONFIG_LOAD_SUCCESS';
export const CONFIG_LOAD_SCRIPT_LOADED = 'adbuilder/CONFIG_LOAD_SCRIPT_LOADED';

// Action creators

export const loadConfig = () => ({
  type: CONFIG_LOAD_REQUESTED,
});

export const loadConfigSuccess = config => ({
  type: CONFIG_LOAD_SUCCESS,
  payload: config,
});

// Selectors

export const getConfig = ({ config }) => config;

// Sagas

export function* loadConfigSaga() {
  try {
    if (window.aragoConfig) {
      yield put(loadConfigSuccess(window.aragoConfig));
    }
  } catch (e) {
    console.error('Error loading config', e);
    const {
      sso: { url },
    } = window.aragoConfig;
    if (url) {
      addError('loading-error');
    } else {
      // Ignore the error, and go with the stored config
      return;
    }
  }
  yield put(initAuth());
}

export const saga = function* () {
  yield takeEvery(CONFIG_LOAD_REQUESTED, loadConfigSaga);
};

// Reducer

export const initialState = {
  ssoUrl: null,
  ssoRealm: null,
  ssoClientId: null,
  apiUrl: null,
  mediaUrl: null,
  drBannerUrl: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIG_LOAD_SUCCESS: {
      const { sso, api, domain } = action.payload;
      return {
        ...state,
        ssoUrl: sso.url,
        ssoRealm: sso.realm,
        ssoClientId: sso.clientId,
        apiUrl: api.drb,
        mediaUrl: `${api.media}/site/api/v1`,
        drBannerUrl: `http${
          process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? '' : 's'
        }://${domain}`,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
