export const ENTITIES_ENTITIES_REQUESTED = 'adbuilder/ENTITIES_ENTITIES_REQUESTED';
export const ENTITIES_GET_ENTITIES_SUCCESS = 'adbuilder/ENTITIES_GET_ENTITIES_SUCCESS';
export const ENTITIES_GET_ENTITIES_FAILURE = 'adbuilder/ENTITIES_GET_ENTITIES_FAILURE';
export const ENTITIES_DCO_REQUESTED = 'adbuilder/ENTITIES_DCO_REQUESTED';
export const ENTITIES_GET_DCO_SUCCESS = 'adbuilder/ENTITIES_GET_DCO_SUCCESS';
export const ENTITIES_GET_DCO_FAILURE = 'adbuilder/ENTITIES_GET_DCO_FAILURE';
export const ENTITIES_OPEN_MOVE_DIALOG = 'adbuilder/ENTITIES_OPEN_MOVE_DIALOG';
export const ENTITIES_OPEN_SAVE_AS_DIALOG = 'adbuilder/ENTITIES_OPEN_SAVE_AS_DIALOG';
export const ENTITIES_OPEN_SHARE_DIALOG = 'adbuilder/ENTITIES_OPEN_SHARE_DIALOG';
export const ENTITIES_CLOSE_DIALOG = 'adbuilder/ENTITIES_CLOSE_DIALOG';
export const ENTITIES_ENTITY_CLICK = 'adbuilder/ENTITIES_ENTITY_CLICK';
export const ENTITIES_VALIDATE_REQUESTED = 'adbuilder/ENTITIES_VALIDATE_REQUESTED';
export const ENTITIES_TOGGLE_ENTITY_READ_PERMISSION = 'adbuilder/ENTITIES_TOGGLE_ENTITY_READ_PERMISSION';
export const ENTITIES_VALIDATE_PERMISSIONS_REQUESTED = 'adbuilder/ENTITIES_VALIDATE_PERMISSIONS_REQUESTED';
export const ENTITIES_VALIDATE_PERMISSIONS_SUCCESS = 'adbuilder/ENTITIES_VALIDATE_PERMISSIONS_SUCCESS';
export const ENTITIES_VALIDATE_PERMISSIONS_FAILURE = 'adbuilder/ENTITIES_VALIDATE_PERMISSIONS_FAILURE';
export const ENTITIES_USER_ENTITY_REQUESTED = 'adbuilder/ENTITIES_USER_ENTITY_REQUESTED';
export const ENTITIES_USER_ENTITY_SUCCESS = 'adbuilder/ENTITIES_USER_ENTITY_SUCCESS';
export const ENTITIES_USER_ENTITY_FAILURE = 'adbuilder/ENTITIES_USER_ENTITY_FAILURE';
export const ENTITIES_SET_CUSTOM_TEST_CHOICE = 'adbuilder/ENTITIES_SET_CUSTOM_TEST_CHOICE';
export const ENTITIES_SET_CUSTOM_TEST_ENTITY = 'adbuilder/ENTITIES_SET_CUSTOM_TEST_ENTITY';
export const ENTITIES_MOVE_REQUESTED = 'adbuilder/ENTITIES_MOVE_REQUESTED';
