import { applyStyle, addBlinkAnimation, addCustomization } from '../../util/dom-helpers';
import { computeTextStyle } from '../../../reference/textProperties';

const renderButton = ({
  appearance,
  backgroundColor,
  borderWidth,
  borderColor,
  borderRadius,
  borderRadiusUnit,
  opacity,
  dropShadowEnabled,
  dropShadow,
  text,
  color,
  fontFamily,
  fontSize,
  fontStyle,
  property,
  customizable,
  blinkAnimationEnabled,
  blinkAnimation,
}) => {
  const dom = document.createElement('button');
  dom.className = `button ${appearance}`;

  applyStyle(dom, {
    backgroundColor,
    borderWidth: `${borderWidth}px`,
    borderColor,
    borderRadius: `${borderRadius}${borderRadiusUnit}`,
    opacity,
    ...addBlinkAnimation({ blinkAnimationEnabled, blinkAnimation }),
  });
  if (dropShadowEnabled) {
    dom.style.boxShadow = `${dropShadow.offsetX}px ${dropShadow.offsetY}px ${dropShadow.blurRadius}px ${
      dropShadow.color
    }`;
  }

  const textDom = document.createElement('div');

  textDom.innerHTML = text;
  if (customizable) {
    addCustomization(textDom, property);
    applyStyle(textDom, {
      visibility: 'hidden',
    });
  }

  applyStyle(textDom, computeTextStyle({ color, fontFamily, fontSize, fontStyle }));

  dom.appendChild(textDom);

  return dom;
};

export default renderButton;
