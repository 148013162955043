import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { ToggleButtonGroup } from '@material-ui/lab';
import { withStyles, FormControl, InputLabel } from '@material-ui/core';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementFlipV, getSelectedElementFlipH } from '../../banner/bannerSelectors';
import { setElementHorizontalFlip, setElementVerticalFlip } from '../../banner/bannerActionsCreators';
import ToggleButtonWithTooltip from '../../components/ToggleButton/ToggleButtonWithTooltip';
import { styles } from '../propertyEditorStyles';
import elementIcons from '../../reference/elementIcons';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

class ElementFlip extends React.Component {
  state = { classesState: {} };

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return {
      classesState: {
        labelShrink: { shrink: classes.labelShrink },
        toggleButtonGroup: { root: classes.toggleButtons },
        toggleButtonWithTooltip: {
          root: classes.toggleButton,
          disabled: classes.toggleButtonDisabled,
          selected: classes.toggleButtonsSelected,
        },
      },
    };
  }

  onHorizontalFlipChange = () => {
    const { id, setElementHorizontalFlip, flipH } = this.props;
    setElementHorizontalFlip({ flipH: !flipH, id });
  };
  onVerticalFlipChange = () => {
    const { id, setElementVerticalFlip, flipV } = this.props;
    setElementVerticalFlip({ flipV: !flipV, id });
  };

  render() {
    const { flipH, flipV, intl, classes } = this.props;
    const { classesState } = this.state;
    return (
      <FormControl>
        <InputLabel shrink classes={classesState.labelShrink}>
          <FormattedMessage id="properties.image.flip" />
        </InputLabel>
        <ToggleButtonGroup classes={classesState.toggleButtonGroup} exclusive={false} selected>
          <ToggleButtonWithTooltip
            classes={classesState.toggleButtonWithTooltip}
            value={flipH}
            selected={flipH}
            onClick={this.onHorizontalFlipChange}
            tooltip={intl.formatMessage({ id: 'properties.image.flipH' })}
          >
            {elementIcons.flipH(classes.flipIcon)}
          </ToggleButtonWithTooltip>
          <ToggleButtonWithTooltip
            classes={classesState.toggleButtonWithTooltip}
            value={flipV}
            selected={flipV}
            onClick={this.onVerticalFlipChange}
            tooltip={intl.formatMessage({ id: 'properties.image.flipV' })}
          >
            {elementIcons.flipV(classes.flipIcon)}
          </ToggleButtonWithTooltip>
        </ToggleButtonGroup>
      </FormControl>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  flipV: makeSelectorInstance(getSelectedElementFlipV)(state),
  flipH: makeSelectorInstance(getSelectedElementFlipH)(state),
});

const dispatchToProps = {
  setElementHorizontalFlip,
  setElementVerticalFlip,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(ElementFlip);
