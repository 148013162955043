import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List } from 'immutable';
import {
  Tooltip,
  ButtonBase,
  Button,
  Menu,
  MenuItem,
  Divider,
  Select,
  Typography,
  InputLabel,
} from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import AddIcon from '@material-ui/icons/Add';
import LoopIcon from '@material-ui/icons/Loop';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import PlayCircleIcon from 'mdi-material-ui/PlayCircle';
import StopCircleIcon from 'mdi-material-ui/StopCircle';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ElementRecord } from '../reference/records';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { maxBannerDuration } from '../reference/bannerIABParams';
import { getSelectedBannerIndex, checkIfNoBanners } from '../shared-selectors/sharedSelectors';
import {
  getBannerRepetitions,
  getBannerDurationNoRepetitions,
  getBannerSlides,
  getBannerDuration,
  getBannerNextIds,
} from '../banner/bannerSelectors';
import { getBannerSize } from '../banner/bannerSelectorsShared';
import { addSlideRequested, duplicateSlide, setAnimationRepetition } from '../banner/bannerActionsCreators';
import { duplicateSlideRequested } from '../banners/bannersActionsCreators';
import {
  getBannersNames,
  getNumberOfBanners,
  getPastableBanners,
  selectedBannerCanCopySomethingOnOtherBanners,
  isCurrentBannerSlaveBanner,
} from '../banners/bannersSelectors';
import {
  selectSlide,
  getSelectedSlideIndex,
  isBannerPlayingHTMLAnimation,
  isBannerPlayingGIFAnimation,
  updateBannerAnimationStatus,
} from '../temporary-status/temporaryStatusDucks';
import { toggleSlides, toggleElementList, isSlidesListOpen, isElementListOpen } from '../layout/layoutDucks';
import { toggleMenuOrModalOpen } from '../temporary-text-editor/temporaryTextEditorDucks';
import Slide from './Slide';
import styles from './slidesStyles';
import SlideTransition from './SlideTransition';
import { formatI18nBannerNameId } from '../banner/bannerUtils';

const emptyArray = [];
const numberOfRepetitions = [1, 2, 3, 4, 5];
class Slides extends React.Component {
  state = {
    menuAnchorEl: null,
    menuSlide: null,
    stylesState: {},
    classesState: {},
    perfectScrollbarOptions: {
      suppressScrollX: true,
      handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
      wheelSpeed: 0.8,
      wheelPropagation: false,
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      classes,
      open,
      elementListOpen,
      bannerIsPlayingHTMLAnimation,
      bannerIsPlayingGIFAnimation,
      bannerDurationNoRepetion,
    } = nextProps;

    const durationError = bannerDurationNoRepetion > maxBannerDuration;
    return {
      ...prevState,
      stylesState: {},
      classesState: {
        root: classNames(
          classes.bannerAnimation,
          (bannerIsPlayingHTMLAnimation || bannerIsPlayingGIFAnimation) && classes.playing,
          !open && classes.folded,
        ),
        duration: classNames(classes.duration, durationError && classes.durationError),
        playButtonGroup: { root: classes.toggleButtons },
        playButton: {
          root: classNames(classes.toggleButton, classes.smallButtonContainer, classes.bigButton),
          disabled: classes.toggleButtonDisabled,
          label: classes.bigButtonLabel,
          selected: classes.toggleButtonsSelected,
        },
        repetitionsSelect: {
          select: classes.select,
          icon: classes.select,
        },
        selectItem: { selected: classes.menuSelected, root: classes.menuItem },
        slidesContainer: classNames(
          classes.root,
          (!open || bannerIsPlayingHTMLAnimation || bannerIsPlayingGIFAnimation) && classes.folded,
          (!elementListOpen || bannerIsPlayingHTMLAnimation) && classes.foldedElementList,
        ),
        addTolltip: { tooltip: classes.tooltip, popper: classes.popper },
      },
    };
  }

  onSelectSlide = newIndex => {
    this.props.selectSlide(newIndex);
  };

  onMenuOpen = ({ e, ...menuSlide }) => {
    e.stopPropagation();
    this.props.toggleMenuOrModalOpen(true);
    this.setState({ menuAnchorEl: e.currentTarget, menuSlide });
  };

  onMenuClose = () => {
    this.props.toggleMenuOrModalOpen(false);
    this.setState({ menuAnchorEl: null, menuSlide: null });
  };

  onTogglePlay = () => {
    const { bannerIsPlayingHTMLAnimation, updateBannerAnimationStatus } = this.props;
    bannerIsPlayingHTMLAnimation
      ? updateBannerAnimationStatus({ bannerIsPlayingHTMLAnimation: false, slideIsPlayingAnimation: false })
      : updateBannerAnimationStatus({ bannerIsPlayingHTMLAnimation: true, slideIsPlayingAnimation: true });
  };

  renderSelectValue = times => (
    <Typography color="inherit">
      {this.props.intl.formatMessage({ id: 'app.menu.animationRepetitionTitle' }, { times })}
    </Typography>
  );

  setRepetitions = e => this.props.setAnimationRepetition(e.target.value);

  toggleSlides = () => this.props.toggleSlides(!this.props.open);

  onMenuItemDuplicateHereClick = () => {
    const { currentBannerNextId, duplicateSlide, bannerSelected } = this.props;
    const { menuSlide } = this.state;
    this.onMenuClose();
    duplicateSlide({
      slide: menuSlide.slide,
      elements: menuSlide.elements.filter(el => !el.showOnAllSlides),
      nextId: currentBannerNextId,
      bannerIndex: bannerSelected,
    });
  };

  onMenuItemDuplicateAllBannersClick = () => {
    this.onMenuClose();
    const { duplicateSlideRequested, bannersNames, bannerSelected } = this.props;
    const { menuSlide } = this.state;
    const bannerIndexes = bannersNames.map((_, ind) => ind).filter(ind => ind !== bannerSelected);
    duplicateSlideRequested({
      slide: menuSlide.slide,
      elements: menuSlide.elements.map(el => ElementRecord({ ...el.toObject(), showOnAllSlides: false })()),
      bannerIndexes,
    });
  };

  onMenuItemDuplicateOtherBannerClick = e => {
    this.onMenuClose();
    const { duplicateSlideRequested } = this.props;
    const { menuSlide } = this.state;
    const bannerIndexes = List([parseInt(e.target.dataset.bannerindexes, 10)]);

    duplicateSlideRequested({
      slide: menuSlide.slide,
      elements: menuSlide.elements.map(el => ElementRecord({ ...el.toObject(), showOnAllSlides: false })()),
      bannerIndexes,
    });
  };

  render() {
    const {
      classes,
      addSlideRequested,
      slides,
      selectedSlideIndex,
      open,
      bannerIsPlayingHTMLAnimation,
      repetitions,
      noBanner,
      bannerTotalDuration,
      bannerSize,
      numberOfBanners,
      intl,
      isBannerSlave,
      canCopyOnOtherBanners,
      pastableBanners,
    } = this.props;

    const { menuAnchorEl, classesState, perfectScrollbarOptions } = this.state;

    return (
      <React.Fragment>
        <div className={classesState.root}>
          <div className={classes.subtitle}>
            <FormattedMessage id="app.menu.animationDuration" />:
            <Typography color="inherit" className={classesState.duration}>
              {bannerTotalDuration}s
            </Typography>
          </div>
          <ToggleButtonGroup
            classes={classesState.playButtonGroup}
            selected
            value={emptyArray}
            onChange={this.onTogglePlay}
          >
            <ToggleButton
              value="playAndPauseTransition"
              className={classes.toggleButton}
              classes={classesState.playButton}
            >
              {bannerIsPlayingHTMLAnimation ? (
                <Fragment>
                  <StopCircleIcon />
                  {intl.formatMessage({ id: 'properties.transition.stop' })}
                </Fragment>
              ) : (
                <Fragment>
                  <PlayCircleIcon />
                  {intl.formatMessage({ id: 'properties.transition.play' })}
                </Fragment>
              )}
            </ToggleButton>
          </ToggleButtonGroup>
          <Select
            value={repetitions}
            classes={classesState.repetitionsSelect}
            disableUnderline
            renderValue={this.renderSelectValue}
            onChange={this.setRepetitions}
            disabled={isBannerSlave}
          >
            {numberOfRepetitions.map(times => (
              <MenuItem key={times} value={times} classes={classesState.selectItem}>
                {intl.formatMessage({ id: 'app.menu.animationRepetitionTimes' }, { times })}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classesState.slidesContainer}>
          <div className={classes.handle}>
            <Tooltip title={intl.formatMessage({ id: open ? 'slides.close' : 'slides.open' })} placement="right">
              <ButtonBase onClick={this.toggleSlides}>{open ? <ArrowLeftIcon /> : <ArrowRightIcon />}</ButtonBase>
            </Tooltip>
          </div>
          {/* <ButtonBase
            className={classNames(classes.subtitle, classes.toggleElementList)}
            onClick={() => toggleElementList(!elementListOpen)}
          >
            <FormattedMessage id={elementListOpen ? 'app.elements.close' : 'app.elements.open'} />
            {elementListOpen ? <ArrowLeftIcon /> : <ArrowRightIcon />}
          </ButtonBase> */}
          {!noBanner && (
            <PerfectScrollbar className={classes.scrollContainer} options={perfectScrollbarOptions}>
              {repetitions !== 1 && <LoopIcon className={classes.loopIcon} />}
              {slides.map((slide, idx) => (
                <Fragment key={slide.id}>
                  {(idx > 0 || repetitions !== 1) && (
                    <SlideTransition transition={slide.transition} slide={slide} index={idx} />
                  )}
                  <Slide
                    onMenuOpen={this.onMenuOpen}
                    slide={slide}
                    slideDuration={slide.duration}
                    index={idx}
                    selected={idx === selectedSlideIndex}
                    canDelete={slides.size > 1}
                    selectSlide={this.onSelectSlide}
                  />
                </Fragment>
              ))}
              <Tooltip
                classes={classesState.addTolltip}
                title={intl.formatMessage({ id: 'slides.add' })}
                placement="right"
              >
                <div className={classes.buttonContainer}>
                  <Button variant="text" onClick={addSlideRequested} disabled={isBannerSlave}>
                    <AddIcon />
                  </Button>
                </div>
              </Tooltip>
            </PerfectScrollbar>
          )}
          <div className={classes.bannerSize}>
            <InputLabel shrink>
              {intl.formatMessage({ id: 'properties.banner-size' }, { size: bannerSize.size })}
            </InputLabel>
            <InputLabel shrink>
              {intl.formatMessage({ id: 'properties.banner-maxSize' }, { maxSize: bannerSize.maxSize })}
            </InputLabel>
          </div>
          <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={this.onMenuClose}>
            <MenuItem onClick={this.onMenuItemDuplicateHereClick}>
              <FormattedMessage id="app.slides.duplicate.here" />
            </MenuItem>
            {numberOfBanners > 2 && pastableBanners.size === numberOfBanners && <Divider />}
            {numberOfBanners > 2 &&
              pastableBanners.size === numberOfBanners && (
                <MenuItem onClick={this.onMenuItemDuplicateAllBannersClick}>
                  <FormattedMessage id="app.slides.duplicate.to-all-banners" />
                </MenuItem>
              )}
            {canCopyOnOtherBanners && <Divider />}
            {canCopyOnOtherBanners &&
              pastableBanners.map(banner => (
                <MenuItem
                  key={banner.name}
                  data-bannerindexes={banner.index}
                  onClick={this.onMenuItemDuplicateOtherBannerClick}
                >
                  {intl.formatMessage(
                    { id: 'app.slides.duplicate.to-banner' },
                    { bannerName: formatI18nBannerNameId(intl, banner.name) },
                  )}
                </MenuItem>
              ))}
          </Menu>
        </div>
      </React.Fragment>
    );
  }
}

const makeStateToProps = () => state => ({
  bannersNames: makeSelectorInstance(getBannersNames)(state),
  bannerSelected: makeSelectorInstance(getSelectedBannerIndex)(state),
  slides: makeSelectorInstance(getBannerSlides)(state),
  currentBannerNextId: makeSelectorInstance(getBannerNextIds)(state),
  repetitions: makeSelectorInstance(getBannerRepetitions)(state),
  selectedSlideIndex: makeSelectorInstance(getSelectedSlideIndex)(state),
  open: makeSelectorInstance(isSlidesListOpen)(state),
  elementListOpen: makeSelectorInstance(isElementListOpen)(state),
  bannerIsPlayingHTMLAnimation: makeSelectorInstance(isBannerPlayingHTMLAnimation)(state),
  bannerIsPlayingGIFAnimation: makeSelectorInstance(isBannerPlayingGIFAnimation)(state),
  noBanner: makeSelectorInstance(checkIfNoBanners)(state),
  numberOfBanners: makeSelectorInstance(getNumberOfBanners)(state),
  bannerTotalDuration: makeSelectorInstance(getBannerDuration)(state),
  bannerDurationNoRepetion: makeSelectorInstance(getBannerDurationNoRepetitions)(state),
  bannerSize: makeSelectorInstance(getBannerSize)(state),
  isBannerSlave: makeSelectorInstance(isCurrentBannerSlaveBanner)(state),
  pastableBanners: makeSelectorInstance(getPastableBanners)(state),
  canCopyOnOtherBanners: makeSelectorInstance(selectedBannerCanCopySomethingOnOtherBanners)(state),
});

const dispatchToProps = {
  toggleSlides,
  toggleElementList,
  addSlideRequested,
  duplicateSlide,
  duplicateSlideRequested,
  setAnimationRepetition,
  updateBannerAnimationStatus,
  toggleMenuOrModalOpen,
  selectSlide,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(Slides);
