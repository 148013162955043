import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { ActionCreators } from 'redux-undo';
import { connect } from 'react-redux';
import keyboardShortcuts from '../reference/keyboardShortcuts';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getTemporaryMenuOrModalOpen } from '../shared-selectors/sharedSelectors';
import { toggleMenuOrModalOpen } from '../temporary-text-editor/temporaryTextEditorDucks';

class TextFieldDoped extends React.PureComponent {
  componentDidMount() {
    const { blurTextElement, toggleMenuOrModalOpen, menuOrModalOpen } = this.props;
    blurTextElement && menuOrModalOpen && toggleMenuOrModalOpen(false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.focus && !prevProps.focus) {
      this.textField.focus();
    }
  }

  componentWillUnmount() {
    const {
      value,
      id,
      onVerifyMethod,
      additionalData,
      blurTextElement,
      toggleMenuOrModalOpen,
      menuOrModalOpen,
    } = this.props;
    blurTextElement && menuOrModalOpen && toggleMenuOrModalOpen(false);
    if (this.focused) {
      onVerifyMethod({
        value,
        id,
        additionalData,
        firstValue: this.firstValue,
      });
    }
  }

  onKeyDown = e => {
    const { jump } = this.props;
    e.stopPropagation();
    if (keyboardShortcuts.enter.includes(e.key)) {
      e.preventDefault(); // to prevent entering line break in text element
      e.target.blur();
    }
    if (keyboardShortcuts.cancel.includes(e.key)) {
      this.cancel = true;
      jump(-this.countChanges);
      e.target.blur();
    }
  };

  onKeyUp = e => e.stopPropagation();

  onChange = e => {
    const { onChangeMethod, id, helper, additionalData } = this.props;
    this.countChanges = this.countChanges + 1;
    onChangeMethod({
      value: helper(e.target.value),
      id,
      additionalData,
      untrack: false, // track
    });
  };

  onFocus = () => {
    const { onChangeMethod, value, id, additionalData, blurTextElement, toggleMenuOrModalOpen } = this.props;
    this.firstValue = value;
    this.countChanges = 0;
    this.focused = true;
    blurTextElement && toggleMenuOrModalOpen(true);
    onChangeMethod({
      value,
      id,
      additionalData,
      untrack: false, // track, to be able to rewind to this step when cancel
    });
  };

  onBlur = e => {
    const {
      onVerifyMethod,
      id,
      helper,
      additionalData,
      blurTextElement,
      toggleMenuOrModalOpen,
      menuOrModalOpen,
    } = this.props;
    blurTextElement && menuOrModalOpen && toggleMenuOrModalOpen(false);
    this.focused = false;
    if (this.cancel) {
      this.cancel = false;
    } else {
      onVerifyMethod({
        value: helper(e.target.value),
        id,
        additionalData,
        firstValue: this.firstValue,
      });
    }
  };

  onClick = () => {
    const { blurTextElement, toggleMenuOrModalOpen, onClick } = this.props;
    blurTextElement ? toggleMenuOrModalOpen(true) : onClick();
    this.textField.focus();
  };

  render() {
    const {
      onChangeMethod,
      onVerifyMethod,
      value,
      jump,
      id,
      helper,
      additionalData,
      focus,
      forwardInputRef,
      onClick,
      blurTextElement,
      toggleMenuOrModalOpen,
      menuOrModalOpen,
      ...otherProps
    } = this.props;
    return (
      <TextField
        value={value}
        inputRef={ref => (this.textField = ref)}
        onKeyDown={this.onKeyDown}
        onKeyUp={this.onKeyUp}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onClick={this.onClick}
        {...otherProps}
      />
    );
  }
}

TextFieldDoped.propTypes = {
  onChangeMethod: PropTypes.func,
  onVerifyMethod: PropTypes.func,
  id: PropTypes.string,
  helper: PropTypes.func,
  additionalData: PropTypes.object,
  focus: PropTypes.bool,
  blurTextElement: PropTypes.bool,
};

const makeDispatchToProps = () => state => ({
  menuOrModalOpen: makeSelectorInstance(getTemporaryMenuOrModalOpen)(state),
});

TextFieldDoped.defaultProps = {
  onChangeMethod: () => {
    return;
  },
  onVerifyMethod: () => {
    return;
  },
  onClick: () => {
    return;
  },
  helper: value => parseInt(value, 10) || 0,
  additionalData: {},
  id: '',
  focus: false,
  blurTextElement: true,
};

const dispatchToProps = {
  jump: ActionCreators.jump,
  toggleMenuOrModalOpen,
};

export default connect(
  makeDispatchToProps,
  dispatchToProps,
)(TextFieldDoped);
