import fetchNuxeo, { removePermissionUrl } from './fetchNuxeo';
import { extractPermissions } from '../../banners/bannersUtils';

const fetchNuxeoDeletePermission = async uid => {
  const errorMessage = "fetchNuxeoDeletePermission: can't set permission in nuxeo";
  try {
    return await fetchNuxeo({
      path: removePermissionUrl,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-NXenrichers.document': 'acls', // to know what permissions are given to the banner-set
      },
      body: JSON.stringify({
        input: uid, // banner-set uid
        params: {
          user: 'members',
        },
      }),
      errorMessage,
    })
      .then(res => res.json())
      .then(entry => extractPermissions(entry))
      .catch(e => console.error(errorMessage, e));
  } catch (e) {
    console.error('fetchNuxeoDeletePermission', e);
    throw new Error(errorMessage);
  }
};

export default fetchNuxeoDeletePermission;
