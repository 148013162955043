import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ExpansionPanel from './ExpansionPanel';
import { styles } from './propertyEditorStyles';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getSelectedElementIsCustomizable } from '../banner/bannerSelectors';

import ElementCustomizable from './custom-fields-element/ElementCustomizable';
import ElementCustomFieldSelect from './custom-fields-element/ElementCustomFieldSelect';
import ElementDefaultValue from './custom-fields-element/ElementDefaultValue';
import ElementCurrentValue from './custom-fields-element/ElementCurrentValue';
import ElementTestCustomization from './custom-fields-element/ElementTestCustomization';

// perfect scrollbar options
const perfectScrollbarOptions = {
  suppressScrollX: true,
  handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
  wheelSpeed: 0.8,
  wheelPropagation: true,
};

class CustomFieldsEditorElement extends React.PureComponent {
  render() {
    const { classes, customizable, intl } = this.props;

    return (
      <PerfectScrollbar className={classes.scrollContainer} options={perfectScrollbarOptions}>
        <ExpansionPanel title={intl.formatMessage({ id: 'properties.customization' })}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ElementCustomizable />
            </Grid>
            {customizable && (
              <React.Fragment>
                <Grid item xs={12}>
                  <ElementCustomFieldSelect />
                </Grid>
                <Grid item xs={12}>
                  <ElementCurrentValue />
                </Grid>
                <Grid item xs={12}>
                  <ElementDefaultValue />
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </ExpansionPanel>
        <ExpansionPanel title={intl.formatMessage({ id: 'customization.live-test' })} expanded={customizable}>
          <ElementTestCustomization />
        </ExpansionPanel>
      </PerfectScrollbar>
    );
  }
}

const makeStateToProps = () => state => ({
  customizable: makeSelectorInstance(getSelectedElementIsCustomizable)(state),
});

export default compose(
  connect(makeStateToProps),
  injectIntl,
  withStyles(styles),
)(CustomFieldsEditorElement);
