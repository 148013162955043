export const bannerFormats = [
  {
    name: 'medium_rectangle',
    locale: 'bannerFormat.medium_rectangle',
    width: 300,
    height: 250,
    maxSize: 250,
    responsive: 'responsive',
  },
  {
    name: 'leaderboard',
    locale: 'bannerFormat.leaderboard',
    width: 728,
    height: 90,
    maxSize: 250,
    responsive: 'responsive',
  },
  {
    name: 'half_page',
    locale: 'bannerFormat.half_page',
    width: 300,
    height: 600,
    maxSize: 250,
    responsive: 'responsive',
  },
  {
    name: 'full_page',
    locale: 'bannerFormat.full_page',
    width: 320,
    height: 480,
    maxSize: 250, //unknown
    responsive: 'mobile',
  },
  {
    name: 'mobile',
    locale: 'bannerFormat.mobile',
    width: 300,
    height: 50,
    maxSize: 250, //unknown
    responsive: 'mobile',
  },
  {
    name: 'mobile_leaderboard',
    locale: 'bannerFormat.mobile_leaderboard',
    width: 320,
    height: 50,
    maxSize: 250, //unknown
    responsive: 'mobile',
  },
  {
    name: 'large_mobile',
    locale: 'bannerFormat.large_mobile',
    width: 320,
    height: 100,
    maxSize: 150, //unknown
    responsive: 'mobile',
  },
  {
    name: 'wide_skyscrapper',
    locale: 'bannerFormat.wide_skyscrapper',
    width: 160,
    height: 600,
    maxSize: 250,
    responsive: 'responsive',
  },
  {
    name: 'billboard',
    locale: 'bannerFormat.billboard',
    width: 970,
    height: 250,
    maxSize: 250,
    responsive: 'desktop',
  },
  {
    name: 'large_rectangle',
    locale: 'bannerFormat.large_rectangle',
    width: 336,
    height: 280,
    maxSize: 250,
    responsive: 'mobile',
  },
];

export const additionalFormats = [
  {
    name: 'large_leaderboard',
    locale: 'bannerFormat.large_leaderboard',
    width: 950,
    height: 90,
    maxSize: 250, //unknown
    responsive: 'desktop',
  },
  {
    name: 'square',
    locale: 'bannerFormat.square',
    width: 250,
    height: 250,
    maxSize: 250, //unknown
    responsive: 'mobile',
  },
  {
    name: 'square2',
    locale: 'bannerFormat.square',
    width: 200,
    height: 200,
    maxSize: 250, //unknown
    responsive: 'mobile',
  },
  {
    name: 'mobile_safe_area',
    locale: 'bannerFormat.mobile_safe_area',
    width: 320,
    height: 416,
    maxSize: 250, //unknown
    responsive: 'mobile',
  },
  {
    name: 'billboard2',
    locale: 'bannerFormat.billboard',
    width: 750,
    height: 100,
    maxSize: 250,
    responsive: 'desktop',
  },
  {
    name: 'triple_billboard',
    locale: 'bannerFormat.triple_billboard',
    width: 770,
    height: 281,
    maxSize: 250,
    responsive: 'desktop',
  },
  {
    name: 'pushdown',
    locale: 'bannerFormat.pushdown',
    width: 970,
    height: 90,
    maxSize: 250,
    responsive: 'desktop',
  },
  {
    name: 'landscape',
    locale: 'bannerFormat.landscape',
    width: 768,
    height: 90,
    maxSize: 250,
    responsive: 'desktop',
  },
  {
    name: 'in_between',
    locale: 'bannerFormat.in_between',
    width: 800,
    height: 600,
    maxSize: 400, //unknown
    responsive: 'desktop',
  },
  {
    name: 'skyscrapper',
    locale: 'bannerFormat.skyscrapper',
    width: 120,
    height: 600,
    maxSize: 250, //unknown
    responsive: 'responsive',
  },
  {
    name: 'portrait',
    locale: 'bannerFormat.portrait',
    width: 300,
    height: 1050,
    maxSize: 250,
    responsive: 'desktop',
  },
  {
    name: 'footer',
    locale: 'bannerFormat.footer',
    width: 970,
    height: 400,
    maxSize: 250, //unknown
    responsive: 'desktop',
  },
  {
    name: 'sidekick',
    locale: 'bannerFormat.sidekick',
    width: 850,
    height: 550,
    maxSize: 250,
    responsive: 'desktop',
  },
  {
    name: 'wide_leaderboard',
    locale: 'bannerFormat.wide_leaderboard',
    width: 1000,
    height: 200,
    maxSize: 250,
    responsive: 'desktop',
  },
  {
    name: 'wideboard',
    locale: 'bannerFormat.wideboard',
    width: 994,
    height: 250,
    maxSize: 250,
    responsive: 'desktop',
  },
  {
    name: 'premium_insterstitial',
    locale: 'bannerFormat.premium_interstitial',
    width: 320,
    height: 568,
    maxSize: 250,
    responsive: 'mobile',
  },
  {
    name: 'skin',
    locale: 'bannerFormat.skin',
    width: 1800,
    height: 1000,
    maxSize: 500,
    responsive: 'desktop',
  },
];

export const allBannerFormats = [...bannerFormats, ...additionalFormats];
