import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withStyles, FormControl, InputLabel, Grid } from '@material-ui/core';
import { styles } from '../propertyEditorStyles';
import classNames from 'classnames';
import DirectionChooser from '../DirectionChooser';
import DistanceChooser from '../DistanceChooser';

class ElementTransitionInTranslationParameters extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return {
      classesState: {
        directionChooserRoot: { selected: classes.directionChooserRoot },
        directionChooserButton: {
          root: classNames(classes.toggleButton, classes.smallButton, classes.smallButtonContainer),
        },
        distanceChooser: { item: classes.distanceChooser },
        labelShrink: { shrink: classes.labelShrink },
      },
    };
  }

  render() {
    const { transitionTabIndex } = this.props;
    const { classesState } = this.state;
    return (
      <React.Fragment>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel shrink classes={classesState.labelShrink}>
              <FormattedMessage id="properties.transition.translation.direction" />
            </InputLabel>
            {transitionTabIndex === 0 && (
              <DirectionChooser
                transition="transitionIn"
                classesRoot={classesState.directionChooserRoot}
                classesButton={classesState.directionChooserButton}
              />
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} classes={classesState.distanceChooser}>
          <DistanceChooser transitionName="transitionIn" />
        </Grid>
      </React.Fragment>
    );
  }
}

export default compose(withStyles(styles))(ElementTransitionInTranslationParameters);
