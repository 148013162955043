import fetchNuxeo, { logoutNuxeo } from './fetchNuxeo';

const fetchNuxeoLogout = () =>
  fetchNuxeo({
    baseUrl: window.aragoConfig.api.media,
    path: logoutNuxeo,
    errorMessage: "can't log out from nuxeo",
  });

export default fetchNuxeoLogout;
