import { createSelector } from 'reselect';

const parseJwt = token => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};

/*
  Problem to solve here : we can't create a selector in a ducks A, which should be used in a ducks B, when ducks A already import some stuff from ducks B.
  In that case, the selector needs to be in a neutral place, from where it can be imported from anywhere else.
*/

export const getBannerPresent = state => state.banner.present;
export const getPastBanner = state => state.banner.past[state.banner.past.length - 1];
export const getFutureBanner = state => state.banner.future[state.banner.future.length - 1];

export const getBannerFormat = createSelector([getBannerPresent], banner => ({
  bannerWidth: banner.format.width,
  bannerHeight: banner.format.height,
  name: banner.format.name,
}));

export const getUserFonts = ({ fonts: { fonts } = {} }) => fonts; // see fontSelectors.test.js
export const getUsedFontsFamilies = ({ banners: { present } }) => {
  const bannersList = present.banners;
  const fontFamilies = new Set();
  bannersList.forEach(banner => {
    banner.present.elements.forEach(element => {
      if (element.fontFamily) {
        fontFamilies.add(element.fontFamily);
      }
    });
  });
  return Array.from(fontFamilies.values());
};

export const isFontUploadDialogOpen = ({ fonts }) => fonts.uploadDialogOpen;

export const getUserEntityId = ({ security: { token } }) => (token ? parseJwt(token).entity_attribute : '');
export const getUserWorkspacePath = ({ security: { user } }) => (user && user.workspacePath) || null;
export const getUser = ({ security: { user } }) => user;
export const isAppLoaded = state => {
  if (!state.layout) return false;
  return state.layout.adBuilderLoaded;
};

// the authError is here only to show an error message on the welcome dialog.
// it should be on the securityDucks but we don't want this info to be persisted, and securityDucks is persisted
// so we choose to put it in the layoutDucks which is not persisted
export const isAuthError = ({ layout: { authError } }) => authError;

export const isUserAuthenticated = ({ security: { authenticated } }) => authenticated;

export const isUserAuthenticatedAndAppLoaded = createSelector(
  [isUserAuthenticated, isAppLoaded],
  (authenticated, adBuilderLoaded) => adBuilderLoaded && authenticated,
);

export const needToSaveState = ({ needToSaveState }) => needToSaveState.needToSave;

export const getBannersPresentState = createSelector([state => state], state => state.banners.present);
export const getBanners = createSelector([getBannersPresentState], bannersState => bannersState.banners);
export const getBannerSetProperties = createSelector([getBannersPresentState], bannersState => bannersState.bannerSet);
export const getBannerSetWorkspacePath = createSelector([getBannerSetProperties], bannerSet => bannerSet.workspacePath);

export const isNewImageDialogOpen = ({ newImage }) => newImage.open;
export const isExportDialogOpen = ({ exportToImages }) => exportToImages.dialogOpen;
export const isOpenBannerSetFromNuxeoOpen = ({ openBannerSet }) =>
  openBannerSet.dialogOpen && !openBannerSet.importFromComputer;
export const isImportBannerSetFromComputerOpen = ({ openBannerSet }) =>
  openBannerSet.dialogOpen && openBannerSet.importFromComputer;
export const isSaveBannersDialogOpen = ({ saveBanners }) => saveBanners.dialogOpen;

export const getTemporaryMenuOrModalOpen = ({ temporaryTextEditor: { menuOrModalOpen } }) => menuOrModalOpen;

export const getBannerStatus = ({ temporaryStatus }) => temporaryStatus.bannerStatus;
export const getSelectedBannerIndex = ({ temporaryStatus }) => temporaryStatus.selectedBannerIndex;
const getBannerColorPickerIsOpen = createSelector([getBannerStatus], status => status.colorPickerIsOpen);
export const getDisableShortcuts = createSelector(
  // TESTME
  [getBannerColorPickerIsOpen, getTemporaryMenuOrModalOpen],
  (colorPickerOpen, menuOrModalOpen) => menuOrModalOpen || colorPickerOpen,
);

export const checkIfNoBanners = createSelector(
  [getBanners, isAppLoaded],
  (banners, appLoaded) => !banners || banners.isEmpty() || !appLoaded,
);
export const isColorPickerOpen = createSelector([getBannerStatus], status => status.colorPickerIsOpen);

export const getBannerDisableDropZone = createSelector(
  [
    getBannerStatus,
    isNewImageDialogOpen,
    isFontUploadDialogOpen,
    isOpenBannerSetFromNuxeoOpen,
    isImportBannerSetFromComputerOpen,
    isSaveBannersDialogOpen,
    isExportDialogOpen,
  ],
  (
    { isCropping, isDragging, isResizing, isRotating },
    newImageOpen,
    fontUploadDialogOpen,
    openBannerFromNuxeoDialogOpen,
    openBannerFromComputerDialogOpen,
    saveBannersDialogOpen,
    exportSlidesToImagesDialogOpen,
  ) => {
    return (
      newImageOpen ||
      fontUploadDialogOpen ||
      openBannerFromNuxeoDialogOpen ||
      openBannerFromComputerDialogOpen ||
      saveBannersDialogOpen ||
      exportSlidesToImagesDialogOpen ||
      isCropping ||
      isDragging ||
      isResizing ||
      isRotating
    );
  },
);
