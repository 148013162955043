import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { setElementShowOnAllSlides } from '../../banner/bannerActionsCreators';
import { getSelectedElementShowOnAllSlides } from '../../banner/bannerSelectors';
import { isCurrentBannerSlaveBanner } from '../../banners/bannersSelectors';
import { getSelectedElementId, getSelectedSlideIndex } from '../../temporary-status/temporaryStatusDucks';
import CheckboxDoped from '../../components/CheckboxDoped';

class ElementShowOnAllSlides extends React.Component {
  render() {
    const { setElementShowOnAllSlides, showOnAllSlides, id, isBannerSlave, slideIndex } = this.props;
    return (
      <CheckboxDoped
        toggleFunction={setElementShowOnAllSlides}
        checked={showOnAllSlides}
        disabled={isBannerSlave}
        intlId="properties.show-on-all-slides"
        id={id}
        slideIndex={slideIndex}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  showOnAllSlides: makeSelectorInstance(getSelectedElementShowOnAllSlides)(state),
  id: makeSelectorInstance(getSelectedElementId)(state),
  slideIndex: makeSelectorInstance(getSelectedSlideIndex)(state),
  isBannerSlave: makeSelectorInstance(isCurrentBannerSlaveBanner)(state),
});

const dispatchToProps = {
  setElementShowOnAllSlides,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
)(ElementShowOnAllSlides);
