import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, onlyUpdateForKeys } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { InputAdornment, Tooltip, IconButton, ButtonBase } from '@material-ui/core';
import PlayCircleIcon from 'mdi-material-ui/PlayCircle';
import StopCircleIcon from 'mdi-material-ui/StopCircle';
import DeleteIcon from 'mdi-material-ui/Delete';
import CircleIcon from 'mdi-material-ui/Circle';
import TextFieldDoped from '../components/TextFieldDoped';

import elementIcons from '../reference/elementIcons';
import keyboardShortcuts from '../reference/keyboardShortcuts';
import makeSelectorInstance from '../reference/makeSelectorInstance';

import { getSelectedBannerIndex, getBannerFormat } from '../shared-selectors/sharedSelectors';
import { getBannerSlides, getBannerBgColor, getElementsOfSlide } from '../banner/bannerSelectors';
import { setSlideDuration, openDeleteSlideDialog, setSlideDurationRequested } from '../banner/bannerActionsCreators';
import { isCurrentBannerSlaveBanner } from '../banners/bannersSelectors';
import { styles, slideHeight, slideWidth } from './slideStyles';

import { isRenderingCurrentSceneForSnapshot } from '../export/exportSelectors';
import { durationInputProps, parseFloatHelper } from '../property-editor/constants';
import {
  getSlideIsPlayingAnimation,
  isBannerPlayingHTMLAnimation,
  updateBannerAnimationStatus,
} from '../temporary-status/temporaryStatusDucks';
import ScenePreview from '../scene/ScenePreview';

class CustomInputAdornment extends React.Component {
  state = {};
  shouldComponentUpdate() {
    return false;
  }

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return { positionEnd: classes.inputAdornment };
  }
  render() {
    return (
      <InputAdornment classes={this.state} position="end">
        s
      </InputAdornment>
    );
  }
}

const StyledCustomInputAdornment = withStyles(styles)(CustomInputAdornment);
const styledCustomInputAdornment = <StyledCustomInputAdornment />;

class Slide extends React.Component {
  state = {
    zoom: undefined,
    previewStyle: undefined,
    InputPropsClassesAndEndornment: {
      classes: {
        root: this.props.classes.duration,
        input: this.props.classes.durationInput,
        underline: this.props.classes.durationUnderline,
        focused: this.props.classes.durationFocused,
      },
      endAdornment: styledCustomInputAdornment,
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { bannerColor, bannerWidth, bannerHeight } = nextProps;
    const maxHeight = slideHeight - 30; // padding 15px
    const maxWidth = slideWidth - 30; // padding 15px
    let previewHeight = maxHeight;
    let previewWidth = (bannerWidth * maxHeight) / bannerHeight;
    if (previewWidth > maxWidth) {
      previewWidth = maxWidth;
      previewHeight = (bannerHeight * maxWidth) / bannerWidth;
    }

    const zoom = previewWidth / bannerWidth;

    const previewStyle = {
      backgroundColor: bannerColor,
      width: previewWidth,
      height: previewHeight,
    };

    return {
      ...prevState,
      zoom,
      previewStyle,
    };
  }

  handleKeyDown = e => {
    e.stopPropagation();
    if (keyboardShortcuts.enter.includes(e.key)) {
      e.target.blur();
    }
    if (keyboardShortcuts.cancel.includes(e.key)) {
      this.setState({ slideDuration: parseFloat(this.props.slide.duration) });
      e.target.blur();
    }
  };

  onToggleSlideTransition = () => {
    const { selected, slideIsPlayingAnimation, selectSlide, updateBannerAnimationStatus, index } = this.props;
    !selected && !slideIsPlayingAnimation && selectSlide(index);
    slideIsPlayingAnimation
      ? updateBannerAnimationStatus({ slideIsPlayingAnimation: false })
      : updateBannerAnimationStatus({ slideIsPlayingAnimation: true });
  };

  onSelectSlide = () => {
    const { slideIsPlayingAnimation, selectSlide, index } = this.props;
    !slideIsPlayingAnimation && selectSlide(index);
  };

  onChangeInputDuration = e => this.setState({ slideDuration: parseFloat(e.target.value) || 0 });

  onMenuOpenRequested = e => {
    e.stopPropagation();
    e.persist();
    const { onMenuOpen, slide, elements } = this.props;
    onMenuOpen({ e, slide, elements });
  };

  onDeleteClick = () => this.props.openDeleteSlideDialog(this.props.index);

  onDurationChange = ({ value: duration }) => {
    const { index, setSlideDuration } = this.props;
    setSlideDuration({ index, duration, untrack: true });
  };

  onDurationVerify = ({ value: duration, firstValue: oldDuration }) => {
    const { index, setSlideDurationRequested, elements } = this.props;
    setSlideDurationRequested({ index, duration, oldDuration, elements });
  };

  render() {
    const {
      classes,
      slide,
      index,
      selected,
      bannerIsPlayingHTMLAnimation,
      canDelete,
      intl,
      isBannerSlave,
      slideIsPlayingAnimation,
    } = this.props;

    const { InputPropsClassesAndEndornment, previewStyle, zoom } = this.state;
    const number = intl.formatNumber(index + 1, { minimumIntegerDigits: 2 });
    return (
      <div className={classNames(classes.root, selected && classes.selected)}>
        <div className={classes.previewContainer}>
          <div className={classes.preview} style={previewStyle}>
            <ScenePreview slide={slide} zoom={zoom} />
          </div>
        </div>
        {selected ? (
          <Tooltip title={intl.formatMessage({ id: 'slide.elementTransitionsPlay' })} placement="right">
            <div>
              <ButtonBase
                onClick={this.onToggleSlideTransition}
                disabled={slide.elementIds.isEmpty() || slide.duration === 0 || bannerIsPlayingHTMLAnimation}
                className={classes.previewButton}
              >
                <React.Fragment>
                  <CircleIcon htmlColor="#FFF" />
                  {selected && slideIsPlayingAnimation && <StopCircleIcon className={classes.playButtonIcon} />}
                  {!(selected && slideIsPlayingAnimation) && <PlayCircleIcon className={classes.playButtonIcon} />}
                </React.Fragment>
              </ButtonBase>
            </div>
          </Tooltip>
        ) : (
          <Tooltip title={intl.formatMessage({ id: 'slide.open' }, { number })} placement="right">
            <div>
              <ButtonBase className={classes.previewButton} disabled={selected} onClick={this.onSelectSlide} />
            </div>
          </Tooltip>
        )}
        <div className={classes.footer}>
          <div className={classes.number}>{number}</div>
          <TextFieldDoped
            key={slide.id}
            id={slide.id}
            type="number"
            label={null}
            value={slide.duration}
            inputProps={durationInputProps}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={InputPropsClassesAndEndornment}
            onChangeMethod={this.onDurationChange}
            onVerifyMethod={this.onDurationVerify}
            helper={parseFloatHelper}
            disabled={isBannerSlave}
          />
        </div>
        {canDelete && !isBannerSlave && (
          <Tooltip title={intl.formatMessage({ id: 'slide.delete' }, { number })} placement="right">
            <div>
              <IconButton className={classes.deleteButton} onClick={this.onDeleteClick}>
                <DeleteIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
        {!isBannerSlave && (
          <Tooltip title={intl.formatMessage({ id: 'app.slides.duplicate' }, { number })}>
            <div>
              <IconButton className={classes.duplicateButton} onClick={this.onMenuOpenRequested}>
                {elementIcons.duplicate}
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
    );
  }
}

Slide.propTypes = {
  slide: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selected: PropTypes.bool,
  canDelete: PropTypes.bool,
};

const makeStateToProps = () => (state, { slide, index }) => ({
  bannerColor: makeSelectorInstance(getBannerBgColor)(state),
  slides: makeSelectorInstance(getBannerSlides)(state),
  bannerIndex: makeSelectorInstance(getSelectedBannerIndex)(state),
  elements: makeSelectorInstance(getElementsOfSlide(slide))(state),
  slideIsPlayingAnimation: makeSelectorInstance(getSlideIsPlayingAnimation)(state),
  bannerFormat: makeSelectorInstance(getBannerFormat)(state),
  bannerIsPlayingHTMLAnimation: makeSelectorInstance(isBannerPlayingHTMLAnimation)(state),
  // to update thumbnail when needed,
  renderingCurrentSceneForSnapshot: makeSelectorInstance(isRenderingCurrentSceneForSnapshot)(state),
  isBannerSlave: makeSelectorInstance(isCurrentBannerSlaveBanner)(state),
});

const dispatchToProps = {
  setSlideDuration,
  setSlideDurationRequested,
  updateBannerAnimationStatus,
  openDeleteSlideDialog,
};

export default compose(
  connect(makeStateToProps, dispatchToProps),
  withProps(({ bannerFormat }) => ({
    bannerWidth: bannerFormat.bannerWidth,
    bannerHeight: bannerFormat.bannerHeight,
  })),
  onlyUpdateForKeys([
    'bannerColor',
    'isBannerSlave',
    'bannerIndex', // because slide index doesn't change when banner index changes, we still need to update
    'slidesPlayingAnimation',
    'bannerIsPlayingHTMLAnimation',
    'renderingCurrentSceneForSnapshot',
    'slideIsPlayingAnimation',
    'bannerWidth',
    'bannerHeight',
    'selected',
    'index',
    'canDelete',
    'slide',
    'slideDuration', // to update the component when the value change and tot remove useless 0s
    'elements',
  ]),
  injectIntl,
  withStyles(styles),
)(Slide);
