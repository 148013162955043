import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { List } from 'immutable';
import { compose } from 'recompose';

import { borderWidth, guideColor } from '../reference/guides';
import { editorOverhead } from '../reference/dimensions';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getBannerFormat } from '../shared-selectors/sharedSelectors';

const styles = {
  root: {
    border: `${borderWidth}px solid ${guideColor}`,
    position: 'absolute',
    zIndex: 1000,
    pointerEvents: 'none',
  },
  equidistanthorizontal: {
    '&:before': {
      content: "''",
      position: 'absolute',
      border: `${borderWidth}px solid ${guideColor}`,
      top: -5,
      left: -borderWidth,
      height: 10,
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      border: `${borderWidth}px solid ${guideColor}`,
      top: -5,
      right: 0,
      height: 10,
    },
  },
  equidistantvertical: {
    '&:before': {
      content: "''",
      position: 'absolute',
      border: `${borderWidth}px solid ${guideColor}`,
      left: -5,
      top: -borderWidth,
      width: 10,
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      border: `${borderWidth}px solid ${guideColor}`,
      left: -5,
      bottom: 0,
      width: 10,
    },
  },
};

const getLeft = (guide, additionalData) => {
  if (guide.magnetism === 'equidistant') return guide.position.left;
  if (guide.orientation === 'horizontal') return guide.position.left;
  return guide.position.left(additionalData);
};

const getTop = (guide, additionalData) => {
  if (guide.magnetism === 'equidistant') return guide.position.top;
  if (guide.orientation === 'vertical') return guide.position.top;
  return guide.position.top(additionalData);
};

const Guide = ({ classes, className, bannerWidth, bannerHeight, guide, additionalData, zoom }) => {
  /* equidistant guides */
  if (List.isList(guide)) {
    return (
      <React.Fragment>
        {guide.map(gui => (
          <div
            key={`${gui.magnetism}${gui.position.top}${gui.position.left}`}
            data-eids={gui.elementIds}
            className={classNames(classes.root, classes[`equidistant${gui.orientation}`], className)}
            style={{
              width: (gui.position.width - 2 * borderWidth || 0) * zoom,
              height: (gui.position.height - 2 * borderWidth || 0) * zoom,
              top: editorOverhead + bannerHeight / 2 + (getTop(gui, additionalData) - bannerHeight / 2) * zoom,
              left: editorOverhead + bannerWidth / 2 + (getLeft(gui, additionalData) - bannerWidth / 2) * zoom,
              // borderColor: gui.magnetism === 'equidistant' ? 'red' : guideColor,
              borderColor: guideColor,
              fontSize: '0.1rem',
            }}
          />
        ))}
      </React.Fragment>
    );
  }
  /* position guides */
  const { orientation, position } = guide;
  const style =
    orientation === 'horizontal'
      ? {
          top: editorOverhead + bannerHeight / 2 + (position - bannerHeight / 2) * zoom,
          left: 0,
          height: 0,
          width: 2 * editorOverhead + bannerWidth,
        }
      : {
          top: 0,
          left: editorOverhead + bannerWidth / 2 + (position - bannerWidth / 2) * zoom,
          height: 2 * editorOverhead + bannerHeight,
          width: 0,
        };
  return <div className={classNames(classes.root, className)} style={style} />;
};

const makeStateToProps = () => {
  const stateToProps = state => {
    const { bannerWidth, bannerHeight } = makeSelectorInstance(getBannerFormat)(state);
    return {
      bannerWidth,
      bannerHeight,
    };
  };
  return stateToProps;
};

export default compose(
  connect(makeStateToProps),
  withStyles(styles),
  React.memo,
)(Guide);
