export const minWidth = 0;
export const minHeight = 1;
export const menuHeight = 60;
export const tabHeight = 25;
export const appBarHeight = 30;
export const elementListWidth = 200;
export const propertyEditorWidth = 280;
export const slidesPanelWidth = 150;
export const editorOverhead = 500;
export const menuButtonWidth = 50;
export const menuButtonHeight = 35;
export const handleWidth = 20;
