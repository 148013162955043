import { List } from 'immutable';
import { ActionCreators } from 'redux-undo';
import { buffers } from 'redux-saga';
import { put, select, take, takeLatest, call, actionChannel } from 'redux-saga/effects';

import { BannerRecord, BannerFormatRecord, CroppingPropertiesRecord, elementFromJS } from '../reference/records';
import { allBannerFormats } from '../reference/bannerFormats';
import {
  bannerSetIdParam,
  getValueFromUrlParameter,
  removeParameterFromUrl,
  customTextKeyParam,
} from '../reference/urlParams';
import { newIntlMessage } from '../i18n/i18nDucks';
import {
  getUserFonts,
  getUserWorkspacePath,
  getSelectedBannerIndex,
  getBannerSetProperties,
  isUserAuthenticated,
  getUser,
  needToSaveState,
  getBanners,
  getBannerPresent,
} from '../shared-selectors/sharedSelectors';
import { addResource } from '../resources/resourcesDucks';
import {
  importBannerSet,
  makeBannersReadyToSaveOrExport,
  importBannerSetFromNuxeo,
  getCustomKeysForDCO,
} from '../export/import-export-helpers';
import { BANNER_SET_THUMBNAIL, SLIDE_ADD_REQUESTED, SLIDE_DELETE_REQUESTED } from '../banner/bannerActions';
import { newBannerName, checkNewElementDuration, calculateImageSize, createElement } from '../banner/bannerUtils';
import {
  getElementsBySlide,
  getBannerUniqueIdentification,
  getBanner,
  getSelectedSlide,
  getBannerNumberOfSlides,
} from '../banner/bannerSelectors';
import {
  setBannerThumbnail,
  addSlide,
  deleteElementRequested,
  deleteSlide,
  triggerUndoRedo,
  addElementRequested,
  deleteElement,
  addNewBanner,
  clearState,
} from '../banner/bannerActionsCreators';
import {
  BANNERS_LOAD,
  BANNERS_ADD_REQUESTED,
  BANNERS_COPY_REQUESTED,
  BANNERS_COPY_FORMAT,
  BANNERS_TAKE_SCREENSHOT_TO_THUMBNAIL_REQUESTED,
  BANNERSET_CLOSE_PREV_ADD_NEW_REQUESTED,
  BANNERSET_FROM_NUXEO_REQUESTED,
  BANNERSET_FROM_NUXEO_SUCCESS,
  BANNERSET_CREATE_NEW_BANNERSET_REQUESTED,
  BANNERSET_LOAD_FROM_URL_PARAMS_REQUESTED,
  BANNERSET_SET_THUMBNAIL,
  BANNERS_CLOSE_REQUESTED,
  BANNERS_SELECT_REQUESTED,
  BANNERS_SET_MASTER_REQUESTED,
  SLIDE_DUPLICATE_REQUESTED,
  ELEMENT_DUPLICATE_REQUESTED,
  BANNERS_UNDO_REQUESTED,
  BANNERS_REDO_REQUESTED,
  BANNERS_SET_DEFAULT_THUMBNAIL,
} from './bannersActions';
import {
  setBannerSetName,
  loadBanner,
  loadBannerSetSuccess,
  undoBanners,
  redoBanners,
  deleteElementInBanner,
  openBannerSetFromNuxeo,
  openBannerSetFromNuxeoSuccess,
  takeScreenshotForThumbnail,
  addDefaultScreenshotToResources,
  setBannerSetThumbnail,
  setBannerSetInfos,
  resetBannerSetInfos,
  updateBanner,
  closeBanner,
  closeAllBanners,
  selectBanner,
  selectedBanner,
  makeBannerMaster,
  duplicatSlideInBanners,
  loadBannerSetFailure,
} from './bannersActionsCreators';
import {
  getBannersNextIds,
  getElementAtBannerIndex,
  getSlidesOfBannerAtIndex,
  getSelectedBanner,
  getBannersThumbnailIds,
  getBannersPresent,
  getBannerPresentAtIndex,
  getSlideForSnapshotOfSlideIndex,
  getBannerSetThumbnailResourceId,
  getBannerThumbnailAtIndex,
  getBannerSetName,
  getBannerSetUid,
  isCurrentBannerMasterBanner,
  getBannersMasterIndex,
  getNumberOfBanners,
  isCurrentBannerSlaveBanner,
  getBannerSlavesIndexes,
  getBannerSetExpirationDate,
} from './bannersSelectors';
import { moveOrSaveAsDialogIsOpenForSaveAs } from '../entities/entitiesSelectors';
import { addFont } from '../fonts/fontsDucks';
import { addError, addInfo } from '../messages/messagesDucks';
import { EXPORT_BANNERSETS_ITEM_RENDERED_FOR_EXPORT } from '../export/exportActions';

import { importingBannerSet } from '../open-banner-set/openBannerSetDucks';
import {
  noNeedToSave,
  SAVE_BANNERS_SAVE_BANNERSET_TO_NUXEO_REQUESTED,
  openSaveBannersDialogRequested,
  SAVE_BANNERS_IS_DONE,
  setDefaultThumbnailsRemaining,
  setDefaultThumbnailsTotal,
} from '../save-banners-dialog/saveBannersDucks';
import {
  selectSlide,
  getSelectedElementId,
  getSelectedElementIds,
  getSelectedSlideIndex,
} from '../temporary-status/temporaryStatusDucks';
import { takeCurrentSceneScreenshot } from '../export/exportDucks';
import {
  getNewBannerSetBannerFormats,
  openFormatSelectorDialog,
} from '../banner-format-selector/bannerFormatSelectorDucks';
import { createJPEGImageFromDOM } from './bannersUtils';
import fetchNuxeo from '../api/fetchNuxeo';
import { domtoimageExecutionContext } from '../export/exportHelpers';

export const expandBuffers = () => buffers.expanding(7);

export function* backupBannerSaga() {
  // get banner
  const bannerIndex = yield select(getSelectedBannerIndex);
  const banner = yield select(getBanner);

  // get selected elements and slides, to save to the banner state when the banner will be reloaded
  const selectedElementId = yield select(getSelectedElementId);
  const selectedElementIds = yield select(getSelectedElementIds);
  const selectedSlideIndex = yield select(getSelectedSlideIndex);
  const isBannerSlave = yield select(isCurrentBannerSlaveBanner);
  const isBannerMaster = yield select(isCurrentBannerMasterBanner);
  // Backup banner
  yield put(
    updateBanner({
      bannerIndex,
      banner,
      selectedElementId,
      selectedElementIds,
      selectedSlideIndex,
      isBannerDissociated: !isBannerMaster && !isBannerSlave,
    }),
  );
}

export function* selectBannerSaga({ payload: newIndex }) {
  try {
    // Backup old banner
    yield call(backupBannerSaga);

    // Load new banner
    const banners = yield select(getBanners);
    const newBanner = banners.get(newIndex);
    yield put(selectedBanner(newIndex));
    yield put(loadBanner(newBanner));
    if (newBanner && !newBanner._latestUnfiltered) yield put(triggerUndoRedo());
  } catch (e) {
    console.error('selectBannerSaga', e);
  }
}

export function* selectBannerThenDuplicateSlideSaga({ payload: { bannerIndexes, slide, elements } }) {
  const nextIds = yield select(getBannersNextIds);
  for (let bannerIndex of bannerIndexes) {
    yield put(duplicatSlideInBanners({ bannerIndex, slide, elements, nextId: nextIds.get(bannerIndex) }));
  }
  yield put(selectBanner(bannerIndexes.get(0))); // load requested banner first
  yield take(BANNERS_LOAD);
  const numberOfSlides = yield select(getBannerNumberOfSlides);
  yield put(selectSlide(numberOfSlides - 1));
}

export function* selectBannerThenDuplicateElementSaga({
  payload: { id, slideIndex, oldSlideIndex, bannerIndex, oldBannerIndex, operation, element, deleteOriginalElement },
}) {
  try {
    const sameBanner = bannerIndex === oldBannerIndex;
    const sameSlide = sameBanner && slideIndex === oldSlideIndex;
    yield call(backupBannerSaga);
    // get the copied data
    const oldSlides = yield select(getSlidesOfBannerAtIndex(oldBannerIndex));
    const oldSlide = oldSlides.get(oldSlideIndex);
    // if operation is cut, element is in the payload
    // if operation is copy, id only is in the payload
    if (!element) {
      element = yield select(getElementAtBannerIndex(oldBannerIndex, id));
    } else {
      element = elementFromJS(element);
    }
    if (operation === 'cut') {
      if (sameSlide) {
        // Cut - paste in the same slide = no-op
        //eslint-disable-next-line no-console
        console.log('No-op cut-paste operation');
        return;
      }
      if (deleteOriginalElement) {
        if (sameBanner) {
          yield put(deleteElement(id));
        } else {
          yield put(deleteElementInBanner(oldBannerIndex, id));
        }
      }
    }

    // load the banner and slide where the new element will be created
    if (bannerIndex !== oldBannerIndex) {
      yield put(selectBanner(bannerIndex)); // load requested banner first
      yield take(BANNERS_LOAD); // wait for the banner to be loaded
    }

    yield put(selectSlide(slideIndex));
    const slide = yield select(getSelectedSlide);
    const type = element.type;

    // update the transition parameters
    const { duration, transitionIn, transitionOut } = yield call(checkNewElementDuration, element, slide, oldSlide);

    // define the new element
    let newElement = element.merge({
      showOnAllSlides: false,
      duration,
      transitionIn,
      transitionOut,
    });

    if (!sameSlide && type === 'image') {
      const banner = yield select(getBannerPresentAtIndex(bannerIndex));

      const { width, height } = calculateImageSize(banner.format.width, banner.format.height, {
        width: element.width,
        height: element.height,
      });
      const dimensions = createElement(banner.format.width, banner.format.height, width, height, duration);

      newElement = newElement.merge({
        ...dimensions,
        picWhileCroppingProperties: CroppingPropertiesRecord({
          ...dimensions,
        }),
      });
    }

    // handle the case if the banner and the slide indexes are the same
    if (sameSlide) {
      // shift
      newElement = newElement.merge({
        x: element.x + 10,
        y: element.y + 10,
      });
      // update text
      if (type === 'text') {
        newElement = newElement.set(
          'text',
          newIntlMessage('properties.text.copy', {
            original: element.text,
          }),
        );
      }
      // update image
      if (type === 'image') {
        newElement = newElement.merge({
          picWhileCroppingProperties: CroppingPropertiesRecord({
            x: element.picWhileCroppingProperties.x + 10,
            y: element.picWhileCroppingProperties.y + 10,
            width: element.picWhileCroppingProperties.width,
            height: element.picWhileCroppingProperties.height,
            rotation: element.picWhileCroppingProperties.rotation,
          }),
        });
      }
    }

    newElement = newElement.toObject();
    delete newElement.id;
    yield put(addElementRequested(newElement));
  } catch (e) {
    console.error('selectBannerThenDuplicateElementSaga', e);
  }
}

export function* closeBannerSaga({ payload: index }) {
  const isMasterBanner = yield select(isCurrentBannerMasterBanner);
  if (isMasterBanner) {
    yield put(makeBannerMaster(null));
  }

  const numberOfBanners = yield select(getNumberOfBanners);
  yield put(closeBanner(index, numberOfBanners));

  // if we just deleted the last banner, reset the bannerset infos
  // else, load selected default next banner
  if (numberOfBanners > 1) {
    const banner = yield select(getSelectedBanner);
    yield put(loadBanner(banner));
  } else {
    yield put(resetBannerSetInfos());
  }
}

export function* addBannerSaga({ payload: { format } }) {
  // Backup old banner
  yield call(backupBannerSaga);
  // Create new banner
  const numberOfBanners = yield select(getNumberOfBanners);
  yield put(
    addNewBanner({
      banner: BannerRecord({
        name: newBannerName(format),
        format: BannerFormatRecord({ ...format }),
      }),
    }),
  );
  yield put(selectedBanner(numberOfBanners));
}

export function* addBannerCopySaga() {
  try {
    // Backup old banner
    yield call(backupBannerSaga);
    yield put(openFormatSelectorDialog({ isForCopyingBanner: true }));

    const {
      payload: { format },
    } = yield take(BANNERS_COPY_FORMAT);

    const oldBanner = yield select(getBanner);
    const oldBannerPresent = yield select(getBannerPresent);
    const bannerCopy = {
      ...oldBanner,
      present: oldBannerPresent
        .set('name', newBannerName(format))
        .set('format', BannerFormatRecord(format))
        .set('thumbnailResourceId', undefined) // to prevent identical thumbnails for ever and ever
        .set('expirationDate', oldBannerPresent.expirationDate),
    };

    // Create and select new banner
    const numberOfBanners = yield select(getNumberOfBanners);
    yield put(addNewBanner({ banner: bannerCopy }));
    yield put(selectedBanner(numberOfBanners));
  } catch (e) {
    console.error('Error copying banner', e);
  }
}

export function* importBannerSetSaga(zipFile) {
  try {
    yield call(removeParameterFromUrl, customTextKeyParam);
    const { banners, resources, bannerSetInfos, fonts } = yield call(importBannerSet, zipFile);
    return { banners, resources, bannerSetInfos, fonts };
  } catch (e) {
    console.error('importBannerSetSaga', e);
    yield put(addError('banner.open.import-error'));
  }
}

export function* openBannerSetSaga({ banners, resources, bannerSetInfos, fonts }) {
  try {
    for (const { id, blob } of resources) {
      yield put(addResource(blob, null, id));
    }
    for (const { id, fontName, blob } of fonts) {
      yield put(addFont(blob, fontName, false, id));
    }

    for (const banner of banners) {
      const numberOfBanners = yield select(getNumberOfBanners);
      yield put(addNewBanner({ banner }));
      yield put(selectedBanner(numberOfBanners));
    }
    yield put(setBannerSetInfos(bannerSetInfos));
    yield put(selectBanner(0));
    yield put(addInfo('banner.open.import-success'));
  } catch (e) {
    console.error('Error opening banner', e);
    yield put(addError('banner.open.import-error'));
  }
}

export function* importBannerSetFromNuxeoSaga({ payload: { nuxeoBannerSet } }) {
  try {
    yield put(importingBannerSet());
    const { banners, resources, bannerSetInfos, fonts } = yield call(importBannerSetFromNuxeo, nuxeoBannerSet);
    yield put(closeAllBanners());
    yield call(openBannerSetSaga, { banners, resources, bannerSetInfos, fonts });
    yield put(noNeedToSave());
    yield put(openBannerSetFromNuxeoSuccess());
  } catch (e) {
    console.error('importBannerSetFromNuxeoSaga', e);
    yield put(addError('banner.open.import-error'));
  }
}

export function* saveBannerSetSaga(action = null, forNuxeo = true) {
  // Backup current banner
  yield call(backupBannerSaga);
  let customName = null;
  let bannersUniqueIds = [];
  if (action) {
    customName = action.payload.name;
    bannersUniqueIds = action.payload.bannersUniqueIds;
  }

  // Get bannerset infos
  const bannerSetRecord = yield select(getBannerSetProperties);
  const bannerSetInfos = bannerSetRecord.toObject();

  // Set bannerset export name if provided
  if (customName) bannerSetInfos.name = customName;

  // Check if need to make thumbnails
  // banners thumbnails
  const thumbnails = yield select(getBannersThumbnailIds);
  const bannerIndexesToCreateThumbnails = thumbnails
    .map((thumb, bannerIndex) => ({ thumb, bannerIndex }))
    .filter(({ thumb }) => thumb === undefined)
    .map(({ bannerIndex }) => bannerIndex)
    .toJS();
  let numberOfThumbnails = bannerIndexesToCreateThumbnails.length;

  // bannerset thumbnail
  const bannerSetThumbnail = yield select(getBannerSetThumbnailResourceId);
  const oneMoreForBannerset = !bannerSetThumbnail;

  // set the progress bar
  yield put(setDefaultThumbnailsTotal(numberOfThumbnails + oneMoreForBannerset));

  //Set banners thumbnails if not yet set
  if (numberOfThumbnails > 0) {
    // target: create thumbnails one after the other.
    // 1. create two action channels, one for the screenshot request...
    const waitForThumbnails = yield actionChannel(BANNERS_SET_DEFAULT_THUMBNAIL, expandBuffers());
    // ... and one for notifying the screenshot has been made
    const waitForThumbnailsCreated = yield actionChannel(BANNER_SET_THUMBNAIL, buffers.none());
    // for all the screenshots to be made, launch the process

    for (const bannerIndex of bannerIndexesToCreateThumbnails) {
      yield put(addDefaultScreenshotToResources({ thumbnailFor: 'banner', bannerIndex, slideIndex: 0 }));
    }
    while (numberOfThumbnails > 0) {
      // one by one, create the screenshot
      const { payload } = yield take(waitForThumbnails);
      yield put(takeScreenshotForThumbnail(payload));
      yield take(waitForThumbnailsCreated);
      yield put(setDefaultThumbnailsRemaining(numberOfThumbnails + oneMoreForBannerset - 1));
      numberOfThumbnails--;
    }
  }

  //Set bannerSet thumbnail if not yet set
  if (oneMoreForBannerset) {
    const waitForThumbnail = yield actionChannel(BANNERS_SET_DEFAULT_THUMBNAIL, expandBuffers());
    const waitForThumbnailCreated = yield actionChannel(BANNERSET_SET_THUMBNAIL, expandBuffers());
    yield put(addDefaultScreenshotToResources({ thumbnailFor: 'bannerSet', bannerIndex: 0, slideIndex: 0 }));
    let bannerSetToBeThumbnailed = true;
    while (bannerSetToBeThumbnailed) {
      const { payload } = yield take(waitForThumbnail);
      yield put(takeScreenshotForThumbnail(payload));
      yield take(waitForThumbnailCreated);
      bannerSetToBeThumbnailed = false;
    }
    const newResourceId = yield select(getBannerSetThumbnailResourceId);
    bannerSetInfos.thumbnailResourceId = newResourceId;
    yield put(setDefaultThumbnailsRemaining(0));
  }

  // Get banners
  const banners = yield select(getBannersPresent);
  let bannersToExport = banners;
  if (bannersUniqueIds.length) {
    bannersToExport = banners.filter(banner => bannersUniqueIds.includes(getBannerUniqueIdentification({ banner })));
  }

  // Get custom keys for DCO
  const customKeysForDCO = yield call(getCustomKeysForDCO, banners);

  // Get user fonts
  const userFonts = yield select(getUserFonts);

  try {
    const authenticated = yield select(isUserAuthenticated);
    if (authenticated && forNuxeo) {
      // if save in Nuxeo, we need to save also the name of the owner in case the banner-set is shared with other users
      const { firstName, lastName } = yield select(getUser);
      const ownerName = `${lastName} ${firstName}`;
      bannerSetInfos.ownerName = ownerName;
      yield put(setBannerSetInfos({ ownerName }));
    }
    const readyToExport = yield call(
      makeBannersReadyToSaveOrExport,
      bannersToExport,
      userFonts,
      bannerSetInfos,
      customKeysForDCO,
      forNuxeo,
    );
    return readyToExport;
  } catch (e) {
    console.error('Error exporting bannerSet', e);
  }
}

export function* closePrevBannersetOpenImportedSaga({ payload: { zipFile } }) {
  try {
    const { banners, resources, bannerSetInfos, fonts } = yield call(importBannerSetSaga, zipFile);
    yield put(closeAllBanners());
    yield call(openBannerSetSaga, { banners, resources, bannerSetInfos, fonts });
  } catch (e) {
    console.error('error saving prev banner and open imported one', e);
  }
}

export function* createNewBannerSetSaga(action) {
  const { name } = action.payload;

  try {
    yield call(removeParameterFromUrl, customTextKeyParam);

    const newBannerSetFormats = yield select(getNewBannerSetBannerFormats);

    yield put(closeAllBanners());
    yield put(setBannerSetName(name));

    for (const formatName of newBannerSetFormats) {
      const format = allBannerFormats.filter(bannerFormat => bannerFormat.name === formatName)[0];
      const numberOfBanners = yield select(getNumberOfBanners);
      yield put(
        addNewBanner({
          banner: BannerRecord({
            name: newBannerName(format),
            format: BannerFormatRecord({ ...format }),
          }),
        }),
      );
      yield put(selectedBanner(numberOfBanners));
    }

    yield put(selectBanner(0));
    yield take(BANNERS_LOAD);
    if (newBannerSetFormats.length > 1) {
      yield put(makeBannerMaster(0));
    }
    yield put(triggerUndoRedo());
  } catch (e) {
    console.error('createNewBannerSetSaga', e);
  }
}

export function* takeScreenshotOfSlideSaga(slideIndex, bannerIndex) {
  try {
    const banner = yield select(getBannerPresentAtIndex(bannerIndex));
    const slidesToExport = yield select(getSlideForSnapshotOfSlideIndex(bannerIndex, slideIndex));
    const bannerToTakeScreenshot = List.of({
      bannerName: banner.name,
      bannerFormat: banner.format.toJS(),
      bannerUniqueId: getBannerUniqueIdentification({ banner }),
      backgroundColor: banner.backgroundColor,
      slidesToExport,
    });
    yield put(takeCurrentSceneScreenshot(bannerToTakeScreenshot));

    const { payload: DOMAndDimensions } = yield take(EXPORT_BANNERSETS_ITEM_RENDERED_FOR_EXPORT);

    return yield call(createJPEGImageFromDOM, DOMAndDimensions);
  } catch (e) {
    console.error('takeScreenshotOfSlideSaga', e);
  }
}

export function* takeScreenshotToThumbnailSaga({ payload: { thumbnailFor, bannerIndex, slideIndex } }) {
  const thumbnailForBannerSet = thumbnailFor === 'bannerSet';
  const thumbnailForBanner = thumbnailFor === 'banner';
  try {
    yield* domtoimageExecutionContext(function* () {
      // Backup current banner
      yield call(backupBannerSaga);
      // thumbnail for the current banner

      const thumbnailBlob = yield call(takeScreenshotOfSlideSaga, slideIndex, bannerIndex);

      if (thumbnailForBannerSet) {
        const thumbnailResourceId = yield select(getBannerSetThumbnailResourceId);

        yield put(addResource(thumbnailBlob, setBannerSetThumbnail(), thumbnailResourceId));
        return;
      }
      const currentIndex = yield select(getSelectedBannerIndex);
      if (thumbnailForBanner) {
        const thumbnailResourceId = yield select(getBannerThumbnailAtIndex(bannerIndex));

        yield put(addResource(thumbnailBlob, setBannerThumbnail({ bannerIndex, currentIndex }), thumbnailResourceId));
      }
      // thumbnail for slave banners
      const fromBannerMaster = yield select(isCurrentBannerMasterBanner);
      if (!fromBannerMaster) return;
      const bannersSlavesIndexes = yield select(getBannerSlavesIndexes);
      for (let bannerSlaveIndex of bannersSlavesIndexes) {
        const slaveThumbnailBlob = yield call(takeScreenshotOfSlideSaga, slideIndex, bannerSlaveIndex);
        const thumbnailResourceId = yield select(getBannerThumbnailAtIndex(bannerSlaveIndex));
        yield put(
          addResource(
            slaveThumbnailBlob,
            setBannerThumbnail({ bannerIndex: bannerSlaveIndex, currentIndex }),
            thumbnailResourceId,
          ),
        );
      }
    });
  } catch (e) {
    console.error('takeScreenshotToThumbnailSaga', e);
    if (thumbnailForBannerSet) yield put(setBannerSetThumbnail());
    if (thumbnailForBanner) yield put(setBannerThumbnail());
  }
}

export function* exportBannerSetToNuxeoSaga({ payload: userWorkspacePath }) {
  try {
    if (!userWorkspacePath) {
      userWorkspacePath = yield select(getUserWorkspacePath); // default save to current user workspace path
    }
    const { fileContent, attachments } = yield call(saveBannerSetSaga);
    const forSaveAs = yield select(moveOrSaveAsDialogIsOpenForSaveAs);
    const uid = yield select(getBannerSetUid);
    const bannerSetName = yield select(getBannerSetName);
    const bannerSetExpirationDate = yield select(getBannerSetExpirationDate);
    const { newUid, update, newPath, permissions, lockOwner } = yield call(fetchNuxeo.saveBannerSet, {
      userWorkspacePath,
      bannerSetId: uid,
      fileContent,
      attachments,
      bannerSetName,
      bannerSetExpirationDate,
      duplicate: forSaveAs,
    });
    if (!update) {
      //Banner set creation: get new id and workpath
      yield put(
        setBannerSetInfos({
          uid: newUid,
          workspacePath: newPath,
          permissions,
          lockOwner,
          expirationDate: bannerSetExpirationDate,
        }),
      );
    }
    yield put(addInfo('nuxeo.bannerSet.success-saving'));
  } catch (e) {
    console.error('exportBannerSetToNuxeoSaga', e);
    yield put(addError('nuxeo.bannerSet.error-saving'));
  }
}

export function* loadBannerSetFromUrlParamsSaga() {
  try {
    // get the param from url
    const idFromUrl = yield call(getValueFromUrlParameter, bannerSetIdParam);
    if (!idFromUrl) {
      yield put(loadBannerSetSuccess());
      return;
    }
    yield call(removeParameterFromUrl, bannerSetIdParam);
    const needToSave = yield select(needToSaveState);
    const uid = yield select(getBannerSetUid);
    if (needToSave && uid && uid.length > 0) {
      yield put(
        openSaveBannersDialogRequested({
          cancelable: false,
          message: 'banner.open.ask-before.call-to-action.from-drbanner',
        }),
      );
      yield take(SAVE_BANNERS_IS_DONE);
    }
    // check if the user is authenticated
    const authenticated = yield select(isUserAuthenticated);
    if (!authenticated) {
      yield put(loadBannerSetSuccess());
      return;
    }
    // load the bannerset
    yield put(clearState());
    yield put(importingBannerSet());
    const nuxeoBannerSet = yield call(fetchNuxeo.bannerSetFromUid, idFromUrl);
    if (nuxeoBannerSet) {
      yield put(openBannerSetFromNuxeo(nuxeoBannerSet));
      yield take(BANNERSET_FROM_NUXEO_SUCCESS);
      yield put(loadBannerSetSuccess());
    } else {
      yield put(addError('banner.open.import-error-uid'));
      yield put(loadBannerSetFailure());
    }
  } catch (e) {
    console.error("can't load bannerset from url params");
    yield put(loadBannerSetFailure());
    yield put(addError('banner.open.import-error-unexpected'));
  }
}

export function* addSlideSaga() {
  // Backup old banner
  yield call(backupBannerSaga);
  const numberOfSlides = yield select(getBannerNumberOfSlides);
  yield put(addSlide(numberOfSlides));
}

export function* deleteSlideSaga({ payload: index }) {
  const elementsBySlide = yield select(getElementsBySlide);
  const slideElements = elementsBySlide.get(index).filter(element => !element.showOnAllSlides);
  for (const element of slideElements) {
    yield put(deleteElementRequested(element.id));
  }
  const numberOfSlides = yield select(getBannerNumberOfSlides);
  yield put(deleteSlide(index, numberOfSlides));
  yield call(backupBannerSaga);
}

export function* setBannerMasterSaga({ payload: makeMaster }) {
  if (makeMaster) {
    const selectedBannerIndex = yield select(getSelectedBannerIndex);
    yield put(makeBannerMaster(selectedBannerIndex));
    return;
  }
  yield put(makeBannerMaster(null));
}

export function* undoRedoSaga(undoOrRedo) {
  const selectedBannerIndex = yield select(getSelectedBannerIndex);
  const masterBannerIndex = yield select(getBannersMasterIndex);
  if (undoOrRedo === 'undo') {
    yield put(undoBanners());
    yield put(ActionCreators.undo());
  }
  if (undoOrRedo === 'redo') {
    yield put(redoBanners());
    yield put(ActionCreators.redo());
  }
  const newSelectedBannerIndex = yield select(getSelectedBannerIndex);
  const newMasterBannerIndex = yield select(getBannersMasterIndex);
  if (selectedBannerIndex !== newSelectedBannerIndex) yield put(selectBanner(selectedBannerIndex));
  if (masterBannerIndex !== newMasterBannerIndex) yield put(makeBannerMaster(masterBannerIndex));
}
export function* undoSaga() {
  yield call(undoRedoSaga, 'undo');
}
export function* redoSaga() {
  yield call(undoRedoSaga, 'redo');
}

export function* saga() {
  yield takeLatest(BANNERS_ADD_REQUESTED, addBannerSaga);
  yield takeLatest(BANNERS_COPY_REQUESTED, addBannerCopySaga);
  yield takeLatest(SLIDE_ADD_REQUESTED, addSlideSaga);
  yield takeLatest(BANNERS_CLOSE_REQUESTED, closeBannerSaga);
  yield takeLatest(BANNERSET_CLOSE_PREV_ADD_NEW_REQUESTED, closePrevBannersetOpenImportedSaga);
  yield takeLatest(BANNERSET_CREATE_NEW_BANNERSET_REQUESTED, createNewBannerSetSaga);
  yield takeLatest(SLIDE_DELETE_REQUESTED, deleteSlideSaga);
  yield takeLatest(SAVE_BANNERS_SAVE_BANNERSET_TO_NUXEO_REQUESTED, exportBannerSetToNuxeoSaga);
  yield takeLatest(BANNERSET_FROM_NUXEO_REQUESTED, importBannerSetFromNuxeoSaga);
  yield takeLatest(BANNERSET_LOAD_FROM_URL_PARAMS_REQUESTED, loadBannerSetFromUrlParamsSaga);
  yield takeLatest(BANNERS_SELECT_REQUESTED, selectBannerSaga);
  yield takeLatest(SLIDE_DUPLICATE_REQUESTED, selectBannerThenDuplicateSlideSaga);
  yield takeLatest(ELEMENT_DUPLICATE_REQUESTED, selectBannerThenDuplicateElementSaga);
  yield takeLatest(BANNERS_SET_MASTER_REQUESTED, setBannerMasterSaga);
  yield takeLatest(BANNERS_TAKE_SCREENSHOT_TO_THUMBNAIL_REQUESTED, takeScreenshotToThumbnailSaga);
  yield takeLatest(BANNERS_UNDO_REQUESTED, undoSaga);
  yield takeLatest(BANNERS_REDO_REQUESTED, redoSaga);
}
