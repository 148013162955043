import { elementLabel } from '../../element/elementLabel';
import { checkIfIsMapAndConvertIfNeeded } from './';
export const verifyElementsDuration = ({
  slide,
  slides,
  duration,
  elements,
  selectedSlideIndex,
  addError,
  addWarning,
  setElementDuration,
  setSlideDuration,
  setSlidesDuration,
}) => {
  //if we have only one element, we create a Map from it, to make the function more simple
  const elementsToIterate = checkIfIsMapAndConvertIfNeeded(elements);

  const elementsForWhichDurationIsTooSmall = [];
  const durationsOfElementsForWhichDurationIsTooSmall = [];
  const elementsShownOnAllSlides = elementsToIterate.filter(el => el.showOnAllSlides);

  elementsToIterate.forEach(element => {
    const minDuration = element.transitionIn.delay + element.transitionIn.duration + element.transitionOut.duration;
    let newDuration = duration;
    // compare duration with transitions duration
    if (element.duration < minDuration) {
      elementsForWhichDurationIsTooSmall.push(elementLabel(element));
      durationsOfElementsForWhichDurationIsTooSmall.push(minDuration);
      //... and rectify if needed
      newDuration = minDuration;
    }
    //if everything is alright, we track the new recorded duration
    setElementDuration({
      id: element.id,
      duration: newDuration,
      untrack: false,
    });
  });

  if (elementsForWhichDurationIsTooSmall.length === 1) {
    addError('properties.duration.error.isTooSmall', {
      name: elementsForWhichDurationIsTooSmall[0],
      transitionsDuration: durationsOfElementsForWhichDurationIsTooSmall[0],
    });
  }

  if (elementsForWhichDurationIsTooSmall.length > 1) {
    addError('properties.duration.error.areTooSmall', {
      names: elementsForWhichDurationIsTooSmall.join(', '),
    });
  }

  /* handle the 'not show on all slides' case */
  if (!elementsShownOnAllSlides.size && duration <= slide.duration) return;
  if (!elementsShownOnAllSlides.size && duration > slide.duration) {
    setSlideDuration({
      index: selectedSlideIndex,
      duration,
      untrack: true,
    });
    if (elementsToIterate.size === 1) {
      addWarning('properties.duration.warning.isExceedingSlideDuration', {
        name: elementLabel(elementsToIterate.first()),
      });
      return;
    }
    addWarning('properties.duration.warning.areExceedingSlideDuration', {
      names: elementsToIterate.map(el => elementLabel(el)).join(', '),
      selectedSlideIndex,
    });
    return;
  }
  /* handle the 'show on all slides' case */

  //TESTME
  const slidesToIncreaseDuration = slides.filter(slide => duration > slide.duration).map(slide => slide.id);
  if (!slidesToIncreaseDuration.size) return;

  setSlidesDuration({
    indexes: slidesToIncreaseDuration,
    duration,
    untrack: true,
  });

  if (slidesToIncreaseDuration.size === 1) {
    if (elementsShownOnAllSlides.size === 1) {
      addWarning('properties.duration.warning.isExceeding.slideDuration', {
        element: elementLabel(elementsShownOnAllSlides.first()),
        slide: slidesToIncreaseDuration.first(),
        duration,
      });
      return;
    }
    addWarning('properties.duration.warning.areExceeding.slideDuration', {
      elements: elementsShownOnAllSlides.map(el => elementLabel(el)).join(', '),
      slide: slidesToIncreaseDuration.first(),
      duration,
    });
    return;
  }

  if (elementsShownOnAllSlides.size === 1) {
    addWarning('properties.duration.warning.isExceeding.slidesDuration', {
      element: elementLabel(elementsShownOnAllSlides.first()),
      slides: slidesToIncreaseDuration.join(', '),
      duration,
    });
    return;
  }
  addWarning('properties.duration.warning.areExceeding.slidesDuration', {
    elements: elementsShownOnAllSlides.map(el => elementLabel(el)).join(', '),
    slides: slidesToIncreaseDuration.join(', '),
    duration,
  });
  return;
};
