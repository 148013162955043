import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  CircularProgress,
  Button,
  DialogActions,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from 'mdi-material-ui/Delete';
import { red } from '@material-ui/core/colors';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Dropzone from 'react-dropzone';

import keyboardShortcuts from '../reference/keyboardShortcuts';
import {
  dropzoneBorderColor,
  acceptDropzoneBgColor,
  acceptDropzoneBorderColor,
  rejectDropzoneBgColor,
  rejectDropzoneBorderColor,
} from '../reference/colors';
import { addImageElementFromComputer } from '../banner/bannerActionsCreators';
import { getNextElementId } from '../banner/bannerSelectors';
import { addError } from '../messages/messagesDucks';
import NewImageFrom123RF from './NewImageFrom123RF';
import { closeNewImageDialog, getNewImageDialogInfos, getNewImageData } from './newImageDucks';
import NewImageFromNuxeo from './NewImageFromNuxeo';
import { maxUploadSize, isImage, acceptedImageFileTypesTypesAsString, removeFile } from '../reference/importParams';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { isUserAuthenticated } from '../shared-selectors/sharedSelectors';

const thumbnailHeight = 150;

const styles = theme => ({
  dropZone: {
    borderWidth: 3,
    borderColor: dropzoneBorderColor,
    borderStyle: 'dashed',
    color: 'rgba(0, 0, 0, 0.75)',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    width: '100%',
    minHeight: 150,
    backgroundImage:
      'repeating-linear-gradient(-45deg, transparent, transparent 20px, rgba(0, 0, 0, 0.05) 20px, rgba(0, 0, 0, 0.05) 40px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogContent: {
    padding: 24,
    width: 600,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      padding: 16,
      boxSizing: 'border-box',
    },
  },
  overlay: {
    top: -3,
    right: -3,
    bottom: -3,
    left: -3,
  },
  busy: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollArea: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: `${thumbnailHeight}px`,
    gridGap: '10px',
    width: '100%',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    marginRight: 10,
    marginBottom: 10,
    cursor: 'pointer',
    position: 'relative',
  },
  accept: {
    backgroundColor: acceptDropzoneBgColor,
    borderColor: acceptDropzoneBorderColor,
  },
  reject: {
    backgroundColor: rejectDropzoneBgColor,
    borderColor: rejectDropzoneBorderColor,
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: red[500],
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.short,
    }),
    stroke: 'white',
    strokeWidth: '5%',
  },
  imagePreview: {
    position: 'relative',
  },
});

const activateNuxeo = false;
const activate123RF = false;

let tab = 0;
const fetchNuxeo = tab;
if (activateNuxeo) tab++;
const fetch123rf = tab;
if (activate123RF) tab++;
const computer = tab;

const recordNewfiles = files => state => {
  const filesToLoad = state.files ? [...state.files] : [];
  files.filter(isImage).forEach(file => {
    const thumbnailUrl = URL.createObjectURL(file);
    const fileToLoad = {
      name: file.name,
      size: file.size,
      type: file.type,
      lastModifiedDate: file.lastModifiedDate,
      // we can import the same file twice, the first one will be the one recorded.
      // the key is here to differentiate both of them.
      key: `${Date.now()}-${file.name}-${file.lastModifiedDate}`,
      file,
      thumbnailUrl,
    };
    filesToLoad.push(fileToLoad);
  });
  return {
    files: filesToLoad,
    activeTab: computer,
  };
};

const revokeUrls = (files = []) => {
  files &&
    files.length > 0 &&
    files.forEach(({ thumbnailUrl }) => {
      if (URL.revokeObjectURL) {
        URL.revokeObjectURL(thumbnailUrl);
      }
    });
};

class NewImageDialog extends React.PureComponent {
  state = {
    activeTab: this.props.authenticated ? fetch123rf : computer,
    files: null,
  };

  componentDidMount() {
    if (this.props.open && this.props.files && this.props.files.length > 0) {
      this.setState(recordNewfiles(this.props.files));
    }
    if (this.props.open) {
      window.addEventListener('keydown', this.handleShortcuts);
      window.addEventListener('keyup', this.handleShortcuts);
    }
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleShortcuts);
    window.removeEventListener('keyup', this.handleShortcuts);
  }
  handleShortcuts = e => {
    if (keyboardShortcuts.cancel.includes(e.key)) {
      this.handleClose();
    }
    if (keyboardShortcuts.enter.includes(e.key)) {
      this.handleAddImages();
    }
  };

  handleTabChange = (event, activeTab) => {
    this.setState({ activeTab });
  };

  handleClose = () => {
    revokeUrls(this.state.files);
    this.props.onCloseDialog();
  };

  handleAddImages = () => {
    const { addImageElementFromComputer, nextImageId } = this.props;
    const { files } = this.state;

    if (files && files.length > 0) {
      revokeUrls(files);
      files.forEach((image, ind) => addImageElementFromComputer(image.file, image.name, nextImageId + ind));
    }
  };

  handleDropRejected = rejectedFiles => {
    const { intl, addError } = this.props;
    const rejectedFile = rejectedFiles[0];
    if (rejectedFile.size > maxUploadSize) {
      addError('newImage.dropzone.error.size', {
        name: rejectedFile.name,
        maxSize: intl.formatNumber(maxUploadSize / (1024 * 1024)),
      });
    } else {
      addError('newImage.dropzone.error.type', { name: rejectedFile.name });
    }
  };

  renderTabs = () => {
    const { intl } = this.props;
    const { activeTab } = this.state;
    return (
      <Tabs
        value={activeTab}
        onChange={this.handleTabChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        {activateNuxeo && <Tab label={intl.formatMessage({ id: 'newImage.type.nuxeo' })} />}
        {activate123RF && <Tab label={intl.formatMessage({ id: 'newImage.type.123rf' })} />}
        <Tab label={intl.formatMessage({ id: 'newImage.type.computer' })} />
      </Tabs>
    );
  };

  renderDropzone = ({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
    const { intl } = this.props;
    if (isDragReject) return <FormattedMessage id={'newImage.dropzone.reject'} />;
    if (isDragActive) return <FormattedMessage id={'newImage.dropzone.accept'} />;
    return (
      <FormattedHTMLMessage
        id="newImage.dropzone.message"
        values={{ maxSize: intl.formatNumber(maxUploadSize / (1024 * 1024)) }}
      />
    );
  };

  render() {
    const { classes, open, busy, onCloseDialog, intl } = this.props;
    const { activeTab, files } = this.state;
    return (
      <Dialog open={open} onClose={onCloseDialog}>
        <DialogTitle>
          <FormattedMessage id="newImage.title" />
        </DialogTitle>
        {(activateNuxeo || activate123RF) && this.renderTabs()}
        <DialogContent classes={{ root: classes.dialogContent }}>
          {activateNuxeo &&
            activeTab === fetchNuxeo && <NewImageFromNuxeo onImageLoaded={onCloseDialog} onError={onCloseDialog} />}
          {activate123RF && activeTab === fetch123rf && <NewImageFrom123RF onImageLoaded={onCloseDialog} />}
          {activeTab === computer && (
            <React.Fragment>
              <Dropzone
                accept={acceptedImageFileTypesTypesAsString}
                maxSize={maxUploadSize}
                multiple={true}
                disablePreview={true}
                className={classNames(classes.dropZone)}
                activeClassName={classes.acceptDropZone}
                onDropAccepted={filesToUpload => this.setState(recordNewfiles(filesToUpload))}
                onDropRejected={this.handleDropRejected}
              >
                {this.renderDropzone}
              </Dropzone>
              <div
                className={classes.scrollArea}
                style={{ height: thumbnailHeight * (files ? Math.ceil(files.length / 3) : 0) + 10 }}
              >
                {files &&
                  files.map((image, index) => (
                    <div key={image.key} className={classes.imagePreview}>
                      <div className={classes.thumbnail} style={{ backgroundImage: `url(${image.thumbnailUrl})` }} />
                      <Tooltip title={<FormattedMessage id="file.delete" />} placement="right">
                        <IconButton className={classes.deleteButton} onClick={() => this.setState(removeFile(index))}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ))}
              </div>
            </React.Fragment>
          )}
        </DialogContent>
        {activeTab === computer && (
          <DialogActions>
            <Button onClick={this.handleClose}>{intl.formatMessage({ id: 'alert.cancel' })}</Button>
            <Button color="primary" disabled={!files || files.length === 0} onClick={this.handleAddImages}>
              {intl.formatMessage({ id: 'alert.import' })}
            </Button>
          </DialogActions>
        )}
        {busy && (
          <div className={classes.busy}>
            <CircularProgress />
          </div>
        )}
      </Dialog>
    );
  }
}

const makeStateToProps = () => {
  const stateToProps = state => {
    const authenticated = makeSelectorInstance(isUserAuthenticated)(state);
    const { open, busy, dialogKey: key } = makeSelectorInstance(getNewImageDialogInfos)(state);
    const files = makeSelectorInstance(getNewImageData)(state);
    const nextImageId = makeSelectorInstance(getNextElementId('image'))(state);

    return {
      authenticated,
      open,
      busy,
      key,
      files,
      nextImageId,
    };
  };
  return stateToProps;
};

const dispatchToProps = {
  onCloseDialog: closeNewImageDialog,
  addImageElementFromComputer,
  addError,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(NewImageDialog);
