import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { setElementCustomizable } from '../../banner/bannerActionsCreators';
import { getSelectedElementIsCustomizable } from '../../banner/bannerSelectors';
import { isCurrentBannerSlaveBanner } from '../../banners/bannersSelectors';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import CheckboxDoped from '../../components/CheckboxDoped';

class ElementCustomizable extends React.Component {
  render() {
    const { setElementCustomizable, customizable, id, isBannerSlave } = this.props;
    return (
      <CheckboxDoped
        toggleFunction={setElementCustomizable}
        checked={customizable}
        disabled={isBannerSlave}
        intlId="properties.customization.customizable"
        id={id}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  customizable: makeSelectorInstance(getSelectedElementIsCustomizable)(state),
  id: makeSelectorInstance(getSelectedElementId)(state),
  isBannerSlave: makeSelectorInstance(isCurrentBannerSlaveBanner)(state),
});

const dispatchToProps = {
  setElementCustomizable,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
)(ElementCustomizable);
