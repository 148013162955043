import { corners } from '../../../reference/corners';

const isSmaller = (a, b, strict = true) => (strict ? a < b : a <= b);
const isBigger = (a, b, strict = true) => (strict ? a > b : a >= b);

export const calculateLockAspectRatioCursorCases = ({
  phi,
  omega,
  movingCorner,
  cursorPosition,
  diagonal,
  ALPHA,
  minCursorPosition,
}) => {
  let cursorPositionChecked = cursorPosition;
  let diag = 0; // diagonale between ortho with horizontal angle
  let tooSmall = false;

  const horizontalPosition = diagonale => (cursorPosition.x - minCursorPosition.x) * Math.tan(diagonale);
  const verticalPosition = -cursorPosition.y + minCursorPosition.y;

  const tooSmallRightSide = (diagonale, strict = true) =>
    isBigger(diagonale, Math.PI / 2, strict) && isSmaller(diagonale, (3 * Math.PI) / 2, strict)
      ? horizontalPosition(diagonale) > verticalPosition
      : horizontalPosition(diagonale) < verticalPosition;

  const tooSmallLeftSide = (diagonale, strict = true) =>
    isSmaller(diagonale, Math.PI / 2, strict) || isBigger(diagonale, (3 * Math.PI) / 2, strict)
      ? horizontalPosition(diagonale) > verticalPosition
      : horizontalPosition(diagonale) < verticalPosition;

  switch (movingCorner) {
    case corners.topLeft:
    case corners.bottomRight:
      diag = (ALPHA - phi + Math.PI / 2 + 2 * Math.PI) % (2 * Math.PI); //cf schéma
      break;
    case corners.topRight:
    case corners.bottomLeft:
      diag = (ALPHA + phi + Math.PI / 2 + 2 * Math.PI) % (2 * Math.PI); //cf schéma
      break;
    case corners.middleTop:
    case corners.middleBottom:
      diag = (ALPHA + 2 * Math.PI) % (2 * Math.PI); //cf schéma
      break;
    case corners.middleRight:
    case corners.middleLeft:
      diag = (ALPHA + Math.PI / 2 + 2 * Math.PI) % (2 * Math.PI); //cf schéma
      break;
    default:
      break;
  }

  switch (movingCorner) {
    case corners.topLeft:
    case corners.bottomLeft:
    case corners.middleTop:
    case corners.middleLeft:
      tooSmall = tooSmallLeftSide(diag);
      break;
    case corners.bottomRight:
    case corners.topRight:
    case corners.middleBottom:
    case corners.middleRight:
      tooSmall = tooSmallRightSide(diag);
      break;
    default:
      break;
  }

  if (tooSmall) {
    cursorPositionChecked = minCursorPosition;
    return { cursorPositionChecked };
  }

  switch (movingCorner) {
    case corners.topLeft: {
      const projection = diagonal * Math.sin(Math.PI - omega - phi); //cf schéma: BD
      cursorPositionChecked = {
        x: cursorPosition.x - projection * Math.sin(phi - ALPHA),
        y: cursorPosition.y + projection * Math.cos(phi - ALPHA),
      };
      break;
    }
    case corners.bottomRight: {
      const projection = diagonal * Math.sin(2 * Math.PI - omega - phi); //cf schéma: BD
      cursorPositionChecked = {
        x: cursorPosition.x + projection * Math.sin(phi - ALPHA),
        y: cursorPosition.y - projection * Math.cos(phi - ALPHA),
      };
      break;
    }
    case corners.bottomLeft:
    case corners.topRight: {
      const projection = diagonal * Math.sin(omega - phi); //cf schéma: BD
      cursorPositionChecked = {
        x: cursorPosition.x + projection * Math.cos(Math.PI / 2 - phi - ALPHA),
        y: cursorPosition.y + projection * Math.sin(Math.PI / 2 - phi - ALPHA),
      };
      break;
    }
    case corners.middleBottom:
    case corners.middleTop: {
      const projection = diagonal * Math.sin(omega - Math.PI / 2); //cf schéma: BD
      cursorPositionChecked = {
        x: cursorPosition.x + projection * Math.cos(ALPHA),
        y: cursorPosition.y - projection * Math.sin(ALPHA),
      };
      break;
    }
    case corners.middleLeft:
    case corners.middleRight: {
      const projection = diagonal * Math.sin(omega); //cf schéma: BD
      cursorPositionChecked = {
        x: cursorPosition.x + projection * Math.sin(ALPHA),
        y: cursorPosition.y + projection * Math.cos(ALPHA),
      };
      break;
    }
    default:
      return { cursorPositionChecked };
  }

  return { cursorPositionChecked };
};
