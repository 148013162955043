import { objectUrlToBlob } from './objectUrlToBlob';
import { getResourceUrl } from '../../resources/resourcesDucks';
import { originalResourcesFolderName } from '../../reference/filesNames';

export const zipImageResourcesBannerSet = (banners, zip) => {
  // Image resources
  const originalResourcesZip = zip.folder(originalResourcesFolderName);

  banners.forEach(banner => {
    Object.keys(banner.elements)
      .map(elementId => banner.elements[elementId])
      .filter(el => el.resourceId)
      .forEach(({ resourceId, fileName }) =>
        originalResourcesZip.file(
          `${resourceId}/${fileName}`,
          objectUrlToBlob(getResourceUrl(resourceId), 'zipImageResourcesBannerSet'),
        ),
      );
  });
};
