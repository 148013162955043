import * as bannerActions from './bannerActions';
import * as bannersActions from '../banners/bannersActions';
import { CONFIG_LOAD_REQUESTED } from '../config/configDucks';

const actions = [
  ...Object.keys(bannerActions).map(action => bannerActions[action]),
  ...Object.keys(bannersActions).map(action => bannersActions[action]),
  CONFIG_LOAD_REQUESTED,
];

const untrackedActionsForSave = [
  /* banner actions*/
  CONFIG_LOAD_REQUESTED,
  bannerActions.BANNER_SELECT_ELEMENT,
  bannerActions.BANNER_SELECT_MULTIPLE_ELEMENTS,
  bannerActions.BANNER_UPDATE_ANIMATION_STATUS,
  bannerActions.BANNER_UPDATE_STATUS,
  bannerActions.BANNER_SHOW_ANIMATION_SNAPSHOT,
  bannerActions.BANNER_ADD_ELEMENT_REQUESTED,
  bannerActions.BANNER_IMAGE_ELEMENT_FROM_COMPUTER_REQUESTED,
  bannerActions.BANNER_IMAGE_ELEMENT_FROM_123RF_REQUESTED,
  bannerActions.BANNER_IMAGE_ELEMENT_FROM_NUXEO_REQUESTED,
  bannerActions.BANNER_CLEAR_STATE,
  bannerActions.BANNER_TRIGGER_UNDO_REDO,
  bannerActions.BANNER_SET_EXPIRATION_DATE_REQUESTED,

  /* slide actions*/
  bannerActions.SLIDE_ADD_REQUESTED,
  bannerActions.SLIDE_SELECT,
  bannerActions.SLIDE_SET_DURATION_REQUESTED,
  bannerActions.SLIDE_DELETE_REQUESTED,
  /* element actions*/
  bannerActions.ELEMENT_UPDATE_STATUS,
  bannerActions.ELEMENT_DELETE_REQUESTED,
  bannerActions.ELEMENT_SET_FONT_FAMILY_REQUESTED,
  /* banners state actions */
  bannersActions.BANNERS_LOAD,
  bannersActions.BANNERS_ADD_REQUESTED,
  bannersActions.BANNERS_COPY_REQUESTED,
  bannersActions.BANNERS_COPY_FORMAT,
  bannersActions.BANNERS_SELECT_REQUESTED,
  bannersActions.BANNERS_SELECTED,
  bannersActions.BANNERS_UPDATE,
  bannersActions.SLIDE_DUPLICATE_REQUESTED,
  bannersActions.ELEMENT_DUPLICATE_REQUESTED,
  bannersActions.BANNERS_SET_MASTER_REQUESTED,
  bannersActions.BANNERS_DELETE_ELEMENT_AFTER_CUT,
  bannersActions.BANNERS_CLOSE_REQUESTED,
  bannersActions.BANNERSET_ADD_TO_EXISTING_BANNERS_REQUESTED,
  bannersActions.BANNERSET_SAVE_PREV_ADD_NEW_REQUESTED,
  bannersActions.BANNERSET_CLOSE_PREV_ADD_NEW_REQUESTED,
  bannersActions.BANNERSET_FROM_NUXEO_REQUESTED,
  bannersActions.BANNERSET_FROM_NUXEO_SUCCESS,
  bannersActions.BANNERSET_TO_NUXEO_REQUESTED,
  bannersActions.BANNERSET_CREATE_NEW_BANNERSET_REQUESTED,
  bannersActions.BANNERSET_SET_PERMISSIONS,
  bannersActions.BANNERSET_SET_LOCK,
  bannersActions.BANNERSET_LOAD_FROM_URL_PARAMS_REQUESTED,
  bannersActions.BANNERSET_LOAD_FROM_URL_PARAMS_SUCCESS,
  bannersActions.BANNERSET_LOAD_FROM_URL_PARAMS_FAILURE,
  bannersActions.BANNERS_TAKE_SCREENSHOT_TO_THUMBNAIL_REQUESTED,
  bannersActions.BANNERS_UNDO_REQUESTED,
  bannersActions.BANNERS_REDO_REQUESTED,
];

const untrackedActions = [
  ...untrackedActionsForSave.filter(type => type !== bannerActions.BANNER_SELECT_MULTIPLE_ELEMENTS), // allow undo multiselect
  /* banner actions*/
  bannerActions.BANNER_NEW,
  bannerActions.BANNER_SET_THUMBNAIL,
  bannerActions.BANNER_SAVE,
  /* slide actions*/
  /* element actions*/
  bannerActions.ELEMENT_SET_IMAGE_RESOURCE,
  bannerActions.ELEMENT_SET_OPTIMIZED_IMAGE_RESOURCE,
  /* bannerset actions*/
  bannersActions.BANNERS_CLOSE,
  bannersActions.BANNERS_SLIDE_DUPLICATE,
  bannersActions.BANNERSET_SET_INFOS,
  bannersActions.BANNERSET_RESET_INFOS,
  bannersActions.BANNERS_CLOSE_ALL,
  bannersActions.BANNERS_SET_MASTER,
  bannersActions.BANNERSET_SET_FPS,
  bannersActions.BANNERSET_SET_NAME,
  bannersActions.BANNERS_SET_DEFAULT_THUMBNAIL,
  bannersActions.BANNERSET_SET_THUMBNAIL,
];

export const trackedActions = actions.filter(action => !untrackedActions.includes(action));
export const trackedActionsForSave = actions.filter(action => !untrackedActionsForSave.includes(action));
export const filter = action => trackedActions.includes(action.type) && !action.untrack;
