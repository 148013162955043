import { corners } from '../../../reference/corners';
import { calculateRotateCoordsInSystem } from './';

export const calculateRootTransformedBoundingRect = ({ x, y, width, height, centerCoords, ALPHA, zoom }) => {
  const cornerPositions = {
    [corners.topLeft]: calculateRotateCoordsInSystem({
      initCoords: { x, y },
      centerCoords,
      ALPHA: ALPHA + 2 * Math.PI,
      corner: corners.topLeft,
    }),
    [corners.topRight]: calculateRotateCoordsInSystem({
      initCoords: { x: x + width, y },
      centerCoords,
      ALPHA: ALPHA + 2 * Math.PI,
      corner: corners.topRight,
    }),
    [corners.bottomRight]: calculateRotateCoordsInSystem({
      initCoords: { x: x + width, y: y + height },
      centerCoords,
      ALPHA: ALPHA + 2 * Math.PI,
      corner: corners.bottomRight,
    }),
    [corners.bottomLeft]: calculateRotateCoordsInSystem({
      initCoords: { x, y: y + height },
      centerCoords,
      ALPHA: ALPHA + 2 * Math.PI,
      corner: corners.bottomLeft,
    }),
  };

  const abcisses = Object.keys(cornerPositions).map(corner => cornerPositions[corner].x / zoom);
  const ordinates = Object.keys(cornerPositions).map(corner => cornerPositions[corner].y / zoom);

  return {
    top: Math.min(...ordinates),
    left: Math.min(...abcisses),
    bottom: Math.max(...ordinates),
    right: Math.max(...abcisses),
  };
};
