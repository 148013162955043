import { filterOnlyUsedFonts } from './filterOnlyUsedFonts';
import { cleanBanner } from './cleanBanner';

export const getBannerDataToZip = (banner, userFonts) => {
  const { userFontNames, googleFontNames, customFontNames } = filterOnlyUsedFonts(banner, userFonts);
  const jsBanner = cleanBanner(banner, userFontNames, googleFontNames, customFontNames, userFonts);

  return {
    userFontNames,
    customFontNames,
    jsBanner,
  };
};
