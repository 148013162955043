import { slideTransitionTypesVar } from '../../reference/transitions';

export const getAnimationOut = (isLastSlide, nextSlide) => {
  if (isLastSlide) return slideTransitionTypesVar.lastSlide;
  if (
    nextSlide.transition.type === slideTransitionTypesVar.none ||
    nextSlide.transition.type === slideTransitionTypesVar.fadeIn
  )
    return `${slideTransitionTypesVar.none}-out`;
  if (nextSlide.transition.type !== slideTransitionTypesVar.fadeOut) return `${nextSlide.transition.type}-out`;
  return slideTransitionTypesVar.fadeOut;
};
