import React from 'react';
import { connect } from 'react-redux';
import ColorPicker from 'rc-color-picker';
import { decomposeColor, fade } from '@material-ui/core/styles/colorManipulator';
import EyeDropper from './EyeDropper';
import { withStyles } from '@material-ui/core/styles';
import { convertRGBToHex } from '../helpers';

import 'rc-color-picker/assets/index.css';
import { compose } from 'recompose';
import { updateBannerStatus } from '../../temporary-status/temporaryStatusDucks';

const styles = theme => ({
  colorPickerContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  colorPicker: {
    marginTop: 22,
    '& .rc-color-picker-trigger': {
      width: 24,
      height: 24,
      background: fade(theme.palette.background.paper, 0.5),
      border: `1px solid ${fade(theme.palette.text.primary, 0.12)}`,
      backgroundClip: 'content-box',
      padding: '3px 11px',
      boxShadow: 'none',
      // position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: fade(theme.palette.background.paper, 0.5),
        borderRadius: 2,
      },
    },
  },
});

const renderColor = value => {
  const color = decomposeColor(value || '#FFFFFF');
  const alpha = color.values.length >= 4 ? color.values[3] * 100 : 100;
  color.type = 'rgb';
  color.values = color.values.slice(0, 3);

  return { hexColor: convertRGBToHex(color), alpha };
};

class RgbaColorPicker extends React.Component {
  state = {
    hexColor: undefined,
    alpha: undefined,
  };

  componentDidMount() {
    this.renderColorOnly(this.props.value);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.renderColorOnly(this.props.value);
    }
  }

  onOpen = () => {
    window.addEventListener('keydown', function(e) {
      e.stopPropagation();
    });
    window.addEventListener('keyup', function(e) {
      e.stopPropagation();
    });
    this.props.updateBannerStatus({ colorPickerIsOpen: true });
  };

  onClose = () => {
    window.removeEventListener('keydown', function(e) {
      e.stopPropagation();
    });
    window.removeEventListener('keyup', function(e) {
      e.stopPropagation();
    });
    this.props.updateBannerStatus({ colorPickerIsOpen: false });
  };

  renderColorOnly = value => {
    this.setState({ ...renderColor(value) });
  };

  renderAndChangeColor = value => {
    const { hexColor, alpha } = renderColor(value);
    this.setState({ hexColor, alpha });
    this.props.onChange(fade(hexColor, alpha / 100));
  };

  render() {
    const { hexColor, alpha } = this.state;
    return (
      <div className={this.props.classes.colorPickerContainer}>
        <ColorPicker
          enableAlpha={this.props.enableAlpha}
          {...this.props}
          className={this.props.classes.colorPicker}
          color={hexColor}
          alpha={alpha}
          onOpen={this.onOpen}
          onClose={this.onClose}
          onChange={({ color, alpha }) => this.renderAndChangeColor(fade(color, alpha / 100))}
        />
        <EyeDropper
          onColorSearching={this.renderColorOnly}
          onColorPicked={this.renderAndChangeColor}
          initialColor={this.props.initialColor}
        />
      </div>
    );
  }
}

const dispatchToProps = {
  updateBannerStatus,
};

export default compose(
  connect(
    null,
    dispatchToProps,
  ),
  withStyles(styles),
)(RgbaColorPicker);
