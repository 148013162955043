import { fade } from '@material-ui/core/styles/colorManipulator';
import { propertyEditorWidth, handleWidth } from '../reference/dimensions';
import { properyEditorPanelColor } from '../reference/colors';

const handleHeight = theme => theme.spacing(6);

export const staticStyles = {
  textAlignRight: { textAlign: 'right' },
  flexEnd: { display: 'flex', alignItems: 'flex-end' },
};

export const styles = theme => ({
  root: {
    backgroundColor: properyEditorPanelColor,
    width: propertyEditorWidth,
    zIndex: 100,
    boxShadow: theme.shadows[2],
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    transition: theme.transitions.create(['transform'], { duration: theme.transitions.duration.standard }),
    display: 'flex',
    flexDirection: 'column',
    '& .react-swipeable-view-container': {
      height: '100%',
    },
  },
  folded: {
    transform: `translateX(${propertyEditorWidth}px)`,
  },
  scrollContainer: {
    height: '100%',
    // overflowY: 'auto',
    overflow: 'hidden', // react-perfect-scrollbar
  },
  labelShrink: {
    width: '130%',
  },
  labelShrinkXL: {
    width: '180%',
  },
  handle: {
    position: 'absolute',
    width: handleWidth,
    height: handleHeight(theme),
    left: -handleWidth - 5,
    top: 0,
    padding: '0 0 5px 5px',
    overflow: 'hidden',
    pointerEvents: 'none',
    color: `${theme.palette.primary.main} !important`,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: handleWidth,
      height: handleHeight(theme),
      backgroundColor: properyEditorPanelColor,
      borderRadius: '4px 0 0 4px',
      boxShadow: theme.shadows[2],
      zIndex: -1,
    },
    '& > button': {
      width: '100%',
      height: '100%',
      borderRadius: '4px 0 0 4px',
      pointerEvents: 'auto',
      transition: theme.transitions.create(['background-color'], { duration: theme.transitions.duration.short }),
      '&:hover': {
        backgroundColor: fade(theme.palette.text.primary, theme.palette.action.hoverOpacity),
      },
    },
  },
  subheader: {
    padding: 0,
    lineHeight: 'inherit',
  },
  slider: {
    marginTop: 16,
    width: 'auto',
  },
  toggleButtons: {
    marginTop: 22,
    display: 'flex',
    justifyContent: 'space-between',
    background: fade(theme.palette.background.paper, 0.5),
    border: `1px solid ${fade(theme.palette.text.primary, 0.12)}`,
    boxShadow: 'unset',
  },
  fontStyleToggleGroup: {
    display: 'flex',
  },
  toggleButtonTooltipContainer: {
    width: '100%',
  },
  // toggleButtonsSelected: {
  //   background: fade(theme.palette.background.paper, 0.5),
  //   border: `1px solid ${fade(theme.palette.text.primary, 0.12)}`,
  //   boxShadow: 'unset',
  // },
  labelSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toggleButtonsInactive: {
    background: fade(theme.palette.background.paper, 0.5),
    border: `1px solid ${fade(theme.palette.text.primary, 0.12)}`,
    boxShadow: 'unset',
    pointerEvents: 'none',
    cursor: 'default',
  },
  averageInput: {
    fontStyle: 'italic',
    color: fade(theme.palette.action.disabled, 0.4),
  },
  toggleButtonsStandalone: {
    display: 'inline-block',
    textTransform: 'initial',
    color: `${theme.palette.text.primary} !important`,
    transition: theme.transitions.create(['background-color', 'color'], { duration: theme.transitions.duration.short }),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
    },
  },
  toggleButton: {
    minWidth: 30,
    height: theme.spacing(4),
    textTransform: 'initial',
    color: theme.palette.action.active,
    border: 'unset',
    transition: theme.transitions.create(['background-color', 'color'], { duration: theme.transitions.duration.short }),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
    },
  },
  disableChildren: {
    '& *': {
      pointerEvents: 'none',
    },
  },
  lowPaddingX: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  toggleButton2: {
    textTransform: 'initial',
    height: theme.spacing(4),
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['background-color', 'color'], { duration: theme.transitions.duration.short }),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
    },
  },
  formatButton: {
    textTransform: 'initial',
    color: theme.palette.text.primary,
    width: '100%',
    height: theme.spacing(4),
    transition: theme.transitions.create(['background-color', 'color'], { duration: theme.transitions.duration.short }),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
    },
  },
  formatButtonRoot: {
    width: '110%',
  },
  addFontButton: {
    margin: 0,
    padding: 0,
  },
  distanceChooser: {
    display: 'flex',
    '& > div': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  radioGroup: {
    flex: 1,
    display: 'flex',
    marginTop: theme.spacing(1),
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    fontSize: 14,
    '& > *': {
      marginRight: -14,
    },
  },
  radioGroupCentered: {
    marginBottom: theme.spacing(1),
    '& > *': {
      marginRight: 0,
    },
    '& > *:not(:last-child)': {
      marginBottom: 2 * theme.spacing(1),
    },
  },
  radioButton: {
    padding: `0 ${theme.spacing(1)}px 0 ${2 * theme.spacing(1)}px`,
    '& svg': {
      fontSize: 24,
    },
  },
  toggleButtonDisabled: {
    color: fade(theme.palette.action.disabled, 0.12),
  },
  button: {
    textTransform: 'none',
    transition: theme.transitions.create(['background-color', 'color'], { duration: theme.transitions.duration.short }),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  fontIcon: {
    marginRight: theme.spacing(1),
    fontSize: 16,
  },
  transitionAllSlides: {
    padding: '0 24px',
  },
  thumbnail: {
    width: propertyEditorWidth / 3,
    height: propertyEditorWidth / 3,
    borderRadius: theme.spacing(4),
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
  flipIcon: {
    height: '20px',
    width: '20px',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  transitionPanel: {
    flexDirection: 'column',
    padding: `${theme.spacing(2)}px 0`,
  },
  transitionTab: {
    textTransform: 'initial',
    minWidth: 50,
    minHeight: theme.spacing(4),
  },
  tabSelected: {
    background: 'transparent',
    boxShadow: 'unset',
  },
  transitionTabHigh: {
    minHeight: handleHeight(theme),
  },
  tabsRoot: {
    minHeight: theme.spacing(4),
    flexShrink: 0,
    boxShadow: '0 2px 0px 0 rgba(203,203,203,0.45)',
  },
  tabsRootHigh: {
    height: handleHeight(theme),
  },
  transitionContent: {
    padding: theme.spacing(3),
  },
  onlySpinnerNumberInput: {
    '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
      opacity: 1,
    },
    '& input[type=number]': {
      cursor: 'default !important',
    },
  },
  smallButton: {
    minWidth: 0,
    flex: 1,
    padding: `${theme.spacing(0.5)}px !important`,
    height: 'auto',
    border: 'none',
  },
  bigButton: {
    flex: 1,
  },
  bigButtonLabel: {
    '& > *': {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
  smallButtonContainer: {
    width: '100%',
    justifyContent: 'center',
  },
  deleteButton: {
    minWidth: 0,
    padding: theme.spacing(1),
  },
  cropButton: {
    flex: 1,
    '& svg + span': {
      paddingLeft: theme.spacing(1),
    },
  },
  playTransLabel: {
    '& span': {
      paddingRight: theme.spacing(1),
    },
  },
  playTransRoot: {
    width: '100%',
    justifyContent: 'space-between',
  },
  unitSelect: {
    paddingRight: 20,
  },
  value: {
    minHeight: theme.spacing(4),
    marginTop: theme.spacing(4),
    display: 'inline-flex',
    justifyContent: 'flex-start',
    fontFamily: theme.typography.fontFamily,
  },
  valueRight: {
    minHeight: theme.spacing(4),
    marginTop: theme.spacing(4),
    display: 'inline-flex',
    justifyContent: 'flex-end',
    fontFamily: theme.typography.fontFamily,
  },
  valueContent: {
    flex: 1,
    padding: '6px 0 7px',
  },
  valueAdornment: {
    height: theme.spacing(4),
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  chips: {
    marginTop: 22,
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  chipLabel: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  menuSelected: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
    },
    '&$menuSelected': {
      backgroundColor: fade(theme.palette.primary.main, 0.7),
      color: '#FFF',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
      },
    },
  },
  menuItemDisabled: {
    color: fade(theme.palette.action.disabled, 0.38),
  },
  directionChooserRoot: {
    marginTop: 20,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    boxShadow: theme.shadows[2],
  },
});
