import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose, onlyUpdateForKeys } from 'recompose';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PlayCircleIcon from 'mdi-material-ui/PlayCircle';
import StopCircleIcon from 'mdi-material-ui/StopCircle';
import { getSelectedElementCanPlayAnimation } from '../../banner/bannerSelectors';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { styles } from '../propertyEditorStyles';
import {
  updateElementStatus,
  getSelectedElementId,
  getSelectedElementIsPlayingAnimation,
} from '../../temporary-status/temporaryStatusDucks';

const emptyArray = [];

class ElementTransitionPlay extends React.Component {
  state = { classesState: {} };

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return {
      classesState: {
        toggleButtonGroup: { root: classes.toggleButtons },
        toggleAnimationButton: {
          root: classNames(classes.toggleButton, classes.smallButtonContainer, classes.bigButton),
          disabled: classes.toggleButtonDisabled,
          label: classes.bigButtonLabel,
          selected: classes.toggleButtonsSelected,
        },
      },
    };
  }

  onToggleElementAnimation = () => {
    const { elementIsPlayingAnimation, id, updateElementStatus } = this.props;
    elementIsPlayingAnimation
      ? updateElementStatus({ id, elementIsPlayingAnimation: false })
      : updateElementStatus({ id, elementIsPlayingAnimation: true });
  };

  render() {
    const { classes, canPlayAnimation, elementIsPlayingAnimation } = this.props;
    const { classesState } = this.state;

    return (
      <ToggleButtonGroup
        classes={classesState.toggleButtonGroup}
        selected
        value={emptyArray}
        onChange={this.onToggleElementAnimation}
      >
        <ToggleButton
          value="playAndPauseTransition"
          className={classes.toggleButton}
          classes={classesState.toggleAnimationButton}
          disabled={!canPlayAnimation}
        >
          {elementIsPlayingAnimation ? (
            <React.Fragment>
              <StopCircleIcon />
              <FormattedMessage id="properties.transition.stop" />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <PlayCircleIcon />
              <FormattedMessage id="properties.transition.play" />
            </React.Fragment>
          )}
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  elementIsPlayingAnimation: makeSelectorInstance(getSelectedElementIsPlayingAnimation)(state),
  canPlayAnimation: makeSelectorInstance(getSelectedElementCanPlayAnimation)(state),
});

const dispatchToProps = {
  updateElementStatus,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  onlyUpdateForKeys(['elementIsPlayingAnimation', 'canPlayAnimation']),
  withStyles(styles),
)(ElementTransitionPlay);
