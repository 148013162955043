import React from 'react';
import FormatText from 'mdi-material-ui/FormatText';
import Image from 'mdi-material-ui/Image';
import Rectangle from 'mdi-material-ui/VectorRectangle';
import ArrowRightBoldBox from 'mdi-material-ui/ArrowRightBoldBox';
import RotateLeft from 'mdi-material-ui/RotateLeft';
import BackupRestore from 'mdi-material-ui/BackupRestore';
import Undo from 'mdi-material-ui/Undo';
import Redo from 'mdi-material-ui/Redo';
import ArrowLeft from 'mdi-material-ui/ArrowLeft';
import ArrowRight from 'mdi-material-ui/ArrowRight';
import ArrowUp from 'mdi-material-ui/ArrowUp';
import ArrowDown from 'mdi-material-ui/ArrowDown';
import ArrowBottomRight from 'mdi-material-ui/ArrowBottomRight';
import ArrowBottomLeft from 'mdi-material-ui/ArrowBottomLeft';
import ArrowTopRight from 'mdi-material-ui/ArrowTopRight';
import ArrowTopLeft from 'mdi-material-ui/ArrowTopLeft';
import CursorMove from 'mdi-material-ui/CursorMove';
import ContentDuplicate from 'mdi-material-ui/ContentDuplicate';
import Plus from 'mdi-material-ui/Plus';
import Minus from 'mdi-material-ui/Minus';
import CropFree from 'mdi-material-ui/CropFree';
import resizeImageHandleIcon from './icons/resize-handle.png';
import flipHIcon from './icons/flipH.svg';
import flipVIcon from './icons/flipV.svg';
import DimensionsIcon from './icons/DimensionsIcon';
import FolderOpen from 'mdi-material-ui/FolderOpen';
import Share from 'mdi-material-ui/Share';
import ContentSave from 'mdi-material-ui/ContentSave';
import ContentSaveEdit from 'mdi-material-ui/ContentSaveEdit';
import ContentSaveMode from './icons/ContentSaveMode';
import FolderPlus from 'mdi-material-ui/FolderPlus';
import CloseIcon from '@material-ui/icons/Close';
import PlusBox from 'mdi-material-ui/PlusBox';
import Pencil from 'mdi-material-ui/Pencil';
import Import from 'mdi-material-ui/Import';
import Export from 'mdi-material-ui/Export';
import PencilOff from 'mdi-material-ui/PencilOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default {
  text: <FormatText />,
  image: <Image />,
  rectangle: <Rectangle />,
  button: <ArrowRightBoldBox />,
  rotate: <RotateLeft />,
  move: <CursorMove />,
  duplicate: <ContentDuplicate />,
  plus: <Plus />,
  minus: <Minus />,
  resetZoom: <CropFree />,
  resizeImageHandle: resizeImageHandleIcon,
  flipH(classes) {
    return <div style={{ backgroundImage: `url(${flipHIcon})` }} className={classes} />;
  },
  flipV(classes) {
    return <div style={{ backgroundImage: `url(${flipVIcon})` }} className={classes} />;
  },
  arrow: {
    topLeft: <ArrowTopLeft />,
    up: <ArrowUp />,
    topRight: <ArrowTopRight />,
    left: <ArrowLeft />,
    right: <ArrowRight />,
    bottomLeft: <ArrowBottomLeft />,
    down: <ArrowDown />,
    bottomRight: <ArrowBottomRight />,
  },
  openBannerSet: <FolderOpen />,
  shareBannerSet: <Share />,
  exportBannerSet: <Export />,
  saveBannerSet: <ContentSave />,
  saveAsBannerSet: <ContentSaveEdit />,
  moveBannerSet: <ContentSaveMode />,
  newBannerSet: <FolderPlus />,
  importBannerSet: <Import />,
  closeBanner: <CloseIcon />,
  addNewBanner: <PlusBox />,
  edit: <Pencil />,
  editStop: <PencilOff />,
  format: <DimensionsIcon />,
  expandMoreIcon: <ExpandMoreIcon />,
  restore: <BackupRestore />,
  undo: <Undo />,
  redo: <Redo />,
};
