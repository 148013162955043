import { cornerAnglesForCursor } from '../corners';

//firefox bug: https://stackoverflow.com/questions/30676125/svg-as-data-uri-triggers-xml-parsing-error-in-firefox

const resize = rotation =>
  "<svg width='20px' height='20px' transform='rotate(" +
  rotation +
  ")' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs></defs><g id='Page-1' stroke='none' stroke-width='1' " +
  "fill='none' fill-rule='evenodd'><g id='resizeupdown' fill-rule='nonzero'><g id='bg-copy' opacity='0.00999999978' fill='%23FFFFFF'><rect id='bg' x='0' y='0' width='20' " +
  "height='20'></rect></g><polygon id='resize-border' fill='%23FFFFFF' points='9.9883 1 4.5273 6.962 8.0053 6.962 8.0053 8 2.0003 8 2.0003 8.019 2.0003 11.961 2.0003 " +
  "11.981 8.0053 11.981 8.0053 13.039 4.5393 13.039 10.0113 19 15.4733 13.039 11.9953 13.039 11.9953 11.981 18.0003 11.981 18.0003 8 11.9943 8 11.9943 6.962 15.4613 6.962'>" +
  "</polygon><polygon id='resize' fill='%23000000' points='16.9609 9.0195 10.9799 9.0195 10.9799 5.9805 13.2399 5.9805 9.9889 2.6615 6.7659 5.9805 9.0199 5.9805 9.0199 9.0195 " +
  '3.0399 9.0195 3.0199 9.0195 3.0199 10.9805 3.0399 10.9805 9.0399 10.9805 9.0399 14.0195 6.7599 14.0195 10.0109 17.3395 13.2339 14.0195 10.9809 14.0195 10.9809 10.9805 ' +
  "16.9609 10.9805 16.9809 10.9805 16.9809 9.0195'></polygon></g></g></svg>";

const rotate = rotation =>
  "<svg width='20px' height='20px' transform='rotate(" +
  (rotation - 90) +
  ")' viewBox='0 0 250 250' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs></defs><g id='Page-1' stroke='none' stroke-width='1' " +
  "fill='none' fill-rule='evenodd'><g id='rotate' transform='translate(-39.000000, -38.000000)'><g id='Group-4' transform='translate(39.000000, 38.000000)' opacity='0.01' " +
  "fill-rule='nonzero' fill='%23FFFFFF'><rect id='bg' x='0' y='0' width='250' height='250'></rect></g><path d='M118.743179,133.571646 C141.298009,135.369488 158.920665," +
  '142.752306 171.611144,155.7201 C184.301624,168.687894 191.730455,186.236703 193.897637,208.366529 L149.397568,208.366529 L214.027867,278.406331 L278.380005,208.366529 ' +
  'L233.396982,208.366529 C233.978666,176.143685 222.849342,148.940663 200.00901,126.757464 C177.168678,104.574265 150.080068,93.482665 118.743179,93.482665 L118.743179,' +
  "48 L48,112.828291 L118.743179,177.983203 L118.743179,133.571646 Z' stroke='%23FFFFFF' stroke-width='13' fill='%23000000' transform='translate(163.190002, 163.203166) " +
  "rotate(45.000000) translate(-163.190002, -163.203166) '></path></g></g></svg>";

const topLeft = ({ rotation, handleDragFor }) =>
  `url("data:image/svg+xml;utf8,${
    handleDragFor === 'resize'
      ? resize(cornerAnglesForCursor.topLeft + rotation)
      : rotate(cornerAnglesForCursor.topLeft + rotation)
  }") 10 10, auto`;
const topRight = ({ rotation, handleDragFor }) =>
  `url("data:image/svg+xml;utf8,${
    handleDragFor === 'resize'
      ? resize(cornerAnglesForCursor.topRight + rotation)
      : rotate(cornerAnglesForCursor.topRight + rotation)
  }") 10 10, auto`;
const bottomLeft = ({ rotation, handleDragFor }) =>
  `url("data:image/svg+xml;utf8,${
    handleDragFor === 'resize'
      ? resize(cornerAnglesForCursor.bottomLeft + rotation)
      : rotate(cornerAnglesForCursor.bottomLeft + rotation)
  }") 10 10, auto`;
const bottomRight = ({ rotation, handleDragFor }) =>
  `url("data:image/svg+xml;utf8,${
    handleDragFor === 'resize'
      ? resize(cornerAnglesForCursor.bottomRight + rotation)
      : rotate(cornerAnglesForCursor.bottomRight + rotation)
  }") 10 10, auto`;
const middleTop = ({ rotation, handleDragFor }) =>
  `url("data:image/svg+xml;utf8,${
    handleDragFor === 'resize'
      ? resize(cornerAnglesForCursor.middleTop + rotation)
      : rotate(cornerAnglesForCursor.middleTop + rotation)
  }") 10 10, auto`;
const middleRight = ({ rotation, handleDragFor }) =>
  `url("data:image/svg+xml;utf8,${
    handleDragFor === 'resize'
      ? resize(cornerAnglesForCursor.middleRight + rotation)
      : rotate(cornerAnglesForCursor.middleRight + rotation)
  }") 10 10, auto`;
const middleBottom = ({ rotation, handleDragFor }) =>
  `url("data:image/svg+xml;utf8,${
    handleDragFor === 'resize'
      ? resize(cornerAnglesForCursor.middleBottom + rotation)
      : rotate(cornerAnglesForCursor.middleBottom + rotation)
  }") 10 10, auto`;
const middleLeft = ({ rotation, handleDragFor }) =>
  `url("data:image/svg+xml;utf8,${
    handleDragFor === 'resize'
      ? resize(cornerAnglesForCursor.middleLeft + rotation)
      : rotate(cornerAnglesForCursor.middleLeft + rotation)
  }") 10 10, auto`;

const eyeDropper =
  "url(\"data:image/svg+xml;utf8,<svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' " +
  "xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='eyedropper' fill-rule='nonzero' " +
  "fill='%23000000'><path d='M17.35,9.72 L15.22,11.85 L13.81,10.43 L6.1,18.14 L1.5,20 L0,18.5 L1.86,13.9 L9.57,6.19 L8.15,4.78 L10.28,2.65 L17.35,9.72 L17.35," +
  '9.72 Z M14.76,1 C15.93,-0.17 17.83,-0.17 19,1 C20.17,2.17 20.17,4.07 19,5.24 L17.08,7.16 L12.84,2.92 L14.76,1 L14.76,1 Z M3.56,15.03 L2.5,17.5 L4.97,' +
  "16.44 L12.4,9 L11,7.6 L3.56,15.03 Z' id='Shape'></path></g></g></svg>\") 0 20, auto";

export default {
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  middleTop,
  middleRight,
  middleBottom,
  middleLeft,
  eyeDropper,
};
