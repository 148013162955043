import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { compose, onlyUpdateForKeys } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElements } from '../../banner/bannerSelectors';
import { setElementTransitionInDelay, setElementsTransitionInDelay } from '../../banner/bannerActionsCreators';
import { durationInputProps, secondEndornment, parseFloatHelper } from '../constants';
import { addError } from '../../messages/messagesDucks';
import {
  verifyElementsTransitionInDelay,
  elementsDeepPropertyAverage,
  allElementsHasSameDeepProperty,
} from '../helpers';
import { styles } from '../propertyEditorStyles';
import { getSelectedElementIds } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementsTransitionInDelay extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { elements, classes } = nextProps;
    return {
      durationAdditionalData: {
        elements,
        oldTransitionInDelays: elements.map(el => el.transitionIn.delay),
      },
      InputProps: {
        ...secondEndornment,
        classes: {
          root: allElementsHasSameDeepProperty(elements, 'transitionOut', 'duration') === false && classes.averageInput,
        },
      },
    };
  }

  onTransitionInDelayChange = ({ value: delay }) =>
    this.props.setElementsTransitionInDelay({ delay, ids: this.props.ids }); //tracked
  onTransitionInDelayVerify = ({ additionalData: { oldTransitionInDelays, elements } }) => {
    const { setElementTransitionInDelay, addError } = this.props;
    verifyElementsTransitionInDelay({
      elements,
      oldTransitionInDelays,
      setElementTransitionInDelay,
      addError,
    });
  };

  render() {
    const { selectedElementIds, elements, intl } = this.props;
    const { durationAdditionalData, InputProps } = this.state;
    return (
      <TextFieldDoped
        key={selectedElementIds}
        type="number"
        label={intl.formatMessage({ id: 'properties.transition.delay' })}
        value={elementsDeepPropertyAverage(elements, 'transitionIn', 'delay')[0]}
        fullWidth
        inputProps={durationInputProps}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={InputProps}
        helper={parseFloatHelper}
        additionalData={durationAdditionalData}
        onChangeMethod={this.onTransitionInDelayChange}
        onVerifyMethod={this.onTransitionInDelayVerify}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  elements: makeSelectorInstance(getSelectedElements)(state),
  selectedElementIds: makeSelectorInstance(getSelectedElementIds)(state),
  ids: makeSelectorInstance(getSelectedElementIds)(state),
});

const dispatchToProps = {
  addError,
  setElementTransitionInDelay,
  setElementsTransitionInDelay,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  onlyUpdateForKeys(['elements', 'selectedElementIds']),
  injectIntl,
  withStyles(styles),
)(ElementsTransitionInDelay);
