import React from 'react';
import FormatFontIcon from 'mdi-material-ui/FormatFont';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { Button, withStyles } from '@material-ui/core';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementFontSize } from '../../banner/bannerSelectors';
import { styles } from '../propertyEditorStyles';
import { openUploadFontDialog } from '../../fonts/fontsDucks';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

class ElementAddFont extends React.Component {
  onFontUploadClick = () => this.props.openUploadFontDialog();

  render() {
    const { classes } = this.props;
    return (
      <Button variant="outlined" className={classes.button} fullWidth onClick={this.onFontUploadClick}>
        <FormatFontIcon className={classes.fontIcon} />
        <FormattedMessage id="properties.text.upload-font" />
      </Button>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  fontSize: makeSelectorInstance(getSelectedElementFontSize)(state),
});

const dispatchToProps = {
  openUploadFontDialog,
};
export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  withStyles(styles),
)(ElementAddFont);
