import React from 'react';
import { connect } from 'react-redux';
import { getSelectedElementFileName } from '../../banner/bannerSelectors';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withStyles, FormControl, InputLabel } from '@material-ui/core';
import { styles } from '../propertyEditorStyles';

class ElementFileName extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    return { shrink: nextProps.classes.labelShrink };
  }

  render() {
    const { fileName, classes } = this.props;

    return (
      <FormControl fullWidth>
        <InputLabel shrink classes={this.state}>
          <FormattedMessage id="properties.image.fileName" />
        </InputLabel>
        <div className={classes.value}>{fileName}</div>
      </FormControl>
    );
  }
}

const makeStateToProps = () => state => ({
  fileName: makeSelectorInstance(getSelectedElementFileName)(state),
});

export default compose(
  connect(makeStateToProps),
  withStyles(styles),
)(ElementFileName);
