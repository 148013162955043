import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Range } from 'immutable';
import { IntlProvider } from 'react-intl';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import makeSelectorInstance from '../reference/makeSelectorInstance';
import { elementListWidth, propertyEditorWidth, slidesPanelWidth } from '../reference/dimensions';
import { getValueFromUrlParameter, bannerSetIdParam } from '../reference/urlParams';

import {
  checkIfSlideIsLastSlideOverall,
  checkIfSlideIsFirstSlideOverall,
  checkIfSlideIsLastSlideOfTour,
  checkIfSlideIsFirstSlideOfTour,
} from '../player/util/slidePositions';

import { getSelectedBannerIndex, isAppLoaded } from '../shared-selectors/sharedSelectors';
import { getSlidesForBannerAnimation, getBannerRepetitions, getSelectedSlide } from '../banner/bannerSelectors';
import {
  getSlidesToExportToImages,
  getBannersToRenderForImageExport,
  isRenderingCurrentSceneForSnapshot,
  isRenderingBannersForExportToAnimatedGIF,
} from '../export/exportSelectors';
import { getTranslationState, getLocaleState } from '../i18n/i18nDucks';
import { checkKeyDiff } from '../components/helpers';
import {
  getBannerShowAnimationSnapshot,
  isBannerPlayingHTMLAnimation,
  isBannerPlayingGIFAnimation,
  getBannerCurrentFrame,
  getSelectedSlideIndex,
} from '../temporary-status/temporaryStatusDucks';

import Menu from '../menu/Menu';
import BannerSetNameBar from '../banners/BannerSetNameBar';
import TabBar from '../banners/TabBar';
import ElementList from '../element-list/ElementList';
import Scene from '../scene/Scene';
import BannerFormatSelector from '../banner-format-selector/BannerFormatSelector';
import PropertyEditor from '../property-editor/PropertyEditor';
import Slides from '../slides/Slides';
import NewImageDialog from '../new-image/NewImageDialog';
import Messages from '../messages/Messages';
import Alert from '../alert/Alert';
import GlobalDropZone from '../new-image/GlobalDropZone';
import UploadFontDialog from '../fonts/UploadFontDialog';
import ImportBannerSetFromComputer from '../open-banner-set/ImportBannerSetFromComputer';
import OpenBannerSetFromNuxeo from '../open-banner-set/OpenBannerSetFromNuxeo';
import SaveBannersDialog from '../save-banners-dialog/SaveBannersDialog';
import ExportDialog from '../export/ExportDialog';
import AdBuilderWelcomeDialog from '../open-banner-set/AdBuilderWelcomeDialog';
import ImportBannersDialog from '../open-banner-set/ImportBannersDialog';
import EntitiesDialog from '../entities/EntitiesDialog';
import MasterBannerDialog from '../master-banner-dialog/MasterBannerDialog';
import BannerSnapshotHidden from '../export/BannerSnapshotHidden';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    background: 'white',
  },
  clientArea: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  dropZoneOverlay: {
    opacity: 0.9,
  },
  scenesContainer: {
    flex: 1,
    overflow: 'auto',
    position: 'absolute',
    top: 0,
    bottom: slidesPanelWidth,
    left: elementListWidth,
    right: propertyEditorWidth,
  },
};

/*
  WORKING CASES
  There are several working cases for rendering banners and slides :
  1. Render banners for exporting animated GIF
  2. Render banners for creating snapshot and banner thumbnail
  3. Render banners' slides to export to JPG or PNG
  4. Render banner when playing animation or when showing and choosing snapshots
  5. Render banner when playing GIF animation
  6. Render working slide
*/

class App extends React.Component {
  shouldComponentUpdate(nextProps) {
    return Boolean(checkKeyDiff(nextProps, this.props, 'App'));
  }

  render() {
    const {
      messages,
      locale,
      classes,
      selectedSlideIndex,
      slidesForAnimation,
      bannerIsPlayingHTMLAnimation,
      bannerIsPlayingGIFAnimation,
      showAnimationSnapshot,
      selectedBannerIndex,
      slidesToExportToImages,
      bannersToRenderForImageExport,
      renderingBannersForExportToAnimatedGIF,
      renderingCurrentSceneForSnapshot,
      currentFrame,
      slide,
      repetitions,
    } = this.props;

    moment.locale(locale); // update moment locale

    if (!messages) return null;

    return (
      <IntlProvider key={locale + messages} locale={locale} messages={JSON.parse(messages)}>
        <GlobalDropZone disableClick>
          {/* 1. Render banners for exporting animated GIF */}
          {bannersToRenderForImageExport &&
            bannersToRenderForImageExport.size > 0 &&
            renderingBannersForExportToAnimatedGIF &&
            bannersToRenderForImageExport.map(
              ({
                bannerName,
                bannerFormat,
                slidesToExport,
                bannerNumberOfFrames,
                backgroundColor,
                transparentColor,
                bannerUniqueId,
              }) =>
                Range(0, bannerNumberOfFrames).map(frame => (
                  <BannerSnapshotHidden
                    key={`${bannerName}-frame${frame}`}
                    from="renderingBannersForExportToAnimatedGIF"
                    slidesToExport={slidesToExport}
                    bannerName={bannerName}
                    bannerFormat={bannerFormat}
                    bannerUniqueId={bannerUniqueId}
                    backgroundColor={backgroundColor}
                    transparentColor={transparentColor}
                    currentFrame={frame}
                    repetitions={repetitions}
                  />
                )),
            )}
          {/* 2. Render banners for creating snapshot and banner thumbnail */}
          {bannersToRenderForImageExport &&
            bannersToRenderForImageExport.size > 0 &&
            renderingCurrentSceneForSnapshot &&
            bannersToRenderForImageExport.map(
              ({ bannerName, bannerFormat, slidesToExport, backgroundColor, bannerUniqueId }) => (
                <BannerSnapshotHidden
                  key={`${bannerName}-frame${currentFrame}`}
                  from="renderingCurrentSceneForSnapshot"
                  slidesToExport={slidesToExport}
                  bannerFormat={bannerFormat}
                  bannerName={bannerName}
                  bannerUniqueId={bannerUniqueId}
                  backgroundColor={backgroundColor}
                  currentFrame={currentFrame}
                  snapshotCurrentScene={true}
                  repetitions={repetitions}
                />
              ),
            )}
          {/* 3. Render banners' slides to export to JPG or PNG */}
          {slidesToExportToImages &&
            slidesToExportToImages.size > 0 &&
            slidesToExportToImages.map((slideToExport, ind) => (
              <BannerSnapshotHidden
                key={`${slideToExport.slide.id}${slideToExport.bannerUniqueId}${slideToExport.currentFrame}`}
                from="renderingCurrentSceneForSnapshot"
                slidesToExport={slidesToExportToImages.filter((_, index) => index === ind)}
                bannerName={slideToExport.bannerName}
                bannerFormat={slideToExport.bannerFormat}
                bannerUniqueId={slideToExport.bannerUniqueId}
                backgroundColor={slideToExport.bannerBackgroundColor}
                snapshotCurrentScene={true}
                repetitions={1}
              />
            ))}
          <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <div className={classes.root}>
              <BannerSetNameBar />
              <Menu backToDrBanner={getValueFromUrlParameter(bannerSetIdParam)} />
              <TabBar />
              <div className={classes.clientArea} id="client-area" key={selectedBannerIndex}>
                <Slides />
                <PropertyEditor />
                <ElementList />
                {/* 4. Render banner when playing HTML animation (!!NOT GIF ANIMATION!!) or when showing and choosing snapshots */}
                {((bannerIsPlayingHTMLAnimation && !showAnimationSnapshot) || showAnimationSnapshot) &&
                  slidesForAnimation.map((slideDetails, ind) => (
                    <Scene
                      key={slideDetails.slideIndex}
                      slideIndex={slideDetails.slideIndex}
                      slide={slideDetails.slide}
                      slideDurationForAnimation={slideDetails.slideDurationForAnimation}
                      animationGlobalDelay={slideDetails.animationGlobalDelay}
                      isLastSlide={checkIfSlideIsLastSlideOverall(ind, slidesForAnimation)}
                      isFirstSlide={checkIfSlideIsFirstSlideOverall(ind)}
                      slidesForAnimation={slidesForAnimation}
                      isLastSlideOfTour={checkIfSlideIsLastSlideOfTour(ind, slidesForAnimation, repetitions)}
                      isFirstSlideOfTour={checkIfSlideIsFirstSlideOfTour(ind, slidesForAnimation, repetitions)}
                    />
                  ))}
                {/* 5. Render banner when playing GIF animation  */}
                {bannerIsPlayingGIFAnimation &&
                  slidesForAnimation.map((slideDetails, ind) => (
                    <Scene
                      key={slideDetails.slideIndex}
                      slideIndex={slideDetails.slideIndex}
                      slide={slideDetails.slide}
                      slideDurationForAnimation={slideDetails.slideDurationForAnimation}
                      animationGlobalDelay={slideDetails.animationGlobalDelay}
                      isLastSlide={checkIfSlideIsLastSlideOverall(slideDetails.slideIndex, slidesForAnimation)}
                      isFirstSlide={checkIfSlideIsFirstSlideOverall(slideDetails.slideIndex)}
                      slidesForAnimation={slidesForAnimation}
                      isLastSlideOfTour={checkIfSlideIsLastSlideOfTour(
                        slideDetails.slideIndex,
                        slidesForAnimation,
                        repetitions,
                      )}
                      isFirstSlideOfTour={checkIfSlideIsFirstSlideOfTour(
                        slideDetails.slideIndex,
                        slidesForAnimation,
                        repetitions,
                      )}
                    />
                  ))}
                {/* 6. Render working slide */}
                {!(bannerIsPlayingHTMLAnimation || bannerIsPlayingGIFAnimation || showAnimationSnapshot) && (
                  <Scene
                    key={slide}
                    slide={slide}
                    slideIndex={selectedSlideIndex}
                    animationGlobalDelay={0}
                    isLastSlide={true}
                    isFirstSlide={true}
                    isLastSlideOfTour={false}
                    isFirstSlideOfTour={false}
                  />
                )}
              </div>
              <BannerFormatSelector />
              <NewImageDialog />
              <UploadFontDialog />
              <OpenBannerSetFromNuxeo />
              <ImportBannerSetFromComputer />
              <SaveBannersDialog />
              <ExportDialog />
              <Messages />
              <Alert />
              <AdBuilderWelcomeDialog />
              <MasterBannerDialog />
              <EntitiesDialog />
              <ImportBannersDialog />
            </div>
          </MuiPickersUtilsProvider>
        </GlobalDropZone>
      </IntlProvider>
    );
  }
}

const makeStateToProps = () => state => ({
  key: makeSelectorInstance(isAppLoaded)(state), // needed to force re-render once the theme has loaded, if not there is a bug with MUI not loading styles for defined classes
  messages: makeSelectorInstance(getTranslationState)(state),
  locale: makeSelectorInstance(getLocaleState)(state),
  showAnimationSnapshot: makeSelectorInstance(getBannerShowAnimationSnapshot)(state),
  currentFrame: makeSelectorInstance(getBannerCurrentFrame)(state),
  bannerIsPlayingHTMLAnimation: makeSelectorInstance(isBannerPlayingHTMLAnimation)(state),
  bannerIsPlayingGIFAnimation: makeSelectorInstance(isBannerPlayingGIFAnimation)(state),
  slide: makeSelectorInstance(getSelectedSlide)(state),
  selectedSlideIndex: makeSelectorInstance(getSelectedSlideIndex)(state),
  selectedBannerIndex: makeSelectorInstance(getSelectedBannerIndex)(state),
  slidesToExportToImages: makeSelectorInstance(getSlidesToExportToImages)(state),
  bannersToRenderForImageExport: makeSelectorInstance(getBannersToRenderForImageExport)(state),
  slidesForAnimation: makeSelectorInstance(getSlidesForBannerAnimation)(state),
  renderingCurrentSceneForSnapshot: makeSelectorInstance(isRenderingCurrentSceneForSnapshot)(state),
  repetitions: makeSelectorInstance(getBannerRepetitions)(state),
  renderingBannersForExportToAnimatedGIF: makeSelectorInstance(isRenderingBannersForExportToAnimatedGIF)(state),
});

export default compose(connect(makeStateToProps), withStyles(styles))(App);
