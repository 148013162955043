export const corners = {
  topLeft: 'topLeft',
  topRight: 'topRight',
  bottomLeft: 'bottomLeft',
  bottomRight: 'bottomRight',
  middleTop: 'middleTop',
  middleRight: 'middleRight',
  middleBottom: 'middleBottom',
  middleLeft: 'middleLeft',
  inside: 'inside',
};

export const cornerAnglesForCursor = {
  [corners.topLeft]: 315,
  [corners.topRight]: 45,
  [corners.bottomLeft]: 225,
  [corners.bottomRight]: 135,
  [corners.middleTop]: 0,
  [corners.middleRight]: 90,
  [corners.middleBottom]: 180,
  [corners.middleLeft]: 270,
};

export const cornerAnglesForRotation = {
  [corners.topLeft]: 45,
  [corners.topRight]: 135,
  [corners.bottomLeft]: 225,
  [corners.bottomRight]: 315,
  [corners.middleTop]: 90,
  [corners.middleRight]: 0,
  [corners.middleBottom]: 270,
  [corners.middleLeft]: 180,
};
