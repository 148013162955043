import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, Dialog, DialogTitle, DialogActions, Button, DialogContent } from '@material-ui/core';
import { Line } from 'rc-progress';
import classnames from 'classnames';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { isUserAuthenticated } from '../shared-selectors/sharedSelectors';
import { isBannerSetIsReadOnly } from '../banners/bannersSelectors';
import {
  closeSaveBannersDialog,
  getSaveBannersDialogState,
  saveBannerSetRequested,
  getDefaultThumbnailsToCreate,
} from './saveBannersDucks';
import { getSelectedEntityIdForSave } from '../entities/entitiesSelectors';
import { openSaveAsDialog } from '../entities/entitiesActionCreators';

const classes = theme => ({
  paperWidthSm: {
    maxWidth: 650,
  },
  paperWidthMd: {
    maxWidth: 650,
  },
  paperWidthXs: {
    maxWidth: 650,
  },
  noPadding: {
    padding: 0,
  },
  variantBody2: {
    color: theme.typography.body2.color,
    marginTop: theme.spacing(2),
  },
  padding: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
});

const SaveBannersDialog = ({
  cancel,
  cancelable,
  message,
  saveCurrent,
  dontSaveCurrent,
  authenticated,
  open,
  readOnly,
  saving,
  defaultThumbnails,
  theme,
  classes,
  selectedEntityIdForSave,
  handleSaveAsBannerSet,
}) => {
  const dialogClasses = {
    paperWidthSm: classnames(classes.paperWidthSm, saving && classes.padding),
    paperWidthMd: classnames(classes.paperWidthMd, saving && classes.padding),
    paperWidthXs: classnames(classes.paperWidthXs, saving && classes.padding),
  };

  const saveMessage =
    authenticated && !readOnly ? 'banner.open.ask-before.save-current' : 'app.menu.bannerExport.label';

  const handleSaveButtonClick = useCallback(() => {
    selectedEntityIdForSave ? saveCurrent(true) : handleSaveAsBannerSet();
  }, [handleSaveAsBannerSet, saveCurrent, selectedEntityIdForSave]);

  return (
    <Dialog
      open={open || saving}
      onClose={cancel}
      classes={dialogClasses}
      disableBackdropClick={!cancelable || saving}
      disableEscapeKeyDown={!cancelable || saving}
    >
      {saving && (
        <React.Fragment>
          <DialogTitle classes={{ root: classes.noPadding }}>
            <FormattedMessage id="banner.open.saving" />
          </DialogTitle>
          {defaultThumbnails.remaining ? (
            <DialogContent classes={{ root: classnames(classes.noPadding, classes.variantBody2) }}>
              <FormattedMessage id="banner.open.saving.creating-thumbnails" values={defaultThumbnails} />
              <Line
                percent={(defaultThumbnails.done / defaultThumbnails.total) * 100}
                strokeWidth="4"
                strokeColor={theme.palette.primary.main}
              />
            </DialogContent>
          ) : null}
        </React.Fragment>
      )}
      {!saving && (
        <React.Fragment>
          <DialogTitle>
            <FormattedMessage id={message} />
          </DialogTitle>
          <DialogActions>
            {cancelable && (
              <Button onClick={cancel}>
                <FormattedMessage id="alert.cancel" />
              </Button>
            )}
            <Button color="primary" onClick={handleSaveButtonClick}>
              <FormattedMessage id={saveMessage} />
            </Button>
            <Button color="primary" onClick={dontSaveCurrent}>
              <FormattedMessage id="banner.open.ask-before.not-save-current" />
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
};

const makeStateToProps = () => {
  const stateToProps = state => {
    const { dialogOpen: open, cancelable, saving, dialogKey: key, message } = makeSelectorInstance(
      getSaveBannersDialogState,
    )(state);
    const authenticated = makeSelectorInstance(isUserAuthenticated)(state);
    const readOnly = makeSelectorInstance(isBannerSetIsReadOnly)(state);
    const defaultThumbnails = makeSelectorInstance(getDefaultThumbnailsToCreate)(state);
    const selectedEntityIdForSave = makeSelectorInstance(getSelectedEntityIdForSave)(state);

    return {
      open,
      saving,
      authenticated,
      readOnly,
      cancelable,
      message,
      defaultThumbnails,
      key, // Used to reset the component on each dialog open
      selectedEntityIdForSave,
    };
  };
  return stateToProps;
};

const dispatchToProps = {
  cancel: closeSaveBannersDialog,
  saveCurrent: () => saveBannerSetRequested(true),
  dontSaveCurrent: () => saveBannerSetRequested(false),
  handleSaveAsBannerSet: () => openSaveAsDialog(),
};

export default compose(
  connect(makeStateToProps, dispatchToProps),
  withStyles(classes, { withTheme: true }),
  React.memo,
)(SaveBannersDialog);
