import { saga as bannerSaga } from './banner/bannerSaga';
import { saga as bannersSaga } from './banners/bannersSaga';
import { saga as i18nSaga } from './i18n/i18nDucks';
import { saga as resourcesSaga } from './resources/resourcesDucks';
import { saga as alertSaga } from './alert/alertDucks';
import { saga as fontsSaga } from './fonts/fontsDucks';
import { saga as layoutSaga } from './layout/layoutDucks';
import { saga as securitySaga } from './security/securityDucks';
import { saga as exportSlidesToImagesSaga } from './export/exportDucks';
import { saga as openBannerSetDialogSaga } from './open-banner-set/openBannerSetDucks';
import { saga as configSaga } from './config/configDucks';
import { saga as bannerFormatSaga } from './banner-format-selector/bannerFormatSelectorDucks';
import { saga as saveBannersSaga } from './save-banners-dialog/saveBannersDucks';
import { saga as entitiesSaga } from './entities/entitiesDucks';

export default [
  bannerSaga,
  bannerFormatSaga,
  bannersSaga,
  resourcesSaga,
  alertSaga,
  fontsSaga,
  saveBannersSaga,
  layoutSaga,
  securitySaga,
  exportSlidesToImagesSaga,
  openBannerSetDialogSaga,
  configSaga,
  i18nSaga,
  entitiesSaga,
];
