import { corners } from '../../../reference/corners';

export const checkIfCornerIsMiddleCorner = ({ movingCorner }) => {
  switch (movingCorner) {
    case corners.middleRight:
    case corners.middleBottom:
    case corners.middleLeft:
    case corners.middleTop: {
      return true;
    }
    default: {
      return false;
    }
  }
};
