import { fontsFolderName } from '../../reference/filesNames';
import { Map } from 'immutable';
import { debug } from './params';

export const importAllFonts = async unzippedPlayers => {
  try {
    let fonts = [];

    for (const unzippedPlayer of unzippedPlayers) {
      const bannerFonts = await Promise.all(
        Map(unzippedPlayer.files)
          .valueSeq()
          .filter(file => !file.dir)
          .filter(file => file.name.startsWith(`${fontsFolderName}/`))
          .toArray()
          .map(file => {
            const id = file.name.replace(/^[^/]+\/([^/]+)\/.*$/, '$1');
            const fontName = file.name.replace(/^.*\/([^/]+)$/, '$1');
            return file.async('blob').then(blob => ({ id, fontName, blob }));
          }),
      );
      fonts.push(...bannerFonts);
    }

    debug && console.log({ fonts });

    const fontsObject = {};
    for (const font of fonts) {
      fontsObject[font.id] = font;
    }

    debug && console.log({ fontsObject });

    const fontMap = Map(fontsObject)
      .valueSeq()
      .toArray();
    debug && console.log({ fontMap });
    return fontMap;
  } catch (e) {
    console.error('cant import fonts from nuxeo', e);
  }
};
