import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose, onlyUpdateForKeys } from 'recompose';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core';
import CropIcon from '@material-ui/icons/Crop';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import classNames from 'classnames';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import {
  getSelectedElementPositionX,
  getSelectedElementPositionY,
  getSelectedElementWidth,
  getSelectedElementHeight,
  getSelectedElementPicWhileCroppingProperties,
} from '../../banner/bannerSelectors';
import { onCropClickRequested, setElementBackToOriginalImage } from '../../banner/bannerActionsCreators';
import ToggleButtonWithTooltip from '../../components/ToggleButton/ToggleButtonWithTooltip';
import { styles } from '../propertyEditorStyles';
import { disableBackToOriginalImage } from '../helpers';
import { getSelectedElementId, getSelectedElementCroppingStatus } from '../../temporary-status/temporaryStatusDucks';

const emptyArray = [];
const cropArray = ['crop'];

class ElementCrop extends React.Component {
  state = { classesState: {} };

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return {
      classesState: {
        toggleButtonGroup: { root: classes.toggleButtons },
        cropButton: {
          root: classNames(classes.toggleButton, classes.bigButton),
          label: classes.bigButtonLabel,
          selected: classes.toggleButtonsSelected,
        },
        restoreImage: {
          root: classNames(classes.toggleButton, classes.lowPaddingX),
          disabled: classes.toggleButtonDisabled,
          selected: classes.toggleButtonsSelected,
        },
      },
    };
  }

  onCropClick = (e, value) => {
    this.props.onCropClickRequested(value && value.includes('reset'));
  };

  onRestoreImageClick = () => this.props.setElementBackToOriginalImage(this.props.id);

  render() {
    const { isCropping, intl, width, height, picWhileCroppingProperties, x, y } = this.props;
    const { classesState } = this.state;
    const disabled = disableBackToOriginalImage({ width, height, picWhileCroppingProperties, x, y });
    return (
      <ToggleButtonGroup
        classes={classesState.toggleButtonGroup}
        selected
        value={isCropping ? cropArray : emptyArray}
        onChange={this.onCropClick}
      >
        <ToggleButton value="crop" classes={classesState.cropButton}>
          <CropIcon />
          <FormattedMessage id="properties.image.crop" />
        </ToggleButton>
        <ToggleButtonWithTooltip
          classes={classesState.restoreImage}
          value="reset"
          tooltip={intl.formatMessage({ id: 'properties.image.backToOriginalImage' })}
          disabled={disabled}
          onClick={this.onRestoreImageClick}
        >
          <RestoreIcon />
        </ToggleButtonWithTooltip>
      </ToggleButtonGroup>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  isCropping: makeSelectorInstance(getSelectedElementCroppingStatus)(state),
  picWhileCroppingProperties: makeSelectorInstance(getSelectedElementPicWhileCroppingProperties)(state),
  height: makeSelectorInstance(getSelectedElementHeight)(state),
  width: makeSelectorInstance(getSelectedElementWidth)(state),
  x: makeSelectorInstance(getSelectedElementPositionX)(state),
  y: makeSelectorInstance(getSelectedElementPositionY)(state),
});

const dispatchToProps = {
  onCropClickRequested,
  setElementBackToOriginalImage,
};
export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  onlyUpdateForKeys(['id', 'isCropping']),
  injectIntl,
  withStyles(styles),
)(ElementCrop);
