import { put, takeLatest, call, take } from 'redux-saga/effects';
import { BANNER_NEW } from '../banner/bannerActions';
import {
  BANNERSET_ADD_TO_EXISTING_BANNERS_REQUESTED,
  BANNERSET_SAVE_PREV_ADD_NEW_REQUESTED,
  BANNERSET_CLOSE_PREV_ADD_NEW_REQUESTED,
  BANNERSET_FROM_NUXEO_REQUESTED,
} from '../banners/bannersActions';
import { MESSAGE_ADD } from '../messages/messagesDucks';
import { openSaveBannersDialogRequested, SAVE_BANNERS_IS_DONE } from '../save-banners-dialog/saveBannersDucks';
import { getValueFromUrlParameter, openBannersetParam, removeParameterFromUrl } from '../reference/urlParams';
// Actions

export const OPEN_BANNERSET_DIALOG_OPEN = 'adbuilder/OPEN_BANNERSET_DIALOG_OPEN';
const OPEN_BANNERSET_DIALOG_OPEN_REQUESTED = 'adbuilder/OPEN_BANNERSET_DIALOG_OPEN_REQUESTED';
const OPEN_BANNERSET_DIALOG_OPEN_AT_START_REQUESTED = 'adbuilder/OPEN_BANNERSET_DIALOG_OPEN_AT_START_REQUESTED';
export const OPEN_BANNERSET_DIALOG_CLOSE = 'adbuilder/OPEN_BANNERSET_DIALOG_CLOSE';
const OPEN_BANNERSET_IS_IMPORTING_BANNERSET = 'adbuilder/OPEN_BANNERSET_IS_IMPORTING_BANNERSET';

// Action creators
export const openOpenBannerSetDialogRequested = (file = null, importFromComputer = false) => ({
  type: OPEN_BANNERSET_DIALOG_OPEN_REQUESTED,
  payload: file,
  importFromComputer,
});

export const openOpenBannerSetDialogAtStart = () => ({
  type: OPEN_BANNERSET_DIALOG_OPEN_AT_START_REQUESTED,
});

export const openOpenBannerSetDialog = (file = null, importFromComputer = false) => ({
  type: OPEN_BANNERSET_DIALOG_OPEN,
  payload: {
    file,
  },
  importFromComputer,
});

export const closeOpenBannerSetDialog = () => ({
  type: OPEN_BANNERSET_DIALOG_CLOSE,
});

export const importingBannerSet = () => ({
  type: OPEN_BANNERSET_IS_IMPORTING_BANNERSET,
});

// Selectors
export const isImportingBannerSet = ({ openBannerSet }) => openBannerSet.importing;
export const getOpenBannerSetDialogKey = ({ openBannerSet }) => openBannerSet.dialogKey;
export const getOpenBannerSetFile = ({ openBannerSet }) => openBannerSet.bannerSetData.file;
export const getOpenBannerSetDialogImportFromComputer = ({ openBannerSet }) => openBannerSet.importFromComputer;
// Sagas

function* openBannerSetDialogSaga({ payload, importFromComputer }) {
  yield put(openSaveBannersDialogRequested({}));
  const { payload: open } = yield take(SAVE_BANNERS_IS_DONE);
  if (open) {
    yield put(openOpenBannerSetDialog(payload, importFromComputer));
  }
}

function* openBannerSetDialogAtStartSaga() {
  const openBannerSetRequestedFromUrl = yield call(getValueFromUrlParameter, openBannersetParam);
  if (openBannerSetRequestedFromUrl) {
    yield put(openOpenBannerSetDialog());
    yield call(removeParameterFromUrl, openBannersetParam);
  }
}

export function* saga() {
  yield takeLatest(OPEN_BANNERSET_DIALOG_OPEN_REQUESTED, openBannerSetDialogSaga);
  yield takeLatest(OPEN_BANNERSET_DIALOG_OPEN_AT_START_REQUESTED, openBannerSetDialogAtStartSaga);
}
// Reducer
export const initialState = {
  dialogOpen: false,
  dialogKey: 0,
  importing: false,
  bannerSetData: { file: null },
  importFromComputer: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_BANNERSET_DIALOG_OPEN: {
      return {
        ...state,
        dialogOpen: true,
        dialogKey: state.dialogKey + 1,
        importing: false,
        bannerSetData: { ...action.payload },
        importFromComputer: action.importFromComputer,
      };
    }
    case OPEN_BANNERSET_DIALOG_CLOSE:
    case BANNER_NEW: {
      return {
        ...state,
        dialogOpen: false,
        dialogKey: state.dialogKey + 1,
        bannerSetData: { file: null },
        importFromComputer: false,
      };
    }
    case MESSAGE_ADD: {
      const { message } = action.payload;
      if (
        message === 'banner.open.import-success' ||
        message === 'banner.open.import-error-unexpected' ||
        message === 'banner.open.import-error-uid' ||
        message === 'banner.open.import-error'
      ) {
        return {
          ...state,
          importing: false,
          importFromComputer: false,
        };
      }
      return state;
    }
    case OPEN_BANNERSET_IS_IMPORTING_BANNERSET: {
      return {
        ...state,
        importing: true,
      };
    }
    case BANNERSET_ADD_TO_EXISTING_BANNERS_REQUESTED:
    case BANNERSET_SAVE_PREV_ADD_NEW_REQUESTED:
    case BANNERSET_CLOSE_PREV_ADD_NEW_REQUESTED:
    case BANNERSET_FROM_NUXEO_REQUESTED: {
      return {
        ...state,
        dialogOpen: false,
        dialogKey: state.dialogKey + 1,
        importing: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
