import React from 'react';
import PropTypes from 'prop-types';
import { TextField, withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ActionCreators } from 'redux-undo';

import { getSelectedElements } from '../banner/bannerSelectors';
import { updateElements, updateElementsWithDiff } from '../banner/bannerActionsCreators';
import keyboardShortcuts from '../reference/keyboardShortcuts';
import { allElementsHasSameProperty, elementsPropertyAverage } from './helpers';
import { compose } from 'recompose';
import { styles } from './propertyEditorStyles';
import { toggleMenuOrModalOpen } from '../temporary-text-editor/temporaryTextEditorDucks';
import { getTemporaryMenuOrModalOpen } from '../shared-selectors/sharedSelectors';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getSelectedElementIds } from '../temporary-status/temporaryStatusDucks';

class TextFieldForElements extends React.Component {
  state = {};

  componentDidMount() {
    const { blurTextElement, toggleMenuOrModalOpen, menuOrModalOpen } = this.props;
    blurTextElement && menuOrModalOpen && toggleMenuOrModalOpen(false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.focus && !prevProps.focus) {
      this.textField.focus();
    }
  }

  componentWillUnmount() {
    const { blurTextElement, toggleMenuOrModalOpen, menuOrModalOpen } = this.props;
    blurTextElement && menuOrModalOpen && toggleMenuOrModalOpen(false);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { classes, elements, property } = nextProps;
    return {
      average: elementsPropertyAverage(elements, property)[0],
      classesState: {
        InputProps: {
          classes: {
            root: allElementsHasSameProperty(elements, property) === false && classes.averageInput,
          },
        },
      },
    };
  }

  onKeyDown = e => {
    const { jump } = this.props;
    e.stopPropagation();
    if (keyboardShortcuts.enter.includes(e.key)) {
      e.target.blur();
    }
    if (keyboardShortcuts.cancel.includes(e.key)) {
      this.cancel = true;
      jump(-this.countChanges);
      e.target.blur();
    }
  };

  onKeyUp = e => e.stopPropagation();

  onChange = e => {
    const {
      updateElementsWithDiff,
      updateElements,
      shouldUpdateWithDiff,
      helper,
      averageSumupFactor,
      property,
      ids,
    } = this.props;
    const { average } = this.state;
    this.countChanges = this.countChanges + 1;
    shouldUpdateWithDiff
      ? updateElementsWithDiff({ ids, [property]: helper(e.target.value) - average * averageSumupFactor || 0 }, false) //track
      : updateElements({ ids, [property]: helper(e.target.value) || 0 }, false); //track
  };

  onFocus = () => {
    const { blurTextElement, toggleMenuOrModalOpen } = this.props;
    const { average } = this.state;
    this.countChanges = 0;
    this.averageValue = average;
    blurTextElement && toggleMenuOrModalOpen(true);
  };

  onBlur = e => {
    const {
      updateElementsWithDiff,
      updateElements,
      shouldUpdateWithDiff,
      helper,
      averageSumupFactor,
      blurTextElement,
      toggleMenuOrModalOpen,
      menuOrModalOpen,
      property,
      ids,
    } = this.props;
    const { average } = this.state;
    blurTextElement && menuOrModalOpen && toggleMenuOrModalOpen(false);
    if (this.cancel) {
      this.cancel = false;
    } else {
      shouldUpdateWithDiff
        ? updateElementsWithDiff({ ids, [property]: helper(e.target.value) - average * averageSumupFactor || 0 }, false) //tracked
        : updateElements({ ids, [property]: helper(e.target.value) || 0 }, false); //tracked
    }
  };

  onClick = () => {
    const { blurTextElement, toggleMenuOrModalOpen, onClick } = this.props;
    blurTextElement ? toggleMenuOrModalOpen(true) : onClick();
    this.textField.focus();
  };

  render() {
    const { formattedMessage, intl } = this.props;
    const { average, classesState } = this.state;
    return (
      <TextField
        type="number"
        inputRef={ref => (this.textField = ref)}
        InputProps={classesState.InputProps}
        label={intl.formatMessage({ id: formattedMessage })}
        value={average}
        onKeyDown={this.onKeyDown}
        onKeyUp={this.onKeyUp}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        fullWidth
      />
    );
  }
}

TextFieldForElements.propTypes = {
  property: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  formattedMessage: PropTypes.string.isRequired,
  jump: PropTypes.func.isRequired,
  updateElementsWithDiff: PropTypes.func.isRequired,
  updateElements: PropTypes.func.isRequired,
  shouldUpdateWithDiff: PropTypes.bool.isRequired,
  helper: PropTypes.func,
  averageSumupFactor: PropTypes.number,
  blurTextElement: PropTypes.bool,
};

TextFieldForElements.defaultProps = {
  helper(value) {
    return parseInt(value, 10) || 0;
  },
  onVerifyMethod: () => {
    return;
  },
  onClick: () => {
    return;
  },
  averageSumupFactor: 1,
  blurTextElement: true,
};

const makeStateToProps = () => state => ({
  elements: makeSelectorInstance(getSelectedElements)(state),
  ids: makeSelectorInstance(getSelectedElementIds)(state),
  menuOrModalOpen: makeSelectorInstance(getTemporaryMenuOrModalOpen)(state),
});

const dispatchToProps = {
  updateElements,
  updateElementsWithDiff,
  jump: ActionCreators.jump,
  toggleMenuOrModalOpen,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(TextFieldForElements);
