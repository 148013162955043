import fetchNuxeo, { meNuxeo } from './fetchNuxeo';

const fetchNuxeoMe = () =>
  fetchNuxeo({
    baseUrl: window.aragoConfig.api.media,
    path: meNuxeo,
    errorMessage: "can't get me from nuxeo",
    credentials: 'include',
  });

export default fetchNuxeoMe;
