import { put, select, takeLatest, takeEvery, take, call } from 'redux-saga/effects';
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { minSlideDurationNotTransiting } from '../reference/transitions';
import autoHideDurations from '../reference/messagesDurations';
import { elementLabel } from '../element/elementLabel';
import { getBannerFormat, getUserFonts, getSelectedBannerIndex } from '../shared-selectors/sharedSelectors';
import {
  getSlideAtIndex,
  getBannerSlides,
  getBannerRepetitions,
  getNextElementId,
  getSelectedSlideDuration,
  getElementFromId,
} from './bannerSelectors';
import {
  isCurrentBannerSlaveBanner,
  isCurrentBannerMasterBanner,
  getBannerSlavesIndexes,
  getBannerSetExpirationDate,
  getBannersPresent,
  getBannersMasterIndex,
} from '../banners/bannersSelectors';
import { getConfig } from '../config/configDucks';
import { loadFont } from '../resources/fonts';
import { importFrom123RFToNuxeo } from '../resources/fetching123RF';
import { addResource, createOptimizedResource } from '../resources/resourcesDucks';
import {
  createTextElement,
  createImageElement,
  createRectangleElement,
  createButtonElement,
  getImageSize,
  getElementsMinDuration,
  solveNextSlide,
  solveEnterTransition,
  solveLeaveTransitions,
  solveTotalTransitionDuration,
  getSlideShortestDurationPossible,
} from './bannerUtils';
// Actions
import {
  BANNER_ELEMENT_ADDED,
  BANNER_ADD_ELEMENT_REQUESTED,
  BANNER_IMAGE_ELEMENT_FROM_COMPUTER_REQUESTED,
  BANNER_IMAGE_ELEMENT_FROM_123RF_REQUESTED,
  BANNER_IMAGE_ELEMENT_FROM_NUXEO_REQUESTED,
  BANNER_SET_EXPIRATION_DATE_REQUESTED,
  ELEMENT_SET_FONT_FAMILY_REQUESTED,
  ELEMENT_CROP_REQUESTED,
  ELEMENT_DELETE_REQUESTED,
  ELEMENT_UPDATE_DIMENSIONS_REQUESTED,
  SLIDE_SET_DURATION_REQUESTED,
  SLIDE_CHECK_AND_UPDATE_SLIDE_TRANSITION,
} from './bannerActions';
import {
  addedElement,
  checkAndUpdateSlideTransitionRequested,
  setSlideDuration,
  setElementDuration,
  setSlidesDuration,
  setSlideTransitionDuration,
  setElementBackToOriginalImage,
  deleteElement,
  setElementImageResource,
  setElementFontFamilySuccess,
  setElementDimensions,
  setBannerExpirationDate,
} from './bannerActionsCreators';
import { addError, addWarning, addInfo } from '../messages/messagesDucks';
import { openNewImageDialog } from '../new-image/newImageDucks';
import {
  openMasterBannerDialog,
  MASTER_BANNER_DIALOG_CONFIRM_DISSOCIATE,
  MASTER_BANNER_DIALOG_CANCEL,
} from '../master-banner-dialog/masterBannerDialogDucks';
import {
  getSelectedSlideIndex,
  getSelectedElementId,
  updateBannerStatus,
  updateElementStatus,
  getBannerIsCropping,
  getSelectedElementCroppingStatus,
} from '../temporary-status/temporaryStatusDucks';
import fetchNuxeo from '../api/fetchNuxeo';
import { setBannerSetExpirationDate } from '../banners/bannersActionsCreators';

export function* selectBannerProperties(type) {
  const { bannerWidth, bannerHeight } = yield select(getBannerFormat);
  const idNumber = yield select(getNextElementId(type));
  const duration = yield select(getSelectedSlideDuration);
  const slideIndex = yield select(getSelectedSlideIndex);
  return {
    bannerWidth,
    bannerHeight,
    idNumber,
    duration,
    slideIndex,
  };
}

export function* addElementSaga({ payload: elementProperties }) {
  const { bannerWidth, bannerHeight, idNumber, slideIndex, duration } = yield call(
    selectBannerProperties,
    elementProperties.type,
  );
  let createElement;
  switch (elementProperties.type) {
    case 'new-image': {
      yield put(openNewImageDialog());
      return;
    }
    case 'image': {
      createElement = createImageElement;
      break;
    }
    case 'text': {
      createElement = createTextElement;
      break;
    }
    case 'rectangle': {
      createElement = createRectangleElement;
      break;
    }
    case 'button': {
      createElement = createButtonElement;
      break;
    }
    default:
      return;
  }
  yield put(
    addedElement(createElement({ bannerWidth, bannerHeight, idNumber, duration, elementProperties }), slideIndex),
  );
}

export function* addImageElementFromComputerSaga({ payload: { file, id } }) {
  const { bannerWidth, bannerHeight, idNumber: imageIdNumber, slideIndex, duration } = yield call(
    selectBannerProperties,
    'image',
  );
  let idNumber;
  if (id !== undefined) {
    idNumber = id;
  } else {
    idNumber = imageIdNumber;
  }

  const url = URL.createObjectURL(file);
  const { width, height } = yield call(getImageSize, url);
  if (URL.revokeObjectURL) {
    URL.revokeObjectURL(url);
  }

  let imageFile = file;
  if (file.type !== 'image/gif') {
    imageFile = yield call(imageCompression, file, { maxWidthOrHeight: Math.max(width, height), fileType: file.type });
  }

  const elementProperties = {
    width,
    height,
    // fileName: name || imageFile.name, // keep in case we want the real file name
    fileName: uuidv4(),
  };

  const newImageElement = createImageElement({
    bannerWidth,
    bannerHeight,
    idNumber,
    elementProperties,
    duration,
  });

  yield put(
    // resourceId in the "callback" action will be filled by the resource manager.
    addResource(imageFile, addedElement(newImageElement, slideIndex)),
  );
  const {
    payload: { element },
  } = yield take(BANNER_ELEMENT_ADDED);
  yield put(createOptimizedResource({ element, file: imageFile }));
}

function* addImageElementFrom123RFSaga({ payload: { id, name, thumbnailUrl } }) {
  // TESTME // FIXME
  /* not used yet  */
  //step 1: load thumbnail first
  const { bannerWidth, bannerHeight, idNumber, slideIndex, duration } = yield call(selectBannerProperties, 'image');
  const { apiUrl } = yield select(getConfig);

  // Import image into Nuxeo & fetch full URL
  const url = yield importFrom123RFToNuxeo(apiUrl, id);
  const { width, height } = yield getImageSize(url);

  const elementProperties = {
    width,
    height,
    fileName: name,
    thumbnailUrl,
  };
  const newImageElement = createImageElement({
    bannerWidth,
    bannerHeight,
    idNumber,
    elementProperties,
    duration,
  });

  yield put(addedElement(newImageElement, slideIndex));

  //step2: when thumbnail is loaded, fetch high res image and load it
  const blobImage = yield call(fetchNuxeo.imageAsBlob, url);

  yield put(
    // resourceId in the "callback" action will be filled by the resource manager.
    addResource(blobImage, setElementImageResource({ id: newImageElement.id })),
  );
}

function* addImageElementFromNuxeoSaga({ payload: { uid, name, url, thumbnailUrl, width, height } }) {
  // TESTME
  /* not used yet  */
  //step 1: load thumbnail & metadata
  const { bannerWidth, bannerHeight, idNumber, slideIndex, duration } = yield call(selectBannerProperties, 'image');

  const elementProperties = {
    width,
    height,
    fileName: name,
    thumbnailUrl,
  };
  const newImageElement = createImageElement({
    bannerWidth,
    bannerHeight,
    idNumber,
    elementProperties,
    duration,
  });
  yield put(addedElement(newImageElement, slideIndex));

  //step2: fetch high res image and load it
  const blobImage = yield call(fetchNuxeo.imageAsBlob, url);
  yield put(
    // resourceId in the "callback" action will be filled by the resource manager.
    addResource(blobImage, setElementImageResource({ id: newImageElement.id }), uid),
  );
}

export function* setFontFamilySaga({ payload: { fontFamily, id } }) {
  const userFonts = yield select(getUserFonts);
  yield call(loadFont, fontFamily, userFonts);
  yield put(setElementFontFamilySuccess({ id, fontFamily }));
}

export function* elementUpdateDimensionsSaga({ payload }) {
  yield put(setElementDimensions(payload));
  yield put(updateElementStatus(payload));
  yield put(updateBannerStatus(payload));
  const element = yield select(getElementFromId(payload.id));
  const isCropping = yield select(getBannerIsCropping);
  const selectedElementId = yield select(getSelectedElementId);

  if (element.type === 'image' && !(isCropping && selectedElementId === element.id)) {
    const banners = yield select(getBannersPresent);
    const currentBannerIndex = yield select(getSelectedBannerIndex);
    const masterBannerIndex = yield select(getBannersMasterIndex);

    const elements = banners.toArray().reduce((allElements, banner, bannerIndex) => {
      if (bannerIndex !== currentBannerIndex) {
        return { ...allElements, ...(banner.toObject().elements.toObject() || {}) };
      }
      return allElements;
    }, {});
    const isElementSharingOptimizedResource =
      currentBannerIndex !== masterBannerIndex &&
      Object.keys(elements).some(elKey => {
        return elements[elKey].type === 'image' && elements[elKey].optimizedResourceId === element.optimizedResourceId;
      });

    yield put(
      createOptimizedResource({
        element: element.set(
          'optimizedResourceId',
          isElementSharingOptimizedResource ? undefined : element.optimizedResourceId,
        ),
      }),
    );
  }
}

export function* updateExpirationDateSaga({ payload: { expirationDate } }) {
  const bannerIndex = yield select(getSelectedBannerIndex);
  yield put(setBannerExpirationDate({ bannerIndex, expirationDate }));

  const isBannerMaster = yield select(isCurrentBannerMasterBanner);
  const bannerSlaveIndexes = yield select(getBannerSlavesIndexes);

  if (isBannerMaster) {
    for (let banSlaveIndex of bannerSlaveIndexes) {
      yield put(setBannerExpirationDate({ bannerIndex: banSlaveIndex, expirationDate }));
    }
    yield put(setBannerSetExpirationDate(expirationDate));
    yield put(addInfo('banners.bannerSet.expirationDate.updated'));
  } else {
    const bannerMasterIndex = yield select(getBannersMasterIndex);
    const bannerSetExpirationDate = yield select(getBannerSetExpirationDate);

    const isBannerSlave =
      Array.isArray(bannerSlaveIndexes) && bannerSlaveIndexes.some(slaveBannerId => slaveBannerId === bannerIndex);
    const toUpdate = !bannerSetExpirationDate || moment(bannerSetExpirationDate).isBefore(moment(expirationDate));

    if ((!bannerMasterIndex || isBannerSlave) && toUpdate) {
      yield put(setBannerSetExpirationDate(expirationDate));
      yield put(addInfo('banners.bannerSet.expirationDate.updated'));
    } else if (!bannerMasterIndex && !isBannerSlave) {
      const banners = yield select(getBannersPresent);
      let lateExpirationDate = null;
      banners.forEach(ban => {
        if (!lateExpirationDate || moment(ban.expirationDate).isSameOrAfter(moment(lateExpirationDate)))
          lateExpirationDate = ban.expirationDate;
      });
      if (!bannerSetExpirationDate || !moment(bannerSetExpirationDate).isSame(moment(lateExpirationDate))) {
        yield put(setBannerSetExpirationDate(lateExpirationDate));
        yield put(addInfo('banners.bannerSet.expirationDate.updated'));
      }
    }
  }
}

export function* setSlideDurationSaga({
  payload: { index, duration: newSlideDuration, oldDuration: oldSlideDuration, elements },
}) {
  try {
    if (newSlideDuration === oldSlideDuration) return;

    if (newSlideDuration === 0) {
      yield put(addError('slide.duration.not-zero'));
      yield put(setSlideDuration({ index, duration: oldSlideDuration, untrack: true }));
      return;
    }

    const slides = yield select(getBannerSlides);

    if (newSlideDuration < oldSlideDuration) {
      // check and update if needed slides transitions durations
      yield put(checkAndUpdateSlideTransitionRequested(index, newSlideDuration));

      // compare the new slide duration to the minimum it can take
      // minimum duration of an element is the sum of transitions durations + delay
      const elementsMinDurations = getElementsMinDuration(elements);

      const shortestDurationPossible = getSlideShortestDurationPossible(elementsMinDurations);

      // forbid the slide duration to be shorter than this minimum duration

      if (newSlideDuration < shortestDurationPossible) {
        yield put(
          addError('slide.duration.error', {
            min: shortestDurationPossible,
            name: elementsMinDurations
              .filter(({ minDuration }) => minDuration === shortestDurationPossible)
              .map(({ element }) => elementLabel(element))
              .join(', '),
          }),
        );
        yield put(setSlideDuration({ index, duration: oldSlideDuration, untrack: true }));
        return;
      }
      // change all the elements durations to stick to the new slide duration

      if (newSlideDuration >= shortestDurationPossible) {
        yield put(
          addWarning('slide.duration.warning.decrease', { autoHideDuration: autoHideDurations.veryLongMessage }),
        );
        for (let element of elements) {
          if (element.duration > newSlideDuration) {
            yield put(
              setElementDuration({
                id: element.id,
                duration: newSlideDuration,
                untrack: true,
              }),
            );
          }
        }
        return;
      }
    }

    if (newSlideDuration > oldSlideDuration) {
      // if the slide duration is increased, all its elements duration are increased by the difference
      const increaseDiff = newSlideDuration - oldSlideDuration;

      // if the slide is shwon on all slide, the other slides should be modified too.
      let slidesIncreasedByElementsShownInAllSlides = [];
      let longestElementDuration = 0;
      for (let element of elements) {
        const newElementDuration = element.duration + increaseDiff;
        yield put(setElementDuration({ id: element.id, duration: newElementDuration, untrack: true }));
        if (element.showOnAllSlides && newElementDuration > longestElementDuration) {
          slidesIncreasedByElementsShownInAllSlides = [];
          longestElementDuration = newElementDuration;
          for (let slide of slides) {
            longestElementDuration > slide.duration &&
              slides.indexOf(slide) !== index &&
              slidesIncreasedByElementsShownInAllSlides.push(slide.id);
          }
        }
      }
      yield put(
        addWarning('slide.duration.warning.increase', {
          autoHideDuration: autoHideDurations.veryLongMessage,
          increaseDiff,
        }),
      );
      if (slidesIncreasedByElementsShownInAllSlides.length > 0) {
        yield put(
          setSlidesDuration({
            indexes: slidesIncreasedByElementsShownInAllSlides,
            duration: longestElementDuration,
            untrack: true,
          }),
        );
      }
    }
  } catch (e) {
    console.error('setSlideDurationSaga', e);
  }
}

export function* checkAndUpdateSlideTransitionSaga({ payload: { slideIndex, newSlideDuration } }) {
  try {
    const slides = yield select(getBannerSlides);
    const repetitions = yield select(getBannerRepetitions);
    const slide = yield select(getSlideAtIndex(slideIndex));
    const slidesIds = slides.map(slide => slide.id);

    const withRepetitions = repetitions > 1;
    const slideIsLast = slidesIds.indexOf(slide.id) === slidesIds.size - 1;
    const nextSlide = solveNextSlide(slides, slideIndex, withRepetitions);

    // first, check the existing transitions with the other slides
    // enter transitions are defined in the slide
    const isTransitionSlideIn = solveEnterTransition(slide);
    // leave transitions are defined in the next slide
    const isTransitionSlideOut = solveLeaveTransitions(slideIsLast, slides.get(0), nextSlide, withRepetitions);
    // do nothing if there is no transition
    if (!isTransitionSlideIn && !isTransitionSlideOut) return;

    const totalTransitionDuration = solveTotalTransitionDuration(slide, nextSlide);

    if (isTransitionSlideIn && isTransitionSlideOut) {
      if (newSlideDuration >= totalTransitionDuration + minSlideDurationNotTransiting) return;
      // set slide transition in duration
      yield put(
        setSlideTransitionDuration({
          index: slideIndex,
          duration:
            ((newSlideDuration - minSlideDurationNotTransiting) * slide.transition.duration) / totalTransitionDuration,
          untrack: true,
        }),
      );
      // set slide transition out duration
      yield put(
        setSlideTransitionDuration({
          index: slides.indexOf(nextSlide),
          duration:
            ((newSlideDuration - minSlideDurationNotTransiting) * nextSlide.transition.duration) /
            totalTransitionDuration,
          untrack: true,
        }),
      );
    }
    if (isTransitionSlideIn && !isTransitionSlideOut) {
      if (newSlideDuration >= slide.transition.duration + minSlideDurationNotTransiting) return;
      // set slide transition in duration
      yield put(
        setSlideTransitionDuration({
          index: slideIndex,
          duration: newSlideDuration - minSlideDurationNotTransiting,
          untrack: true,
        }),
      );
    }
    if (!isTransitionSlideIn && isTransitionSlideOut) {
      if (newSlideDuration >= nextSlide.transition.duration) return;
      // set slide transition out duration
      yield put(
        setSlideTransitionDuration({
          index: slides.indexOf(nextSlide),
          duration: newSlideDuration - minSlideDurationNotTransiting,
          untrack: true,
        }),
      );
    }
    yield put(addWarning('slide.transition.duration.warning', { autoHideDuration: autoHideDurations.veryLongMessage }));
  } catch (e) {
    console.error('checkAndUpdateSlideTransitionSaga', e);
  }
}

export function* cropSaga({ payload: reset }) {
  const id = yield select(getSelectedElementId);
  const isCropping = yield select(getSelectedElementCroppingStatus);
  if (reset) {
    yield put(setElementBackToOriginalImage(id));
  } else {
    yield put(updateElementStatus({ isCropping: !isCropping }));
    yield put(updateBannerStatus({ isCropping: !isCropping }));
  }
}

export function* checkDissociateSaga(action) {
  const isSlaveBanner = yield select(isCurrentBannerSlaveBanner);
  if (isSlaveBanner) {
    let todo;
    switch (action.type) {
      case BANNER_ADD_ELEMENT_REQUESTED:
      case ELEMENT_DELETE_REQUESTED: {
        todo = 'dissociate';
        break;
      }
      case ELEMENT_CROP_REQUESTED: {
        todo = 'crop';
        break;
      }
      default:
        break;
    }
    yield put(openMasterBannerDialog(todo));
    const choice = yield take([MASTER_BANNER_DIALOG_CONFIRM_DISSOCIATE, MASTER_BANNER_DIALOG_CANCEL]);
    if (choice.type === MASTER_BANNER_DIALOG_CANCEL) return;
  }
  switch (action.type) {
    case ELEMENT_DELETE_REQUESTED: {
      const id = action.payload;
      yield put(deleteElement(id));
      return;
    }
    case BANNER_ADD_ELEMENT_REQUESTED: {
      yield call(addElementSaga, action);
      return;
    }
    case ELEMENT_CROP_REQUESTED: {
      yield call(cropSaga, action);
      return;
    }
    default:
      return;
  }
}

export function* saga() {
  yield takeLatest(BANNER_ADD_ELEMENT_REQUESTED, checkDissociateSaga);
  yield takeLatest(ELEMENT_DELETE_REQUESTED, checkDissociateSaga);
  yield takeLatest(ELEMENT_CROP_REQUESTED, checkDissociateSaga);
  yield takeLatest(SLIDE_SET_DURATION_REQUESTED, setSlideDurationSaga);
  yield takeLatest(SLIDE_CHECK_AND_UPDATE_SLIDE_TRANSITION, checkAndUpdateSlideTransitionSaga);
  yield takeEvery(BANNER_IMAGE_ELEMENT_FROM_COMPUTER_REQUESTED, addImageElementFromComputerSaga);
  yield takeLatest(BANNER_IMAGE_ELEMENT_FROM_123RF_REQUESTED, addImageElementFrom123RFSaga);
  yield takeLatest(BANNER_IMAGE_ELEMENT_FROM_NUXEO_REQUESTED, addImageElementFromNuxeoSaga);
  yield takeLatest(ELEMENT_SET_FONT_FAMILY_REQUESTED, setFontFamilySaga);
  yield takeLatest(ELEMENT_UPDATE_DIMENSIONS_REQUESTED, elementUpdateDimensionsSaga);
  yield takeLatest(BANNER_SET_EXPIRATION_DATE_REQUESTED, updateExpirationDateSaga);
}
