import {
  propertyEditorWidth,
  slidesPanelWidth,
  elementListWidth,
  menuHeight,
  tabHeight,
  appBarHeight,
  handleWidth,
} from '../reference/dimensions';
import { slidesPanelColor } from '../reference/colors';
import { fade } from '@material-ui/core/styles/colorManipulator';

const toggleElementListHeight = 40;
const bannerAnimationHeight = 80 + toggleElementListHeight - 8;
const bannerSizeHeight = 40;

const slidesStyles = theme => ({
  root: {
    backgroundColor: slidesPanelColor,
    boxShadow: theme.shadows[2],
    position: 'absolute',
    zIndex: 101,
    top: 0,
    left: 0,
    bottom: 0,
    width: slidesPanelWidth,
    right: propertyEditorWidth,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
    paddingTop: bannerAnimationHeight,
    paddingBottom: bannerSizeHeight,
  },
  foldedElementList: {
    '& $handle': {
      transform: `translateX(${-elementListWidth}px)`,
    },
  },
  folded: {
    transform: `translateX(${-slidesPanelWidth}px)`,
    '& $foldedElementList': {
      transform: `translateX(${-slidesPanelWidth - elementListWidth}px)`,
    },
    '& $handle': {
      transform: `translateX(${-elementListWidth}px)`,
    },
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: toggleElementListHeight,
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%',
    // backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontSize: 10,
    margin: 0,
  },
  toggleElementList: {
    // marginTop: -bannerAnimationHeight,
    // marginBottom: bannerAnimationHeight,
  },
  handle: {
    position: 'absolute',
    width: handleWidth,
    height: theme.spacing(6),
    right: -handleWidth - 5 - elementListWidth,
    top: 0,
    padding: '0 5px 5px 0',
    overflow: 'hidden',
    pointerEvents: 'none',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
    color: `${theme.palette.primary.main} !important`,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: handleWidth,
      height: theme.spacing(6),
      backgroundColor: slidesPanelColor,
      borderRadius: '0 4px 4px 0',
      boxShadow: theme.shadows[2],
      zIndex: -1,
    },
    '& > button': {
      width: '100%',
      height: '100%',
      borderRadius: '0 4px 4px 0',
      pointerEvents: 'auto',
      transition: theme.transitions.create(['background-color'], { duration: theme.transitions.duration.short }),
      '&:hover': {
        backgroundColor: fade(theme.palette.text.primary, theme.palette.action.hoverOpacity),
      },
    },
  },
  scrollContainer: {
    padding: 16,
    boxSizing: 'border-box',
    // height: `calc(100% - ${toggleElementListHeight}px - ${bannerAnimationHeight}px)`,
    height: '100%',
    // overflowY: 'auto',
    overflow: 'hidden', // react-perfect-scrollbar
    flexDirection: 'column',
    display: 'flex',
    '& > *': {
      flexShrink: 0,
    },
  },
  popper: {
    '& *': {
      pointerEvents: 'none',
    },
  },
  loopIcon: {
    alignSelf: 'center',
    marginRight: theme.spacing(2),
  },
  bannerAnimation: {
    position: 'fixed',
    backgroundColor: 'rgba(237,237,237,1.00)',
    top: menuHeight + tabHeight + appBarHeight + 8,
    // top: menuHeight + tabHeight + appBarHeight + toggleElementListHeight,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: bannerAnimationHeight,
    width: slidesPanelWidth,
    zIndex: 102,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
    '& > *': {
      // display: 'flex',
      // justifyContent: 'center',
      // width: '100%',
      flexShrink: 0,
    },
    '& $folded': {
      transform: `translateX(${-slidesPanelWidth}px)`,
    },
  },
  bannerSize: {
    position: 'absolute',
    backgroundColor: 'rgba(237,237,237,1.00)',
    bottom: 0,
    // top: menuHeight + tabHeight + appBarHeight + toggleElementListHeight,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    paddingLeft: 5,
    boxSizing: 'border-box',
    height: bannerSizeHeight,
    width: slidesPanelWidth,
    zIndex: 102,
    color: fade(theme.palette.text.primary, 0.54),
    '& > label': {
      width: '133%',
      fontSize: '0.9rem',
    },
  },
  playing: {
    // transform: `translateX(${slidesPanelWidth}px)`,
  },
  duration: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  durationError: {
    backgroundColor: theme.palette.error.main,
  },
  playIcon: {
    padding: 5,
  },
  select: {
    color: 'inherit',
  },
  menuSelected: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
    },
    '&$menuSelected': {
      backgroundColor: fade(theme.palette.primary.main, 0.7),
      color: '#FFF',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
      },
    },
  },
  toggleButton: {
    textTransform: 'initial',
    height: theme.spacing(4),
    color: theme.palette.action.active,
    transition: theme.transitions.create(['background-color', 'color'], { duration: theme.transitions.duration.short }),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
    },
  },
  toggleButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    background: fade(theme.palette.background.paper, 0.5),
    // border: `1px solid ${fade(theme.palette.text.primary, 0.12)}`,
    boxShadow: 'unset',
  },
  toggleButtonTooltipContainer: {
    width: '100%',
  },
  smallButtonContainer: {
    width: '100%',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  bigButton: {
    flex: 1,
  },
  bigButtonLabel: {
    '& > *': {
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
});

export default slidesStyles;
