import React from 'react';
import classNames from 'classnames';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { computeTextStyle, computeTextShadow } from '../reference/textProperties';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getElementCustomValue, getStateWithPresentBanner } from '../banner/bannerSelectors';

const styles = theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    boxSizing: 'border-box', // for rendering in animated GIF
    padding: 0,
    pointerEvents: 'none',
    transition: theme.transitions.create(['background-image'], { duration: theme.transitions.duration.short }),
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: 'inherit',
      transition: theme.transitions.create(['background-color'], { duration: theme.transitions.duration.short }),
    },
    '&:hover::after': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },
  text: {
    width: '100%',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    zIndex: 1,
    pointerEvents: 'none',
  },
  enabled: {
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  flat: {},
  verticalGradient: {
    backgroundImage:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.2))',
  },
  horizontalGradient: {
    backgroundImage:
      'linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.2))',
  },
  diagonalGradient: {
    backgroundImage:
      'linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.2))',
  },
  glossy: {
    boxShadow: 'inset 0 1px rgba(255, 255, 255, 0.4), 0 1px 2px rgba(0, 0, 0, 0.2)',
    backgroundImage:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.12) 50%, rgba(0, 0, 0, 0.04))',
  },
});

class ButtonElement extends React.Component {
  state = { styleState: {}, classesState: {}, text: '' };

  static getDerivedStateFromProps(nextProps) {
    const {
      classes,
      className,
      opacity,
      backgroundColor,
      borderWidth,
      borderColor,
      borderRadius,
      borderRadiusUnit,
      dropShadowEnabled,
      dropShadow,
      appearance,
      selected,
      animating,
    } = nextProps;

    return {
      styleState: {
        button: {
          backgroundColor,
          borderWidth,
          borderColor,
          borderRadius: `${borderRadius}${borderRadiusUnit}`,
          opacity,
          boxShadow: dropShadowEnabled && computeTextShadow(dropShadow),
        },
        text: computeTextStyle(nextProps),
      },
      classesState: {
        button: classNames(classes.root, classes[appearance], className, 'button', {
          [classes.enabled]: animating || !selected,
        }),
        text: classes.text,
      },
    };
  }

  render() {
    const { styleState, classesState } = this.state;
    const { customizable, customValue, text } = this.props;
    return (
      <button className={classesState.button} style={styleState.button} type="button">
        <div className={classesState.text} style={styleState.text}>
          {customizable ? customValue : text}
        </div>
      </button>
    );
  }
}

const makeStateToProps = () => (state, { id, bannerUniqueId }) => {
  // FIXME: this is an Hack to simulate a current banner in state, because selectors are based on the current Banner
  const fakeState = bannerUniqueId ? getStateWithPresentBanner(state, bannerUniqueId) : state;
  return {
    customValue: makeSelectorInstance(getElementCustomValue(id))(fakeState),
  };
};

export default compose(
  connect(makeStateToProps),
  withStyles(styles),
)(ButtonElement);
