import moment from 'moment';
// moment default locales
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/ja';
import 'moment/locale/de';
import 'moment/locale/ko';

import IntlMessageFormat from 'intl-messageformat';
import { takeEvery, put } from 'redux-saga/effects';
import messages_en from './en.json';
import messages_fr from './fr.json';
import { locize, locale, availableLocales } from './index';

// Utils
let messages = messages_en;
const fetchTranslation = async language =>
  await fetch(locize(language))
    .then(res => {
      if (res.ok) return res.json();
      throw new Error(`cant fetch ${language} translations from locize`);
    })
    .catch(err => console.error(err));

// init moment locale
moment.locale(locale);

// Generate format
const getLongDateFormat = locale => moment.localeData(locale).longDateFormat('L');

export const newIntlMessage = (property, options = {}) =>
  new IntlMessageFormat(messages[property], locale).format(options); // TESTME

// Actions
const I18N_LOAD_TRANSLATIONS_REQUESTED = 'adbuilder/I18N_LOAD_TRANSLATIONS_REQUESTED';
const I18N_SAVE_TRANSLATIONS = 'adbuilder/I18N_SAVE_TRANSLATIONS';
const I18N_CHANGE_LOCALE = 'adbuilder/I18N_CHANGE_LOCALE';

// Actions creators
export const loadTranslations = () => ({
  type: I18N_LOAD_TRANSLATIONS_REQUESTED,
});

export const saveTranslations = translations => ({
  type: I18N_SAVE_TRANSLATIONS,
  payload: translations,
});

export const toggleLocale = () => ({
  type: I18N_CHANGE_LOCALE,
});

// Selectors
export const isTranslationLoaded = ({ i18n }) => i18n.transationLoaded;
export const getTranslationState = ({ i18n }) => JSON.stringify(i18n.translations[i18n.locale]);
export const getI18nMessages = ({ i18n }) => i18n.translations;
export const getLocaleState = ({ i18n }) => i18n.locale;
export const getDateFormat = ({ i18n }) => i18n.dateFormat;

// Sagas
export function* loadTranslationsSaga() {
  try {
    const mainTranslation = yield fetchTranslation(locale);
    const english = yield fetchTranslation('en');

    const translationConsolidated = {};

    for (let transKey of Object.keys(english)) {
      translationConsolidated[transKey] = mainTranslation[transKey] || english[transKey];
    }

    yield put(
      saveTranslations({
        [locale]: translationConsolidated,
        en: english,
        dateFormat: getLongDateFormat('en'),
      }),
    );

    messages = translationConsolidated;

    if (process.env.NODE_ENV === 'development') {
      const allTranslations = {};
      for (let language of availableLocales.filter(loc => loc !== locale && loc !== 'en')) {
        const translation = yield fetchTranslation(language);
        allTranslations[language] = translation;
      }
      yield put(saveTranslations(allTranslations));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* saga() {
  yield takeEvery(I18N_LOAD_TRANSLATIONS_REQUESTED, loadTranslationsSaga);
}

// Reducer

export const initialState = {
  translations: {
    en: messages_en,
    de: messages_en,
    fr: messages_fr,
  },
  locale,
  dateFormat: getLongDateFormat(locale),
  transationLoaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case I18N_SAVE_TRANSLATIONS: {
      return {
        ...state,
        translations: {
          ...state.translations,
          ...action.payload,
        },
        transationLoaded: true,
      };
    }
    case I18N_CHANGE_LOCALE: {
      const currentLocaleIndex = availableLocales.findIndex(locale => locale === state.locale);
      const nextLocaleIndex = currentLocaleIndex === availableLocales.length - 1 ? 0 : currentLocaleIndex + 1;
      return {
        ...state,
        locale: availableLocales[nextLocaleIndex],
        dateFormat: getLongDateFormat(availableLocales[nextLocaleIndex]),
      };
    }
    default:
      return state;
  }
};

export default reducer;
