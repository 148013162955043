export const saveUrl = (() => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';

  return (fileName, url) => {
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  };
})();
