import React from 'react';
import { connect } from 'react-redux';
import { EntitiesTree, EntitiesNodeContentRenderer } from 'drb-commons-front';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { successGetEntities, handleEntityClick } from './entitiesActionCreators';
import { getUserEntityId } from '../shared-selectors/sharedSelectors';
import { getSelectedEntityIdForSave, getEntities } from './entitiesSelectors';
import {
  getBannerSetOwnerEntityId,
  isEntityNotSelectable,
  isEntitySelectable,
  isEntitySetSelected,
} from '../banners/bannersSelectors';

const Entity = ({
  currentEntityId,
  selectedEntityId,
  notSelectable,
  checkBoxChecked,
  checkBoxesActivated,
  showCheckBox,
  handleEntityClick,
  ...props
}) => (
  <EntitiesNodeContentRenderer
    {...props}
    currentEntityId={currentEntityId}
    selectedEntityId={selectedEntityId}
    notSelectable={notSelectable}
    showCheckBox={showCheckBox}
    checkBoxChecked={checkBoxChecked}
    handleEntityClick={handleEntityClick}
  />
);

const makeStateToEntityProps = (state, { node: { id: entityId } }) => ({
  currentEntityId: makeSelectorInstance(getBannerSetOwnerEntityId)(state),
  selectedEntityId: makeSelectorInstance(getSelectedEntityIdForSave)(state),
  notSelectable: makeSelectorInstance(isEntityNotSelectable(entityId))(state),
  checkBoxChecked: makeSelectorInstance(isEntitySetSelected(entityId))(state),
  showCheckBox: makeSelectorInstance(isEntitySelectable(entityId))(state),
});

const dispatchToEntityProps = {
  handleEntityClick,
};

const EntityInjected = connect(
  makeStateToEntityProps,
  dispatchToEntityProps,
)(Entity);

const Entities = ({ entities, rootEntityId, setEntities }) => (
  <EntitiesTree
    entities={entities}
    rootEntityId={rootEntityId}
    updateEntities={setEntities}
    nodeContentRenderer={EntityInjected}
    filter="active"
  />
);

const makeStateToProps = () => state => ({
  entities: makeSelectorInstance(getEntities)(state),
  rootEntityId: makeSelectorInstance(getUserEntityId)(state),
});

const dispatchToProps = {
  setEntities: successGetEntities,
};

export default connect(
  makeStateToProps,
  dispatchToProps,
)(Entities);
