import { Record, is, Set } from 'immutable';
import { ActionTypes } from 'redux-undo';
import { getBannerNumberOfSlides } from './banner/bannerSelectors';
import { isCurrentBannerMasterBanner } from './banners/bannersSelectors';
import {
  ensureCurrentSelections,
  getCurrentSelections,
  forceSelection,
  ensurePastSelections,
  ensureFutureSelections,
} from './temporary-status/temporaryStatusDucks';

export const bannerMasterMiddleware = ({ getState }) => {
  return next => action => {
    if (isCurrentBannerMasterBanner(getState())) {
      return next({
        ...action,
        fromMasterBanner: true,
      });
    }
    return next({
      ...action,
      fromMasterBanner: false,
    });
  };
};

const SelectionsRecord = Record({
  selectedElementId: null,
  selectedElementIds: Set(),
  selectedSlideIndex: 0,
  selectedBannerIndex: 0,
});

export const ensureCurrentSelectionMiddleware = ({ dispatch, getState }) => {
  return next => action => {
    const currentSelections = SelectionsRecord(getCurrentSelections(getState()));
    const initSelections = SelectionsRecord();
    if (is(currentSelections, initSelections)) {
      return next({
        ...action,
        maxAllowedSelectedSlideIndex: getBannerNumberOfSlides(getState()) - 1,
      });
    }
    let ensuredSelections = SelectionsRecord(ensureCurrentSelections(getState()));
    if (action.type === ActionTypes.UNDO) {
      ensuredSelections = SelectionsRecord(ensurePastSelections(getState()));
    }
    if (action.type === ActionTypes.REDO) {
      ensuredSelections = SelectionsRecord(ensureFutureSelections(getState()));
    }
    if (!is(ensuredSelections, currentSelections)) {
      next(forceSelection(ensuredSelections));
    }
    return next({
      ...action,
      maxAllowedSelectedSlideIndex: getBannerNumberOfSlides(getState()) - 1,
    });
  };
};
