import { googleFont, customFont, userFont } from './params';

export const cleanBanner = (banner, userFontNames, googleFontNames, customFontNames, userFonts) => {
  const jsBanner = banner.toJS();

  // Deleting transient status info
  delete jsBanner.animationStatus;
  delete jsBanner.selectedElementId;
  delete jsBanner.selectedElementIds;
  delete jsBanner.selectedSlideIndex;
  delete jsBanner.status;
  delete jsBanner.zoom;
  Object.keys(jsBanner.elements).forEach(elId => {
    delete jsBanner.elements[elId].status;
  });
  jsBanner.slides.forEach(slide => delete slide.slideIsPlayingAnimation);

  jsBanner.fonts = [].concat(
    userFontNames.map(name => ({ name, type: userFont, id: userFonts[name] })).toArray(),
    googleFontNames.map(name => ({ name, type: googleFont })).toArray(),
    customFontNames.map(name => ({ name, type: customFont })).toArray(),
  );

  return jsBanner;
};
