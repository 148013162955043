import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ListItem, ListItemIcon, ListItemText, Tooltip, IconButton, withStyles } from '@material-ui/core';
import LensIcon from '@material-ui/icons/Lens';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import classNames from 'classnames';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { compose, onlyUpdateForKeys, withProps } from 'recompose';

import elementIcons from '../reference/elementIcons';

import { getResourceUrl } from '../resources/resourcesDucks';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { elementLabel } from '../element/elementLabel';
import styles from './elementListStyles';
import {
  getElementIsCustomizable,
  getElementType,
  getElementText,
  getElementFileName,
  getElementThumbnailUrl,
  getElementResourceId,
  getElementBackgroundColor,
  getElementShowOnAllSlides,
  isElementDisplayedAsSelectedInElementList,
  getElementCustomProperty,
  getElementCustomValue,
} from '../banner/bannerSelectors';
import { isCurrentBannerSlaveBanner } from '../banners/bannersSelectors';
import { isBannerPlayingHTMLAnimation } from '../temporary-status/temporaryStatusDucks';

const stopPropagation = e => e.stopPropagation();

const DragHandle = compose(
  injectIntl,
  SortableHandle,
)(props => (
  <Tooltip title={props.intl.formatMessage({ id: 'app.elements.drag' })}>
    <DragIndicatorIcon {...props} />
  </Tooltip>
));

class Image extends React.Component {
  state = {
    backgroundImage: `url("${this.props.thumbnailUrl || getResourceUrl(this.props.resourceId, 'ListedElement')}")`,
  };

  render() {
    return <div className={this.props.classes.thumbnail} style={this.state} />;
  }
}

const EnhancedImage = withStyles(styles)(Image);

class Background extends React.Component {
  state = {
    backgroundColor: this.props.backgroundColor,
  };

  static getDerivedStateFromProps(nextProps) {
    return { backgroundColor: nextProps.backgroundColor };
  }

  render() {
    return <div className={this.props.classes.rectangleThumbnail} style={this.state} />;
  }
}

const EnhancedBackground = withStyles(styles)(Background);

class Icon extends React.Component {
  state = { root: this.props.classes.listItemIcon };

  render() {
    const { classes, thumbnailUrl, resourceId, backgroundColor, type } = this.props;
    const renderIcon = type => {
      if (type === 'image') {
        return <EnhancedImage thumbnailUrl={thumbnailUrl} resourceId={resourceId} />;
      }
      if (type === 'rectangle') {
        return (
          <div className={classes.thumbnail}>
            <EnhancedBackground backgroundColor={backgroundColor} />
            {elementIcons[type]}
          </div>
        );
      }
      return <div className={classes.thumbnail}>{elementIcons[type]}</div>;
    };
    return <ListItemIcon classes={this.state}>{renderIcon(type)}</ListItemIcon>;
  }
}

const EnhancedIcon = compose(
  withStyles(styles),
  onlyUpdateForKeys(['thumbnailUrl', 'resourceId', 'backgroundColor']),
)(Icon);

const Label = ({ label, classes }) => (
  <ListItemText className={classes.itemText} disableTypography>
    {label}
  </ListItemText>
);

const EnhancedLabel = compose(
  withStyles(styles),
  onlyUpdateForKeys(['label']),
)(Label);

class ListedElement extends React.Component {
  state = {
    classesState: {
      listItem: { root: this.props.classes.item },
      lensIcon: { root: this.props.classes.allSlidesIcon },
      showOnAllSlides: { root: this.props.classes.noMargin },
    },
  };
  onClick = event => {
    event.stopPropagation();
    if (this.props.bannerIsPlayingHTMLAnimation) {
      event.preventDefault();
      return;
    }
    this.props.onItemClick(this.props.id);
  };

  render() {
    const {
      thumbnailUrl,
      type,
      element,
      resourceId,
      backgroundColor,
      showOnAllSlides,
      selected,
      onMenuOpen,
      classes,
      intl,
      isBannerSlave,
      customValue,
    } = this.props;
    const { classesState } = this.state;
    return (
      <ListItem
        button
        onClick={this.onClick}
        classes={classesState.listItem}
        className={classNames({ [classes.selectedItem]: selected })}
      >
        <DragHandle className={classes.dragHandle} />
        <EnhancedIcon
          thumbnailUrl={thumbnailUrl}
          type={type}
          resourceId={resourceId}
          backgroundColor={backgroundColor}
        />
        <EnhancedLabel label={elementLabel(element, customValue)} />
        {showOnAllSlides && (
          <ListItemIcon classes={classesState.showOnAllSlides}>
            <LensIcon classes={classesState.lensIcon} />
          </ListItemIcon>
        )}
        {!isBannerSlave && (
          <Tooltip title={intl.formatMessage({ id: 'app.elements.duplicate' })}>
            <div className={classes.duplicateButton}>
              <IconButton onClick={onMenuOpen} onMouseDown={stopPropagation}>
                {elementIcons.duplicate}
              </IconButton>
            </div>
          </Tooltip>
        )}
      </ListItem>
    );
  }
}

const makeStateToProps = () => (state, { id }) => ({
  selected: makeSelectorInstance(isElementDisplayedAsSelectedInElementList(id))(state),
  bannerIsPlayingHTMLAnimation: makeSelectorInstance(isBannerPlayingHTMLAnimation)(state),
  customizable: makeSelectorInstance(getElementIsCustomizable(id))(state),
  customValue: makeSelectorInstance(getElementCustomValue(id))(state),
  type: makeSelectorInstance(getElementType(id))(state),
  text: makeSelectorInstance(getElementText(id))(state),
  fileName: makeSelectorInstance(getElementFileName(id))(state),
  thumbnailUrl: makeSelectorInstance(getElementThumbnailUrl(id))(state),
  resourceId: makeSelectorInstance(getElementResourceId(id))(state),
  backgroundColor: makeSelectorInstance(getElementBackgroundColor(id))(state),
  showOnAllSlides: makeSelectorInstance(getElementShowOnAllSlides(id))(state),
  property: makeSelectorInstance(getElementCustomProperty(id))(state),
  isBannerSlave: makeSelectorInstance(isCurrentBannerSlaveBanner)(state),
});

export default compose(
  SortableElement,
  connect(makeStateToProps),
  withProps(({ id, property, text, customizable, customValue, fileName, type }) => ({
    element: { id, property, text, customizable, customValue, fileName, type },
  })),
  injectIntl,
  withStyles(styles),
)(ListedElement);
