import { getResourceUrl } from '../../resources/resourcesDucks';
import { objectUrlToBlob } from './objectUrlToBlob';
import { fontsFolderName } from '../../reference/filesNames';
import { availableFonts } from '../../resources/fonts';

export const zipFontResources = (userFontNames, userFonts, zip, customFontNames) => {
  // Font resources
  const fontsZip = zip.folder(fontsFolderName);
  userFontNames.forEach(fontName => {
    const resourceId = userFonts[fontName];
    fontsZip.file(`${resourceId}/${fontName}`, objectUrlToBlob(getResourceUrl(resourceId, 'zipFontResources')));
  });
  if (customFontNames) {
    customFontNames.forEach(fontName => {
      const fontUrl = availableFonts.filter(font => font.name === fontName)[0].fontUrl;
      fontsZip.file(`${fontName}/${fontName}`, objectUrlToBlob(fontUrl));
    });
  }
};
