export const EXPORT_BANNERSETS_DIALOG_OPEN = 'adbuilder/EXPORT_BANNERSETS_DIALOG_OPEN';
export const EXPORT_BANNERSETS_DIALOG_CLOSE = 'adbuilder/EXPORT_BANNERSETS_DIALOG_CLOSE';
export const EXPORT_TO_IMAGES_REQUESTED = 'adbuilder/EXPORT_TO_IMAGES_REQUESTED';
export const EXPORT_TO_ANIMATED_GIF_REQUESTED = 'adbuilder/EXPORT_TO_ANIMATED_GIF_REQUESTED';
export const EXPORT_TO_HTML_ANIMATION_REQUESTED = 'adbuilder/EXPORT_TO_HTML_ANIMATION_REQUESTED';
export const EXPORT_TO_ZIP_REQUESTED = 'adbuilder/EXPORT_TO_ZIP_REQUESTED';
export const EXPORT_BANNERSETS_LOAD_PROGRESS_BAR = 'adbuilder/EXPORT_BANNERSETS_LOAD_PROGRESS_BAR';
export const EXPORT_BANNERSETS_SET_PROGRESS = 'adbuilder/EXPORT_BANNERSETS_SET_PROGRESS';
export const EXPORT_BANNERSETS_PROGRESS_BAR_LOADED = 'adbuilder/EXPORT_BANNERSETS_PROGRESS_BAR_LOADED';
export const EXPORT_BANNERSETS_RENDER_SLIDES = 'adbuilder/EXPORT_BANNERSETS_RENDER_SLIDES';
export const EXPORT_TO_HTML_ANIMATION = 'adbuilder/EXPORT_TO_HTML_ANIMATION';
export const EXPORT_BANNERSETS_RENDER_CURRENT_SCENE = 'adbuilder/EXPORT_BANNERSETS_RENDER_CURRENT_SCENE';
export const EXPORT_BANNERSETS_RENDER_BANNERS_FOR_ANIMATED_GIF =
  'adbuilder/EXPORT_BANNERSETS_RENDER_BANNERS_FOR_ANIMATED_GIF';
export const EXPORT_BANNERSETS_ITEM_RENDERED_FOR_EXPORT = 'adbuilder/EXPORT_BANNERSETS_ITEM_RENDERED_FOR_EXPORT';
export const EXPORT_BANNERSETS_DONE = 'adbuilder/EXPORT_BANNERSETS_DONE';
