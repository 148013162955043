import React from 'react';
import TextFieldForElements from '../TextFieldForElements';

class ElementsPositionX extends React.Component {
  render() {
    const { shouldUpdateWithDiff } = this.props;
    return (
      <TextFieldForElements
        property="x"
        formattedMessage="properties.position.x"
        shouldUpdateWithDiff={shouldUpdateWithDiff}
      />
    );
  }
}

export default ElementsPositionX;
