import { Map } from 'immutable';
// the goal is not to double the verification functions, and to handle unique element as well as Maps of elements in one go.
// for that, if we have a solo element, we convert it to a Map
export const checkIfIsMapAndConvertIfNeeded = elementOrElementsToCheck => {
  if (Map.isMap(elementOrElementsToCheck)) {
    // if we have a Map, it means we already have multiple elements
    return elementOrElementsToCheck;
  } else {
    // if we don't, it means we have a single element
    const element = elementOrElementsToCheck;
    return Map({
      [element.id]: element,
    });
  }
};
