import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { DatePicker } from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';

import makeSelectorInstance from '../reference/makeSelectorInstance';
import { setBannerExpirationDateUpdate } from '../banner/bannerActionsCreators';
import { getDateFormat } from '../i18n/i18nDucks';
import {
  getBannerSetExpirationDate,
  getBannerSetCreationDate,
  isCurrentBannerMasterBanner,
} from '../banners/bannersSelectors';
import { getBannerExpirationDate } from '../banner/bannerSelectors';
import { getBanners } from '../shared-selectors/sharedSelectors';

const BannerExpirationDatePicker = ({ bannerExpirationDate, format, updateBannerExpirationDate }) => {
  const updateDate = date => {
    updateBannerExpirationDate(moment(date).toDate());
  };

  return (
    <DatePicker
      value={bannerExpirationDate}
      onChange={updateDate}
      variant="inline"
      format={format}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <CalendarTodayIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      autoOk
      hideTabs
    />
  );
};

const makeStateToProps = () => {
  const stateToProps = state => {
    const isMaster = makeSelectorInstance(isCurrentBannerMasterBanner)(state);
    const banners = makeSelectorInstance(getBanners)(state);
    const creationDate = makeSelectorInstance(getBannerSetCreationDate)(state) || new Date();
    let defaultBannerExpirationDate =
      isMaster || banners.length === 1
        ? makeSelectorInstance(getBannerSetExpirationDate)(state)
        : makeSelectorInstance(getBannerExpirationDate)(state);
    if (!defaultBannerExpirationDate) {
      // default date one year after banner creation
      defaultBannerExpirationDate = moment(creationDate)
        .add(1, 'y')
        .toDate();
    }
    return {
      format: makeSelectorInstance(getDateFormat)(state),
      bannerExpirationDate: defaultBannerExpirationDate,
    };
  };
  return stateToProps;
};

const dispatchToProps = {
  updateBannerExpirationDate: setBannerExpirationDateUpdate,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
)(BannerExpirationDatePicker);
