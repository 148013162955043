import React from 'react';
import { compose } from 'recompose';
import { Grid, FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ToggleButtonGroup } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import elementIcons from '../reference/elementIcons';
import { getBannerFormat } from '../shared-selectors/sharedSelectors';
import { getBannerBgColor } from '../banner/bannerSelectors';
import { setBannerBackgroundColor } from '../banner/bannerActionsCreators';
import { checkIfCanExistBannerMaster } from '../banners/bannersSelectors';
import { openFormatSelectorDialog } from '../banner-format-selector/bannerFormatSelectorDucks';
import RgbaColorPicker from '../components/ColorPicker/RgbaColorPicker';
import BannerMaster from './properties-banner/BannerMaster';
import BannerDissociateFromMaster from './properties-banner/BannerDissociateFromMaster';
import ExpansionPanel from './ExpansionPanel';
import ToggleButtonWithTooltip from '../components/ToggleButton/ToggleButtonWithTooltip';
import BannerSetThumbnail from '../menu/BannerSetThumbnail';
import { styles } from './propertyEditorStyles';
import BannerExpirationDatePicker from '../menu/BannerExpirationDatePicker';

const PropertyEditorBanner = ({
  classes,
  setBannerBackgroundColor,
  format,
  backgroundColor,
  openFormatSelectorDialog,
  canExistBannerMaster,
}) => (
  <PerfectScrollbar
    className={classes.scrollContainer}
    options={{
      suppressScrollX: true,
      handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
      wheelSpeed: 0.8,
      wheelPropagation: true,
    }}
  >
    <ExpansionPanel title={<FormattedMessage id="properties.banner" />}>
      <Grid container spacing={2} alignItems="center">
        {canExistBannerMaster && (
          <React.Fragment>
            <Grid item xs={12}>
              <BannerMaster />
            </Grid>
            <Grid item xs={12}>
              <BannerDissociateFromMaster />
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel shrink classes={{ shrink: classes.labelShrink }}>
              <FormattedMessage id="properties.background-color" />
            </InputLabel>
            <RgbaColorPicker
              enableAlpha={false}
              placement="bottomLeft"
              value={backgroundColor}
              initialColor={backgroundColor}
              onChange={color => setBannerBackgroundColor(color)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth classes={{ root: classes.formatButtonRoot }}>
            <InputLabel shrink classes={{ shrink: classes.labelShrink }}>
              <FormattedMessage id="properties.dimension" />
            </InputLabel>
            <ToggleButtonGroup
              classes={{ root: classes.toggleButtons }}
              selected
              value={[]}
              // onChange={this.onCropClick}
            >
              <ToggleButtonWithTooltip
                classes={{
                  root: classes.formatButton,
                  container: classes.toggleButtonTooltipContainer,
                  label: classes.labelSpaceBetween,
                  selected: classes.toggleButtonsSelected,
                }}
                value="format"
                noFlex={false}
                tooltip={<FormattedMessage id="app.menu.bannerChangeDimensions" />}
                onClick={() => openFormatSelectorDialog({ isForChangingFormat: true })}
              >
                <React.Fragment>
                  {elementIcons.format}
                  <span>
                    {format.bannerWidth} x {format.bannerHeight}
                  </span>
                </React.Fragment>
              </ToggleButtonWithTooltip>
            </ToggleButtonGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormHelperText>
            <FormattedMessage id="properties.bannerSet.thumbnail" />
          </FormHelperText>
          <BannerSetThumbnail thumbnailFor="banner" />
        </Grid>
        <Grid item xs={12}>
          <FormHelperText>
            <FormattedMessage id="properties.banner-expirationDate" />
          </FormHelperText>
          <BannerExpirationDatePicker />
        </Grid>
      </Grid>
    </ExpansionPanel>
  </PerfectScrollbar>
);

const makeStateToProps = () => state => ({
  format: makeSelectorInstance(getBannerFormat)(state),
  backgroundColor: makeSelectorInstance(getBannerBgColor)(state),
  canExistBannerMaster: makeSelectorInstance(checkIfCanExistBannerMaster)(state),
});

const dispatchToProps = {
  setBannerBackgroundColor,
  openFormatSelectorDialog,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  withStyles(styles),
  React.memo,
)(PropertyEditorBanner);
