import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementDropShadowEnabled } from '../../banner/bannerSelectors';
import { setElementShadowEnabled } from '../../banner/bannerActionsCreators';
import CheckboxDoped from '../../components/CheckboxDoped';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

class ElementDropShadowToggle extends React.Component {
  render() {
    const { setElementShadowEnabled, dropShadowEnabled, id } = this.props;
    return (
      <CheckboxDoped
        toggleFunction={setElementShadowEnabled}
        checked={dropShadowEnabled}
        intlId="properties.shadow"
        id={id}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  dropShadowEnabled: makeSelectorInstance(getSelectedElementDropShadowEnabled)(state),
});

const dispatchToProps = {
  setElementShadowEnabled,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
)(ElementDropShadowToggle);
