import JSZip from 'jszip';
import moment from 'moment';
import { doWithToken } from 'drb-commons-front';
import { bannerFromJS } from '../../reference/records';
import { importResourcesFromNuxeo } from './importResourcesFromNuxeo';
import { importAllFonts } from './importAllFonts';
import { killCache } from './killCache';
import { pngType, jpegType } from '../../reference/importParams';
import { originalResourcesZipName } from '../../reference/filesNames';

export const importBannerSetFromNuxeo = nuxeoBannerSet =>
  doWithToken(async token => {
    try {
      const {
        metadataUrl,
        attachments,
        workspacePath,
        name,
        uid,
        lockOwner,
        permissions,
        creationDate,
        validity, // to override bannerset expirationDate from metadata
      } = nuxeoBannerSet;

      // 1. import metadata...
      const bannersMetadataState = await fetch(killCache(metadataUrl), {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
        credentials: 'omit', // trying to fix the AD-358 bug
      }).then(res => res.json());

      // ...including banners...
      const banners = bannersMetadataState.banners.map(banner => bannerFromJS(banner));

      // ...and bannerset...
      const bannerSetInfos = {
        ...bannersMetadataState.bannerSet,
        name,
        uid,
        lockOwner,
        workspacePath,
        permissions,
        creationDate,
        expirationDate: moment(validity, 'YYYY-MM-DDTHH:mm.Z').isValid()
          ? validity
          : bannersMetadataState.bannerSet.expirationDate,
      };
      ///...and original images...
      const originalImageNuxeoData = attachments.filter(({ file: { name } }) => name === originalResourcesZipName)[0];
      let originalImagesZipUrl = null;
      let originalImagesUnzipped = null;

      if (Boolean(originalImageNuxeoData)) {
        originalImagesZipUrl = originalImageNuxeoData.file.data;
        if (originalImagesZipUrl) {
          const originalImagesZip = await fetch(killCache(originalImagesZipUrl), {
            headers: new Headers({
              Authorization: `Bearer ${token}`,
            }),
            credentials: 'omit', // trying to fix the AD-358 bug
          })
            .then(res => res.blob())
            .catch(err => {
              console.error(err);
              return null;
            });

          if (originalImagesZip) {
            originalImagesUnzipped = await JSZip.loadAsync(originalImagesZip);
          }
        }
      }

      // 2. import and unzip players
      let unzippedPlayers = [];
      const playersUrl = attachments
        .filter(({ file }) => !(file['mime-type'] === jpegType || file['mime-type'] === pngType))
        .filter(({ file }) => file['name'] !== originalResourcesZipName)
        .map(({ file: { data: url } }) => url);

      for (const url of playersUrl) {
        const playerZip = await fetch(killCache(url), {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
          }),
          credentials: 'omit', // trying to fix the AD-358 bug
        }).then(res => res.blob());
        const unzippedPlayer = await JSZip.loadAsync(playerZip);
        unzippedPlayers.push(unzippedPlayer);
      }

      // 3. import resources (thumbnails and images)
      const resources = await importResourcesFromNuxeo(
        attachments,
        banners,
        bannerSetInfos,
        unzippedPlayers,
        originalImagesUnzipped,
        token,
      );

      // 4. import fonts
      const fonts = await importAllFonts(unzippedPlayers);

      return { banners, bannerSetInfos, resources, fonts };
    } catch (e) {
      console.error('error while importing bannerset from nuxeo', e);
    }
  });
