import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { Line } from 'rc-progress';
import classnames from 'classnames';
import keyboardShortcuts from '../reference/keyboardShortcuts';
import { imageTypes, HTML, ZIP } from '../reference/exportParams';

import makeSelectorInstance from '../reference/makeSelectorInstance';
import { isExportDialogOpen, getBannerPresent } from '../shared-selectors/sharedSelectors';
import { getBannerUniqueIdentification } from '../banner/bannerSelectors';
import { getBannerSetName, getBannersPresent } from '../banners/bannersSelectors';
import {
  getExportDialogKey,
  getToggleProgressBar,
  getNumberOfFramesToRender,
  getProgress,
  isExporting,
} from './exportSelectors';
import {
  closeExportDialog,
  exportBannersToImages,
  // exportBannersToAnimatedGIF,
  exportBannersToHTMLAnimation,
  exportBannersToZIP,
  progressBarHasLoaded,
} from './exportDucks';
import { formatI18nBannerNameId } from '../banner/bannerUtils';

const showFirstColumn = false;

const styles = theme => ({
  root: {
    maxWidth: 750,
  },
  dialogContent: {
    padding: 16,
    boxSizing: 'border-box',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'flex-start',
    '& > *': {
      padding: 16,
      boxSizing: 'border-box',
    },
    // width: 700,
  },
  paperPadding: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  noPadding: {
    padding: 0,
  },
  leftColumn: {
    width: '100%',
  },
  rightColumn: {
    width: '100%',
  },
  formGroup: {
    width: '100%',
  },
  cell: {
    width: '100%',
    margin: 0,
  },
  leftColHeader: {
    width: '100%',
    border: '1px solid black',
    margin: 0,
    '&> *': {},
  },
  rightColHeader: {
    width: '100%',
    border: '1px solid black',
    borderLeft: 'none',
    margin: 0,
    '&> *': {},
  },
  labelLeft: {
    marginLeft: showFirstColumn ? 50 : 10,
    flexGrow: 1,
    textAlign: 'left',
  },
  labelRight: {
    marginLeft: 70,
    textAlign: 'left',
    flexGrow: 1,
  },
  item: {
    marginBottom: 30,
  },
  imageTypeActionGroup: {
    alignItems: 'center',
    padding: '10px 16px 0',
    width: '100%',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  radioButton: {
    padding: '0 12px',
  },
  bannersetNameLabel: {
    height: 37,
  },
  hideCheckBoxes: {
    visibility: 'hidden',
  },
});

class ExportDialog extends React.PureComponent {
  state = {
    bannersForSlidesConversionToImage: {
      [getBannerUniqueIdentification({ banner: this.props.banner })]: true,
    },
    fileName: this.props.originalBannerSetName,
    imageType: imageTypes[0],
    progressBar: undefined,
  };

  componentDidUpdate() {
    if (this.props.toggleProgressBar && !this.progressOngoing) {
      this.progressOngoing = true;
      this.props.progressBarHasLoaded();
    }

    if (this.props.progress !== this.state.progressBar && this.progressOngoing) {
      this.setState({ progressBar: this.props.progress });
    }
  }

  componentDidMount() {
    if (this.props.open) {
      window.addEventListener('keydown', this.handleShortcuts);
      window.addEventListener('keyup', this.handleShortcuts);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleShortcuts);
    window.removeEventListener('keyup', this.handleShortcuts);
    window.clearInterval(this.interval);
  }
  handleShortcuts = e => {
    if (keyboardShortcuts.cancel.includes(e.key)) {
      this.props.onCloseDialog();
    }
  };

  handleClose = () => {
    if (this.props.toggleProgressBar) return;
    this.props.onCloseDialog();
  };

  renderActions = () => {
    const {
      onCloseDialog,
      exportBannersToImages,
      // exportBannersToAnimatedGIF,
      exportBannersToHTMLAnimation,
      exportBannersToZIP,
    } = this.props;

    const { bannersForSlidesConversionToImage, fileName, imageType } = this.state;
    const bannersToConvertSlidesToImage = Object.keys(bannersForSlidesConversionToImage).filter(
      key => bannersForSlidesConversionToImage[key],
    );

    return (
      <DialogActions>
        <Button onClick={onCloseDialog}>
          <FormattedMessage id="alert.cancel" />
        </Button>
        <Button
          color="primary"
          disabled={!fileName || bannersToConvertSlidesToImage.length === 0}
          onClick={() => {
            switch (imageType) {
              // case GIF:
              //   exportBannersToAnimatedGIF(bannersToConvertSlidesToImage, fileName);
              //   break;
              case HTML:
                exportBannersToHTMLAnimation(bannersToConvertSlidesToImage, fileName);
                break;
              case ZIP:
                exportBannersToZIP(bannersToConvertSlidesToImage, fileName);
                break;
              default:
                exportBannersToImages(bannersToConvertSlidesToImage, fileName, imageType);
                break;
            }
          }}
        >
          <FormattedMessage id="banners.export-dialog.export" values={{ imageType }} />
        </Button>
      </DialogActions>
    );
  };

  renderProgressBar = () => {
    const { theme } = this.props;
    const { progressBar } = this.state;

    return (
      <DialogContent>
        {isNaN(progressBar) ? (
          <span>{progressBar}</span>
        ) : (
          <React.Fragment>
            <span>{progressBar} %</span>
            <Line percent={progressBar} strokeWidth="4" strokeColor={theme.palette.primary.main} />
          </React.Fragment>
        )}
      </DialogContent>
    );
  };

  renderContent = () => {
    const { classes, banners, intl } = this.props;
    const { bannersForSlidesConversionToImage, fileName, imageType } = this.state;
    const allowBannerSelect = true;

    const allBannersChecked = {};
    banners.forEach(banner => (allBannersChecked[getBannerUniqueIdentification({ banner })] = true));
    const allChecked =
      Object.keys(bannersForSlidesConversionToImage).filter(
        banner => bannersForSlidesConversionToImage[banner] === true,
      ).length === banners.size;

    return (
      <React.Fragment>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={4}>
              <FormattedMessage id="banners.export-dialog.export-format" />
            </Grid>
            <Grid item xs={8}>
              <RadioGroup
                aria-label={intl.formatMessage({ id: 'banners.export-dialog.export-format' })}
                name="image format"
                className={classes.radioGroup}
                value={imageType}
                onChange={(e, imageType) => this.setState({ imageType })}
              >
                {imageTypes.map(type => (
                  <FormControlLabel
                    key={type}
                    value={type}
                    control={<Radio color="primary" classes={{ root: classes.radioButton }} />}
                    label={intl.formatMessage({ id: `banners.export-dialog.export-format.${type}` })}
                  />
                ))}
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={6} classes={{ item: classes.bannersetNameLabel }}>
              <FormattedMessage id="banners.export-dialog.set-export-name" />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onKeyDown={e => e.stopPropagation()}
                onKeyUp={e => e.stopPropagation()}
                type="text"
                label={intl.formatMessage({ id: 'banners.export-dialog.export-name' })}
                value={fileName}
                fullWidth
                margin="dense"
                id="standard-with-placeholder"
                onChange={e => this.setState({ fileName: e.target.value })}
              />
            </Grid>
          </Grid>
          {allowBannerSelect && (
            <Grid container alignItems="flex-end">
              <Grid item xs={12} classes={{ item: classes.item }}>
                <FormattedHTMLMessage id="banners.export-dialog.set-export-selection" />
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth classes={{ root: classes.leftColumn }}>
                  <FormGroup classes={{ root: classes.formGroup }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(allChecked)}
                          color="primary"
                          onChange={e =>
                            this.setState({
                              bannersForSlidesConversionToImage: allChecked ? {} : { ...allBannersChecked },
                            })
                          }
                          value="all"
                          classes={{
                            root: !showFirstColumn && classes.hideCheckBoxes,
                          }}
                        />
                      }
                      label={<FormattedHTMLMessage id="banners.export-dialog.banners-names" />}
                      classes={{ root: classes.leftColHeader, label: classes.labelLeft }}
                    />
                    {banners.map(banner => (
                      <FormControlLabel
                        key={banner.name}
                        control={
                          <Checkbox
                            color="primary"
                            checked={Boolean(
                              bannersForSlidesConversionToImage[getBannerUniqueIdentification({ banner })] === true,
                            )}
                            onChange={e =>
                              this.setState({
                                bannersForSlidesConversionToImage: {
                                  ...bannersForSlidesConversionToImage,
                                  [getBannerUniqueIdentification({ banner })]: e.target.checked,
                                },
                              })
                            }
                            value={banner.name}
                            classes={{
                              root: !showFirstColumn && classes.hideCheckBoxes,
                            }}
                          />
                        }
                        label={formatI18nBannerNameId(intl, banner.format.name)}
                        classes={{ root: classes.cell, label: classes.labelLeft }}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth classes={{ root: classes.rightColumn }}>
                  <FormGroup classes={{ root: classes.formGroup }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allChecked}
                          color="primary"
                          onChange={e =>
                            this.setState({
                              bannersForSlidesConversionToImage: allChecked ? {} : { ...allBannersChecked },
                            })
                          }
                          value="all"
                        />
                      }
                      label={intl.formatMessage({ id: 'banners.export-dialog.banners-formats' })}
                      labelPlacement="start"
                      classes={{ root: classes.rightColHeader, label: classes.labelRight }}
                    />
                    {banners.map(banner => (
                      <FormControlLabel
                        key={banner.name}
                        control={
                          <Checkbox
                            color="primary"
                            checked={Boolean(
                              bannersForSlidesConversionToImage[getBannerUniqueIdentification({ banner })] === true,
                            )}
                            onChange={e =>
                              this.setState({
                                bannersForSlidesConversionToImage: {
                                  ...bannersForSlidesConversionToImage,
                                  [getBannerUniqueIdentification({ banner })]: e.target.checked,
                                },
                              })
                            }
                            value={`${banner.format.height}${banner.format.width}`}
                          />
                        }
                        label={`${banner.format.width}x${banner.format.height}`}
                        classes={{ root: classes.cell, label: classes.labelRight }}
                        labelPlacement="start"
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {this.renderActions()}
      </React.Fragment>
    );
  };

  render() {
    const { open, classes, toggleProgressBar, exporting } = this.props;
    const { progressBar } = this.state;

    return (
      <Dialog
        open={open || toggleProgressBar || exporting}
        disableBackdropClick={toggleProgressBar || exporting}
        disableEscapeKeyDown={toggleProgressBar || exporting}
        onClose={this.handleClose}
        classes={{
          paper: classnames(classes.root, exporting && classes.paperPadding),
        }}
      >
        <DialogTitle classes={{ root: classnames(exporting && classes.noPadding) }}>
          <FormattedMessage id={exporting ? 'banners.export-dialog.exporting' : 'banners.export-dialog.title'} />
        </DialogTitle>
        {!exporting && (
          <React.Fragment>
            {progressBar === undefined && this.renderContent()}
            {progressBar !== undefined && this.renderProgressBar()}
          </React.Fragment>
        )}
      </Dialog>
    );
  }
}

const makeStateToProps = () => state => ({
  banner: makeSelectorInstance(getBannerPresent)(state),
  banners: makeSelectorInstance(getBannersPresent)(state),
  key: makeSelectorInstance(getExportDialogKey)(state), // used to reset
  open: makeSelectorInstance(isExportDialogOpen)(state),
  originalBannerSetName: makeSelectorInstance(getBannerSetName)(state),
  toggleProgressBar: makeSelectorInstance(getToggleProgressBar)(state),
  progress: makeSelectorInstance(getProgress)(state),
  numberOfFramesToRender: makeSelectorInstance(getNumberOfFramesToRender)(state),
  exporting: makeSelectorInstance(isExporting)(state),
});

const dispatchToProps = {
  onCloseDialog: closeExportDialog,
  exportBannersToImages,
  // exportBannersToAnimatedGIF,
  exportBannersToHTMLAnimation,
  progressBarHasLoaded,
  exportBannersToZIP,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles, { withTheme: true }),
)(ExportDialog);
