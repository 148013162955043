import renderBanner from '../../player/banner/renderBanner';
import bannerCSS from '../../reference/bannerCSS';
import { addGoogleFontLink } from '../../player/util/dom-helpers';
import { googleFont, customFont, userFont } from './params';

export const exportBannerToPlayer = bannerJS => {
  try {
    const { renderedBanner, keyframes } = renderBanner(bannerJS);
    const googleFonts = bannerJS.fonts.filter(font => font.type === googleFont).map(({ name }) => name);
    const userCustomFonts = bannerJS.fonts.filter(font => font.type === userFont);
    const customFonts = bannerJS.fonts.filter(font => font.type === customFont);
    const noRequestToFavicon = '<link rel="icon" href="data:;base64,iVBORw0KGgo=">';
    //<link rel="stylesheet" type="text/css" href="fonts/fonts.css" /> => if need of fonts.css
    // prettier-ignore
    const globalCss =
    bannerCSS(
      keyframes.join(' '),
      userCustomFonts,
      customFonts
    )

    let newPlayersScripts = '';

    if (window.adbConfig.player.scripts.length > 0) {
      newPlayersScripts = window.adbConfig.player.scripts.map(
        script => `<script type="text/javascript" src="${window.adbConfig.player.baseUrl}${script}"></script>`,
      );
    }

    if (window.adbConfig.player && hasCustomizableFields(bannerJS)) {
      newPlayersScripts += `<script type="text/javascript" src="${window.adbConfig.player.baseUrl}${window.adbConfig.player.dco}"></script>`;
    } else if (window.adbConfig.player) {
      newPlayersScripts += `<script type="text/javascript" src="${window.adbConfig.player.baseUrl}${window.adbConfig.player.tracking}"></script>`;
    }

    return (
      '<!DOCTYPE html><html lang="en"><head><meta charset="utf-8">' +
      `<meta name="ad.size" content="width=${bannerJS.format.width},height=${bannerJS.format.height}">` +
      newPlayersScripts +
      (googleFonts && googleFonts.length ? addGoogleFontLink(googleFonts).outerHTML : '') +
      noRequestToFavicon +
      globalCss +
      `<title>${bannerJS.format.width} x ${bannerJS.format.height}</title>` +
      '</head><body>' +
      renderedBanner.outerHTML +
      '</body></html>'
    );
  } catch (e) {
    console.error('cannot export banner to player', e);
  }
};

function hasCustomizableFields({ elements }) {
  return !!(elements && Object.keys(elements).find(id => elements[id].customizable));
}
