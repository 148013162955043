import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withStyles, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { buttonAppearances } from '../../reference/buttons';
import { styles } from '../propertyEditorStyles';
import { getSelectedElementAppearance } from '../../banner/bannerSelectors';
import { updateElement } from '../../banner/bannerActionsCreators';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

export const selectAppearanceInputPropId = { id: 'appearance' };

class ElementAppearance extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return {
      classesState: {
        menuItem: { selected: classes.menuSelected, root: classes.menuItem },
      },
    };
  }

  onAppearanceChange = e => {
    const { updateElement, id } = this.props;
    updateElement({ id, appearance: e.target.value });
  };

  render() {
    const { appearance } = this.props;
    const { classesState } = this.state;
    return (
      <FormControl fullWidth>
        <InputLabel htmlFor="appearance">
          <FormattedMessage id="properties.button.appearance" />
        </InputLabel>
        <Select
          value={appearance || 'flat'}
          inputProps={selectAppearanceInputPropId}
          onChange={this.onAppearanceChange}
        >
          {buttonAppearances.map(buttonAppearance => (
            <MenuItem key={buttonAppearance} value={buttonAppearance} classes={classesState.menuItem}>
              <FormattedMessage id={`properties.button.appearance.${buttonAppearance}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  appearance: makeSelectorInstance(getSelectedElementAppearance)(state),
});

const dispatchToProps = {
  updateElement,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  withStyles(styles),
)(ElementAppearance);
