import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import BannerFormatSelectorCard from './BannerFormatSelectorCard';
import { getBannerFormatDialogState, selectAllFormats } from './bannerFormatSelectorDucks';
import { compose } from 'recompose';
import makeSelectorInstance from '../reference/makeSelectorInstance';

const useStyles = makeStyles(() => ({
  bannerCards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '15px 0 15px',
  },
  selectAll: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

const BannerFormats = memo(({ formats, bannerFormatDialogState, selectAllFormatsAction }) => {
  /* Vars */

  const classes = useStyles();
  const { selectedFormats } = bannerFormatDialogState;

  /* Callback */

  const handleSelectAllFormats = useCallback(
    (evt, value) => {
      selectAllFormatsAction(evt.target.value, value);
    },
    [selectAllFormatsAction],
  );

  return (
    <>
      <div className={classes.selectAll}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={formats.filter(({ name }) => selectedFormats.includes(name)).length === formats.length}
              onChange={handleSelectAllFormats}
              value={JSON.stringify(formats.map(({ name }) => name))}
            />
          }
          label={<FormattedMessage id="bannerSetCreate.dialog.select-all-formats" />}
        />
      </div>
      <div className={classes.bannerCards}>
        {formats.map(format => (
          <BannerFormatSelectorCard
            key={format.name}
            selected={selectedFormats && selectedFormats.includes(format.name)}
            format={format}
          />
        ))}
      </div>
    </>
  );
});

BannerFormats.propTypes = {
  formats: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string.isRequired,
      locale: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      maxSize: PropTypes.number.isRequired,
      responsive: PropTypes.oneOf(['desktop', 'mobile']).isRequired,
    }).isRequired,
  ).isRequired,
  bannerFormatDialogState: PropTypes.shape({
    selectedFormats: PropTypes.array.isRequired,
  }).isRequired,
  selectAllFormatsAction: PropTypes.func.isRequired,
};

const makeStateToProps = () => state => ({
  bannerFormatDialogState: makeSelectorInstance(getBannerFormatDialogState)(state),
});

const dispatchToProps = { selectAllFormatsAction: selectAllFormats };

export default compose(connect(makeStateToProps, dispatchToProps))(BannerFormats);
