import React from 'react';
import TextFieldForElements from '../TextFieldForElements';
import { getRotationToSave } from '../helpers';

class ElementsRotation extends React.Component {
  render() {
    return (
      <TextFieldForElements
        property="rotation"
        formattedMessage="properties.rotation"
        shouldUpdateWithDiff={false}
        helper={getRotationToSave}
        averageSumupFactor={-1}
      />
    );
  }
}

export default ElementsRotation;
