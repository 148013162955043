import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, onlyUpdateForKeys } from 'recompose';
import { noChangeHelper } from '../constants';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { setElementTextContent, updateElement } from '../../banner/bannerActionsCreators';
import { getSelectedElementText, getSelectedElementType } from '../../banner/bannerSelectors';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementDefaultValue extends React.Component {
  onTextChange = ({ value: text, id }) => {
    const { setElementTextContent, updateElement, type } = this.props;
    if (type === 'text') {
      updateElement({ contentState: null, text, id });
    } else {
      setElementTextContent({ text, id });
    }
  };

  render() {
    const { id, text, intl } = this.props;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="text"
        label={intl.formatMessage({ id: 'customization.field.defaultValue' })}
        value={text || ''}
        fullWidth
        multiline
        helper={noChangeHelper} //to prevent parseInt...
        onChangeMethod={this.onTextChange} //tracked
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  type: makeSelectorInstance(getSelectedElementType)(state),
  text: makeSelectorInstance(getSelectedElementText)(state),
});

const dispatchToProps = {
  setElementTextContent,
  updateElement,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  onlyUpdateForKeys(['text']),
  injectIntl,
)(ElementDefaultValue);
