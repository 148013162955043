import React from 'react';
import { connect } from 'react-redux';
import { TextField, MenuItem, withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { compose, onlyUpdateForKeys } from 'recompose';
import { styles } from '../propertyEditorStyles';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { setElementFontFamilyRequested } from '../../banner/bannerActionsCreators';
import { getSelectedElementFontFamily } from '../../banner/bannerSelectors';
import { getAllFontNames } from '../../fonts/fontsDucks';
import { checkKeyDiff } from '../../components/helpers';
import { loadAllFonts } from '../../resources/fonts';
import {
  toggleFontFamilyMenuOpen,
  getTemporaryFontFamilyMenuOpen,
} from '../../temporary-text-editor/temporaryTextEditorDucks';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

class FontName extends React.Component {
  state = { fontFamily: '"Roboto", "Helvetica", "Arial"' };

  shouldComponentUpdate(nextProps) {
    return Boolean(checkKeyDiff(nextProps, this.props, 'FontName'));
  }

  static getDerivedStateFromProps(nextProps) {
    return { fontFamily: `"${nextProps.fontName}", "Roboto", "Helvetica", "Arial", sans-serif` };
  }

  render() {
    return <span style={this.state}>{this.props.fontName}</span>;
  }
}

class ElementFontFamilySelect extends React.Component {
  state = {
    fontsLoaded: false,
  };

  componentDidMount() {
    // Load fonts in the background
    this.canChangeState = true;
    loadAllFonts().then(() => {
      if (this.canChangeState) {
        this.setState({ fontsLoaded: true });
      }
    });
  }

  componentWillUnmount() {
    this.fontsLoaded = false;
    this.canChangeState = false;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { classes } = nextProps;
    return {
      ...prevState,
      classesState: { menuItem: { selected: classes.menuSelected, root: classes.menuItem } },
    };
  }

  onFontSelectOpen = () => this.props.toggleFontFamilyMenuOpen(true);

  onFontSelectClose = () => this.props.toggleFontFamilyMenuOpen(false);

  onFontFamilyChange = ({ target: { value: fontFamily } }) => {
    const { id, setElementFontFamilyRequested } = this.props;
    setElementFontFamilyRequested({ fontFamily, id });
  };

  render() {
    const { intl, fontFamily, fontNames, fontSelectOpen } = this.props;
    const { classesState } = this.state;
    return (
      <TextField
        select
        label={intl.formatMessage({ id: 'properties.text.font-family' })}
        value={fontFamily}
        fullWidth
        onChange={this.onFontFamilyChange}
        SelectProps={{
          open: fontSelectOpen,
          onOpen: () => this.onFontSelectOpen(),
          onClose: () => this.onFontSelectClose(),
        }}
      >
        {fontNames.map(fontName => (
          <MenuItem key={fontName} value={fontName} classes={classesState.menuItem}>
            <FontName fontName={fontName} />
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  fontFamily: makeSelectorInstance(getSelectedElementFontFamily)(state),
  fontNames: makeSelectorInstance(getAllFontNames)(state),
  fontSelectOpen: makeSelectorInstance(getTemporaryFontFamilyMenuOpen)(state),
});

const dispatchToProps = {
  setElementFontFamilyRequested,
  toggleFontFamilyMenuOpen,
};

export default compose(
  connect(makeStateToProps, dispatchToProps),
  onlyUpdateForKeys(['fontSelectOpen', 'fontFamily']),
  injectIntl,
  withStyles(styles),
)(ElementFontFamilySelect);
