import { slideTransitionsDetails } from '../../reference/transitions';
import { calculateSlideOwnDelayForTransitionOut } from '.';
import { getAnimationIn } from './getAnimationIn';
import { getAnimationOut } from './getAnimationOut';

export const setSlideSnapshotsAnimationProperties = (framesPerSecond, frameNumber, from) => (
  _,
  {
    isLastSlide,
    slide: slideProp,
    slidesForAnimation,
    slideIndex,
    slideDurationForAnimation,
    animationGlobalDelay,
    snapshotCurrentScene,
  },
) => {
  if (snapshotCurrentScene) {
    return { animationProperties: null };
  }
  const animationTimeLine = (1 / framesPerSecond) * frameNumber;
  /*the transition of index n is for slides n-1 and n*/

  const slide = slideIndex !== undefined ? slidesForAnimation[slideIndex].slide : slideProp;
  const nextSlide = isLastSlide ? null : slidesForAnimation[slideIndex + 1].slide;
  let animationOwnDelayForTransitionOut = isLastSlide ? 0 : calculateSlideOwnDelayForTransitionOut({ nextSlide });

  const animationIn = getAnimationIn(slide);

  const animationOut = getAnimationOut(isLastSlide, nextSlide);

  const animationDurationIn =
    slideTransitionsDetails[slide.transition.type].influence.currentSlide * slide.transition.duration;

  const animationDurationOut = nextSlide
    ? slideTransitionsDetails[nextSlide.transition.type].influence.prevSlide * nextSlide.transition.duration
    : 0;

  const animationDelayOut =
    animationGlobalDelay + (slideDurationForAnimation || slide.duration) - animationOwnDelayForTransitionOut;

  let animationInSnapshot = -0.01; // to have the element positionned while wiating to start animation
  let animationOutSnapshot = 0.01; // if set to 0, the effect above doesn't work

  if (animationTimeLine >= animationGlobalDelay) {
    animationInSnapshot = -animationTimeLine + animationGlobalDelay;
  }
  if (animationTimeLine >= animationDelayOut) {
    animationOutSnapshot = -animationTimeLine + animationDelayOut;
  }

  const animationProperties = {
    animationName: `${animationIn}, ${animationOut}`,
    animationDuration: `${animationDurationIn === 0 ? 0.02 : animationDurationIn}s, ${
      animationDurationOut === 0 ? 0.02 : animationDurationOut
    }s`,
    animationDelay: `${animationInSnapshot}s, ${animationOutSnapshot}s`,
    animationIterationCount: '1, 1',
    animationTimingFunction: 'ease-in-out, ease-in-out',
    animationFillMode: 'both, forwards',
    animationPlayState: 'paused, paused',
    animationDirection: 'normal, normal',
    overflow: 'hidden', //for transition effects between slides
  };

  return {
    animationProperties,
  };
};
