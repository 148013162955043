import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { toggleElementBlink } from '../../banner/bannerActionsCreators';
import { getSelectedElementBlinkAnimationEnabled } from '../../banner/bannerSelectors';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import CheckboxDoped from '../../components/CheckboxDoped';

class ElementBlinkToggle extends React.Component {
  render() {
    const { toggleElementBlink, blinkAnimationEnabled, id } = this.props;
    return (
      <CheckboxDoped
        toggleFunction={toggleElementBlink}
        checked={blinkAnimationEnabled}
        intlId="properties.animation.blink"
        id={id}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  blinkAnimationEnabled: makeSelectorInstance(getSelectedElementBlinkAnimationEnabled)(state),
});

const dispatchToProps = {
  toggleElementBlink,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
)(ElementBlinkToggle);
