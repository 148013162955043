import React from 'react';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { ToggleButtonGroup } from '@material-ui/lab';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { getSelectedElementLockAspectRatio } from '../../banner/bannerSelectors';
import { setElementLockAspectRatio } from '../../banner/bannerActionsCreators';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import ToggleButtonWithTooltip from '../../components/ToggleButton/ToggleButtonWithTooltip';
import { styles } from '../propertyEditorStyles';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

const lockAspect = ['lockAspect'];
const emptyArray = [];

class ElementLockAspectRatio extends React.Component {
  state = { classesState: {} };

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return {
      classesState: {
        lockAspectRatio: {
          root: classNames(classes.toggleButton, classes.smallButton),
          container: classes.smallButtonContainer,
          selected: classes.toggleButtonsSelected,
        },
        toggleButtonGroup: { root: classes.toggleButtons },
      },
    };
  }

  onLockAspectRatioChange = () => {
    const { setElementLockAspectRatio, lockAspectRatio, id } = this.props;
    setElementLockAspectRatio({ lockAspectRatio: !lockAspectRatio, id });
  };

  render() {
    const { lockAspectRatio, intl } = this.props;
    const { classesState } = this.state;
    return (
      <ToggleButtonGroup
        classes={classesState.toggleButtonGroup}
        selected
        value={lockAspectRatio ? lockAspect : emptyArray}
        onChange={this.onLockAspectRatioChange}
      >
        <ToggleButtonWithTooltip
          value="lockAspect"
          noFlex={false}
          tooltip={intl.formatMessage({ id: 'properties.image.lockAspectRatio' })}
          classes={classesState.lockAspectRatio}
        >
          {lockAspectRatio ? <LockIcon /> : <LockOpenIcon />}
        </ToggleButtonWithTooltip>
      </ToggleButtonGroup>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  lockAspectRatio: makeSelectorInstance(getSelectedElementLockAspectRatio)(state),
});

const dispatchToProps = {
  setElementLockAspectRatio,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(ElementLockAspectRatio);
