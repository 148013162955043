// Imports

import { put, select, takeEvery } from 'redux-saga/effects';

// Actions

export const ALERT_OPEN = 'adbuilder/ALERT_OPEN';
export const ALERT_CANCEL = 'adbuilder/ALERT_CANCEL';
const ALERT_CONFIRM_REQUIRED = 'adbuilder/ALERT_CONFIRM_REQUIRED';
export const ALERT_CONFIRM = 'adbuilder/ALERT_CONFIRM';
const ALERT_UPDATE_COMMENT = 'adbuilder/ALERT_UPDATE_COMMENT';
const ALERT_ERROR = 'adbuilder/ALERT_ERROR';

// Action creators

const defaultAlert = {
  title: null,
  titleValues: {},
  actionLabel: 'alert.action',
  actionColor: 'primary',
  message: 'alert.text',
  messageValues: {},
  withComment: false,
  commentLabel: 'alert.comment',
  commentLabelValues: {},
  commentRequired: false,
  action: null,
};

export const openAlert = config => {
  return {
    type: ALERT_OPEN,
    payload: { ...defaultAlert, ...config },
  };
};

export const cancelAlert = () => ({
  type: ALERT_CANCEL,
});

export const confirmAlert = () => ({
  type: ALERT_CONFIRM_REQUIRED,
});

const doConfirmAlert = () => ({
  type: ALERT_CONFIRM,
});

export const updateComment = comment => ({
  type: ALERT_UPDATE_COMMENT,
  payload: comment,
});

const setError = () => ({
  type: ALERT_ERROR,
});

// Selectors

const getComment = ({ alert: { withComment, commentRequired, commentaire } }) => ({
  required: withComment && commentRequired,
  withComment,
  commentaire,
});

const getAction = ({ alert: { action } }) => action;

export const getAlert = ({ alert }) => alert;
// Sagas
// /\S/.test(string) returns true if and only if there's a non-space character in string
export function* confirmSaga() {
  const { withComment, required, commentaire } = yield select(getComment);
  if (required && (!commentaire || !/\S/.test(commentaire))) {
    yield put(setError());
  } else {
    const action = yield select(getAction);
    yield put(doConfirmAlert());
    if (action) {
      if (withComment && typeof action.payload === 'object') {
        const actionWithComment = { ...action, payload: { ...action.payload, commentaire } };
        yield put(actionWithComment);
      } else {
        yield put(action);
      }
    }
  }
}

export function* saga() {
  yield takeEvery(ALERT_CONFIRM_REQUIRED, confirmSaga);
}

// Reducer

export const initialState = {
  open: false,
  commentaire: '',
  error: false,
  ...defaultAlert,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ALERT_OPEN: {
      return {
        open: true,
        commentaire: '',
        error: false,
        ...action.payload,
      };
    }
    case ALERT_UPDATE_COMMENT: {
      return {
        ...state,
        commentaire: action.payload,
      };
    }
    case ALERT_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case ALERT_CANCEL:
    case ALERT_CONFIRM: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
