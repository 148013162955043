export const BANNERS_UNDO = 'adbuilder/BANNERS_UNDO';
export const BANNERS_REDO = 'adbuilder/BANNERS_REDO';
export const BANNERS_UNDO_REQUESTED = 'adbuilder/BANNERS_UNDO_REQUESTED';
export const BANNERS_REDO_REQUESTED = 'adbuilder/BANNERS_REDO_REQUESTED';
// Actions to track

// Actions to not track
export const BANNERS_LOAD = 'adbuilder/BANNERS_LOAD';
export const BANNERS_SET_MASTER = 'adbuilder/BANNERS_SET_MASTER';
export const BANNERSET_SET_FPS = 'adbuilder/BANNERSET_SET_FPS';
export const BANNERSET_SET_NAME = 'adbuilder/BANNERSET_SET_NAME';
export const BANNERSET_SET_THUMBNAIL = 'adbuilder/BANNERSET_SET_THUMBNAIL';
export const BANNERSET_SET_EXPIRATION_DATE = 'adbuilder/BANNERSET_SET_EXPIRATION_DATE';
export const BANNERS_ADD_REQUESTED = 'adbuilder/BANNERS_ADD_REQUESTED';
export const BANNERS_COPY_REQUESTED = 'adbuilder/BANNERS_COPY_REQUESTED';
export const BANNERS_COPY_FORMAT = 'adbuilder/BANNERS_COPY_FORMAT';
export const BANNERS_SELECT_REQUESTED = 'adbuilder/BANNERS_SELECT_REQUESTED';
export const BANNERS_SELECTED = 'adbuilder/BANNERS_SELECTED';
export const SLIDE_DUPLICATE_REQUESTED = 'adbuilder/SLIDE_DUPLICATE_REQUESTED';
export const ELEMENT_DUPLICATE_REQUESTED = 'adbuilder/ELEMENT_DUPLICATE_REQUESTED';
export const BANNERS_SET_MASTER_REQUESTED = 'adbuilder/BANNERS_SET_MASTER_REQUESTED';
export const BANNERS_DELETE_ELEMENT_AFTER_CUT = 'adbuilder/BANNERS_DELETE_ELEMENT_AFTER_CUT';
export const BANNERS_CLOSE_ALL = 'adbuilder/BANNERS_CLOSE_ALL';
export const BANNERS_CLOSE_REQUESTED = 'adbuilder/BANNERS_CLOSE_REQUESTED';
export const BANNERS_CLOSE = 'adbuilder/BANNERS_CLOSE';
export const BANNERS_UPDATE = 'adbuilder/BANNERS_UPDATE';
export const BANNERS_SLIDE_DUPLICATE = 'adbuilder/BANNERS_SLIDE_DUPLICATE';
export const BANNERSET_RESET_INFOS = 'adbuilder/BANNERSET_RESET_INFOS';
export const BANNERSET_ADD_TO_EXISTING_BANNERS_REQUESTED = 'adbuilder/BANNERSET_ADD_TO_EXISTING_BANNERS_REQUESTED';
export const BANNERSET_SAVE_PREV_ADD_NEW_REQUESTED = 'adbuilder/BANNERSET_SAVE_PREV_ADD_NEW_REQUESTED';
export const BANNERSET_CLOSE_PREV_ADD_NEW_REQUESTED = 'adbuilder/BANNERSET_CLOSE_PREV_ADD_NEW_REQUESTED';
export const BANNERSET_FROM_NUXEO_REQUESTED = 'adbuilder/BANNERSET_FROM_NUXEO_REQUESTED';
export const BANNERSET_FROM_NUXEO_SUCCESS = 'adbuilder/BANNERSET_FROM_NUXEO_SUCCESS';
export const BANNERSET_TO_NUXEO_REQUESTED = 'adbuilder/BANNERSET_TO_NUXEO_REQUESTED';
export const BANNERSET_CREATE_NEW_BANNERSET_REQUESTED = 'adbuilder/BANNERSET_CREATE_NEW_BANNERSET_REQUESTED';
export const BANNERSET_LOAD_FROM_URL_PARAMS_REQUESTED = 'adbuilder/BANNERSET_LOAD_FROM_URL_PARAMS_REQUESTED';
export const BANNERSET_LOAD_FROM_URL_PARAMS_SUCCESS = 'adbuilder/BANNERSET_LOAD_FROM_URL_PARAMS_SUCCESS';
export const BANNERSET_LOAD_FROM_URL_PARAMS_FAILURE = 'adbuilder/BANNERSET_LOAD_FROM_URL_PARAMS_FAILURE';
export const BANNERSET_SET_INFOS = 'adbuilder/BANNERSET_SET_INFOS';
export const BANNERSET_SET_PERMISSIONS = 'adbuilder/BANNERSET_SET_PERMISSIONS';
export const BANNERSET_SET_LOCK = 'adbuilder/BANNERSET_SET_LOCK';
export const BANNERS_TAKE_SCREENSHOT_TO_THUMBNAIL_REQUESTED =
  'adbuilder/BANNERS_TAKE_SCREENSHOT_TO_THUMBNAIL_REQUESTED';
export const BANNERS_SET_DEFAULT_THUMBNAIL = 'adbuilder/BANNERS_SET_DEFAULT_THUMBNAIL';
