import React from 'react';
import { connect } from 'react-redux';
import Morphable from './Morphable';
import keyboardShortcuts from '../reference/keyboardShortcuts';

import { needToUpdateGuides, setBgProperties } from './interactions-helpers';
import {
  setElementAnimationProperties,
  setElementSnapshotAnimationProperties,
  updateElementSnapshotAnimationProperties,
  setBlinkAnimation,
} from './animation-helpers';
import {
  getBannerGuidesForElement,
  getSelectedSlide,
  getBannerFuture,
  isElementSelected,
  isElementMultipleSelected,
  getBannerZoom,
} from '../banner/bannerSelectors';
import {
  setElementPosition,
  setElementRotation,
  setElementDimensionsRequested,
  setElementLockAspectRatio,
  openDeleteElementDialog,
} from '../banner/bannerActionsCreators';
import {
  selectElement,
  updateElementStatus,
  updateBannerStatus,
  isBannerPlayingGIFAnimation,
  selectMultipleElements,
  unselectMultipleElements,
  getSelectedElementCroppingStatus,
} from '../temporary-status/temporaryStatusDucks';
import { getDisableShortcuts } from '../shared-selectors/sharedSelectors';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { checkKeyDiff } from '../components/helpers';

class ElementRoot extends React.Component {
  state = {
    rotation: -Math.abs(this.props.rotation),
    position: {
      x: this.props.x,
      y: this.props.y,
    },
    size: {
      width: this.props.width,
      height: this.props.height,
    },
    animation: null,
    blinkAnimation: {},
    minHeight: 0,
    multipleSelectEnabled: false,
    // backgroundProperties -> for image only
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    /*
    ABOUT MORPHING (rotation - drag - resize)
    */
    const { x, y, width, height, rotation, status, selected } = nextProps;
    const { isRotating, isDragging, isResizing, isCropping } = status;
    const rotationChanged = -Math.abs(rotation) !== prevState.rotation;
    const { updateWithArrows } = prevState;
    const positionChanged =
      Boolean(checkKeyDiff(prevState.position, { x, y })) || Boolean(checkKeyDiff(prevState.size, { width, height }));

    if (!isRotating && rotationChanged) {
      return {
        ...prevState,
        selected,
        rotation: -Math.abs(rotation),
      };
    }

    if (!isDragging && !isResizing && !isCropping && !updateWithArrows && positionChanged) {
      return {
        ...prevState,
        selected,
        position: {
          x,
          y,
        },
        size: {
          width,
          height,
        },
      };
    }

    /*
    ABOUT ANIMATION
    */

    const {
      status: { elementIsPlayingAnimation },
      showAnimationSnapshot,
      bannerIsPlayingGIFAnimation,
      slideIsPlayingAnimation,
    } = nextProps;
    const { animation } = prevState;
    const noAnimation = animation === null || animation.animationName === null;
    /* start */
    if (!showAnimationSnapshot && ((elementIsPlayingAnimation && selected) || slideIsPlayingAnimation) && noAnimation) {
      return {
        ...prevState,
        selected,
        ...setBlinkAnimation({ preview: false })(prevState, nextProps),
        ...setElementAnimationProperties(prevState, nextProps),
      };
    }

    /* end */
    if (
      !showAnimationSnapshot &&
      !(elementIsPlayingAnimation || slideIsPlayingAnimation) &&
      !noAnimation &&
      !bannerIsPlayingGIFAnimation
    ) {
      return {
        ...prevState,
        selected,
        animation: null,
        blinkAnimation: null,
      };
    }

    return {
      ...prevState,
      selected, // in order to pass the "selected" prop to the ButtonElement and all elements
    };
  }

  componentDidMount() {
    const {
      status: { elementIsPlayingAnimation },
      showAnimationSnapshot,
      type,
      framesPerSecond,
      currentFrame,
      bannerIsPlayingGIFAnimation,
    } = this.props;

    /* if type is image, give backgroundProperties*/
    if (type === 'image') {
      this.setState(setBgProperties('componentDidMount'));
    }

    /*init guides*/
    this.guidesToShow = [];

    /* start animation by frame */
    if (showAnimationSnapshot || bannerIsPlayingGIFAnimation) {
      if (elementIsPlayingAnimation) {
        this.startAnimationByFrame(0);
      } else {
        this.setState(setElementSnapshotAnimationProperties(framesPerSecond, currentFrame));
      }
    } else {
      /*init event listeners for keyboard shortcuts*/
      window.addEventListener('keydown', this.handleShortcuts);
      window.addEventListener('keyup', this.handleShortcuts);
      window.addEventListener('blur', this.handleShortcuts);
    }
  }

  componentDidUpdate(prevProps) {
    /*
    ABOUT UNDO-REDO
    */

    const { x, y, width, height, rotation, picWhileCroppingProperties, type, bannerIsPlayingGIFAnimation } = this.props;
    const { size, position } = this.state;

    const redo = this.props.future.length < prevProps.future.length;
    const undo = this.props.future.length > prevProps.future.length;

    const rotationChanged = -Math.abs(rotation) !== this.state.rotation;
    const positionChanged = Boolean(checkKeyDiff(position, { x, y })) || Boolean(checkKeyDiff(size, { width, height }));

    if (undo || redo) {
      if (positionChanged) {
        this.setState({
          position: { x, y },
          size: { width, height },
        });
      }
      if (rotationChanged) {
        this.setState({ rotation: -Math.abs(rotation) });
      }
    }

    /*
    ABOUT BLINK ANIMATION
    */
    if (
      Boolean(checkKeyDiff(prevProps.blinkAnimation, this.props.blinkAnimation)) ||
      (!prevProps.blinkAnimationEnabled && this.props.blinkAnimationEnabled)
    ) {
      this.setState(setBlinkAnimation({ preview: true, previewDuration: 3 }));
    }

    /*
    ABOUT SIMULATING GIF ANIMATION
    */

    if (bannerIsPlayingGIFAnimation) {
      if (prevProps.currentFrame !== this.props.currentFrame) {
        this.setState(updateElementSnapshotAnimationProperties(this.props.framesPerSecond, this.props.currentFrame));
      }
      return;
    }

    /*
    ABOUT CROP RESET
    */
    const bgPropsChanged =
      Boolean(checkKeyDiff(picWhileCroppingProperties, prevProps.picWhileCroppingProperties)) ||
      Boolean(checkKeyDiff({ x, y }, { x: prevProps.x, y: prevProps.y }));

    if (bgPropsChanged && type === 'image') {
      this.setState(setBgProperties('componentDidUpdate'));
    }

    /*
    ABOUT ANIMATION
    */

    if (!this.props.showAnimationSnapshot) {
      prevProps.showAnimationSnapshot && this.setState({ animation: null });
      return;
    }

    const {
      status: { elementIsPlayingAnimation },
      currentFrame,
      framesPerSecond,
    } = this.props;

    /* static */
    if (prevProps.currentFrame !== currentFrame || !prevProps.showAnimationSnapshot) {
      this.setState(updateElementSnapshotAnimationProperties(framesPerSecond, currentFrame));
    }

    /* start */
    if (elementIsPlayingAnimation && this.animationInterval === null) {
      this.startAnimationByFrame(0);
    }

    /* end */
    if (!elementIsPlayingAnimation && this.animationInterval !== null) {
      this.setState({ animation: null });
      window.clearInterval(this.animationInterval);
      this.animationInterval = null;
      this.frameNumber = null;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleShortcuts);
    window.removeEventListener('keyup', this.handleShortcuts);
    window.removeEventListener('blur', this.handleShortcuts);
    window.clearInterval(this.animationInterval);
  }

  /*------------------------------------*/
  /*REFS*/
  /*------------------------------------*/

  saveTopLeftCorner = (topLeftCorner, from) => {
    const {
      selected,
      status: { isCropping },
      saveTopLeftCornerElement,
    } = this.props;
    selected && isCropping && saveTopLeftCornerElement(topLeftCorner, from);
  };

  /*------------------------------------*/
  /*SHORTCUTS*/
  /*------------------------------------*/

  handleShortcuts = e => {
    const {
      selected,
      id,
      openDeleteElementDialog,
      showOnAllSlides,
      status,
      disableShortcuts,
      preventDeleteElement,
    } = this.props;

    if (disableShortcuts) {
      return;
    }
    if (selected) {
      /* delete element */
      if (keyboardShortcuts.deleteElementRequested.includes(e.key) && !status.textIsSelected && !preventDeleteElement) {
        e.preventDefault();
        openDeleteElementDialog(id, showOnAllSlides);
      }
    }

    /* multiple select */
    if (keyboardShortcuts.multipleSelect.includes(e.key) && !status.textIsSelected) {
      if (e.type === 'keydown') {
        this.setState({ multipleSelectEnabled: true });
      } else {
        this.setState({ multipleSelectEnabled: false });
      }
    }
    if (e.type === 'blur') {
      this.setState({ multipleSelectEnabled: false });
    }
  };

  /*------------------------------------*/
  /*START MORPHISM*/
  /*------------------------------------*/

  onHandleMouseDown = e => {
    const {
      id,
      selectElement,
      status,
      selected,
      multipleSelected,
      selectMultipleElements,
      unselectMultipleElements,
    } = this.props;
    if (this.state.multipleSelectEnabled) {
      multipleSelected ? unselectMultipleElements([id]) : selectMultipleElements([id]);
    } else {
      if (!selected) {
        selectElement(id);
      }
      if (selected && status.isCropping) {
        this.props.onHandleMouseDown();
      }
    }
  };

  /*------------------------------------*/
  /*DRAG METHODS*/
  /*------------------------------------*/

  onDragStart = () => {
    const { updateElementStatus, updateBannerStatus, id, selected } = this.props;

    /*tell redux store we start the drag*/
    if (selected) {
      updateElementStatus({ isDragging: true, id });
      updateBannerStatus({ isDragging: true });
    }
  };

  onDrag = ({ x, y, guidesToShow = [] }) => {
    const { zoom, showGuides } = this.props;
    /*performance issue: if guides to show changed, we need to update the Scene component. if not, no need to update.*/
    if (needToUpdateGuides({ guidesToShow, currentGuides: this.guidesToShow })) {
      showGuides(guidesToShow);
      this.guidesToShow = guidesToShow;
    }

    /*update the component*/
    this.setState({
      position: { x: x / zoom, y: y / zoom },
    });
  };

  onDragStop = () => {
    const {
      id,
      selected,
      setElementPosition,
      isCropping,
      updateElementStatus,
      updateBannerStatus,
      showGuides,
    } = this.props;

    const {
      position: { x, y },
    } = this.state;

    if (selected) {
      setElementPosition({ x, y, id, isCropping });
      updateElementStatus({ isDragging: false, id });
      updateBannerStatus({ isDragging: false });
      showGuides(null);
    }

    return false;
  };

  moveWithArrows = ({ x, y, saveNewPosition = false }) => {
    const { setElementPosition, id, isCropping } = this.props;

    if (saveNewPosition) {
      setElementPosition({ ...this.state.position, id, isCropping, untrack: false });
      this.setState({ updateWithArrows: false });
    } else {
      this.setState({ position: { x, y }, updateWithArrows: true });
    }
  };

  /*------------------------------------*/
  /*RESIZE METHODS*/
  /*------------------------------------*/

  onResizeStart = e => {
    const { id, updateElementStatus, updateBannerStatus } = this.props;

    /*tell redux store we start the resize*/
    updateElementStatus({ isResizing: true, id });
    updateBannerStatus({ isResizing: true });
  };

  onResize = ({ width, height, x, y, guidesToShow }) => {
    const { size, backgroundProperties } = this.state;
    const { type, showGuides, zoom } = this.props;
    const resizeFactor = {
      width: width / zoom / size.width,
      height: height / zoom / size.height,
    };

    let nextState = {
      size: {
        height: height / zoom,
        width: width / zoom,
      },
      position: {
        x: x / zoom,
        y: y / zoom,
      },
    };

    if (needToUpdateGuides({ guidesToShow, currentGuides: this.guidesToShow })) {
      showGuides(guidesToShow);
      this.guidesToShow = guidesToShow;
    }

    if (type === 'image') {
      nextState.backgroundProperties = {
        x: backgroundProperties.x * resizeFactor.width,
        y: backgroundProperties.y * resizeFactor.height,
        width: backgroundProperties.width * resizeFactor.width,
        height: backgroundProperties.height * resizeFactor.height,
      };
    }

    this.setState(nextState);
  };

  onResizeStop = () => {
    const { setElementDimensionsRequested, id, showGuides, isCropping } = this.props;
    const {
      position: { x, y },
      size: { width, height },
    } = this.state;

    setElementDimensionsRequested({ x, y, width, height, id, isCropping, isResizing: false });
    showGuides(null);
  };

  /*------------------------------------*/
  /*ROTATE*/
  /*------------------------------------*/

  onRotateStart = () => {
    const { id, updateElementStatus, updateBannerStatus } = this.props;

    /*tell redux store we start the rotation*/
    updateElementStatus({ isRotating: true, id });
    updateBannerStatus({ isRotating: true });
  };

  onRotate = ({ rotation, guidesToShow = [] }) => {
    const { status, showGuides } = this.props;
    if (status.isRotating) {
      this.setState({ rotation });
      if (needToUpdateGuides({ guidesToShow, currentGuides: this.guidesToShow })) {
        showGuides(guidesToShow);
        this.guidesToShow = guidesToShow;
      }
    } else {
      console.error('inside rotating method without rotating status');
    }
  };

  onRotateEnd = () => {
    const { id, setElementRotation, isCropping, updateElementStatus, updateBannerStatus, showGuides } = this.props;
    const { rotation } = this.state;
    setElementRotation({ isCropping, rotation: -rotation, id });
    updateElementStatus({ isRotating: false, id });
    updateBannerStatus({ isRotating: false });
    showGuides(null);
  };

  /*------------------------------------*/
  /*TRANSITIONS*/
  /*------------------------------------*/

  startAnimationByFrame = frameNumber => {
    const { framesPerSecond, slideDuration } = this.props;
    this.frameNumber = frameNumber;
    const animInterval = 1 / framesPerSecond;
    this.animationInterval = 1;
    this.animationInterval = window.setInterval(() => {
      if (frameNumber === 0) {
        this.setState(setElementSnapshotAnimationProperties(framesPerSecond, this.frameNumber));
        this.frameNumber = this.frameNumber + 1;
      } else if (frameNumber * animInterval < slideDuration) {
        this.setState(updateElementSnapshotAnimationProperties(framesPerSecond, this.frameNumber));
        this.frameNumber = this.frameNumber + 1;
      } else {
        window.clearInterval(this.animationInterval);
        this.frameNumber = null;
      }
    }, animInterval * 1000);
  };

  onAnimationEnd = e => {
    const { slideIsPlayingAnimation, updateElementStatus, id } = this.props;
    if (!slideIsPlayingAnimation && e.animationName.includes('-out')) {
      updateElementStatus({ id, elementIsPlayingAnimation: false });
    }
  };

  /*------------------------------------*/
  /*RENDER*/
  /*------------------------------------*/

  render() {
    const { animation, blinkAnimation, position, rotation, size, minHeight, backgroundProperties } = this.state;
    const { selected, multipleSelected, status, setElementLockAspectRatio, zoom } = this.props;
    return (
      <Morphable
        key={JSON.stringify(blinkAnimation)}
        onHandleMouseDown={this.onHandleMouseDown}
        onDragStart={this.onDragStart}
        onDrag={this.onDrag}
        onDragStop={this.onDragStop}
        moveWithArrows={this.moveWithArrows}
        onResizeStart={this.onResizeStart}
        onResize={this.onResize}
        onResizeStop={this.onResizeStop}
        onRotateStart={this.onRotateStart}
        onRotate={this.onRotate}
        onRotateEnd={this.onRotateEnd}
        minHeight={minHeight}
        position={position}
        size={size}
        animation={animation}
        selected={selected}
        multipleSelected={multipleSelected}
        {...this.props}
        blinkAnimation={blinkAnimation}
        backgroundProperties={backgroundProperties}
        rotation={rotation}
        disableRotation={status.isCropping}
        saveTopLeftCorner={this.saveTopLeftCorner}
        onAnimationEnd={this.onAnimationEnd}
        setElementLockAspectRatio={setElementLockAspectRatio}
        zoom={zoom}
      >
        {this.props.render(this.state)}
      </Morphable>
    );
  }
}
const makeStateToProps = () => (state, { elementForExport, id }) => ({
  slide: makeSelectorInstance(getSelectedSlide)(state),
  future: makeSelectorInstance(getBannerFuture)(state),
  disableShortcuts: makeSelectorInstance(getDisableShortcuts)(state),
  bannerIsPlayingGIFAnimation: makeSelectorInstance(isBannerPlayingGIFAnimation)(state),
  selected: makeSelectorInstance(isElementSelected(id, elementForExport))(state),
  multipleSelected: makeSelectorInstance(isElementMultipleSelected(id, elementForExport))(state),
  guides: makeSelectorInstance(getBannerGuidesForElement(id))(state),
  zoom: makeSelectorInstance(getBannerZoom)(state),
  isCropping: makeSelectorInstance(getSelectedElementCroppingStatus)(state),
});

const dispatchToProps = {
  setElementPosition,
  setElementRotation,
  setElementDimensionsRequested,
  selectElement,
  unselectMultipleElements,
  selectMultipleElements,
  updateElementStatus,
  updateBannerStatus,
  setElementLockAspectRatio,
  openDeleteElementDialog,
};

export default connect(
  makeStateToProps,
  dispatchToProps,
)(ElementRoot);
