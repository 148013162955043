import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import CheckboxDoped from '../../components/CheckboxDoped';
import { isCurrentBannerMasterBanner } from '../../banners/bannersSelectors';
import { makeBannerMasterRequested } from '../../banners/bannersActionsCreators';

class BannerMaster extends React.Component {
  render() {
    const { makeBannerMasterRequested, isBannerMaster } = this.props;
    return (
      <CheckboxDoped
        toggleFunction={makeBannerMasterRequested}
        checked={isBannerMaster}
        intlId="properties.banner-master"
      />
    );
  }
}

const makeStateToProps = () => state => ({
  isBannerMaster: makeSelectorInstance(isCurrentBannerMasterBanner)(state),
});

const dispatchToProps = {
  makeBannerMasterRequested,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
)(BannerMaster);
