import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getResourceUrl } from '../resources/resourcesDucks';
import { compose } from 'recompose';

const styles = {
  root: {
    height: '100%',
    width: '100%',
    pointerEvents: 'none',
    boxSizing: 'border-box', // for rendering in animated GIF
  },
  image: {
    height: '100%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    pointerEvents: 'none',
    borderStyle: 'solid',
  },
};

class ImageElement extends React.Component {
  state = { style: {} };

  static getDerivedStateFromProps(nextProps) {
    try {
      const {
        position: { x, y },
        resourceId,
        optimizedResourceId,
        thumbnailUrl,
        flipH,
        borderWidth,
        borderColor,
        borderRadius,
        borderRadiusUnit,
        flipV,
        opacity,
        dropShadowEnabled,
        dropShadow,
        backgroundProperties: backgroundPropertiesProps,
        picWhileCroppingProperties,
        forSnapshot,
      } = nextProps;

      const bgCalculated = {
        x: picWhileCroppingProperties.x - x,
        y: picWhileCroppingProperties.y - y,
        width: picWhileCroppingProperties.width,
        height: picWhileCroppingProperties.height,
      };
      const backgroundProperties = forSnapshot ? bgCalculated : backgroundPropertiesProps;

      const imgUrl = resourceId
        ? getResourceUrl(optimizedResourceId || resourceId, 'ImageElement', true)
        : thumbnailUrl;

      const style = {
        borderWidth,
        borderColor,
        borderRadius: `${borderRadius}${borderRadiusUnit}`,
        backgroundPosition: `${backgroundProperties.x}px ${backgroundProperties.y}px`,
        backgroundSize: `${backgroundProperties.width}px ${backgroundProperties.height}px`,
        backgroundImage: `url(${imgUrl})`,
        transform: `scale(${flipH ? -1 : 1}, ${flipV ? -1 : 1})`,
        opacity,
        filter:
          dropShadowEnabled &&
          `drop-shadow(${dropShadow.offsetX}px ${dropShadow.offsetY}px ` +
            `${dropShadow.blurRadius}px ${dropShadow.color})`,
      };

      return {
        style,
      };
    } catch (e) {
      console.error(nextProps.id, nextProps, e);
      return { style: {} };
    }
  }

  render() {
    const { classes, className } = this.props;
    const { style } = this.state;
    return (
      <div className={classNames(classes.root, className)}>
        <div className={classNames(classes.image, 'image')} style={style} />
      </div>
    );
  }
}

ImageElement.propTypes = {
  backgroundProperties: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  forSnapshot: PropTypes.bool,
};

//to prevent error 'backgroundProperties is not defined' when quitting cropping state on clicking on banner
ImageElement.defaultProps = {
  backgroundProperties: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  forSnapshot: false,
};

export default compose(
  withStyles(styles),
  React.memo,
)(ImageElement);
