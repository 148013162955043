import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, Tabs, Tab, IconButton, Tooltip } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import CloseIcon from '@material-ui/icons/Close';
import ContentDuplicate from 'mdi-material-ui/ContentDuplicate';
import classNames from 'classnames';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { tabHeight } from '../reference/dimensions';
import { getSelectedBannerIndex, checkIfNoBanners } from '../shared-selectors/sharedSelectors';
import { openDeleteBannerDialog, selectBanner, addBannerCopy } from '../banners/bannersActionsCreators';
import { getBannersPresent, getBannersMasterIndex, getBannerMaster } from '../banners/bannersSelectors';
import { openFormatSelectorDialog } from '../banner-format-selector/bannerFormatSelectorDucks';
import { checkBannerIsDissociated } from './bannersUtils';
import { formatI18nBannerNameId } from '../banner/bannerUtils';
import elementIcons from '../reference/elementIcons';

let windowWidth = window.innerWidth;
window.addEventListener('onresize', function () {
  windowWidth = window.innerWidth;
});

const minTabWidth = 270;
const maxTabBarWidth = windowWidth - tabHeight;

const styles = theme => ({
  root: {
    minHeight: 20,
    boxShadow: theme.shadows[1],
    borderRadius: 0,
    zIndex: 105,
    backgroundColor: 'rgba(0,0,0,0.1)',
    display: 'flex',
    '& *': {
      boxSizing: 'border-box',
    },
  },
  tabBarRoot: {
    minHeight: 20,
    width: maxTabBarWidth,
  },
  tab: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  tabRoot: {
    height: tabHeight,
    minHeight: 0,
    minWidth: minTabWidth,
    padding: 0,
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid rgba(0,0,0,0.05)',
    textAlign: 'center',
  },
  tabRootMaster: {
    border: '2px solid rgba(0,0,0,0.3)',
  },
  tabRootDissociate: {
    filter: 'blur(1.5px)',
  },
  tabSelected: {
    backgroundColor: 'rgba(255,255,255,1)',
  },
  tabLabel: {
    display: 'flex',

    textTransform: 'initial',
  },
  tabIndicator: {
    display: 'none',
  },
  actionButton: {
    position: 'absolute',
    width: 20,
    height: 20,
    padding: 0,
    fontSize: 16,
  },
  duplicateButton: {
    left: 0,
  },
  closeButton: {
    right: 0,
  },
  newBannerButton: {
    fontSize: 16,
    marginTop: 'auto',
    marginBottom: 'auto',
    width: tabHeight,
    height: tabHeight,
    padding: 0,
    color: 'rgba(0, 0, 0, 0.54)',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 0,
  },
  subtitle: {
    marginLeft: 5,
    opacity: 0.35,
  },
  scrollButtonsDesktop: {
    maxWidth: tabHeight,
    color: 'rgba(0, 0, 0, 0.54)',
    // material-ui bugs when scroll buttons has a border and we add a tab. escape: through pseudo element
    '&::after': {
      position: 'absolute',
      width: tabHeight,
      height: tabHeight,
      top: 0,
      left: 0,
      content: '""',
      border: '1px solid rgba(0, 0, 0, 0.1)',
    },
  },
});

class TabBar extends React.Component {
  state = {
    stylesState: {},
    classesState: {},
    variantSize: undefined,
  };

  static getDerivedStateFromProps(nextProps) {
    const { banners, classes } = nextProps;
    const tabClasses = {
      selected: classes.tabSelected,
    };
    return {
      classesState: {
        tabs: {
          root: classes.tabBarRoot,
          indicator: classes.tabIndicator,
          scrollButtonsDesktop: classes.scrollButtonsDesktop,
        },
        tab: {
          root: classes.tabRoot,
          ...tabClasses,
        },
        tabMaster: {
          root: classNames(classes.tabRoot, classes.tabRootMaster),
          ...tabClasses,
        },
        tabDissociate: {
          root: classNames(classes.tabRoot, classes.tabRootDissociate),
          ...tabClasses,
        },
      },
      variantSize: maxTabBarWidth / minTabWidth < banners.size ? 'scrollable' : 'fullWidth',
    };
  }

  onTabChange = (_, tabIndex) => {
    if (this.props.selectedIndex !== tabIndex) this.props.selectBanner(tabIndex, 'tab'); // to prevent change when copying banner
  };

  stopPropagation = e => e.stopPropagation();

  onCloseBanner = e => {
    e.stopPropagation();
    this.props.openDeleteBannerDialog(this.props.selectedIndex);
  };

  handleNewBanner = () => {
    this.props.openFormatSelectorDialog({ isForNewBannerCreation: true });
  };

  render() {
    const {
      classes,
      banners,
      noBanners,
      selectedIndex,
      intl,
      addBannerCopy,
      bannerMasterIndex,
      bannerMaster,
    } = this.props;
    const { classesState, variantSize } = this.state;
    return (
      <div className={classes.root}>
        {!noBanners && (
          <React.Fragment>
            <Tabs
              value={selectedIndex}
              onChange={this.onTabChange}
              textColor="primary"
              scrollButtons="auto"
              variant={variantSize}
              classes={classesState.tabs}
            >
              {banners.map((banner, idx) => {
                const dissociateFromMaster = checkBannerIsDissociated({
                  bannerSlave: banner,
                  bannerMaster,
                  debug: false,
                });
                const { name, format } = banner;
                const isBannerMasterIdx = bannerMasterIndex === idx;
                const bannerIsDissociatedAndSelected = dissociateFromMaster && bannerMasterIndex === selectedIndex;
                let tabClasses;
                if (isBannerMasterIdx) {
                  tabClasses = classesState.tabMaster;
                } else {
                  tabClasses = bannerIsDissociatedAndSelected ? classesState.tabDissociate : classesState.tab;
                }
                return (
                  <Tab
                    key={name}
                    component="div"
                    classes={tabClasses}
                    label={
                      <Fragment>
                        <span>
                          {format.width} x {format.height}
                        </span>
                        <span className={classes.subtitle}>({formatI18nBannerNameId(intl, format.name)})</span>
                        {idx === selectedIndex && (
                          <Fragment>
                            <IconButton
                              className={classNames(classes.actionButton, classes.closeButton)}
                              onMouseDown={this.stopPropagation}
                              onClick={this.onCloseBanner}
                            >
                              <CloseIcon className={classes.buttonIcon} fontSize="inherit" />
                            </IconButton>
                            <Tooltip title={intl.formatMessage({ id: 'app.menu.bannerNewFromCurrent' })}>
                              <IconButton
                                className={classNames(classes.actionButton, classes.duplicateButton)}
                                onClick={addBannerCopy}
                                onMouseDown={this.stopPropagation}
                              >
                                <ContentDuplicate className={classes.buttonIcon} fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </Fragment>
                        )}
                      </Fragment>
                    }
                  />
                );
              })}
            </Tabs>
            <Tooltip title={intl.formatMessage({ id: 'app.menu.bannerNew' })}>
              <IconButton
                color="inherit"
                className={classes.newBannerButton}
                onMouseDown={this.stopPropagation}
                onClick={this.handleNewBanner}
              >
                {elementIcons.addNewBanner}
              </IconButton>
            </Tooltip>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const makeStateToProps = () => state => ({
  banners: makeSelectorInstance(getBannersPresent)(state),
  selectedIndex: makeSelectorInstance(getSelectedBannerIndex)(state),
  bannerMasterIndex: makeSelectorInstance(getBannersMasterIndex)(state),
  bannerMaster: makeSelectorInstance(getBannerMaster)(state),
  noBanners: makeSelectorInstance(checkIfNoBanners)(state),
});

const dispatchToProps = {
  openFormatSelectorDialog,
  selectBanner,
  openDeleteBannerDialog,
  addBannerCopy,
};

export default compose(connect(makeStateToProps, dispatchToProps), injectIntl, withStyles(styles))(TabBar);
