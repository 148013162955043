import fetchDrbApi from './fetchDrbApi';
import fetchDrbApiEntities from './fetchDrbApiEntities';
import fetchDrbApiEntitiesDCO from './fetchDrbApiEntitiesDCO';
import fetchDrbApiEntityById from './fetchDrbApiEntityById';
import fetchDrbApiUserTheme from './fetchDrbApiUserTheme';

export default {
  fetch: fetchDrbApi,
  entities: fetchDrbApiEntities,
  dco: fetchDrbApiEntitiesDCO,
  entityById: fetchDrbApiEntityById,
  userTheme: fetchDrbApiUserTheme,
};
