import { put, select, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { addResource, deleteResource } from '../resources/resourcesDucks';
import { getUserFonts } from '../shared-selectors/sharedSelectors';
import { BANNER_CLEAR_STATE } from '../banner/bannerActions';
import { setElementFontFamilyRequested } from '../banner/bannerActionsCreators';
import { BANNERS_CLOSE_ALL } from '../banners/bannersActions';
import { availableFonts, loadFont, unloadFont } from '../resources/fonts';
import { addError, addInfo } from '../messages/messagesDucks';
import { getSelectedElementId } from '../temporary-status/temporaryStatusDucks';
// Actions

export const FONT_UPLOAD_DIALOG_OPEN = 'adbuilder/FONT_UPLOAD_DIALOG_OPEN';
export const FONT_UPLOAD_DIALOG_CLOSE = 'adbuilder/FONT_UPLOAD_DIALOG_CLOSE';
const FONT_ADD_REQUESTED = 'adbuilder/FONT_ADD_REQUESTED';
export const FONT_ADDED = 'adbuilder/FONT_ADDED';

// Action creators

export const openUploadFontDialog = (file = null) => ({
  type: FONT_UPLOAD_DIALOG_OPEN,
  payload: {
    file,
  },
});

export const closeUploadFontDialog = () => ({
  type: FONT_UPLOAD_DIALOG_CLOSE,
});

export const addFont = (file, fontName, updateSelectedElement, id) => ({
  type: FONT_ADD_REQUESTED,
  payload: { file, fontName, updateSelectedElement, id },
});

const addedFont = (fontName, updateSelectedElement) => ({
  type: FONT_ADDED,
  payload: { fontName, updateSelectedElement },
});

// Selectors

export const getAllFontNames = createSelector(getUserFonts, userFonts => {
  const fontNames = [...Object.keys(userFonts), ...availableFonts.map(font => font.name)];
  fontNames.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  return fontNames;
});

export const getFontUploadDialogKey = ({ fonts }) => fonts.uploadDialogKey;
export const getFontUploadFile = ({ fonts }) => fonts.fontImport.file;
export const getFonts = ({ fonts }) => (fonts ? fonts.fonts : null);
export const getFontMenuOpen = ({ fonts }) => fonts.menuOpen;

// Sagas

export function* addFontSaga({ payload: { file, fontName, updateSelectedElement, id } }) {
  // Do no overwrite default fonts
  if (availableFonts.some(font => font.name === fontName)) {
    if (updateSelectedElement) yield put(addError('uploadFont.error.duplicate'));
    return;
  }

  // Unload a previously loaded user font, to overwrite it.
  const userFonts = yield select(getUserFonts);
  if (userFonts.hasOwnProperty(fontName)) {
    yield put(deleteResource(userFonts[fontName]));
    unloadFont(fontName);
  }

  // resourceId in the "callback" action will be filled by the resource manager.
  yield put(addResource(file, addedFont(fontName, updateSelectedElement), id));
}

export function* addedFontSaga({ payload: { fontName, updateSelectedElement } }) {
  if (updateSelectedElement) {
    // Select font for the selected element
    const elementId = yield select(getSelectedElementId);
    yield put(setElementFontFamilyRequested({ id: elementId, fontFamily: fontName }));
    yield put(addInfo('uploadFont.message.success', { fontName }));
  } else {
    // Preload the font
    const userFonts = yield select(getUserFonts);
    yield loadFont(fontName, userFonts, 'addedFontSaga');
  }
}

export function* saga() {
  yield takeEvery(FONT_ADD_REQUESTED, addFontSaga);
  yield takeEvery(FONT_ADDED, addedFontSaga);
}

// Reducer

export const initialState = {
  uploadDialogOpen: false,
  uploadDialogKey: 0,
  importing: false,
  fontImport: { file: null },
  fonts: {}, // Font resource ids, indexed by name
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FONT_UPLOAD_DIALOG_OPEN: {
      return {
        ...state,
        uploadDialogOpen: true,
        uploadDialogKey: state.uploadDialogKey + 1,
        fontImport: { ...action.payload },
      };
    }
    case FONT_UPLOAD_DIALOG_CLOSE: {
      return {
        ...state,
        uploadDialogKey: state.uploadDialogKey + 1,
        uploadDialogOpen: false,
      };
    }
    case FONT_ADD_REQUESTED: {
      return {
        ...state,
        importing: true,
      };
    }
    case FONT_ADDED: {
      const { fontName, resourceId } = action.payload;
      return {
        ...state,
        uploadDialogOpen: false,
        importing: false,
        fonts: {
          ...state.fonts,
          [fontName]: resourceId,
        },
      };
    }
    case BANNER_CLEAR_STATE:
    case BANNERS_CLOSE_ALL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
