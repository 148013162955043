import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { checkKeyDiff } from './helpers';
import { injectIntl } from 'react-intl';

class CheckboxDoped extends React.Component {
  shouldComponentUpdate(nextProps) {
    return Boolean(checkKeyDiff(nextProps, this.props));
  }

  onChange = () => {
    this.props.toggleFunction(!this.props.checked, this.props.id, this.props.slideIndex);
  };

  render() {
    const { disabled, checked, intl, intlId, toggleFunction, slideIndex, id, ...props } = this.props;
    return (
      <FormControlLabel
        disabled={disabled}
        //eslint-disable-next-line
        control={<Checkbox checked={checked} color="primary" onChange={this.onChange} />}
        label={intlId ? intl.formatMessage({ id: intlId }) : null}
        {...props}
      />
    );
  }
}

CheckboxDoped.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  intlId: PropTypes.string,
  toggleFunction: PropTypes.func,
};

CheckboxDoped.defaultProps = {
  disabled: false,
  intlId: undefined,
  toggleFunction: () => {
    return;
  },
};

export default injectIntl(CheckboxDoped);
