import React from 'react';
import { connect } from 'react-redux';
import { FormControl, RadioGroup, Radio, FormControlLabel, withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import classNames from 'classnames';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { styles } from '../propertyEditorStyles';
import { updateElement } from '../../banner/bannerActionsCreators';
import { getSelectedElementTransitionsOnAllSlides } from '../../banner/bannerSelectors';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

class ElementTransitionShowOnAllSlides extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return {
      classesState: {
        radio: { root: classes.radioButton },
        radioGroup: { root: classNames(classes.radioGroup, classes.radioGroupCentered) },
      },
    };
  }

  toggleTransitionOnAllSlides = e => {
    const { transitionsOnAllSlides, updateElement, id } = this.props;
    updateElement({ transitionsOnAllSlides: !transitionsOnAllSlides, id }); // tracked
  };

  render() {
    const { transitionsOnAllSlides, intl } = this.props;
    const { classesState } = this.state;

    return (
      <React.Fragment>
        <FormControl fullWidth>
          <RadioGroup
            name="default distance"
            classes={classesState.radioGroup}
            value={transitionsOnAllSlides ? 'all-slides' : 'first-and-last'}
            onChange={this.toggleTransitionOnAllSlides}
          >
            <FormControlLabel
              value="all-slides"
              //eslint-disable-next-line
              control={<Radio color="primary" fontSize="10" classes={classesState.radio} />}
              label={intl.formatMessage({ id: 'properties.transition.showOnAllSlides' })}
            />
            <FormControlLabel
              value="first-and-last"
              //eslint-disable-next-line
              control={<Radio color="primary" fontSize="10" classes={classesState.radio} />}
              label={intl.formatMessage({ id: 'properties.transition.showOnFirstAndLastSlides' })}
            />
          </RadioGroup>
        </FormControl>
      </React.Fragment>
    );
  }
}

const makeStateToProps = () => state => ({
  transitionsOnAllSlides: makeSelectorInstance(getSelectedElementTransitionsOnAllSlides)(state),
  id: makeSelectorInstance(getSelectedElementId)(state),
});

const dispatchToProps = {
  updateElement,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(ElementTransitionShowOnAllSlides);
