import {
  resourcesFolderName,
  bannerSetThumbnailName,
  bannerThumbnailName,
  imageEndName,
  originalResourcesFolderName,
} from '../../reference/filesNames';
import { Map } from 'immutable';
import { unzipImages } from './unzipImages';

export const importResources = async (zip, banners, bannerSetInfos, unzippedPlayers, originalImagesUnzipped) => {
  try {
    let resources = [];
    // import thumbnails

    // 3. import resources (thumbnails and images)
    const thumbnails = await Promise.all(
      Map(zip.files)
        .valueSeq()
        .filter(file => !file.dir)
        .filter(file => (file.name.endsWith(imageEndName('png')) || file.name.endsWith(imageEndName('jpeg'))))
        .toArray()
        .map(file => file.async('blob').then(blob => ({ name: file.name, blob }))),
    );

    for (const { name, blob } of thumbnails) {
      const isBannerSetThumbnail = name === bannerSetThumbnailName('png') || name === bannerSetThumbnailName('jpeg')
      let id =
        isBannerSetThumbnail
          ? bannerSetInfos.thumbnailResourceId
          : banners
            .filter(banner => bannerThumbnailName(banner.format, 'png') === name || bannerThumbnailName(banner.format, 'jpeg') === name)
              .map(({ thumbnailResourceId }) => thumbnailResourceId)[0];
      resources.push({ blob, id });
    }

    // import players resources
    for (const unzippedPlayer of unzippedPlayers) {
      const bannerImages = await unzipImages(unzippedPlayer, resourcesFolderName);
      resources.push(...bannerImages);
    }

    // import original images resources
    let originalImages = [];
    if (originalImagesUnzipped) {
      originalImages = await unzipImages(originalImagesUnzipped, originalResourcesFolderName);
    }
    resources.push(...originalImages);

    const resourcesObject = {};
    for (const resource of resources) {
      resourcesObject[resource.id] = resource;
    }

    const resourceMap = Map(resourcesObject)
      .valueSeq()
      .toArray();
    return resourceMap;
  } catch (e) {
    console.error('cant import resources', e);
  }
};
