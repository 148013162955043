import JSZip from 'jszip';
import { Map } from 'immutable';
import { bannerFromJS } from '../../reference/records';
import { importResources } from './importResources';
import { importAllFonts } from './importAllFonts';
import { debug } from './params';
import { metadataJSONName, originalResourcesZipName } from '../../reference/filesNames';
import { playerZipExtension } from '../../reference/importParams';

export const importBannerSet = async zipBannerSet => {
  try {
    debug && console.log({ zipBannerSet });
    const zip = await JSZip.loadAsync(zipBannerSet);
    debug && console.log({ zip });

    // 1. import metadata...
    const bannersJson = await zip.file(metadataJSONName).async('string');
    const bannersState = JSON.parse(bannersJson);
    debug && console.log({ bannersState });
    // ...including banners...
    const banners = bannersState.banners.map(banner => bannerFromJS(banner));
    // ...and bannerset...
    const bannerSetInfos = {
      ...bannersState.bannerSet,
      uid: undefined,
    };
    debug && console.log({ bannerSetInfos });
    debug && console.log(zip.file(originalResourcesZipName));
    ///...and original images...
    let originalImagesUnzipped;
    if (zip.file(originalResourcesZipName)) {
      const originalImagesZip = await zip.file(originalResourcesZipName).async('blob');
      originalImagesUnzipped = await JSZip.loadAsync(originalImagesZip);
    }
    debug && console.log({ originalImagesUnzipped });

    // 2. import and unzip players
    const unzippedPlayers = await Promise.all(
      Map(zip.files)
        .valueSeq()
        .filter(file => !file.dir)
        .filter(file => file.name.endsWith(playerZipExtension))
        .toArray()
        .map(file => file.async('blob'))
        .map(file => JSZip.loadAsync(file)),
    );
    debug && console.log({ unzippedPlayers });

    // 3. import resources (thumbnails and images)
    const resources = await importResources(zip, banners, bannerSetInfos, unzippedPlayers, originalImagesUnzipped);
    // 4. import fonts
    const fonts = await importAllFonts(unzippedPlayers);

    return { banners, bannerSetInfos, resources, fonts };
  } catch (e) {
    console.error('error while importing bannerset from computer', e);
  }
};
