import { slideTransitionsDetails } from '../../reference/transitions';
import { calculateSlideOwnDelayForTransitionOut } from '.';
import { getAnimationIn } from './getAnimationIn';
import { getAnimationOut } from './getAnimationOut';

export const setSlideAnimationProperties = (
  _,
  { isLastSlide, slide: slideProp, slidesForAnimation, slideIndex, slideDurationForAnimation, animationGlobalDelay },
) => {
  /*the transition of index n is for slides n-1 and n*/
  const slide = slidesForAnimation ? slidesForAnimation[slideIndex].slide : slideProp;
  const nextSlide = isLastSlide ? null : slidesForAnimation[slideIndex + 1].slide;
  let animationOwnDelayForTransitionOut = isLastSlide ? 0 : calculateSlideOwnDelayForTransitionOut({ nextSlide });

  const animationIn = getAnimationIn(slide);
  const animationOut = getAnimationOut(isLastSlide, nextSlide);

  const animationDurationIn =
    slideTransitionsDetails[slide.transition.type].influence.currentSlide * slide.transition.duration;

  const animationDurationOut = nextSlide
    ? slideTransitionsDetails[nextSlide.transition.type].influence.prevSlide * nextSlide.transition.duration
    : 0;

  const animationDelayOut =
    animationGlobalDelay + (slideDurationForAnimation || slide.duration) - animationOwnDelayForTransitionOut;

  const animationProperties = {
    animationName: `${animationIn}, ${animationOut}`,
    animationDuration: `${animationDurationIn}s, ${animationDurationOut}s`,
    animationDelay: `${animationGlobalDelay}s, ${animationDelayOut}s`,
    animationIterationCount: '1, 1',
    animationTimingFunction: 'ease-in-out, ease-in-out',
    animationFillMode: 'both, forwards',
    animationDirection: 'normal, normal',
    overflow: 'hidden', //for transition effects between slides
  };
  return { animationProperties };
};
