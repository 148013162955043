import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose, onlyUpdateForKeys } from 'recompose';
import { withStyles, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { getSelectedElements } from '../../banner/bannerSelectors';
import { setElementsTransitionInType, setElementsTransitionInDuration } from '../../banner/bannerActionsCreators';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { styles } from '../propertyEditorStyles';
import { transitionTypes, transitionTypesVar } from '../../reference/transitions';
import { allElementsHasSameDeepProperty } from '../helpers';
import {
  toggleTransitionInMenuOpen,
  getTemporaryTransitionInMenuOpen,
} from '../../temporary-text-editor/temporaryTextEditorDucks';
import { getSelectedElementIds } from '../../temporary-status/temporaryStatusDucks';

const selectTransitionInTypeInputPropId = { id: 'transition-in-type' };

class ElementsTransitionInType extends React.Component {
  state = { classesState: {} };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { classes } = nextProps;
    return {
      ...prevState,
      classesState: {
        menuItem: { selected: classes.menuSelected, root: classes.menuItem },
      },
    };
  }

  onTransitionTypeChange = e => {
    const { setElementsTransitionInType, setElementsTransitionInDuration, ids } = this.props;
    setElementsTransitionInType({ ids, type: e.target.value });
    if (e.target.value === transitionTypesVar.none) {
      setElementsTransitionInDuration({ ids, duration: 0 });
    }
  };

  onOpen = () => {
    const { toggleTransitionInMenuOpen } = this.props;
    toggleTransitionInMenuOpen(true);
  };

  onClose = () => {
    const { toggleTransitionInMenuOpen } = this.props;
    toggleTransitionInMenuOpen(false);
  };

  render() {
    const { open, elements } = this.props;
    const { classesState } = this.state;
    return (
      <FormControl fullWidth>
        <InputLabel htmlFor="transition-in-type">
          <FormattedMessage id="properties.transition.type" />
        </InputLabel>
        <Select
          open={open}
          value={allElementsHasSameDeepProperty(elements, 'transitionIn', 'type') || ''}
          inputProps={selectTransitionInTypeInputPropId}
          onChange={this.onTransitionTypeChange}
          onOpen={this.onOpen}
          onClose={this.onClose}
        >
          {transitionTypes.map(transition => (
            <MenuItem key={transition} value={transition} classes={classesState.menuItem}>
              <FormattedMessage id={`properties.transition.type.${transition}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

const makeStateToProps = () => state => ({
  elements: makeSelectorInstance(getSelectedElements)(state),
  ids: makeSelectorInstance(getSelectedElementIds)(state),
  open: makeSelectorInstance(getTemporaryTransitionInMenuOpen)(state),
});

const dispatchToProps = {
  setElementsTransitionInType,
  setElementsTransitionInDuration,
  toggleTransitionInMenuOpen,
};
export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  onlyUpdateForKeys(['elements', 'open']),
  withStyles(styles),
)(ElementsTransitionInType);
