import { applyStyle } from '../util/dom-helpers';
import {
  checkIfSlideIsLastSlideOverall,
  checkIfSlideIsFirstSlideOverall,
  checkIfSlideIsLastSlideOfTour,
  checkIfSlideIsFirstSlideOfTour,
} from '../util/slidePositions';

import renderSlide from './renderSlide';

import './banner.css';
import { getSlidesForAnimation } from '../../banner/bannerUtils';

const renderBanner = ({ format: bannerFormat, repetitions, backgroundColor, slides, elements }) => {
  const dom = document.createElement('div');
  dom.className = 'banner';
  applyStyle(dom, {
    width: `${bannerFormat.width}px`,
    height: `${bannerFormat.height}px`,
  });

  let keyframes = [];

  const allSlides = getSlidesForAnimation(slides, repetitions);

  allSlides.forEach((slideDetails, idx) => {
    dom.appendChild(
      renderSlide({
        keyframes,
        bannerFormat,
        slide: slideDetails.slide,
        elements: elements,
        slideDelay: slideDetails.animationGlobalDelay,
        slideDuration: slideDetails.slideDurationForAnimation,
        slideIndex: slideDetails.slideIndex,
        allSlides: allSlides,
        isLastSlide: checkIfSlideIsLastSlideOverall(idx, allSlides),
        isFirstSlide: checkIfSlideIsFirstSlideOverall(idx),
        isLastSlideOfTour: checkIfSlideIsLastSlideOfTour(idx, allSlides, repetitions),
        isFirstSlideOfTour: checkIfSlideIsFirstSlideOfTour(idx, allSlides, repetitions),
        backgroundColor,
      }),
    );
  });

  return { renderedBanner: dom, keyframes };
};

export default renderBanner;
