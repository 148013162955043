import { takeLatest, call, put } from 'redux-saga/effects';
import { SECURITY_AUTH_ERROR, SECURITY_AUTH_RESET } from '../security/securityDucks';
import fetchDrbApi from '../api/fetchDrbApi';
// Actions

const LAYOUT_ELEMENT_LIST_TOGGLE = 'adbuilder/LAYOUT_ELEMENT_LIST_TOGGLE';
const LAYOUT_PROPERTY_EDITOR_TOGGLE = 'adbuilder/LAYOUT_PROPERTY_EDITOR_TOGGLE';
const LAYOUT_SLIDES_TOGGLE = 'adbuilder/LAYOUT_SLIDES_TOGGLE';
const LAYOUT_GET_THEME_REQUESTED = 'adbuilder/LAYOUT_GET_THEME_REQUESTED';
export const LAYOUT_SET_THEME = 'adbuilder/LAYOUT_SET_THEME';
const LAYOUT_APP_LOADED = 'adbuilder/LAYOUT_APP_LOADED';
// Action creators

export const toggleElementList = open => ({
  type: LAYOUT_ELEMENT_LIST_TOGGLE,
  payload: open,
});

export const togglePropertyEditor = open => ({
  type: LAYOUT_PROPERTY_EDITOR_TOGGLE,
  payload: open,
});

export const toggleSlides = open => ({
  type: LAYOUT_SLIDES_TOGGLE,
  payload: open,
});

export const getUserTheme = () => ({
  type: LAYOUT_GET_THEME_REQUESTED,
});

export const setUserTheme = userTheme => ({
  type: LAYOUT_SET_THEME,
  payload: userTheme,
});
export const appLoaded = () => ({
  type: LAYOUT_APP_LOADED,
});

// Selectors
export const isElementListOpen = ({ layout }) => layout.elementList.open;
export const isPropertyEditorOpen = ({ layout }) => layout.propertyEditor.open;
export const isSlidesListOpen = ({ layout }) => layout.slides.open;
export const getUserThemeFromState = ({ layout }) => layout.userTheme;

export function* getThemeSaga() {
  try {
    const userTheme = yield call(fetchDrbApi.userTheme);
    yield put(setUserTheme(userTheme));
  } catch (e) {
    console.error('getThemeSaga', e);
    yield put(setUserTheme());
  }
}

// Sagas
export function* saga() {
  yield takeLatest(LAYOUT_GET_THEME_REQUESTED, getThemeSaga);
}
// Reducer

export const initialState = {
  elementList: {
    open: true,
  },
  propertyEditor: {
    open: true,
  },
  slides: {
    open: true,
  },
  userTheme: {
    primaryColor: '#2cb8c4',
    secondaryColor: '#ffcc19',
  },
  adBuilderLoaded: false,
  // the authError is here only to show an error message on the welcome dialog.
  // it should be on the securityDucks but we don't want this info to be persisted, and securityDucks is persisted
  // so we choose to put it in the layoutDucks which is not persisted
  authError: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LAYOUT_SET_THEME: {
      return {
        ...state,
        userTheme: {
          ...action.payload,
        },
      };
    }
    case SECURITY_AUTH_RESET:
    case SECURITY_AUTH_ERROR: {
      return {
        ...state,
        authError: true,
      };
    }
    case LAYOUT_APP_LOADED: {
      return {
        ...state,
        adBuilderLoaded: true,
      };
    }
    case LAYOUT_ELEMENT_LIST_TOGGLE: {
      return {
        ...state,
        elementList: {
          ...state.elementList,
          open: action.payload,
        },
      };
    }
    case LAYOUT_PROPERTY_EDITOR_TOGGLE: {
      return {
        ...state,
        propertyEditor: {
          ...state.propertyEditor,
          open: action.payload,
        },
      };
    }
    case LAYOUT_SLIDES_TOGGLE: {
      return {
        ...state,
        slides: {
          ...state.slides,
          open: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
