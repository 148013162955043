import fetchNuxeo from './fetchNuxeo';

const fetchNuxeoBannerSetPermissions = async uid =>
  await fetchNuxeo({
    path: '/id/' + uid,
    headers: {
      'X-NXfetch.document': 'lock',
      'X-NXenrichers.document': 'acls',
    },
    errorMessage: "can't fetch bannerset permissions from nuxeo",
  })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      return res.json();
    })
    .catch(err => console.error('error fetching', err));

export default fetchNuxeoBannerSetPermissions;
