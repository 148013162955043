import { corners } from '../../../reference/corners';

const plusOrMinus = condition => {
  if (condition) return 1;
  return -1;
};

export const calculateMiddleHandlesCursorCases = ({
  omega,
  widthTooLow,
  heightTooLow,
  movingCorner,
  cursorPosition,
  diagonal,
  height,
  width,
  ALPHA,
}) => {
  switch (movingCorner) {
    case corners.middleTop: {
      if (heightTooLow) {
        return {
          cursorPositionChecked: cursorPosition,
          widthShouldBeConstant: true,
          heightShouldBeConstant: false,
        };
      }
      /*fixed corner: bottomRight (cf getFixedCornerWhileResizing method)*/

      const projection =
        plusOrMinus(Math.PI / 2 - omega < 0) *
        Math.sqrt(Math.pow(diagonal, 2) - Math.pow(diagonal * Math.cos(Math.PI / 2 - omega), 2));

      const cursorPositionChecked = {
        x: cursorPosition.x + (projection - width) * Math.cos(ALPHA),
        y: cursorPosition.y - (projection - width) * Math.sin(ALPHA),
      };
      return {
        cursorPositionChecked,
        widthShouldBeConstant: true,
        heightShouldBeConstant: false,
      };
    }

    case corners.middleRight: {
      if (widthTooLow) {
        return {
          cursorPositionChecked: cursorPosition,
          widthShouldBeConstant: false,
          heightShouldBeConstant: true,
        };
      }
      /*fixed corner: topLeft (cf getFixedCornerWhileResizing method)*/
      const projection =
        plusOrMinus(omega - Math.PI < 0) * Math.sqrt(Math.pow(diagonal, 2) - Math.pow(diagonal * Math.cos(omega), 2));

      const cursorPositionChecked = {
        x: cursorPosition.x + (projection + height) * Math.sin(ALPHA),
        y: cursorPosition.y + (projection + height) * Math.cos(ALPHA),
      };

      return {
        cursorPositionChecked,
        widthShouldBeConstant: false,
        heightShouldBeConstant: true,
      };
    }
    case corners.middleBottom: {
      if (heightTooLow) {
        return {
          cursorPositionChecked: cursorPosition,
          widthShouldBeConstant: true,
          heightShouldBeConstant: false,
        };
      }
      /*fixed corner: topLeft (cf getFixedCornerWhileResizing method)*/

      const projection =
        plusOrMinus((3 * Math.PI) / 2 - omega > 0) *
        Math.sqrt(Math.pow(diagonal, 2) - Math.pow(diagonal * Math.sin(2 * Math.PI - omega), 2));

      const cursorPositionChecked = {
        x: cursorPosition.x + (projection + width) * Math.cos(ALPHA),
        y: cursorPosition.y - (projection + width) * Math.sin(ALPHA),
      };
      return {
        cursorPositionChecked,
        widthShouldBeConstant: true,
        heightShouldBeConstant: false,
      };
    }

    case corners.middleLeft: {
      if (widthTooLow) {
        return {
          cursorPositionChecked: cursorPosition,
          widthShouldBeConstant: false,
          heightShouldBeConstant: true,
        };
      }
      /*fixed corner: bottomRight (cf getFixedCornerWhileResizing method)*/

      const projection =
        plusOrMinus(Math.PI - omega > 0) *
        Math.sqrt(Math.pow(diagonal, 2) - Math.pow(diagonal * Math.cos(Math.PI - omega), 2));

      const cursorPositionChecked = {
        x: cursorPosition.x + (projection - height) * Math.sin(ALPHA),
        y: cursorPosition.y + (projection - height) * Math.cos(ALPHA),
      };

      return {
        cursorPositionChecked,
        widthShouldBeConstant: false,
        heightShouldBeConstant: true,
      };
    }

    default: {
      return {
        cursorPositionChecked: cursorPosition,
        widthShouldBeConstant: false,
        heightShouldBeConstant: false,
      };
    }
  }
};
