import React from 'react';
import { connect } from 'react-redux';
import { FormControl, RadioGroup, Radio, FormControlLabel, withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import classNames from 'classnames';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { customTest } from '../../reference/customization';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import { getCustomTestChoice } from '../../entities/entitiesSelectors';
import { setCustomTestChoice } from '../../entities/entitiesActionCreators';
import { styles } from '../propertyEditorStyles';
import ElementCurrentValue from './ElementCurrentValue';
import ElementOtherEntitiesValues from './ElementOtherEntitiesValues';
import ElementMostLettersCase from './ElementMostLettersCase';

class ElementTestCustomization extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return {
      classesState: {
        radio: { root: classes.radioButton },
        radioGroup: { root: classNames(classes.radioGroup, classes.radioGroupCentered) },
      },
    };
  }

  changeCustomTestChoice = e => this.props.setCustomTestChoice(e.target.value);

  render() {
    const { customTestChoice, intl } = this.props;
    const { classesState } = this.state;

    return (
      <React.Fragment>
        <FormControl fullWidth>
          <RadioGroup
            name="custom-test"
            classes={classesState.radioGroup}
            value={customTestChoice}
            onChange={this.changeCustomTestChoice}
          >
            <FormControlLabel
              value={customTest.noTest}
              //eslint-disable-next-line
              control={<Radio color="primary" fontSize="inherit" classes={classesState.radio} />}
              label={intl.formatMessage({ id: `customization.live-test.${customTest.noTest}` })}
            />
            <FormControlLabel
              value={customTest.currentEntity}
              //eslint-disable-next-line
              control={<Radio color="primary" fontSize="inherit" classes={classesState.radio} />}
              label={intl.formatMessage({ id: `customization.live-test.${customTest.currentEntity}` })}
            />
            {customTestChoice === customTest.currentEntity && <ElementCurrentValue />}
            <FormControlLabel
              value={customTest.selectEntity}
              //eslint-disable-next-line
              control={<Radio color="primary" fontSize="inherit" classes={classesState.radio} />}
              label={intl.formatMessage({ id: `customization.live-test.${customTest.selectEntity}` })}
            />
            {customTestChoice === customTest.selectEntity && <ElementOtherEntitiesValues />}
            <FormControlLabel
              value={customTest.worstCaseScenario}
              //eslint-disable-next-line
              control={<Radio color="primary" fontSize="inherit" classes={classesState.radio} />}
              label={intl.formatMessage({ id: `customization.live-test.${customTest.worstCaseScenario}` })}
            />
            {customTestChoice === customTest.worstCaseScenario && <ElementMostLettersCase />}
          </RadioGroup>
        </FormControl>
      </React.Fragment>
    );
  }
}

const makeStateToProps = () => state => ({
  customTestChoice: makeSelectorInstance(getCustomTestChoice)(state),
  id: makeSelectorInstance(getSelectedElementId)(state),
});

const dispatchToProps = {
  setCustomTestChoice,
};
export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(ElementTestCustomization);
