import React from 'react';
import { compose, onlyUpdateForKeys } from 'recompose';
import { connect } from 'react-redux';
import { noChangeHelper } from '../constants';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementCustomValueMostLettersCase } from '../../banner/bannerSelectors';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementMostLettersCase extends React.Component {
  render() {
    const { id, mostLetters } = this.props;
    return (
      <TextFieldDoped
        disabled
        key={id}
        id={id}
        type="text"
        label={mostLetters.name}
        value={mostLetters.value}
        fullWidth
        multiline
        helper={noChangeHelper} //to prevent parseInt...
        onChangeMethod={this.onTextChange} //tracked
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  mostLetters: makeSelectorInstance(getSelectedElementCustomValueMostLettersCase)(state),
});

export default compose(
  connect(makeStateToProps),
  onlyUpdateForKeys(['mostLetters']),
)(ElementMostLettersCase);
