import { BANNER_CLEAR_STATE } from '../banner/bannerActions';
import { trackedActionsForSave } from '../banner/bannerFilteredActions';
import { MESSAGE_ADD } from '../messages/messagesDucks';
import { SAVE_BANNERS_NO_NEED } from '../save-banners-dialog/saveBannersDucks';

// Reducer
export const initialState = {
  needToSave: false,
};

const reducer = (state = initialState, action) => {
  // console.log(action.type, trackedActionsForSave, trackedActionsForSave.includes(action.type))
  if (trackedActionsForSave.includes(action.type) && !state.needToSave && !action.untrack) {
    return {
      ...state,
      needToSave: true,
    };
  }
  switch (action.type) {
    case MESSAGE_ADD: {
      if (action.payload && action.payload.message === 'nuxeo.bannerSet.success-saving') {
        return {
          needToSave: false,
        };
      }
      return state;
    }
    case SAVE_BANNERS_NO_NEED:
    case BANNER_CLEAR_STATE: {
      return {
        needToSave: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
