import PropTypes from 'prop-types';

export default PropTypes.shape({
  defaultFormats: PropTypes.object.isRequired,
  defaultLocale: PropTypes.oneOf(['en', 'fr', 'ja', 'de', 'es', 'it', 'ko']).isRequired,
  formatDate: PropTypes.func.isRequired,
  formatHTMLMessage: PropTypes.func.isRequired,
  formatMessage: PropTypes.func.isRequired,
  formatNumber: PropTypes.func.isRequired,
  formatPlural: PropTypes.func.isRequired,
  formatRelative: PropTypes.func.isRequired,
  formatTime: PropTypes.func.isRequired,
  formats: PropTypes.object.isRequired,
  formatters: PropTypes.object.isRequired,
  locale: PropTypes.oneOf(['en', 'fr', 'ja', 'de', 'es', 'it', 'ko']).isRequired,
  messages: PropTypes.object.isRequired,
  now: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  textComponent: PropTypes.string.isRequired,
  timeZone: PropTypes.any,
});
