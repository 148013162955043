import React from 'react';
import TextFieldForElements from '../TextFieldForElements';

class ElementsDimensionWidth extends React.Component {
  render() {
    const { shouldUpdateWithDiff } = this.props;
    return (
      <TextFieldForElements
        property="width"
        formattedMessage="properties.dimension.width"
        shouldUpdateWithDiff={shouldUpdateWithDiff}
      />
    );
  }
}

export default ElementsDimensionWidth;
