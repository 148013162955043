import { debug } from './params';
import { objectUrlToBlob } from './objectUrlToBlob';
import { getResourceUrl, getBestResourceId } from '../../resources/resourcesDucks';

export const zipImageResourcesBanner = (banner, zip) => {
  // Image resources
  const resourcesZip = zip.folder('resources');
  debug && console.log(banner.elements);
  Object.keys(banner.elements)
    .map(elementId => banner.elements[elementId])
    // .filter(el => el.resourceId)
    .filter(el => el.resourceId)
    .forEach(({ resourceId, optimizedResourceId, fileName }) =>
      resourcesZip.file(
        `${getBestResourceId(optimizedResourceId, resourceId)}/${fileName}`,
        objectUrlToBlob(getResourceUrl(getBestResourceId(optimizedResourceId, resourceId), 'zipImageResourcesBanner')),
      ),
    );
};
