import storage from 'redux-persist/lib/storage';
import { createMigrate, createTransform } from 'redux-persist';
import Serialize from 'remotedev-serialize';
import Immutable from 'immutable';

import { records } from './reference/records';
import { initialState } from './reducers';

const migrations = {
  100: () => initialState,
};

const serializer = Serialize.immutable(Immutable, records);
const immutableTransform = createTransform(serializer.stringify, serializer.parse);

export default {
  key: 'adbuilder',
  version: 100,
  storage,
  debug: false,
  migrate: createMigrate(migrations, { debug: false }),
  transforms: [immutableTransform],
  whitelist: ['security'],
};
