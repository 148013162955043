export default {
  toggleRotateResize: ['Meta'],
  lockAspectRatio: ['l'],
  lockAspectRatioOriginalSize: ['o'],
  disableGuides: ['g'],
  deleteElementRequested: ['Delete', 'Backspace'],
  enter: ['Enter'],
  cancel: ['Escape'],
  undo: ['z', 'Z'],
  command: ['Meta', 'Control'],
  shift: ['Shift'],
  moveLeft: ['ArrowLeft'],
  moveRight: ['ArrowRight'],
  moveUp: ['ArrowUp'],
  moveDown: ['ArrowDown'],
  multipleSelect: ['Meta', 'Shift', 'Control'],
  selectAll: ['a'],
};
