import { transitionTypesVar } from '../../reference/transitions';
import { allElementsHasSameDeepProperty } from './elementsGetters';

export const initTranslationDistances = ({
  currentElements,
  prevElements,
  setElementsTransitionTranslationDistance,
  setElementsTransitionTranslationDirection,
  bannerWidth,
  test = 'rien',
}) => {
  if (
    allElementsHasSameDeepProperty(currentElements, 'transitionIn', 'type') === transitionTypesVar.translation &&
    !allElementsHasSameDeepProperty(prevElements, 'transitionIn', 'type', transitionTypesVar.translation)
  ) {
    setElementsTransitionTranslationDistance({
      distance: parseInt(bannerWidth, 10),
      transitionName: 'transitionIn',
      untrack: true,
    });
    setElementsTransitionTranslationDirection({ direction: 'fromLeft', transitionName: 'transitionIn' });
  }
  if (
    allElementsHasSameDeepProperty(currentElements, 'transitionOut', 'type') === transitionTypesVar.translation &&
    !allElementsHasSameDeepProperty(prevElements, 'transitionOut', 'type', transitionTypesVar.translation)
  ) {
    setElementsTransitionTranslationDistance({
      distance: parseInt(bannerWidth, 10),
      transitionName: 'transitionOut',
      untrack: true,
    });
    setElementsTransitionTranslationDirection({ direction: 'toRight', transitionName: 'transitionOut' });
  }
};
