import { takeLatest, call, put, select, take, takeEvery } from 'redux-saga/effects';
import {
  ENTITIES_ENTITIES_REQUESTED,
  ENTITIES_GET_ENTITIES_SUCCESS,
  ENTITIES_GET_ENTITIES_FAILURE,
  ENTITIES_OPEN_MOVE_DIALOG,
  ENTITIES_OPEN_SAVE_AS_DIALOG,
  ENTITIES_OPEN_SHARE_DIALOG,
  ENTITIES_CLOSE_DIALOG,
  ENTITIES_ENTITY_CLICK,
  ENTITIES_VALIDATE_REQUESTED,
  ENTITIES_VALIDATE_PERMISSIONS_REQUESTED,
  ENTITIES_TOGGLE_ENTITY_READ_PERMISSION,
  ENTITIES_VALIDATE_PERMISSIONS_SUCCESS,
  ENTITIES_USER_ENTITY_REQUESTED,
  ENTITIES_SET_CUSTOM_TEST_CHOICE,
  ENTITIES_SET_CUSTOM_TEST_ENTITY,
  ENTITIES_USER_ENTITY_SUCCESS,
  ENTITIES_USER_ENTITY_FAILURE,
  ENTITIES_DCO_REQUESTED,
  ENTITIES_GET_DCO_SUCCESS,
  ENTITIES_GET_DCO_FAILURE,
  ENTITIES_MOVE_REQUESTED,
} from './entitiesActions';
import {
  failureGetEntities,
  successGetEntities,
  valideEntitiesPermissionsSuccess,
  successGetUserOwnerEntity,
  failureGetUserOwnerEntity,
  getUserEntityRequested,
  successGetDCO,
  failureGetDCO,
} from './entitiesActionCreators';
import { generateEntitiesList, generateInitEntities } from './entitiesUtils';
import {
  getBannerSetOwnerEntityId,
  getBannerSetName,
  getBannerSetUid,
  getBannerSetPermissions,
  getBannerSetExpirationDate,
} from '../banners/bannersSelectors';
import {
  saveBannerSetToNuxeo,
  SAVE_BANNERS_SAVE_BANNERSET_TO_NUXEO_REQUESTED,
} from '../save-banners-dialog/saveBannersDucks';
import { MESSAGE_ADD, addError, addInfo } from '../messages/messagesDucks';
import { getSelectedEntityIdForSave, getEntities, moveOrSaveAsDialogIsOpenForSaveAs } from './entitiesSelectors';
import { setBannerSetInfos, setBannerSetName, setBannerSetPermissions } from '../banners/bannersActionsCreators';
import { customTest } from '../reference/customization';
import { getUserEntityId } from '../shared-selectors/sharedSelectors';
import fetchNuxeo from '../api/fetchNuxeo';
import fetchDrbApi from '../api/fetchDrbApi';
import { extractPermissions } from '../banners/bannersUtils';
// Actions

let initEntities = [];
// Sagas

export function* fetchUserEntitySaga() {
  try {
    const userEntityId = yield select(getUserEntityId);
    const userEntity = yield call(fetchDrbApi.entityById, userEntityId);
    if (userEntity.message === 'ERROR.FORBIDDEN') {
      throw new Error('cannot fetch user entity');
    }
    yield put(successGetUserOwnerEntity(userEntity));
  } catch (e) {
    console.error(e);
    // yield put(newSnackbar(i18n.t('ERROR.UNEXPECTED'), null, SnackbarTypes.error));
    yield put(failureGetUserOwnerEntity());
  }
}

export function* fetchEntitiesSaga() {
  try {
    const entities = yield call(fetchDrbApi.entities);
    const bannerWorkspaceOwnerId = yield select(getBannerSetOwnerEntityId);
    initEntities = yield call(generateInitEntities, entities, bannerWorkspaceOwnerId);
    yield put(successGetEntities(initEntities));
    yield put(getUserEntityRequested());
  } catch (e) {
    yield put(failureGetEntities());
    console.error('fetchEntitiesSaga', e);
  }
}

export function* fetchDCOSaga() {
  try {
    const dco = yield call(fetchDrbApi.dco);
    yield put(successGetDCO(dco));
  } catch (e) {
    yield put(failureGetDCO());
    console.error('fetchEntitiesSaga', e);
  }
}

export function* moveOrSaveAsSaga({ payload: saveAsName }) {
  const forSaveAs = yield select(moveOrSaveAsDialogIsOpenForSaveAs);

  try {
    // move or save as the banner-set
    if (forSaveAs) {
      const bannerSetName = yield select(getBannerSetName);
      if (bannerSetName !== saveAsName) yield put(setBannerSetName(saveAsName));
    }
    const entityId = yield select(getSelectedEntityIdForSave);
    const userWorkspacePath = yield call(fetchNuxeo.userWorkspace, entityId);

    yield put(saveBannerSetToNuxeo(userWorkspacePath));
    // update the entities tree
    yield take(MESSAGE_ADD);
    const entities = yield select(getEntities);
    const bannerWorkspaceOwnerId = yield select(getBannerSetOwnerEntityId);
    initEntities = yield call(generateInitEntities, entities, bannerWorkspaceOwnerId);
    yield put(successGetEntities(initEntities));
    yield put(getUserEntityRequested());
    if (forSaveAs) {
      yield put(addInfo('banners.entities.save-as.success'));
    }
  } catch (e) {
    if (forSaveAs) {
      yield put(addError('banners.entities.save-as.error'));
    }
    console.error('moveOrSaveAsSaga', e);
  }
}

export function* toggleEntityReadPermissionsFinishSaga({ payload: { share } }) {
  try {
    yield put(valideEntitiesPermissionsSuccess());
    yield put(addInfo(share ? 'banners.entities.share.success' : 'banners.entities.unshare.success'));
  } catch (e) {
    yield put(addError(share ? 'banners.entities.share.error' : 'banners.entities.unshare.error'));
    console.error('toggleEntityReadPermissionsFinishSaga', e);
  }
}

export function* toggleEntityReadPermissionSaga({ payload: { giveReadPermission } }) {
  try {
    const uid = yield select(getBannerSetUid);
    const entityId = yield select(getSelectedEntityIdForSave);
    const userWorkspacePath = yield call(fetchNuxeo.userWorkspace, entityId);
    const permissions = yield select(getBannerSetPermissions);

    const readPermission = permissions.find(p => p.includes('members'));

    let newPermissions;
    if (giveReadPermission && !readPermission) {
      newPermissions = yield call(fetchNuxeo.setReadPermission, uid);

      const bannerSet = yield call(fetchNuxeo.moveBannerSet, uid, `${userWorkspacePath}/assets`);
      yield put(
        setBannerSetInfos({
          workspacePath: bannerSet.path,
        }),
      );
    } else {
      if (readPermission) {
        newPermissions = yield call(fetchNuxeo.deletePermission, uid);
      }
    }

    yield put(setBannerSetPermissions(newPermissions));
  } catch (e) {
    yield put(addError('banners.entities.share.error'));
    console.error('toggleEntityReadPermissionsSaga', e);
  }
}

export function* moveSaga() {
  try {
    const uid = yield select(getBannerSetUid);
    const entityId = yield select(getSelectedEntityIdForSave);
    const userWorkspacePath = yield call(fetchNuxeo.userWorkspace, entityId);
    const bannerSetExpirationDate = yield select(getBannerSetExpirationDate);

    const bannerSet = yield call(fetchNuxeo.moveBannerSet, uid, `${userWorkspacePath}/assets`);

    yield put(
      setBannerSetInfos({
        uid: bannerSet.uid,
        workspacePath: bannerSet.path,
        permissions: extractPermissions(bannerSet),
        lockOwner: bannerSet.lockOwner,
        expirationDate: bannerSetExpirationDate,
      }),
    );

    yield put(addInfo('banners.entities.move.success'));

    yield take(MESSAGE_ADD);
    const entities = yield select(getEntities);
    const bannerWorkspaceOwnerId = yield select(getBannerSetOwnerEntityId);
    initEntities = yield call(generateInitEntities, entities, bannerWorkspaceOwnerId);
    yield put(successGetEntities(initEntities));
  } catch (e) {
    yield put(addError('banners.entities.move.error'));
    console.error('moveSaga', e);
  }
}

export function* saga() {
  yield takeLatest(ENTITIES_ENTITIES_REQUESTED, fetchEntitiesSaga);
  yield takeLatest(ENTITIES_DCO_REQUESTED, fetchDCOSaga);
  yield takeLatest(ENTITIES_USER_ENTITY_REQUESTED, fetchUserEntitySaga);
  yield takeLatest(ENTITIES_VALIDATE_REQUESTED, moveOrSaveAsSaga);
  yield takeEvery(ENTITIES_VALIDATE_PERMISSIONS_REQUESTED, toggleEntityReadPermissionsFinishSaga);
  yield takeEvery(ENTITIES_TOGGLE_ENTITY_READ_PERMISSION, toggleEntityReadPermissionSaga);
  yield takeLatest(ENTITIES_MOVE_REQUESTED, moveSaga);
}

// Reducer
export const initialState = {
  collection: [],
  dco: [],
  userEntity: {},
  customTestChoice: customTest.noTest, // current-entity, select-entity-{entityId}, worst-case-scenario
  open: false,
  key: 0,
  selectedEntityForSave: undefined, // for move / save-as
  selectedEntityForCusto: undefined,
  toggledEntitiesPermission: {},
  for: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ENTITIES_OPEN_MOVE_DIALOG: {
      return {
        ...state,
        open: true,
        for: 'move',
        key: state.key + 1,
      };
    }
    case ENTITIES_OPEN_SAVE_AS_DIALOG: {
      return {
        ...state,
        open: true,
        for: 'save-as',
        key: state.key + 1,
      };
    }
    case ENTITIES_OPEN_SHARE_DIALOG: {
      return {
        ...state,
        open: true,
        for: 'share',
        key: state.key + 1,
      };
    }
    case ENTITIES_VALIDATE_PERMISSIONS_SUCCESS:
    case ENTITIES_CLOSE_DIALOG: {
      return {
        ...state,
        open: false,
        for: '',
        key: state.key + 1,
        collection: state.for === 'share' ? state.collection : initEntities,
        selectedEntityForSave: undefined,
        toggledEntitiesPermission: {},
      };
    }
    case ENTITIES_ENTITY_CLICK: {
      return {
        ...state,
        open: true,
        selectedEntityForSave: action.payload,
      };
    }
    case ENTITIES_GET_ENTITIES_SUCCESS: {
      return {
        ...state,
        collection: generateEntitiesList(action.payload, state.collection),
        selectedEntityForSave: undefined,
        selectedEntityForCusto: undefined,
      };
    }
    case ENTITIES_GET_ENTITIES_FAILURE: {
      return {
        ...state,
        collection: [],
        selectedEntityForSave: undefined,
        selectedEntityForCusto: undefined,
      };
    }
    case ENTITIES_GET_DCO_SUCCESS: {
      return {
        ...state,
        dco: action.payload,
        selectedEntityForSave: undefined,
        selectedEntityForCusto: undefined,
      };
    }
    case ENTITIES_GET_DCO_FAILURE: {
      return {
        ...state,
        dco: [],
        selectedEntityForSave: undefined,
        selectedEntityForCusto: undefined,
      };
    }
    case ENTITIES_TOGGLE_ENTITY_READ_PERMISSION: {
      const { entityId, giveReadPermission } = action.payload;
      return {
        ...state,
        toggledEntitiesPermission: {
          ...state.toggledEntitiesPermission,
          [entityId]: giveReadPermission,
        },
      };
    }
    case SAVE_BANNERS_SAVE_BANNERSET_TO_NUXEO_REQUESTED: {
      return {
        ...state,
        open: false,
        key: state.key + 1,
      };
    }
    case ENTITIES_SET_CUSTOM_TEST_CHOICE: {
      return {
        ...state,
        customTestChoice: action.payload,
      };
    }
    case ENTITIES_SET_CUSTOM_TEST_ENTITY: {
      return {
        ...state,
        selectedEntityForCusto: action.payload,
      };
    }
    case ENTITIES_USER_ENTITY_SUCCESS: {
      return {
        ...state,
        userEntity: action.payload,
      };
    }
    case ENTITIES_USER_ENTITY_FAILURE: {
      return {
        ...state,
        userEntity: undefined,
      };
    }
    case MESSAGE_ADD: {
      return {
        ...state,
        open: false,
        key: state.key + 1,
        for: '',
      };
    }
    default:
      return state;
  }
};

export default reducer;
