export const checkIfSlideIsLastSlideOverall = (idx, allSlides) => idx === allSlides.length - 1;
export const checkIfSlideIsLastSlideOfTour = (idx, allSlides, repetitions) => {
  const slidesPerTour = (allSlides.length / repetitions);
  return !checkIfSlideIsLastSlideOverall(idx, allSlides) &&
   ((idx % slidesPerTour) === slidesPerTour - 1);
}

export const checkIfSlideIsFirstSlideOverall = (idx) => idx === 0
export const checkIfSlideIsFirstSlideOfTour = (idx, allSlides, repetitions) => {
  const slidesPerTour = (allSlides.length / repetitions);
  return !checkIfSlideIsFirstSlideOverall(idx) &&
     (idx % slidesPerTour) === 0
}
