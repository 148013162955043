import React from 'react';
import { compose } from 'recompose';
import { ToggleButtonGroup } from '@material-ui/lab';
import ToggleButtonWithTooltip from '../components/ToggleButton/ToggleButtonWithTooltip';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getSelectedElement, getSelectedElements } from '../banner/bannerSelectors';
import {
  setElementTransitionTranslationDirection,
  setElementsTransitionTranslationDirection,
} from '../banner/bannerActionsCreators';
import elementIcons from '../reference/elementIcons';
import { allElementsHasSameDeepProperty } from './helpers';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getSelectedElementId, getSelectedElementIds } from '../temporary-status/temporaryStatusDucks';

const handleDirectionChange = ({
  element,
  elements,
  transition,
  setElementTransitionTranslationDirection,
  setElementsTransitionTranslationDirection,
  id,
  ids,
}) => (_, direction) => {
  if (direction) {
    if (element)
      setElementTransitionTranslationDirection({
        id,
        direction,
        transitionName: transition,
      });
    if (elements.size > 0)
      setElementsTransitionTranslationDirection({
        ids,
        direction,
        transitionName: transition,
      });
  }
};

const setDirection = ({ element, elements, transition }) =>
  element ? element[transition].direction : allElementsHasSameDeepProperty(elements, transition, 'direction') || '';

const computeButtons = transition => {
  const topLeftButton = transition === 'transitionIn' ? 'fromTopLeft' : 'toTopLeft';
  const upButton = transition === 'transitionIn' ? 'fromUp' : 'toUp';
  const topRightButton = transition === 'transitionIn' ? 'fromTopRight' : 'toTopRight';
  const leftButton = transition === 'transitionIn' ? 'fromLeft' : 'toLeft';
  const rightButton = transition === 'transitionIn' ? 'fromRight' : 'toRight';
  const bottomLeftButton = transition === 'transitionIn' ? 'fromBottomLeft' : 'toBottomLeft';
  const downButton = transition === 'transitionIn' ? 'fromDown' : 'toDown';
  const bottomRightButton = transition === 'transitionIn' ? 'fromBottomRight' : 'toBottomRight';
  return {
    topLeftButton,
    upButton,
    topRightButton,
    leftButton,
    rightButton,
    bottomLeftButton,
    downButton,
    bottomRightButton,
  };
};

const DirectionChooser = props => {
  const { classesRoot, classesButton, transition, intl } = props;
  const {
    topLeftButton,
    upButton,
    topRightButton,
    leftButton,
    rightButton,
    bottomLeftButton,
    downButton,
    bottomRightButton,
  } = computeButtons(transition);

  return (
    <ToggleButtonGroup
      exclusive
      selected
      classes={classesRoot}
      value={setDirection(props)}
      onChange={handleDirectionChange(props)}
    >
      <ToggleButtonWithTooltip
        value={topLeftButton}
        classes={classesButton}
        tooltip={intl.formatMessage({ id: `transition.translation.direction.${topLeftButton}` })}
      >
        {transition === 'transitionIn' ? elementIcons.arrow.bottomRight : elementIcons.arrow.topLeft}
      </ToggleButtonWithTooltip>
      <ToggleButtonWithTooltip
        value={upButton}
        classes={classesButton}
        tooltip={intl.formatMessage({ id: `transition.translation.direction.${upButton}` })}
      >
        {transition === 'transitionIn' ? elementIcons.arrow.down : elementIcons.arrow.up}
      </ToggleButtonWithTooltip>
      <ToggleButtonWithTooltip
        value={topRightButton}
        classes={classesButton}
        tooltip={intl.formatMessage({ id: `transition.translation.direction.${topRightButton}` })}
      >
        {transition === 'transitionIn' ? elementIcons.arrow.bottomLeft : elementIcons.arrow.topRight}
      </ToggleButtonWithTooltip>
      <ToggleButtonWithTooltip
        value={leftButton}
        classes={classesButton}
        tooltip={intl.formatMessage({ id: `transition.translation.direction.${leftButton}` })}
      >
        {transition === 'transitionIn' ? elementIcons.arrow.right : elementIcons.arrow.left}
      </ToggleButtonWithTooltip>
      <div />
      <ToggleButtonWithTooltip
        value={rightButton}
        classes={classesButton}
        tooltip={intl.formatMessage({ id: `transition.translation.direction.${rightButton}` })}
      >
        {transition === 'transitionIn' ? elementIcons.arrow.left : elementIcons.arrow.right}
      </ToggleButtonWithTooltip>
      <ToggleButtonWithTooltip
        value={bottomLeftButton}
        classes={classesButton}
        tooltip={intl.formatMessage({ id: `transition.translation.direction.${bottomLeftButton}` })}
      >
        {transition === 'transitionIn' ? elementIcons.arrow.topRight : elementIcons.arrow.bottomLeft}
      </ToggleButtonWithTooltip>
      <ToggleButtonWithTooltip
        value={downButton}
        classes={classesButton}
        tooltip={intl.formatMessage({ id: `transition.translation.direction.${downButton}` })}
      >
        {transition === 'transitionIn' ? elementIcons.arrow.up : elementIcons.arrow.down}
      </ToggleButtonWithTooltip>
      <ToggleButtonWithTooltip
        value={bottomRightButton}
        classes={classesButton}
        tooltip={intl.formatMessage({ id: `transition.translation.direction.${bottomRightButton}` })}
      >
        {transition === 'transitionIn' ? elementIcons.arrow.topLeft : elementIcons.arrow.bottomRight}
      </ToggleButtonWithTooltip>
    </ToggleButtonGroup>
  );
};

const makeStateToProps = () => state => ({
  element: makeSelectorInstance(getSelectedElement)(state),
  elements: makeSelectorInstance(getSelectedElements)(state),
  ids: makeSelectorInstance(getSelectedElementIds)(state),
  id: makeSelectorInstance(getSelectedElementId)(state),
});

const dispatchToProps = {
  setElementTransitionTranslationDirection,
  setElementsTransitionTranslationDirection,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  React.memo,
)(DirectionChooser);
