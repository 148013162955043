import fetchNuxeoBatchId from './fetchNuxeoBatchId';
import fetchNuxeoUploadFile from './fetchNuxeoUploadFile';
import fetchNuxeo from './fetchNuxeo';
import fetchNuxeoBannerSetFromUid from './fetchNuxeoBannerSetFromUid';
import { extractPermissions } from '../../banners/bannersUtils';

const checkErrorsBeforeSavingBannerSetInNuxeo = ({
  userWorkspacePath,
  fileContent,
  attachments,
  bannerSetId,
  bannerSetName,
  bannerSetExpirationDate,
}) => {
  if (!bannerSetId && !userWorkspacePath) {
    throw new Error(
      'A userWorkspacePath must be given if the bannerSet is to be created,' +
        'a bannerSetId must be given if the bannerSet is to be updated. ' +
        'At least a userWorkspacePath or a bannerSetId must be given.',
    );
  }

  if (!fileContent) {
    throw new Error('A file content must be given');
  }

  if (!attachments) {
    throw new Error('An attachments must be given');
  }

  if (!bannerSetName) {
    throw new Error('A bannerSetName must be given');
  }

  if (!bannerSetExpirationDate) {
    console.warn('A banner set expiration date not exists');
  }
};

const fetchNuxeoSaveBannerSet = async ({
  userWorkspacePath,
  bannerSetId,
  fileContent,
  attachments,
  bannerSetName,
  bannerSetExpirationDate,
  duplicate = false,
}) => {
  try {
    // 1. check errors
    checkErrorsBeforeSavingBannerSetInNuxeo({
      userWorkspacePath,
      bannerSetId,
      fileContent,
      attachments,
      bannerSetName,
      bannerSetExpirationDate,
    });
    // mediaUrl: http://media.dev.mylocalad.org/nuxeo/site/api/v1

    // 2. set-up the data to upload
    const fileContentUpload = {
      ...fileContent,
      fileIdx: 0,
    };
    const attchmentsUpload = attachments.map((attachment, ind) => ({
      ...attachment,
      fileIdx: ind + 1,
    }));
    const filesToUpload = [fileContentUpload, ...attchmentsUpload];

    // 3. upload the data
    const batchId = await fetchNuxeoBatchId();
    let filesUploaded = [];

    for (let index = 0; index < filesToUpload.length; index++) {
      const params = filesToUpload[index];
      const { uploaded, fileIdx } = await fetchNuxeoUploadFile({
        batchId,
        ...params,
      });
      filesUploaded.push({ uploaded, fileIdx });
    }

    let filesNotUploaded = filesUploaded.filter(file => !file.uploaded);

    if (filesNotUploaded.length > 0) {
      throw new Error(`${filesNotUploaded.join(', ')} couldn't be uploaded`);
    }

    // 4. affect the data to a document
    let update = false;

    if (!duplicate && bannerSetId !== undefined) {
      // update the bannerset
      const checkBannerSetExists = await fetchNuxeoBannerSetFromUid(bannerSetId, true);
      if (checkBannerSetExists) {
        update = true;
      }
    }

    const path = update ? `/id/${bannerSetId}` : `/path${[userWorkspacePath, '/assets'].join('')}`;
    const method = update ? 'PUT' : 'POST';
    const bodyVariant = update
      ? { uid: bannerSetId }
      : {
          type: 'BannerSet',
          name: bannerSetName,
        };

    const headerVariant = update ? { 'X-File-Name': bannerSetName } : {};

    const body = {
      'entity-type': 'document',
      ...bodyVariant,
      properties: {
        'dc:source': 'adbuilder',
        'dc:title': bannerSetName,
        'dc:expired': bannerSetExpirationDate,
        'file:content': {
          'upload-batch': `${batchId}`,
          'upload-fileId': `${fileContentUpload.fileIdx}`,
          name: `${fileContentUpload.fileName}`,
          'mime-type': `${fileContentUpload.fileType}`,
        },
        'files:files': attchmentsUpload.map(attachment => ({
          file: {
            'upload-batch': `${batchId}`,
            'upload-fileId': `${attachment.fileIdx}`,
            name: `${attachment.fileName}`,
            'mime-type': `${attachment.fileType}`,
          },
        })),
      },
    };

    const res = await fetchNuxeo({
      path,
      method,
      headers: {
        'Content-Type': 'application/json',
        'X-NXproperties': 'dublincore, file',
        'X-NXfetch.document': 'lock',
        'X-NXenrichers.document': 'acls',
        ...headerVariant,
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .catch(e => console.error('error posting nuxeo', e));

    if (res['entity-type'] === 'exception') {
      throw new Error(`error ${res.status} saving the bannerset in Nuxeo`);
    }
    const { uid: newUid, path: newPath, lockOwner } = res;

    return { newUid, update, newPath, lockOwner, permissions: extractPermissions(res) };
  } catch (e) {
    console.error("can't upload bannerset in nuxeo", e);
  }
};

export default fetchNuxeoSaveBannerSet;
