import { corners } from '../../../reference/corners';

export const calculateLockAspectRatioMinCursorPosition = ({
  fixedCornerPositionRotated,
  minHeight,
  phi,
  ALPHA,
  movingCorner,
}) => {
  switch (movingCorner) {
    case corners.topRight:
      return {
        x: fixedCornerPositionRotated.x + (minHeight / Math.sin(phi)) * Math.cos(phi + ALPHA),
        y: fixedCornerPositionRotated.y - (minHeight / Math.sin(phi)) * Math.sin(phi + ALPHA),
      };
    case corners.topLeft:
      return {
        x: fixedCornerPositionRotated.x - (minHeight / Math.sin(phi)) * Math.cos(phi - ALPHA),
        y: fixedCornerPositionRotated.y - (minHeight / Math.sin(phi)) * Math.sin(phi - ALPHA),
      };
    case corners.bottomRight:
      return {
        x: fixedCornerPositionRotated.x + (minHeight / Math.sin(phi)) * Math.cos(phi - ALPHA),
        y: fixedCornerPositionRotated.y + (minHeight / Math.sin(phi)) * Math.sin(phi - ALPHA),
      };
    case corners.bottomLeft:
      return {
        x: fixedCornerPositionRotated.x - (minHeight / Math.sin(phi)) * Math.cos(phi + ALPHA),
        y: fixedCornerPositionRotated.y + (minHeight / Math.sin(phi)) * Math.sin(phi + ALPHA),
      };
    case corners.middleTop:
      return {
        x: fixedCornerPositionRotated.x - minHeight * Math.sin(ALPHA),
        y: fixedCornerPositionRotated.y - minHeight * Math.cos(ALPHA),
      };
    case corners.middleBottom:
      return {
        x: fixedCornerPositionRotated.x + minHeight * Math.sin(ALPHA),
        y: fixedCornerPositionRotated.y + minHeight * Math.cos(ALPHA),
      };
    case corners.middleRight:
      return {
        x: fixedCornerPositionRotated.x + (minHeight / Math.tan(phi)) * Math.cos(ALPHA),
        y: fixedCornerPositionRotated.y - (minHeight / Math.tan(phi)) * Math.sin(ALPHA),
      };
    case corners.middleLeft:
      return {
        x: fixedCornerPositionRotated.x - (minHeight / Math.tan(phi)) * Math.cos(ALPHA),
        y: fixedCornerPositionRotated.y + (minHeight / Math.tan(phi)) * Math.sin(ALPHA),
      };
    default:
      return null;
  }
};
