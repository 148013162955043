export const calculateRotateCoordsInSystem = ({ initCoords, centerCoords, ALPHA }) => {
  /*
    INPUT:
    initCoords: { x: y: },
    centerCoords: { X: Y: },
    ALPHA: rad

    OUTPUT
    {
      x:
      y:
      omega: positive angle in rad
    }
  */
  const rotatedCoordsInCenterSystem = {
    X: -centerCoords.X + initCoords.x,
    Y: centerCoords.Y - initCoords.y,
  };

  if (rotatedCoordsInCenterSystem.X === 0 && rotatedCoordsInCenterSystem.Y === 0) {
    return {
      x: initCoords.x,
      y: initCoords.y,
      omega: 0,
    };
  }

  const diag = Math.sqrt(Math.pow(rotatedCoordsInCenterSystem.X, 2) + Math.pow(rotatedCoordsInCenterSystem.Y, 2));

  let omega;
  if (rotatedCoordsInCenterSystem.X < 0) {
    if (rotatedCoordsInCenterSystem.Y >= 0) {
      /*top left*/
      omega = Math.acos(rotatedCoordsInCenterSystem.X / diag) - ALPHA;
    } else {
      /*bottom left*/
      omega = -Math.acos(rotatedCoordsInCenterSystem.X / diag) + 2 * Math.PI - ALPHA;
    }
  } else {
    if (rotatedCoordsInCenterSystem.Y >= 0) {
      /*top right*/
      omega = Math.acos(rotatedCoordsInCenterSystem.X / diag) - ALPHA;
    } else {
      /*bottom right*/
      omega = -Math.acos(rotatedCoordsInCenterSystem.X / diag) + 2 * Math.PI - ALPHA;
    }
  }

  const newX =
    Math.round((diag * Math.cos(omega) + centerCoords.X) * 10000) / 10000 === -0 // round with 2 decimals
      ? 0
      : Math.round((diag * Math.cos(omega) + centerCoords.X) * 10000) / 10000;
  const newY =
    Math.round((-diag * Math.sin(omega) + centerCoords.Y) * 10000) / 10000 === -0 // round with 2 decimals
      ? 0
      : Math.round((-diag * Math.sin(omega) + centerCoords.Y) * 10000) / 10000;

  const coordsOfElementNonRotated = {
    x: newX,
    y: newY,
    omega: Math.round(((omega + 2 * Math.PI) % (2 * Math.PI)) * 100000) / 100000,
  };

  return coordsOfElementNonRotated;
};
