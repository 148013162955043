import React from 'react';
import { InputAdornment } from '@material-ui/core';

// number input props;
export const opacityInputProps = { min: 0, max: 1, step: 0.05 };
export const blinkFrequencyInputProps = { min: 0, max: 100, step: 0.5 };
export const minZeroInputProps = { min: 0 };
export const durationInputProps = { min: 0, step: 0.05 };
// select input prop
export const selectTransitionInTypeInputPropId = { id: 'transition-in-type' };
export const selectTransitionOutTypeInputPropId = { id: 'transition-out-type' };
// endornments
export const secondEndornment = { endAdornment: <InputAdornment position="end">s</InputAdornment> };
export const pixelEndornment = { endAdornment: <InputAdornment position="end">px</InputAdornment> };
export const degreeEndornment = { endAdornment: <InputAdornment position="end">°</InputAdornment> };
export const frequencyEndornment = { endAdornment: <InputAdornment position="end">/&nbsp;sec</InputAdornment> };
// text fields helpers
export const parseFloatHelper = value => parseFloat(value) || 0;
export const round2DigitsHelper = value => parseInt(value * 100, 10) / 100 || 0;
export const noChangeHelper = value => value;
