import { decomposeColor } from '@material-ui/core/styles/colorManipulator';
import concat from 'concat-stream';
import domtoimage from 'dom-to-image';
import GifEncoder from 'gif-encoder';
import JSZip from 'jszip';
import { select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { convertRGBToHex } from '../components/helpers';
import { allBannerFormats } from '../reference/bannerFormats';
import { loadOnlyUsedFonts } from '../resources/fonts';
import { getUsedFontsFamilies, getUserFonts } from '../shared-selectors/sharedSelectors';
import { objectUrlToBlob } from './import-export-helpers';

/*

Class Constructor Functions
this hack is only to make testSaga works
*/
export const computeBannerName = banner => `${banner.format.width}_${banner.format.height}`;
export const newZip = () => new JSZip();
export const newBannerFormData = (name, zipBlob) => {
  const formData = new FormData();
  formData.append(name, zipBlob);
  return formData;
};

/*

ARRAY - Uint8ClampedArray

*/

const joinArray = array => {
  let newArray = [];
  array.map(json => JSON.parse(json)).forEach(arr => newArray.push(...arr));
  const uint8ClampedArray = Uint8ClampedArray.from(newArray);
  return uint8ClampedArray;
};

const splitArray = (array, length = 4) => {
  try {
    return [...array]
      .filter((_, ind) => ind < array.length / length)
      .map((_, ind) => [...array.slice(ind * length, ind * length + length)])
      .map(array => JSON.stringify(array));
  } catch (e) {
    throw new Error('cannot split array', array, length);
  }
};

/*

Colors

*/

export const HEXToJSON = hex => {
  const letters = hex.replace('#', '');
  return JSON.stringify([
    parseInt(`${letters[0]}${letters[1]}`, 16),
    parseInt(`${letters[2]}${letters[3]}`, 16),
    parseInt(`${letters[4]}${letters[5]}`, 16),
    1,
  ]);
};

export const extractFrameColors = frame => {
  try {
    let colors = [];
    const splitFrame = splitArray(frame, 4);
    splitFrame.forEach(color => {
      if (colors.indexOf(color) < 0) {
        colors.push(color);
      }
    });
    return colors;
  } catch (e) {
    throw new Error('cannot extract colors', frame);
  }
};

export const addColorsToPalette = (colors, palette) => {
  try {
    colors.forEach(color => {
      if (palette.indexOf(color) < 0) {
        palette.push(color);
      }
    });
    return palette;
  } catch (e) {
    throw new Error('cannot add colors to palette', colors, palette);
  }
};

export const getHexColor = color => {
  if (!color.includes('#')) return convertRGBToHex(decomposeColor(color)).toUpperCase();
  if (color.length === 7) return color.toUpperCase();
  return [color, color.replace('#', '')].join('').toUpperCase();
};

export const formatTransColor = color => parseInt(color.replace('#', '0x'), 16);

export const computePalette = jsonPalette => joinArray(jsonPalette);

/*

Compute frames

*/

export const computeIndexedFrame = (frame, palette) => {
  try {
    const indexedArray = splitArray(frame, 4).map((color, ind) => {
      // ind < 10 && console.log(color, palette, palette.indexOf(color))
      return palette.indexOf(color);
    });
    return Uint8ClampedArray.from(indexedArray);
  } catch (e) {
    throw new Error('cannot compute frame', frame, palette);
  }
};

export const computeOptimizedFramesDelayAndTrans = (frames, jsonTransColor, typicalDuration, skipFirstFrame = true) => {
  let prevFrame = null; // for step 4;
  return (
    frames
      // step 0: filter the first frame because it is a black frame
      .filter((_, i) => skipFirstFrame && i !== 0)
      // step 1: record the initial position of the frame
      .map((frame, i) => ({ frame, position: i }))
      // step 2: compare the frames together, and filter the identical frames
      .filter((data, i, dataFrames) => {
        //first frame
        if (i === 0) return true;
        if (JSON.stringify(data.frame) !== JSON.stringify(dataFrames[i - 1].frame)) return true;
        return false;
      })
      // step 3: calculate the duration of the frames
      .map(({ frame, position }, i, dataFrames) => ({
        frame,
        frameDuration: dataFrames[i + 1]
          ? (dataFrames[i + 1].position - position) * typicalDuration
          : (frames.length - position - skipFirstFrame) * typicalDuration,
      }))
      // step 4: put the transparent color where it is needed
      .map((data, i) => {
        if (i === 0) {
          prevFrame = splitArray(data.frame);
          return data;
        }
        let newFrame = [];
        let currentFrame = splitArray(data.frame);
        prevFrame.forEach((color, i) => {
          if (color === currentFrame[i]) {
            newFrame.push(jsonTransColor);
          } else {
            newFrame.push(currentFrame[i]);
          }
        });
        prevFrame = currentFrame;
        return {
          ...data,
          frame: joinArray(newFrame),
        };
      })
  );
};

export const computeOptimizedFramesTrans = (frames, jsonTransColor, skipFirstFrame = true) => {
  let prevFrame = null; // for step 4;
  return (
    frames
      // step 0: filter the first frame because it is a black frame
      .filter((_, i) => skipFirstFrame && i !== 0)
      // step 1: put the transparent color where it is needed
      .map((frame, i) => {
        if (i === 0) {
          prevFrame = splitArray(frame);
          return frame;
        }
        let newFrame = [];
        let currentFrame = splitArray(frame);
        prevFrame.forEach((color, i) => {
          if (color === currentFrame[i]) {
            newFrame.push(jsonTransColor);
          } else {
            newFrame.push(currentFrame[i]);
          }
        });
        prevFrame = currentFrame;
        return joinArray(newFrame);
      })
  );
};

/*

GLOBAL OPTIMIZATION METHODS

*/

export const notOptimizedGif = (frames, gif) => {
  for (let frame of frames.filter((_, ind) => ind !== 0)) {
    // filter the first frame because it is a black frame
    gif.addFrame(frame);
  }
};

export const optimizeDelayAndColorsToTransparent = (frames, gif, color, fps) => {
  try {
    const typicalDuration = 1 / fps; // in seconds
    const transparentColor = getHexColor(color);
    gif.setTransparent(formatTransColor(getHexColor(color)));

    const jsonTransColor = HEXToJSON(transparentColor);
    gif.setFrameRate(fps);

    /* BUILD FRAMES */
    computeOptimizedFramesDelayAndTrans(frames, jsonTransColor, typicalDuration).forEach(data => {
      gif.setDispose(1);
      gif.setDelay(data.frameDuration);
      gif.addFrame(data.frame);
    });
  } catch (e) {
    console.error(e);
  }
};

export const optimizeColorsToTransparent = (frames, gif, color, fps) => {
  try {
    const transparentColor = getHexColor(color);
    gif.setTransparent(formatTransColor(getHexColor(color)));

    const jsonTransColor = HEXToJSON(transparentColor);
    gif.setFrameRate(fps);
    gif.setDispose(1);

    /* BUILD FRAMES */
    computeOptimizedFramesTrans(frames, jsonTransColor).forEach(frame => gif.addFrame(frame));
  } catch (e) {
    console.error(e);
  }
};

export const optimizePalette = (frames, gif) => {
  // not working : https://github.com/twolfson/gif-encoder/issues/17#issuecomment-435504887
  let jsonPalette = [];
  let indexedFrames = [];
  for (let frame of frames.filter((_, ind) => ind !== 0)) {
    // filter the first frame because it is a black frame
    const frameColors = extractFrameColors(frame);
    jsonPalette = addColorsToPalette(frameColors, jsonPalette);
    const indexedFrame = computeIndexedFrame(frame, jsonPalette);
    indexedFrames.push(indexedFrame);
  }
  const palette = computePalette(jsonPalette);
  for (let indexedFrame of indexedFrames.filter((_, ind) => ind !== 0)) {
    console.log(indexedFrame, palette);
    gif.addFrame(indexedFrame, { palette, indexedPixels: true });
  }
};

/*

OTHER GIF METHODS

*/

export const pixelsToGIF = (pixels, fileName, width, height) =>
  new Promise((resolve, reject) => {
    const gif = new GifEncoder(width, height);
    gif.pipe(concat(resolve));
    gif.writeHeader();
    gif.addFrame(pixels);
    gif.finish();
    gif.on('error', reject);
  });

export const computeRepetitions = timesPlayed => {
  if (timesPlayed > 1) return timesPlayed - 1;
  return -1;
};

export const pixelsToAnimatedGIF = (frames, width, height, fps, color, timesPlayed) =>
  new Promise((resolve, reject) => {
    const gif = new GifEncoder(width, height);
    gif.pipe(concat(resolve));
    gif.writeHeader();
    gif.setQuality(50);
    gif.setRepeat(computeRepetitions(timesPlayed));

    /* BRUT EXPORT, NOT OPTIMIZED */
    // notOptimizedGif(frames, gif);

    /* OPTIMIZED EXPORT - PALETTE BUILDING - NOT WORKING */
    // optimizePalette(frames, gif);

    /* OPTIMIZED EXPORT - SET TRANSPARENT COLOR */
    optimizeColorsToTransparent(frames, gif, color, fps);

    gif.finish();
    gif.on('error', reject);
  });

export const checkGifSize = (bannerFormatName, bannerName, gif) => {
  const gifSize = gif.byteLength / 1000 || gif.size / 1000;
  console.log({ gif, gifSize });
  const maxSize = allBannerFormats.filter(format => format.name === bannerFormatName)[0].maxSize;
  if (gifSize < maxSize) {
    return { bannerName, gifSize, status: 'accepted' };
  } else {
    return { bannerName, gifSize, maxSize, status: 'rejected' };
  }
};

/*

  domtoimage

*/

export const toJPG = async (DOMNode, currentImagesFolder, imageName) =>
  await domtoimage
    .toJpeg(DOMNode, { quality: 1 })
    .then(imageUrl => currentImagesFolder.file(`${imageName}.jpg`, objectUrlToBlob(imageUrl)))
    .catch(e => console.log("couldn't export to JPG", e));

export const toPNG = async (DOMNode, currentImagesFolder, imageName) =>
  await domtoimage
    .toPng(DOMNode)
    .then(imageUrl => currentImagesFolder.file(`${imageName}.png`, objectUrlToBlob(imageUrl)))
    .catch(e => console.log("couldn't export to PNG", e));

export const toGIF = async (DOMNode, width, height, currentImagesFolder, imageName) => {
  const gif = await domtoimage
    .toPixelData(DOMNode)
    .then(pixels => pixelsToGIF(pixels, `${imageName}.gif`, width, height))
    .catch(e => console.log("couldn't export to GIF", e));

  currentImagesFolder.file(`${imageName}.gif`, gif);
};

export function* domtoimageExecutionContext(exec) {
  const userFonts = yield select(getUserFonts);
  const usedFontsFamilies = yield select(getUsedFontsFamilies);

  const restoreFonts = yield loadOnlyUsedFonts(usedFontsFamilies, userFonts);
  const res = yield* exec();
  yield restoreFonts();
  return res;
}

export function bannerElementsFilenamesRandomiser(jsBanner) {
  const newElements = Object.assign({}, jsBanner.elements || {});
  Object.keys(newElements).forEach(key => {
    const fileName = newElements[key].fileName;
    if (fileName) {
      newElements[key].fileName = `${uuidv4()}.${fileName.split('.').pop() || ''}`;
    }
  });
  return { ...jsBanner, elements: newElements };
}
