import fetchHtml5ToGif, { errors } from './fetchHtml5ToGif';
import fetchHtml5ToGifUpload from './fetchHtml5ToGifUpload';
import fetchHtml5ToGifConvert from './fetchHtml5ToGifConvert';
import fetchHtml5ToGifCheckStatus from './fetchHtml5ToGifCheckStatus';
import fetchHtml5ToGifDownload from './fetchHtml5ToGifDownload';

export default {
  fetch: fetchHtml5ToGif,
  upload: fetchHtml5ToGifUpload,
  convert: fetchHtml5ToGifConvert,
  checkStatus: fetchHtml5ToGifCheckStatus,
  download: fetchHtml5ToGifDownload,
  errors,
};
