export const properyEditorPanelColor = 'rgba(250,250,250,1.00)';
export const elementListPanelColor = 'rgba(239,246,247,1.00)';
export const slidesPanelColor = 'rgba(250,250,250,1.00)';

export const menuButtonBgColor = 'rgba(236,236,236,1)';

export const bannerOverlayShadowColor = 'rgba(255, 255, 255, 0.75)';
export const sceneBgColor = bannerOverlayShadowColor; //previous: #CCC
/* dropzone */
export const dropzoneBorderColor = '#666';
export const acceptDropzoneBgColor = '#8FB';
export const acceptDropzoneBorderColor = '#0A4';
export const rejectDropzoneBgColor = '#F04';
export const rejectDropzoneBorderColor = '#F8A';

export const errorColor = '#F04';
