import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { FormControl, InputLabel, Select, MenuItem, TextField, InputAdornment, ListItemIcon } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { slideTransitionsDetails } from '../reference/transitions';
import keyboardShortcuts from '../reference/keyboardShortcuts';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getBannerSlides, checkIfNoRepetitions } from '../banner/bannerSelectors';
import { setSlideTransitionType, setSlideTransitionDuration } from '../banner/bannerActionsCreators';
import { addError } from '../messages/messagesDucks';

import { checkSlideTransitionChange } from './helpers';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    height: 50,
    marginTop: 10,
  },
  icon: {
    width: 20,
    height: 20,
    display: 'inline-block',
    '& *[data-fill="true"]': {
      fill: '#000',
    },
    '& *[data-stroke="true"]': {
      stroke: '#000',
    },
  },
  transitionRoot: {
    flex: 1,
    '& label + div': {
      marginTop: 15,
    },
  },
  transitionSelect: {
    textAlign: 'center',
    lineHeight: '0.95em',
    height: 35,
    boxSizing: 'border-box',
  },
  durationInput: {
    '-moz-appearance': 'textfield',
    textAlign: 'right',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
  },
  durationRoot: {
    width: 45,
    flex: 1,
  },
  menuSelected: {
    backgroundColor: theme.palette.primary.main,
    '& *[data-fill="true"]': {
      fill: '#000',
    },
    '& *[data-stroke="true"]': {
      stroke: '#000',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
      '& *[data-fill="true"]': {
        fill: '#FFF',
      },
      '& *[data-stroke="true"]': {
        stroke: '#FFF',
      },
    },
  },
  menuItem: {
    '& *[data-fill="true"]': {
      fill: '#000',
    },
    '& *[data-stroke="true"]': {
      stroke: '#000',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
      '& *[data-fill="true"]': {
        fill: '#FFF',
      },
      '& *[data-stroke="true"]': {
        stroke: '#FFF',
      },
    },
    '&$menuSelected': {
      backgroundColor: fade(theme.palette.primary.main, 0.7),
      color: '#FFF',
      '& *[data-fill="true"]': {
        fill: '#FFF',
      },
      '& *[data-stroke="true"]': {
        stroke: '#FFF',
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        '& *[data-fill="true"]': {
          fill: '#FFF',
        },
        '& *[data-stroke="true"]': {
          stroke: '#FFF',
        },
      },
    },
  },
});

class SlideTransition extends React.Component {

  slideTransitionDuration = 0
  cancel = false

  render () {
    const {
      classes,
      transition,
      index,
      setSlideTransitionType,
      setSlideTransitionDuration,
      noRepetition,
      addError,
      slides,
      slide,
    } = this.props

    return (
      <div className={classes.root}>
        <FormControl classes={{root: classes.transitionRoot}}>
          <InputLabel htmlFor="transition-type">
            <FormattedMessage id="slides.transition"/>
          </InputLabel>
          <Select
            value={transition.type}
            classes={{select: classes.transitionSelect}}
            inputProps={{name: 'transition-type'}}
            renderValue={type =>
              type && (
                <div
                  className={classes.icon}
                  dangerouslySetInnerHTML={{
                    __html: slideTransitionsDetails[type].icon,
                  }}
                />
              )
            }
            onKeyDown={e => {
              e.stopPropagation()
              if (keyboardShortcuts.enter.includes(e.key)) {
                e.target.blur()
              }
            }}
            onKeyUp={e => e.stopPropagation()}
            onChange={e => {
              setSlideTransitionType(index, e.target.value)
              if (e.target.value === slideTransitionsDetails.none.name) {
                setSlideTransitionDuration({index, duration: 0, untrack: true})
              } else {
                if (transition.duration === 0) {
                  setSlideTransitionDuration({index, duration: 1, untrack: true})
                }
              }
            }}
          >
            {Object.keys(slideTransitionsDetails).map(transitionType => (
              <MenuItem
                key={transitionType}
                value={slideTransitionsDetails[transitionType].name}
                classes={{selected: classes.menuSelected, root: classes.menuItem}}
              >
                <ListItemIcon>
                  <div
                    className={classes.icon}
                    dangerouslySetInnerHTML={{
                      __html: slideTransitionsDetails[transitionType].icon,
                    }}
                  />
                </ListItemIcon>
                <FormattedMessage id={`slides.transition.${slideTransitionsDetails[transitionType].name}`}/>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {transition.type &&
        transition.type !== 'empty' && (
          <TextField
            type="number"
            value={transition.duration}
            label={<FormattedMessage id="slides.duration"/>}
            InputProps={{
              endAdornment: <InputAdornment position="end">s</InputAdornment>,
              classes: {
                input: classes.durationInput,
                root: classes.durationRoot,
              },
              // https://github.com/mui-org/material-ui/issues/10064#issuecomment-373190215
              inputProps: {min: 0, max: 15, step: 0.1},
            }}
            onKeyDown={e => {
              e.stopPropagation()
              if (keyboardShortcuts.enter.includes(e.key)) {
                e.target.blur()
              }
              if (keyboardShortcuts.cancel.includes(e.key)) {
                this.cancel = true
                setSlideTransitionDuration({index, duration: this.slideTransitionDuration, untrack: true})
                e.target.blur()
              }
            }}
            onKeyUp={e => e.stopPropagation()}
            onFocus={() => (this.slideTransitionDuration = transition.duration)}
            onBlur={() => {
              if (this.cancel) {
                this.cancel = false
              } else {
                checkSlideTransitionChange({
                  newTransitionValue: transition.duration,
                  transition,
                  addError,
                  setSlideTransitionDuration,
                  setSlideTransitionType,
                  slides,
                  noRepetition,
                  slide,
                  index,
                })
              }
            }}
            onChange={e => {
              setSlideTransitionDuration({index, duration: parseFloat(e.target.value) || 0, untrack: true})
            }}
          />
        )}
      </div>
    )
  }
}

SlideTransition.propTypes = {
  transition: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

const makeStateToProps = () => {
  const stateToProps = state => {
    const slides = makeSelectorInstance(getBannerSlides)(state);
    const noRepetition = makeSelectorInstance(checkIfNoRepetitions)(state);
    return {
      slides,
      noRepetition,
    };
  };
  return stateToProps;
};

const dispatchToProps = {
  setSlideTransitionType,
  setSlideTransitionDuration,
  addError,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  withStyles(styles),
  React.memo,
)(SlideTransition);
