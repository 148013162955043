import React from 'react';
import { injectIntl } from 'react-intl';
import { compose, onlyUpdateForKeys } from 'recompose';
import { connect } from 'react-redux';
import { noChangeHelper } from '../constants';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementCustomValue } from '../../banner/bannerSelectors';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementCurrentValue extends React.Component {
  render() {
    const { id, currentValue, intl } = this.props;
    const value = currentValue || intl.formatMessage({ id: 'customization.field.defaultValue' });

    return (
      <TextFieldDoped
        disabled
        key={id}
        id={id}
        type="text"
        label={intl.formatMessage({ id: 'customization.field.currentValue' })}
        value={value}
        fullWidth
        multiline
        helper={noChangeHelper} //to prevent parseInt...
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  currentValue: makeSelectorInstance(getSelectedElementCustomValue)(state),
});

export default compose(
  connect(makeStateToProps),
  onlyUpdateForKeys(['currentValue']),
  injectIntl,
)(ElementCurrentValue);
