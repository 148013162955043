import fetchNuxeo, { addPermissionUrl } from './fetchNuxeo';
import { extractPermissions } from '../../banners/bannersUtils';

const fetchNuxeoSetReadPermission = uid => {
  const errorMessage = 'fetchNuxeoSetReadPermission: error in setting permissions in nuxeo';
  try {
    return fetchNuxeo({
      path: addPermissionUrl,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        'X-NXenrichers.document': 'acls', // to know what permissions are given to the banner-set
      },
      body: JSON.stringify({
        input: uid, // banner-set uid
        params: {
          users: ['members'],
          permission: 'Read',
        },
      }),
      errorMessage,
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }

        console.error(errorMessage, res.statusText);
        throw new Error(errorMessage);
      })
      .then(entry => extractPermissions(entry))
      .catch(e => {
        console.error(errorMessage, e);
        throw new Error(errorMessage);
      });
  } catch (e) {
    console.error('cant set permission in nuxeo', e);
    throw new Error(errorMessage);
  }
};

export default fetchNuxeoSetReadPermission;
