import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Snackbar, IconButton, withStyles } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';

import { closeMessage, nextMessage, getMessages } from './messagesDucks';
import autoHideDurations from '../reference/messagesDurations';
import makeSelectorInstance from '../reference/makeSelectorInstance';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = theme => ({
  success: {
    flexWrap: 'nowrap',
    backgroundColor: 'rgba(74,74,74,1.00)',
  },
  error: {
    flexWrap: 'nowrap',
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    flexWrap: 'nowrap',
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    flexWrap: 'nowrap',
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const Messages = ({ classes, open, level, message, params, onClose, onExited }) => {
  const Icon = variantIcon[level];
  return (
    <Snackbar
      open={open}
      autoHideDuration={(params && params.autoHideDuration) || autoHideDurations.default}
      ContentProps={{ classes: { root: classes[level] } }}
      onClose={(event, reason) => {
        if (reason !== 'clickaway') onClose();
      }}
      onExited={onExited}
      message={
        <span className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          <FormattedHTMLMessage id={message} values={params} />
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
    />
  );
};

const makeStateToProps = () => {
  const stateToProps = state => {
    const { open, level, message, params } = makeSelectorInstance(getMessages)(state);
    return { open, level, message, params };
  };
  return stateToProps;
};

const dispatchToProps = {
  onClose: closeMessage,
  onExited: nextMessage,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  withStyles(styles),
  React.memo,
)(Messages);
