import { fromCSSToTrigo } from '../../interactions-helpers';

const round = number => Math.round(number * 10000) / 10000;

export const getBgProperties = ({ elementPosition, imagePosition, rotation }) => {
  const ALPHA = fromCSSToTrigo(rotation);
  /* cf schéma */

  const imageCoordsInElementSystem = {
    x: elementPosition.x - imagePosition.x,
    y: elementPosition.y - imagePosition.y,
  };

  let diagonal = Math.sqrt(Math.pow(imageCoordsInElementSystem.x, 2) + Math.pow(imageCoordsInElementSystem.y, 2));

  if (imageCoordsInElementSystem.x >= 0) {
    diagonal = -diagonal;
  }

  const beta =
    imageCoordsInElementSystem.x === 0
      ? -(ALPHA - Math.PI / 2)
      : Math.atan(imageCoordsInElementSystem.y / imageCoordsInElementSystem.x);

  const newX = round(diagonal * Math.cos(ALPHA + beta));
  const newY = round(diagonal * Math.sin(ALPHA + beta));

  return {
    x: Math.abs(newX) === 0 ? 0 : Math.round(newX * 10000) / 10000,
    y: Math.abs(newY) === 0 ? 0 : Math.round(newY * 10000) / 10000,
  };
};
