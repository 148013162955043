import { debug } from './params';

export const saveBlob = (() => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';

  return (fileName, blob) => {
    debug && console.log(blob);
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  };
})();
