import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid } from '@material-ui/core';
import { FormattedMessage, FormattedHTMLMessage, FormattedNumber, injectIntl } from 'react-intl';
import Dropzone from 'react-dropzone';
import debugFactory from 'debug';
import classNames from 'classnames';

import keyboardShortcuts from '../reference/keyboardShortcuts';
import {
  dropzoneBorderColor,
  acceptDropzoneBgColor,
  acceptDropzoneBorderColor,
  rejectDropzoneBgColor,
  rejectDropzoneBorderColor,
  errorColor,
} from '../reference/colors';
import { isFontUploadDialogOpen } from '../shared-selectors/sharedSelectors';
import { closeUploadFontDialog, getFontUploadDialogKey, getFontUploadFile, addFont } from './fontsDucks';
import {
  acceptedFontTypes,
  acceptedFontExtensionsAsString as extensions,
  maxUploadSize,
} from '../reference/importParams';
import makeSelectorInstance from '../reference/makeSelectorInstance';

const debug = debugFactory('adbuilder:UploadFontDialog');

const styles = {
  dialogContent: {
    padding: 16,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      padding: 16,
      boxSizing: 'border-box',
    },
    width: 400,
  },
  dropZone: {
    borderColor: dropzoneBorderColor,
    borderStyle: 'dashed',
    color: 'rgba(0, 0, 0, 0.75)',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    padding: 16,
    minHeight: 150,
    backgroundImage:
      'repeating-linear-gradient(-45deg, transparent, transparent 20px, rgba(0, 0, 0, 0.05) 20px, rgba(0, 0, 0, 0.05) 40px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  acceptDropZone: {
    borderStyle: 'solid',
    backgroundColor: acceptDropzoneBgColor,
    borderColor: acceptDropzoneBorderColor,
  },
  rejectDropZone: {
    borderStyle: 'solid',
    backgroundColor: rejectDropzoneBgColor,
    borderColor: rejectDropzoneBorderColor,
  },
  acceptedDropZone: {
    borderStyle: 'solid',
  },
  error: {
    color: errorColor,
    backgroundColor: 'rgba(255, 128, 128, 0.25)',
    borderRadius: 4,
    padding: 16,
  },
};

const onUploadFile = file => state => {
  const newState = { file };
  if (!state.fontName || (state.file && state.file.name.startsWith(state.fontName))) {
    newState.fontName = file.name.replace(/\.\w+$/, '');
  }
  return newState;
};

class UploadFontDialog extends React.PureComponent {
  state = {
    file: null,
    fontName: '',
  };
  componentDidMount() {
    if (this.props.open && this.props.file !== null && this.props.file.name) {
      this.setState(onUploadFile(this.props.file));
    }
    if (this.props.open) {
      window.addEventListener('keydown', this.handleShortcuts);
      window.addEventListener('keyup', this.handleShortcuts);
    }
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleShortcuts);
    window.removeEventListener('keyup', this.handleShortcuts);
  }
  handleShortcuts = e => {
    if (keyboardShortcuts.cancel.includes(e.key)) {
      this.props.onCloseDialog();
    }
    if (keyboardShortcuts.enter.includes(e.key)) {
      this.state.file && this.state.fontName && this.props.addFont(this.state.file, this.state.fontName, true);
    }
  };

  onFontNameChange = ({ target: { value: fontName } }) => {
    this.setState({ fontName });
  };

  render() {
    const { classes, open, onCloseDialog, addFont, intl } = this.props;
    const { file, fontName } = this.state;
    return (
      <Dialog open={open} onClose={onCloseDialog}>
        <DialogTitle>
          <FormattedMessage id="uploadFont.title" />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Dropzone
                accept={acceptedFontTypes}
                maxSize={maxUploadSize}
                multiple={false}
                disablePreview={true}
                className={classNames(classes.dropZone, file && classes.acceptedDropZone)}
                activeClassName={classes.acceptDropZone}
                onDropAccepted={acceptedFiles => {
                  debug('Dropped font', acceptedFiles);
                  this.setState(onUploadFile(acceptedFiles[0]));
                }}
                onDropRejected={rejectedFiles => {
                  debug('Rejected files', rejectedFiles);
                }}
              >
                {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
                  if (isDragActive) {
                    return <FormattedMessage id="uploadFont.dropzone.accept" />;
                  }
                  if (rejectedFiles.length) {
                    const rejectedFile = rejectedFiles[0];
                    if (rejectedFile.size <= maxUploadSize) {
                      return (
                        <span className={classes.error}>
                          <FormattedHTMLMessage
                            id="uploadFont.dropzone.error.type"
                            values={{ name: rejectedFile.name, extensions }}
                          />
                        </span>
                      );
                    }
                    return (
                      <span className={classes.error}>
                        <FormattedMessage
                          id="uploadFont.dropzone.error.size"
                          values={{
                            name: rejectedFile.name,
                            maxSize: <FormattedNumber value={maxUploadSize / (1024 * 1024)} />,
                          }}
                        />
                      </span>
                    );
                  }
                  if (acceptedFiles.length) {
                    return <span>{acceptedFiles[0].name}</span>;
                  } else if (file !== null) {
                    return <span>{file.name}</span>;
                  }
                  return (
                    <FormattedHTMLMessage
                      id="uploadFont.dropzone.message"
                      values={{ maxSize: intl.formatNumber(maxUploadSize / (1024 * 1024)) }}
                    />
                  );
                }}
              </Dropzone>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="fontName"
                value={fontName}
                onChange={this.onFontNameChange}
                label={<FormattedMessage id="uploadFont.font_name" />}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog}>Annuler</Button>
          <Button
            color="primary"
            disabled={!file || !fontName || !fontName.length}
            onClick={() => addFont(file, fontName, true)}
          >
            Importer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const makeStateToProps = () => {
  const stateToProps = state => {
    const key = makeSelectorInstance(getFontUploadDialogKey)(state);
    const open = makeSelectorInstance(isFontUploadDialogOpen)(state);
    const file = makeSelectorInstance(getFontUploadFile)(state);
    return {
      open,
      file,
      key, // Used to reset the component on each dialog open
    };
  };
  return stateToProps;
};

const dispatchToProps = {
  onCloseDialog: closeUploadFontDialog,
  addFont,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(UploadFontDialog);
