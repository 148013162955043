import { fade } from '@material-ui/core/styles/colorManipulator';
import { red } from '@material-ui/core/colors';

export const slideHeight = 80;
export const slideWidth = 118;

export const styles = theme => ({
  root: {
    // minWidth: 100,
    // minHeight: 80,
    height: slideHeight,
    width: slideWidth,
    textAlign: 'center',
    // marginRight: theme.spacing(2),
    border: '1px solid rgba(0, 0, 0, 0.05)',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    position: 'relative',
  },
  selected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: fade(theme.palette.primary.main, 0.14),
  },
  previewContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  preview: {
    position: 'relative',
    // flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    // padding: '15px 20px',
    // backgroundClip: 'padding-box',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    opacity: 0.5,
  },
  footer: {
    padding: theme.spacing(1),
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 20,
    pointerEvents: 'none',
  },
  number: {
    opacity: 0.5,
    fontSize: '75%',
  },
  duration: {
    width: 35,
    pointerEvents: 'auto',
    '&:hover:not($durationFocused)': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
  },
  durationInput: {
    padding: 0,
    textAlign: 'right',
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
  },
  inputAdornment: {
    marginLeft: 5,
  },
  durationUnderline: {
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
  },
  durationFocused: {
    background: '#FFF',
  },
  previewButton: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
      backgroundColor: theme.palette.action.hover,
    },
    transition: theme.transitions.create(['opacity', 'background-color'], {
      duration: theme.transitions.duration.short,
    }),
  },
  playButtonIcon: {
    zIndex: 1,
    color: theme.palette.primary.main,
    position: 'absolute',
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 32,
    height: 32,
    color: red[500],
    opacity: 0.25,
    '& > span': {
      marginTop: -8,
    },
    '&:hover': {
      opacity: 1,
    },
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.short,
    }),
  },
  duplicateButton: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 32,
    height: 32,
    opacity: 0.25,
    '&:hover': {
      opacity: 1,
    },
    '& > span': {
      marginTop: -8,
    },
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.short,
    }),
  },
});
