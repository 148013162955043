import React from 'react';
import { connect } from 'react-redux';
import { itemRenderedForExport } from './exportDucks';
import SceneSnapshot from '../scene/SceneSnapshot';
import {
  checkIfSlideIsLastSlideOverall,
  checkIfSlideIsFirstSlideOverall,
  checkIfSlideIsLastSlideOfTour,
  checkIfSlideIsFirstSlideOfTour,
} from '../player/util/slidePositions';
// This banner is rendered hidden, for several purposes :
// -> exporting to animated GIF
// -> exporting to PNG or JPEG
// -> creating a thumbnail

class BannerSnapshotHidden extends React.Component {
  componentDidMount() {
    const {
      bannerFormat: { width, height },
      bannerName,
      backgroundColor,
      itemRenderedForExport,
      transparentColor,
    } = this.props;
    itemRenderedForExport({ DOMNode: this.banner, width, height, bannerName, backgroundColor, transparentColor });
  }

  render() {
    const {
      slidesToExport,
      currentFrame,
      bannerUniqueId,
      bannerFormat,
      bannerName,
      backgroundColor,
      snapshotCurrentScene,
      repetitions,
    } = this.props;

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: bannerFormat.width,
          height: bannerFormat.height,
          zIndex: -1,
        }}
        ref={banner => (this.banner = banner)}
      >
        {slidesToExport.map(slideToExport => (
          <SceneSnapshot
            key={`-slide-${slideToExport.slideIndex}-bannerId-${bannerUniqueId}-frame-${currentFrame}`}
            bannerUniqueId={bannerUniqueId}
            format={bannerFormat}
            bannerName={bannerName}
            backgroundColor={backgroundColor}
            slideIndex={slideToExport.slideIndex}
            slide={slideToExport.slide}
            slidesForAnimation={slidesToExport}
            slideDurationForAnimation={slideToExport.slideDurationForAnimation}
            animationGlobalDelay={slideToExport.animationGlobalDelay}
            isLastSlide={checkIfSlideIsLastSlideOverall(slideToExport.slideIndex, slidesToExport)}
            isFirstSlide={checkIfSlideIsFirstSlideOverall(slideToExport.slideIndex)}
            isLastSlideOfTour={checkIfSlideIsLastSlideOfTour(slideToExport.slideIndex, slidesToExport, repetitions)}
            isFirstSlideOfTour={checkIfSlideIsFirstSlideOfTour(slideToExport.slideIndex, slidesToExport, repetitions)}
            currentFrame={currentFrame}
            snapshotCurrentScene={snapshotCurrentScene}
          />
        ))}
      </div>
    );
  }
}

const dispatchToProps = {
  itemRenderedForExport,
};

export default connect(
  null,
  dispatchToProps,
)(BannerSnapshotHidden);
