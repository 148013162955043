// Actions
import {
  BANNER_IMAGE_ELEMENT_FROM_123RF_REQUESTED,
  BANNER_IMAGE_ELEMENT_FROM_NUXEO_REQUESTED,
  BANNER_ELEMENT_ADDED,
  BANNER_CLEAR_STATE,
} from '../banner/bannerActions';
export const NEW_IMAGE_DIALOG_OPEN = 'adbuilder/NEW_IMAGE_DIALOG_OPEN';
export const NEW_IMAGE_DIALOG_CLOSE = 'adbuilder/NEW_IMAGE_DIALOG_CLOSE';
// Action creators

export const openNewImageDialog = (files = null) => ({
  type: NEW_IMAGE_DIALOG_OPEN,
  payload: {
    files,
  },
});

export const closeNewImageDialog = () => ({
  type: NEW_IMAGE_DIALOG_CLOSE,
});

// Selectors
export const getNewImageDialogInfos = ({ newImage }) => newImage;
export const getNewImageData = ({ newImage }) => newImage.imageData.files;

// Sagas

// Reducer

export const initialState = {
  open: false,
  busy: false,
  dialogKey: 0,
  imageData: {
    files: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NEW_IMAGE_DIALOG_OPEN: {
      return {
        ...state,
        open: true,
        dialogKey: state.dialogKey + 1,
        busy: false,
        imageData: { ...action.payload },
      };
    }
    case NEW_IMAGE_DIALOG_CLOSE: {
      return {
        ...state,
        open: false,
        dialogKey: state.dialogKey + 1,
        busy: false,
        imageData: { files: null },
      };
    }
    case BANNER_IMAGE_ELEMENT_FROM_123RF_REQUESTED:
    case BANNER_IMAGE_ELEMENT_FROM_NUXEO_REQUESTED: {
      return {
        ...state,
        busy: true,
      };
    }
    case BANNER_ELEMENT_ADDED: {
      return {
        ...state,
        open: false,
        busy: false,
        dialogKey: state.dialogKey + 1,
        imageData: { files: null },
      };
    }
    case BANNER_CLEAR_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
