import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withStyles, FormControl, InputLabel } from '@material-ui/core';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementDropShadowColor } from '../../banner/bannerSelectors';
import { updateElementShadow } from '../../banner/bannerActionsCreators';
import { styles } from '../propertyEditorStyles';
import RgbaColorPicker from '../../components/ColorPicker/RgbaColorPicker';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

class ElementDropShadowColor extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    return { shrink: nextProps.classes.labelShrink };
  }

  onShadowColorChange = color => {
    const { id, updateElementShadow } = this.props;
    updateElementShadow({ color, id });
  };

  render() {
    const { id, color } = this.props;

    return (
      <FormControl fullWidth>
        <InputLabel shrink classes={this.state}>
          <FormattedMessage id="properties.shadow.color" />
        </InputLabel>
        <RgbaColorPicker key={id} enableAlpha value={color} initialColor={color} onChange={this.onShadowColorChange} />
      </FormControl>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  color: makeSelectorInstance(getSelectedElementDropShadowColor)(state),
});

const dispatchToProps = {
  updateElementShadow,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  withStyles(styles),
)(ElementDropShadowColor);
