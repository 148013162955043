import { BannerRecord } from '../reference/records';
import * as actions from './bannerActions';
import { createBanner } from './bannerUtils';
import { openAlert } from '../alert/alertDucks';

export const clearState = () => ({
  type: actions.BANNER_CLEAR_STATE,
});

export const triggerUndoRedo = () => ({
  type: actions.BANNER_TRIGGER_UNDO_REDO,
});

export const addNewBanner = ({ banner = null, name } = {}) => {
  let payload;
  if (banner) {
    if (banner.present) {
      payload = banner;
    } else {
      payload = {
        past: [],
        present: BannerRecord({ ...banner.toObject(), name: banner.name.toUpperCase() }),
        future: [],
      };
    }
  } else {
    payload = {
      past: [],
      present: createBanner(name.toUpperCase()),
      future: [],
    };
  }
  return {
    type: actions.BANNER_NEW,
    payload,
  };
};

export const changeFormat = ({ name, width, height, bannerIndex }) => ({
  type: actions.BANNER_CHANGE_FORMAT,
  payload: { name, width, height, bannerIndex },
});

export const setBannerName = (name, bannerIndex, untrack = false) => ({
  type: actions.BANNER_SET_NAME,
  payload: {
    name: name.toUpperCase(),
    bannerIndex,
  },
  untrack,
});

export const setBannerThumbnail = ({ bannerIndex, currentIndex }) => ({
  type: actions.BANNER_SET_THUMBNAIL,
  payload: { bannerIndex, currentIndex },
});

export const setBannerZoom = zoom => ({
  type: actions.BANNER_SET_ZOOM,
  payload: zoom,
});

export const setBannerBackgroundColor = color => ({
  type: actions.BANNER_SET_BACKGROUND_COLOR,
  payload: color,
});

export const toggleBannerDissociateFromMaster = dissociateFromMaster => ({
  type: actions.BANNER_TOGGLE_DISSOCIATE_FROM_MASTER,
  payload: dissociateFromMaster,
});

export const setBannerTransparentColor = color => ({
  type: actions.BANNER_SET_TRANSPARENT_COLOR,
  payload: color,
});

export const setBannerExpirationDate = ({ bannerIndex, expirationDate }) => ({
  type: actions.BANNER_SET_EXPIRATION_DATE,
  payload: { bannerIndex, expirationDate },
});

export const setBannerExpirationDateUpdate = expirationDate => ({
  type: actions.BANNER_SET_EXPIRATION_DATE_REQUESTED,
  payload: { expirationDate },
});

export const setAnimationRepetition = repetitions => ({
  type: actions.BANNER_ANIMATION_SET_REPETITION,
  payload: repetitions,
});

export const addElementRequested = (elementProperties = {}) => ({
  type: actions.BANNER_ADD_ELEMENT_REQUESTED,
  payload: elementProperties,
});

export const addImageElementFromComputer = (file, name, id) => ({
  type: actions.BANNER_IMAGE_ELEMENT_FROM_COMPUTER_REQUESTED,
  payload: { file, name, id },
});

export const addImageElementFrom123RF = image => ({
  type: actions.BANNER_IMAGE_ELEMENT_FROM_123RF_REQUESTED,
  payload: image,
});

export const addImageElementFromNuxeo = image => ({
  type: actions.BANNER_IMAGE_ELEMENT_FROM_NUXEO_REQUESTED,
  payload: image,
});

export const addedElement = (element, slideIndex) => ({
  type: actions.BANNER_ELEMENT_ADDED,
  payload: {
    element,
    slideIndex,
  },
});

// Called asynchronously, so the selected element id can have changed.
export const setElementImageResource = ({ id, resourceId }) => ({
  type: actions.ELEMENT_SET_IMAGE_RESOURCE,
  payload: {
    id,
    resourceId,
  },
});

// Called asynchronously, so the selected element id can have changed.
export const setElementOptimizedImageResource = ({ id }) => ({
  type: actions.ELEMENT_SET_OPTIMIZED_IMAGE_RESOURCE,
  payload: {
    id,
  },
});

export const updateElementDisposition = ({ id, slideIndex, disposition }) => ({
  type: actions.ELEMENT_UPDATE_DISPOSITION,
  payload: { disposition, id, slideIndex },
});

export const updateElementIndex = ({ oldIndex, newIndex, slideIndex, id }) => ({
  type: actions.ELEMENT_UPDATE_INDEX,
  payload: { oldIndex, newIndex, slideIndex, id },
});

// Can be called on any element, not just the selected one.
export const setElementPosition = ({ id, x, y, isCropping = false, untrack }) => ({
  type: actions.ELEMENT_UPDATE_POSITION,
  payload: {
    id,
    x: parseInt(x * 10, 10) / 10,
    y: parseInt(y * 10, 10) / 10,
    isCropping,
  },
  untrack,
});

export const updateElement = (updatedProps, untrack = false) => ({
  type: actions.ELEMENT_UPDATE,
  payload: updatedProps,
  untrack,
});

export const setElementTextContent = ({ text, id }) => ({
  type: actions.ELEMENT_UPDATE,
  payload: { text, id },
});

// Can be called on any element, not just the selected one.
export const setElementDimensions = ({ id, x, y, width, height, isCropping = false, untrack = false }) => ({
  type: actions.ELEMENT_UPDATE_DIMENSIONS,
  payload: {
    id,
    x: Math.round(x),
    y: Math.round(y),
    width: Math.round(width),
    height: Math.round(height),
    isCropping,
  },
  untrack,
});

// Can be called on any element, not just the selected one.
export const setElementDimensionsRequested = ({
  id,
  x,
  y,
  width,
  height,
  isResizing,
  isCropping,
  untrack = false,
}) => ({
  type: actions.ELEMENT_UPDATE_DIMENSIONS_REQUESTED,
  payload: {
    id,
    x,
    y,
    width,
    height,
    isResizing,
    isCropping,
    untrack,
  },
});

export const setElementFontStyle = ({ id, fontStyle }) => ({
  type: actions.ELEMENT_UPDATE_FONT_STYLE,
  payload: {
    fontStyle,
    id,
  },
});

export const setElementFontFamilyRequested = ({ fontFamily, id }) => ({
  type: actions.ELEMENT_SET_FONT_FAMILY_REQUESTED,
  payload: {
    fontFamily,
    id,
  },
});

// Called asynchronously, so the selected element id can have changed.
export const setElementFontFamilySuccess = ({ id, fontFamily }) => ({
  type: actions.ELEMENT_UPDATE,
  payload: {
    id,
    fontFamily,
  },
});

export const setElementFontSize = ({ fontSize, id }) => ({
  type: actions.ELEMENT_SET_FONT_SIZE,
  payload: {
    fontSize,
    id,
  },
});

export const setElementColor = ({ color, id }) => ({
  type: actions.ELEMENT_UPDATE,
  payload: {
    color,
    id,
  },
});

export const setElementOpacity = ({ opacity, id }) => ({
  type: actions.ELEMENT_UPDATE,
  payload: {
    opacity,
    id,
  },
});

export const setElementRotation = ({ id, rotation, isCropping = false, untrack = false }) => ({
  type: actions.ELEMENT_UPDATE_ROTATION,
  payload: {
    id,
    rotation,
    isCropping,
  },
  untrack,
});

export const setElementLockAspectRatio = ({ id, lockAspectRatio, untrack = false }) => ({
  type: actions.ELEMENT_UPDATE,
  payload: {
    lockAspectRatio,
    id,
  },
  untrack,
});

export const setElementBackToOriginalSize = id => ({
  type: actions.ELEMENT_SET_BACK_TO_ORIGINAL_SIZE,
  payload: { id },
});

export const setElementBackToOriginalImage = id => ({
  type: actions.ELEMENT_SET_BACK_TO_ORIGINAL_IMAGE,
  payload: { id },
});

export const setElementHorizontalAlignment = ({ id, horizontalAlign }) => ({
  type: actions.ELEMENT_UPDATE,
  payload: {
    horizontalAlign,
    id,
  },
});

export const setElementVerticalAlignment = ({ id, verticalAlign }) => ({
  type: actions.ELEMENT_UPDATE,
  payload: {
    verticalAlign,
    id,
  },
});

export const setElementHorizontalFlip = ({ id, flipH }) => ({
  type: actions.ELEMENT_UPDATE,
  payload: {
    flipH,
    id,
  },
});

export const setElementVerticalFlip = ({ id, flipV }) => ({
  type: actions.ELEMENT_UPDATE,
  payload: {
    flipV,
    id,
  },
});

export const setElementShadowEnabled = (dropShadowEnabled, id) => ({
  type: actions.ELEMENT_TOGGLE_SHADOW,
  payload: {
    dropShadowEnabled,
    id,
  },
});

export const updateElementShadow = shadowProps => ({
  type: actions.ELEMENT_UPDATE_SHADOW,
  payload: shadowProps,
});

export const toggleElementBlink = (blinkAnimationEnabled, id) => ({
  type: actions.ELEMENT_TOGGLE_BLINK,
  payload: {
    blinkAnimationEnabled,
    id,
  },
});

export const updateElementBlink = blinkAnimation => ({
  type: actions.ELEMENT_UPDATE_BLINK,
  payload: blinkAnimation,
});

export const setElementDuration = ({ id, duration, untrack = false }) => ({
  type: actions.ELEMENT_UPDATE,
  payload: {
    id,
    duration,
  },
  untrack,
});

export const setElementCustomizable = (customizable, id) => ({
  type: actions.ELEMENT_UPDATE,
  payload: {
    customizable,
    property: customizable ? undefined : null,
    id,
  },
});

export const setElementProperty = ({ property, id }) => ({
  type: actions.ELEMENT_UPDATE,
  payload: {
    property,
    id,
  },
});

export const setElementTransitionParameters = ({ id, transitionName, params, untrack = false }) => ({
  type: actions.ELEMENT_UPDATE_TRANSITION,
  payload: {
    transitionName,
    value: params,
    id,
  },
  untrack,
});

export const setElementTransitionInType = ({ id, type, untrack = false }) => ({
  type: actions.ELEMENT_UPDATE_TRANSITION,
  payload: {
    transitionName: 'transitionIn',
    value: { type },
    id,
  },
  untrack,
});

export const setElementTransitionInDuration = ({ id, duration, untrack = false }) => ({
  type: actions.ELEMENT_UPDATE_TRANSITION,
  payload: {
    id,
    transitionName: 'transitionIn',
    value: { duration: parseInt(duration * 100, 10) / 100 },
  },
  untrack,
});

export const setElementTransitionTranslationDirection = ({ id, direction, transitionName, untrack = false }) => ({
  type: actions.ELEMENT_UPDATE_TRANSITION,
  payload: {
    id,
    transitionName,
    value: { direction },
  },
  untrack,
});

export const setElementTransitionTranslationDistance = ({ id, distance, transitionName, untrack = false }) => ({
  type: actions.ELEMENT_UPDATE_TRANSITION,
  payload: {
    id,
    transitionName,
    value: { distance },
  },
  untrack,
});

export const toggleElementTransitionDistanceDefault = ({ id, defaultDistance, transitionName, untrack = false }) => ({
  type: actions.ELEMENT_UPDATE_TRANSITION,
  payload: {
    id,
    transitionName,
    value: { defaultDistance },
  },
  untrack,
});

export const setElementTransitionInDelay = ({ id, delay, untrack = false }) => ({
  type: actions.ELEMENT_UPDATE_TRANSITION,
  payload: {
    id,
    transitionName: 'transitionIn',
    value: { delay: parseInt(delay * 100, 10) / 100 || 0 },
  },
  untrack,
});

export const setElementTransitionOutType = ({ id, type, untrack = false }) => ({
  type: actions.ELEMENT_UPDATE_TRANSITION,
  payload: {
    transitionName: 'transitionOut',
    value: { type },
    id,
  },
  untrack,
});

export const setElementTransitionOutDuration = ({ id, duration, untrack = false }) => ({
  type: actions.ELEMENT_UPDATE_TRANSITION,
  payload: {
    id,
    transitionName: 'transitionOut',
    value: { duration: parseInt(duration * 100, 10) / 100 },
  },
  untrack,
});

//Multiple elements
export const updateElements = (updatedProps, untrack = false) => ({
  type: actions.ELEMENTS_UPDATE,
  payload: updatedProps,
  untrack,
});

//Multiple elements
export const updateElementsWithDiff = (updatedProps, untrack = false) => ({
  type: actions.ELEMENTS_UPDATE_DIFF,
  payload: updatedProps,
  untrack,
});

export const setElementsDuration = ({ ids, duration, untrack = false }) => ({
  type: actions.ELEMENTS_UPDATE,
  payload: { duration, ids },
  untrack,
});

export const setElementsTransitionInType = ({ ids, type, untrack = false }) => ({
  type: actions.ELEMENTS_UPDATE_DEEP,
  payload: {
    ids,
    property: 'transitionIn',
    value: { type },
  },
  untrack,
});

export const setElementsTransitionInDuration = ({ ids, duration, untrack = false }) => ({
  type: actions.ELEMENTS_UPDATE_DEEP,
  payload: {
    ids,
    property: 'transitionIn',
    value: { duration: parseInt(duration * 100, 10) / 100 },
  },
  untrack,
});

export const setElementsTransitionTranslationDirection = ({ ids, direction, transitionName, untrack = false }) => ({
  type: actions.ELEMENTS_UPDATE_DEEP,
  payload: {
    ids,
    property: transitionName,
    value: { direction },
  },
  untrack,
});

export const setElementsTransitionTranslationDistance = ({ ids, distance, transitionName, untrack = false }) => ({
  type: actions.ELEMENTS_UPDATE_DEEP,
  payload: {
    ids,
    property: transitionName,
    value: { distance },
  },
  untrack,
});

export const setElementsTransitionInDelay = ({ ids, delay, untrack = false }) => ({
  type: actions.ELEMENTS_UPDATE_DEEP,
  payload: {
    ids,
    property: 'transitionIn',
    value: { delay: parseInt(delay * 100, 10) / 100 || 0 },
  },
  untrack,
});

export const setElementsTransitionOutType = ({ ids, type, untrack = false }) => ({
  type: actions.ELEMENTS_UPDATE_DEEP,
  payload: {
    ids,
    property: 'transitionOut',
    value: { type },
  },
  untrack,
});

export const setElementsTransitionOutDuration = ({ ids, duration, untrack = false }) => ({
  type: actions.ELEMENTS_UPDATE_DEEP,
  payload: {
    ids,
    property: 'transitionOut',
    value: { duration: parseInt(duration * 100, 10) / 100 },
  },
  untrack,
});

export const toggleElementsTransitionDistanceDefault = ({ defaultDistance, transitionName, untrack = false }) => ({
  type: actions.ELEMENTS_UPDATE_DEEP,
  payload: {
    property: transitionName,
    value: { defaultDistance },
  },
  untrack,
});

export const setElementShowOnAllSlides = (showOnAllSlides, id, slideIndex) => ({
  type: actions.ELEMENT_SET_SHOW_ON_ALL_SLIDES,
  payload: { showOnAllSlides, id, slideIndex },
});

export const setElementsShowOnAllSlides = (showOnAllSlides, ids, slideIndex) => ({
  type: actions.ELEMENTS_SET_SHOW_ON_ALL_SLIDES,
  payload: { showOnAllSlides, ids, slideIndex },
});

export const onCropClickRequested = reset => ({
  type: actions.ELEMENT_CROP_REQUESTED,
  payload: reset,
});

export const setImagepicWhileCroppingPropertiesPosition = ({ id, x, y }) => ({
  type: actions.ELEMENT_SET_IMAGE_PROPERTIES_POSITION,
  payload: {
    x,
    y,
    id,
  },
});

export const setImagepicWhileCroppingPropertiesDimensions = ({ id, height, width }) => ({
  type: actions.ELEMENT_SET_IMAGE_PROPERTIES_DIMENSIONS,
  payload: {
    width,
    height,
    id,
  },
});

export const setImagepicWhileCroppingPropertiesRotation = ({ id, rotation }) => ({
  type: actions.ELEMENT_SET_IMAGE_PROPERTIES_ROTATION,
  payload: {
    rotation,
    id,
  },
});

export const deleteElement = id => ({
  type: actions.ELEMENT_DELETE,
  payload: id,
});

export const deleteElementRequested = id => ({
  type: actions.ELEMENT_DELETE_REQUESTED,
  payload: id,
});

export const openDeleteElementDialog = (id, allSlides) =>
  openAlert({
    title: 'element.delete.title',
    message: allSlides ? 'element.delete.text.all' : 'element.delete.text',
    actionLabel: 'element.delete.action',
    actionColor: 'red',
    action: deleteElementRequested(id),
  });

const deleteElements = ids => ({
  type: actions.ELEMENTS_DELETE,
  payload: {
    ids,
  },
});

export const openDeleteElementsDialog = (ids, someElementsAreOnAllSlides) =>
  openAlert({
    title: 'elements.delete.title',
    message: someElementsAreOnAllSlides ? 'elements.delete.text.all' : 'elements.delete.text',
    actionLabel: 'element.delete.action',
    actionColor: 'red',
    action: deleteElements(ids),
  });

export const addSlide = newSlideIndex => ({
  type: actions.SLIDE_ADD,
  payload: newSlideIndex,
});

export const addSlideRequested = () => ({
  type: actions.SLIDE_ADD_REQUESTED,
});

export const duplicateSlide = ({ bannerIndex, slide, elements, nextId }) => ({
  type: actions.SLIDE_DUPLICATE,
  payload: { bannerIndex, slide, elements, nextId },
});

const requestDeleteSlide = index => ({
  type: actions.SLIDE_DELETE_REQUESTED,
  payload: index,
});

export const deleteSlide = (deletedIndex, numberOfSlides) => ({
  type: actions.SLIDE_DELETE,
  payload: {
    deletedIndex,
    numberOfSlides,
  },
});

const padToTwo = number => (number <= 99 ? ('0' + number).slice(-2) : number);

export const openDeleteSlideDialog = index =>
  openAlert({
    title: 'slide.delete.title',
    titleValues: { number: padToTwo(index + 1) },
    message: 'slide.delete.text',
    actionLabel: 'slide.delete.action',
    actionColor: 'red',
    action: requestDeleteSlide(index),
  });

/* call asynchronously to call also setBannerDuration with updated values */
export const setSlideDuration = ({ index, duration, untrack = false }) => ({
  type: actions.SLIDE_SET_DURATION,
  payload: { index, duration: parseInt(duration * 10, 10) / 10 },
  untrack,
});

export const setSlideDurationRequested = ({ index, duration, oldDuration, elements }) => ({
  type: actions.SLIDE_SET_DURATION_REQUESTED,
  payload: { index, duration: parseInt(duration * 10, 10) / 10, oldDuration, elements },
});

export const setSlidesDuration = ({ indexes, duration, untrack = false }) => ({
  type: actions.SLIDES_SET_DURATION,
  payload: { indexes, duration: parseInt(duration * 10, 10) / 10 },
  untrack,
});

export const setSlideTransitionType = (index, type) => ({
  type: actions.SLIDE_SET_TRANSITION_TYPE,
  payload: { index, type },
});

export const setSlideTransitionDuration = ({ index, duration, untrack = false }) => ({
  type: actions.SLIDE_SET_TRANSITION_DURATION,
  payload: { index, duration: parseInt(duration * 10, 10) / 10 },
  untrack,
});

export const checkAndUpdateSlideTransitionRequested = (slideIndex, newSlideDuration) => ({
  type: actions.SLIDE_CHECK_AND_UPDATE_SLIDE_TRANSITION,
  payload: { slideIndex, newSlideDuration },
});
