import { List, Set } from 'immutable';
// reference - helpers
import { BannersRecord, BannerFormatRecord, bannerSetFromJS, BannerSetRecord, SlideRecord } from '../reference/records';
import {
  checkBannerIsDissociated,
  updateSlaveBanners,
  setElementStartingPosition,
  calculateX,
  calculateY,
} from './bannersUtils';
import { getBannerFormat } from '../shared-selectors/sharedSelectors';
import {
  BANNER_SET_NAME,
  BANNER_NEW,
  BANNER_CLEAR_STATE,
  BANNER_CHANGE_FORMAT,
  BANNER_SET_THUMBNAIL,
  BANNER_ELEMENT_ADDED,
  BANNER_SET_BACKGROUND_COLOR,
  BANNER_ANIMATION_SET_REPETITION,
  ELEMENT_UPDATE_DISPOSITION,
  ELEMENT_UPDATE_INDEX,
  ELEMENT_SET_FONT_SIZE,
  ELEMENT_SET_BACK_TO_ORIGINAL_SIZE,
  ELEMENT_SET_BACK_TO_ORIGINAL_IMAGE,
  ELEMENT_SET_IMAGE_PROPERTIES_POSITION,
  ELEMENT_SET_IMAGE_PROPERTIES_DIMENSIONS,
  ELEMENT_SET_IMAGE_PROPERTIES_ROTATION,
  ELEMENT_DELETE,
  ELEMENT_UPDATE,
  ELEMENT_UPDATE_FONT_STYLE,
  ELEMENT_UPDATE_DIMENSIONS,
  ELEMENT_UPDATE_POSITION,
  ELEMENT_UPDATE_ROTATION,
  ELEMENT_UPDATE_TRANSITION,
  ELEMENT_TOGGLE_SHADOW,
  ELEMENT_UPDATE_SHADOW,
  ELEMENT_TOGGLE_BLINK,
  ELEMENT_UPDATE_BLINK,
  ELEMENT_SET_SHOW_ON_ALL_SLIDES,
  ELEMENTS_UPDATE,
  ELEMENTS_UPDATE_DEEP,
  ELEMENTS_SET_SHOW_ON_ALL_SLIDES,
  ELEMENTS_DELETE,
  SLIDE_ADD,
  SLIDE_DUPLICATE,
  SLIDE_SET_DURATION,
  SLIDES_SET_DURATION,
  SLIDE_SET_TRANSITION_TYPE,
  SLIDE_SET_TRANSITION_DURATION,
  SLIDE_DELETE,
  BANNER_TRIGGER_UNDO_REDO,
  BANNER_SET_EXPIRATION_DATE,
} from '../banner/bannerActions';
import { createShadow, createBlink } from '../banner/bannerUtils';
import {
  slideDuplicateReducer,
  slideDeleteReducer,
  slideAddReducer,
  updateElementDispositionReducer,
  updateElementIndexReducer,
  updateElementFontSizeReducer,
  updateElementShowOnAllSlidesReducer,
  updateElementsReducer,
  updateElementsShowOnAllSlidesReducer,
} from '../banner/bannerDucks';
import {
  BANNERSET_SET_FPS,
  BANNERSET_SET_INFOS,
  BANNERSET_SET_PERMISSIONS,
  BANNERSET_SET_LOCK,
  BANNERSET_RESET_INFOS,
  BANNERSET_SET_NAME,
  BANNERSET_SET_THUMBNAIL,
  BANNERS_UPDATE,
  BANNERS_CLOSE,
  BANNERS_CLOSE_ALL,
  BANNERS_SET_MASTER,
  BANNERS_SLIDE_DUPLICATE,
  BANNERS_DELETE_ELEMENT_AFTER_CUT,
  BANNERSET_SET_EXPIRATION_DATE,
} from './bannersActions';
import { getBannerMasterFromReducer, checkIfCanExistBannerMaster } from './bannersSelectors';

// Reducer
export const initialState = BannersRecord();

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BANNER_NEW: {
      return state.update('banners', banners => banners.push(action.payload));
    }
    case BANNERS_UPDATE: {
      const { bannerIndex, banner, selectedElementId, selectedElementIds, selectedSlideIndex } = action.payload;

      return state.withMutations(s => {
        const isBannerMaster = s.banners.findIndex(ban => ban.present.get('isMaster')) === bannerIndex;
        const elements = banner.present.get('elements');
        const bannerElementsImages = {};
        elements.filter(element => element.type === 'image' && element.optimizedResourceId).forEach(element => {
          bannerElementsImages[element.id] = {
            optimizedResourceId: element.optimizedResourceId,
          };
        });

        s.setIn(['banners', bannerIndex], {
          ...banner,
          present: banner.present.withMutations(b => {
            return b
              .set('selectedElementId', selectedElementId)
              .set('selectedElementIds', selectedElementIds)
              .set('selectedSlideIndex', selectedSlideIndex);
          }),
        });

        s.banners.forEach((ban, banIndex) => {
          if (isBannerMaster) {
            s.updateIn(['banners', banIndex, 'present', 'elements'], elements => {
              return elements.withMutations(els => {
                els.forEach((el, elIndex) => {
                  const masterElement = bannerElementsImages[el.get('id')];
                  if (
                    masterElement &&
                    banIndex !== bannerIndex &&
                    el.get('type') === 'image' &&
                    !el.get('optimizedResourceId') &&
                    !ban.present.get('dissociateFromMaster')
                  ) {
                    els.set(
                      elIndex,
                      el.withMutations(e => e.set('optimizedResourceId', masterElement.optimizedResourceId)),
                    );
                  }
                });
                return els;
              });
            });
          }

          return s;
        });
      });
    }
    case BANNER_CHANGE_FORMAT: {
      return state.setIn(
        ['banners', action.payload.bannerIndex, 'present', 'format'],
        BannerFormatRecord(action.payload),
      );
    }
    case BANNER_SET_NAME: {
      return state.setIn(['banners', action.payload.bannerIndex, 'present', 'name'], action.payload.name);
    }
    case BANNERS_SLIDE_DUPLICATE: {
      return state.updateIn(['banners', action.payload.bannerIndex, 'present'], banner =>
        slideDuplicateReducer(banner, action.payload),
      );
    }
    case BANNERS_DELETE_ELEMENT_AFTER_CUT: {
      const { bannerIndex, elementId } = action.payload;
      return state
        .updateIn(['banners', bannerIndex, 'present', 'elements'], elements =>
          elements.filter((_, id) => id !== elementId),
        )
        .updateIn(['banners', bannerIndex, 'present', 'slides'], slides =>
          slides.map(slide =>
            SlideRecord({
              ...slide.toObject(),
              elementIds: slide.elementIds.filter(id => id !== elementId),
            }),
          ),
        );
    }
    case BANNER_SET_THUMBNAIL: {
      return state.setIn(
        ['banners', action.payload.bannerIndex, 'present', 'thumbnailResourceId'],
        action.payload.resourceId,
      );
    }
    case BANNER_SET_EXPIRATION_DATE: {
      return state.setIn(
        ['banners', action.payload.bannerIndex, 'present', 'expirationDate'],
        action.payload.expirationDate,
      );
    }
    case BANNERSET_SET_NAME: {
      return state.setIn(['bannerSet', 'name'], action.payload.name);
    }
    case BANNERSET_SET_FPS: {
      return state.setIn(['bannerSet', 'framesPerSecond'], action.payload.framesPerSecond);
    }
    case BANNERSET_SET_THUMBNAIL: {
      return state.setIn(['bannerSet', 'thumbnailResourceId'], action.payload.resourceId);
    }
    case BANNERSET_SET_INFOS: {
      return state.update('bannerSet', bannerSet => bannerSetFromJS({ ...bannerSet.toObject(), ...action.payload }));
    }
    case BANNERSET_SET_PERMISSIONS: {
      return state.setIn(['bannerSet', 'permissions'], action.payload);
    }
    case BANNERSET_SET_LOCK: {
      return state.setIn(['bannerSet', 'lockOwner'], action.payload);
    }
    case BANNERSET_RESET_INFOS: {
      return state.set('bannerSet', BannerSetRecord());
    }
    case BANNERSET_SET_EXPIRATION_DATE: {
      return state.setIn(['bannerSet', 'expirationDate'], action.payload.expirationDate);
    }
    case BANNERS_CLOSE: {
      return state.deleteIn(['banners', action.payload.deletedIndex]);
    }
    case BANNERS_CLOSE_ALL: {
      if (!BannersRecord()) return state;
      return BannersRecord();
    }
    case BANNERS_SET_MASTER: {
      return state.withMutations(s =>
        s.update('banners', banners =>
          banners.map((banner, index) => ({
            ...banner,
            present: banner.present.set('isMaster', index === action.payload),
          })),
        ),
      );
    }
    case BANNER_CLEAR_STATE: {
      return initialState;
    }
    default:
      break;
  }
  /* BANNER MASTER */

  if (!checkIfCanExistBannerMaster({ banners: { present: state } })) return state;
  const bannerMaster = getBannerMasterFromReducer({ banners: { present: state } });
  if (!action.fromMasterBanner) return state;
  if (!bannerMaster) return state;

  const slideIndex = action.payload && action.payload.slideIndex >= 0 && action.payload.slideIndex;
  const slideElementIds =
    bannerMaster.slides && bannerMaster.slides.get(slideIndex)
      ? bannerMaster.slides.get(slideIndex).elementIds
      : List();

  const elementId =
    (action.payload && action.payload.id) ||
    (action.payload && action.payload.ids && action.payload.ids.length === 1 && action.payload.ids[0]);
  const bannerMasterSelectedElement = bannerMaster.elements.get(elementId);
  const elementIds = (action.payload && action.payload.ids) || bannerMaster.selectedElementIds;

  switch (action.type) {
    case BANNER_ELEMENT_ADDED: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          const bannerFormat = getBannerFormat({ banner });
          const resourceId = action.payload.resourceId || action.payload.element.resourceId;
          return {
            ...banner,
            present: banner.present.withMutations(s =>
              s
                .updateIn(['slides', slideIndex, 'elementIds'], elIds => elIds.unshift(action.payload.element.id))
                .setIn(
                  ['elements', action.payload.element.id],
                  setElementStartingPosition(action.payload.element, bannerFormat, resourceId),
                )
                .updateIn(['nextId', action.payload.element.type], typeId => typeId + 1),
            ),
          };
        }),
      );
    }
    case BANNER_SET_BACKGROUND_COLOR: {
      return state.update('banners', banners =>
        updateSlaveBanners({
          banners,
          bannerMaster,
          property: 'backgroundColor',
          value: action.payload,
        }),
      );
    }
    case BANNER_ANIMATION_SET_REPETITION: {
      return state.update('banners', banners =>
        updateSlaveBanners({
          banners,
          bannerMaster,
          property: 'repetitions',
          value: action.payload,
        }),
      );
    }
    case ELEMENT_UPDATE_DISPOSITION: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: updateElementDispositionReducer({
              action,
              banner: banner.present,
              slideElementIds,
              elementId,
              slideIndex,
            }),
          };
        }),
      );
    }
    case ELEMENT_UPDATE_INDEX: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: updateElementIndexReducer({ action, banner: banner.present, slideElementIds, slideIndex }),
          };
        }),
      );
    }
    case ELEMENT_SET_FONT_SIZE: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          const selectedElement = banner.present.elements.get(elementId);
          return {
            ...banner,
            present: updateElementFontSizeReducer({ action, banner: banner.present, elementId, selectedElement }),
          };
        }),
      );
    }
    case ELEMENT_SET_BACK_TO_ORIGINAL_SIZE: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          const selectedElement = banner.present.elements.get(elementId);
          return {
            ...banner,
            present: banner.present
              .mergeIn(['elements', elementId], {
                height: selectedElement.originalSize.height,
                width: selectedElement.originalSize.width,
                x: Math.floor((banner.present.format.width - selectedElement.originalSize.width) / 2),
                y: Math.floor((banner.present.format.height - selectedElement.originalSize.height) / 2),
              })
              .mergeIn(['elements', elementId, 'picWhileCroppingProperties'], {
                height: selectedElement.originalSize.height,
                width: selectedElement.originalSize.width,
                x: Math.floor((banner.present.format.width - selectedElement.originalSize.width) / 2),
                y: Math.floor((banner.present.format.height - selectedElement.originalSize.height) / 2),
              }),
          };
        }),
      );
    }
    case ELEMENT_SET_BACK_TO_ORIGINAL_IMAGE: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          const selectedElement = banner.present.elements.get(elementId);
          return {
            ...banner,
            present: banner.present.mergeIn(['elements', elementId, 'picWhileCroppingProperties'], {
              height: selectedElement.height,
              width: selectedElement.width,
              x: selectedElement.x,
              y: selectedElement.y,
            }),
          };
        }),
      );
    }
    case ELEMENT_DELETE: {
      const idToDelete = action.payload;
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: banner.present
              .update('slides', slides =>
                slides.map(slide => slide.update('elementIds', elIds => elIds.filter(id => id !== idToDelete))),
              )
              .deleteIn(['elements', idToDelete])
              .set('selectedElementId', null),
          };
        }),
      );
    }
    case ELEMENT_UPDATE: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: banner.present.mergeIn(['elements', elementId], action.payload),
          };
        }),
      );
    }
    case ELEMENT_UPDATE_FONT_STYLE: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: banner.present.updateIn(['elements', elementId, 'fontStyle'], fontStyles => {
              if (fontStyles.has(action.payload.fontStyle)) {
                return fontStyles.delete(action.payload.fontStyle);
              }
              return fontStyles.add(action.payload.fontStyle);
            }),
          };
        }),
      );
    }
    case ELEMENT_UPDATE_ROTATION: {
      const { rotation, isCropping } = action.payload;
      if (!bannerMasterSelectedElement) return state;
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          const selectedElement = banner.present.elements.get(elementId);
          if (bannerMasterSelectedElement.type === 'image' && !isCropping) {
            return {
              ...banner,
              present: banner.present
                .setIn(['elements', elementId, 'rotation'], rotation)
                .updateIn(
                  ['elements', elementId, 'picWhileCroppingProperties', 'rotation'],
                  oldRotation => oldRotation + rotation - selectedElement.rotation,
                ),
            };
          }
          return {
            ...banner,
            present: banner.present.setIn(['elements', elementId, 'rotation'], rotation),
          };
        }),
      );
    }
    case ELEMENT_UPDATE_TRANSITION: {
      const { transitionName, value } = action.payload;
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: banner.present.mergeIn(['elements', elementId, transitionName], value),
          };
        }),
      );
    }
    case ELEMENT_TOGGLE_SHADOW: {
      return state.update('banners', banners =>
        banners.map(banner => {
          const selectedElement = banner.present.elements.get(elementId);
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: banner.present.mergeIn(['elements', elementId], {
              dropShadowEnabled: action.payload.dropShadowEnabled,
              dropShadow: selectedElement.dropShadow || createShadow(),
            }),
          };
        }),
      );
    }
    case ELEMENT_UPDATE_SHADOW: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: banner.present.mergeIn(['elements', elementId, 'dropShadow'], action.payload),
          };
        }),
      );
    }
    case ELEMENT_TOGGLE_BLINK: {
      return state.update('banners', banners =>
        banners.map(banner => {
          const selectedElement = banner.present.elements.get(elementId);
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: banner.present.mergeIn(['elements', elementId], {
              blinkAnimationEnabled: action.payload.blinkAnimationEnabled,
              blinkAnimation: selectedElement.blinkAnimation || createBlink(),
            }),
          };
        }),
      );
    }
    case ELEMENT_UPDATE_BLINK: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: banner.present.mergeIn(['elements', elementId, 'blinkAnimation'], action.payload),
          };
        }),
      );
    }
    case ELEMENT_SET_SHOW_ON_ALL_SLIDES: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: updateElementShowOnAllSlidesReducer({ action, banner: banner.present, slideIndex, elementId }),
          };
        }),
      );
    }
    case SLIDE_ADD: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: slideAddReducer(banner.present),
          };
        }),
      );
    }
    case SLIDE_DUPLICATE: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: slideDuplicateReducer(banner.present, action.payload, true),
          };
        }),
      );
    }
    case SLIDE_SET_DURATION: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: banner.present.setIn(['slides', action.payload.index, 'duration'], action.payload.duration),
          };
        }),
      );
    }
    case SLIDES_SET_DURATION: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: banner.present.update('slides', slides =>
              slides.map(
                slide =>
                  action.payload.indexes.includes(slide.id) ? slide.set('duration', action.payload.duration) : slide,
              ),
            ),
          };
        }),
      );
    }
    case SLIDE_SET_TRANSITION_TYPE: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: banner.present.setIn(['slides', action.payload.index, 'transition', 'type'], action.payload.type),
          };
        }),
      );
    }
    case SLIDE_SET_TRANSITION_DURATION: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: banner.present.setIn(
              ['slides', action.payload.index, 'transition', 'duration'],
              action.payload.duration,
            ),
          };
        }),
      );
    }
    case SLIDE_DELETE: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: slideDeleteReducer(banner.present, action.payload),
          };
        }),
      );
    }
    case ELEMENT_UPDATE_DIMENSIONS: {
      if (!bannerMasterSelectedElement) return state;
      const { x: masterX, y: masterY, width: masterWidth, height: masterHeight, isCropping } = action.payload;
      // get the master elements parameters for cases 2 & 3
      const masterElementBounds = {
        top: masterY,
        left: masterX,
        bottom: masterY + masterHeight,
        right: masterX + masterWidth,
        center: {
          x: masterX + masterWidth / 2,
          y: masterY + masterHeight / 2,
        },
      };
      const bannerMasterWidth = bannerMaster.format.width;
      const bannerMasterHeight = bannerMaster.format.height;
      const toLeft = masterElementBounds.center.x < bannerMasterWidth / 2;
      const toRight = masterElementBounds.center.x > bannerMasterWidth / 2;
      const toTop = masterElementBounds.center.y < bannerMasterHeight / 2;
      const toBottom = masterElementBounds.center.y > bannerMasterHeight / 2;

      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;

          const slaveElement = banner.present.elements.get(elementId);
          const resizeFactor = slaveElement.originalSize
            ? slaveElement.originalSize.width / bannerMasterSelectedElement.originalSize.width
            : 1;
          if (bannerMasterSelectedElement.type === 'image' && isCropping) {
            const x = Math.round(slaveElement.x + (masterX - bannerMasterSelectedElement.x) * resizeFactor);
            const y = Math.round(slaveElement.y + (masterY - bannerMasterSelectedElement.y) * resizeFactor);
            const width = Math.round(
              slaveElement.width + (masterWidth - bannerMasterSelectedElement.width) * resizeFactor,
            );
            const height = Math.round(
              slaveElement.height + (masterHeight - bannerMasterSelectedElement.height) * resizeFactor,
            );
            return {
              ...banner,
              present: banner.present.mergeIn(['elements', elementId], { x, y, width, height }),
            };
          }
          const positionFactor = {
            X: banner.present.format.width / bannerMaster.format.width,
            Y: banner.present.format.height / bannerMaster.format.height,
          };
          const width = masterWidth * resizeFactor;
          const height = masterHeight * resizeFactor;
          const x = Math.round(calculateX(toLeft, toRight, masterElementBounds, positionFactor, banner, width));
          const y = Math.round(calculateY(toTop, toBottom, masterElementBounds, positionFactor, banner, height));

          const resizeCoef = {
            width: width / slaveElement.width,
            height: height / slaveElement.height,
          };
          if (bannerMasterSelectedElement.type === 'image') {
            return {
              ...banner,
              present: banner.present
                .mergeIn(['elements', elementId], { x, y, width, height })
                .updateIn(['elements', elementId, 'picWhileCroppingProperties'], props =>
                  props.merge({
                    x: x - (slaveElement.x - props.x) * resizeCoef.width,
                    y: y - (slaveElement.y - props.y) * resizeCoef.height,
                    width: props.width * resizeCoef.width,
                    height: props.height * resizeCoef.height,
                  }),
                ),
            };
          }
          return {
            ...banner,
            present: banner.present.mergeIn(['elements', elementId], { x, y, width, height }),
          };
        }),
      );
    }
    case ELEMENT_UPDATE_POSITION: {
      if (!bannerMasterSelectedElement) return state;
      const { x: masterX, y: masterY, isCropping } = action.payload;
      // get the master elements parameters for cases 2 & 3
      const masterElementBounds = {
        top: masterY,
        left: masterX,
        bottom: masterY + bannerMasterSelectedElement.height,
        right: masterX + bannerMasterSelectedElement.width,
        center: {
          x: masterX + bannerMasterSelectedElement.width / 2,
          y: masterY + bannerMasterSelectedElement.height / 2,
        },
      };
      const bannerMasterWidth = bannerMaster.format.width;
      const bannerMasterHeight = bannerMaster.format.height;
      const toLeft = masterElementBounds.center.x < bannerMasterWidth / 2;
      const toRight = masterElementBounds.center.x > bannerMasterWidth / 2;
      const toTop = masterElementBounds.center.y < bannerMasterHeight / 2;
      const toBottom = masterElementBounds.center.y > bannerMasterHeight / 2;

      // update the state
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          const slaveElement = banner.present.elements.get(elementId);
          // case 1: cropping
          if (bannerMasterSelectedElement.type === 'image' && isCropping) {
            const resizeFactor = slaveElement.originalSize.width / bannerMasterSelectedElement.originalSize.width;
            const x = slaveElement.x + (masterX - bannerMasterSelectedElement.x) * resizeFactor;
            const y = slaveElement.y + (masterY - bannerMasterSelectedElement.y) * resizeFactor;
            return {
              ...banner,
              present: banner.present.mergeIn(['elements', elementId], { x, y }),
            };
          }

          const positionFactor = {
            X: banner.present.format.width / bannerMaster.format.width,
            Y: banner.present.format.height / bannerMaster.format.height,
          };

          const x = Math.round(
            calculateX(toLeft, toRight, masterElementBounds, positionFactor, banner, slaveElement.width),
          );
          const y = Math.round(
            calculateY(toTop, toBottom, masterElementBounds, positionFactor, banner, slaveElement.height),
          );
          // case 2: image
          if (bannerMasterSelectedElement.type === 'image' && !isCropping) {
            return {
              ...banner,
              present: banner.present
                .mergeIn(['elements', elementId], { x, y })
                .mergeIn(['elements', elementId, 'picWhileCroppingProperties'], {
                  x: slaveElement.picWhileCroppingProperties.x + (x - slaveElement.x),
                  y: slaveElement.picWhileCroppingProperties.y + (y - slaveElement.y),
                }),
            };
          }
          // case 3: basic element
          return {
            ...banner,
            present: banner.present.mergeIn(['elements', elementId], { x, y }),
          };
        }),
      );
    }
    case ELEMENT_SET_IMAGE_PROPERTIES_POSITION:
    case ELEMENT_SET_IMAGE_PROPERTIES_DIMENSIONS:
    case ELEMENT_SET_IMAGE_PROPERTIES_ROTATION: {
      // FIXME: does not work whith changing dimensions depending on banner format
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          const masterElement = bannerMaster.elements.get(elementId);
          const slaveElement = banner.present.elements.get(elementId);
          const masterPWCP = masterElement.picWhileCroppingProperties;
          const resizeFactor = slaveElement.originalSize.width / masterElement.originalSize.width;
          const slavePWCP = slaveElement.picWhileCroppingProperties;
          const updatedPayload = {
            x: Math.round(slavePWCP.x + ((action.payload.x || masterPWCP.x) - masterPWCP.x) * resizeFactor),
            y: Math.round(slavePWCP.y + ((action.payload.y || masterPWCP.y) - masterPWCP.y) * resizeFactor),
            width: Math.round(
              slavePWCP.width + ((action.payload.width || masterPWCP.width) - masterPWCP.width) * resizeFactor,
            ),
            height: Math.round(
              slavePWCP.height + ((action.payload.height || masterPWCP.height) - masterPWCP.height) * resizeFactor,
            ),
          };
          return {
            ...banner,
            present: banner.present.mergeIn(['elements', elementId, 'picWhileCroppingProperties'], updatedPayload),
          };
        }),
      );
    }
    case ELEMENTS_UPDATE: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: updateElementsReducer({ banner: banner.present, action, elementIds }),
          };
        }),
      );
    }
    case ELEMENTS_UPDATE_DEEP: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: banner.present.update('elements', els =>
              els.map(
                el => (elementIds.includes(el.id) ? el.mergeIn([action.payload.property], action.payload.value) : el),
              ),
            ),
          };
        }),
      );
    }
    case ELEMENTS_SET_SHOW_ON_ALL_SLIDES: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: updateElementsShowOnAllSlidesReducer({ action, banner: banner.present, slideIndex, elementIds }),
          };
        }),
      );
    }
    case ELEMENTS_DELETE: {
      return state.update('banners', banners =>
        banners.map(banner => {
          if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
          return {
            ...banner,
            present: banner.present
              .update('slides', slides =>
                slides.map(slide => slide.update('elementIds', elIds => elIds.filter(id => !elementIds.includes(id)))),
              )
              .update('elements', elements => elements.filter(el => !elementIds.includes(el.id)))
              .set('selectedElementId', null)
              .set('selectedElementIds', Set()),
          };
        }),
      );
    }
    case BANNER_TRIGGER_UNDO_REDO:
    default:
      return state;
  }
};

export default reducer;
