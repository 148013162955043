import React from 'react';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';
import { List, ListSubheader, withStyles } from '@material-ui/core';
import classnames from 'classnames';
import { SortableContainer } from 'react-sortable-hoc';

import ListedElement from './ListedElement';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from './elementListStyles';

const perfectScrollbarOptions = {
  suppressScrollX: true,
  handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
  wheelSpeed: 0.8,
  wheelPropagation: false,
};

const SortableElementList = ({
  formattedSlideNumber,
  elementIds,
  onMenuOpen,
  onItemClick,
  onListClick,
  classes,
  dragging,
  intl,
  theme,
}) => (
  <PerfectScrollbar
    key={JSON.stringify(theme)}
    className={classnames(classes.scrollContainer)}
    options={perfectScrollbarOptions}
  >
    <ListSubheader component="div" className={classes.subheader}>
      {intl.formatMessage({ id: 'app.elements' }, { formattedSlideNumber })}
    </ListSubheader>
    <List onClick={onListClick} className={classnames(classes.list, { [classes.dragging]: dragging })}>
      {elementIds &&
        elementIds.map((id, index) => (
          <ListedElement
            key={`${index}${id}`}
            id={id}
            index={index}
            onItemClick={onItemClick}
            onMenuOpen={onMenuOpen(id)}
          />
        ))}
    </List>
  </PerfectScrollbar>
);

export default compose(
  SortableContainer,
  injectIntl,
  withStyles(styles, { withTheme: true }),
)(SortableElementList);
