import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, onlyUpdateForKeys } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import {
  getSelectedElementPositionX,
  getSelectedElementPositionY,
  getSelectedElementWidth,
  getSelectedElementLockAspectRatio,
  getSelectedElementHeight,
} from '../../banner/bannerSelectors';
import { setElementDimensions } from '../../banner/bannerActionsCreators';
import { getSelectedElementId, getSelectedElementCroppingStatus } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementWidth extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    return {
      height: nextProps.height,
      width: nextProps.width,
      x: nextProps.x,
      y: nextProps.y,
      lockAspectRatio: nextProps.lockAspectRatio,
    };
  }

  onWidthChange = ({ value: newWidth, untrack, id, additionalData }) => {
    const { height: oldHeight, width: oldWidth, x, y, lockAspectRatio } = additionalData;
    const { setElementDimensions, isCropping } = this.props;
    setElementDimensions({
      width: newWidth,
      height: lockAspectRatio ? (oldHeight * newWidth) / oldWidth : oldHeight,
      id,
      x,
      y,
      isCropping,
      untrack,
    }); //track
  };

  render() {
    const { id, width, intl } = this.props;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        label={intl.formatMessage({ id: 'properties.dimension.width' })}
        value={Math.round(width)}
        additionalData={this.state}
        fullWidth
        onChangeMethod={this.onWidthChange}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  x: makeSelectorInstance(getSelectedElementPositionX)(state),
  y: makeSelectorInstance(getSelectedElementPositionY)(state),
  lockAspectRatio: makeSelectorInstance(getSelectedElementLockAspectRatio)(state),
  width: makeSelectorInstance(getSelectedElementWidth)(state),
  height: makeSelectorInstance(getSelectedElementHeight)(state),
  isCropping: makeSelectorInstance(getSelectedElementCroppingStatus)(state),
});

const dispatchToProps = {
  setElementDimensions,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  onlyUpdateForKeys(['width', 'height', 'x', 'y', 'lockAspectRatio']),
  injectIntl,
)(ElementWidth);
