import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import {
  acceptedFileTypesAsString,
  maxBannerUploadSize,
  areBanners,
  areBannerSets,
  areImages,
  areTemplates,
  areFonts,
  areLocalizations,
  areUnacceptedFiles,
  areMixedFiles,
  areUnacceptedFonts,
} from '../reference/importParams';
import {
  acceptDropzoneBgColor,
  acceptDropzoneBorderColor,
  rejectDropzoneBgColor,
  rejectDropzoneBorderColor,
} from '../reference/colors';
import { openNewImageDialog } from './newImageDucks';
import { addError } from '../messages/messagesDucks';
import { openOpenBannerSetDialogRequested } from '../open-banner-set/openBannerSetDucks';
import { openUploadFontDialog } from '../fonts/fontsDucks';
import { getBannerDisableDropZone } from '../shared-selectors/sharedSelectors';
import makeSelectorInstance from '../reference/makeSelectorInstance';

const styles = {
  root: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    zIndex: 1400,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    borderStyle: 'solid',
    borderRadius: 4,
  },
  accept: {
    backgroundColor: acceptDropzoneBgColor,
    borderColor: acceptDropzoneBorderColor,
  },
  reject: {
    backgroundColor: rejectDropzoneBgColor,
    borderColor: rejectDropzoneBorderColor,
  },
};

export const onDropAccepted = ({
  acceptedFiles,
  addError,
  openOpenBannerSetDialogRequested,
  openNewImageDialog,
  openUploadFontDialog,
}) => {
  if (areBanners(acceptedFiles) || areBannerSets(acceptedFiles)) {
    openOpenBannerSetDialogRequested(acceptedFiles[0], true);
  } else if (areImages(acceptedFiles)) {
    openNewImageDialog(acceptedFiles);
  } else if (areTemplates(acceptedFiles)) {
    addError('file.dropzone.error');
  } else if (areFonts(acceptedFiles)) {
    openUploadFontDialog(acceptedFiles[0]);
  } else if (areLocalizations(acceptedFiles)) {
    addError('file.dropzone.error');
  } else if (areUnacceptedFonts(acceptedFiles)) {
    addError('file.dropzone.error.unacceptedFont');
  } else if (areUnacceptedFiles(acceptedFiles)) {
    addError('file.dropzone.error.unacceptedTypes');
  } else if (areMixedFiles(acceptedFiles)) {
    addError('file.dropzone.error.mixedFiles');
  } else {
    addError('file.dropzone.error');
  }
};

const GlobalDropZone = ({
  classes,
  intl,
  children,
  addError,
  openOpenBannerSetDialogRequested,
  openNewImageDialog,
  openUploadFontDialog,
  ...otherProps
}) => (
  <Dropzone
    accept={acceptedFileTypesAsString + ', font/*, Document'}
    maxSize={maxBannerUploadSize}
    multiple={true}
    disablePreview={true}
    className={classes.root}
    onDropAccepted={acceptedFiles => {
      onDropAccepted({
        acceptedFiles,
        addError,
        openOpenBannerSetDialogRequested,
        openNewImageDialog,
        openUploadFontDialog,
      });
    }}
    onDropRejected={rejectedFiles => {
      const rejectedFile = rejectedFiles[0];
      console.error(rejectedFile);
      if (rejectedFile.size > maxBannerUploadSize) {
        addError('file.dropzone.error.size', {
          name: rejectedFile.name,
          maxSize: intl.formatNumber(maxBannerUploadSize / (1024 * 1024)),
        });
      } else {
        addError('file.dropzone.error.type', { name: rejectedFile.name });
      }
    }}
    {...otherProps}
  >
    {({ isDragActive, isDragReject }) => {
      return (
        <React.Fragment>
          {/* we dont't show the drag-reject red zone because it is not workin very well :
        it works only with MIME types and not with extensions, so sometimes it popups whereas it shouldn't
        (especially with fonts)
        https://react-dropzone.netlify.com/#accepting-specific-file-types */}
          {/* isDragReject && (
          <div className={classNames(classes.overlay, classes.reject)}>
            <FormattedMessage id={'file.dropzone.reject'} />
          </div>
        ) */}
          {isDragActive &&
            !isDragReject && (
              <div className={classNames(classes.overlay, classes.accept)}>
                <FormattedMessage id={'file.dropzone.accept'} />
              </div>
            )}
          {children}
        </React.Fragment>
      );
    }}
  </Dropzone>
);

const makeStateToProps = () => state => ({
  disabled: makeSelectorInstance(getBannerDisableDropZone)(state),
});

const dispatchToProps = {
  addError,
  openNewImageDialog,
  openOpenBannerSetDialogRequested,
  openUploadFontDialog,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  withStyles(styles),
  React.memo,
)(GlobalDropZone);
