import { transitionTypesVar } from '../../reference/transitions';

export const verifyTransitionType = ({
  bannerWidth,
  element,
  newType,
  setElementTransitionParameters,
  transitionName,
  minDuration,
}) => {
  if (newType === transitionTypesVar.none) {
    setElementTransitionParameters({
      id: element.id,
      transitionName,
      params: {
        duration: 0,
        type: newType,
      },
      untrack: false,
    });
  } else if (minDuration >= 1) {
    if (newType === transitionTypesVar.translation) {
      setElementTransitionParameters({
        id: element.id,
        transitionName,
        params: {
          duration: element[transitionName].duration === 0 ? 1 : element[transitionName].duration,
          type: newType,
          distance: bannerWidth,
          // direction: transitionName === 'transitionIn' ? 'fromLeft' : 'toRight',
        },
        untrack: false,
      });
    } else if (element.transitionOut.duration === 0) {
      setElementTransitionParameters({
        id: element.id,
        transitionName,
        params: {
          duration: 1,
          type: newType,
        },
        untrack: false,
      });
    } else {
      setElementTransitionParameters({
        id: element.id,
        transitionName,
        params: {
          type: newType,
        },
        untrack: false,
      });
    }
  } else {
    setElementTransitionParameters({
      id: element.id,
      transitionName,
      params: {
        type: newType,
        duration: minDuration,
        distance: bannerWidth,
        // direction: transitionName === 'transitionIn' ? 'fromLeft' : 'toRight',
      },
      untrack: false,
    });
  }
};
