import { calculateRootTransformedBoundingRect } from './';

export const checkInsideTheBounds = ({
  disableBounds,
  bounds,
  x,
  y,
  width,
  height,
  centerCoords,
  ALPHA,
  rootTransformedBoundingsOffset,
  zoom,
}) => {
  let xInsideBounds = x;
  let yInsideBounds = y;

  if (!bounds || disableBounds) {
    return {
      rootTransformedBoundingRect: null,
      xInsideBounds,
      yInsideBounds,
    };
  }

  const rootTransformedBoundingRect = calculateRootTransformedBoundingRect({
    x,
    y,
    ALPHA,
    width,
    height,
    zoom,
    centerCoords,
  });

  Object.keys(rootTransformedBoundingRect)
    .map(side => ({
      side,
      goingOutsideTheBounds:
        side === 'top' || side === 'left'
          ? rootTransformedBoundingRect[side] < bounds[side]
          : rootTransformedBoundingRect[side] > bounds[side],
    }))
    .filter(side => side.goingOutsideTheBounds)
    .map(sideProps => sideProps.side)
    .forEach(sideOutsideBounds => {
      if (sideOutsideBounds === 'top') {
        yInsideBounds = (bounds.top - rootTransformedBoundingsOffset.top) * zoom;
      }
      if (sideOutsideBounds === 'left') {
        xInsideBounds = (bounds.left - rootTransformedBoundingsOffset.left) * zoom;
      }
      if (sideOutsideBounds === 'bottom') {
        yInsideBounds = (bounds.bottom - rootTransformedBoundingsOffset.bottom - height / zoom) * zoom;
      }
      if (sideOutsideBounds === 'right') {
        xInsideBounds = (bounds.right - rootTransformedBoundingsOffset.right - width / zoom) * zoom;
      }
    });

  return {
    rootTransformedBoundingRect,
    xInsideBounds,
    yInsideBounds,
  };
};
