import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { withStyles, Select, MenuItem } from '@material-ui/core';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementBorderRadius, getSelectedElementBorderRadiusUnit } from '../../banner/bannerSelectors';
import { updateElement } from '../../banner/bannerActionsCreators';
import { minZeroInputProps } from '../constants';
import { styles } from '../propertyEditorStyles';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

const flexEnd = { display: 'flex', alignItems: 'flex-end' };

class ElementBorderRadius extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return {
      classesState: {
        menuItem: { selected: classes.menuSelected, root: classes.menuItem },
        labelShrinkXL: { classes: { shrink: classes.labelShrinkXL } },
        unitSelect: { select: classes.unitSelect },
      },
    };
  }

  onBorderRadiusChange = ({ value: borderRadius, id }) => this.props.updateElement({ borderRadius, id }); //tracked
  onBorderRadiusUnitChange = e => this.props.updateElement({ borderRadiusUnit: e.target.value, id: this.props.id });

  render() {
    const { id, borderRadiusUnit, borderRadius, intl } = this.props;
    const { classesState } = this.state;
    return (
      <div style={flexEnd}>
        <TextFieldDoped
          key={id}
          id={id}
          type="number"
          label={intl.formatMessage({ id: 'properties.rectangle.border.radius' })}
          value={borderRadius}
          fullWidth
          inputProps={minZeroInputProps}
          InputLabelProps={classesState.labelShrinkXL}
          onChangeMethod={this.onBorderRadiusChange}
        />
        <Select
          value={borderRadiusUnit || 'px'}
          onChange={this.onBorderRadiusUnitChange}
          classes={classesState.unitSelect}
        >
          <MenuItem value="px" classes={classesState.menuItem}>
            px
          </MenuItem>
          <MenuItem value="%" classes={classesState.menuItem}>
            %
          </MenuItem>
        </Select>
      </div>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  borderRadius: makeSelectorInstance(getSelectedElementBorderRadius)(state),
  borderRadiusUnit: makeSelectorInstance(getSelectedElementBorderRadiusUnit)(state),
});

const dispatchToProps = {
  updateElement,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(ElementBorderRadius);
