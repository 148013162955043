import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withStyles, FormControl, InputLabel } from '@material-ui/core';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementBorderColor } from '../../banner/bannerSelectors';
import { updateElement } from '../../banner/bannerActionsCreators';
import { styles } from '../propertyEditorStyles';
import RgbaColorPicker from '../../components/ColorPicker/RgbaColorPicker';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

class ElementBorderColor extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    return { shrink: nextProps.classes.labelShrink };
  }

  onBorderColorChange = borderColor => {
    const { id, updateElement } = this.props;
    updateElement({ id, borderColor });
  };

  render() {
    const { id, borderColor } = this.props;

    return (
      <FormControl fullWidth>
        <InputLabel shrink classes={this.state}>
          <FormattedMessage id="properties.rectangle.border.color" />
        </InputLabel>
        <RgbaColorPicker
          key={id}
          enableAlpha
          value={borderColor}
          initialColor={borderColor}
          onChange={this.onBorderColorChange}
        />
      </FormControl>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  borderColor: makeSelectorInstance(getSelectedElementBorderColor)(state),
});

const dispatchToProps = {
  updateElement,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  withStyles(styles),
)(ElementBorderColor);
