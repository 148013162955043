import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { Grid, Button, Tooltip, Tabs, Tab } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { transitionTypesVar } from '../reference/transitions';
import {
  getSelectedElementType,
  getSelectedElementTransitionInType,
  getSelectedElementTransitionOutType,
  getSelectedElementTransitionOutDuration,
  getSelectedElementTransitionInTotalDuration,
  getSelectedElementBlinkAnimationEnabled,
  getSelectedElementDropShadowEnabled,
  getSelectedElementShowOnAllSlides,
} from '../banner/bannerSelectors';
import { openDeleteElementDialog } from '../banner/bannerActionsCreators';

import ExpansionPanel from './ExpansionPanel';

import { styles, staticStyles } from './propertyEditorStyles';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import ElementFontFamilySelect from './properties-element/ElementFontFamilySelect';
import ElementPositionX from './properties-element/ElementPositionX';
import ElementPositionY from './properties-element/ElementPositionY';
import ElementRotation from './properties-element/ElementRotation';
import ElementWidth from './properties-element/ElementWidth';
import ElementHeight from './properties-element/ElementHeight';
import ElementLockAspectRatio from './properties-element/ElementLockAspectRatio';
import ElementDisposition from './properties-element/ElementDisposition';
import ElementText from './properties-element/ElementText';
import ElementFileName from './properties-element/ElementFileName';
import ElementSize from './properties-element/ElementSize';
import ElementResetDimensions from './properties-element/ElementResetDimensions';
import ElementShowOnAllSlides from './properties-element/ElementShowOnAllSlides';
import ElementOpacity from './properties-element/ElementOpacity';
import ElementDropShadowToggle from './properties-element/ElementDropShadowToggle';
import ElementDropShadowColor from './properties-element/ElementDropShadowColor';
import ElementDropShadowBlurRadius from './properties-element/ElementDropShadowBlurRadius';
import ElementDropShadowOffsetX from './properties-element/ElementDropShadowOffsetX';
import ElementDropShadowOffsetY from './properties-element/ElementDropShadowOffsetY';
import ElementFontSize from './properties-element/ElementFontSize';
import ElementAddFont from './properties-element/ElementAddFont';
import ElementFontStyle from './properties-element/ElementFontStyle';
import ElementFontColor from './properties-element/ElementFontColor';
import ElementFontAlignHorizontal from './properties-element/ElementFontAlignHorizontal';
import ElementBackgroundColor from './properties-element/ElementBackgroundColor';
import ElementBorderColor from './properties-element/ElementBorderColor';
import ElementBorderWidth from './properties-element/ElementBorderWidth';
import ElementBorderRadius from './properties-element/ElementBorderRadius';
import ElementAppearance from './properties-element/ElementAppearance';
import ElementFlip from './properties-element/ElementFlip';
import ElementCrop from './properties-element/ElementCrop';
import ElementDuration from './properties-element/ElementDuration';
import ElementTransitionPlay from './properties-element/ElementTransitionPlay';
import ElementBlinkToggle from './properties-element/ElementBlinkToggle';
import ElementBlinkOpacity from './properties-element/ElementBlinkOpacity';
import ElementBlinkFrequency from './properties-element/ElementBlinkFrequency';
import ElementTransitionInType from './properties-element/ElementTransitionInType';
import ElementTransitionOutType from './properties-element/ElementTransitionOutType';
import ElementTransitionInTranslationParameters from './properties-element/ElementTransitionInTranslationParameters';
import ElementTransitionOutTranslationParameters from './properties-element/ElementTransitionOutTranslationParameters';
import ElementTransitionInDuration from './properties-element/ElementTransitionInDuration';
import ElementTransitionOutDuration from './properties-element/ElementTransitionOutDuration';
import ElementTransitionInDelay from './properties-element/ElementTransitionInDelay';
import ElementTransitionShowOnAllSlides from './properties-element/ElementTransitionShowOnAllSlides';
import { getSelectedElementId } from '../temporary-status/temporaryStatusDucks';

// perfect scrollbar options
const perfectScrollbarOptions = {
  suppressScrollX: true,
  handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
  wheelSpeed: 0.8,
  wheelPropagation: true,
};

const switchGrid = type => (type === 'image' ? 3 : type === 'rectangle' ? 4 : 6);

class PropertyEditorElement extends React.PureComponent {
  state = {
    transitionTabIndex: 0,
    classesState: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { classes } = nextProps;
    return {
      ...prevState,
      classesState: {
        transitionPanel: { details: classes.transitionPanel },
        transitionAllSlides: { container: classes.transitionAllSlides },
        transitionTab: {
          root: classes.transitionTab,
          selected: classes.tabSelected,
        },
      },
    };
  }

  onTransitionTabChange = transitionTabIndex => {
    this.setState({ transitionTabIndex });
  };

  onTransitionTabClick = (e, v) => this.onTransitionTabChange(v);

  onDeleteClick = e => {
    const { openDeleteElementDialog, id, showOnAllSlides } = this.props;
    e.preventDefault(); // to prevent losing focus on text element
    openDeleteElementDialog(id, showOnAllSlides);
  };

  render() {
    const {
      classes,
      blinkAnimationEnabled,
      dropShadowEnabled,
      type,
      intl,
      transitionInType,
      transitionOutType,
      transitionInDuration,
      transitionOutDuration,
      showOnAllSlides,
    } = this.props;

    const { transitionTabIndex, classesState } = this.state;

    return (
      <PerfectScrollbar className={classes.scrollContainer} options={perfectScrollbarOptions}>
        {type === 'button' && (
          <ExpansionPanel title={intl.formatMessage({ id: 'properties.content' })}>
            <Grid container spacing={2}>
              <ElementText />
            </Grid>
          </ExpansionPanel>
        )}

        {type === 'image' && (
          <ExpansionPanel title={intl.formatMessage({ id: 'properties.content' })}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                {/* IMAGE CUSTO */}
                <ElementFileName />
              </Grid>
              <Grid item xs={4} style={staticStyles.textAlignRight}>
                <ElementSize />
              </Grid>
            </Grid>
          </ExpansionPanel>
        )}

        <ExpansionPanel title={intl.formatMessage({ id: `properties.${type}` })}>
          <Grid container spacing={2}>
            {(type === 'text' || type === 'button') && (
              <Fragment>
                <Grid item xs={8}>
                  <ElementFontFamilySelect />
                </Grid>
                <Grid item xs={4}>
                  <ElementFontSize />
                </Grid>
                <Grid item xs={12}>
                  <ElementAddFont />
                </Grid>
                <Grid item xs={8}>
                  <ElementFontStyle />
                </Grid>
                <Grid item xs={4}>
                  <ElementFontColor />
                </Grid>
              </Fragment>
            )}
            {type === 'text' && (
              <Fragment>
                <Grid item xs={12}>
                  <ElementFontAlignHorizontal />
                </Grid>
                {/* <Grid item xs={12}>
                  <ElementFontAlignVertical />
                </Grid> */}
              </Fragment>
            )}

            {type !== 'text' && (
              <Fragment>
                {type !== 'image' && (
                  <Grid item xs={6}>
                    <ElementBackgroundColor />
                  </Grid>
                )}
                <Grid item xs={type !== 'image' ? 6 : 12}>
                  <ElementBorderColor />
                </Grid>
                <Grid item xs={6}>
                  <ElementBorderWidth />
                </Grid>
                <Grid item xs={6}>
                  <ElementBorderRadius />
                </Grid>
              </Fragment>
            )}

            {type === 'button' && (
              <Grid item xs={12}>
                <ElementAppearance />
              </Grid>
            )}

            {type === 'image' && (
              <Fragment>
                <Grid item xs={5}>
                  <ElementFlip />
                </Grid>

                <Grid item xs={7}>
                  <ElementCrop />
                </Grid>
              </Fragment>
            )}
          </Grid>
        </ExpansionPanel>

        <ExpansionPanel title={intl.formatMessage({ id: 'properties.position-dimensions' })}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={10}>
              <ElementDisposition />
            </Grid>
            <Grid item xs={2} style={staticStyles.textAlignRight}>
              <Tooltip title={intl.formatMessage({ id: 'menu.buttons.delete' })}>
                <Button fullWidth className={classes.deleteButton} onClick={this.onDeleteClick}>
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <ElementPositionX />
            </Grid>
            <Grid item xs={4}>
              <ElementPositionY />
            </Grid>
            <Grid item xs={4}>
              <ElementRotation />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={switchGrid(type)}>
                  <ElementWidth />
                </Grid>
                {(type === 'image' || type === 'rectangle') && (
                  <Grid item xs={3}>
                    <ElementLockAspectRatio />
                  </Grid>
                )}
                <Grid item xs={switchGrid(type)}>
                  <ElementHeight />
                </Grid>
                {type === 'image' && (
                  <Grid item xs={3}>
                    <ElementResetDimensions />
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <ElementShowOnAllSlides />
            </Grid>
          </Grid>
        </ExpansionPanel>

        <ExpansionPanel title={intl.formatMessage({ id: 'properties.appearance' })}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ElementOpacity />
            </Grid>

            <Grid item xs={12}>
              <ElementDropShadowToggle />
            </Grid>
            {dropShadowEnabled && (
              <Fragment>
                <Grid item xs={6}>
                  <ElementDropShadowColor />
                </Grid>
                <Grid item xs={6}>
                  <ElementDropShadowBlurRadius />
                </Grid>
                <Grid item xs={6}>
                  <ElementDropShadowOffsetX />
                </Grid>
                <Grid item xs={6}>
                  <ElementDropShadowOffsetY />
                </Grid>
              </Fragment>
            )}
          </Grid>
        </ExpansionPanel>

        <ExpansionPanel title={intl.formatMessage({ id: 'properties.duration' })}>
          <Grid container spacing={2} alignItems="center" direction="row">
            <Grid item xs={6}>
              <ElementDuration />
            </Grid>
            <Grid item xs={6}>
              <ElementTransitionPlay />
            </Grid>
          </Grid>
        </ExpansionPanel>
        <ExpansionPanel title={intl.formatMessage({ id: 'properties.animation' })}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ElementBlinkToggle />
            </Grid>
            {blinkAnimationEnabled && (
              <Fragment>
                <Grid item xs={6}>
                  <ElementBlinkOpacity />
                </Grid>
                <Grid item xs={6}>
                  <ElementBlinkFrequency />
                </Grid>
              </Fragment>
            )}
          </Grid>
        </ExpansionPanel>
        <ExpansionPanel
          title={intl.formatMessage({ id: 'properties.transition' })}
          classes={classesState.transitionPanel}
        >
          {showOnAllSlides && (
            <Grid container spacing={2} classes={classesState.transitionAllSlides}>
              <Grid item xs={12}>
                <ElementTransitionShowOnAllSlides />
              </Grid>
            </Grid>
          )}
          <Tabs
            value={transitionTabIndex}
            onChange={this.onTransitionTabClick}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={
                transitionInDuration
                  ? intl.formatMessage({ id: 'properties.transition.in.details' }, { duration: transitionInDuration })
                  : intl.formatMessage({ id: 'properties.transition.in' })
              }
              classes={classesState.transitionTab}
            />
            <Tab
              label={
                transitionOutDuration
                  ? intl.formatMessage({ id: 'properties.transition.out.details' }, { duration: transitionOutDuration })
                  : intl.formatMessage({ id: 'properties.transition.out' })
              }
              classes={classesState.transitionTab}
            />
          </Tabs>
          <SwipeableViews index={transitionTabIndex} onChangeIndex={this.onTransitionTabChange}>
            <div className={classes.transitionContent}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ElementTransitionInType />
                </Grid>
                {transitionInType === transitionTypesVar.translation && (
                  <ElementTransitionInTranslationParameters transitionTabIndex={transitionTabIndex} />
                )}
                {transitionInType !== transitionTypesVar.none ? (
                  <Grid item xs={6}>
                    <ElementTransitionInDuration />
                  </Grid>
                ) : (
                  <Grid item xs={6} />
                )}
                <Grid item xs={6}>
                  <ElementTransitionInDelay />
                </Grid>
              </Grid>
            </div>
            <div className={classes.transitionContent}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ElementTransitionOutType />
                </Grid>
                {transitionOutType === transitionTypesVar.translation && (
                  <ElementTransitionOutTranslationParameters transitionTabIndex={transitionTabIndex} />
                )}
                {transitionOutType !== transitionTypesVar.none && (
                  <Fragment>
                    <Grid item xs={6}>
                      <ElementTransitionOutDuration />
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </div>
          </SwipeableViews>
        </ExpansionPanel>
      </PerfectScrollbar>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  showOnAllSlides: makeSelectorInstance(getSelectedElementShowOnAllSlides)(state),
  blinkAnimationEnabled: makeSelectorInstance(getSelectedElementBlinkAnimationEnabled)(state),
  dropShadowEnabled: makeSelectorInstance(getSelectedElementDropShadowEnabled)(state),
  transitionInType: makeSelectorInstance(getSelectedElementTransitionInType)(state),
  transitionOutType: makeSelectorInstance(getSelectedElementTransitionOutType)(state),
  transitionInDuration: makeSelectorInstance(getSelectedElementTransitionInTotalDuration)(state),
  transitionOutDuration: makeSelectorInstance(getSelectedElementTransitionOutDuration)(state),
  type: makeSelectorInstance(getSelectedElementType)(state),
});
const dispatchToProps = {
  openDeleteElementDialog,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(PropertyEditorElement);
