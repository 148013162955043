import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Editor, RichUtils } from 'draft-js';
import React from 'react';
import { connect } from 'react-redux';
import { compose, onlyUpdateForKeys } from 'recompose';
import { updateElement } from '../banner/bannerActionsCreators';
import {
  getBannerUniqueIdentification,
  getElementCustomValue,
  getStateWithPresentBanner,
} from '../banner/bannerSelectors';
import { getCustomizationIsEnabled } from '../entities/entitiesSelectors';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import {
  computeTextShadow,
  computeTextStyle,
  fontSizeProperty,
  textSelectedProperty,
} from '../reference/textProperties';
import { getTemporaryMenuOrModalOpen } from '../shared-selectors/sharedSelectors';
import {
  getSelectedElementStatus,
  updateBannerStatus,
  updateElementStatus,
} from '../temporary-status/temporaryStatusDucks';
import {
  getTemporaryFontSizeFocused,
  getTemporaryInlineStyle,
  getTemporaryTextEditorState,
  updateTemporaryTextEditor,
} from '../temporary-text-editor/temporaryTextEditorDucks';
import {
  cleanInlineStylesFromSelection,
  createHtmlTextElement,
  createRawContentStateFromText,
  getEditorStateFromRawContentState,
  getRawContentStateFromEditorState,
  getTextFromEditorState,
  hasNoSelection,
  removeFontInlineStyleFromSelection,
} from './textHelpers';

const styles = {
  editor: {
    width: '100%',
    cursor: 'text',
  },
  notSelected: {
    cursor: 'default',
  },
  deactivated: {
    pointerEvents: 'none',
    '& *': {
      pointerEvents: 'none',
    },
  },
  rawHtml: {
    '& *': {
      border: 'none',
      margin: 0,
      padding: 0,
      whiteSpace: 'pre-wrap', // to be the same as draft-js
    },
  },
};

class TextElement extends React.Component {
  state = {
    globalStyle: {},
    html: { _html: '' },
    styleMap: {},
    editorStateActivated: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      color,
      fontFamily,
      fontSizes,
      fontSize,
      contentState: rawContentState,
      text,
      horizontalAlign,
      dropShadow,
      dropShadowEnabled,
      opacity,
    } = nextProps;
    const styleMap = {};
    for (let fontSize of fontSizes) {
      styleMap[`${fontSizeProperty}-${fontSize}`] = {
        fontSize,
      };
    }
    styleMap[textSelectedProperty] = {
      backgroundColor: 'rgba(220,220,220,1.00)',
    };

    return {
      ...prevState,
      html: {
        __html: createHtmlTextElement(rawContentState, text).replace(/<p><\/p>\n/g, '<br />'),
      },
      globalStyle: {
        color,
        fontFamily,
        fontSize,
        opacity,
        textAlign: horizontalAlign,
        textShadow: dropShadowEnabled ? computeTextShadow(dropShadow) : '',
      },
      styleMap,
    };
  }

  componentDidMount() {
    this.textElementClicked = 0;
    if (this.props.selected) {
      this.activateEditorState();
      this.rehydrate();
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.selected && !this.props.selected) || (!prevProps.customizable && this.props.customizable)) {
      this.deactivateEditorState();
      this.save();
    }

    if (!this.props.selected) return;

    if (!prevProps.fontSizeInputFocused && this.props.fontSizeInputFocused) {
      this.toggleHighlightSelection();
      return;
    }
    if (prevProps.fontSizeInputFocused && !this.props.fontSizeInputFocused) {
      this.toggleHighlightSelection();
      return;
    }

    if (!prevProps.menuOrModalOpen && this.props.menuOrModalOpen) this.save();
    if (!prevProps.selected && this.props.selected) this.rehydrate();
    if (prevProps.menuOrModalOpen && !this.props.menuOrModalOpen) this.rehydrate();

    if (this.props.selected && !this.props.menuOrModalOpen && !this.props.fontSizeInputFocused) {
      this.checkIfNeedForRefocus();
      this.checkEditorHeight();
      this.checkCustomContainerHeight();
    }
    if (prevProps.inlineStyle.key !== this.props.inlineStyle.key) this.updateInlineStyles();

    const clickedOnFontStyleButtonWithNoFocus =
      prevProps.editorState &&
      hasNoSelection(prevProps.editorState) &&
      (this.props.editorState && !hasNoSelection(this.props.editorState)) &&
      !this.editorRef;

    if (clickedOnFontStyleButtonWithNoFocus) this.activateEditorState();
  }

  componentWillUnmount() {
    this.props.updateTemporaryTextEditor({ editorState: undefined });
  }

  // don't activate editor just when element is selected, because we want to be able
  // to deal with the element itself first, more thant the text content
  activateEditorState = () => {
    this.textElementClicked++;
    this.setState({ editorStateActivated: this.textElementClicked > 1 }, () => this.reFocus());
  };

  deactivateEditorState = () => {
    this.textElementClicked = 0;
    this.setState({ editorStateActivated: false });
  };

  /* when the component is focused, we render a temporary workingValue,
  stored in redux to be shared between this component and the properties editor */
  /* when the component is not focused, we render the html directly */

  checkEditorHeight = () => {
    if (!this.editorContainerRef) return;
    const { height, updateElement, id } = this.props;
    if (height !== this.editorContainerRef.scrollHeight) {
      updateElement({ height: this.editorContainerRef.scrollHeight, id });
    }
  };

  checkCustomContainerHeight = () => {
    if (!this.customContainer) return;
    const { height, updateElement, id } = this.props;
    if (height !== this.customContainer.parentElement.parentElement.scrollHeight) {
      updateElement({ height: this.customContainer.parentElement.parentElement.scrollHeight, id });
    }
  };

  checkIfNeedForRefocus = () => {
    const {
      editorState,
      selected,
      status: { textIsSelected, isDragging, isRotating, isResizing, elementIsPlayingAnimation },
      menuOrModalOpen,
    } = this.props;
    if (isDragging || isRotating || isResizing || elementIsPlayingAnimation || menuOrModalOpen) return; // let other actions do their job
    if (selected && editorState && !editorState.getSelection().getHasFocus() && this.editorRef) this.editorRef.focus(); // keep the focus
    if (selected && !textIsSelected) this.reFocus();
  };

  onChange = editorState => {
    const { updateTemporaryTextEditor, updateElement, id, customizable } = this.props;
    if (customizable) return;
    updateTemporaryTextEditor({ editorState });
    if (this.updateContentState) {
      this.updateContentState = false;
      updateElement({
        text: getTextFromEditorState(editorState),
        contentState: getRawContentStateFromEditorState(editorState),
        id,
      });
    }
  };

  save = (toggleTextSelected = true) => {
    const { updateTemporaryTextEditor, updateElement, editorState, id } = this.props;
    if (!editorState) return;
    updateTemporaryTextEditor({ editorState: undefined });
    // remove temp background-color if needed
    if (editorState.getCurrentInlineStyle().has(textSelectedProperty) && toggleTextSelected) {
      const cleanedEditorState = cleanInlineStylesFromSelection(editorState, [textSelectedProperty], true);
      updateElement({
        text: getTextFromEditorState(cleanedEditorState),
        contentState: getRawContentStateFromEditorState(cleanedEditorState),
        id,
      });
    } else {
      updateElement({
        text: getTextFromEditorState(editorState),
        contentState: getRawContentStateFromEditorState(editorState),
        id,
      });
    }
  };

  toggleHighlightSelection = () => {
    const { editorState, inlineStyle } = this.props;
    const { value } = inlineStyle;
    if (!editorState) return;
    // fontsize
    const addProperty = value;
    if (addProperty && !editorState.getCurrentInlineStyle().has(textSelectedProperty)) {
      this.canChangeFontSize = true;
      this.onChange(RichUtils.toggleInlineStyle(editorState, textSelectedProperty));
    }
    if (!addProperty && editorState.getCurrentInlineStyle().has(textSelectedProperty)) {
      this.canChangeFontSize = false;
      this.onChange(RichUtils.toggleInlineStyle(editorState, textSelectedProperty));
    }
  };

  updateInlineStyles = () => {
    const { editorState, inlineStyle } = this.props;
    const { property, value } = inlineStyle;
    if (!editorState) return;
    // fontsize
    if (property === fontSizeProperty && this.canChangeFontSize) {
      // remove all font inline style from characters
      this.updateContentState = true;
      const nextInlineStyle = `${property}-${value}`;

      this.onChange(
        RichUtils.toggleInlineStyle(removeFontInlineStyleFromSelection(this.props.editorState), nextInlineStyle),
      );

      // if there is already some inline style
      /* if (editorState.getCurrentInlineStyle().size > 0) {
        // check if one of the inline style is fontsize
        let fontSizeInlineStyle = undefined;
        for (let style of editorState.getCurrentInlineStyle()) {
          if (style.includes(fontSizeProperty)) fontSizeInlineStyle = style;
        }
        // if one of the inline style is fontsize, remove it to add the new one
        if (fontSizeInlineStyle) {
          const editorStateWithoutFontsizeStyle = RichUtils.toggleInlineStyle(editorState, fontSizeInlineStyle);
          this.onChange(RichUtils.toggleInlineStyle(editorStateWithoutFontsizeStyle, nextInlineStyle));
        } else {
          this.onChange(RichUtils.toggleInlineStyle(editorState, nextInlineStyle));
        }
      } else {
        this.onChange(RichUtils.toggleInlineStyle(editorState, nextInlineStyle));
      }
      */
    }
  };

  rehydrate = () => {
    const { updateTemporaryTextEditor, contentState, text } = this.props;
    const editorState = getEditorStateFromRawContentState(contentState || createRawContentStateFromText(text));
    updateTemporaryTextEditor({ editorState });
  };

  reFocus = () => {
    if (!this.state.editorStateActivated) return;
    const { updateElementStatus, updateBannerStatus, id } = this.props;
    updateElementStatus({ textIsSelected: true, id });
    updateBannerStatus({ textIsSelected: true });
  };

  onFocus = () => {
    const {
      updateElementStatus,
      updateBannerStatus,
      id,
      status: { textIsSelected },
    } = this.props;
    if (textIsSelected) return;
    updateElementStatus({ textIsSelected: true, id });
    updateBannerStatus({ textIsSelected: true });
  };

  onBlur = e => {
    if (this.props.menuOrModalOpen) return;
    e.preventDefault(); // it blurs too much, and make the text unselectd
    const {
      updateElementStatus,
      updateBannerStatus,
      id,
      status: { textIsSelected },
    } = this.props;
    if (!textIsSelected) return;
    updateElementStatus({ textIsSelected: false, id });
    updateBannerStatus({ textIsSelected: false });
  };

  handleKeyCommand = (command, editorState) => {
    if (this.props.menuOrModalOpen || this.props.fontSizeInputFocused) return 'not-handled';
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  render() {
    const {
      classes,
      horizontalAlign,
      status: { isRotating, elementIsPlayingAnimation },
      selected,
      forSnapshot,
      editorState,
      menuOrModalOpen,
      customizable,
      customValue,
      custoEnabled,
    } = this.props;

    const { styleMap, html, globalStyle, editorStateActivated } = this.state;

    const deactivate = isRotating || customizable;
    const notForWork =
      !editorStateActivated ||
      forSnapshot ||
      !selected ||
      !editorState ||
      menuOrModalOpen ||
      elementIsPlayingAnimation ||
      customizable;
    return (
      <div
        className={classNames(classes.editor, deactivate && classes.deactivated, !selected && classes.notSelected)}
        style={globalStyle}
      >
        {notForWork &&
          customizable &&
          custoEnabled && (
            <div
              className={classes.rawHtml}
              style={computeTextStyle(this.props)}
              ref={customContainer => (this.customContainer = customContainer)}
            >
              {customValue}
            </div>
          )}
        {notForWork &&
          !(customizable && custoEnabled) && (
            <div className={classes.rawHtml} dangerouslySetInnerHTML={html} onClick={this.activateEditorState} />
          )}
        {!notForWork && (
          <Editor
            ref={editorRef => {
              this.editorRef = editorRef;
              this.editorContainerRef = editorRef && editorRef.editorContainer.parentNode.parentNode.parentNode; // draft-js
            }}
            editorState={editorState}
            customStyleMap={styleMap}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            textAlignment={horizontalAlign}
            readOnly={deactivate}
          />
        )}
      </div>
    );
  }
}

const makeStateToProps = () => (state, { id, bannerUniqueId }) => {
  // FIXME: this is an Hack to simulate a current banner in state, because selectors are based on the current Banner
  const fakeState = bannerUniqueId ? getStateWithPresentBanner(state, bannerUniqueId) : state;
  return {
    editorState: makeSelectorInstance(getTemporaryTextEditorState)(fakeState),
    key: makeSelectorInstance(getBannerUniqueIdentification),
    menuOrModalOpen: makeSelectorInstance(getTemporaryMenuOrModalOpen)(fakeState),
    inlineStyle: makeSelectorInstance(getTemporaryInlineStyle)(fakeState),
    fontSizeInputFocused: makeSelectorInstance(getTemporaryFontSizeFocused)(fakeState),
    status: makeSelectorInstance(getSelectedElementStatus)(fakeState),
    customValue: makeSelectorInstance(getElementCustomValue(id))(fakeState),
    custoEnabled: makeSelectorInstance(getCustomizationIsEnabled)(fakeState),
  };
};

const dispatchToProps = {
  updateBannerStatus,
  updateElementStatus,
  updateTemporaryTextEditor,
  updateElement,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  onlyUpdateForKeys([
    'editorState',
    'contentState',
    'color',
    'fontFamily',
    'fontSize',
    'selected',
    'fontStyle',
    'menuOrModalOpen',
    'inlineStyle',
    'property',
    'customizable',
    'customValue',
    'fontSizeInputFocused',
  ]),
  withStyles(styles),
)(TextElement);
