import React from 'react';
import { connect } from 'react-redux';
import { withStyles, IconButton, Tooltip } from '@material-ui/core';
import { compose } from 'recompose';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import CameraEnhanceIcon from 'mdi-material-ui/CameraEnhance';
import { menuHeight, appBarHeight } from '../reference/dimensions';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getSelectedBannerIndex, isAppLoaded } from '../shared-selectors/sharedSelectors';
import { getBannerSetThumbnailResourceId } from '../banners/bannersSelectors';
import { getBannerThumbnailResourceId } from '../banner/bannerSelectors';
import { takeScreenshotForThumbnail } from '../banners/bannersActionsCreators';
import { getResourceUrl } from '../resources/resourcesDucks';
import { isRenderingCurrentSceneForSnapshot } from '../export/exportSelectors';
import { getSelectedSlideIndex } from '../temporary-status/temporaryStatusDucks';

const styles = {
  root: {
    position: 'relative',
    height: menuHeight,
    width: menuHeight,
    borderRadius: 10,
    flexShrink: 0,
    border: '1px solid black',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    overflow: 'hidden',
    '&:hover': {
      '& $clickForThumbnail': {
        opacity: 0.4,
      },
    },
  },
  bannerSet: {
    transform: `translateY(-${appBarHeight / 2}px)`,
  },
  banner: {
    transform: 'translateY(5px)',
  },
  clickForThumbnail: {
    position: 'absolute',
    height: '50%',
    top: '50%',
    width: '100%',
    backgroundColor: '#000',
    opacity: 0,
    fontSize: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 250ms ease-in-out',
  },
  icon: {
    color: '#fff',
  },
};

const BannerSetThumbnail = ({
  classes,
  thumbnailId,
  takeScreenshotForThumbnail,
  renderingCurrentSceneForSnapshot,
  thumbnailFor,
  bannerIndex,
  slideIndex,
  appLoaded,
}) => (
  <div
    className={classNames(
      classes.root,
      thumbnailFor === 'bannerSet' && classes.bannerSet,
      thumbnailFor === 'banner' && classes.banner,
    )}
    style={{
      backgroundImage: `url(${thumbnailId && appLoaded && getResourceUrl(thumbnailId, 'BannerSetThumbnail')})`,
    }}
  >
    <div
      style={{
        opacity: !thumbnailId && 0.4,
      }}
      className={classes.clickForThumbnail}
    >
      <Tooltip
        title={
          thumbnailFor === 'bannerSet' ? (
            <FormattedMessage id="properties.bannerSet.set-slide-thumbnail" />
          ) : (
            <FormattedMessage id="properties.banner.set-slide-thumbnail" />
          )
        }
      >
        <div>
          {/* need to wrap in div because material-ui warning:  you are providing a disabled `button` child to the Tooltip component */}
          <IconButton
            classes={{ root: classes.icon }}
            disabled={renderingCurrentSceneForSnapshot}
            onClick={() =>
              takeScreenshotForThumbnail({
                thumbnailFor,
                bannerIndex,
                slideIndex,
              })
            }
          >
            <CameraEnhanceIcon color="inherit" />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  </div>
);

const makeStateToProps = () => {
  const stateToProps = (state, { thumbnailFor }) => {
    const bannerSetThumbnailId = makeSelectorInstance(getBannerSetThumbnailResourceId)(state);
    const bannerThumbnailId = makeSelectorInstance(getBannerThumbnailResourceId)(state);
    const bannerIndex = makeSelectorInstance(getSelectedBannerIndex)(state);
    const slideIndex = makeSelectorInstance(getSelectedSlideIndex)(state);
    const appLoaded = makeSelectorInstance(isAppLoaded)(state);
    const renderingCurrentSceneForSnapshot = makeSelectorInstance(isRenderingCurrentSceneForSnapshot)(state);
    return {
      thumbnailId: thumbnailFor === 'bannerSet' ? bannerSetThumbnailId : bannerThumbnailId,
      renderingCurrentSceneForSnapshot,
      slideIndex,
      bannerIndex,
      appLoaded,
    };
  };
  return stateToProps;
};

const dispatchToProps = {
  takeScreenshotForThumbnail,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  withStyles(styles),
)(BannerSetThumbnail);
