import { corners } from '../../../reference/corners';

const calculateExtremeCursorCasesForMiddleCorner = ({
  widthTooLow,
  width,
  height,
  fixedCornerPositionRotated,
  ALPHA,
  movingCorner,
  minHeight,
  heightTooLowOnly,
  cursorPositionChecked,
}) => {
  if (widthTooLow) {
    switch (movingCorner) {
      case corners.middleRight: {
        cursorPositionChecked = {
          x: fixedCornerPositionRotated.x + height * Math.sin(ALPHA),
          y: fixedCornerPositionRotated.y + height * Math.cos(ALPHA),
        };
        break;
      }
      case corners.middleLeft: {
        cursorPositionChecked = {
          x: fixedCornerPositionRotated.x - height * Math.sin(ALPHA),
          y: fixedCornerPositionRotated.y - height * Math.cos(ALPHA),
        };
        break;
      }
      default:
        break;
    }
  }
  if (heightTooLowOnly) {
    const beta = Math.atan(minHeight / width);
    const newDiagonal = Math.sqrt(Math.pow(minHeight, 2) + Math.pow(width, 2));
    switch (movingCorner) {
      case corners.middleTop: {
        cursorPositionChecked = {
          x: fixedCornerPositionRotated.x - newDiagonal * Math.cos(ALPHA - beta),
          y: fixedCornerPositionRotated.y + newDiagonal * Math.sin(ALPHA - beta),
        };
        break;
      }
      case corners.middleBottom: {
        cursorPositionChecked = {
          x: fixedCornerPositionRotated.x + newDiagonal * Math.cos(ALPHA - beta),
          y: fixedCornerPositionRotated.y - newDiagonal * Math.sin(ALPHA - beta),
        };
        break;
      }
      default:
        break;
    }
  }

  return cursorPositionChecked;
};

const calculateExtremeCursorCasesForOtherCorners = ({
  omega,
  widthTooLow,
  cursorPosition,
  ALPHA,
  movingCorner,
  diagonal,
  minHeight,
  heightTooLowOnly,
  cursorPositionChecked,
}) => {
  if (widthTooLow) {
    const projection = Math.sin(Math.PI / 2 - omega) * diagonal;
    cursorPositionChecked = {
      x: cursorPosition.x - projection * Math.cos(ALPHA),
      y: cursorPosition.y + projection * Math.sin(ALPHA),
    };
  }
  if (heightTooLowOnly) {
    let projection;
    switch (movingCorner) {
      default: {
        projection = Math.sin(-omega) * diagonal + minHeight;
        break;
      }
      case corners.bottomLeft:
      case corners.bottomRight: {
        projection = Math.sin(-omega) * diagonal - minHeight;
        break;
      }
    }
    cursorPositionChecked = {
      x: cursorPosition.x - projection * Math.cos(Math.PI / 2 - ALPHA),
      y: cursorPosition.y - projection * Math.sin(Math.PI / 2 - ALPHA),
    };
  }

  return cursorPositionChecked;
};

export const calculateExtremeCursorCases = params => {
  const { widthTooLow, heightTooLow, cursorPosition, widthShouldBeConstant, heightShouldBeConstant } = params;
  const cornerIsOneOfMiddleCorner = widthShouldBeConstant || heightShouldBeConstant;
  /*calculate the updated cursor position:
  the idea is that if the cursor falls outside of the element,
  the updated cursor will be the orthogonal projection of the real cursor on the side of the element*/
  let cursorPositionChecked = cursorPosition;
  const heightTooLowOnly = !widthTooLow && heightTooLow;

  if (cornerIsOneOfMiddleCorner) {
    cursorPositionChecked = calculateExtremeCursorCasesForMiddleCorner({
      ...params,
      heightTooLowOnly,
      cursorPositionChecked,
    });
  }
  if (!cornerIsOneOfMiddleCorner) {
    cursorPositionChecked = calculateExtremeCursorCasesForOtherCorners({
      ...params,
      heightTooLowOnly,
      cursorPositionChecked,
    });
  }

  return {
    cursorPositionChecked,
  };
};
