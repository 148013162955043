export const setBlinkAnimation = ({ preview, previewDuration = 3 }) => (
  _,
  { blinkAnimation: { frequency, minOpacity }, blinkAnimationEnabled },
) => {
  return blinkAnimationEnabled
    ? {
        blinkAnimation: {
          animationName: 'blink',
          animationDelay: 0,
          animationDuration: `${1 / frequency}s`,
          animationIterationCount: preview ? previewDuration * frequency : 'infinite',
          animationTimingFunction: 'linear',
          animationFillMode: 'none',
          animationDirection: 'normal',
          '--minOpacity': minOpacity,
        },
      }
    : { blinkAnimation: undefined };
};
