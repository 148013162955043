import { addLocaleData } from 'react-intl';
import locale_en from 'react-intl/locale-data/en';
import locale_fr from 'react-intl/locale-data/fr';
import locale_ja from 'react-intl/locale-data/ja';
import locale_de from 'react-intl/locale-data/de';
import locale_es from 'react-intl/locale-data/es';
import locale_it from 'react-intl/locale-data/it';
import locale_ko from 'react-intl/locale-data/ko';

addLocaleData([...locale_en, ...locale_fr, ...locale_ja, ...locale_de, ...locale_es, ...locale_it, ...locale_ko]);

const navigatorLanguage = navigator.language.split(/[-_]/)[0]; // language without region code
export const availableLocales = ['en', 'fr', 'ja', 'de', 'es', 'it', 'ko'];
export const locale = availableLocales.includes(navigatorLanguage) ? navigatorLanguage : 'en';

// locize Data
const projectId = '7d7469be-2494-4178-8785-1c7a455f30d2';
const version = 'latest';
const namespace = 'ADBUILDER';

export const locize = lang => `https://api.locize.io/${projectId}/${version}/${lang}/${namespace}`;
