import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';

import intlPropType from '../prop-types/intl';
import Entities from './Entities';
import {
  moveOrSaveAsDialogIsOpen,
  moveOrSaveAsDialogkey,
  getSelectedEntityIdForSave,
  moveOrSaveAsDialogIsOpenFor,
  isPermissionToggled as selectIsPermissionToggled,
  getEntities,
} from './entitiesSelectors';
import {
  closemoveOrSaveAsDialog,
  toggleEntityPermission,
  validateMoveOrSaveAsRequested,
  valideEntitiesPermissionsRequested,
  validateMoveRequested,
} from './entitiesActionCreators';
import { getBannerSetName, getBannerSetOwnerEntityId, getBannerSetPermissions } from '../banners/bannersSelectors';
import { doesContainEuroSymbol } from '../components/forms/rules/doesContainEuroSymbol';
import { compose } from 'recompose';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import usePrevious from '../hooks/usePrevious';

const padding = 24;

const useStyles = makeStyles(() => ({
  root: {
    padding,
    boxSizing: 'border-box',
  },
  dialogPaper: {
    maxWidth: 1100 - 2 * padding,
    width: '100%',
  },
  rootEntities: {
    // 36px + 16px: DialogActions, 76px: DialogTitle, 96px: margin top and down of dialog
    height: 'calc(100vh - 36px - 16px - 76px - 96px - 24px)',
    width: '100%',
    // width: 1100 - 2 * padding,
    '& .rst__tree': {
      height: 'calc(100% - 104px) !important', // height of text field bannerSetName
    },
  },
  bannerSetName: {
    marginBottom: 24,
  },
  saveAsNameField: {
    marginBottom: 12,
  },
}));

/**
 * Return errors when the form is not valid
 * @param {string | undefined} bannerSetName
 * @param {object} intl
 * @returns {string | undefined} The form error
 */
const validate = (bannerSetNameValue, intl) => {
  if (doesContainEuroSymbol(bannerSetNameValue)) {
    return intl.formatMessage({ id: 'bannerSetCreate.dialog.bannerSetName.error' });
  }

  return undefined;
};

const EntitiesDialog = ({
  intl,
  closemoveOrSaveAsDialogAction,
  isOpen,
  dialogKey,
  dialogOpenFor,
  selectedEntityId,
  isPermissionToggled,
  bannerSetName,
  bannerSetPermissions,
  bannerSetOwnerEntityId,
  entities,
  toggleEntityPermissionAction,
  valideEntitiesPermissionsRequestedAction,
  validateMoveRequestedAction,
  validateMoveOrSaveAsRequestedAction,
}) => {
  /* Vars */
  const prevBannerSetName = usePrevious(bannerSetName);
  const [bannerSetNameValue, changeBannerSetNameValue] = useState(bannerSetName);
  const [bannerSetNameError, changeBannerSetNameError] = useState(undefined);
  const classes = useStyles();
  const bannerSetOwnerEntity = entities && entities.find(entity => entity.id === bannerSetOwnerEntityId);
  const isBannerShared = bannerSetPermissions.find(p => p.includes('members'));

  /**
   * As EntitiesDialog is never rerendered when dialogIsClose, when initializing component
   * bannerSetName from props is undefined, so we must watch for bannerSetName update
   */
  useEffect(() => {
    if (prevBannerSetName !== bannerSetName) {
      changeBannerSetNameValue(bannerSetName);
    }
  }, [bannerSetName, prevBannerSetName]);

  const handleConfirmButtonClick = useCallback(() => {
    switch (dialogOpenFor) {
      case 'share': {
        toggleEntityPermissionAction(!isBannerShared);
        valideEntitiesPermissionsRequestedAction(!isBannerShared);
        break;
      }
      case 'move': {
        validateMoveRequestedAction();
        break;
      }
      case 'save-as':
      default: {
        const error = validate(bannerSetNameValue, intl);
        if (error) {
          changeBannerSetNameError(error);
        } else {
          validateMoveOrSaveAsRequestedAction(bannerSetNameValue);
        }
        break;
      }
    }
  }, [
    bannerSetNameValue,
    intl,
    validateMoveOrSaveAsRequestedAction,
    toggleEntityPermissionAction,
    valideEntitiesPermissionsRequestedAction,
    dialogOpenFor,
    validateMoveRequestedAction,
    isBannerShared,
  ]);

  const handleBannerSetNameChange = useCallback(
    evt => {
      const { value } = evt.target;
      const error = validate(value, intl);

      changeBannerSetNameError(error);
      changeBannerSetNameValue(value);
    },
    [intl],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={closemoveOrSaveAsDialogAction}
      maxWidth={false}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>
        <FormattedMessage id={`banners.entities.${dialogOpenFor}`} />
      </DialogTitle>
      <DialogContent
        className={classNames(classes.root, {
          [classes.rootEntities]: dialogOpenFor !== 'share' || (dialogOpenFor === 'share' && !isBannerShared),
        })}
      >
        {dialogOpenFor === 'save-as' && (
          <Grid container spacing={4} justify="center">
            <Grid item xs={4}>
              <TextField
                name="bannerSet-name"
                className={classes.saveAsNameField}
                id="bannerSet-name"
                type="text"
                label={intl.formatMessage({ id: 'bannerSetCreate.dialog.bannerSetName' })}
                fullWidth
                onChange={handleBannerSetNameChange}
                value={bannerSetNameValue}
                helperText={bannerSetNameError}
                error={Boolean(bannerSetNameError)}
              />
            </Grid>
          </Grid>
        )}
        {dialogOpenFor === 'share' && (
          <>
            <Typography align="center" gutterBottom>
              {isBannerShared && (
                <>
                  <FormattedMessage id="banner.share.is-saved-in" />
                  <Typography component="span" variant="body2">{` ${
                    bannerSetOwnerEntity && bannerSetOwnerEntity.name
                  }`}</Typography>
                  <br />
                </>
              )}
              <FormattedMessage id={isBannerShared ? 'banner.unshare.message' : 'banner.share.message'} />
            </Typography>
          </>
        )}
        {(dialogOpenFor !== 'share' || (dialogOpenFor === 'share' && !isBannerShared)) && <Entities key={dialogKey} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={closemoveOrSaveAsDialogAction}>
          <FormattedMessage id="alert.cancel" />
        </Button>
        <Button
          type="button"
          color="primary"
          onClick={handleConfirmButtonClick}
          disabled={
            isPermissionToggled ||
            (dialogOpenFor !== 'share' && !selectedEntityId) ||
            (dialogOpenFor === 'share' && !isBannerShared && !selectedEntityId)
          }
        >
          {dialogOpenFor === 'share' ? (
            <FormattedMessage id={isBannerShared ? 'banner.unshare.action' : 'banner.share.action'} />
          ) : (
            <FormattedMessage id={`banners.entities.${dialogOpenFor}-here`} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EntitiesDialog.propTypes = {
  intl: intlPropType,
  // makeStateToProps
  isOpen: PropTypes.bool.isRequired,
  dialogKey: PropTypes.number.isRequired,
  dialogOpenFor: PropTypes.string.isRequired,
  selectedEntityId: PropTypes.string.isRequired,
  isPermissionToggled: PropTypes.bool.isRequired,
  bannerSetName: PropTypes.string.isRequired,
  bannerSetPermissions: PropTypes.array.isRequired,
  bannerSetOwnerEntityId: PropTypes.string.isRequired,
  entities: PropTypes.array.isRequired,
  // dispatchToProps
  closemoveOrSaveAsDialogAction: PropTypes.func.isRequired,
  toggleEntityPermissionAction: PropTypes.func.isRequired,
  valideEntitiesPermissionsRequestedAction: PropTypes.func.isRequired,
  validateMoveRequestedAction: PropTypes.func.isRequired,
  validateMoveOrSaveAsRequestedAction: PropTypes.func.isRequired,
};

const makeStateToProps = () => state => ({
  isOpen: makeSelectorInstance(moveOrSaveAsDialogIsOpen)(state),
  dialogKey: makeSelectorInstance(moveOrSaveAsDialogkey)(state),
  dialogOpenFor: makeSelectorInstance(moveOrSaveAsDialogIsOpenFor)(state),
  selectedEntityId: makeSelectorInstance(getSelectedEntityIdForSave)(state),
  isPermissionToggled: makeSelectorInstance(selectIsPermissionToggled)(state),
  bannerSetName: makeSelectorInstance(getBannerSetName)(state),
  bannerSetPermissions: makeSelectorInstance(getBannerSetPermissions)(state),
  bannerSetOwnerEntityId: makeSelectorInstance(getBannerSetOwnerEntityId)(state),
  entities: makeSelectorInstance(getEntities)(state),
});

const dispatchToProps = {
  closemoveOrSaveAsDialogAction: closemoveOrSaveAsDialog,
  toggleEntityPermissionAction: toggleEntityPermission,
  valideEntitiesPermissionsRequestedAction: valideEntitiesPermissionsRequested,
  validateMoveRequestedAction: validateMoveRequested,
  validateMoveOrSaveAsRequestedAction: validateMoveOrSaveAsRequested,
};

export default compose(connect(makeStateToProps, dispatchToProps), injectIntl)(EntitiesDialog);
