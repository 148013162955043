import { corners } from '../../../reference/corners';

export const calculateLockAspectRatioDiagonal = ({ minCursorPosition, phi, ALPHA, movingCorner }) => {
  switch (movingCorner) {
    case corners.bottomLeft:
    case corners.topRight: {
      return {
        top: minCursorPosition.y,
        left: minCursorPosition.x - 1000,
        width: 2000,
        height: 0,
        transform: `rotate(${-(ALPHA + phi)}rad)`, //trigo is anti-clockwise, CSS is clockwise
      };
    }
    case corners.bottomRight:
    case corners.topLeft: {
      return {
        top: minCursorPosition.y,
        left: minCursorPosition.x - 1000,
        width: 2000,
        height: 0,
        transform: `rotate(${-(ALPHA - phi)}rad)`, //trigo is anti-clockwise, CSS is clockwise
      };
    }
    default:
      return null;
  }
};
