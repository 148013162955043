import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Element from '../element/Element';
import keyboardShortcuts from '../reference/keyboardShortcuts';
import autoHideDurations from '../reference/messagesDurations';
import { elementListWidth, propertyEditorWidth, slidesPanelWidth, editorOverhead } from '../reference/dimensions';
import { sceneBgColor, bannerOverlayShadowColor } from '../reference/colors';
import { acceptedFileTypes, maxUploadSize } from '../reference/importParams';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import {
  setSlideAnimationProperties,
  setSlideSnapshotsAnimationProperties,
  updateSlideSnapshotsFrame,
  updateSlideGIFAnimation,
} from './helpers';
import {
  getSelectedBannerIndex,
  getBannerDisableDropZone,
  getDisableShortcuts,
  checkIfNoBanners,
  getBanners,
  getBannerFormat,
  getTemporaryMenuOrModalOpen,
} from '../shared-selectors/sharedSelectors';
import {
  getBannerDuration,
  getElementsOfSlide,
  getBannerZoom,
  getBannerBgColor,
  getBannerGuidesForElement,
  getSelectedElements,
} from '../banner/bannerSelectors';
import {
  openDeleteElementsDialog,
  addImageElementFromComputer,
  addElementRequested,
} from '../banner/bannerActionsCreators';
import { getSlideElements, getBannerSetFPS, isCurrentBannerSlaveBanner } from '../banners/bannersSelectors';
import { duplicateElementRequested } from '../banners/bannersActionsCreators';
import { bannerAnimationExport } from '../export/exportDucks';
import {
  unselectElement,
  unselectMultipleElements,
  updateBannerAnimationStatus,
  selectMultipleElements,
  getSelectedElementId,
  getSelectedElementIds,
  isBannerPlayingHTMLAnimation,
  isBannerPlayingGIFAnimation,
  getBannerIsCropping,
  getBannerTextIsSelected,
  getBannerCurrentFrame,
  getBannerShowAnimationSnapshot,
  getSlideIsPlayingAnimation,
} from '../temporary-status/temporaryStatusDucks';
import { addError, addInfo } from '../messages/messagesDucks';
import Guide from '../guides/Guide';
import { isElementListOpen, isSlidesListOpen, isPropertyEditorOpen } from '../layout/layoutDucks';
import { checkKeyDiff } from '../components/helpers';
import { someElementsHasPropertyChecked } from '../property-editor/helpers';

const styles = theme => ({
  root: {
    backgroundColor: sceneBgColor,
    flex: 1,
    // overflow: 'auto',
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    boxSizing: 'border-box',
    marginLeft: `${slidesPanelWidth + elementListWidth}px`,
    marginRight: `${propertyEditorWidth}px`,
    transition: `${theme.transitions.create(['margin-left', 'margin-right'], {
      duration: theme.transitions.duration.standard,
    })} !important`,
  },
  animatedScene: {
    // overflow: 'hidden',
    marginRight: '0px',
    marginLeft: '0px',
  },
  supressScroll: {
    '& .ps__rail-x': {
      display: 'none',
    },
    '& .ps__rail-y': {
      display: 'none',
    },
  },
  bannerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  banner: {
    position: 'relative',
    pointerEvents: 'none',
    // zIndex: 1,
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
    // zIndex: 1,
  },
  bannerOverlayBanner: {
    boxShadow: `${theme.shadows[2]}, 0 0 0 3000px ${bannerOverlayShadowColor}`,
    // transition: theme.transitions.create(['box-shadow'], {
    //   duration: theme.transitions.duration.long,
    // }),
  },
  bannerOverlayBannerWhileTransition: {
    boxShadow: `${theme.shadows[2]}, 0 0 0 3000px rgba(255, 255, 255, 1)`,
  },
  selection: {
    // border: '1px dashed black', //FIXME not done yet
    position: 'absolute',
  },
});

class Scene extends React.Component {
  state = {
    copiedElementId: {},
    animationProperties: null,
    guidesToShow: [],
    disableGuides: false,
    stylesState: {},
    classesState: {},
    perfectScrollbarOptions: {},
    bounds: {},
    width: null,
    height: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    /*end of slideshow*/
    const { slideIsPlayingAnimation, showAnimationSnapshot, bannerIsPlayingGIFAnimation } = nextProps;
    const { animationProperties: prevAnimationProperties } = prevState;

    /* stop animation */
    let animationProperties = prevAnimationProperties;
    if (
      !showAnimationSnapshot &&
      !slideIsPlayingAnimation &&
      !bannerIsPlayingGIFAnimation &&
      prevAnimationProperties &&
      prevAnimationProperties.animationName
    ) {
      animationProperties = null;
    }
    /* rendering image */
    /* if (showAnimationSnapshot && animationProperties === null) {
      return {
        ...prevState,
        ...setSlideSnapshotsAnimationProperties(framesPerSecond, currentFrame)(prevState, nextProps),
      };
    }
 */
    /*start of slideshow*/
    if (slideIsPlayingAnimation && (!prevAnimationProperties || !prevAnimationProperties.animationName)) {
      animationProperties = setSlideAnimationProperties(prevState, nextProps).animationProperties;
    }
    /* styles and classes in state to prevent object literals in render and to prevent re-render */
    const {
      classes,
      bannerIsPlayingHTMLAnimation,
      noBanners,
      slidesOpen,
      propertyEditorOpen,
      format,
      backgroundColorForExport,
      backgroundColor: backgroundColorProps,
      zoom,
      bannerUniqueId,
    } = nextProps;
    const bannerIsPlayingAnimation = bannerIsPlayingHTMLAnimation || bannerIsPlayingGIFAnimation;
    const supressScroll = bannerIsPlayingAnimation;
    const { bannerWidth: width, bannerHeight: height } = format;
    const backgroundColor = backgroundColorForExport ? backgroundColorForExport : backgroundColorProps;
    const minimalScene = showAnimationSnapshot || slideIsPlayingAnimation || bannerIsPlayingAnimation;

    const stylesState = {
      perfectScrollbarStyle: {
        ...animationProperties,
        opacity: noBanners && 0,
        marginLeft: slidesOpen ? `${slidesPanelWidth + elementListWidth}px` : '0px',
        marginRight: propertyEditorOpen ? `${propertyEditorWidth}px` : '0px',
      },
      bannerContainerStyle: {
        minWidth: width + editorOverhead * 2,
        minHeight: height + editorOverhead * 2,
      },
      bannerStyle: {
        width,
        height,
        backgroundColor,
        transform: `scale(${bannerUniqueId ? 1 : zoom})`,
      },
      bannerOverlayStyle: {
        width,
        height,
        transform: `scale(${bannerUniqueId ? 1 : zoom})`,
      },
    };

    const classesState = {
      perfectScrollbarClasses: classNames('scene-container', classes.root, {
        [classes.supressScroll]: supressScroll,
        [classes.animatedScene]: bannerIsPlayingAnimation,
      }),
      bannerClasses: classNames(classes.banner, {
        [classes.animatedScene]: Boolean(bannerUniqueId),
      }),
      bannerOverlayInside: classNames(classes.bannerOverlayBanner, {
        [classes.bannerOverlayBannerWhileTransition]: minimalScene,
      }),
    };

    const perfectScrollbarOptions = {
      handlers: supressScroll ? [] : ['click-rail', 'drag-thumb', 'wheel', 'touch'],
      wheelSpeed: 0.8,
      wheelPropagation: false,
    };

    const bounds = {
      top: -editorOverhead,
      left: -editorOverhead,
      right: width + editorOverhead,
      bottom: height + editorOverhead,
    };

    return {
      ...prevState,
      animationProperties,
      stylesState,
      classesState,
      perfectScrollbarOptions,
      bounds,
      width,
      height,
    };
  }

  componentDidMount() {
    this.centerView('componentDidMount');
    /*
    ABOUT ANIMATION
    */
    const { currentFrame, framesPerSecond, showAnimationSnapshot, bannerIsPlayingGIFAnimation } = this.props;

    /* gif animation */
    if (bannerIsPlayingGIFAnimation) {
      this.currentFrame = 0;
      this.setState(setSlideSnapshotsAnimationProperties(framesPerSecond, this.currentFrame, 'playGIFAnimation'));
      this.playGIFAnimation();
      return;
    }

    /* static */
    if (showAnimationSnapshot) {
      this.setState(setSlideSnapshotsAnimationProperties(framesPerSecond, currentFrame, 'componentDidMount'));
    }

    /*for guides disabling / enabling*/
    window.addEventListener('keydown', this.handleShortcuts);
    window.addEventListener('keyup', this.handleShortcuts);

    /*copy and paste listener*/
    window.addEventListener('cut', this.handleCut);
    window.addEventListener('copy', this.handleCopy);
    window.addEventListener('paste', this.handlePaste);
    this.canvas.addEventListener('contextmenu', this.onContextMenuRequired);
    /* animatino end event listener */
    this.rootContainer.addEventListener('animationend', this.handleEndAnimation);
    /* scroll event listener */
    this.rootContainer.addEventListener('ps-scroll-x', this.saveScrollPosion);
    this.rootContainer.addEventListener('ps-scroll-y', this.saveScrollPosion);
  }

  shouldComponentUpdate(nextProps) {
    if (checkKeyDiff(nextProps, this.props) && checkKeyDiff(nextProps, this.props) === 'selectedElementId, guides') {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    /*
    CENTER VIEW
    */
    const {
      format: { bannerWidth: prevWidth, bannerHeight: prevHeight },
      bannerIsCropping: bannerWasCropping,
    } = prevProps;
    const {
      format: { bannerWidth: width, bannerHeight: height },
      bannerIsCropping,
    } = this.props;

    if (width !== prevWidth || height !== prevHeight) {
      this.centerView('componentDidUpdate');
    }

    if (!bannerWasCropping && bannerIsCropping) {
      this.keepScroll();
    }
    if (bannerWasCropping && !bannerIsCropping) {
      this.keepScroll();
      /* scroll event listener */
      this.rootContainer.addEventListener('ps-scroll-x', this.saveScrollPosion);
      this.rootContainer.addEventListener('ps-scroll-y', this.saveScrollPosion);
      return;
    }
    /*
    KEEP SCROLL POSITION
    */
    const toggleSlideOpen = !prevProps.slidesOpen && this.props.slidesOpen;
    const toggleSlideClose = prevProps.slidesOpen && !this.props.slidesOpen;
    const togglePropertiesOpen = !prevProps.propertyEditorOpen && this.props.propertyEditorOpen;
    const togglePropertiesClose = prevProps.propertyEditorOpen && !this.props.propertyEditorOpen;
    if (toggleSlideOpen) {
      this.rootContainer.scrollLeft = this.scroll.left + slidesPanelWidth + elementListWidth;
    }
    if (togglePropertiesOpen || togglePropertiesClose) {
      this.rootContainer.scrollLeft = this.scroll.left;
    }

    if (toggleSlideOpen || toggleSlideClose || togglePropertiesOpen || togglePropertiesClose) {
      this._scrollBarRef.updateScroll();
      this.rootContainer.scrollTop = this.scroll.top;

      /* scroll event listener */
      this.rootContainer.addEventListener('ps-scroll-x', this.saveScrollPosion);
      this.rootContainer.addEventListener('ps-scroll-y', this.saveScrollPosion);
    }

    /*
    ABOUT ANIMATION
    */
    const {
      currentFrame,
      framesPerSecond,
      showAnimationSnapshot,
      slidesForAnimation,
      bannerIsPlayingGIFAnimation,
    } = this.props;

    /* static */
    if (showAnimationSnapshot && prevProps.currentFrame !== currentFrame) {
      this.setState(updateSlideSnapshotsFrame(framesPerSecond, currentFrame));
    }
    /* update transition if transition between slides changed */
    if (showAnimationSnapshot && JSON.stringify(slidesForAnimation) !== JSON.stringify(prevProps.slidesForAnimation)) {
      this.setState(setSlideSnapshotsAnimationProperties(framesPerSecond, currentFrame, 'componentDidUpdate'));
    }
    if (!showAnimationSnapshot && prevProps.showAnimationSnapshot) {
      this.setState({ animationProperties: null });
    }

    /* reset this.currentFrame if we stop the GIF animation */
    if (!bannerIsPlayingGIFAnimation && prevProps.bannerIsPlayingGIFAnimation) {
      this.currentFrame = null;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleShortcuts);
    window.removeEventListener('keyup', this.handleShortcuts);
    window.clearInterval(this.animInterval);
    window.removeEventListener('cut', this.handleCut);
    window.removeEventListener('copy', this.handleCopy);
    window.removeEventListener('paste', this.handlePaste);
    this.rootContainer.removeEventListener('animationend', this.handleEndAnimation);
    this.rootContainer.removeEventListener('ps-scroll-x', this.saveScrollPosion);
    this.rootContainer.removeEventListener('ps-scroll-y', this.saveScrollPosion);
  }

  saveScrollPosion = from => {
    if (this.props.bannerIsCropping) return; // dont save the scroll positio whil cropping
    this.scroll = {
      left: this.rootContainer.scrollLeft,
      top: this.rootContainer.scrollTop,
    };
  };

  centerView = from => {
    const {
      format: { bannerWidth: width, bannerHeight: height },
    } = this.props;
    this.rootContainer.scrollLeft = (width + editorOverhead * 2 - this.rootContainer.clientWidth) / 2;
    this.rootContainer.scrollTop = (height + editorOverhead * 2 - this.rootContainer.clientHeight) / 2;
    this.scroll = {
      left: this.rootContainer.scrollLeft,
      top: this.rootContainer.scrollTop,
    };
  };

  keepScroll = () => {
    this.rootContainer.scrollLeft = this.scroll.left;
    this.rootContainer.scrollTop = this.scroll.top;
  };

  handleShortcuts = e => {
    const {
      disableShortcuts,
      selectedElementIds,
      textIsSelected,
      preventDeleteElement,
      selectedElements,
      openDeleteElementsDialog,
    } = this.props;
    /* toggle guides */
    if (disableShortcuts) {
      return;
    }
    if (keyboardShortcuts.disableGuides.includes(e.key)) {
      if (e.type === 'keydown') {
        this.setState({ disableGuides: true });
      } else {
        this.setState({ disableGuides: false });
      }
    }
    if (
      keyboardShortcuts.deleteElementRequested.includes(e.key) &&
      selectedElementIds.size > 0 &&
      !textIsSelected &&
      !preventDeleteElement
    ) {
      e.preventDefault();
      openDeleteElementsDialog(selectedElementIds, someElementsHasPropertyChecked(selectedElements, 'showOnAllSlides'));
    }
  };

  handleCut = e => {
    const {
      selectedElementId,
      selectedElementIds,
      addError,
      addInfo,
      slideIndex,
      bannerIndex,
      isSlaveBanner,
      elements,
      textIsSelected,
    } = this.props;
    if (isSlaveBanner) {
      addError('app.elements.cut.error.slaveBanner');
      return;
    }
    if (e.clipboardData) {
      if (selectedElementIds.size > 1) {
        addError('app.elements.copy.error.number');
        return;
      }
      if (selectedElementId && !textIsSelected) {
        const data = JSON.stringify({
          operation: 'cut',
          id: selectedElementId,
          // pass the element when cutting,
          // because it will be deleted after the first paste
          // and we still need it for more pastes afterwards
          element: elements.find(({ id }) => id === selectedElementId).toJS(),
          deleteOriginalElement: true, // delete the original element on the first paste
          oldSlideIndex: slideIndex,
          oldBannerIndex: bannerIndex,
        });
        addInfo('app.elements.cut.success', {
          autoHideDuration: autoHideDurations.shortMessage,
        });
        window.copiedData = data;
        e.preventDefault();
      }
    }
  };

  onContextMenuRequired = e => {
    console.log('onContextMenuRequired');
    // TODO : open menu to copy / paste element
  };

  handleCopy = e => {
    const {
      addError,
      selectedElementId,
      selectedElementIds,
      textIsSelected,
      addInfo,
      slideIndex,
      bannerIndex,
      menuOrModalOpen,
    } = this.props;
    if (menuOrModalOpen || textIsSelected) return;
    if (e.clipboardData) {
      if (selectedElementIds.size > 1) {
        addError('app.elements.copy.error.number');
        return;
      }
      if (selectedElementId) {
        const data = JSON.stringify({
          operation: 'copy',
          id: selectedElementId,
          oldSlideIndex: slideIndex,
          oldBannerIndex: bannerIndex,
        });
        addInfo('app.elements.copy.success', {
          autoHideDuration: autoHideDurations.shortMessage,
        });
        window.copiedData = data;
        e.preventDefault();
      }
    }
  };

  handlePaste = e => {
    const {
      duplicateElementRequested,
      onPasteImage,
      addElementRequested,
      slideIndex,
      addError,
      addInfo,
      bannerIndex,
      intl,
      menuOrModalOpen,
      textIsSelected,
    } = this.props;
    // element pasting
    if (window.copiedData) {
      const data = JSON.parse(window.copiedData);
      addInfo('app.elements.paste.success', {
        autoHideDuration: autoHideDurations.shortMessage,
      });
      duplicateElementRequested({
        ...data,
        slideIndex,
        bannerIndex,
      });
      window.copiedData = JSON.stringify({
        ...data,
        deleteOriginalElement: false, // original element has been deleted on the first paste
      });
      return;
    }
    const clipboardData = e.clipboardData || window.clipboardData;
    if (!clipboardData.types) return;
    // file pasting
    if (clipboardData.files && clipboardData.files.length > 0) {
      console.log('pasting file', clipboardData, clipboardData.types);
      if (clipboardData.files.length > 1) {
        addError('app.elements.paste.error.number');
        return;
      }
      const file = clipboardData.files.item(0);
      const fileName = clipboardData.getData('text/plain');
      if (!acceptedFileTypes.includes(file.type)) {
        addError('newImage.dropzone.error.type', { name: fileName });
        return;
      }
      if (file.size > maxUploadSize) {
        addError('newImage.dropzone.error.size', {
          name: fileName,
          maxSize: intl.formatNumber(maxUploadSize / (1024 * 1024)),
        });
        return;
      }
      onPasteImage(file, fileName);
      return;
    }
    // text pasting
    if (!menuOrModalOpen && clipboardData.types.includes('text/plain') && !textIsSelected) {
      console.log('pasting text', clipboardData, clipboardData.types);
      const text = clipboardData.getData('text/plain');
      addElementRequested({ text, type: 'text' });
    }
  };

  playGIFAnimation = () => {
    const { framesPerSecond, bannerDuration, updateBannerAnimationStatus } = this.props;
    const interval = 1 / framesPerSecond;
    this.animInterval = window.setInterval(() => {
      if (this.currentFrame < bannerDuration * framesPerSecond) {
        this.setState(updateSlideGIFAnimation(framesPerSecond, this.currentFrame, 'playGIFAnimation'));
        this.currentFrame = this.currentFrame + 1;
      } else {
        window.clearInterval(this.animInterval);
        updateBannerAnimationStatus({ bannerIsPlayingGIFAnimation: false });
        this.currentFrame = null;
      }
    }, interval * 1000);
  };

  handleEndAnimation = e => {
    const {
      updateBannerAnimationStatus,
      bannerIsPlayingHTMLAnimation,
      isLastSlide,
      showAnimationSnapshot,
      slideIsPlayingAnimation,
    } = this.props;

    const notConcerned =
      !(slideIsPlayingAnimation || bannerIsPlayingHTMLAnimation) ||
      !e ||
      !e.animationName.endsWith('-out') ||
      e.target !== this.rootContainer;

    if (notConcerned) return;

    /* i.e. if we are talking about the end of the slide transition and not any other element or slide */
    if (bannerIsPlayingHTMLAnimation) {
      /* end the animation if it is the last slide */
      isLastSlide && updateBannerAnimationStatus({ bannerIsPlayingHTMLAnimation: false });
    } else {
      !showAnimationSnapshot && updateBannerAnimationStatus({ slideIsPlayingAnimation: false });
    }
  };

  handleCanvasMouseDown = e => {
    if (e.target === this.canvas) {
      const { selectedElementId, selectedElementIds, unselectElement, unselectMultipleElements } = this.props;
      if (selectedElementId !== null) {
        unselectElement();
      }
      if (selectedElementIds.size > 0) {
        unselectMultipleElements(selectedElementIds);
      }
    }
  };

  handleGuidesToShow = guidesToShow => {
    this.setState({ guidesToShow });
  };

  render() {
    const {
      classes,
      selectedElementId,
      selectedElementIds,
      elements,
      bannerIsPlayingHTMLAnimation,
      bannerIsPlayingGIFAnimation,
      slide,
      slideIsPlayingAnimation,
      animationGlobalDelay,
      guides,
      zoom,
      isLastSlide,
      isFirstSlide,
      slideDurationForAnimation,
      bannerUniqueId,
      noBanners,
      framesPerSecond,
      currentFrame,
      showAnimationSnapshot,
      preventDeleteElement,
      slidesForAnimation,
      isLastSlideOfTour,
      isFirstSlideOfTour,
    } = this.props;

    const {
      guidesToShow,
      disableGuides,
      classesState,
      stylesState,
      perfectScrollbarOptions,
      bounds,
      width,
      height,
    } = this.state;

    return (
      <React.Fragment>
        <PerfectScrollbar
          id="scene-root"
          key={`${bannerIsPlayingHTMLAnimation}${bannerIsPlayingGIFAnimation}`}
          className={classNames('ps', classesState.perfectScrollbarClasses)}
          containerRef={rootContainer => (this.rootContainer = rootContainer)}
          ref={_scrollBarRef => (this._scrollBarRef = _scrollBarRef)}
          style={stylesState.perfectScrollbarStyle}
          options={perfectScrollbarOptions}
        >
          <div
            className={classes.bannerContainer}
            id="scene-root"
            style={stylesState.bannerContainerStyle}
            ref={canvas => (this.canvas = canvas)}
            onMouseDown={this.handleCanvasMouseDown}
          >
            <div
              className={classesState.bannerClasses}
              style={stylesState.bannerStyle}
              ref={slide => (this.slide = slide)}
            >
              {!noBanners &&
                elements.reverse().map(element => {
                  return (
                    <Element
                      {...element.toJS()}
                      key={element.id}
                      bounds={bounds}
                      selectedElementId={selectedElementId}
                      zoom={zoom}
                      selectedElementIds={selectedElementIds}
                      animationDelay={animationGlobalDelay}
                      slideIsPlayingAnimation={slideIsPlayingAnimation}
                      showGuides={this.handleGuidesToShow}
                      isLastSlide={isLastSlide}
                      isFirstSlide={isFirstSlide}
                      isLastSlideOfTour={isLastSlideOfTour}
                      isFirstSlideOfTour={isFirstSlideOfTour}
                      slideDuration={slide.duration}
                      slideDurationForAnimation={slideDurationForAnimation || slide.duration}
                      elementForExport={Boolean(bannerUniqueId)}
                      framesPerSecond={framesPerSecond}
                      currentFrame={this.currentFrame || currentFrame}
                      showAnimationSnapshot={showAnimationSnapshot}
                      preventDeleteElement={preventDeleteElement}
                      bannerWidth={width}
                      bannerHeight={height}
                      slidesForAnimation={slidesForAnimation}
                    />
                  );
                })}
            </div>
            <div className={classes.bannerOverlay}>
              <div className={classesState.bannerOverlayInside} style={stylesState.bannerOverlayStyle} />
            </div>
            {!disableGuides &&
              guidesToShow &&
              guidesToShow.map((guiId, i) => {
                const indexOfGuide = Number.isInteger(guiId) ? guiId : guiId.indexOfGuide;
                return (
                  <Guide
                    zoom={zoom}
                    key={`${indexOfGuide}${i}`}
                    guide={guides.get(indexOfGuide)}
                    additionalData={guiId}
                  />
                );
              })}
          </div>
        </PerfectScrollbar>
      </React.Fragment>
    );
  }
}

const makeStateToProps = () => {
  const stateToProps = (
    state,
    { slide, bannerUniqueId, backgroundColorForExport, currentFrame, showAnimationSnapshot },
  ) => {
    const elements =
      bannerUniqueId !== undefined
        ? makeSelectorInstance(getSlideElements(bannerUniqueId, slide))(state)
        : makeSelectorInstance(getElementsOfSlide(slide))(state);
    const currentFrameState = makeSelectorInstance(getBannerCurrentFrame)(state);

    const showAnimationSnapshotState = makeSelectorInstance(getBannerShowAnimationSnapshot)(state);

    return {
      currentFrame: currentFrame || currentFrameState,
      showAnimationSnapshot: showAnimationSnapshot || showAnimationSnapshotState,
      elements,
      backgroundColorForExport,
      format: makeSelectorInstance(getBannerFormat)(state),
      backgroundColor: makeSelectorInstance(getBannerBgColor)(state),
      bannerDuration: makeSelectorInstance(getBannerDuration)(state),
      selectedElementId: makeSelectorInstance(getSelectedElementId)(state),
      bannerIndex: makeSelectorInstance(getSelectedBannerIndex)(state),
      selectedElementIds: makeSelectorInstance(getSelectedElementIds)(state),
      selectedElements: makeSelectorInstance(getSelectedElements)(state),
      bannerIsPlayingHTMLAnimation: makeSelectorInstance(isBannerPlayingHTMLAnimation)(state),
      bannerIsPlayingGIFAnimation: makeSelectorInstance(isBannerPlayingGIFAnimation)(state),
      bannerIsCropping: makeSelectorInstance(getBannerIsCropping)(state),
      textIsSelected: makeSelectorInstance(getBannerTextIsSelected)(state),
      disableShortcuts: makeSelectorInstance(getDisableShortcuts)(state),
      zoom: makeSelectorInstance(getBannerZoom)(state),
      slidesOpen: makeSelectorInstance(isSlidesListOpen)(state),
      elementListOpen: makeSelectorInstance(isElementListOpen)(state),
      propertyEditorOpen: makeSelectorInstance(isPropertyEditorOpen)(state),
      preventDeleteElement: makeSelectorInstance(getBannerDisableDropZone)(state),
      banners: makeSelectorInstance(getBanners)(state),
      guides: makeSelectorInstance(getBannerGuidesForElement())(state),
      noBanners: makeSelectorInstance(checkIfNoBanners)(state),
      framesPerSecond: makeSelectorInstance(getBannerSetFPS)(state),
      slideIsPlayingAnimation: makeSelectorInstance(getSlideIsPlayingAnimation)(state),
      isSlaveBanner: makeSelectorInstance(isCurrentBannerSlaveBanner)(state),
      menuOrModalOpen: makeSelectorInstance(getTemporaryMenuOrModalOpen)(state),
    };
  };
  return stateToProps;
};

const dispatchToProps = {
  unselectElement,
  unselectMultipleElements,
  selectMultipleElements,
  duplicateElementRequested,
  updateBannerAnimationStatus,
  onPasteImage: addImageElementFromComputer,
  addElementRequested,
  addError,
  addInfo,
  openDeleteElementsDialog,
  bannerAnimationExport,
};

Scene.propTypes = {
  slide: PropTypes.object.isRequired,
  slideIndex: PropTypes.number,
  bannerUniqueId: PropTypes.string,
  backgroundColorForExport: PropTypes.string,
  showAnimationSnapshot: PropTypes.bool,
  currentFrame: PropTypes.number,
};

Scene.defaultProps = {
  showAnimationSnapshot: false,
  currentFrame: null,
  bannerUniqueId: null,
  backgroundColorForExport: null,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  withStyles(styles),
)(Scene);
