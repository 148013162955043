import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { ToggleButtonGroup } from '@material-ui/lab';
import ToggleButtonWithTooltip from '../../components/ToggleButton/ToggleButtonWithTooltip';
import { withStyles, FormControl, InputLabel } from '@material-ui/core';
import { styles } from '../propertyEditorStyles';
import ArrangeBringForwardIcon from 'mdi-material-ui/ArrangeBringForward';
import ArrangeSendBackwardIcon from 'mdi-material-ui/ArrangeSendBackward';
import ArrangeBringToFrontIcon from 'mdi-material-ui/ArrangeBringToFront';
import ArrangeSendToBackIcon from 'mdi-material-ui/ArrangeSendToBack';

class ElementsDisposition extends React.Component {
  state = { classesState: {} };

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return {
      classesState: {
        labelShrink: { shrink: classes.labelShrink },
        toggleButtonGroup: { root: classes.toggleButtons },
        toggleButtonWithTooltip: {
          root: classes.toggleButton,
          disabled: classes.toggleButtonDisabled,
          selected: classes.toggleButtonsInactive,
        },
      },
    };
  }

  render() {
    const { intl } = this.props;
    const { classesState } = this.state;
    return (
      <FormControl>
        <InputLabel shrink>
          <FormattedMessage id="properties.disposition" />
        </InputLabel>
        <ToggleButtonGroup classes={classesState.toggleButtonGroup} selected>
          <ToggleButtonWithTooltip
            classes={classesState.toggleButtonWithTooltip}
            value="forward"
            disabled
            tooltip={intl.formatMessage({ id: 'properties.disposition.forward' })}
          >
            <ArrangeBringForwardIcon />
          </ToggleButtonWithTooltip>
          <ToggleButtonWithTooltip
            classes={classesState.toggleButtonWithTooltip}
            value="backward"
            disabled
            tooltip={intl.formatMessage({ id: 'properties.disposition.backward' })}
          >
            <ArrangeSendBackwardIcon />
          </ToggleButtonWithTooltip>
          <ToggleButtonWithTooltip
            classes={classesState.toggleButtonWithTooltip}
            value="front"
            disabled
            tooltip={intl.formatMessage({ id: 'properties.disposition.front' })}
          >
            <ArrangeBringToFrontIcon />
          </ToggleButtonWithTooltip>
          <ToggleButtonWithTooltip
            classes={classesState.toggleButtonWithTooltip}
            value="back"
            disabled
            tooltip={intl.formatMessage({ id: 'properties.disposition.back' })}
          >
            <ArrangeSendToBackIcon />
          </ToggleButtonWithTooltip>
        </ToggleButtonGroup>
      </FormControl>
    );
  }
}

export default compose(
  injectIntl,
  withStyles(styles),
)(ElementsDisposition);
