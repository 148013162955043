import webFontLoader from 'webfontloader';
import { customFont, googleFont } from '../export/import-export-helpers/params';
import { addGoogleFontLink } from '../player/util/dom-helpers';
import { getResourceUrl } from './resourcesDucks';

// 100 most popular fonts on Google Fonts + selected webfonts
// curl "https://www.googleapis.com/webfonts/v1/webfonts?key=CHANGE_ME&sort=popularity" | jq '.items[].family' | head -100 | sort

// https://stackoverflow.com/a/46541824/5225096: Fonts won't work in IE if the font-family entry in css is named differently than the font name (important for custom fonts only)

export const availableFonts = [
  { name: 'Abel', type: googleFont },
  { name: 'Abril Fatface', type: googleFont },
  { name: 'Acme', type: googleFont },
  { name: 'Agenda', type: customFont, url: '/fonts/Agenda.css', fontUrl: '/fonts/Agenda Medium.woff' },
  {
    name: 'Aktiv Grotesk Corp',
    type: customFont,
    url: '/fonts/AktivGroteskCorp.css',
    fontUrl: '/fonts/Aktiv Grotesk Corp.woff',
  },
  { name: 'Alegreya', type: googleFont },
  { name: 'Allura', type: googleFont },
  { name: 'Amatic SC', type: googleFont },
  { name: 'Antenna', type: googleFont },
  { name: 'Anton', type: googleFont },
  { name: 'Archivo Black', type: googleFont },
  { name: 'Archivo Narrow', type: googleFont },
  { name: 'Arimo', type: googleFont },
  { name: 'Arquitecta', type: customFont, url: '/fonts/Arquitecta.css', fontUrl: '/fonts/Arquitecta Medium.woff' },
  { name: 'Arvo', type: googleFont },
  { name: 'Asap', type: googleFont },
  {
    name: 'Bauer Bodoni Std 1',
    type: customFont,
    url: '/fonts/BauerBodoniStd1.css',
    fontUrl: '/fonts/Bauer Bodoni Std 1.woff',
  },
  { name: 'Big Caslon', type: customFont, url: '/fonts/BigCaslon.css', fontUrl: '/fonts/BigCaslon.woff' },
  { name: 'Bitter', type: googleFont },
  {
    name: 'Buenos Aires Trial',
    type: customFont,
    url: '/fonts/BuenosAiresTrial.css',
    fontUrl: '/fonts/BuenosAiresTrial.woff',
  },
  { name: 'Bree Serif', type: googleFont },
  { name: 'Cabin', type: googleFont },
  { name: 'Cairo', type: googleFont },
  { name: 'Catamaran', type: googleFont },
  { name: 'Choplin', type: customFont, url: '/fonts/Choplin.css', fontUrl: '/fonts/Choplin.woff' },
  {
    name: 'CrystalMushroom',
    type: customFont,
    url: '/fonts/CrystalMushroom.css',
    fontUrl: '/fonts/CrystalMushroom.woff',
  },
  { name: 'Cinzel', type: googleFont },
  { name: 'Comfortaa', type: googleFont },
  { name: 'Courgette', type: googleFont },
  { name: 'Crete Round', type: googleFont },
  { name: 'Crimson Text', type: googleFont },
  { name: 'Cuprum', type: googleFont },
  { name: 'Dancing Script', type: googleFont },
  { name: 'DIN Pro', type: customFont, url: '/fonts/DINPro.css', fontUrl: '/fonts/DINPro.woff' },
  { name: 'Domine', type: googleFont },
  { name: 'Dosis', type: googleFont },
  { name: 'Druk Wide', type: customFont, url: '/fonts/DrukWide.css', fontUrl: '/fonts/DrukWide.woff' },
  { name: 'EB Garamond', type: googleFont },
  { name: 'Etelka Text Pro', type: customFont, url: '/fonts/EtelkaTextPro.css', fontUrl: '/fonts/EtelkaTextPro.woff' },
  { name: 'Exo 2', type: googleFont },
  { name: 'Exo', type: googleFont },
  { name: 'Fira Sans', type: googleFont },
  { name: 'Fjalla One', type: googleFont },
  { name: 'Francois One', type: googleFont },
  { name: 'Gloria Hallelujah', type: googleFont },
  { name: 'Great Vibes', type: googleFont },
  { name: 'Heebo', type: googleFont },
  { name: 'Hind Siliguri', type: googleFont },
  { name: 'Hind', type: googleFont },
  { name: 'Inconsolata', type: googleFont },
  { name: 'Indie Flower', type: googleFont },
  { name: 'Josefin Sans', type: googleFont },
  { name: 'Kanit', type: googleFont },
  { name: 'Karla', type: googleFont },
  { name: 'Lato', type: googleFont },
  { name: 'Le Jeune Deck', type: customFont, url: '/fonts/LeJeuneDeck.css', fontUrl: '/fonts/LeJeuneDeck.woff' },
  { name: 'Libre Baskerville', type: googleFont },
  { name: 'Libre Franklin', type: googleFont },
  { name: 'Lobster', type: googleFont },
  { name: 'Lora', type: googleFont },
  { name: 'Maven Pro', type: googleFont },
  { name: 'Merriweather Sans', type: googleFont },
  { name: 'Merriweather', type: googleFont },
  { name: 'Minion Pro', type: customFont, url: '/fonts/MinionPro.css', fontUrl: '/fonts/MinionPro.woff' },
  { name: 'Montserrat', type: googleFont },
  { name: 'Moonhouse', type: customFont, url: '/fonts/Moonhouse.css', fontUrl: '/fonts/Moonhouse.woff' },
  { name: 'Muli', type: googleFont },
  { name: 'Nanum Gothic', type: googleFont },
  { name: 'Noticia Text', type: googleFont },
  { name: 'Noto Sans', type: googleFont },
  { name: 'Noto Serif', type: googleFont },
  { name: 'Nunito Sans', type: googleFont },
  { name: 'Nunito', type: googleFont },
  { name: 'Open Sans Condensed', type: googleFont },
  { name: 'Open Sans', type: googleFont },
  { name: 'Orbitron', type: googleFont },
  { name: 'Oswald:400,700', type: googleFont },
  { name: 'Oxygen', type: googleFont },
  { name: 'Platform', type: customFont, url: '/fonts/Platform.css', fontUrl: '/fonts/Platform.woff' },
  { name: 'Proforma', type: customFont, url: '/fonts/Proforma.css', fontUrl: '/fonts/Proforma.woff' },
  { name: 'PT Sans Caption', type: googleFont },
  { name: 'PT Sans Narrow', type: googleFont },
  { name: 'PT Sans', type: googleFont },
  { name: 'PT Serif', type: googleFont },
  { name: 'Pacifico', type: googleFont },
  { name: 'Passion One', type: googleFont },
  { name: 'Patua One', type: googleFont },
  { name: 'Permanent Marker', type: googleFont },
  { name: 'Play', type: googleFont },
  { name: 'Playfair Display', type: googleFont },
  { name: 'Poiret One', type: googleFont },
  { name: 'Poppins', type: googleFont },
  { name: 'Questrial', type: googleFont },
  { name: 'Quicksand', type: googleFont },
  { name: 'Rajdhani', type: googleFont },
  { name: 'Raleway', type: googleFont },
  { name: 'Righteous', type: googleFont },
  { name: 'Roboto Condensed', type: googleFont },
  { name: 'Roboto Mono', type: googleFont },
  { name: 'Roboto Slab', type: googleFont },
  { name: 'Roboto', type: googleFont },
  { name: 'Rokkitt', type: googleFont },
  { name: 'Ropa Sans', type: googleFont },
  { name: 'Rubik', type: googleFont },
  { name: 'Shadows Into Light', type: googleFont },
  { name: 'Signika', type: googleFont },
  { name: 'Slabo 27px', type: googleFont },
  { name: 'Source Code Pro', type: googleFont },
  { name: 'Source Sans Pro', type: googleFont },
  { name: 'Source Serif Pro', type: googleFont },
  { name: 'Stencil Std', type: customFont, url: '/fonts/StencilStd.css', fontUrl: '/fonts/StencilStd.woff' },
  { name: 'Teko', type: googleFont },
  { name: 'Titillium Web', type: googleFont },
  { name: 'Triplex', type: customFont, url: '/fonts/Triplex.css', fontUrl: '/fonts/Triplex.woff' },
  { name: 'Ubuntu Condensed', type: googleFont },
  { name: 'Ubuntu', type: googleFont },
  { name: 'Varela Round', type: googleFont },
  { name: 'VIP Roman', type: customFont, url: '/fonts/VIPRoman.css', fontUrl: '/fonts/VIPRoman.woff' },
  { name: 'Vollkorn', type: googleFont },
  { name: 'Wingdings', type: customFont, url: '/fonts/Wingdings.css', fontUrl: '/fonts/Wingdings.woff' },
  { name: 'Wingdings 2', type: customFont, url: '/fonts/Wingdings2.css', fontUrl: '/fonts/Wingdings2.woff' },
  { name: 'Work Sans', type: googleFont },
  { name: 'Yanone Kaffeesatz', type: googleFont },
  { name: 'Zag', type: customFont, url: '/fonts/Zag.css', fontUrl: '/fonts/Zag.woff' },
];

const loadedFonts = ['Roboto', 'Open Sans'];

export const loadFonts = (fonts, userFontResourceIds, from) => {
  const unloadedFonts = fonts.filter(font => !loadedFonts.includes(font));
  if (!unloadedFonts.length) return Promise.resolve();

  /* GOOGLE FONTS
    webFontLoader is not loading bold fonts, and there is a bug in the url build
    because webFontLoader adds a 'subset=bold' at the end of the url that returns an error 400 from google API.
    Soo we build ourselves
    */

  const googleFamilies = [];
  const webFontConfig = {
    google: { families: [] },
    custom: { families: [], urls: [] },
  };
  for (const fontName of unloadedFonts) {
    const fontConfig = availableFonts.find(f => f.name === fontName);
    const resourceId = userFontResourceIds && userFontResourceIds[fontName];
    if (fontConfig) {
      switch (fontConfig.type) {
        case googleFont:
          googleFamilies.push(fontName);
          loadedFonts.push(fontName);
          break;
        case 'custom':
          webFontConfig.custom.families.push(fontName);
          webFontConfig.custom.urls.push(fontConfig.url);
          loadedFonts.push(fontName);
          break;
        default:
          console.error('Unknown font type', fontConfig.type);
        // Do nothing for unknown font type
      }
    } else if (resourceId) {
      const cssText = `@font-face { font-family: '${fontName}'; font-display:swap; src: url('${getResourceUrl(
        resourceId,
        'loadFonts',
      )}'); }`;
      let style = document.getElementById(resourceId);
      if (!style) {
        style = document.createElement('style');
        style.id = resourceId;
        style.type = 'text/css';
        document.head.appendChild(style);
      }
      style.innerHTML = cssText;
      loadedFonts.push(fontName);
    }
  }
  if (!webFontConfig.google.families.length) {
    delete webFontConfig.google;
  }
  if (!webFontConfig.custom.families.length) {
    delete webFontConfig.custom;
  }

  const promises = [];
  /* GOOGLE FONTS */
  if (googleFamilies && googleFamilies.length) {
    promises.push(
      new Promise(resolve => {
        const link = addGoogleFontLink(googleFamilies);
        link.onload = resolve;
        document.head.appendChild(link);
      }),
    );
  }

  /* CUSTOM FONTS */
  if (webFontConfig.custom) {
    promises.push(
      new Promise((resolve, reject) => {
        const fontTimeout = window.setTimeout(() => reject('Font loading timeout --- ', unloadedFonts), 5000);
        webFontLoader.load({
          ...webFontConfig,
          classes: false,
          active: e => {
            window.clearTimeout(fontTimeout);
            return resolve();
          },
          inactive: () => {
            window.clearTimeout(fontTimeout);
            return reject('Font loading error ---', unloadedFonts);
          },
        });
      }),
    );
  }

  return Promise.all(promises);
};

export const loadFont = (font, userFontResourceIds, from) =>
  loadFonts([font], userFontResourceIds, `${from} -> loadFont`);

export const loadAllFonts = userFontResourceIds =>
  loadFonts(
    [].concat(availableFonts.map(font => font.name), userFontResourceIds && Object.keys(userFontResourceIds)),
    userFontResourceIds,
  );

// 'Forget' that the font is loaded, to allow reloading it.
export const unloadFont = fontName => {
  delete loadedFonts[fontName];
};

export const getStylesheetsToRemove = () => {
  const removedLinks = [];
  document.querySelectorAll("link[rel='stylesheet']").forEach(link => {
    if (link.href.includes('font')) {
      removedLinks.push(link);
    }
  });
  return removedLinks;
};

export const deleteStylesheets = stylesheets => {
  stylesheets.forEach(link => {
    link.remove();
  });
};

export const restoreStylesheets = stylesheets => {
  stylesheets.forEach(link => {
    document.head.appendChild(link);
  });
};

/**
 * Method to load Only the used fonts before using domtoimage lib.
 * Return a restore callback to restore to previous state.
 */
export const loadOnlyUsedFonts = (usedFontsFamilies, userFontResourceIds) => {
  const originalStylesheets = getStylesheetsToRemove();
  const originalLoadedFonts = loadedFonts.splice(0, loadedFonts.length);

  const restoreFonts = () => {
    const printStylesheets = getStylesheetsToRemove();
    restoreStylesheets(originalStylesheets);
    loadedFonts.splice(0, loadedFonts.length);
    loadedFonts.push(...originalLoadedFonts);
    deleteStylesheets(printStylesheets);
  };

  return loadFonts(usedFontsFamilies, userFontResourceIds, `imageexport`).then(() => {
    deleteStylesheets(originalStylesheets);
    return restoreFonts;
  });
};
