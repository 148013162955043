import { debug, googleFont, customFont } from './params';
import { availableFonts } from '../../resources/fonts';

const isGoogleFont = fontName => {
  const fonts = availableFonts.filter(font => font.name === fontName);
  if (fonts.length === 1) {
    return fonts[0].type === googleFont;
  } else {
    return false;
  }
};
const isCustomFont = fontName => {
  const fonts = availableFonts.filter(font => font.name === fontName);
  if (fonts.length === 1) {
    return fonts[0].type === customFont;
  } else {
    return false;
  }
};

export const filterOnlyUsedFonts = (banner, userFonts) => {
  const fontNames = banner.elements
    .valueSeq()
    .filter(el => el.fontFamily)
    .map(el => el.fontFamily)
    .toSet();
  debug && console.log('fontNames', fontNames.toArray());
  return {
    userFontNames: fontNames.filter(fontName => userFonts.hasOwnProperty(fontName)),
    googleFontNames: fontNames.filter(fontName => !userFonts.hasOwnProperty(fontName) && isGoogleFont(fontName)),
    customFontNames: fontNames.filter(fontName => !userFonts.hasOwnProperty(fontName) && isCustomFont(fontName)),
  };
};
