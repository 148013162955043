import React from 'react';
import { connect } from 'react-redux';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { compose } from 'recompose';
import { getSelectedBannerIndex } from '../../shared-selectors/sharedSelectors';
import { getBannerDissociateFromMaster } from '../../banner/bannerSelectors';
import { toggleBannerDissociateFromMaster } from '../../banner/bannerActionsCreators';
import { getBannersMasterIndex, isCurrentBannerDissociatedByDefault } from '../../banners/bannersSelectors';
import CheckboxDoped from '../../components/CheckboxDoped';

class BannerDissociateFromMaster extends React.Component {
  render() {
    const {
      toggleBannerDissociateFromMaster,
      dissociateFromMaster,
      selectedBannerIndex,
      bannerMasterIndex,
      isBannerDissociatedByDefault,
    } = this.props;
    return (
      <CheckboxDoped
        toggleFunction={toggleBannerDissociateFromMaster}
        checked={dissociateFromMaster || isBannerDissociatedByDefault}
        intlId="properties.banner-dissociate-from-master"
        disabled={bannerMasterIndex === selectedBannerIndex || isBannerDissociatedByDefault}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  dissociateFromMaster: makeSelectorInstance(getBannerDissociateFromMaster)(state),
  bannerMasterIndex: makeSelectorInstance(getBannersMasterIndex)(state),
  selectedBannerIndex: makeSelectorInstance(getSelectedBannerIndex)(state),
  isBannerDissociatedByDefault: makeSelectorInstance(isCurrentBannerDissociatedByDefault)(state),
});

const dispatchToProps = {
  toggleBannerDissociateFromMaster,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
)(BannerDissociateFromMaster);
