import { doWithToken } from 'drb-commons-front';

// Bug AD-358
// from https://stackoverflow.com/a/11463939/5225096

const addParamsToUrl = (url, params) => {
  const encodedParams = Object.keys(params)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');

  return url + '/?' + encodedParams;
};

const killCache = url => (url.includes('?') ? `${url}&killCache${Date.now()}` : `${url}/?killCache${Date.now()}`);

const fetchApi = async ({
  baseUrl = this.baseUrl,
  path = '',
  params = {},
  method = 'GET',
  body = null,
  headers = {},
  credentials = 'same-origin',
  errorMessage = "can't fetch",
  withToken = true,
}) => {
  try {
    let url = baseUrl + path;
    if (Object.keys(params).length) {
      url = addParamsToUrl(url, params);
    }
    url = killCache(url);

    if (withToken) {
      return doWithToken(token =>
        fetch(url, {
          method,
          headers: new Headers({
            ...headers,
            Authorization: `Bearer ${token}`,
          }),
          credentials,
          body,
        }),
      );
    }

    return fetch(url, {
      method,
      headers: new Headers({ ...headers }),
      credentials,
      body,
    });
  } catch (e) {
    console.error('fetchApi', e);
    throw Error(errorMessage);
  }
};

export default fetchApi;
