import fetchDrbApi from './fetchDrbApi';

export const fetchDrbApiEntitiesDCO = () =>
  fetchDrbApi({ path: '/entities/dco/?withReadOnlyFields=true' })
    .then(res => res.json())
    .then(entities =>
      entities.map(({ id, dco }) => ({
        id,
        ...dco,
      })),
    )
    .catch(err => console.error(err));

export default fetchDrbApiEntitiesDCO;
