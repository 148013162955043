import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogActions, Button, DialogContent } from '@material-ui/core';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import {
  getMasterBannerDialogOpen,
  getMasterBannerDialogKey,
  stopMasterBannerCancel,
  stopMasterBannerConfirmStop,
  getMasterBannerDialogTodo,
  stopMasterBannerConfirmDissociate,
} from './masterBannerDialogDucks';

const MasterBannerDialog = ({ cancel, confirmStop, confirmDissociate, open, todo }) => {
  return (
    <Dialog open={open} onClose={cancel}>
      <DialogTitle>
        {todo === 'stop' ? (
          <FormattedMessage id="master-banner-dialog.title-stop" />
        ) : (
          <FormattedMessage id="master-banner-dialog.title-dissociate" />
        )}
      </DialogTitle>
      <DialogContent>
        {todo === 'stop' && <FormattedHTMLMessage id="master-banner-dialog.stop" />}
        {todo === 'crop' && <FormattedHTMLMessage id="master-banner-dialog.dissociate-crop" />}
        {todo === 'dissociate' && <FormattedHTMLMessage id="master-banner-dialog.dissociate" />}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>
          <FormattedMessage id="alert.cancel" />
        </Button>
        {todo === 'stop' && (
          <Button color="primary" onClick={confirmStop}>
            <FormattedMessage id="master-banner-dialog.confirm-stop" />
          </Button>
        )}
        {todo !== 'stop' && (
          <Button color="primary" onClick={confirmDissociate}>
            <FormattedMessage id="master-banner-dialog.confirm-dissociate" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const makeStateToProps = () => state => ({
  open: makeSelectorInstance(getMasterBannerDialogOpen)(state),
  key: makeSelectorInstance(getMasterBannerDialogKey)(state),
  todo: makeSelectorInstance(getMasterBannerDialogTodo)(state),
});
const dispatchToProps = {
  cancel: stopMasterBannerCancel,
  confirmStop: stopMasterBannerConfirmStop,
  confirmDissociate: stopMasterBannerConfirmDissociate,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  React.memo,
)(MasterBannerDialog);
