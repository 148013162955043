import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { createLogger } from 'redux-logger';

import reducers from './reducers';
import sagas from './sagas';
import persistConfig from './persistConfig';
import { bannerMasterMiddleware, ensureCurrentSelectionMiddleware } from './middlewares';
import { loadConfig } from './config/configDucks';

import Immutable from 'immutable';
import { loadTranslations } from './i18n/i18nDucks';

var installDevTools = require('immutable-devtools');
installDevTools(Immutable);

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers(reducers);
const persistedReducer = persistReducer(persistConfig, rootReducer);
const enhancers = [sagaMiddleware, bannerMasterMiddleware, ensureCurrentSelectionMiddleware];
if (process.env.REACT_APP_DEBUG === 'true') {
  enhancers.push(createLogger({ collapsed: true }));
}

const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...enhancers)));

sagas.map(sagaMiddleware.run);

const persistor = persistStore(store, null, async () => {
  store.dispatch(loadConfig());
  store.dispatch(loadTranslations());
});
export { store, persistor };
