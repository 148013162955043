import { corners } from '../../../reference/corners';

const debug = false;

export const getGuidesToShowForResize = ({
  guides,
  rotation,
  newParams,
  magnetismGrid,
  disableGuides,
  movingCorner,
  immobileCorner,
  zoom,
  lockAspectRatio,
}) => {
  let newX = newParams.x;
  let newY = newParams.y;
  let newWidth = newParams.width;
  let newHeight = newParams.height;

  let aspectRatio = newWidth / newHeight;
  const cornerIsMiddleCorner =
    movingCorner === corners.middleTop ||
    movingCorner === corners.middleRight ||
    movingCorner === corners.middleBottom ||
    movingCorner === corners.middleLeft;

  let guidesToShow = [];

  if (disableGuides || Math.abs(rotation) !== 0) {
    return {
      guidesToShow,
      newX,
      newY,
      newWidth,
      newHeight,
    };
  }

  const theoreticalBottomRightCoords = {
    x: newX + newWidth,
    y: newY + newHeight,
  };

  const theoreticalCenterCoords = {
    x: newX + newWidth / 2,
    y: newY + newHeight / 2,
  };

  guides.forEach((guide, ind) => {
    if (guide.orientation === 'vertical') {
      /* center guide */
      if (
        guide.magnetism === 'center' &&
        Math.abs(theoreticalCenterCoords.x - guide.position * zoom) < magnetismGrid * zoom
      ) {
        if (
          movingCorner === corners.bottomRight ||
          movingCorner === corners.middleRight ||
          movingCorner === corners.topRight
        ) {
          debug && console.log('vertical center bottom right');
          newWidth = 2 * (guide.position * zoom - newX);
          if (cornerIsMiddleCorner && lockAspectRatio) {
            newHeight = newWidth / aspectRatio;
            newY = immobileCorner.top - newHeight / 2;
          }
        } else if (
          movingCorner === corners.bottomLeft ||
          movingCorner === corners.middleLeft ||
          movingCorner === corners.topLeft
        ) {
          debug && console.log('vertical center top left');
          newX = guide.position * zoom - (theoreticalBottomRightCoords.x - guide.position * zoom);
          newWidth = theoreticalBottomRightCoords.x - newX;
          if (cornerIsMiddleCorner && lockAspectRatio) {
            newHeight = newWidth / aspectRatio;
            newY = immobileCorner.top - newHeight / 2;
          }
        }
        guidesToShow.push(ind);
      } else if (
        /* left guide */
        guide.magnetism === 'left' &&
        (movingCorner === corners.topLeft ||
          movingCorner === corners.middleLeft ||
          movingCorner === corners.bottomLeft) &&
        Math.abs(newX - guide.position * zoom) < magnetismGrid * zoom
      ) {
        debug && console.log('vertical left');
        newX = guide.position * zoom;
        newWidth = theoreticalBottomRightCoords.x - newX;
        if (cornerIsMiddleCorner && lockAspectRatio) {
          newHeight = newWidth / aspectRatio;
          newY = immobileCorner.top - newHeight / 2;
        }
        guidesToShow.push(ind);
      } else if (
        /* right guide */
        guide.magnetism === 'right' &&
        (movingCorner === corners.topRight ||
          movingCorner === corners.middleRight ||
          movingCorner === corners.bottomRight) &&
        Math.abs(theoreticalBottomRightCoords.x - guide.position * zoom) < magnetismGrid * zoom
      ) {
        debug && console.log('vertical right');
        newWidth = guide.position * zoom - newX;
        if (cornerIsMiddleCorner && lockAspectRatio) {
          newHeight = newWidth / aspectRatio;
          newY = immobileCorner.top - newHeight / 2;
        }
        guidesToShow.push(ind);
      }
    }
    if (guide.orientation === 'horizontal') {
      /* center guide */
      if (
        guide.magnetism === 'center' &&
        Math.abs(theoreticalCenterCoords.y - guide.position * zoom) < magnetismGrid * zoom
      ) {
        if (
          movingCorner === corners.bottomRight ||
          movingCorner === corners.middleBottom ||
          movingCorner === corners.bottomLeft
        ) {
          debug && console.log('horizontal center bottom');
          newHeight = 2 * (guide.position * zoom - newY);
          if (cornerIsMiddleCorner && lockAspectRatio) {
            newWidth = newHeight * aspectRatio;
            newX = immobileCorner.left - newWidth / 2;
          }
        } else if (
          movingCorner === corners.topRight ||
          movingCorner === corners.middleTop ||
          movingCorner === corners.topLeft
        ) {
          debug && console.log('horizontal center top');
          newY = guide.position * zoom - (theoreticalBottomRightCoords.y - guide.position * zoom);
          newHeight = theoreticalBottomRightCoords.y - newY;
          if (cornerIsMiddleCorner && lockAspectRatio) {
            newWidth = newHeight * aspectRatio;
            newX = immobileCorner.left - newWidth / 2;
          }
        }
        guidesToShow.push(ind);
      } else if (
        /* top guide */
        guide.magnetism === 'top' &&
        (movingCorner === corners.topLeft || movingCorner === corners.middleTop || movingCorner === corners.topRight) &&
        Math.abs(newY - guide.position * zoom) < magnetismGrid * zoom
      ) {
        debug && console.log('horizontal top');
        newY = guide.position * zoom;
        newHeight = theoreticalBottomRightCoords.y - guide.position * zoom;
        if (cornerIsMiddleCorner && lockAspectRatio) {
          newWidth = newHeight * aspectRatio;
          newX = immobileCorner.left - newWidth / 2;
        }
        guidesToShow.push(ind);
      } else if (
        /* bottom guide */
        guide.magnetism === 'bottom' &&
        (movingCorner === corners.bottomLeft ||
          movingCorner === corners.middleBottom ||
          movingCorner === corners.bottomRight) &&
        Math.abs(theoreticalBottomRightCoords.y - guide.position * zoom) < magnetismGrid * zoom
      ) {
        debug && console.log('horizontal bottom');
        newHeight = guide.position * zoom - newY;
        if (cornerIsMiddleCorner && lockAspectRatio) {
          newWidth = newHeight * aspectRatio;
          newX = immobileCorner.left - newWidth / 2;
        }
        guidesToShow.push(ind);
      }
    }
  });

  return {
    guidesToShow,
    newX,
    newY,
    newWidth,
    newHeight,
  };
};
