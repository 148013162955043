import fetchApi from '../fetchApi';

const fetchDrbApi = async args => {
  const errorMessage = args.errorMessage || "can't fetch drb api";
  try {
    return await fetchApi({
      baseUrl: window.aragoConfig.api.drb,
      errorMessage,
      ...args,
    });
  } catch (e) {
    throw errorMessage;
  }
};
export default fetchDrbApi;
