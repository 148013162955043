import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Checkbox } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import classNames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import Monitor from 'mdi-material-ui/Monitor';
import Cellphone from 'mdi-material-ui/Cellphone';
import MonitorCellphone from 'mdi-material-ui/MonitorCellphone';

import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getSelectedBannerIndex, getBannerFormat } from '../shared-selectors/sharedSelectors';
import { getBannerName } from '../banner/bannerSelectors';
import { getBannersFormats, getOtherBannersFormats } from '../banners/bannersSelectors';
import { changeFormat, setBannerName } from '../banner/bannerActionsCreators';
import { addBanner, setBannerCopyFormat } from '../banners/bannersActionsCreators';
import {
  toggleSelectFormat,
  getBannerFormatDialogState,
  selectIsOpenForBannerSetCreation,
} from './bannerFormatSelectorDucks';

const cardWidth = 140;
const cardHeight = 180;
const iconRatio = 0.8;

const iconMaxWidth = 1000;

const styles = theme => ({
  card: {
    width: `${cardWidth}px`,
    height: `${cardHeight}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexShrink: 0,
    marginRight: 15,
    '&:hover': {
      '& $iconBgContainer': {
        // transform: 'scale(1.02)',
        boxShadow: theme.shadows[2],
      },
    },
  },
  disabled: {
    opacity: 0.35,
  },
  iconBgContainer: {
    position: 'relative',
    height: '75%',
    width: '100%',
    transition: theme.transitions.create(['transform'], { duration: theme.transitions.duration.shortest }),
  },
  iconContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: fade(theme.palette.primary.main, 0.3),
    borderRadius: theme.shape.borderRadius,
  },
  selected: {
    border: `2px solid ${theme.palette.primary.dark}`,
  },
  iconFormat: {
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    height: '80%',
    width: '80%',
    margin: 'auto',
    backgroundColor: theme.palette.primary.main,
  },
  description: {
    height: '25%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  descriptionName: {
    fontSize: '0.85rem',
    lineHeight: '1.25rem',
  },
  descriptionDimensions: {
    fontSize: '0.65rem',
    opacity: 0.75,
  },
  checkBox: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
  },
  iconResponsive: {
    pointerEvents: 'none',
    width: 20,
    height: 20,
    fontSize: 20,
    position: 'absolute',
    bottom: 0,
    right: 2,
  },
});

const cardIsDisabled = ({
  isForBannerSetCreation,
  isForNewBannerCreation,
  isForCopyingBanner,
  allBannersFormats,
  otherBannersFormats,
  name,
}) => {
  if (isForBannerSetCreation) return false;
  if (isForNewBannerCreation || isForCopyingBanner) {
    return allBannersFormats.map(formatObj => formatObj.name).includes(name);
  }
  return otherBannersFormats.map(formatObj => formatObj.name).includes(name);
};

const BannerFormatSelectorCard = ({
  classes,
  format,
  currentFormat,
  dialogOpenFor,
  isForBannerSetCreation,
  selected,
  changeFormat,
  toggleSelectFormat,
  addBanner,
  intl,
  otherBannersFormats,
  allBannersFormats,
  setBannerName,
  setBannerCopyFormat,
  bannerIndex,
}) => {
  const { name, height, width, locale, responsive } = format;

  const { isForNewBannerCreation, isForChangingFormat, isForCopyingBanner } = dialogOpenFor;

  const { bannerHeight: currentHeight, bannerWidth: currentWidth } = currentFormat;

  const cardIsCurrentFormat = currentHeight === height && currentWidth === width;

  const localeName = intl.formatMessage({ id: locale });

  let Media;
  switch (responsive) {
    case 'desktop': {
      Media = Monitor;
      break;
    }
    case 'mobile': {
      Media = Cellphone;
      break;
    }
    default: {
      Media = MonitorCellphone;
      break;
    }
  }

  const disabled = cardIsDisabled({
    isForBannerSetCreation,
    isForNewBannerCreation,
    isForCopyingBanner,
    allBannersFormats,
    otherBannersFormats,
    name,
  });

  const onClick = () => {
    if (isForNewBannerCreation) {
      addBanner({ name, height, width });
    } else if (isForCopyingBanner) {
      setBannerCopyFormat({ name, height, width });
    } else if (isForChangingFormat) {
      setBannerName(localeName, bannerIndex, false);
      changeFormat({ name, height, width, bannerIndex });
    } else if (isForBannerSetCreation) {
      toggleSelectFormat(name);
    }
  };

  const buttonBaseClasses = { root: classes.card, disabled: classes.disabled };
  const iconContainerClasses = classNames(classes.iconContainer, {
    [classes.selected]: cardIsCurrentFormat && isForChangingFormat,
  });

  const iconStyle = {
    height: (height * cardWidth * iconRatio) / iconMaxWidth,
    width: (width * cardWidth * iconRatio) / iconMaxWidth,
  };

  return (
    <ButtonBase
      classes={buttonBaseClasses}
      focusVisibleClassName={classes.cardFocusVisible}
      onClick={onClick}
      component="div"
      disabled={disabled}
    >
      <div className={classes.iconBgContainer}>
        <div className={iconContainerClasses}>
          <div className={classes.iconFormat} style={iconStyle} />
        </div>
        <div className={classes.iconResponsive}>
          <Media fontSize="inherit" color="primary" />
        </div>
      </div>
      {isForBannerSetCreation && <Checkbox color="primary" checked={selected} classes={{ root: classes.checkBox }} />}
      <div className={classes.description}>
        <span className={classes.descriptionName}>{localeName}</span>
        <span className={classes.descriptionDimensions}>{`${width} x ${height}`}</span>
      </div>
    </ButtonBase>
  );
};

const makeStateToProps = () => state => ({
  currentFormat: makeSelectorInstance(getBannerFormat)(state),
  bannerIndex: makeSelectorInstance(getSelectedBannerIndex)(state),
  bannerName: makeSelectorInstance(getBannerName)(state),
  dialogOpenFor: makeSelectorInstance(getBannerFormatDialogState)(state),
  isForBannerSetCreation: makeSelectorInstance(selectIsOpenForBannerSetCreation)(state),
  otherBannersFormats: makeSelectorInstance(getOtherBannersFormats)(state),
  allBannersFormats: makeSelectorInstance(getBannersFormats)(state),
});

const dispatchToProps = {
  changeFormat,
  addBanner,
  toggleSelectFormat,
  setBannerName,
  setBannerCopyFormat,
};

export default compose(
  connect(makeStateToProps, dispatchToProps),
  injectIntl,
  withStyles(styles),
  React.memo,
)(BannerFormatSelectorCard);
