import { withStyles } from '@material-ui/core/styles';
import { ToggleButton } from '@material-ui/lab';
import domtoimage from 'dom-to-image';
import EyeDropper from 'mdi-material-ui/Eyedropper';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { isElementListOpen, isPropertyEditorOpen, isSlidesListOpen } from '../../layout/layoutDucks';
import cursors from '../../reference/cursors';
import keyboardShortcuts from '../../reference/keyboardShortcuts';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { loadOnlyUsedFonts } from '../../resources/fonts';
import { getUsedFontsFamilies, getUserFonts } from '../../shared-selectors/sharedSelectors';
import { updateBannerStatus } from '../../temporary-status/temporaryStatusDucks';

const styles = theme => ({
  eyeDropperButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
    marginLeft: `0 !important`,
    paddingLeft: `0 !important`,
    height: theme.spacing(4),
    padding: 0,
    border: 'none',
    position: 'relative',
  },
  cursor: {
    width: 1,
    height: 1,
    background: 'blue',
    position: 'fixed',
  },
});

const initialState = { canvas: undefined, color: undefined };

class EyeDropperComponent extends React.Component {
  state = initialState;

  componentDidMount() {
    this.eyeDropperActive = false;
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleShortcuts);
    const canvas = document.querySelector('.canvas-for-eyedropper');
    if (canvas) {
      canvas.removeEventListener('click', this.deactivateEyeDropper);
      canvas.removeEventListener('mousemove', this.saveColor);
    }
    this.eyeDropperIsStarting = false;
    this.eyeDropperActive = false;
  }
  handleShortcuts = e => {
    /* remove eyedropper */
    if (keyboardShortcuts.cancel.includes(e.key)) {
      this.deactivateEyeDropper(e, this.props.initialColor);
    }
  };

  activateEyeDropper = async e => {
    if (this.eyeDropperActive) {
      this.deactivateEyeDropper(e, this.props.initialColor);
      this.eyeDropperActive = false;
      return;
    }
    if (this.eyeDropperIsStarting) return; // be patient please
    this.eyeDropperIsStarting = true;
    const { updateBannerStatus, userFonts, usedFontsFamilies } = this.props;
    updateBannerStatus({ isEyeDropping: true });
    window.addEventListener('keyup', this.handleShortcuts);
    const scene = document.querySelector('#scene-root');
    const { width, height } = scene.getBoundingClientRect();

    const restoreFonts = await loadOnlyUsedFonts(usedFontsFamilies, userFonts);
    const imageUrl = await domtoimage.toJpeg(scene).catch(e => console.error(e))
    await restoreFonts();

    const img = new Image();
    const canvas = document.createElement('canvas');

    img.onload = function() {
      canvas.getContext('2d').drawImage(img, 0, 0, width, height);
    };
    img.src = imageUrl;
    canvas.style.cursor = cursors.eyeDropper;
    canvas.classList.add('canvas-for-eyedropper');
    canvas.width = width;
    canvas.height = height;
    document.querySelector('.scene-container').appendChild(canvas);
    canvas.addEventListener('click', this.deactivateEyeDropper);
    canvas.addEventListener('mousemove', this.saveColor);
    this.eyeDropperIsStarting = false;
    this.eyeDropperActive = true;
  };

  deactivateEyeDropper = (e, color) => {
    e.stopPropagation();
    /* remove event listeners */
    const canvas = document.querySelector('.canvas-for-eyedropper');
    if (canvas) {
      canvas.removeEventListener('click', this.deactivateEyeDropper);
      canvas.removeEventListener('mousemove', this.saveColor);
    }
    window.removeEventListener('keyup', this.handleShortcuts);
    /*send the new color or the initial color if operation is canceled */
    this.props.updateBannerStatus({ isEyeDropping: false });
    this.props.onColorPicked(color ? color : this.state.color);
    /*deactivate eyedropper*/
    if (document.querySelector('.scene-container') && canvas) {
      document.querySelector('.scene-container').removeChild(canvas);
    }
    this.setState(initialState);
  };

  saveColor = e => {
    const { onColorSearching } = this.props;

    const x = e.layerX;
    const y = e.layerY;
    const context = e.target.getContext('2d');
    const data = context.getImageData(x, y, 1, 1).data;
    const color = `rgba(${data[0]}, ${data[1]}, ${data[2]}, 1)`;

    onColorSearching(color);
    this.setState({ color });
  };

  render() {
    const { top, left, canvas } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.cursor} style={{ top, left }} />
        <ToggleButton
          classes={{ root: classes.eyeDropperButton }}
          value="eyedropper"
          disabled={canvas !== undefined}
          onClick={this.activateEyeDropper}
        >
          <EyeDropper />
        </ToggleButton>
      </React.Fragment>
    );
  }
}

const makeStateToProps = () => {
  const stateToProps = state => {
    const slidesOpen = makeSelectorInstance(isSlidesListOpen)(state);
    const elementListOpen = makeSelectorInstance(isElementListOpen)(state);
    const propertyEditorOpen = makeSelectorInstance(isPropertyEditorOpen)(state);
    const userFonts = makeSelectorInstance(getUserFonts)(state);
    const usedFontsFamilies = makeSelectorInstance(getUsedFontsFamilies)(state);
    return {
      slidesOpen,
      elementListOpen,
      propertyEditorOpen,
      userFonts,
      usedFontsFamilies,
    };
  };
  return stateToProps;
};

const dispatchToProps = {
  updateBannerStatus,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  withStyles(styles),
)(EyeDropperComponent);
