import fetchHtml5ToGif, { apiKey, clientId, convertPath } from './fetchHtml5ToGif';

const fetchHtml5ToGifConvert = params =>
  fetchHtml5ToGif({
    method: 'POST',
    path: convertPath,
    params: {
      ...params,
      fps: 6,
      animatefps: 6,
      quality: 1,
      outputtype: 'GIF',
      clientid: clientId,
      apikey: apiKey,
    },
  }).then(res => res.json());

export default fetchHtml5ToGifConvert;
