import { corners } from '../../../reference/corners';
import { minWidth } from '../../../reference/dimensions';

export const calculateNewPosition = ({
  minHeight,
  x,
  y,
  newDimensions,
  fixedCorner,
  newFixedCornerPositionNonRotated,
}) => {
  let newX = null;
  let newY = null;

  switch (fixedCorner) {
    case corners.middleRight:
    case corners.topRight: {
      newX = newDimensions.width <= minWidth ? x : newFixedCornerPositionNonRotated.x - newDimensions.width;
      break;
    }
    case corners.bottomLeft:
    case corners.middleLeft:
    case corners.topLeft: {
      newX = newDimensions.width <= minWidth ? x : newFixedCornerPositionNonRotated.x;
      break;
    }
    case corners.bottomRight: {
      newX = newFixedCornerPositionNonRotated.x - newDimensions.width;
      break;
    }
    case corners.middleTop:
    case corners.middleBottom: {
      newX = newDimensions.width <= minWidth ? x : newFixedCornerPositionNonRotated.x - newDimensions.width / 2;
      break;
    }
    default:
      break;
  }

  switch (fixedCorner) {
    case corners.middleTop:
    case corners.topLeft:
    case corners.topRight: {
      newY = newDimensions.height <= minHeight ? y : newFixedCornerPositionNonRotated.y;
      break;
    }
    case corners.bottomRight: {
      newY = newFixedCornerPositionNonRotated.y - newDimensions.height;
      break;
    }
    case corners.middleBottom:
    case corners.bottomLeft: {
      newY = newDimensions.height <= minHeight ? y : newFixedCornerPositionNonRotated.y - newDimensions.height;
      break;
    }
    case corners.middleRight:
    case corners.middleLeft: {
      newY = newDimensions.height <= minHeight ? y : newFixedCornerPositionNonRotated.y - newDimensions.height / 2;
      break;
    }
    default:
      break;
  }

  return {
    x: newX,
    y: newY,
  };
};
