import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogActions, withStyles } from '@material-ui/core';
import classnames from 'classnames';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { STRATEGY_SSO_LOGIN_COMPULSORY } from '../reference/strategies';
import elementIcons from '../reference/elementIcons';
import {
  isOpenBannerSetFromNuxeoOpen,
  isUserAuthenticatedAndAppLoaded,
  isAuthError,
  checkIfNoBanners,
} from '../shared-selectors/sharedSelectors';
import {
  openOpenBannerSetDialogRequested,
  isImportingBannerSet,
  getOpenBannerSetDialogImportFromComputer,
} from './openBannerSetDucks';
import {
  openFormatSelectorDialog,
  getBannerFormatDialogOpen,
} from '../banner-format-selector/bannerFormatSelectorDucks';
import AddButton from '../components/AddButton';
import { isTranslationLoaded } from '../i18n/i18nDucks';

const styles = theme => ({
  paper: {
    width: 600,
  },
  paddingBottom: {
    paddingBottom: theme.spacing(4),
  },
  authError: {
    '& em': {
      color: 'red',
      fontStyle: 'normal',
      fontWeight: '400',
    },
  },
  actions: {
    justifyContent: 'center',
  },
});

const AdBuilderWelcomeDialog = ({
  openOpenBannerSetDialog,
  openFormatSelectorDialog,
  openOpenBannerFromComputerDialog,
  noBanners,
  formatDialogOpen,
  openBannerDialogOpen,
  importingBannerSet,
  importBannerDialogOpen,
  authenticatedAndLoaded,
  authError,
  classes,
  translationLoaded,
}) => {
  let message = 'banner.open.welcome-not-authenticated';
  if (authenticatedAndLoaded) message = 'banner.open.welcome';
  if (authError) message = 'banner.open.welcome-authenticated-error';

  return (
    <Dialog
      key={translationLoaded}
      open={noBanners && !formatDialogOpen && !openBannerDialogOpen && !importingBannerSet && !importBannerDialogOpen}
      disableBackdropClick
      disableEscapeKeyDown
      classes={{
        paperWidthSm: classes.paper,
        paperWidthMd: classes.paper,
        paperWidthXs: classes.paper,
      }}
    >
      <DialogTitle
        classes={{
          root: classnames(!authenticatedAndLoaded && classes.paddingBottom, authError && classes.authError),
        }}
      >
        <FormattedHTMLMessage id={message} />
      </DialogTitle>
      {(authenticatedAndLoaded || !STRATEGY_SSO_LOGIN_COMPULSORY) &&
        !authError && (
          <DialogActions
            classes={{
              root: classes.actions,
            }}
          >
            <AddButton onClick={openFormatSelectorDialog}>
              {elementIcons.importBannerSet}
              <FormattedMessage id="app.menu.bannerSetNew.label" />
            </AddButton>
            <AddButton onClick={openOpenBannerSetDialog}>
              {elementIcons.openBannerSet}
              <FormattedMessage id="app.menu.bannerOpen.label" />
            </AddButton>
            <AddButton onClick={openOpenBannerFromComputerDialog}>
              {elementIcons.newBannerSet}
              <FormattedMessage id="app.menu.bannerImport.label" />
            </AddButton>
          </DialogActions>
        )}
    </Dialog>
  );
};

const makeStateToProps = () => state => ({
  translationLoaded: makeSelectorInstance(isTranslationLoaded)(state),
  formatDialogOpen: makeSelectorInstance(getBannerFormatDialogOpen)(state),
  openBannerDialogOpen: makeSelectorInstance(isOpenBannerSetFromNuxeoOpen)(state),
  importBannerDialogOpen: makeSelectorInstance(getOpenBannerSetDialogImportFromComputer)(state),
  noBanners: makeSelectorInstance(checkIfNoBanners)(state),
  authenticatedAndLoaded: makeSelectorInstance(isUserAuthenticatedAndAppLoaded)(state),
  authError: makeSelectorInstance(isAuthError)(state),
  importingBannerSet: makeSelectorInstance(isImportingBannerSet)(state),
});

const dispatchToProps = {
  openOpenBannerFromComputerDialog: () => openOpenBannerSetDialogRequested(null, true),
  openOpenBannerSetDialog: () => openOpenBannerSetDialogRequested(),
  openFormatSelectorDialog: () => openFormatSelectorDialog({ isForBannerSetCreation: true }),
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  withStyles(styles),
)(AdBuilderWelcomeDialog);
