import { calculateSlideOwnDelayForTransitionOut } from '.';

export const updateSlideSnapshotsFrame = (framesPerSecond, frameNumber) => (
  { animationProperties },
  { isLastSlide, slide: slideProp, slidesForAnimation, slideIndex, slideDurationForAnimation, animationGlobalDelay },
) => {
  const animationTimeLine = (1 / framesPerSecond) * frameNumber;
  /*the transition of index n is for slides n-1 and n*/

  const slide = slideIndex !== undefined ? slidesForAnimation[slideIndex].slide : slideProp;
  const nextSlide = isLastSlide ? null : slidesForAnimation[slideIndex + 1].slide;
  let animationOwnDelayForTransitionOut = isLastSlide ? 0 : calculateSlideOwnDelayForTransitionOut({ nextSlide });

  const animationDelayOut =
    animationGlobalDelay + (slideDurationForAnimation || slide.duration) - animationOwnDelayForTransitionOut;

  let animationInSnapshot = -0.01; // to have the element positionned while wiating to start animation
  let animationOutSnapshot = 0.01; // if set to 0, the effect above doesn't work

  if (animationTimeLine >= animationGlobalDelay) {
    animationInSnapshot = -animationTimeLine + animationGlobalDelay;
  }
  if (animationTimeLine >= animationDelayOut) {
    animationOutSnapshot = -animationTimeLine + animationDelayOut;
  }

  return {
    animationProperties: {
      ...animationProperties,
      animationDelay: `${animationInSnapshot}s, ${animationOutSnapshot}s`,
    },
  };
};
