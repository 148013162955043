import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { Grid, Button, Tooltip, Tabs, Tab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getBannerFormat } from '../shared-selectors/sharedSelectors';
import {
  setElementTransitionInDuration,
  setElementTransitionOutDuration,
  setElementsTransitionTranslationDistance,
  setElementsTransitionTranslationDirection,
  openDeleteElementsDialog,
} from '../banner/bannerActionsCreators';
import { getSelectedElements, getSelectedSlide } from '../banner/bannerSelectors';
import { transitionTypesVar } from '../reference/transitions';
import { getSelectedSlideIndex, getSelectedElementIds } from '../temporary-status/temporaryStatusDucks';

import {
  checkPrevAndCurrentSituation,
  initTranslationDistances,
  updateTransitionDurationWhenChangingTransitionFromNoneToOther,
  someElementsHasPropertyChecked,
  allElementsHasSameDeepProperty,
  allElementsHasNotThisDeepProperty,
  allElementsHasPropertyChecked,
} from './helpers';

import ExpansionPanel from './ExpansionPanel';

import { styles, staticStyles } from './propertyEditorStyles';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import ElementsPositionX from './properties-elements/ElementsPositionX';
import ElementsPositionY from './properties-elements/ElementsPositionY';
import ElementsRotation from './properties-elements/ElementsRotation';
import ElementsDimensionWidth from './properties-elements/ElementsDimensionWidth';
import ElementsDimensionHeight from './properties-elements/ElementsDimensionHeight';
import ElementsShowOnAllSlides from './properties-elements/ElementsShowOnAllSlides';
import ElementsDisposition from './properties-elements/ElementsDisposition';
import ElementsOpacity from './properties-elements/ElementsOpacity';
import ElementsDuration from './properties-elements/ElementsDuration';
import ElementsTransitionInType from './properties-elements/ElementsTransitionInType';
import ElementsTransitionOutType from './properties-elements/ElementsTransitionOutType';
import ElementsTransitionInTranslationParameters from './properties-elements/ElementsTransitionInTranslationParameters';
import ElementsTransitionOutTranslationParameters from './properties-elements/ElementsTransitionOutTranslationParameters';
import ElementsTransitionInDuration from './properties-elements/ElementsTransitionInDuration';
import ElementsTransitionOutDuration from './properties-elements/ElementsTransitionOutDuration';
import ElementsTransitionInDelay from './properties-elements/ElementsTransitionInDelay';
import ElementsTransitionShowOnAllSlides from './properties-elements/ElementsTransitionShowOnAllSlides';

const updateWithDiff = false;

const expansionTitles = {
  dimensions: { id: 'properties.position-dimensions' },
  appearance: { id: 'properties.appearance' },
  duration: { id: 'properties.duration' },
};

const perfectScrollbarOptions = {
  suppressScrollX: true,
  handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
  wheelSpeed: 0.8,
  wheelPropagation: true,
};

class PropertyEditorElements extends React.PureComponent {
  state = {
    transitionTabIndex: 0,
    classesState: {
      expansionPanel: { details: this.props.classes.transitionPanel },
      transitionAllSlides: { container: this.props.classes.transitionAllSlides },
      transitionTab: {
        root: this.props.classes.transitionTab,
        selected: this.props.classes.tabSelected,
      },
    },
  };

  componentDidUpdate(prevProps) {
    /*initiate translation transition parameters*/
    const {
      setElementsTransitionTranslationDistance,
      setElementsTransitionTranslationDirection,
      setElementTransitionInDuration,
      setElementTransitionOutDuration,
      bannerWidth,
    } = this.props;

    const prevElements = prevProps.elements;
    const currentElements = this.props.elements;
    const prevSlide = prevProps.slide;
    const prevSlideIndex = prevProps.selectedSlideIndex;

    const { showingSameElements } = checkPrevAndCurrentSituation({
      prevElements,
      currentElements,
      prevSlide,
      prevSlideIndex,
    });

    if (showingSameElements) {
      /* 1.1: WAS SHOWING AN ELEMENT - SHOWING THE SAME ELEMENT */
      initTranslationDistances({
        currentElements,
        prevElements,
        setElementsTransitionTranslationDistance,
        setElementsTransitionTranslationDirection,
        bannerWidth,
      });

      /*init duration for transition in from 'none' to any other one*/
      currentElements.forEach((currentElement, ind) => {
        updateTransitionDurationWhenChangingTransitionFromNoneToOther({
          prevElement: prevElements.get(currentElement.id),
          currentElement,
          setElementTransitionInDuration,
          setElementTransitionOutDuration,
        });
      });
    }
  }

  onTransitionTabChange = transitionTabIndex => {
    this.setState({ transitionTabIndex });
  };

  onDeleteClick = () => {
    const { elements, openDeleteElementsDialog, ids } = this.props;
    openDeleteElementsDialog(ids, someElementsHasPropertyChecked(elements, 'showOnAllSlides'));
  };

  render() {
    const { classes, elements, intl } = this.props;

    const { transitionTabIndex, classesState } = this.state;

    return (
      <PerfectScrollbar className={classes.scrollContainer} options={perfectScrollbarOptions}>
        <ExpansionPanel title={intl.formatMessage(expansionTitles.dimensions)}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={10}>
              <ElementsDisposition />
            </Grid>
            <Grid item xs={2} style={staticStyles.textAlignRight}>
              <Tooltip title={intl.formatMessage({ id: 'menu.buttons.delete' })}>
                <Button fullWidth className={classes.deleteButton} onClick={this.onDeleteClick}>
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <ElementsPositionX shouldUpdateWithDiff={updateWithDiff} />
            </Grid>
            <Grid item xs={4}>
              <ElementsPositionY shouldUpdateWithDiff={updateWithDiff} />
            </Grid>
            <Grid item xs={4}>
              <ElementsRotation shouldUpdateWithDiff={false} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <ElementsDimensionWidth shouldUpdateWithDiff={updateWithDiff} />
                </Grid>
                <Grid item xs={6}>
                  <ElementsDimensionHeight shouldUpdateWithDiff={updateWithDiff} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ElementsShowOnAllSlides />
            </Grid>
          </Grid>
        </ExpansionPanel>
        <ExpansionPanel title={intl.formatMessage(expansionTitles.appearance)}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <ElementsOpacity />
            </Grid>
          </Grid>
        </ExpansionPanel>
        <ExpansionPanel className={classesState.expansionPanel} title={intl.formatMessage(expansionTitles.duration)}>
          <Grid container spacing={1} alignItems="center" direction="row">
            <Grid item xs={12}>
              <ElementsDuration />
            </Grid>
          </Grid>
        </ExpansionPanel>
        <ExpansionPanel
          title={intl.formatMessage({ id: 'properties.transition' })}
          classes={classesState.expansionPanel}
        >
          {allElementsHasPropertyChecked(elements, 'showOnAllSlides') && (
            <Grid container spacing={2} classes={classesState.transitionAllSlides}>
              <Grid item xs={12}>
                <ElementsTransitionShowOnAllSlides />
              </Grid>
            </Grid>
          )}
          <Tabs
            value={transitionTabIndex}
            onChange={(e, v) => this.onTransitionTabChange(v)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={
                allElementsHasSameDeepProperty(elements, 'transitionIn', 'duration')
                  ? intl.formatMessage(
                      { id: 'properties.transition.in.details' },
                      { duration: allElementsHasSameDeepProperty(elements, 'transitionIn', 'duration') },
                    )
                  : intl.formatMessage({ id: 'properties.transition.in' })
              }
              classes={classesState.transitionTab}
            />
            <Tab
              label={
                allElementsHasSameDeepProperty(elements, 'transitionOut', 'duration')
                  ? intl.formatMessage(
                      { id: 'properties.transition.out.details' },
                      { duration: allElementsHasSameDeepProperty(elements, 'transitionOut', 'duration') },
                    )
                  : intl.formatMessage({ id: 'properties.transition.out' })
              }
              classes={classesState.transitionTab}
            />
          </Tabs>
          <SwipeableViews index={transitionTabIndex} onChangeIndex={this.onTransitionTabChange}>
            <div className={classes.transitionContent}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ElementsTransitionInType />
                </Grid>
                {allElementsHasSameDeepProperty(elements, 'transitionIn', 'type') &&
                  allElementsHasSameDeepProperty(elements, 'transitionIn', 'type') ===
                    transitionTypesVar.translation && (
                    <ElementsTransitionInTranslationParameters transitionTabIndex={transitionTabIndex} />
                  )}
                {allElementsHasNotThisDeepProperty(elements, 'transitionIn', 'type', transitionTypesVar.none) ? (
                  <Grid item xs={6}>
                    <ElementsTransitionInDuration />
                  </Grid>
                ) : (
                  <Grid item xs={6} />
                )}
                <Grid item xs={6}>
                  <ElementsTransitionInDelay />
                </Grid>
              </Grid>
            </div>
            <div className={classes.transitionContent}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ElementsTransitionOutType />
                </Grid>
                {allElementsHasSameDeepProperty(elements, 'transitionOut', 'type') &&
                  allElementsHasSameDeepProperty(elements, 'transitionOut', 'type') ===
                    transitionTypesVar.translation && (
                    <ElementsTransitionOutTranslationParameters transitionTabIndex={transitionTabIndex} />
                  )}
                {allElementsHasNotThisDeepProperty(elements, 'transitionOut', 'type', transitionTypesVar.none) && (
                  <Fragment>
                    <Grid item xs={6}>
                      <ElementsTransitionOutDuration />
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </div>
          </SwipeableViews>
        </ExpansionPanel>
      </PerfectScrollbar>
    );
  }
}

const makeStateToProps = () => state => ({
  elements: makeSelectorInstance(getSelectedElements)(state),
  ids: makeSelectorInstance(getSelectedElementIds)(state),
  slide: makeSelectorInstance(getSelectedSlide)(state),
  bannerWidth: makeSelectorInstance(getBannerFormat)(state).bannerWidth,
  selectedSlideIndex: makeSelectorInstance(getSelectedSlideIndex)(state),
});

const dispatchToProps = {
  setElementTransitionInDuration,
  setElementTransitionOutDuration,
  setElementsTransitionTranslationDistance,
  setElementsTransitionTranslationDirection,
  openDeleteElementsDialog,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(PropertyEditorElements);
