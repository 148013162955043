import JSZip from 'jszip';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import fetchHtml5ToGif from '../api/fetchHtml5ToGif';
import { BANNER_SET_THUMBNAIL } from '../banner/bannerActions';
import { getBannerDurationForAnimatedGIF } from '../banner/bannerSelectors';
import { BANNERSET_SET_THUMBNAIL } from '../banners/bannersActions';
import { backupBannerSaga, saveBannerSetSaga } from '../banners/bannersSaga';
import { getBannerSetName, getBannersFromUniqueIdentifications } from '../banners/bannersSelectors';
import { addError, addInfo } from '../messages/messagesDucks';
import { GIF as GIFType, JPG, PNG } from '../reference/exportParams';
import { htmlName } from '../reference/filesNames';
import { getUserFonts } from '../shared-selectors/sharedSelectors';
import {
  EXPORT_BANNERSETS_DIALOG_CLOSE,
  EXPORT_BANNERSETS_DIALOG_OPEN,
  EXPORT_BANNERSETS_DONE,
  EXPORT_BANNERSETS_ITEM_RENDERED_FOR_EXPORT,
  EXPORT_BANNERSETS_LOAD_PROGRESS_BAR,
  EXPORT_BANNERSETS_PROGRESS_BAR_LOADED,
  EXPORT_BANNERSETS_RENDER_BANNERS_FOR_ANIMATED_GIF,
  EXPORT_BANNERSETS_RENDER_CURRENT_SCENE,
  EXPORT_BANNERSETS_RENDER_SLIDES,
  EXPORT_BANNERSETS_SET_PROGRESS,
  EXPORT_TO_ANIMATED_GIF_REQUESTED,
  EXPORT_TO_HTML_ANIMATION,
  EXPORT_TO_HTML_ANIMATION_REQUESTED,
  EXPORT_TO_IMAGES_REQUESTED,
  EXPORT_TO_ZIP_REQUESTED,
} from './exportActions';
import {
  bannerElementsFilenamesRandomiser,
  checkGifSize,
  computeBannerName,
  domtoimageExecutionContext,
  newBannerFormData,
  newZip,
  toGIF,
  toJPG,
  toPNG,
} from './exportHelpers';
import { makeSlidesToExportToImages } from './exportSelectors';
import {
  exportBannerSetToZip,
  exportBannerToPlayer,
  getBannerDataToZip,
  saveBlob,
  zipFontResources,
  zipImageResourcesBanner,
} from './import-export-helpers';

// Action creators
export const openExportDialog = () => ({
  type: EXPORT_BANNERSETS_DIALOG_OPEN,
});

export const closeExportDialog = () => ({
  type: EXPORT_BANNERSETS_DIALOG_CLOSE,
});

export const exportBannersToImages = (bannersUniqueIds, fileName, imageType) => ({
  type: EXPORT_TO_IMAGES_REQUESTED,
  payload: {
    bannersUniqueIds,
    fileName,
    imageType,
  },
});

export const exportBannersToAnimatedGIF = (bannersUniqueIds, fileName) => ({
  type: EXPORT_TO_ANIMATED_GIF_REQUESTED,
  payload: {
    bannersUniqueIds,
    fileName,
  },
});

export const exportBannersToHTMLAnimation = (bannersUniqueIds, fileName) => ({
  type: EXPORT_TO_HTML_ANIMATION_REQUESTED,
  payload: {
    bannersUniqueIds,
    fileName,
  },
});

export const exportBannersToZIP = (bannersUniqueIds, name) => ({
  type: EXPORT_TO_ZIP_REQUESTED,
  payload: {
    name,
    bannersUniqueIds,
  },
});

export const renderSlides = slidesToExportToImages => ({
  type: EXPORT_BANNERSETS_RENDER_SLIDES,
  payload: slidesToExportToImages,
});

export const bannerAnimationExport = (DOMNode, animationProperties) => ({
  type: EXPORT_TO_HTML_ANIMATION,
  payload: {
    DOMNode,
    animationProperties,
  },
});

export const takeCurrentSceneScreenshot = bannerToTakeScreenshot => ({
  type: EXPORT_BANNERSETS_RENDER_CURRENT_SCENE,
  payload: bannerToTakeScreenshot,
});

export const setProgressBar = () => ({
  type: EXPORT_BANNERSETS_LOAD_PROGRESS_BAR,
});

export const progressBarHasLoaded = () => ({
  type: EXPORT_BANNERSETS_PROGRESS_BAR_LOADED,
});

export const setProgress = progress => ({
  type: EXPORT_BANNERSETS_SET_PROGRESS,
  payload: progress,
});

export const itemRenderedForExport = ({ DOMNode, width, height, bannerName, backgroundColor, transparentColor }) => ({
  type: EXPORT_BANNERSETS_ITEM_RENDERED_FOR_EXPORT,
  payload: { DOMNode, width, height, bannerName, backgroundColor, transparentColor },
});

export const bannerSetsExported = () => ({
  type: EXPORT_BANNERSETS_DONE,
});

// Sagas

export function* getRenderedDOMNodesSaga(itemsToRender) {
  let numberOfItemsToExport = itemsToRender.size;
  const DOMNodes = [];

  while (numberOfItemsToExport > 0) {
    const {
      payload: { DOMNode, width, height, bannerName },
    } = yield take(EXPORT_BANNERSETS_ITEM_RENDERED_FOR_EXPORT);
    DOMNodes.push({ DOMNode, width, height, bannerName });
    numberOfItemsToExport--;
  }
  return DOMNodes;
}

export function* zipBannerSaga(banner, zip) {
  const userFonts = yield select(getUserFonts);
  const { userFontNames, jsBanner, customFontNames } = yield call(getBannerDataToZip, banner, userFonts);
  yield call(zipFontResources, userFontNames, userFonts, zip, customFontNames);
  const player = yield call(exportBannerToPlayer, bannerElementsFilenamesRandomiser(jsBanner));
  zip.file(htmlName, player);
  yield call(zipImageResourcesBanner, jsBanner, zip);
}

export function* exportSlidesToImagesSaga({ payload: { bannersUniqueIds, fileName, imageType, animated = false } }) {
  yield call(backupBannerSaga);
  const slidesToExportToImages = yield select(makeSlidesToExportToImages(bannersUniqueIds));
  yield put(renderSlides(slidesToExportToImages));
  const slidesDOMNodes = yield call(getRenderedDOMNodesSaga, slidesToExportToImages);
  const zip = new JSZip();
  const zipName = `${fileName}.${imageType}-images.zip`;

  yield* domtoimageExecutionContext(function* () {
    let bannerImagesFolders = {};

    for (let slideDOMNode of slidesDOMNodes) {
      const { DOMNode, width, height, bannerName } = slideDOMNode;
      // Define the folder where to put the image
      let currentImagesFolder;
      if (bannerImagesFolders[bannerName] && bannerImagesFolders[bannerName].size > 0) {
        currentImagesFolder = bannerImagesFolders[bannerName].folder;
        bannerImagesFolders[bannerName].size = bannerImagesFolders[bannerName].size + 1;
      } else {
        currentImagesFolder = zip.folder(`${bannerName}`);
        bannerImagesFolders[bannerName] = {
          folder: currentImagesFolder,
          size: 1,
        };
      }
      const ind = bannerImagesFolders[bannerName].size;
      const imageName = `${bannerName}-${ind}`;
      // Build the image from the DOM Node
      switch (imageType) {
        case JPG: {
          yield call(toJPG, DOMNode, currentImagesFolder, imageName);
          break;
        }
        case PNG: {
          yield call(toPNG, DOMNode, currentImagesFolder, imageName);
          break;
        }
        case GIFType: {
          if (animated) break;
          yield call(toGIF, DOMNode, width, height, currentImagesFolder, imageName);
          break;
        }
        default:
          break;
      }
    }
  });

  zip.generateAsync({ type: 'blob' }).then(zipBlob => saveBlob(zipName, zipBlob));
  yield put(bannerSetsExported());
}

export function* convertBannersThroughHTMLTOGIFSaga({ payload: { bannersUniqueIds } }) {
  /* IMPORTANT: not working now, because no token bought by DrBanner */
  yield call(backupBannerSaga);
  yield put(setProgressBar());
  yield take(EXPORT_BANNERSETS_PROGRESS_BAR_LOADED);

  try {
    const fullBanners = yield select(getBannersFromUniqueIdentifications(bannersUniqueIds));
    const banners = fullBanners.map(banner => banner.present);
    for (let banner of banners) {
      const zip = yield call(newZip);
      yield call(zipBannerSaga, banner, zip);
      const zipBlob = yield zip.generateAsync({ type: 'blob' });
      /* Upload the rendered banner */
      const name = yield call(computeBannerName, banner);
      const formData = yield call(newBannerFormData, name, zipBlob);
      const uploadRes = yield call(fetchHtml5ToGif.upload, formData);

      if (uploadRes.status === 'error') {
        throw new Error('Error uploading the rendered banner to GIF: ' + uploadRes.message);
      }
      if (uploadRes.status !== 'success') {
        throw new Error('Undefined error uploading the rendered banner to GIF');
      }

      /* Convert the banner to GIF */
      const params = {
        url: uploadRes.ziptohtmlurl,
        height: banner.format.height,
        width: banner.format.width,
        duration: getBannerDurationForAnimatedGIF({ banner: { present: banner } }),
        loop: banner.repetitions,
      };

      const convertRes = yield call(fetchHtml5ToGif.convert, params);

      if (convertRes.status === 'error') {
        throw new Error('Error converting the rendered banner to GIF: ' + convertRes.message);
      }
      if (convertRes.status !== 'success') {
        throw new Error('Undefined error converting the rendered banner to GIF');
      }

      const gifUrl = yield call(fetchGifUrlWhenFinishedConvertingSaga, convertRes);

      if (!gifUrl) return;

      const gifBlob = yield call(fetchHtml5ToGif.download, gifUrl);
      yield call(saveBlob, `${name}.gif`, gifBlob);
      const sizeCheck = yield call(checkGifSize, banner.format.name, name, gifBlob);

      if (sizeCheck.status === 'accepted') {
        yield put(
          addInfo('export.gif.sizeAccept.files', {
            res: `${name} - ${Math.round(sizeCheck.gifSize)}Ko`,
          }),
        );
      }
      if (sizeCheck.status === 'rejected') {
        yield put(
          addError('export.gif.sizeError.files', {
            rej: `${name} - ${Math.round(sizeCheck.gifSize)}Ko (max: ${Math.round(sizeCheck.maxSize)}Ko)`,
          }),
        );
      }
    }
  } catch (e) {
    console.error('error while creating GIF', e);
    yield put(addError('export.gif.error'));
  }
  yield put(bannerSetsExported());
}

export function* fetchGifUrlWhenFinishedConvertingSaga(convertRes) {
  try {
    let jobIsFinished = false;

    const checkStatusUrlParams = {
      tokenid: convertRes.tokenid,
    };
    let statusRes;
    let tries = 0;
    let interval = 2;
    const maxTries = 100;

    while (!jobIsFinished && tries < maxTries) {
      tries++;
      yield call(delay, interval * 1000);
      statusRes = yield call(fetchHtml5ToGif.checkStatus, checkStatusUrlParams);
      if (statusRes.status !== 'success' || !statusRes.jobstatus) {
        tries = maxTries;
        break;
      }
      if (statusRes.jobstatus === 'done') {
        jobIsFinished = true;
        break;
      }
      if (!fetchHtml5ToGif.errors.includes(statusRes.jobstatus)) {
        yield put(setProgress(statusRes.jobstatus));
        continue;
      }
      tries = maxTries;
    }

    if (!jobIsFinished) {
      // tried too many time && job not done === error
      yield put(addError('export.gif.error'));
      console.error('error while creating GIF');
      return '';
    }

    return statusRes.url;
  } catch (e) {
    console.error(e);
    return '';
  }
}

export function* exportBannersToHTMLSaga({ payload: { bannersUniqueIds, fileName } }) {
  yield call(backupBannerSaga);
  const zip = yield call(newZip);
  const zipName = `${fileName}.HTMLExport.zip`;
  try {
    const fullBanners = yield select(getBannersFromUniqueIdentifications(bannersUniqueIds));
    const banners = fullBanners.map(banner => banner.present);
    for (let banner of banners) {
      yield call(zipBannerSaga, banner, zip.folder(banner.name));
    }
    zip.generateAsync({ type: 'blob' }).then(zipBlob => saveBlob(zipName, zipBlob));
  } catch (e) {
    console.error('cannot save banner to HTML', e);
  }
  yield put(bannerSetsExported());
}

export function* exportBannersToZIPSaga(action) {
  const readyToExport = yield call(saveBannerSetSaga, action, false);
  const bannerSetName = action.payload ? action.payload.name : yield select(getBannerSetName);
  yield call(exportBannerSetToZip, readyToExport, bannerSetName);
  yield put(bannerSetsExported());
}

export function* saga() {
  yield takeLatest(EXPORT_TO_IMAGES_REQUESTED, exportSlidesToImagesSaga);
  yield takeLatest(EXPORT_TO_ANIMATED_GIF_REQUESTED, convertBannersThroughHTMLTOGIFSaga);
  yield takeLatest(EXPORT_TO_HTML_ANIMATION_REQUESTED, exportBannersToHTMLSaga);
  yield takeLatest(EXPORT_TO_ZIP_REQUESTED, exportBannersToZIPSaga);
}

// Reducer

export const initialState = {
  dialogOpen: false,
  dialogKey: 0,
  renderingSlidesForExportToImages: false,
  renderingBannersForExportToAnimatedGIF: false,
  renderingCurrentSceneForSnapshot: false,
  slidesToExportToImages: undefined,
  bannersToRenderForImageExport: undefined,
  toggleProgressBar: false,
  progress: 'Exporting',
  exporting: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_BANNERSETS_DIALOG_OPEN: {
      return {
        ...state,
        dialogOpen: true,
        dialogKey: state.dialogKey + 1,
      };
    }
    case EXPORT_BANNERSETS_LOAD_PROGRESS_BAR: {
      return {
        ...state,
        toggleProgressBar: true,
      };
    }
    case EXPORT_BANNERSETS_SET_PROGRESS: {
      const percent = parseInt(action.payload, 10);
      return {
        ...state,
        progress: isNaN(percent) ? action.payload : percent,
      };
    }
    case EXPORT_BANNERSETS_RENDER_SLIDES: {
      return {
        ...state,
        renderingSlidesForExportToImages: true,
        slidesToExportToImages: action.payload,
      };
    }
    case EXPORT_BANNERSETS_RENDER_CURRENT_SCENE: {
      return {
        ...state,
        renderingCurrentSceneForSnapshot: true,
        bannersToRenderForImageExport: action.payload,
      };
    }
    case EXPORT_BANNERSETS_RENDER_BANNERS_FOR_ANIMATED_GIF: {
      return {
        ...state,
        renderingBannersForExportToAnimatedGIF: true,
        bannersToRenderForImageExport: action.payload,
      };
    }
    case BANNER_SET_THUMBNAIL:
    case BANNERSET_SET_THUMBNAIL: {
      return {
        ...state,
        renderingCurrentSceneForSnapshot: false,
        bannersToRenderForImageExport: undefined,
      };
    }
    case EXPORT_TO_HTML_ANIMATION_REQUESTED:
    case EXPORT_TO_ZIP_REQUESTED:
    case EXPORT_TO_IMAGES_REQUESTED: {
      return {
        ...state,
        exporting: true,
      };
    }
    case EXPORT_BANNERSETS_DONE: {
      return {
        ...initialState,
        dialogKey: state.dialogKey + 1,
      };
    }
    case EXPORT_BANNERSETS_DIALOG_CLOSE: {
      return {
        ...state,
        dialogOpen: false,
        dialogKey: state.dialogKey + 1,
        exporting: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
