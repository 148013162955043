import autoHideDurations from '../../reference/messagesDurations';
import { elementLabel } from '../../element/elementLabel';
import { transitionTypesVar } from '../../reference/transitions';
import { checkIfIsMapAndConvertIfNeeded } from './';

const handleElementsTryingToChangeTheirTransitionDurationToPositive = (
  elementsTryingToChangeTheirTransitionDurationToPositive,
  props,
) => {
  if (!elementsTryingToChangeTheirTransitionDurationToPositive.length) return;
  const { setElementsTransitionInDuration, addWarning } = props;
  if (elementsTryingToChangeTheirTransitionDurationToPositive.length === 1) {
    addWarning('properties.transition.in.duration.warning.isPuttingTransitionDurationInTransitionNone', {
      name: elementsTryingToChangeTheirTransitionDurationToPositive.map(el => elementLabel(el))[0],
      autoHideDuration: autoHideDurations.veryLongMessage,
    });
  } else {
    addWarning('properties.transition.in.duration.warning.arePuttingTransitionDurationInTransitionNone', {
      names: elementsTryingToChangeTheirTransitionDurationToPositive.map(el => elementLabel(el)).join(', '),
      autoHideDuration: autoHideDurations.veryLongMessage,
    });
  }
  setElementsTransitionInDuration({
    ids: elementsTryingToChangeTheirTransitionDurationToPositive.map(el => el.id),
    duration: 0,
    untrack: true,
  });
};

const handleElementsTryingToChangeTheirTransitionDurationToZero = (
  elementsTryingToChangeTheirTransitionDurationToZero,
  props,
) => {
  const { setElementsTransitionInType, addWarning } = props;
  if (!elementsTryingToChangeTheirTransitionDurationToZero.length) return;
  if (elementsTryingToChangeTheirTransitionDurationToZero.length === 1) {
    addWarning('properties.transition.in.duration.warning.isChangingTransitionType', {
      name: elementsTryingToChangeTheirTransitionDurationToZero.map(el => elementLabel(el))[0],
      autoHideDuration: autoHideDurations.veryLongMessage,
    });
  } else {
    addWarning('properties.transition.in.duration.warning.areChangingTransitionType', {
      names: elementsTryingToChangeTheirTransitionDurationToZero.map(el => elementLabel(el)).join(', '),
      autoHideDuration: autoHideDurations.veryLongMessage,
    });
  }
  setElementsTransitionInType({
    ids: elementsTryingToChangeTheirTransitionDurationToZero.map(el => el.id),
    type: 'none',
    untrack: true,
  });
};

const handleElementsChangingTheirDurationAndSlidesDuration = (
  elementsChangingTheirDurationAndCurrentSlideDurationOnly,
  elementsChangingTheirDurationAndSlidesDuration,
  slidesChangingTheirDuration,
  longestDurationOfElementShownOnAllSlide,
  longestDurationOfElementNotShownOnAllSlide,
  props,
) => {
  const { slide, selectedSlideIndex, setSlideDuration, setSlidesDuration } = props;

  if (
    !elementsChangingTheirDurationAndSlidesDuration.length &&
    elementsChangingTheirDurationAndCurrentSlideDurationOnly.length
  ) {
    // if no element on all slide is affecting slide duration, but still some elements affect the current slide
    setSlideDuration({
      index: selectedSlideIndex,
      duration: longestDurationOfElementNotShownOnAllSlide,
      untrack: true,
    });
    return;
  }
  if (!elementsChangingTheirDurationAndSlidesDuration.length) return;
  // if an element is on all slides and is affecting some slides duration :
  if (!elementsChangingTheirDurationAndCurrentSlideDurationOnly.length) {
    // if there is no element changing the current slide only,
    setSlidesDuration({
      indexes: [...slidesChangingTheirDuration],
      duration: longestDurationOfElementShownOnAllSlide,
      untrack: true,
    });
    return;
  }
  // if an element is only on the current slide and is also affecting some slides duration

  if (longestDurationOfElementShownOnAllSlide < longestDurationOfElementNotShownOnAllSlide) {
    // in this case, the current slide won't have the same change of duration than the other slides
    setSlideDuration({
      index: selectedSlideIndex,
      duration: longestDurationOfElementNotShownOnAllSlide,
      untrack: true,
    });
    setSlidesDuration({
      indexes: [...slidesChangingTheirDuration].filter(index => index !== slide.id),
      duration: longestDurationOfElementShownOnAllSlide,
      untrack: true,
    });
    return;
  }
  // in that case, all the slides that change their duration have the same change
  setSlidesDuration({
    indexes: [...slidesChangingTheirDuration],
    duration: longestDurationOfElementShownOnAllSlide,
    untrack: true,
  });
};

export const verifyElementsTransitionInDuration = props => {
  const { slide, slides, elements, setElementDuration, addWarning } = props;

  const elementsToIterate = checkIfIsMapAndConvertIfNeeded(elements);
  let elementsChangingTheirDuration = [];
  let elementsTryingToChangeTheirTransitionDurationToZero = [];
  let elementsTryingToChangeTheirTransitionDurationToPositive = [];
  let elementsChangingTheirDurationAndCurrentSlideDurationOnly = [];
  let elementsChangingTheirDurationAndSlidesDuration = [];
  let slidesChangingTheirDuration = new Set();
  let longestDurationOfElementShownOnAllSlide = 0;
  let longestDurationOfElementNotShownOnAllSlide = 0;

  elementsToIterate.forEach(element => {
    //MANY CASES !!
    // if the transition of the element is none while the other are not none, its transition duration will stay at 0
    // if the new transition is 0 and its transition is not none, its transition must be none now
    // If the element is shown on all slides, its new duration can affect the other slides while not affecting the current slide...
    // Let's decompose this...
    const newElementPotentialDuration =
      element.transitionIn.delay + element.transitionOut.duration + element.transitionIn.duration;
    // (if the new potential duration is smaller than the duration, then this element has no influence on anything else.)

    if (element.transitionIn.type === transitionTypesVar.none && element.transitionIn.duration !== 0) {
      // if the transition is none, the duration MUST be 0
      elementsTryingToChangeTheirTransitionDurationToPositive.push(element);
    } else if (element.transitionIn.duration === 0 && element.transitionIn.type !== transitionTypesVar.none) {
      // if the duration is 0, the transition MUST be none
      elementsTryingToChangeTheirTransitionDurationToZero.push(element);
    } else if (newElementPotentialDuration > element.duration) {
      // if the potential duration is greater than the original duration :
      setElementDuration({ id: element.id, duration: newElementPotentialDuration, untrack: true });
      elementsChangingTheirDuration.push(element);

      if (element.showOnAllSlides) {
        // add elements and slides affected by this new transition duration

        if (newElementPotentialDuration === longestDurationOfElementShownOnAllSlide) {
          elementsChangingTheirDurationAndSlidesDuration.push(element);
        } else if (newElementPotentialDuration > longestDurationOfElementShownOnAllSlide) {
          elementsChangingTheirDurationAndSlidesDuration = [element];
          longestDurationOfElementShownOnAllSlide = newElementPotentialDuration;
          slides.forEach(slide => {
            if (slide.duration < longestDurationOfElementShownOnAllSlide) {
              slidesChangingTheirDuration.add(slide.id);
            }
          });
        } else {
          elementsChangingTheirDuration.push(element);
        }
      } else {
        // add elements affecting this slide duration
        if (newElementPotentialDuration > longestDurationOfElementNotShownOnAllSlide) {
          longestDurationOfElementNotShownOnAllSlide = newElementPotentialDuration;
          if (longestDurationOfElementNotShownOnAllSlide > slide.duration) {
            elementsChangingTheirDurationAndCurrentSlideDurationOnly = [element];
            slidesChangingTheirDuration.add(slide.id);
          }
        } else if (newElementPotentialDuration === longestDurationOfElementNotShownOnAllSlide) {
          elementsChangingTheirDurationAndCurrentSlideDurationOnly.push(element);
        }
      }
    }
  });

  // SO: case by case

  //First, easy : with duration > 0 and transition none...
  handleElementsTryingToChangeTheirTransitionDurationToPositive(
    elementsTryingToChangeTheirTransitionDurationToPositive,
    props,
  );
  // or with duration === 0 and transition not none.
  handleElementsTryingToChangeTheirTransitionDurationToZero(elementsTryingToChangeTheirTransitionDurationToZero, props);

  //Then, more complexe, regarding the slide duration :
  handleElementsChangingTheirDurationAndSlidesDuration(
    elementsChangingTheirDurationAndCurrentSlideDurationOnly,
    elementsChangingTheirDurationAndSlidesDuration,
    slidesChangingTheirDuration,
    longestDurationOfElementShownOnAllSlide,
    longestDurationOfElementNotShownOnAllSlide,
    props,
  );
  // error messages simplified
  if (slidesChangingTheirDuration.size > 0 || elementsChangingTheirDuration.length > 0) {
    addWarning('properties.transition.in.duration.warning.areChangingElementsOrSlidesDuration', {
      elements:
        elementsChangingTheirDuration.length > 0
          ? elementsChangingTheirDuration.map(el => elementLabel(el)).join(', ')
          : 0,
      slides: slidesChangingTheirDuration.size > 0 ? [...slidesChangingTheirDuration].join(', ') : 0,
      autoHideDuration: autoHideDurations.veryLongMessage,
    });
  }
};
