import React from 'react';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import classNames from 'classnames';
import keyboardShortcuts from '../reference/keyboardShortcuts';
import MenuCopyToSlides from './MenuCopyToSlides';
import MenuCopyToBanners from './MenuCopyToBanners';

import { updateElementIndex } from '../banner/bannerActionsCreators';
import { getBannerNumberOfSlides, getSelectedSlideElementIds } from '../banner/bannerSelectors';
import { isColorPickerOpen, getTemporaryMenuOrModalOpen, checkIfNoBanners } from '../shared-selectors/sharedSelectors';
import { isElementListOpen, isSlidesListOpen } from '../layout/layoutDucks';
import {
  isBannerPlayingHTMLAnimation,
  isBannerPlayingGIFAnimation,
  selectElement,
  unselectElement,
  getSelectedElementId,
  getSelectedElementIds,
  getSelectedSlideIndex,
  selectMultipleElements,
  unselectMultipleElements,
  getBannerTextIsSelected,
} from '../temporary-status/temporaryStatusDucks';

import styles from './elementListStyles';

import SortableElementList from './SortableElementList';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { duplicateElementRequested } from '../banners/bannersActionsCreators';
import { selectedBannerCanCopySomethingOnOtherBanners } from '../banners/bannersSelectors';
import { toggleMenuOrModalOpen } from '../temporary-text-editor/temporaryTextEditorDucks';

class ElementList extends React.PureComponent {
  state = {
    menuAnchorEl: null,
    menuElement: null,
    dragging: false,
    multipleSelectEnabled: false,
  };

  componentDidMount() {
    /* multiple select */
    window.addEventListener('keydown', this.handleShortcuts);
    window.addEventListener('keyup', this.handleShortcuts);
    window.addEventListener('blur', this.handleShortcuts);
  }

  componentWillUnmount() {
    /* multiple select */
    window.removeEventListener('keydown', this.handleShortcuts);
    window.removeEventListener('keyup', this.handleShortcuts);
    window.removeEventListener('blur', this.handleShortcuts);
  }

  handleShortcuts = e => {
    /* multiple select */
    if (this.props.colorPickerIsOpen || this.props.menuOrModalOpen) {
      return;
    }
    if (keyboardShortcuts.multipleSelect.includes(e.key)) {
      if (e.type === 'keydown') {
        this.setState({ multipleSelectEnabled: true });
      } else {
        this.setState({ multipleSelectEnabled: false });
      }
    }
    /* select all elements */
    if (keyboardShortcuts.selectAll.includes(e.key) && !this.props.textIsSelected && this.state.multipleSelectEnabled) {
      e.preventDefault();
      this.props.selectMultipleElements(this.props.elementIds);
    }
    if (e.type === 'blur') {
      this.setState({ multipleSelectEnabled: false });
    }
  };

  onMenuOpen = menuElement => e => {
    e.stopPropagation();
    this.props.toggleMenuOrModalOpen(true);
    this.setState({ menuAnchorEl: e.currentTarget, menuElement });
  };

  onMenuClose = () => {
    this.props.toggleMenuOrModalOpen(false);
    this.setState({ menuAnchorEl: null, menuElement: null });
  };

  onItemClick = id => {
    const { selectElement, selectMultipleElements, unselectMultipleElements, selectedElementIds } = this.props;
    if (this.state.multipleSelectEnabled) {
      selectedElementIds.includes(id) ? unselectMultipleElements([id]) : selectMultipleElements([id]);
    } else {
      selectElement(id);
    }
  };
  onListClick = id => {
    const { selectedElementId, selectedElementIds, unselectElement, unselectMultipleElements } = this.props;
    if (selectedElementId !== null) {
      unselectElement();
    }
    if (selectedElementIds.size > 0) {
      unselectMultipleElements(selectedElementIds);
    }
  };
  handleSortStart = () => this.setState({ dragging: true });
  handleSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({ dragging: false });
    const { onSort, selectedElementId, selectedSlideIndex } = this.props;
    onSort({ oldIndex, newIndex, id: selectedElementId, slideIndex: selectedSlideIndex });
  };

  render() {
    const {
      classes,
      elementIds,
      bannerIsPlayingHTMLAnimation,
      bannerIsPlayingGIFAnimation,
      onDuplicateClick,
      open,
      nbSlides,
      selectedSlideIndex,
      noBanners,
      slidesListOpen,
      intl,
      canCopyOnOtherBanners,
    } = this.props;
    const { menuAnchorEl, menuElement, dragging } = this.state;
    const formattedSlideNumber = intl.formatNumber(selectedSlideIndex + 1, { minimumIntegerDigits: 2 });

    return (
      <div
        className={classNames(
          classes.root,
          !open && classes.folded,
          (!slidesListOpen || bannerIsPlayingHTMLAnimation || bannerIsPlayingGIFAnimation) && classes.foldedSlides,
        )}
      >
        {!noBanners && (
          <SortableElementList
            elementIds={elementIds}
            formattedSlideNumber={formattedSlideNumber}
            onMenuOpen={this.onMenuOpen}
            onItemClick={this.onItemClick}
            onListClick={this.onListClick}
            dragging={dragging}
            onSortStart={this.handleSortStart}
            onSortEnd={this.handleSortEnd}
            lockAxis="y"
            useDragHandle={true}
            helperClass={classes.draggingItem}
          />
        )}
        {canCopyOnOtherBanners ? (
          <MenuCopyToBanners
            menuAnchorEl={menuAnchorEl}
            id={menuElement}
            selectedSlideIndex={selectedSlideIndex}
            onDuplicateClick={onDuplicateClick}
            onMenuClose={this.onMenuClose}
          />
        ) : (
          <MenuCopyToSlides
            menuAnchorEl={menuAnchorEl}
            id={menuElement}
            nbSlides={nbSlides}
            selectedSlideIndex={selectedSlideIndex}
            onDuplicateClick={onDuplicateClick}
            onMenuClose={this.onMenuClose}
          />
        )}
      </div>
    );
  }
}

const makeStateToProps = () => state => ({
  elementIds: makeSelectorInstance(getSelectedSlideElementIds)(state),
  noBanners: makeSelectorInstance(checkIfNoBanners)(state),
  selectedElementId: makeSelectorInstance(getSelectedElementId)(state),
  selectedElementIds: makeSelectorInstance(getSelectedElementIds)(state),
  open: makeSelectorInstance(isElementListOpen)(state),
  slidesListOpen: makeSelectorInstance(isSlidesListOpen)(state),
  bannerIsPlayingHTMLAnimation: makeSelectorInstance(isBannerPlayingHTMLAnimation)(state),
  bannerIsPlayingGIFAnimation: makeSelectorInstance(isBannerPlayingGIFAnimation)(state),
  nbSlides: makeSelectorInstance(getBannerNumberOfSlides)(state),
  selectedSlideIndex: makeSelectorInstance(getSelectedSlideIndex)(state),
  colorPickerIsOpen: makeSelectorInstance(isColorPickerOpen)(state),
  canCopyOnOtherBanners: makeSelectorInstance(selectedBannerCanCopySomethingOnOtherBanners)(state),
  textIsSelected: makeSelectorInstance(getBannerTextIsSelected)(state),
  menuOrModalOpen: makeSelectorInstance(getTemporaryMenuOrModalOpen)(state),
});

const dispatchToProps = {
  selectElement,
  unselectElement,
  selectMultipleElements,
  unselectMultipleElements,
  onDuplicateClick: duplicateElementRequested,
  onSort: updateElementIndex,
  toggleMenuOrModalOpen,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(ElementList);
