import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, onlyUpdateForKeys } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import {
  getBannerSlides,
  getSelectedElement,
  getSelectedSlide,
  getSelectedElementTransitionInDuration,
} from '../../banner/bannerSelectors';
import {
  setElementDuration,
  setElementsTransitionInType,
  setElementTransitionInDuration,
  setElementsTransitionInDuration,
  setSlidesDuration,
  setSlideDuration,
} from '../../banner/bannerActionsCreators';
import { durationInputProps, secondEndornment, parseFloatHelper } from '../constants';
import { addWarning } from '../../messages/messagesDucks';
import { verifyElementsTransitionInDuration } from '../helpers';
import { getSelectedSlideIndex, getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementTransitionInDuration extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { slide, slides, selectedSlideIndex, element } = nextProps;
    return {
      durationAdditionalData: {
        slide,
        slides,
        selectedSlideIndex,
        elementSelected: element,
      },
    };
  }

  onTransitionInDurationChange = ({ value: duration, id }) =>
    this.props.setElementTransitionInDuration({ duration, id }); //tracked
  onTransitionInDurationVerify = ({ additionalData: { slide, slides, selectedSlideIndex, elementSelected } }) => {
    const {
      setElementDuration,
      setElementsTransitionInType,
      setElementsTransitionInDuration,
      setSlideDuration,
      setSlidesDuration,
      addWarning,
    } = this.props;
    verifyElementsTransitionInDuration({
      slide,
      slides,
      selectedSlideIndex,
      elements: elementSelected,
      setElementDuration,
      setElementsTransitionInType,
      setElementsTransitionInDuration,
      setSlideDuration,
      setSlidesDuration,
      addWarning,
    });
  };

  render() {
    const { id, transitionInDuration, intl } = this.props;
    const { durationAdditionalData } = this.state;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        label={intl.formatMessage({ id: 'properties.transition.duration' })}
        value={transitionInDuration}
        fullWidth
        inputProps={durationInputProps}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={secondEndornment}
        helper={parseFloatHelper}
        additionalData={durationAdditionalData}
        onChangeMethod={this.onTransitionInDurationChange}
        onVerifyMethod={this.onTransitionInDurationVerify}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  transitionInDuration: makeSelectorInstance(getSelectedElementTransitionInDuration)(state),
  element: makeSelectorInstance(getSelectedElement)(state),
  slide: makeSelectorInstance(getSelectedSlide)(state),
  selectedSlideIndex: makeSelectorInstance(getSelectedSlideIndex)(state),
  slides: makeSelectorInstance(getBannerSlides)(state),
});

const dispatchToProps = {
  setElementDuration,
  addWarning,
  setSlideDuration,
  setSlidesDuration,
  setElementTransitionInDuration,
  setElementsTransitionInDuration,
  setElementsTransitionInType,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  onlyUpdateForKeys(['id', 'transitionInDuration']),
  injectIntl,
)(ElementTransitionInDuration);
