import fetchNuxeoBatchId from './fetchNuxeoBatchId';
import fetchNuxeo from './fetchNuxeo';

const fetchNuxeoUploadFile = async ({ file, fileName, fileType = '', fileIdx = 0, batchId }) => {
  if (!file) {
    throw new Error('please give a file');
  }

  if (!fileName) {
    throw new Error('please give a file name');
  }

  // 1. get a batch id
  let batchIdToUse;
  if (batchId === undefined) {
    batchIdToUse = await fetchNuxeoBatchId();
  } else {
    batchIdToUse = batchId;
  }

  // 2. upload the document
  const fetchNuxeoResponse = await fetchNuxeo({
    path: `/upload/${batchIdToUse}/${fileIdx}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/octet-stream',
      'X-File-Name': fileName,
      'X-File-Type': fileType,
    },
    body: file,
  })
    .then(response => response.json())
    .catch(e => console.error('error posting nuxeo', e));

  /* getting a response like that:
    { response: {
        batchId: {batchId},
        fileIdx: "0",
        uploadType: "normal",
        uploaded: "true",
        uploadedSize: "13"
      }
    }
    */
  if (fetchNuxeoResponse !== undefined) {
    return fetchNuxeoResponse;
  } else {
    return {
      batchId: null,
      uploaded: false,
      fileIdx: null,
    };
  }
};

export default fetchNuxeoUploadFile;
