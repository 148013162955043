export const formatId = id =>
  id
    .split('-')
    .map(string => string.charAt(0).toUpperCase() + string.slice(1))
    .join(' ');

export const elementLabel = (element, customValue) => {
  if (element.customizable) return customValue;
  if (element.type === 'text' || element.type === 'button') {
    return (
      (element.text && element.text.length > 15 ? element.text.slice(0, 15).concat('...') : element.text) || element.id
    );
  }
  if (element.type === 'image') {
    return element.fileName || element.id;
  }
  return formatId(element.id);
};
