import undoable from 'redux-undo';
import recycleState from 'redux-recycle';
import { BannersRecord } from '../reference/records';
import * as bannersActions from './bannersActions';
import { updateDissociatedBannersState } from './bannersHigherOrderReducers';
import { filter } from './bannersFilteredActions';
import bannersReducer from './bannersDucks';

const undoableReducer = updateDissociatedBannersState(
  undoable(bannersReducer, {
    limit: 5,
    undoType: bannersActions.BANNERS_UNDO,
    redoType: bannersActions.BANNERS_REDO,
    filter,
    initTypes: ['@@redux-undo/INIT'],
  }),
);

const reducer = recycleState(
  undoableReducer,
  [bannersActions.BANNERS_CLOSE_ALL],
  () => ({
    past: [],
    future: [],
    present: BannersRecord(),
  }),
  {
    recycleActionType: false,
  },
);

export default reducer;
