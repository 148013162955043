import fetchNuxeo from './fetchNuxeo';

const fetchNuxeoMoveBannerSet = (documentId, target) =>
  fetchNuxeo({
    path: '/automation/Document.Move',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-NXenrichers.document': 'acls',
    },
    body: JSON.stringify({
      input: documentId,
      params: {
        target,
      },
    }),
  }).then(res => res.json());

export default fetchNuxeoMoveBannerSet;
