import { corners } from '../../../reference/corners';

export const calculateNewDimensions = ({
  widthShouldBeConstant,
  heightShouldBeConstant,
  cursorPositionNotRotated,
  newFixedCornerPositionNonRotated,
  width,
  height,
  lockAspectRatio,
  movingCorner,
}) => {
  const newWidth = Math.abs(cursorPositionNotRotated.x - newFixedCornerPositionNonRotated.x);
  const newHeight = Math.abs(cursorPositionNotRotated.y - newFixedCornerPositionNonRotated.y);

  if (lockAspectRatio) {
    switch (movingCorner) {
      case corners.middleBottom:
      case corners.middleTop: {
        return {
          width: (width * newHeight) / height,
          height: newHeight,
        };
      }
      case corners.middleRight:
      case corners.middleLeft: {
        return {
          width: newWidth,
          height: (height * newWidth) / width,
        };
      }
      default:
        return {
          width: widthShouldBeConstant ? width : newWidth,
          height: heightShouldBeConstant ? height : newHeight,
        };
    }
  }

  return {
    width: widthShouldBeConstant ? width : newWidth,
    height: heightShouldBeConstant ? height : newHeight,
  };
};
