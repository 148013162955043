import React from 'react';
import { connect } from 'react-redux';
import equal from 'fast-deep-equal';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { ToggleButtonGroup } from '@material-ui/lab';
import { withStyles, FormControl, InputLabel } from '@material-ui/core';
import { RichUtils } from 'draft-js';
import classNames from 'classnames';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { fontStyles, fontStyleIcons } from '../../reference/textProperties';
import {
  getSelectedElementFontStyle,
  getSelectedElementType,
  getSelectedElementIsCustomizable,
} from '../../banner/bannerSelectors';
import { updateElement, setElementFontStyle } from '../../banner/bannerActionsCreators';
import ToggleButtonWithTooltip from '../../components/ToggleButton/ToggleButtonWithTooltip';
import { styles } from '../propertyEditorStyles';
import {
  updateTemporaryTextEditor,
  getTemporaryTextEditorState,
} from '../../temporary-text-editor/temporaryTextEditorDucks';
import {
  getRawContentStateFromEditorState,
  getTextFromEditorState,
  hasNoSelection,
  selectAll,
  hasFocus,
} from '../../element/textHelpers';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

const emptyArray = [];
const stylesArrayed = fontStyles.map(style => [style]);

const useGlobalStyling = props => props.type === 'button' || props.customizable;
const useEditorStyling = props => !useGlobalStyling(props) && props.editorState;

const fontStyleChanged = (prevProps, props) => props.fontStyle !== prevProps.fontStyle;
const updateAppliedStyles = (_, props) => ({ appliedFontStyles: props.fontStyle.toArray() });

class ElementFontStyle extends React.Component {
  state = { classesState: {}, appliedFontStyles: [] };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { classes } = nextProps;
    return {
      ...prevState,
      classesState: {
        labelShrink: { shrink: classes.labelShrink },
        toggleButtonGroup: { root: classes.toggleButtons },
        toggleButtonWithTooltip: {
          root: classNames(classes.toggleButton, classes.disableChildren),
          disabled: classes.toggleButtonDisabled,
          selected: classes.toggleButtonsSelected,
        },
      },
    };
  }

  componentDidMount() {
    if (useGlobalStyling(this.props)) this.setState(updateAppliedStyles);
    if (useEditorStyling(this.props)) this.updateToggleButtonGroup();
  }

  componentDidUpdate(prevProps) {
    if (useGlobalStyling(this.props) && fontStyleChanged(this.props, prevProps)) {
      this.setState(updateAppliedStyles);
      return;
    }

    if (!this.props.editorState || !prevProps.editorState) return;
    const styleChanged =
      this.props.editorState.getCurrentInlineStyle() !== prevProps.editorState.getCurrentInlineStyle();
    const selectionChanged =
      this.props.editorState.getSelection().anchorOffset !== prevProps.editorState.getSelection().anchorOffset &&
      this.props.editorState.getSelection().focusOffset !== prevProps.editorState.getSelection().focusOffset;
    if (styleChanged || selectionChanged) this.updateToggleButtonGroup();
  }

  updateToggleButtonGroup() {
    const { editorState } = this.props;

    const appliedFontStyles = [];

    for (let inlineStyle of editorState.getCurrentInlineStyle()) {
      if (fontStyles.includes(inlineStyle)) {
        appliedFontStyles.push(inlineStyle);
      }
    }

    const sortedAppliedFontStyles = [...appliedFontStyles].sort();
    if (!equal(this.state.appliedFontStyles, sortedAppliedFontStyles)) {
      this.setState({ appliedFontStyles: sortedAppliedFontStyles });
    }
  }

  onFontStyleChange = e => {
    e.persist();
    e.preventDefault(); // in order to keep the text element focused
    const fontStyle = e.target.dataset.style;
    const { setElementFontStyle, updateTemporaryTextEditor, id, editorState: oldEditorState } = this.props;

    if (useGlobalStyling(this.props)) {
      setElementFontStyle({ fontStyle, id });
    }

    if (useEditorStyling(this.props) || useGlobalStyling(this.props)) {
      const editorState = RichUtils.toggleInlineStyle(
        !hasFocus(oldEditorState) || hasNoSelection(oldEditorState) ? selectAll(oldEditorState) : oldEditorState,
        fontStyle,
      );
      updateTemporaryTextEditor({ editorState });
      updateElement({
        contentState: getRawContentStateFromEditorState(editorState),
        text: getTextFromEditorState(editorState),
        id,
      });
    }
  };

  render() {
    const { intl, classes } = this.props;
    const { classesState, appliedFontStyles } = this.state;
    return (
      <FormControl>
        <InputLabel shrink classes={classesState.labelShrink}>
          <FormattedMessage id="properties.text.font-style" />
        </InputLabel>
        <div className={classes.fontStyleToggleGroup}>
          {fontStyles.map((style, ind) => (
            <ToggleButtonGroup
              key={style}
              classes={classesState.toggleButtonGroup}
              selected
              value={appliedFontStyles.includes(style) ? stylesArrayed[ind] : emptyArray}
              onChange={this.onFontStyleChange}
            >
              <ToggleButtonWithTooltip
                classes={classesState.toggleButtonWithTooltip}
                data-style={style}
                value={style}
                tooltip={intl.formatMessage({ id: `properties.text.font-style.${style}` })}
              >
                {fontStyleIcons[style]}
              </ToggleButtonWithTooltip>
            </ToggleButtonGroup>
          ))}
        </div>
      </FormControl>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  type: makeSelectorInstance(getSelectedElementType)(state),
  fontStyle: makeSelectorInstance(getSelectedElementFontStyle)(state),
  customizable: makeSelectorInstance(getSelectedElementIsCustomizable)(state),
  editorState: makeSelectorInstance(getTemporaryTextEditorState)(state),
});

const dispatchToProps = {
  updateElement,
  setElementFontStyle,
  updateTemporaryTextEditor,
};
export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(ElementFontStyle);
