import fetchHtml5ToGif, { uploadPath } from './fetchHtml5ToGif';

const fetchHtml5ToGifUpload = formData =>
  fetchHtml5ToGif({
    path: uploadPath,
    method: 'POST',
    body: formData,
  })
    .then(result => result.json())
    .catch(err => console.log('error in API', err));

export default fetchHtml5ToGifUpload;
