export const BANNER_TRIGGER_UNDO_REDO = 'adbuilder/BANNER_TRIGGER_UNDO_REDO';
export const BANNER_NEW = 'adbuilder/BANNER_NEW';
export const BANNER_SAVE = 'adbuilder/BANNER_SAVE';
export const BANNER_CLEAR_STATE = 'adbuilder/BANNER_CLEAR_STATE';
export const BANNER_SET_NAME = 'adbuilder/BANNER_SET_NAME';
export const BANNER_SET_THUMBNAIL = 'adbuilder/BANNER_SET_THUMBNAIL';
export const BANNER_SET_EXPIRATION_DATE = 'adbuilder/BANNER_SET_EXPIRATION_DATE';
export const BANNER_SET_CURRENT_FRAME = 'adbuilder/BANNER_SET_CURRENT_FRAME';
export const BANNER_ELEMENT_ADDED = 'adbuilder/BANNER_ELEMENT_ADDED';
export const ELEMENT_SET_IMAGE_RESOURCE = 'adbuilder/ELEMENT_SET_IMAGE_RESOURCE';
export const ELEMENT_SET_OPTIMIZED_IMAGE_RESOURCE = 'adbuilder/ELEMENT_SET_OPTIMIZED_IMAGE_RESOURCE';
export const BANNER_CHANGE_FORMAT = 'adbuilder/BANNER_CHANGE_FORMAT';
export const BANNER_SELECT_ELEMENT = 'adbuilder/BANNER_SELECT_ELEMENT';
export const BANNER_SELECT_MULTIPLE_ELEMENTS = 'adbuilder/BANNER_SELECT_MULTIPLE_ELEMENTS';
export const BANNER_ADD_ELEMENT_REQUESTED = 'adbuilder/BANNER_ADD_ELEMENT_REQUESTED';
export const BANNER_IMAGE_ELEMENT_FROM_COMPUTER_REQUESTED = 'adbuilder/BANNER_IMAGE_ELEMENT_FROM_COMPUTER_REQUESTED';
export const BANNER_IMAGE_ELEMENT_FROM_123RF_REQUESTED = 'adbuilder/BANNER_IMAGE_ELEMENT_FROM_123RF_REQUESTED';
export const BANNER_IMAGE_ELEMENT_FROM_NUXEO_REQUESTED = 'adbuilder/BANNER_IMAGE_ELEMENT_FROM_NUXEO_REQUESTED';
export const BANNER_SET_BACKGROUND_COLOR = 'adbuilder/BANNER_SET_BACKGROUND_COLOR';
export const BANNER_SET_TRANSPARENT_COLOR = 'adbuilder/BANNER_SET_TRANSPARENT_COLOR';
export const BANNER_UPDATE_STATUS = 'adbuilder/BANNER_UPDATE_STATUS';
export const BANNER_UPDATE_ANIMATION_STATUS = 'adbuilder/BANNER_UPDATE_ANIMATION_STATUS';
export const BANNER_SHOW_ANIMATION_SNAPSHOT = 'adbuilder/BANNER_SHOW_ANIMATION_SNAPSHOT';
export const BANNER_SET_ZOOM = 'adbuilder/BANNER_SET_ZOOM';
export const BANNER_ANIMATION_SET_REPETITION = 'adbuilder/BANNER_ANIMATION_SET_REPETITION';
export const BANNER_TOGGLE_DISSOCIATE_FROM_MASTER = 'adbuilder/BANNER_TOGGLE_DISSOCIATE_FROM_MASTER';
export const BANNER_SET_EXPIRATION_DATE_REQUESTED = 'adbuilder/BANNER_SET_EXPIRATION_DATE_REQUESTED';

export const ELEMENT_UPDATE_DISPOSITION = 'adbuilder/ELEMENT_UPDATE_DISPOSITION';
export const ELEMENT_UPDATE_INDEX = 'adbuilder/ELEMENT_UPDATE_INDEX';
export const ELEMENT_SET_FONT_FAMILY_REQUESTED = 'adbuilder/ELEMENT_SET_FONT_FAMILY_REQUESTED';
export const ELEMENT_SET_FONT_SIZE = 'adbuilder/ELEMENT_SET_FONT_SIZE';
export const ELEMENT_SET_BACK_TO_ORIGINAL_SIZE = 'adbuilder/ELEMENT_SET_BACK_TO_ORIGINAL_SIZE';
export const ELEMENT_SET_BACK_TO_ORIGINAL_IMAGE = 'adbuilder/ELEMENT_SET_BACK_TO_ORIGINAL_IMAGE';
export const ELEMENT_SET_IMAGE_PROPERTIES_DIMENSIONS = 'adbuilder/ELEMENT_SET_IMAGE_PROPERTIES_DIMENSIONS';
export const ELEMENT_SET_IMAGE_PROPERTIES_POSITION = 'adbuilder/ELEMENT_SET_IMAGE_PROPERTIES_POSITION';
export const ELEMENT_SET_IMAGE_PROPERTIES_ROTATION = 'adbuilder/ELEMENT_SET_IMAGE_PROPERTIES_ROTATION';
export const ELEMENT_DELETE = 'adbuilder/ELEMENT_DELETE';
export const ELEMENT_DELETE_REQUESTED = 'adbuilder/ELEMENT_DELETE_REQUESTED';
export const ELEMENT_UPDATE = 'adbuilder/ELEMENT_UPDATE';
export const ELEMENT_UPDATE_FONT_STYLE = 'adbuilder/ELEMENT_UPDATE_FONT_STYLE';
export const ELEMENT_UPDATE_DIMENSIONS = 'adbuilder/ELEMENT_UPDATE_DIMENSIONS';
export const ELEMENT_UPDATE_DIMENSIONS_REQUESTED = 'adbuilder/ELEMENT_UPDATE_DIMENSIONS_REQUESTED';
export const ELEMENT_UPDATE_POSITION = 'adbuilder/ELEMENT_UPDATE_POSITION';
export const ELEMENT_UPDATE_ROTATION = 'adbuilder/ELEMENT_UPDATE_ROTATION';
export const ELEMENT_UPDATE_TRANSITION = 'adbuilder/ELEMENT_UPDATE_TRANSITION';
export const ELEMENT_TOGGLE_SHADOW = 'adbuilder/ELEMENT_TOGGLE_SHADOW';
export const ELEMENT_UPDATE_SHADOW = 'adbuilder/ELEMENT_UPDATE_SHADOW';
export const ELEMENT_TOGGLE_BLINK = 'adbuilder/ELEMENT_TOGGLE_BLINK';
export const ELEMENT_UPDATE_BLINK = 'adbuilder/ELEMENT_UPDATE_BLINK';
export const ELEMENT_UPDATE_STATUS = 'adbuilder/ELEMENT_UPDATE_STATUS';
export const ELEMENT_SET_SHOW_ON_ALL_SLIDES = 'adbuilder/ELEMENT_SET_SHOW_ON_ALL_SLIDES';
export const ELEMENTS_UPDATE = 'adbuilder/ELEMENTS_UPDATE';
export const ELEMENTS_UPDATE_DIFF = 'adbuilder/ELEMENTS_UPDATE_DIFF';
export const ELEMENTS_UPDATE_DEEP = 'adbuilder/ELEMENTS_UPDATE_DEEP';
export const ELEMENTS_SET_SHOW_ON_ALL_SLIDES = 'adbuilder/ELEMENTS_SET_SHOW_ON_ALL_SLIDES';
export const ELEMENTS_DELETE = 'adbuilder/ELEMENTS_DELETE';
export const ELEMENT_CROP_REQUESTED = 'adbuilder/ELEMENT_CROP_REQUESTED';

export const SLIDE_ADD_REQUESTED = 'adbuilder/SLIDE_ADD_REQUESTED';
export const SLIDE_ADD = 'adbuilder/SLIDE_ADD';
export const SLIDE_DUPLICATE = 'adbuilder/SLIDE_DUPLICATE';
export const SLIDE_SELECT = 'adbuilder/SLIDE_SELECT';
export const SLIDE_SET_DURATION = 'adbuilder/SLIDE_SET_DURATION';
export const SLIDE_SET_DURATION_REQUESTED = 'adbuilder/SLIDE_SET_DURATION_REQUESTED';
export const SLIDE_CHECK_AND_UPDATE_SLIDE_TRANSITION = 'adbuilder/SLIDE_CHECK_AND_UPDATE_SLIDE_TRANSITION';
export const SLIDES_SET_DURATION = 'adbuilder/SLIDES_SET_DURATION';
export const SLIDE_SET_TRANSITION_TYPE = 'adbuilder/SLIDE_SET_TRANSITION_TYPE';
export const SLIDE_SET_TRANSITION_DURATION = 'adbuilder/SLIDE_SET_TRANSITION_DURATION';
export const SLIDE_DELETE_REQUESTED = 'adbuilder/SLIDE_DELETE_REQUESTED';
export const SLIDE_DELETE = 'adbuilder/SLIDE_DELETE';
