import fetchNuxeo, { pageSize, baseSearchUrl, queryParam } from './fetchNuxeo';

/*------------------------------------*/
/*IMAGES*/
/*------------------------------------*/
const fetchNuxeoImageThumbnails = async (workspacePath, pageNumber = 0) => {
  const errorMessage = "fetchNuxeoImageThumbnails: can't fetch images thumbnails from nuxeo";
  try {
    const query =
      "SELECT * FROM Document WHERE ecm:primaryType NOT IN ('Favorites') " +
      "AND ecm:primaryType IN ('Picture') AND ecm:mixinType NOT IN ('Folderish','HiddenInNavigation') " +
      "AND ecm:currentLifeCycleState <> 'deleted'";
    //example: SELECT * FROM Document WHERE ecm:ancestorId = 'c5904f77-299a-411e-8477-81d3102a81f9'
    const params = {
      [queryParam]: query,
      sortBy: 'dc:modified,dc:title',
      sortOrder: 'DESC,ASC',
      pageSize,
      currentPageIndex: pageNumber,
    };
    const path = baseSearchUrl;
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json+nxrequest',
      'X-NXproperties': 'dublincore, file, picture',
      'X-NXenrichers.document': 'thumbnail',
      'Cache-Control': 'no-cache',
    };
    return await fetchNuxeo({
      path,
      headers,
      params,
      errorMessage,
    })
      .then(res => res.json())
      .then(({ entries, pageCount }) => ({
        images: entries.map(
          ({
            uid,
            title: name,
            properties: {
              'file:content': { data: url },
              'picture:info': { width, height },
            },
            contextParameters: {
              thumbnail: { url: thumbnailUrl },
            },
          }) => ({
            uid,
            name,
            url,
            thumbnailUrl,
            width,
            height,
          }),
        ),
        totalPages: pageCount,
      }))
      .catch(err => console.error('fetchThumbnailsFromNuxeo', err));
  } catch (e) {
    console.error(errorMessage, e);
  }
};

export default fetchNuxeoImageThumbnails;
