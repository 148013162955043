import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { ToggleButtonGroup } from '@material-ui/lab';
import { withStyles, FormControl, InputLabel } from '@material-ui/core';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { horizontalAlignments, horizontalAlignmentIcons } from '../../reference/textProperties';
import { getSelectedElementFontHorizontalAlign } from '../../banner/bannerSelectors';
import { setElementHorizontalAlignment } from '../../banner/bannerActionsCreators';
import ToggleButtonWithTooltip from '../../components/ToggleButton/ToggleButtonWithTooltip';
import { styles } from '../propertyEditorStyles';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

class ElementFontAlignHorizontal extends React.Component {
  state = { classesState: {} };

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return {
      classesState: {
        labelShrink: { shrink: classes.labelShrink },
        toggleButtonGroup: { root: classes.toggleButtons },
        toggleButtonWithTooltip: {
          root: classes.toggleButton,
          disabled: classes.toggleButtonDisabled,
          selected: classes.toggleButtonsSelected,
        },
      },
    };
  }

  onHorizontalAlignChange = (_, horizontalAlign) => {
    const { setElementHorizontalAlignment, id } = this.props;
    setElementHorizontalAlignment({ horizontalAlign, id });
  };

  render() {
    const { horizontalAlign, intl, classes } = this.props;
    const { classesState } = this.state;
    return (
      <FormControl>
        <InputLabel shrink classes={classesState.labelShrink}>
          <FormattedMessage id="properties.text.horizontalAlign" />
        </InputLabel>
        <ToggleButtonGroup
          classes={classesState.toggleButtonGroup}
          exclusive={true}
          value={horizontalAlign || ''}
          onChange={this.onHorizontalAlignChange}
        >
          {horizontalAlignments.map(alignment => (
            <ToggleButtonWithTooltip
              classes={classes.toggleButtonWithTooltip}
              key={alignment}
              value={alignment}
              tooltip={intl.formatMessage({ id: `properties.text.horizontalAlign.${alignment}` })}
            >
              {horizontalAlignmentIcons[alignment]}
            </ToggleButtonWithTooltip>
          ))}
        </ToggleButtonGroup>
      </FormControl>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  horizontalAlign: makeSelectorInstance(getSelectedElementFontHorizontalAlign)(state),
});

const dispatchToProps = {
  setElementHorizontalAlignment,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(ElementFontAlignHorizontal);
