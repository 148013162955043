import { fade, darken } from '@material-ui/core/styles/colorManipulator';
import { elementListWidth, slidesPanelWidth } from '../reference/dimensions';
import { elementListPanelColor } from '../reference/colors';

const elementListStyles = theme => ({
  root: {
    backgroundColor: elementListPanelColor,
    width: elementListWidth,
    zIndex: 100,
    boxShadow: theme.shadows[2],
    position: 'absolute',
    boxSizing: 'border-box',
    top: 0,
    bottom: 0,
    left: slidesPanelWidth,
    transition: theme.transitions.create(['transform'], { duration: theme.transitions.duration.standard }),
    '& *': {
      boxSizing: 'border-box',
    },
    '& .ps__rail-y': {
      zIndex: 150,
    },
  },
  folded: {
    transform: `translateX(${-elementListWidth}px)`,
  },
  foldedSlides: {
    transform: `translateX(${-slidesPanelWidth - elementListWidth}px)`,
  },
  scrollContainer: {
    height: '100%',
    overflowY: 'auto',
  },
  handle: {
    position: 'absolute',
    width: 24,
    height: 32,
    right: -24 - 5,
    top: 16,
    padding: '5px 5px 5px 0',
    overflow: 'hidden',
    pointerEvents: 'none',
    color: `${theme.palette.primary.main} !important`,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 24,
      height: 32,
      backgroundColor: elementListPanelColor,
      borderRadius: '0 4px 4px 0',
      boxShadow: theme.shadows[2],
      zIndex: -1,
    },
    '& > button': {
      width: '100%',
      height: '100%',
      borderRadius: '0 4px 4px 0',
      pointerEvents: 'auto',
      transition: theme.transitions.create(['background-color'], { duration: theme.transitions.duration.short }),
      '&:hover': {
        backgroundColor: fade(theme.palette.text.primary, theme.palette.action.hoverOpacity),
      },
    },
  },
  list: {
    width: '100%',
    height: 'calc(100% - 10px)',
    paddingTop: 0,
  },
  dragging: {
    '& $item': {
      pointerEvents: 'none',
    },
  },
  item: {
    backgroundColor: elementListPanelColor,
    zIndex: 101,
    fontSize: 13,
    padding: '5px 15px',
    transition: theme.transitions.create(['transform'], { duration: theme.transitions.duration.standard }),
    '&:hover $duplicateButton': {
      display: 'unset',
      zIndex: 1000,
    },
  },
  listItemIcon: {
    marginRight: 0,
    minWidth: 0,
  },
  selectedItem: {
    backgroundColor: darken(elementListPanelColor, 0.25),
    '&:hover': {
      backgroundColor: darken(elementListPanelColor, 0.35),
    },
    '& $duplicateButton': {
      display: 'unset',
    },
  },
  draggingItem: {
    boxShadow: theme.shadows[2],
    transition: 'none',
  },
  itemText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    padding: 0,
  },
  duplicateButton: {
    display: 'none',
    // marginRight: -12,
    marginTop: -12,
    marginBottom: -12,
    '&> button': {
      padding: 0,
    },
  },
  thumbnail: {
    position: 'relative',
    width: 24,
    height: 24,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginRight: theme.spacing(1),
  },
  noMargin: {
    margin: 0,
  },
  rectangleThumbnail: {
    position: 'absolute',
    top: 8,
    bottom: 8,
    right: 6,
    left: 6,
    zIndex: -1,
  },
  allSlidesIcon: {
    fontSize: 16,
    opacity: 0.75,
    color: theme.palette.primary.main,
    marginRight: 0,
  },
  dragHandle: {
    marginLeft: -theme.spacing(1) * 2,
    marginRight: 0,
    cursor: 'grab',
    color: fade(theme.palette.text.primary, 0.25),
  },
});

export default elementListStyles;
