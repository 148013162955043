import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, onlyUpdateForKeys } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getBannerSlides, getSelectedElements, getSelectedSlide } from '../../banner/bannerSelectors';
import {
  setElementDuration,
  setElementsTransitionOutType,
  setElementTransitionOutDuration,
  setElementsTransitionOutDuration,
  setSlidesDuration,
  setSlideDuration,
} from '../../banner/bannerActionsCreators';
import { durationInputProps, secondEndornment, parseFloatHelper } from '../constants';
import { addWarning } from '../../messages/messagesDucks';
import {
  verifyElementsTransitionOutDuration,
  allElementsHasSameDeepProperty,
  elementsDeepPropertyAverage,
} from '../helpers';
import { withStyles } from '@material-ui/core';
import { styles } from '../propertyEditorStyles';
import { getSelectedSlideIndex, getSelectedElementIds } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementsTransitionOutDuration extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { slide, slides, selectedSlideIndex, elements, classes } = nextProps;
    return {
      durationAdditionalData: {
        slide,
        slides,
        selectedSlideIndex,
        elements,
      },
      InputProps: {
        ...secondEndornment,
        classes: {
          root: allElementsHasSameDeepProperty(elements, 'transitionOut', 'duration') === false && classes.averageInput,
        },
      },
    };
  }

  onTransitionOutDurationChange = ({ value: duration }) =>
    this.props.setElementsTransitionOutDuration({ ids: this.props.ids, duration }); //tracked
  onTransitionOutDurationVerify = ({ additionalData: { slide, slides, selectedSlideIndex, elements } }) => {
    const {
      setElementDuration,
      setElementsTransitionOutType,
      setSlideDuration,
      setSlidesDuration,
      addWarning,
    } = this.props;
    verifyElementsTransitionOutDuration({
      slide,
      slides,
      selectedSlideIndex,
      elements,
      setElementDuration,
      setElementsTransitionOutType,
      setSlideDuration,
      setSlidesDuration,
      addWarning,
    });
  };

  render() {
    const { ids, intl, elements } = this.props;
    const { durationAdditionalData, InputProps } = this.state;
    return (
      <TextFieldDoped
        key={ids}
        type="number"
        label={intl.formatMessage({ id: 'properties.transition.duration' })}
        value={elementsDeepPropertyAverage(elements, 'transitionOut', 'duration')[0]}
        fullWidth
        inputProps={durationInputProps}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={InputProps}
        helper={parseFloatHelper}
        additionalData={durationAdditionalData}
        onChangeMethod={this.onTransitionOutDurationChange}
        onVerifyMethod={this.onTransitionOutDurationVerify}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  elements: makeSelectorInstance(getSelectedElements)(state),
  ids: makeSelectorInstance(getSelectedElementIds)(state),
  slide: makeSelectorInstance(getSelectedSlide)(state),
  selectedSlideIndex: makeSelectorInstance(getSelectedSlideIndex)(state),
  slides: makeSelectorInstance(getBannerSlides)(state),
});

const dispatchToProps = {
  setElementDuration,
  addWarning,
  setSlideDuration,
  setSlidesDuration,
  setElementTransitionOutDuration,
  setElementsTransitionOutDuration,
  setElementsTransitionOutType,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  onlyUpdateForKeys(['elements', 'ids']),
  injectIntl,
  withStyles(styles),
)(ElementsTransitionOutDuration);
