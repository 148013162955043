import { List, Set } from 'immutable';
import { GuideRecord } from '../reference/records';

//TESTME

const maxEquidistantElements = 5;

const checkElementsAreSuperposed = ({ element, el }) => {
  const elementXIsInEl = element.x <= el.x + el.width && element.x >= el.x;
  const elXIsInElement = el.x <= element.x + element.width && el.x >= element.x;
  const elementYIsInEl = element.y <= el.y + el.height && element.y >= el.y;
  const elYIsInElement = el.y <= element.y + element.height && el.y >= element.y;
  return (elementXIsInEl || elXIsInElement) && (elementYIsInEl || elYIsInElement);
};

const checkElementsAreInHorizontalScope = ({ element, el }) => {
  const topElIsInElementScope = el.y >= element.y && el.y <= element.y + element.height;
  const topElementIsInElScope = element.y >= el.y && element.y <= el.y + el.height;
  const bottomElIsInElementScope = el.y + el.height >= element.y && el.y + el.height <= element.y + element.height;
  const bottomElementIsInElScope = element.y + element.height >= el.y && element.y + element.height <= el.y + el.height;
  return topElIsInElementScope || topElementIsInElScope || bottomElIsInElementScope || bottomElementIsInElScope;
};

const checkElementsAreInVerticalScope = ({ element, el }) => {
  const leftElIsInElementScope = el.x >= element.x && el.x <= element.x + element.width;
  const leftElementIsInElScope = element.x >= el.x && element.x <= el.x + el.width;
  const rightElIsInElementScope = el.x + el.width >= element.x && el.x + el.width <= element.x + element.width;
  const rightElementIsInElScope = element.x + element.width >= el.x && element.x + element.width <= el.x + el.width;
  return leftElIsInElementScope || leftElementIsInElScope || rightElIsInElementScope || rightElementIsInElScope;
};

const buildElementsGuidesCombinations = (elementsInElementScope, guidesCombinations) => {
  const recursiveLoop = (active, rest) => {
    if (rest.length === 0) {
      guidesCombinations.push(active);
    } else {
      recursiveLoop([...active, rest[0]], [...rest.filter((el, i) => i !== 0)]);
      recursiveLoop([...active], [...rest.filter((el, i) => i !== 0)]);
    }
  };
  recursiveLoop([], elementsInElementScope);
};

const addMultipleGuides = ({ remainingElements, elementsDirectionalScope }) => {
  const remainingElementsIds = remainingElements.map(el => el.id);
  let guides = [];
  remainingElements.forEach(element => {
    const elementsInElementScope = elementsDirectionalScope
      .filter(scopes => scopes.includes(element.id))
      .map(scopes => scopes.filter(id => id !== element.id)[0])
      .concat(element.id)
      .sort((el1, el2) => remainingElements.indexOf(el1) >= remainingElements.indexOf(el2));

    if (elementsInElementScope.length <= 2 || elementsInElementScope.length > maxEquidistantElements) {
      return;
    }

    let guidesWholeCombinations = [];
    buildElementsGuidesCombinations(elementsInElementScope, guidesWholeCombinations);

    guidesWholeCombinations
      .filter(guide => guide.includes(element.id))
      .filter(guide => guide.length > 1)
      .map(guide => guide.sort((el1, el2) => remainingElementsIds.indexOf(el1) >= remainingElementsIds.indexOf(el2)))
      .forEach(guide => !guides.includes(JSON.stringify(guide)) && guides.push(JSON.stringify(guide)));
  });

  return guides.map(guide => JSON.parse(guide));
};

const buildElementsHorizontalScopes = ({ remainingElements }) => {
  let elementsHorizontalScopes = [];

  for (let [ind, element] of remainingElements.entries()) {
    const elementHorizontalScope = [];
    for (let el of remainingElements.filterNot((_, i) => i <= ind)) {
      if (!checkElementsAreSuperposed({ el, element }) && checkElementsAreInHorizontalScope({ el, element })) {
        elementHorizontalScope.push(el.x < element.x ? [el.id, element.id] : [element.id, el.id]);
      }
    }
    if (elementHorizontalScope.length) {
      elementsHorizontalScopes.push(...elementHorizontalScope);
    }
  }

  return elementsHorizontalScopes;
};

const buildElementsVerticalScopes = ({ remainingElements }) => {
  let elementsVerticalScopes = [];

  for (let [ind, element] of remainingElements.entries()) {
    const elementVerticalScope = [];
    for (let el of remainingElements.filterNot((_, i) => i <= ind)) {
      if (!checkElementsAreSuperposed({ el, element }) && checkElementsAreInVerticalScope({ el, element })) {
        elementVerticalScope.push(el.y < element.y ? [el.id, element.id] : [element.id, el.id]);
      }
    }
    if (elementVerticalScope.length) {
      elementsVerticalScopes.push(...elementVerticalScope);
    }
  }

  return elementsVerticalScopes;
};

const calculateLeft = (element1, element2) => {
  const el1LeftIsBefore = element1.x <= element2.x;
  const el1LeftIsAfter = !el1LeftIsBefore;
  const el1RightIsAfter = element1.x + element1.width >= element2.x + element2.width;
  const el1RightIsBefore = !el1RightIsAfter;
  const el1IncludesEl2 = el1LeftIsBefore && el1RightIsAfter;
  const el1InEl2 = el1LeftIsAfter && el1RightIsBefore;
  const el1BeforeEl2 = el1LeftIsBefore && el1RightIsBefore;

  if (el1InEl2) return element1.x + element1.width / 2;
  if (el1IncludesEl2) return element2.x + element2.width / 2;
  if (el1BeforeEl2) return element2.x + (element1.x + element1.width - element2.x) / 2;
  // if el1AfterEl2 ( === el1LeftIsAfter && el1RightIsAfter)
  return element1.x + (element2.x + element2.width - element1.x) / 2;
};

const buildVerticalGuides = ({ remainingElements, elementsVerticalScopes }) => {
  let verticalGuidesConstruction = [];
  elementsVerticalScopes
    .filter(potentialGuide => {
      if (potentialGuide.length > 2) {
        let shouldHaveAGuide = true;
        const el1 = remainingElements.toJS().filter(el => el.id === potentialGuide[0])[0];
        const el2 = remainingElements.toJS().filter(el => el.id === potentialGuide[1])[0];
        const height = el2.x - (el1.x + el1.width);
        potentialGuide.forEach((id, i) => {
          if (i >= 1 && i !== potentialGuide.length - 1) {
            const elI = remainingElements.toJS().filter(el => el.id === potentialGuide[i])[0];
            const elIPlus1 = remainingElements.toJS().filter(el => el.id === potentialGuide[i + 1])[0];
            if (elIPlus1.y - (elI.y + elI.height) !== height) {
              shouldHaveAGuide = false;
            }
          }
        });
        return shouldHaveAGuide;
      }
      return true;
    })
    .filter(potentialGuide => {
      if (potentialGuide.length > 2) return true;
      return (
        elementsVerticalScopes
          .filter(scope => scope.length > 2)
          .filter(scope =>
            scope.reduce((acc, scope) => `${acc}${scope}`).includes(`${potentialGuide[0]}${potentialGuide[1]}`),
          ).length === 0
      );
    })
    .forEach(guide => {
      if (guide.length === 2) {
        const el1 = remainingElements.toJS().filter(el => el.id === guide[0])[0];
        const el2 = remainingElements.toJS().filter(el => el.id === guide[1])[0];
        let height;
        if (el1.y > el2.y) {
          height = el1.y - (el2.y + el2.height);
        } else {
          height = el2.y - (el1.y + el1.height);
        }

        const middleGuide = GuideRecord({
          orientation: 'vertical',
          magnetism: 'equidistant',
          elementIds: Set.of(guide),
          position: {
            height,
            top: Math.min(el1.y + el1.height, el2.y + el2.height),
            left: calculateLeft(el2, el1),
          },
        });

        verticalGuidesConstruction.push(
          List.of(
            middleGuide,
            GuideRecord({
              orientation: 'vertical',
              magnetism: 'equidistant-top',
              elementIds: Set.of(guide),
              position: {
                height,
                top: Math.min(el1.y, el2.y) - height,
                left: selectedElement => calculateLeft(selectedElement, el1),
              },
            }),
          ),
        );

        verticalGuidesConstruction.push(
          List.of(
            middleGuide,
            GuideRecord({
              orientation: 'vertical',
              magnetism: 'equidistant-bottom',
              elementIds: Set.of(guide),
              position: {
                height,
                top: Math.max(el1.y + el1.height, el2.y + el2.height),
                left: selectedElement => calculateLeft(selectedElement, el2),
              },
            }),
          ),
        );
      } else {
        const elFirst = remainingElements.toJS().filter(el => el.id === guide[0])[0];
        const elSecond = remainingElements.toJS().filter(el => el.id === guide[1])[0];
        const elLast = remainingElements.toJS().filter(el => el.id === guide[guide.length - 1])[0];
        const height = elSecond.y - (elFirst.y + elFirst.height);

        const middleGuides = guide
          .filter((guide, i) => i !== guide.length - 1)
          .map((elId, i) => [elId, guide[i + 1]])
          .map(elIds => [
            remainingElements.toJS().filter(el => el.id === elIds[0])[0],
            remainingElements.toJS().filter(el => el.id === elIds[1])[0],
          ])
          .map(coupleOfElements => {
            const el1 = coupleOfElements[0];
            const el2 = coupleOfElements[1];
            return GuideRecord({
              orientation: 'vertical',
              magnetism: 'equidistant',
              elementIds: Set.of(guide),
              position: {
                height,
                top: Math.min(el1.y + el1.height, el2.y + el2.height),
                left: calculateLeft(el1, el2),
              },
            });
          });

        verticalGuidesConstruction.push(
          List.of(
            ...middleGuides,
            GuideRecord({
              orientation: 'vertical',
              magnetism: 'equidistant-top',
              elementIds: Set.of(guide),
              position: {
                height,
                top: elFirst.y - height,
                left: selectedElement => calculateLeft(selectedElement, elFirst),
              },
            }),
          ),
        );

        verticalGuidesConstruction.push(
          List.of(
            ...middleGuides,
            GuideRecord({
              orientation: 'vertical',
              magnetism: 'equidistant-bottom',
              elementIds: Set.of(guide),
              position: {
                height,
                top: elLast.y + elLast.height,
                left: selectedElement => calculateLeft(selectedElement, elLast),
              },
            }),
          ),
        );
      }
    });

  return verticalGuidesConstruction;
};

const calculateTop = (element1, element2) => {
  const el1TopIsLower = element1.y <= element2.y;
  const el1TopIsHigher = !el1TopIsLower;
  const el1BottomIsHigher = element1.y + element1.height >= element2.y + element2.height;
  const el1BottomIsLower = !el1BottomIsHigher;
  const el1InEl2 = el1TopIsLower && el1BottomIsHigher;
  const el1IncludesEl2 = el1TopIsHigher && el1BottomIsLower;
  const el1LowerEl2 = el1TopIsLower && el1BottomIsLower;

  if (el1InEl2) return element2.y + element2.height / 2;
  if (el1IncludesEl2) return element1.y + element1.height / 2;
  if (el1LowerEl2) return element2.y + (element1.y + element1.height - element2.y) / 2;
  // if el1HigherEl2 ( === el1TopIsHigher && el1BottomIsHigher)
  return element1.y + (element2.y + element2.height - element1.y) / 2;
};

const buildHorizontalGuides = ({ remainingElements, elementsHorizontalScopes }) => {
  let horizontalGuidesConstruction = [];

  elementsHorizontalScopes
    .filter(potentialGuide => {
      if (potentialGuide.length > 2) {
        let shouldHaveAGuide = true;
        const el1 = remainingElements.toJS().filter(el => el.id === potentialGuide[0])[0];
        const el2 = remainingElements.toJS().filter(el => el.id === potentialGuide[1])[0];
        const width = el2.x - (el1.x + el1.width);
        potentialGuide.forEach((id, i) => {
          if (i >= 1 && i !== potentialGuide.length - 1) {
            const elI = remainingElements.toJS().filter(el => el.id === potentialGuide[i])[0];
            const elIPlus1 = remainingElements.toJS().filter(el => el.id === potentialGuide[i + 1])[0];
            if (elIPlus1.x - (elI.x + elI.width) !== width) {
              shouldHaveAGuide = false;
            }
          }
        });
        return shouldHaveAGuide;
      }
      return true;
    })
    .filter(potentialGuide => {
      if (potentialGuide.length > 2) return true;
      return (
        elementsHorizontalScopes
          .filter(scope => scope.length > 2)
          .filter(scope =>
            scope.reduce((acc, scope) => `${acc}${scope}`).includes(`${potentialGuide[0]}${potentialGuide[1]}`),
          ).length === 0
      );
    })
    .forEach(guide => {
      if (guide.length === 2) {
        const el1 = remainingElements.toJS().filter(el => el.id === guide[0])[0];
        const el2 = remainingElements.toJS().filter(el => el.id === guide[1])[0];
        const width = el2.x - (el1.x + el1.width);

        const middleGuide = GuideRecord({
          orientation: 'horizontal',
          magnetism: 'equidistant',
          elementIds: Set.of(guide),
          position: {
            width,
            left: el1.x + el1.width,
            top: calculateTop(el2, el1),
          },
        });

        horizontalGuidesConstruction.push(
          List.of(
            middleGuide,
            GuideRecord({
              orientation: 'horizontal',
              magnetism: 'equidistant-right',
              elementIds: Set.of(guide),
              position: {
                width,
                left: el2.x + el2.width,
                top: selectedElement => calculateTop(selectedElement, el2),
              },
            }),
          ),
        );

        horizontalGuidesConstruction.push(
          List.of(
            middleGuide,
            GuideRecord({
              orientation: 'horizontal',
              magnetism: 'equidistant-left',
              elementIds: Set.of(guide),
              position: {
                width,
                left: el1.x - width,
                top: selectedElement => calculateTop(selectedElement, el1),
              },
            }),
          ),
        );
      } else {
        const elFirst = remainingElements.toJS().filter(el => el.id === guide[0])[0];
        const elSecond = remainingElements.toJS().filter(el => el.id === guide[1])[0];
        const elLast = remainingElements.toJS().filter(el => el.id === guide[guide.length - 1])[0];
        const width = elSecond.x - (elFirst.x + elFirst.width);

        const middleGuides = guide
          .filter((_, i) => i !== guide.length - 1)
          .map((elId, i) => [elId, guide[i + 1]])
          .map(elIds => [
            remainingElements.toJS().filter(el => el.id === elIds[0])[0],
            remainingElements.toJS().filter(el => el.id === elIds[1])[0],
          ])
          .map(coupleOfElements => {
            const el1 = coupleOfElements[0];
            const el2 = coupleOfElements[1];
            return GuideRecord({
              orientation: 'horizontal',
              magnetism: 'equidistant',
              elementIds: Set.of(guide),
              position: {
                width,
                left: el1.x + el1.width,
                top: calculateTop(el1, el2),
              },
            });
          });

        horizontalGuidesConstruction.push(
          List.of(
            ...middleGuides,
            GuideRecord({
              orientation: 'horizontal',
              magnetism: 'equidistant-left',
              elementIds: Set.of(guide),
              position: {
                width,
                left: elFirst.x - width,
                top: selectedElement => calculateTop(selectedElement, elFirst),
              },
            }),
          ),
        );

        horizontalGuidesConstruction.push(
          List.of(
            ...middleGuides,
            GuideRecord({
              orientation: 'horizontal',
              magnetism: 'equidistant-right',
              elementIds: Set.of(guide),
              position: {
                width,
                left: elLast.x + elLast.width,
                top: selectedElement => calculateTop(selectedElement, elLast),
                // FIXME TESTME: it was elFirst before refacto
              },
            }),
          ),
        );
      }
    });

  return horizontalGuidesConstruction;
};

export const buildEquidistantGuides = ({ remainingElements }) => {
  //Build equidistant guides. Rules are :

  //1. There need to be at least 3 elements (the selected element + 2 others)
  const enoughElements = remainingElements.size >= 2;
  if (!enoughElements) return List();

  /*2. To be eligible for creating a guide, all the elements concerned must be scoped together, meaning that
  you can't have one element with (the top AND the bottom) NOT BETWEEN (the top AND the bottom) of another element */
  let elementsVerticalScopes = buildElementsVerticalScopes({ remainingElements });
  let elementsHorizontalScopes = buildElementsHorizontalScopes({ remainingElements });

  if (!elementsVerticalScopes.length && !elementsHorizontalScopes.length) return List();

  const elementsSortedForVerticalScopeCheck = remainingElements.sort((el1, el2) => el1.y >= el2.y);
  const elementsSortedForHorizontalScopeCheck = remainingElements.sort((el1, el2) => el1.x >= el2.x);

  /*3. we check if there are multiple equidistant elements, and add them in the potential guides list if necessary */
  if (elementsVerticalScopes.length > 1) {
    elementsVerticalScopes = addMultipleGuides({
      remainingElements: elementsSortedForVerticalScopeCheck,
      elementsDirectionalScope: elementsVerticalScopes,
    });
  }

  if (elementsHorizontalScopes.length > 1) {
    elementsHorizontalScopes = addMultipleGuides({
      remainingElements: elementsSortedForHorizontalScopeCheck,
      elementsDirectionalScope: elementsHorizontalScopes,
    });
  }

  /*4. create guides if necessary */
  const verticalGuidesConstruction = buildVerticalGuides({
    remainingElements,
    elementsVerticalScopes,
  });
  const horizontalGuidesConstruction = buildHorizontalGuides({
    remainingElements,
    elementsHorizontalScopes,
  });

  return List.of(...horizontalGuidesConstruction, ...verticalGuidesConstruction);
};
