import React from 'react';
import { connect } from 'react-redux';
import { TextField, MenuItem, withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { setElementProperty } from '../../banner/bannerActionsCreators';
import { getSelectedElementCustomProperty } from '../../banner/bannerSelectors';
import { loadAllFonts } from '../../resources/fonts';
import { styles } from '../propertyEditorStyles';
import {
  toggleCustomFieldSelectOpen,
  getTemporaryCustomFieldOpen,
} from '../../temporary-text-editor/temporaryTextEditorDucks';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import { getCustomFields } from '../../entities/entitiesSelectors';

class ElementCustomFieldSelect extends React.Component {
  state = {
    fontsLoaded: false,
  };

  componentDidMount() {
    // Load fonts in the background
    this.canChangeState = true;
    loadAllFonts().then(() => {
      if (this.canChangeState) {
        this.setState({ fontsLoaded: true });
      }
    });
  }

  componentWillUnmount() {
    this.fontsLoaded = false;
    this.canChangeState = false;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { classes } = nextProps;
    return {
      ...prevState,
      classesState: { menuItem: { selected: classes.menuSelected, root: classes.menuItem } },
    };
  }

  onCustomFieldOpen = () => {
    this.props.toggleCustomFieldSelectOpen(true);
  };

  onCustomFieldClose = () => {
    this.props.toggleCustomFieldSelectOpen(false);
  };

  onCustomFieldChange = ({ target: { value: property } }) => {
    const { id, setElementProperty } = this.props;
    setElementProperty({ property, id });
  };

  render() {
    const { intl, property, customFieldOpen, fields } = this.props;
    const { classesState } = this.state;

    const noFields = !fields || !fields.length;
    return (
      <TextField
        select
        label={intl.formatMessage({ id: noFields ? 'customization.field.not-available' : 'customization.field' })}
        value={noFields || !property ? '' : property}
        disabled={!fields}
        fullWidth
        onChange={this.onCustomFieldChange}
        // https://next.material-ui.com/demos/text-fields/#limitations
        InputLabelProps={{ shrink: Boolean(!noFields && property) }}
        SelectProps={{
          open: customFieldOpen,
          onOpen: () => this.onCustomFieldOpen(),
          onClose: () => this.onCustomFieldClose(),
        }}
      >
        {fields.map(field => (
          <MenuItem key={field} value={field} classes={classesState.menuItem}>
            <span>{field}</span>
          </MenuItem>
        ))}
        {!fields && (
          <MenuItem>
            <span>{intl.formatMessage({ id: 'customization.field.not-available' })}</span>
          </MenuItem>
        )}
      </TextField>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  property: makeSelectorInstance(getSelectedElementCustomProperty)(state),
  customFieldOpen: makeSelectorInstance(getTemporaryCustomFieldOpen)(state),
  fields: makeSelectorInstance(getCustomFields)(state),
});

const dispatchToProps = {
  setElementProperty,
  toggleCustomFieldSelectOpen,
};

export default compose(
  connect(makeStateToProps, dispatchToProps),
  injectIntl,
  withStyles(styles),
)(ElementCustomFieldSelect);
