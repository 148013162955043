import { applyStyle, addBlinkAnimation, addCustomization } from '../../util/dom-helpers';
import { computeTextShadow } from '../../../reference/textProperties';
import { createHtmlTextElement } from '../../../element/textHelpers';

const renderText = ({
  text,
  horizontalAlign: textAlign,
  opacity,
  color,
  fontFamily,
  fontSize,
  dropShadowEnabled,
  dropShadow,
  contentState,
  blinkAnimationEnabled,
  blinkAnimation,
  customizable,
  property,
}) => {
  const dom = document.createElement('div');
  dom.className = 'text';
  dom.innerHTML = createHtmlTextElement(contentState, text);
  applyStyle(dom, {
    textAlign,
    opacity,
    color,
    fontFamily: `"${fontFamily}"`,
    fontSize: `${fontSize}px`,
    ...addBlinkAnimation({ blinkAnimationEnabled, blinkAnimation }),
  });
  if (customizable) {
    addCustomization(dom, property);
    applyStyle(dom, {
      visibility: 'hidden',
    });
  }
  if (dropShadowEnabled) {
    dom.style.textShadow = computeTextShadow(dropShadow);
  }

  return dom;
};

export default renderText;
