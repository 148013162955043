import './index.css';
import './keyframes.css';
import './canvas.css';
import 'draft-js/dist/Draft.css';
import './i18n';
import './i18n/i18nDucks';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import debugFactory from 'debug';

import DrBannerThemeProvider from './layout/DrBannerThemeProvider';
import App from './layout/App';
import { unregister } from './registerServiceWorker';
import { store, persistor } from './store';

if (process.env.NODE_ENV === 'development') {
  debugFactory.enable('adbuilder:*');
}

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <DrBannerThemeProvider adBuilder>
          <App />
        </DrBannerThemeProvider>
      </PersistGate>
    </Provider>,
    document.getElementById('root'),
  );
};
render();

unregister();

if (module.hot) {
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./layout/App'], () => {
    render();
  });
}
