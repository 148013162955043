import fetchNuxeo, { queryParam, baseSearchUrl } from './fetchNuxeo';
import { extractPermissions } from '../../banners/bannersUtils';
import { bannerSetThumbnailName } from '../../reference/filesNames';

const fetchNuxeoBannerSetFromUid = async (uid, justChecking = false) => {
  const errorMessage = `fetchNuxeoBannerSetFromUid: can't fetch bannerset with uid ${uid} from nuxeo`;
  try {
    const query = `SELECT * FROM Document WHERE ecm:primaryType = 'BannerSet' AND ecm:isProxy = 0 AND ecm:isTrashed = 0 AND ecm:uuid = '${uid}'`;

    const params = {
      [queryParam]: query,
    };
    const path = baseSearchUrl;
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json+nxrequest',
      'X-NXproperties': 'dublincore, file, files',
      'X-NXfetch.document': 'lock', // to know if the bannerset has been locked because campaign is ongoing
      'X-NXenrichers.document': 'acls', // to know what permissions are given to the banner-set
    };

    const response = await fetchNuxeo({ path, headers, params, errorMessage })
      .then(res => res.json())
      .catch(e => console.error('fetchNuxeoBannerSetFromUid: error while fetchNuxeo'));

    const bannerSetExists = response.entries && response.entries.length;
    if (justChecking) return Boolean(bannerSetExists);

    if (!bannerSetExists) {
      return null;
    }

    return response.entries.map(entry => {
      const { uid, properties, path, lockOwner } = entry;
      return {
        metadataUrl: properties['file:content'].data,
        name: properties['dc:title'],
        expirationDate: properties['dc:expired'],
        creationDate: properties['dc:created'],
        thumbnailUrl: properties['files:files'].filter(
          el => el.file.name === bannerSetThumbnailName('png') || el.file.name === bannerSetThumbnailName('jpeg'),
        )[0].file.data,
        attachments: properties['files:files'],
        uid,
        lockOwner,
        workspacePath: path,
        permissions: extractPermissions(entry),
      };
    })[0];
  } catch (e) {
    console.error(e);
    throw new Error(errorMessage);
  }
};

export default fetchNuxeoBannerSetFromUid;
