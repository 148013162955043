import { applyStyle, addBlinkAnimation } from '../../util/dom-helpers';
import { getBestResourceId } from '../../../resources/resourcesDucks';

const renderImage = ({
  resourceId,
  optimizedResourceId,
  fileName,
  flipH,
  flipV,
  opacity,
  dropShadowEnabled,
  dropShadow,
  x,
  y,
  width,
  height,
  picWhileCroppingProperties: { rotation: cropRotation, x: cropX, y: cropY, width: cropWidth, height: cropHeight },
  rotation,
  borderRadius,
  borderRadiusUnit,
  borderWidth,
  borderColor,
  blinkAnimationEnabled,
  blinkAnimation,
}) => {
  const dom = document.createElement('div');
  dom.className = 'image';

  const bestResourceId = getBestResourceId(optimizedResourceId, resourceId);
  const bgProps =
    bestResourceId === optimizedResourceId
      ? { backgroundPosition: '0 0', backgroundSize: `${width}px ${height}px` }
      : { backgroundPosition: `${cropX - x}px ${cropY - y}px`, backgroundSize: `${cropWidth}px ${cropHeight}px` };

  applyStyle(dom, {
    ...bgProps,
    backgroundImage: `url('resources/${bestResourceId}/${fileName}')`,
    transform: `scale(${flipH ? -1 : 1}, ${flipV ? -1 : 1}), rotate(${cropRotation - rotation}deg)`,
    borderRadius: `${borderRadius}${borderRadiusUnit}`,
    border: `${borderWidth}px solid ${borderColor}`,
    overflow: 'hidden',
    opacity,
    ...addBlinkAnimation({ blinkAnimationEnabled, blinkAnimation }),
  });
  if (dropShadowEnabled) {
    dom.style.filter =
      `drop-shadow(${dropShadow.offsetX}px ${dropShadow.offsetY}px ` +
      `${dropShadow.blurRadius}px ${dropShadow.color})`;
  }

  return dom;
};

export default renderImage;
