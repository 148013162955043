import { corners } from '../../../reference/corners';

export const checkWidthTooLow = ({ omega, movingCorner }) => {
  switch (movingCorner) {
    case corners.topRight:
      return omega >= Math.PI / 2 && omega <= (3 * Math.PI) / 2;
    case corners.topLeft:
      return omega <= Math.PI / 2 || omega >= (3 * Math.PI) / 2;
    case corners.middleLeft:
      return Math.abs(Math.PI - omega) > Math.PI / 2;
    case corners.bottomRight:
      return omega >= Math.PI / 2 && omega <= (3 * Math.PI) / 2;
    case corners.middleRight:
      return omega >= Math.PI / 2 && omega <= (3 * Math.PI) / 2;
    case corners.bottomLeft:
      return omega >= (3 * Math.PI) / 2 || omega <= Math.PI / 2;
    default:
      return false;
  }
};

export const checkHeightTooLow = ({ lockAspectRatio, diagonal, phi, minHeight, omega, beta, movingCorner }) => {
  if (lockAspectRatio) {
    if (movingCorner === corners.topLeft) {
      return diagonal <= minHeight / Math.sin(phi);
    }
    return false;
  } else {
    switch (movingCorner) {
      case corners.topRight:
      case corners.topLeft:
      case corners.middleTop:
        return (omega + beta + 2 * Math.PI) % (2 * Math.PI) >= Math.PI;
      case corners.bottomRight:
      case corners.middleBottom:
      case corners.bottomLeft:
        return (omega - beta + 2 * Math.PI) % (2 * Math.PI) <= Math.PI;
      default:
        return false;
    }
  }
};
