import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementRotation } from '../../banner/bannerSelectors';
import { setElementRotation } from '../../banner/bannerActionsCreators';
import { degreeEndornment } from '../constants';
import { getRotationToSave } from '../helpers';
import { getSelectedElementId, getSelectedElementCroppingStatus } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementRotation extends React.Component {
  onChangeRotation = ({ value, untrack, id }) => {
    const { setElementRotation, isCropping } = this.props;
    setElementRotation({ isCropping, rotation: getRotationToSave(value), id, untrack }); //tracked
  };

  render() {
    const { id, rotation, intl } = this.props;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        label={intl.formatMessage({ id: 'properties.rotation' })}
        InputProps={degreeEndornment}
        value={Math.abs(Math.round(rotation))}
        fullWidth
        onChangeMethod={this.onChangeRotation} //tracked
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  rotation: makeSelectorInstance(getSelectedElementRotation)(state),
  isCropping: makeSelectorInstance(getSelectedElementCroppingStatus)(state),
});

const dispatchToProps = {
  setElementRotation,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
)(ElementRotation);
