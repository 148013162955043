import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { menuButtonBgColor } from '../reference/colors';
import { menuButtonWidth, menuButtonHeight } from '../reference/dimensions';

const styles = theme => ({
  button: {
    position: 'relative',
    marginLeft: theme.spacing(0.2),
    marginRight: theme.spacing(0.2),
    marginBottom: 15,
    display: 'inline-flex',
    background: menuButtonBgColor,
    color: 'rgba(155,155,155,1)',
    height: menuButtonHeight,
    width: menuButtonWidth,
    fontSize: 11,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    boxShadow: theme.shadows[1],
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
    '&:focus': {
      boxShadow: theme.shadows[4],
    },
    '&:first-of-type': {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    '&:last-of-type': {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
  },
  disabled: {
    cursor: 'default',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  },
  tooltipFixed: {
    textAlign: 'center',
    fontSize: 10,
    position: 'absolute',
    top: 37,
  },
});

const MenuIconButton = ({ disabled, classes, noShadow, iconComponent, tooltip, onClick, label }) => (
  <React.Fragment>
    <Tooltip title={tooltip}>
      <div
        className={classnames(classes.button, disabled && classes.disabled)}
        onClick={onClick}
        style={{
          boxShadow: noShadow && 'none',
        }}
      >
        <IconButton
          disabled={disabled}
          fontSize="inherit"
          disableRipple={true}
          color="primary"
          classes={{
            root: classes.iconContainer,
          }}
        >
          {iconComponent}
        </IconButton>
        <div className={classes.tooltipFixed}>{label}</div>
      </div>
    </Tooltip>
  </React.Fragment>
);

MenuIconButton.propTypes = {
  iconComponent: PropTypes.node.isRequired,
  tooltip: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  noShadow: PropTypes.bool,
};

MenuIconButton.defaultProps = {
  disabled: false,
  noShadow: false,
};

export default withStyles(styles)(MenuIconButton);
