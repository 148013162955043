import CrossFadeIcon from './icons/crossfade.js';
import FadeInIcon from './icons/fadein.js';
import FadeOutIcon from './icons/fadeout.js';
import FadeOutInIcon from './icons/fadeoutin.js';
import EmptyIcon from './icons/empty.js';

//slide transition types
const fade = 'fade';
const translation = 'translation';
const fadeIn = 'fade-in';
const fadeOut = 'fade-out';
const crossFade = 'cross-fade';
const fadeOutIn = 'fade-out-in';
const none = 'none';
const lastSlide = 'last-slide-out';

export const minSlideDurationNotTransiting = 0;

export const slideTransitionTypesVar = {
  fadeIn,
  fadeOut,
  crossFade,
  fadeOutIn,
  none,
  lastSlide,
};

export const slideTransitionsDetails = {
  [fadeIn]: {
    name: fadeIn,
    icon: FadeInIcon,
    crossPrevSlide: 0,
    influence: {
      prevSlide: 0,
      currentSlide: 1,
    },
  },
  [fadeOut]: {
    name: fadeOut,
    icon: FadeOutIcon,
    crossPrevSlide: 0,
    influence: {
      prevSlide: 1, // this transition is happening on prev slide only
      currentSlide: 0,
    },
  },
  [crossFade]: {
    name: crossFade,
    icon: CrossFadeIcon,
    crossPrevSlide: 0.5, // this transition is crossing fully with the prev slide
    influence: {
      prevSlide: 1,
      currentSlide: 1,
    },
  },
  [fadeOutIn]: {
    name: fadeOutIn,
    icon: FadeOutInIcon,
    crossPrevSlide: 0, // this transition is not crossing with the prev slide...
    influence: {
      prevSlide: 0.5, // ...but is happening half on both slides
      currentSlide: 0.5,
    },
  },
  [none]: {
    name: none,
    icon: EmptyIcon,
    crossPrevSlide: 0,
    influence: {
      prevSlide: 0,
      currentSlide: 0,
    },
  },
};

export const slideTransitionIcons = {
  'fade-in': FadeInIcon,
  'fade-out': FadeOutIcon,
  'cross-fade': CrossFadeIcon,
  'fade-out-int': FadeOutInIcon,
  none: EmptyIcon,
};

export const transitionTypesVar = {
  fade,
  translation,
  none,
  lastSlide,
};

export const transitionTypes = [
  transitionTypesVar.fade,
  transitionTypesVar.translation,
  // 'zoom',
  // 'heartbeat',
  transitionTypesVar.none,
];

export const animationNames = transition => {
  switch (transition.type) {
    case translation: {
      switch (transition.direction) {
        case 'fromTopLeft':
          return 'fromTopLeft';
        case 'fromUp':
          return 'fromUp';
        case 'fromTopRight':
          return 'fromTopRight';
        case 'fromLeft':
          return 'fromLeft';
        case 'fromRight':
          return 'fromRight';
        case 'fromBottomLeft':
          return 'fromBottomLeft';
        case 'fromDown':
          return 'fromDown';
        case 'fromBottomRight':
          return 'fromBottomRight';
        case 'toTopLeft':
          return 'toTopLeft';
        case 'toUp':
          return 'toUp';
        case 'toTopRight':
          return 'toTopRight';
        case 'toLeft':
          return 'toLeft';
        case 'toRight':
          return 'toRight';
        case 'toBottomLeft':
          return 'toBottomLeft';
        case 'toDown':
          return 'toDown';
        case 'toBottomRight':
          return 'toBottomRight';
        default:
          return 'fromLeft';
      }
    }
    case fade: {
      return fade;
    }
    default: {
      return 'none';
    }
  }
};

export const defineDistance = (transition, bannerHeight, bannerWidth, defaultDistance) => {
  if (!defaultDistance) return transition.distance || 0;
  switch (transition.direction) {
    case 'fromUp':
    case 'fromDown':
    case 'toUp':
    case 'toDown': {
      return bannerHeight;
    }
    case 'fromLeft':
    case 'fromRight':
    case 'toLeft':
    case 'toRight': {
      return bannerWidth;
    }
    case 'fromTopLeft':
    case 'fromTopRight':
    case 'fromBottomLeft':
    case 'fromBottomRight':
    case 'toTopLeft':
    case 'toTopRight':
    case 'toBottomLeft':
    case 'toBottomRight': {
      return Math.max(bannerWidth, bannerHeight);
    }
    default: {
      return 0;
    }
  }
};

export const animationNamesWithParameter = (transition, bannerHeight, bannerWidth, lastSlide, forceNone) => {
  // TESTME
  if (forceNone) return 'none';
  switch (transition.type) {
    case translation: {
      const distance = defineDistance(transition, bannerHeight, bannerWidth, transition.defaultDistance);
      switch (transition.direction) {
        case 'fromTopLeft':
          return `fromTopLeft-${distance}`;
        case 'fromUp':
          return `fromUp-${distance}`;
        case 'fromTopRight':
          return `fromTopRight-${distance}`;
        case 'fromLeft':
          return `fromLeft-${distance}`;
        case 'fromRight':
          return `fromRight-${distance}`;
        case 'fromBottomLeft':
          return `fromBottomLeft-${distance}`;
        case 'fromDown':
          return `fromDown-${distance}`;
        case 'fromBottomRight':
          return `fromBottomRight-${distance}`;
        case 'toTopLeft':
          return `toTopLeft-${distance}`;
        case 'toUp':
          return `toUp-${distance}`;
        case 'toTopRight':
          return `toTopRight-${distance}`;
        case 'toLeft':
          return `toLeft-${distance}`;
        case 'toRight':
          return `toRight-${distance}`;
        case 'toBottomLeft':
          return `toBottomLeft-${distance}`;
        case 'toDown':
          return `toDown-${distance}`;
        case 'toBottomRight':
          return `toBottomRight-${distance}`;
        default:
          return `fromLeft-${distance}`;
      }
    }
    case fade: {
      return 'fade';
    }
    default: {
      return lastSlide ? 'last-slide' : 'none';
    }
  }
};

export const generateDistanceTransitionsKeyFrame = (direction, distance) => {
  switch (direction) {
    case 'fromTopLeft':
      return `@keyframes fromTopLeft-${distance}-in{from{margin-top:-${distance}px;margin-left:-${distance}px;}to{margin-top:0;margin-left:0;}}`;
    case 'fromUp':
      return `@keyframes fromUp-${distance}-in{from{margin-top:-${distance}px;}to{margin-top:0;}}`;
    case 'fromTopRight':
      return `@keyframes fromTopRight-${distance}-in{from{margin-top:-${distance}px;margin-left:${distance}px;}to{margin-top:0;margin-left:0;}}`;
    case 'fromLeft':
      return `@keyframes fromLeft-${distance}-in{from{margin-left:-${distance}px;}to{margin-left:0;}}`;
    case 'fromRight':
      return `@keyframes fromRight-${distance}-in{from{margin-left:${distance}px;}to{margin-left:0;}}`;
    case 'fromBottomLeft':
      return `@keyframes fromBottomLeft-${distance}-in{from{margin-top:${distance}px;margin-left:-${distance}px;}to{margin-top:0;margin-left:0;}}`;
    case 'fromDown':
      return `@keyframes fromDown-${distance}-in{from{margin-top:${distance}px;}to{margin-top:0;}}`;
    case 'fromBottomRight':
      return `@keyframes fromBottomRight-${distance}-in{from{margin-top:${distance}px;margin-left:${distance}px;}to{margin-top:0;margin-left:0;}}`;
    case 'toTopLeft':
      return `@keyframes toTopLeft-${distance}-out{from{margin-top:0;margin-left:0;}to{margin-top:-${distance}px;margin-left:-${distance}px;}}`;
    case 'toUp':
      return `@keyframes toUp-${distance}-out{from{margin-top:0;}to{margin-top:-${distance}px;}}`;
    case 'toTopRight':
      return `@keyframes toTopRight-${distance}-out{from{margin-top:0;margin-left:0;}to{margin-top:-${distance}px;margin-left:${distance}px;}}`;
    case 'toLeft':
      return `@keyframes toLeft-${distance}-out{from{margin-left:0;}to{margin-left:-${distance}px;}}`;
    case 'toRight':
      return `@keyframes toRight-${distance}-out{from{margin-left:0;}to{margin-left:${distance}px;}}`;
    case 'toBottomLeft':
      return `@keyframes toBottomLeft-${distance}-out{from{margin-top:0;margin-left:0;}to{margin-top:${distance}px;margin-left:-${distance}px;}}`;
    case 'toDown':
      return `@keyframes toDown-${distance}-out{from{margin-top:0;}to{margin-top:${distance}px;}}`;
    case 'toBottomRight':
      return `@keyframes toBottomRight-${distance}-out{from{margin-top:0;margin-left:0;}to{margin-top:${distance}px;margin-left:${distance}px;}}`;
    default:
      console.error(direction, 'is not defined');
      return ``;
  }
};
