import React from 'react';
import classNames from 'classnames';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { FormattedMessage } from 'react-intl';

import { getUser, isUserAuthenticated } from '../shared-selectors/sharedSelectors';
import { getConfig } from '../config/configDucks';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { appBarHeight } from '../reference/dimensions';
import { openSaveBannersDialogRequested } from '../save-banners-dialog/saveBannersDucks';
import { login, logout } from 'drb-commons-front';
import { getValueFromUrlParameter, redirectUrlParam } from '../reference/urlParams';

const debug = true;

const styles = theme => ({
  userButton: {
    textTransform: 'none',
    fontWeight: 500,
    fontSize: 16,
    color: '#CBCBCB',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  loggedIn: {
    // paddingRight: 5,
    transform: `translateY(-${appBarHeight / 3}px)`,
  },
  logo: {
    width: 40,
    height: 40,
    '& > img': {
      width: 40,
      height: 40,
    },
  },
  debug: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: 350,
    height: 20,
    zIndex: 100000,
    background: 'rgba(236, 236, 236, 1.00)',
    borderRadius: 5,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    fontSize: 10,
  },
});

class MenuUser extends React.PureComponent {
  state = {
    anchorEl: null,
  };

  onOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  onClose = () => {
    this.setState({ anchorEl: null });
  };

  onBackToDrBanner = () => {
    const { openSaveBannersDialogRequested, drBannerUrl } = this.props;
    openSaveBannersDialogRequested({ redirection: drBannerUrl });
  };

  render() {
    const { classes, authenticated, user, drBannerUrl, backToDrBanner } = this.props;
    const { anchorEl } = this.state;
    const drBannerMessage = backToDrBanner ? 'app.menu.back-to-drbanner' : 'app.menu.go-to-drbanner';
    if (authenticated) {
      return (
        <React.Fragment>
          <div style={{ display: 'flex' }}>
            <Button
              onClick={this.onBackToDrBanner}
              className={classNames(classes.userButton, classes.loggedIn)}
              color="inherit"
            >
              <FormattedMessage id={drBannerMessage} />
              <ChevronRight />
            </Button>
          </div>
          {process.env.NODE_ENV === 'development' && debug && (
            <React.Fragment>
              <Button
                aria-owns={anchorEl ? 'user-menu' : null}
                aria-haspopup="true"
                onClick={this.onOpen}
                className={classNames(classes.userButton, classes.debug)}
                color="inherit"
              >
                dev mode only: {user.email}
                <ArrowDropDownIcon />
              </Button>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={this.onClose}
              >
                <MenuItem onClick={logout}>
                  <FormattedMessage id="app.menu.logout" />
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }
    return (
      <div style={{ display: 'flex' }}>
        <Button onClick={() => login()} className={classes.userButton} color="inherit">
          <FormattedMessage id="app.menu.login" />
          <a href={drBannerUrl} className={classes.logo}>
            Go
          </a>
        </Button>
      </div>
    );
  }
}

const makeStateToProps = () => state => ({
  authenticated: makeSelectorInstance(isUserAuthenticated)(state),
  user: makeSelectorInstance(getUser)(state),
  drBannerUrl: getValueFromUrlParameter(redirectUrlParam, makeSelectorInstance(getConfig)(state).drBannerUrl),
});

const dispatchToProps = {
  openSaveBannersDialogRequested,
};
export default compose(connect(makeStateToProps, dispatchToProps), withStyles(styles))(MenuUser);
