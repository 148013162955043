import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose, onlyUpdateForKeys } from 'recompose';
import { withStyles, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { transitionTypes } from '../../reference/transitions';
import { getBannerFormat } from '../../shared-selectors/sharedSelectors';
import { getSelectedElementTransitionInType, getSelectedElement } from '../../banner/bannerSelectors';
import { setElementTransitionParameters } from '../../banner/bannerActionsCreators';
import { styles } from '../propertyEditorStyles';
import { verifyTransitionType } from '../helpers';
import {
  toggleTransitionInMenuOpen,
  getTemporaryTransitionInMenuOpen,
} from '../../temporary-text-editor/temporaryTextEditorDucks';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

const selectTransitionInTypeInputPropId = { id: 'transition-in-type' };

class ElementTransitionInType extends React.Component {
  state = { classesState: {} };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { classes } = nextProps;
    return {
      ...prevState,
      classesState: {
        menuItem: { selected: classes.menuSelected, root: classes.menuItem },
      },
    };
  }

  onTransitionTypeChange = e => {
    const {
      format: { bannerWidth },
      element,
      setElementTransitionParameters,
    } = this.props;
    verifyTransitionType({
      bannerWidth,
      element,
      newType: e.target.value,
      setElementTransitionParameters,
      transitionName: 'transitionIn',
      minDuration: element.duration - element.transitionOut.duration - element.transitionIn.delay,
    });
  };

  onOpen = () => {
    const { toggleTransitionInMenuOpen } = this.props;
    toggleTransitionInMenuOpen(true);
  };

  onClose = () => {
    const { toggleTransitionInMenuOpen } = this.props;
    toggleTransitionInMenuOpen(false);
  };

  render() {
    const { type, open } = this.props;
    const { classesState } = this.state;
    return (
      <FormControl fullWidth>
        <InputLabel htmlFor="transition-in-type">
          <FormattedMessage id="properties.transition.type" />
        </InputLabel>
        <Select
          open={open}
          value={type || ''}
          inputProps={selectTransitionInTypeInputPropId}
          onChange={this.onTransitionTypeChange}
          onOpen={this.onOpen}
          onClose={this.onClose}
        >
          {transitionTypes.map(transition => (
            <MenuItem key={transition} value={transition} classes={classesState.menuItem}>
              <FormattedMessage id={`properties.transition.type.${transition}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  format: makeSelectorInstance(getBannerFormat)(state),
  element: makeSelectorInstance(getSelectedElement)(state),
  type: makeSelectorInstance(getSelectedElementTransitionInType)(state),
  open: makeSelectorInstance(getTemporaryTransitionInMenuOpen)(state),
});

const dispatchToProps = {
  setElementTransitionParameters,
  toggleTransitionInMenuOpen,
};
export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  onlyUpdateForKeys(['type', 'open']),
  withStyles(styles),
)(ElementTransitionInType);
