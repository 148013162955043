import JSZip from 'jszip';
import { objectUrlToBlob } from './objectUrlToBlob';
import { debug } from './params';
import { getResourceUrl } from '../../resources/resourcesDucks';
import { getBannerDataToZip } from './getBannerDataToZip';
import {
  bannerSetThumbnailName,
  bannerThumbnailName,
  playerName,
  metadataJSONName,
  htmlName,
  originalResourcesZipName,
} from '../../reference/filesNames';
import { zipImageResourcesBanner } from './zipImageResourcesBanner';
import { zipImageResourcesBannerSet } from './zipImageResourcesBannerSet';
import { zipFontResources } from './zipFontResources';
import { jpegType, zipType, jsonType } from '../../reference/importParams';
import { exportBannerToPlayer } from './exportBannerToPlayer';
import { cleanBannerSet } from './cleanBannerSet';
import { bannerElementsFilenamesRandomiser } from '../exportHelpers';
/* STRUCTURE  :
  export:
  fileContent: <JSON>,
  attachments: Array.

  attachments:
    -> thumbnail.png: bannerSet thumbnail
    -> {BANNER_FORMAT}_thumbnail.png: banner thumbnail (as many thumbnails as banners)
    -> {BANNER_FORMAT}.player.zip : zip of player for each banner :
      -> resources folder
      -> fonts folder
      -> index.html
*/

export const makeBannersReadyToSaveOrExport = async (banners, userFonts, bannerSetInfos, dcoKeys, forNuxeo) => {
  try {
    let attachments = [];

    // 1. build the fileContent, i.e. the metadata.json
    const bannersDataToZip = banners.map(banner => getBannerDataToZip(banner, userFonts));
    const bannersJs = bannersDataToZip.map(
      ({ jsBanner }) => (forNuxeo ? jsBanner : bannerElementsFilenamesRandomiser(jsBanner)),
    );
    const metadata = JSON.stringify({ banners: bannersJs, bannerSet: cleanBannerSet(bannerSetInfos), dcoKeys });
    const fileContent = {
      file: metadata,
      fileName: metadataJSONName,
      fileType: jsonType,
    };

    // 2. build the bannerSet thumbnail
    const thumbnail = await objectUrlToBlob(
      getResourceUrl(bannerSetInfos.thumbnailResourceId, 'makeBannersReadyToSaveOrExport thumbnail'),
    );
    const bannerSetThumbnail = {
      file: thumbnail,
      fileName: bannerSetThumbnailName('jpeg'),
      fileType: jpegType,
    };
    debug && console.log({ bannerSetThumbnail });
    attachments.push(bannerSetThumbnail);

    // 3. build the banners thumbnails
    for (const banner of bannersJs) {
      const { thumbnailResourceId, format } = banner;
      const image = await objectUrlToBlob(getResourceUrl(thumbnailResourceId, 'makeBannersReadyToSaveOrExport image'));
      const bannerThumbnail = {
        file: image,
        fileName: bannerThumbnailName(format, 'jpeg'),
        fileType: jpegType,
      };
      debug && console.log({ bannerThumbnail });
      attachments.push(bannerThumbnail);
    }

    // 4. build the players
    /* // OLD STYLE
    const css = await loadFile('/player/styles.css');
    const html = await loadFile('/player/index.html');
    const js = await loadFile('/player/main.js');
    console.log({ html, css, js });
    */
    for (const bannerData of bannersDataToZip) {
      const { jsBanner, userFontNames, customFontNames } = bannerData;
      const playerContentZip = new JSZip();
      playerContentZip.file(htmlName, exportBannerToPlayer(jsBanner));
      zipImageResourcesBanner(jsBanner, playerContentZip);
      zipFontResources(userFontNames.flatten().toSet(), userFonts, playerContentZip, customFontNames.flatten().toSet());
      let playerContentZipBlob;
      await playerContentZip.generateAsync({ type: 'blob' }).then(zipBlob => (playerContentZipBlob = zipBlob));
      const playerContentToSave = {
        file: playerContentZipBlob,
        fileName: playerName(jsBanner.format),
        fileType: zipType,
      };
      debug && console.log({ playerContentToSave });
      attachments.push(playerContentToSave);
    }

    // 5. save the original resources
    const originalImagesZip = new JSZip();
    zipImageResourcesBannerSet(bannersJs, originalImagesZip);
    let originalImagesZipBlob;
    await originalImagesZip.generateAsync({ type: 'blob' }).then(zipBlob => (originalImagesZipBlob = zipBlob));
    attachments.push({
      file: originalImagesZipBlob,
      fileName: originalResourcesZipName,
      fileType: zipType,
    });

    return {
      fileContent,
      attachments,
    };
  } catch (e) {
    console.error('error in makeBannersReadyToSaveOrExport', e);
  }
};
