import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Collapse,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import intlPropType from '../prop-types/intl';

import BannerFormats from './BannerFormats';
import {
  closeFormatSelectorDialog,
  unfoldDialog,
  selectAllFormats,
  getBannerFormatDialogOpen,
  isFormatSelectorUnfolded,
  selectSelectedBannerFormats,
  selectIsOpenForBannerSetCreation,
} from './bannerFormatSelectorDucks';
import { checkIfNoBanners } from '../shared-selectors/sharedSelectors';
import { bannerFormats, additionalFormats } from '../reference/bannerFormats';
import { initBannerSetName } from '../reference/bannerSetInitProperties';
import { doesContainEuroSymbol } from '../components/forms/rules/doesContainEuroSymbol';
import { createNewBannerSet } from '../banners/bannersActionsCreators';
import { compose } from 'recompose';
import makeSelectorInstance from '../reference/makeSelectorInstance';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '90vw',
    minWidth: 1150,
  },
  bannerFormatSelector: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bannerSetName: {
    flexShrink: 0,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
}));

/**
 * Return errors when the form is not valid
 * @param {string | undefined} bannerSetName
 * @param {object} intl
 * @returns {string | undefined} The form error
 */
const validate = (bannerSetNameValue, intl) => {
  if (doesContainEuroSymbol(bannerSetNameValue)) {
    return intl.formatMessage({ id: 'bannerSetCreate.dialog.bannerSetName.error' });
  }

  return undefined;
};

const BannerFormatSelector = memo(
  ({
    intl,
    isOpen,
    hasNoBanners,
    isOpenForBannerSetCreation,
    selectedBannerFormats,
    isUnfolded,
    closeFormatSelectorDialogAction,
    unfoldDialogAction,
    createNewBannerSetAction,
  }) => {
    /* Vars */

    const [bannerSetNameValue, changeBannerSetNameValue] = useState('');
    const [bannerSetNameError, changeBannerSetNameError] = useState(undefined);
    const classes = useStyles();
    const forbidBannerSetCreation =
      selectedBannerFormats.length === 0 || !bannerSetNameValue || bannerSetNameValue === initBannerSetName;

    /* Callback */

    const handleDialogClose = useCallback(() => {
      closeFormatSelectorDialogAction();
    }, [closeFormatSelectorDialogAction]);

    const handleDialogUnfold = useCallback(() => {
      unfoldDialogAction();
    }, [unfoldDialogAction]);

    const handleCreateButtonClick = useCallback(() => {
      const error = validate(bannerSetNameValue, intl);

      if (error) {
        changeBannerSetNameError(error);
      } else {
        createNewBannerSetAction({ name: bannerSetNameValue });
      }
    }, [bannerSetNameValue, createNewBannerSetAction, intl]);

    const handleBannerSetNameChange = useCallback(
      evt => {
        const { value } = evt.target;
        const error = validate(value, intl);

        changeBannerSetNameError(error);
        changeBannerSetNameValue(value);
      },
      [intl],
    );

    return (
      <Dialog
        open={isOpen}
        key={isOpen}
        classes={{ paperWidthSm: classes.paper, paperWidthMd: classes.paper, paperWidthXs: classes.paper }}
        onClose={handleDialogClose}
        disableBackdropClick={hasNoBanners}
        disableEscapeKeyDown={hasNoBanners}
        transitionDuration={{ exit: 0 }}
      >
        <DialogTitle>
          <FormattedMessage
            id={isOpenForBannerSetCreation ? 'bannerSetCreate.dialog.title' : 'bannerFormat.dialog.title'}
          />
        </DialogTitle>
        <DialogContent classes={{ root: classes.bannerSetName }}>
          {isOpenForBannerSetCreation && (
            <Grid container spacing={4} alignItems="flex-end">
              <Grid item xs={4} />
              <Grid item xs={4}>
                <TextField
                  name="bannerSet-name"
                  id="bannerSet-name"
                  type="text"
                  label={intl.formatMessage({ id: 'bannerSetCreate.dialog.bannerSetName' })}
                  fullWidth
                  placeholder={initBannerSetName}
                  onChange={handleBannerSetNameChange}
                  helperText={bannerSetNameError}
                  value={bannerSetNameValue}
                  error={Boolean(bannerSetNameError)}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogContent>
          <BannerFormats
            selectAllFormats={selectAllFormats}
            formats={bannerFormats}
            classes={classes}
            selectedFormats={selectedBannerFormats}
          />
          {!isUnfolded && (
            <Button onClick={handleDialogUnfold} color="inherit">
              <FormattedMessage id="bannerFormat.dialog.more" />
            </Button>
          )}
          <Collapse in={isUnfolded}>
            <BannerFormats
              selectAllFormats={selectAllFormats}
              formats={additionalFormats}
              classes={classes}
              selectedFormats={selectedBannerFormats}
            />
          </Collapse>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>
            <FormattedMessage id="alert.cancel" />
          </Button>
          {isOpenForBannerSetCreation && (
            <Button type="button" color="primary" onClick={handleCreateButtonClick} disabled={forbidBannerSetCreation}>
              <FormattedMessage id="bannerSetCreate.dialog.createBannerSet" />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  },
);

BannerFormatSelector.propTypes = {
  intl: intlPropType,
  // makeStateToProps
  isOpen: PropTypes.bool.isRequired,
  hasNoBanners: PropTypes.bool.isRequired,
  isOpenForBannerSetCreation: PropTypes.bool.isRequired,
  selectedBannerFormats: PropTypes.array.isRequired,
  isUnfolded: PropTypes.bool.isRequired,
  // dispatchToProps
  closeFormatSelectorDialogAction: PropTypes.func.isRequired,
  unfoldDialogAction: PropTypes.func.isRequired,
  createNewBannerSetAction: PropTypes.func.isRequired,
};

const makeStateToProps = () => state => ({
  isOpen: makeSelectorInstance(getBannerFormatDialogOpen)(state),
  hasNoBanners: makeSelectorInstance(checkIfNoBanners)(state),
  isOpenForBannerSetCreation: makeSelectorInstance(selectIsOpenForBannerSetCreation)(state),
  selectedBannerFormats: makeSelectorInstance(selectSelectedBannerFormats)(state),
  isUnfolded: makeSelectorInstance(isFormatSelectorUnfolded)(state),
});

const dispatchToProps = {
  closeFormatSelectorDialogAction: closeFormatSelectorDialog,
  unfoldDialogAction: unfoldDialog,
  createNewBannerSetAction: createNewBannerSet,
};

export default compose(connect(makeStateToProps, dispatchToProps), injectIntl)(BannerFormatSelector);
