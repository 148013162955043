import React from 'react';
import { connect } from 'react-redux';
import { FormControl, RadioGroup, Radio, FormControlLabel, withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { styles } from '../propertyEditorStyles';
import { getSelectedElements } from '../../banner/bannerSelectors';
import { updateElements } from '../../banner/bannerActionsCreators';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import classNames from 'classnames';
import { allElementsHasPropertyChecked, someElementsHasPropertyChecked } from '../helpers';
import { getSelectedElementIds } from '../../temporary-status/temporaryStatusDucks';

const computeValue = elements => {
  if (allElementsHasPropertyChecked(elements, 'transitionsOnAllSlides')) return 'all-slides';
  if (!someElementsHasPropertyChecked(elements, 'transitionsOnAllSlides')) return 'first-and-last';
  return null;
};

class ElementsTransitionShowOnAllSlides extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return {
      classesState: {
        radio: { root: classes.radioButton },
        radioGroup: { root: classNames(classes.radioGroup, classes.radioGroupCentered) },
      },
    };
  }

  toggleTransitionOnAllSlides = e => {
    const { elements, updateElements, ids } = this.props;
    updateElements({ ids, transitionsOnAllSlides: !allElementsHasPropertyChecked(elements, 'transitionsOnAllSlides') }); // tracked
  };

  render() {
    const { elements, intl } = this.props;
    const { classesState } = this.state;

    return (
      <React.Fragment>
        <FormControl fullWidth>
          <RadioGroup
            name="default distance"
            classes={classesState.radioGroup}
            value={computeValue(elements)}
            onChange={this.toggleTransitionOnAllSlides}
          >
            <FormControlLabel
              value="all-slides"
              //eslint-disable-next-line
              control={<Radio color="primary" fontSize="10" classes={classesState.radio} />}
              label={intl.formatMessage({ id: 'properties.transition.showOnAllSlides' })}
            />
            <FormControlLabel
              value="first-and-last"
              //eslint-disable-next-line
              control={<Radio color="primary" fontSize="10" classes={classesState.radio} />}
              label={intl.formatMessage({ id: 'properties.transition.showOnFirstAndLastSlides' })}
            />
          </RadioGroup>
        </FormControl>
      </React.Fragment>
    );
  }
}

const makeStateToProps = () => state => ({
  elements: makeSelectorInstance(getSelectedElements)(state),
  ids: makeSelectorInstance(getSelectedElementIds)(state),
});

const dispatchToProps = {
  updateElements,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(ElementsTransitionShowOnAllSlides);
