import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { CircularProgress } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';

import fetchNuxeo from '../api/fetchNuxeo';
import { addImageElementFromNuxeo } from '../banner/bannerActionsCreators';
import { getUser, isUserAuthenticated } from '../shared-selectors/sharedSelectors';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { addError } from '../messages/messagesDucks';
import { LoggedOutMessage } from 'drb-commons-front';

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: '150px',
    gridGap: '10px',
    width: '100%',
    height: 500,
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    marginRight: 10,
    marginBottom: 10,
    cursor: 'pointer',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gridColumnEnd: 'span 3',
  },
  message: {
    gridColumnEnd: 'span 3',
  },
};

class NewImageFromNuxeo extends React.PureComponent {
  state = {
    images: null,
    hasMore: true,
  };

  fetchThumbnails = async page => {
    try {
      const {
        user: { workspacePath },
      } = this.props;
      const response = await fetchNuxeo.imageThumbnails(workspacePath, page);
      const { images, totalPages } = response;
      this.setState({ images: [...(this.state.images || []), ...(images || [])], hasMore: totalPages > page + 1 });
    } catch (e) {
      console.error('not able to fetch thumbnails', e);
      this.props.addError('nuxeo.images.error-fetching');
      this.props.onError();
    }
  };

  render() {
    const { images, hasMore } = this.state;
    const { classes, addImageElementFromNuxeo, authenticated } = this.props;

    return authenticated ? (
      <InfiniteScroll
        pageStart={-1} // So first page is 0
        hasMore={hasMore}
        loadMore={this.fetchThumbnails}
        loader={
          <div className={classes.loader} key="image-loader">
            <CircularProgress />
          </div>
        }
        useWindow={false}
        className={classes.root}
      >
        {images &&
          images.map((image, idx) => (
            <div
              key={`image-${idx}-${image.id}`}
              className={classes.thumbnail}
              onClick={() => addImageElementFromNuxeo(image)}
              style={{
                backgroundImage: `url("${image.thumbnailUrl}")`,
              }}
            />
          ))}
        {images &&
          !images.length && (
            <div className={classes.message} key="no-image">
              <FormattedMessage id="nuxeo.images.no-results" />
            </div>
          )}
      </InfiniteScroll>
    ) : (
      <LoggedOutMessage />
    );
  }
}

const makeStateToProps = () => state => ({
  authenticated: makeSelectorInstance(isUserAuthenticated)(state),
  user: makeSelectorInstance(getUser)(state),
});

const dispatchToProps = {
  addImageElementFromNuxeo,
  addError,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  withStyles(styles),
)(NewImageFromNuxeo);
