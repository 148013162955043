import { createSelector } from 'reselect';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getBannerFormat } from '../shared-selectors/sharedSelectors';
import {
  getBannerName,
  getBannerBgColor,
  getBannerUniqueIdentification,
  getBannerSlides,
} from '../banner/bannerSelectors';
import { getBannersFromUniqueIdentifications } from '../banners/bannersSelectors';
import { List } from 'immutable';

export const isExporting = ({ exportToImages }) => exportToImages.exporting;

export const getExportDialogKey = ({ exportToImages }) => exportToImages.dialogKey;

export const getSlidesToExportToImages = ({ exportToImages }) => exportToImages.slidesToExportToImages;

export const getBannersToRenderForImageExport = ({ exportToImages }) => exportToImages.bannersToRenderForImageExport;

export const getExportState = ({ exportToImages }) => exportToImages;

export const isRenderingSlidesForExportToImages = ({ exportToImages }) =>
  exportToImages.renderingSlidesForExportToImages;

export const getToggleProgressBar = ({ exportToImages }) => exportToImages.toggleProgressBar;

export const getProgress = ({ exportToImages }) => exportToImages.progress;

export const getNumberOfFramesToRender = ({ exportToImages }) => exportToImages.numberOfFramesToRender;

export const isRenderingCurrentSceneForSnapshot = ({ exportToImages }) =>
  exportToImages.renderingCurrentSceneForSnapshot;

export const isRenderingBannersForExportToAnimatedGIF = ({ exportToImages }) =>
  exportToImages.renderingBannersForExportToAnimatedGIF;

export const makeSlidesToExportToImages = bannersUniqueIds =>
  createSelector(getBannersFromUniqueIdentifications(bannersUniqueIds), banners =>
    List(
      banners.map(banner => {
        const { bannerHeight: height, bannerWidth: width } = makeSelectorInstance(getBannerFormat)({ banner });
        return makeSelectorInstance(getBannerSlides)({ banner }).map(slide => ({
          slide,
          bannerUniqueId: makeSelectorInstance(getBannerUniqueIdentification)({ banner }),
          bannerName: makeSelectorInstance(getBannerName)({ banner }),
          bannerFormat: { height, width },
          bannerBackgroundColor: makeSelectorInstance(getBannerBgColor)({ banner }),
        }));
      }),
    ).flatten(),
  );
