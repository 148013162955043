export const maxUploadSize = 1024 * 1024;
export const maxBannerUploadSize = 10 * 1024 * 1024;

export const pngType = 'image/png';
export const jpegType = 'image/jpeg';
export const playerZipExtension = '.player.zip';
export const bannerZipExtension = '.banner.zip';
export const bannerSetZipExtension = '.bannerSet.zip';
export const zipType = 'application/zip';
export const jsonType = 'application/json';

export const acceptedImageFileTypes = [jpegType, pngType, 'image/gif', '.jpeg', '.jpg', '.png', '.gif'];
export const acceptedBannerFileTypes = [zipType, 'application/x-zip-compressed', bannerZipExtension];
export const acceptedBannerSetFileTypes = [zipType, 'application/x-zip-compressed', bannerSetZipExtension];
export const acceptedTemplateFileTypes = ['text/csv', 'text/plain', '.csv', 'application/vnd.ms-excel']; //https://github.com/react-dropzone/react-dropzone/issues/276
export const acceptedFontExtensions = ['.woff'];
export const rejectedFontExtensions = ['.ttf', '.otf', '.woff2', '.eot', '.sfnt'];
export const acceptedFontTypes = [...acceptedFontExtensions];
export const acceptedLocalizationFileTypes = ['text/x-java-properties', '.properties', ''];

export const acceptedFileTypes = [
  ...acceptedImageFileTypes,
  ...acceptedBannerFileTypes,
  ...acceptedTemplateFileTypes,
  ...acceptedFontTypes,
  ...acceptedLocalizationFileTypes,
];

export const checkFileType = (extensions, file, needAll = false) => {
  let hasMIMEType = false;
  if (extensions.filter(ext => ext !== '').includes(file.type)) {
    hasMIMEType = true;
  }

  let hasExtension = false;
  extensions
    .filter(ext => ext !== '')
    .forEach(extension => {
      if (file.name.endsWith(extension)) {
        hasExtension = true;
      }
    });
  return needAll ? hasMIMEType && hasExtension : hasMIMEType || hasExtension;
};

export const checkFilesType = (extensions, files, needAll = false) =>
  files.map(file => checkFileType(extensions, file, needAll)).filter(isAccepted => !isAccepted).length === 0;

export const isBanner = file => checkFileType(acceptedBannerFileTypes, file, true);
export const isBannerSet = file => checkFileType(acceptedBannerSetFileTypes, file, true);
export const isImage = file => checkFileType(acceptedImageFileTypes, file);
export const isTemplate = file => checkFileType(acceptedTemplateFileTypes, file);
export const isFont = file => checkFileType(acceptedFontTypes, file);
export const isLocalization = file => checkFileType(acceptedLocalizationFileTypes, file);

export const areBanners = files => checkFilesType(acceptedBannerFileTypes, files, true);
export const areBannerSets = files => checkFilesType(acceptedBannerSetFileTypes, files, true);
export const areImages = files => checkFilesType(acceptedImageFileTypes, files);
export const areTemplates = files => checkFilesType(acceptedTemplateFileTypes, files);
export const areFonts = files => checkFilesType(acceptedFontTypes, files);
export const areLocalizations = files => checkFilesType(acceptedLocalizationFileTypes, files);
export const areUnacceptedFonts = files => checkFilesType(rejectedFontExtensions, files);
export const areUnacceptedFiles = files => !checkFilesType(acceptedFileTypes, files);
export const areMixedFiles = files => checkFilesType(acceptedFileTypes, files);

export const acceptedImageFileTypesTypesAsString = acceptedImageFileTypes.join(', ');
export const acceptedLocalizationFileTypesAsString = acceptedLocalizationFileTypes.join(', ');
export const acceptedFileTypesAsString = acceptedFileTypes.join(', ');
export const acceptedFontExtensionsAsString = acceptedFontExtensions.join(', ');

export const removeFile = index => state => ({
  files: [...state.files.slice(0, index), ...state.files.slice(index + 1)],
});
