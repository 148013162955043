import { List } from 'immutable';
import {
  slideFromJS,
  SizeRecord,
  SlideRecord,
  BannerRecord,
  DropShadowRecord,
  ElementTransitionRecord,
  SlideTransitionRecord,
  BlinkAnimationRecord,
  TextElementRecord,
  CroppingPropertiesRecord,
  RectangleElementRecord,
  ButtonElementRecord,
  ImageElementRecord,
} from '../reference/records';
import { slideTransitionsDetails, slideTransitionTypesVar } from '../reference/transitions';
import { lineHeightFactor } from '../reference/textProperties';
import { newIntlMessage } from '../i18n/i18nDucks';

export const newBannerName = format => format.name;
export const formatI18nBannerNameId = (intl, name) =>
  intl.formatMessage({ id: `bannerFormat.${name.toLowerCase()}` }).toUpperCase();

export const createBanner = name => BannerRecord({ name });

export const centerInBanner = (bannerLength, length) => Math.floor((bannerLength - length) / 2);

export const calculateImageSize = (maxWidth, maxHeight, { width, height }) => {
  let resizeWidthFactor = 1;
  let resizeHeightFactor = 1;
  if (width > maxWidth) {
    resizeWidthFactor = maxWidth / width;
  }
  if (height > maxHeight) {
    resizeHeightFactor = maxHeight / height;
  }
  const resizeFactor = Math.min(resizeWidthFactor, resizeHeightFactor);
  return {
    width: width * resizeFactor,
    height: height * resizeFactor,
  };
};

export const createElement = (bannerWidth, bannerHeight, width, height, duration) => ({
  x: centerInBanner(bannerWidth, width),
  y: centerInBanner(bannerHeight, height),
  height,
  width,
  duration,
});

const textPlaceholder = idNumber => newIntlMessage('properties.defaultText', { index: idNumber });

export const createTextElement = ({ bannerWidth, bannerHeight, idNumber, duration, elementProperties }) => {
  // TESTME
  const fontSize = 16;
  const newTextLineHeight = lineHeightFactor * fontSize;
  const height = newTextLineHeight + 6; //6 === 3px + 3px === padding top and bottom as required
  const width = elementProperties.text ? Math.max(150, elementProperties.text.length * 10) : 150;
  return TextElementRecord({
    id: `text-${idNumber}`,
    ...createElement(bannerWidth, bannerHeight, width, height, duration),
    text: textPlaceholder(idNumber),
    fontSize,
    ...elementProperties,
  });
};

const rectangleSize = {
  width: 1000,
  height: 1000,
};

export const createRectangleElement = ({ bannerWidth, bannerHeight, idNumber, duration, elementProperties }) => {
  const { width, height } = calculateImageSize(bannerWidth, bannerHeight, rectangleSize);
  return RectangleElementRecord({
    id: `rectangle-${idNumber}`,
    ...createElement(bannerWidth, bannerHeight, width, height, duration),
    originalSize: SizeRecord({ width, height }),
    ...elementProperties,
  });
};

const buttonSize = {
  width: 112,
  height: 35,
};

export const createButtonElement = ({ bannerWidth, bannerHeight, idNumber, duration, elementProperties }) =>
  ButtonElementRecord({
    id: `button-${idNumber}`,
    ...createElement(bannerWidth, bannerHeight, buttonSize.width, buttonSize.height, duration),
    text: newIntlMessage('properties.defaultButtonText', {
      index: idNumber,
    }),
    ...elementProperties,
  });

export const createImageElement = ({
  bannerWidth,
  bannerHeight,
  idNumber,
  duration,
  elementProperties,
  resourceId,
}) => {
  const { width: imageWidth, height: imageHeight, ...properties } = elementProperties;

  // keep this code if we want to have image sized the size of the banner
  // const { width, height } = calculateImageSize(
  //   imageWidth > bannerWidth ? imageWidth : bannerWidth,
  //   imageHeight > bannerHeight ? imageHeight : bannerHeight,
  //   { width: imageWidth, height: imageHeight },
  // );
  const dimensions = createElement(bannerWidth, bannerHeight, imageWidth, imageHeight);

  return ImageElementRecord({
    id: `image-${idNumber}`,
    ...dimensions,
    duration,
    resourceId,
    originalSize: SizeRecord({ width: imageWidth, height: imageHeight }),
    picWhileCroppingProperties: CroppingPropertiesRecord({
      ...dimensions,
    }),
    ...properties,
  });
};

export const createSlide = ({ transition, ...properties }) =>
  SlideRecord({
    ...properties,
    transition: SlideTransitionRecord({ ...transition }),
  });

export const getImageSize = url =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = () => reject('Error loading image');
    img.src = url;
  });

export const getElementMinDuration = ({ transitionIn, transitionOut }) =>
  transitionIn.delay + transitionIn.duration + transitionOut.duration;

/**
 * Add for each element its minDuration
 * @param {Array<object>} elements
 * @returns {Array<{
 *  element: object,
 *  minDuration: number,
 * }>} elementsMinDuration
 */
export const getElementsMinDuration = elements =>
  elements.map(element => ({
    element,
    minDuration: getElementMinDuration(element),
  }));

/**
 * Return the shortest duration possible for a slide
 * @param {Array<{
 *  element: object,
 *  minDuration: number,
 * }>} elementsMinDurations
 * @returns {number}
 */
export const getSlideShortestDurationPossible = elementsMinDurations => {
  return Math.max(...elementsMinDurations.map(({ minDuration }) => minDuration));
};

export const createShadow = () => DropShadowRecord();
export const createBlink = () => BlinkAnimationRecord();

export const checkNewElementDuration = (element, slide, oldSlide) => {
  const slideDuration = slide.duration;
  const oldSlideDuration = oldSlide.duration;

  let elMinDuration = getElementMinDuration(element);

  if (element.duration < oldSlideDuration) {
    // it means there is a "delay out", so we want to reproduce in the new slide
    const delayOut = oldSlideDuration - element.duration;
    elMinDuration = elMinDuration + delayOut;

    if (elMinDuration > slideDuration) {
      const f = slideDuration / elMinDuration;
      return {
        duration: slideDuration - delayOut * f,
        transitionIn: ElementTransitionRecord({
          ...element.transitionIn,
          duration: element.transitionIn.duration * f,
          delay: element.transitionIn.delay * f,
        }),
        transitionOut: ElementTransitionRecord({
          ...element.transitionOut,
          duration: element.transitionOut.duration * f,
        }),
      };
    }

    return {
      duration: slideDuration - delayOut,
      transitionIn: element.transitionIn,
      transitionOut: element.transitionOut,
    };
  }

  if (elMinDuration > slideDuration) {
    const f = slideDuration / elMinDuration;
    return {
      duration: slideDuration,
      transitionIn: ElementTransitionRecord({
        ...element.transitionIn,
        duration: element.transitionIn.duration * f,
        delay: element.transitionIn.delay * f,
      }),
      transitionOut: ElementTransitionRecord({
        ...element.transitionOut,
        duration: element.transitionOut.duration * f,
      }),
    };
  }

  return {
    duration: slideDuration,
    transitionIn: element.transitionIn,
    transitionOut: element.transitionOut,
  };
};

export const influenceOfTransitionOnPrevSlide = ({ duration, type }) => {
  return duration * slideTransitionsDetails[type].influence.prevSlide * slideTransitionsDetails[type].crossPrevSlide;
};

export const influenceOfTransitionOnCurrentSlide = ({ duration, type }) => {
  return duration * slideTransitionsDetails[type].influence.currentSlide * slideTransitionsDetails[type].crossPrevSlide;
};

export const getSlideDelay = (slides, slideIndex) =>
  slideIndex === 0
    ? 0
    : slides
        .filter((_, ind) => ind < slideIndex)
        .map(
          (slide, ind) =>
            slide.duration +
            (ind === 0 ? 0 : influenceOfTransitionOnPrevSlide(slides.get(ind).transition || slides.get(0).transition)) -
            influenceOfTransitionOnPrevSlide(slides.get(ind + 1).transition || slides(0).transition),
        )
        .reduce((a, b) => a + b);

export const getSlidesForAnimation = (slides, repetitions) => {
  if (!List.isList(slides)) {
    slides = List.of(...slides.map(slide => slideFromJS(slide)));
  }
  const slidesForAnimation = [];
  const lastSlideIndex = slides.size - 1;
  const lastSlide = slides.get(lastSlideIndex);

  const animationDurationNoRepetition =
    getSlideDelay(slides, lastSlideIndex) +
    lastSlide.duration +
    influenceOfTransitionOnCurrentSlide(lastSlide.transition);

  const animationDelayFirstSlideIfRepetitions =
    animationDurationNoRepetition - influenceOfTransitionOnPrevSlide(slides.get(0).transition);

  const manySlides = slides.size > 1;
  const onlyOneSlide = slides.size === 1;

  for (let repetition = 0; repetition <= repetitions - 1; repetition++) {
    for (let [index, slide] of slides.entries()) {
      let slideDurationForAnimation;
      let animationGlobalDelay;

      const isFirstSlide = index === 0;
      const isLastSlide = index === lastSlideIndex;
      const isLastRepetition = repetition === repetitions - 1;
      const noRepetition = repetition === 0;
      const withRepetitions = !noRepetition;
      const currentTransition = slides.get(index).transition;
      const nextTransition = !isLastSlide ? slides.get(index + 1).transition : null;

      // first slide case
      if (isFirstSlide) {
        animationGlobalDelay = animationDelayFirstSlideIfRepetitions * repetition;
      }
      if (isFirstSlide && onlyOneSlide) {
        slideDurationForAnimation = slide.duration;
      }
      if (isFirstSlide && manySlides && noRepetition) {
        slideDurationForAnimation = slide.duration + influenceOfTransitionOnPrevSlide(nextTransition);
      }
      if (isFirstSlide && manySlides && withRepetitions) {
        slideDurationForAnimation =
          slide.duration +
          influenceOfTransitionOnPrevSlide(nextTransition) +
          influenceOfTransitionOnPrevSlide(currentTransition);
      }

      // other slides
      if (!isFirstSlide) {
        animationGlobalDelay = repetition * animationDurationNoRepetition + getSlideDelay(slides, index);
      }
      if (!isLastSlide && !isFirstSlide) {
        slideDurationForAnimation =
          slide.duration +
          influenceOfTransitionOnPrevSlide(currentTransition) +
          influenceOfTransitionOnPrevSlide(nextTransition);
      }

      // last slide
      if (isLastSlide && isLastRepetition) {
        slideDurationForAnimation = slide.duration + influenceOfTransitionOnPrevSlide(currentTransition);
      }
      if (isLastSlide && !isLastRepetition) {
        slideDurationForAnimation =
          slide.duration +
          influenceOfTransitionOnPrevSlide(currentTransition) +
          influenceOfTransitionOnPrevSlide(slides.get(0).transition);
      }

      const slideDetails = {
        slideDurationForAnimation,
        slide,
        slideIndex: index + repetition * slides.size,
        animationGlobalDelay,
      };
      slidesForAnimation.push(slideDetails);
    }
  }
  return slidesForAnimation;
};

/*
for checkAndUpdateSlideTransitionSaga
*/
export const solveNextSlide = (slides, slideIndex, withRepetitions) => {
  if (slides.size > slideIndex + 1) return slides.get(slideIndex + 1);
  if (withRepetitions) return slides.get(0);
  return null;
};

export const solveEnterTransition = slide => {
  const enterFadeIn = slide.transition.type === slideTransitionTypesVar.fadeIn;
  const enterCrossFade = slide.transition.type === slideTransitionTypesVar.crossFade;
  const enterFadeOutIn = slide.transition.type === slideTransitionTypesVar.fadeOutIn;
  return enterFadeIn || enterCrossFade || enterFadeOutIn;
};

export const solveLeaveTransitions = (slideIsLast, firstSlide, nextSlide, withRepetitions) => {
  if (slideIsLast && !withRepetitions) return false;

  const solveLeaveTransition = transition => {
    return slideIsLast ? firstSlide.transition.type === transition : nextSlide.transition.type === transition;
  };

  const leaveFadeOut = solveLeaveTransition(slideTransitionTypesVar.fadeOut);
  const leaveCrossFade = solveLeaveTransition(slideTransitionTypesVar.crossFade);
  const leaveFadeOutIn = solveLeaveTransition(slideTransitionTypesVar.fadeOutIn);

  return leaveFadeOut || leaveCrossFade || leaveFadeOutIn;
};

export const solveTotalTransitionDuration = (slide, nextSlide) => {
  // ACHTUNG ! cross fade duration is applying fully to the slide and the next slide at the same time,
  // i.e. if the duration is n seconds, it applies like that :
  // -> n seconds of fade-out for the slide
  // -> n seconds of fade-in for the next slide

  // ACHTUNG ! fade in out duration is applying half to the slide and half to the next slide, one after the other,
  // i.e. if the duration is n seconds, it applies like that :
  // -> n / 2 seconds of fade-out for the slide
  // -> n / 2 seconds of fade-in for the next slide
  const transitionInDuration =
    slide.transition.duration * slideTransitionsDetails[slide.transition.type].influence.currentSlide;
  const transitionOutDuration = nextSlide
    ? nextSlide.transition.duration * slideTransitionsDetails[nextSlide.transition.type].influence.prevSlide
    : 0;

  return transitionInDuration + transitionOutDuration;
};
