import { corners } from '../../../reference/corners';

export const getCornerPositionNotRotated = ({ x, y, width, height, corner }) => {
  switch (corner) {
    case corners.topRight:
      return {
        x: x + width,
        y: y,
      };
    case corners.topLeft:
      return {
        x: x,
        y: y,
      };
    case corners.bottomRight:
      return {
        x: x + width,
        y: y + height,
      };
    case corners.bottomLeft:
      return {
        x: x,
        y: y + height,
      };

    /*useful for lock aspect ratio only*/
    case corners.middleTop:
      return {
        x: x + width / 2,
        y: y,
      };
    case corners.middleBottom:
      return {
        x: x + width / 2,
        y: y + height,
      };
    case corners.middleLeft:
      return {
        x: x,
        y: y + height / 2,
      };
    case corners.middleRight:
      return {
        x: x + width,
        y: y + height / 2,
      };
    default:
      return {
        x: null,
        y: null,
      };
  }
};
