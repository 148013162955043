import { createSelector } from 'reselect';
import { List, Map, Set } from 'immutable';

import { transitionTypesVar } from '../reference/transitions';
import { GuideRecord } from '../reference/records';
import { getSlidesForAnimation, getSlideDelay } from './bannerUtils';
import { getBannerPresent, getBannerFormat } from '../shared-selectors/sharedSelectors';
import { buildEquidistantGuides } from '../guides/equidistantGuides';
import { newIntlMessage } from '../i18n/i18nDucks';
import {
  getSelectedSlideIndex,
  getBannerIsEyeDropping,
  getSelectedElementId,
  getSelectedElementIds,
  getSlideIsPlayingAnimation,
  getSelectedElementIsPlayingAnimation,
  showTextAsBannerPlayingAnimation,
} from '../temporary-status/temporaryStatusDucks';
import {
  getCustomizationIsEnabled,
  getDCO,
  getCustomFieldsAndValuesFromUserEntity,
  getCustomTestChoice,
  getSelectedEntityIdForCusto,
} from '../entities/entitiesSelectors';
import { customTest } from '../reference/customization';
import { getBannerFromUniqueIdentification } from '../banners/bannersSelectors';

export const getBannerUniqueIdentification = ({ banner }) =>
  banner.present
    ? `${banner.present.name}${banner.present.format.height}${banner.present.format.width}`
    : `${banner.name}${banner.format.height}${banner.format.width}`;

export const getBannerSlides = createSelector(getBannerPresent, banner => banner.slides);

export const getAllElements = createSelector(getBannerPresent, banner => banner.elements);

export const getBannerRepetitions = createSelector(getBannerPresent, banner => banner.repetitions);

export const getSlideIndex = slide => createSelector([getBannerSlides], slides => slides.indexOf(slide));

// List<List<ElementRecord>>
// GOOD TO KNOW: the list is ordered by the disposition of the element in the slide.elementIds List
// so if there is only one slide 'slide-1' in the banner, with some elements positionned differently than there order of creation,
// we'll have getElementsBySlide('slide-1') !== banner.elements
export const getElementsBySlide = createSelector([getBannerSlides, getAllElements], (slides, elements) =>
  slides.map(slide => slide.elementIds.map(elementId => elements.get(elementId))),
);

export const getSlideAtIndex = slideIndex => createSelector([getBannerSlides], slides => slides.get(slideIndex));

// List
export const getSlidesForBannerAnimation = createSelector(
  [getBannerSlides, getBannerRepetitions],
  (slides, repetitions) => getSlidesForAnimation(slides, repetitions),
);

// Number
export const getBannerDuration = createSelector([getSlidesForBannerAnimation], slides => {
  const lastSlideDetails = slides.filter((_, ind) => ind === slides.length - 1)[0];
  return lastSlideDetails.slideDurationForAnimation + lastSlideDetails.animationGlobalDelay;
});

export const getBannerDurationNoRepetitions = createSelector([getBannerSlides], slides => {
  const slidesForAnimation = getSlidesForAnimation(slides, 1);
  const lastSlideDetails = slidesForAnimation.filter((_, ind) => ind === slidesForAnimation.length - 1)[0];
  return lastSlideDetails.slideDurationForAnimation + lastSlideDetails.animationGlobalDelay;
});

export const getBanner = ({ banner }) => banner;

export const getBannerFuture = ({ banner }) => banner.future;
export const bannerPastExists = ({ banner }) => banner.past.length > 0;
export const bannerFutureExists = ({ banner }) => banner.future.length > 0;

export const getBannerName = createSelector(getBannerPresent, banner => banner.name);

export const getBannerNumberOfSlides = createSelector([getBannerSlides], slides => slides.size);

export const getSlideAnimationDelay = slideIndex =>
  createSelector([getBannerSlides], slides => getSlideDelay(slides, slideIndex));

export const getBannerZoom = createSelector(getBannerPresent, banner => banner.zoom);

export const getBannerBgColor = createSelector(getBannerPresent, banner => banner.backgroundColor);
export const getBannerTransparentColor = createSelector(getBannerPresent, banner => banner.transparentColor);

// BannerAnimationStatusRecord

export const getBannerDissociateFromMaster = createSelector(getBannerPresent, banner => banner.dissociateFromMaster);

// List<SlideRecord>
export const getBannerNumberOfDifferentImages = createSelector(
  [getBannerSlides],
  slides =>
    Set.of(
      slides
        .map(({ elementIds }) => elementIds)
        .flatten()
        .filter(id => id.includes('image')),
    ).flatten().size,
);

// SlideRecord
export const getSelectedSlide = createSelector(
  [getBannerSlides, getSelectedSlideIndex],
  (slides, selectedSlideIndex) => {
    // hack to make sure never error
    if (!slides) return false;
    return slides.size - 1 < selectedSlideIndex ? slides.get(slides.size - 1) : slides.get(selectedSlideIndex);
  },
);

// List
export const getSlideForSnapshot = createSelector([getSelectedSlide, getSelectedSlideIndex], (slide, slideIndex) => {
  const slideDetails = {
    slideDurationForAnimation: undefined,
    slide,
    slideIndex,
    animationGlobalDelay: undefined,
  };

  return [slideDetails];
});

export const getSlidesForBannerAnimatedGIF = createSelector(
  [getSlidesForBannerAnimation, getBannerNumberOfSlides],
  (slides, numberOfSlides) => slides.filter((_, ind) => ind < numberOfSlides),
);

export const getBannerThumbnailResourceId = createSelector(getBannerPresent, banner => banner.thumbnailResourceId);

export const getBannerDurationForAnimatedGIF = createSelector([getSlidesForBannerAnimatedGIF], slides => {
  const lastSlideDetails = slides.filter((_, ind) => ind === slides.length - 1)[0];
  return lastSlideDetails.slideDurationForAnimation + lastSlideDetails.animationGlobalDelay;
});

export const getBannerExpirationDate = createSelector(getBannerPresent, banner => banner.expirationDate);

// Number
export const getBannerNumberOfFrames = framesPerSecond =>
  createSelector(
    // [getBannerFPS, getBannerDuration], -> if FPS is to be controlled by user
    // (fps, duration) => duration * fps,
    [getBannerDurationForAnimatedGIF],
    duration => duration * framesPerSecond,
  );

// Map<String, ElementRecord>
export const getAllElementsIds = createSelector([getAllElements], elements =>
  elements.toList().map(element => element.id),
);

// Set<String>
export const getSelectedSlideElementIds = createSelector([getSelectedSlide], slide => slide.get('elementIds'));
// Set<String>
export const getSelectedSlideDuration = createSelector([getSelectedSlide], slide => slide.get('duration'));
// Set<ElementRecord>
export const getSelectedSlideElements = createSelector(
  [getSelectedSlideElementIds, getAllElements],
  (elementIds, elements) => elementIds.map(elementId => elements.get(elementId)),
);
// String

export const isElementSelected = (id, elementForExport) =>
  createSelector([getSelectedElementId, getBannerIsEyeDropping], (selectedElementId, isEyeDropping) => {
    const canBeSelected = !isEyeDropping && !elementForExport;
    if (!canBeSelected) return false;
    return selectedElementId === id;
  });

export const isElementMultipleSelected = (id, elementForExport) =>
  createSelector([getSelectedElementIds, getBannerIsEyeDropping], (selectedElementIds, isEyeDropping) => {
    const canBeSelected = !isEyeDropping && !elementForExport;
    if (!canBeSelected) return false;
    return selectedElementIds.size > 1 && selectedElementIds.includes(id);
  });

export const isElementDisplayedAsSelectedInElementList = id =>
  createSelector([getSelectedElementIds, getSelectedElementId], (selectedElementIds, selectedElementId) => {
    if (selectedElementId) return selectedElementId === id;
    if (selectedElementIds.size === 1) return selectedElementIds.first() === id;
    if (selectedElementIds.size > 1) return selectedElementIds.includes(id);
    return false;
  });

// ElementRecords
export const getSelectedElements = createSelector(
  [getSelectedElementIds, getAllElements],
  (selectedElementIds, elements) => {
    const selectedElements = elements.filter(element => selectedElementIds.includes(element.id));
    if (selectedElements.size < 2) return Map();
    return selectedElements;
  },
);

// ElementRecord
export const getSelectedElement = createSelector(
  [getSelectedElementIds, getSelectedElementId, getAllElements],
  (selectedElementIds, selectedElementId, elements) => {
    if (selectedElementId === null) {
      if (selectedElementIds.size === 1) return elements.get(selectedElementIds.first());
      return null;
    }
    return elements.get(selectedElementId);
  },
);

// Selected element properties
export const getSelectedElementPositionX = createSelector([getSelectedElement], element => element.x);
export const getSelectedElementPositionY = createSelector([getSelectedElement], element => element.y);
export const getSelectedElementRotation = createSelector([getSelectedElement], element => element.rotation);
export const getSelectedElementWidth = createSelector([getSelectedElement], element => element.width);
export const getSelectedElementHeight = createSelector([getSelectedElement], element => element.height);
export const getSelectedElementType = createSelector([getSelectedElement], element => element.type);
export const getSelectedElementShowOnAllSlides = createSelector(
  [getSelectedElement],
  element => element.showOnAllSlides,
);
export const getSelectedElementTransitionsOnAllSlides = createSelector(
  [getSelectedElement],
  element => element.transitionsOnAllSlides,
);
export const getSelectedElementOriginalSize = createSelector([getSelectedElement], element => element.originalSize);
export const getSelectedElementPicWhileCroppingProperties = createSelector(
  [getSelectedElement],
  element => element.picWhileCroppingProperties,
);

export const getSelectedElementLockAspectRatio = createSelector(
  [getSelectedElement],
  element => element.lockAspectRatio,
);
export const getSelectedElementText = createSelector([getSelectedElement], element => element.text);
export const getSelectedElementTextContentState = createSelector([getSelectedElement], element => element.contentState);
export const getSelectedElementFileName = createSelector([getSelectedElement], element => element.fileName);
export const getSelectedElementResourceId = createSelector([getSelectedElement], element => element.resourceId);
export const getSelectedElementDropShadowEnabled = createSelector(
  [getSelectedElement],
  element => element.dropShadowEnabled,
);
export const getSelectedElementDropShadowColor = createSelector(
  [getSelectedElement],
  element => element.dropShadow.color,
);
export const getSelectedElementDropShadowBlurRadius = createSelector(
  [getSelectedElement],
  element => element.dropShadow.blurRadius,
);
export const getSelectedElementDropShadowOffsetX = createSelector(
  [getSelectedElement],
  element => element.dropShadow.offsetX,
);
export const getSelectedElementDropShadowOffsetY = createSelector(
  [getSelectedElement],
  element => element.dropShadow.offsetY,
);
export const getSelectedElementBlinkAnimationEnabled = createSelector(
  [getSelectedElement],
  element => element.blinkAnimationEnabled,
);
export const getSelectedElementBlinkMinOpacity = createSelector(
  [getSelectedElement],
  element => element.blinkAnimation.minOpacity,
);
export const getSelectedElementBlinkFrequency = createSelector(
  [getSelectedElement],
  element => element.blinkAnimation.frequency,
);
export const getSelectedElementFontFamily = createSelector([getSelectedElement], element => element.fontFamily);
export const getSelectedElementFontSize = createSelector([getSelectedElement], element => element.fontSize);
export const getSelectedElementFontSizes = createSelector([getSelectedElement], element => element.fontSizes);
export const getSelectedElementFontStyle = createSelector([getSelectedElement], element => element.fontStyle);
export const getSelectedElementFontColor = createSelector([getSelectedElement], element => element.color);
export const getSelectedElementFontHorizontalAlign = createSelector(
  [getSelectedElement],
  element => element.horizontalAlign,
);
export const getSelectedElementFontVerticalAlign = createSelector(
  [getSelectedElement],
  element => element.verticalAlign,
);
export const getSelectedElementBackgroundColor = createSelector(
  [getSelectedElement],
  element => element.backgroundColor,
);
export const getSelectedElementBorderColor = createSelector([getSelectedElement], element => element.borderColor);
export const getSelectedElementBorderWidth = createSelector([getSelectedElement], element => element.borderWidth);
export const getSelectedElementBorderRadius = createSelector([getSelectedElement], element => element.borderRadius);
export const getSelectedElementBorderRadiusUnit = createSelector(
  [getSelectedElement],
  element => element.borderRadiusUnit,
);
export const getSelectedElementAppearance = createSelector([getSelectedElement], element => element.appearance);
export const getSelectedElementFlipV = createSelector([getSelectedElement], element => element.flipV);
export const getSelectedElementFlipH = createSelector([getSelectedElement], element => element.flipH);
export const getSelectedElementDuration = createSelector([getSelectedElement], element => element.duration);

export const getSelectedElementCanPlayAnimation = createSelector(
  [getSelectedElement, getSlideIsPlayingAnimation],
  (element, slideIsPlayingAnimation) => {
    return !(
      ((element.transitionIn.type === transitionTypesVar.none || element.transitionIn.duration === 0) &&
        (element.transitionOut.type === transitionTypesVar.none || element.transitionOut.duration === 0) &&
        !element.blinkAnimationEnabled) ||
      slideIsPlayingAnimation
    );
  },
);
export const getSelectedElementTransitionIn = createSelector([getSelectedElement], element => element.transitionIn);
export const getSelectedElementTransitionOut = createSelector([getSelectedElement], element => element.transitionOut);
export const getSelectedElementTransitionInType = createSelector(
  [getSelectedElement],
  element => element.transitionIn && element.transitionIn.type,
);
export const getSelectedElementTransitionInDelay = createSelector(
  [getSelectedElement],
  element => element.transitionIn && element.transitionIn.delay,
);
export const getSelectedElementTransitionInTotalDuration = createSelector([getSelectedElement], element => {
  return (
    (element &&
      element.transitionIn &&
      (element.transitionIn.type !== transitionTypesVar.none
        ? (element.transitionIn.duration || 0) + (element.transitionIn.delay || 0)
        : element.transitionIn.delay)) ||
    0
  );
});
export const getSelectedElementTransitionInDuration = createSelector([getSelectedElement], element => {
  return (
    (element &&
      element.transitionIn &&
      (element.transitionIn.type !== transitionTypesVar.none ? element.transitionIn.duration || 0 : 0)) ||
    0
  );
});
export const getSelectedElementTransitionOutDuration = createSelector([getSelectedElement], element => {
  return (
    (element &&
      element.transitionOut &&
      (element.transitionOut.type !== transitionTypesVar.none ? element.transitionOut.duration || 0 : 0)) ||
    0
  );
});

export const getSelectedElementTransitionOutType = createSelector(
  [getSelectedElement],
  element => element.transitionOut && element.transitionOut.type,
);
export const getSelectedElementOptimizedResourceId = createSelector(
  [getSelectedElement],
  element => element.optimizedResourceId,
);
export const getSelectedElementOpacity = createSelector([getSelectedElement], element => element.opacity);
export const getSelectedElementIsCustomizable = createSelector([getSelectedElement], element => element.customizable);
export const getSelectedElementCustomProperty = createSelector([getSelectedElement], element => element.property);

// Boolean
export const showPropertyEditorElement = createSelector([getSelectedElement], element => Boolean(element));
export const showPropertyEditorElements = createSelector([getSelectedElements], elements => elements.size > 1);
export const showPropertyEditorBanner = createSelector(
  [showPropertyEditorElements, showPropertyEditorElement],
  (elements, element) => Boolean(!element && !elements),
);

// ElementRecord
export const getElementFromId = id => createSelector([getAllElements], elements => elements.get(id));

// Element properties from id
/*
customizable
property
type
text
fileName
thumbnailUrl
resourceId
backgroundColor
showOnAllSlides
*/
export const getElementType = id => createSelector([getElementFromId(id)], element => element.type);
export const getElementText = id => createSelector([getElementFromId(id)], element => element.text);
export const getElementFileName = id => createSelector([getElementFromId(id)], element => element.fileName);
export const getElementThumbnailUrl = id => createSelector([getElementFromId(id)], element => element.thumbnailUrl);
export const getElementResourceId = id => createSelector([getElementFromId(id)], element => element.resourceId);
export const getElementTextWorkingValue = id => createSelector([getElementFromId(id)], element => element.workingValue);
export const getElementTextStorageValue = id => createSelector([getElementFromId(id)], element => element.storageValue);
export const getElementBackgroundColor = id =>
  createSelector([getElementFromId(id)], element => element.backgroundColor);
export const getElementShowOnAllSlides = id =>
  createSelector([getElementFromId(id)], element => element.showOnAllSlides);
export const getElementIsCustomizable = id => createSelector([getElementFromId(id)], element => element.customizable);
export const getElementCustomProperty = id => createSelector([getElementFromId(id)], element => element.property); // TESTME
// Number
export const getBannerNextIds = createSelector(getBannerPresent, banner => banner.nextId);
// Number
export const getNextElementId = elementType => createSelector([getBannerNextIds], nextId => nextId.get(elementType));

// Number
export const getSelectedSlideElementsCount = createSelector(
  [getSelectedSlideElementIds],
  elementIds => elementIds.size,
);

// Number
export const getSelectedElementIndex = createSelector(
  [getSelectedSlideElementIds, getSelectedElementId],
  (elementIds, selectedElementId) => selectedElementId && elementIds.indexOf(selectedElementId),
);

// Set<ElementRecord>
export const getSelectedSlideElementsExcludingSelectedElement = createSelector(
  [getSelectedSlideElements, getSelectedElementId],
  (elements, selectedElementId) => elements.filter(el => el.id !== selectedElementId),
);

// List<List<ElementRecord>>
export const getElementsBySlideIndex = index =>
  createSelector([getElementsBySlide], elementsList => elementsList.get(index));

// List<List<ElementRecord>>
export const getElementsOfSlide = slide =>
  createSelector([getElementsBySlide, getSlideIndex(slide)], (elements, index) => elements.get(index));

// bool
export const checkIfNoRepetitions = createSelector([getBannerRepetitions], repetitions => repetitions === 1);

// List<BannerGuide> // TESTME
export const getBannerGuidesForElement = id =>
  createSelector(
    [getBannerFormat, getSelectedSlideElementsExcludingSelectedElement, getSelectedElement],
    ({ bannerWidth, bannerHeight }, remainingElements, selectedElement) => {
      if (!selectedElement || (id && selectedElement.id !== id)) {
        return List();
      }
      return List.of(
        //Center of element with center of banner
        GuideRecord({
          orientation: 'horizontal',
          magnetism: 'center',
          position: bannerHeight / 2,
        }),
        GuideRecord({
          orientation: 'vertical',
          magnetism: 'center',
          position: bannerWidth / 2,
        }),

        //Block effect when getting out of banner
        GuideRecord({
          orientation: 'horizontal',
          magnetism: 'top',
          position: 0,
        }),
        GuideRecord({
          orientation: 'horizontal',
          magnetism: 'bottom',
          position: bannerHeight,
        }),
        GuideRecord({
          orientation: 'vertical',
          magnetism: 'right',
          position: bannerWidth,
        }),
        GuideRecord({
          orientation: 'vertical',
          magnetism: 'left',
          position: 0,
        }),
      )
        .concat(
          List.of(
            GuideRecord({
              orientation: 'vertical',
              magnetism: 'rotation',
              position: selectedElement.x + selectedElement.width / 2,
            }),
            GuideRecord({
              orientation: 'horizontal',
              magnetism: 'rotation',
              position: selectedElement.y + selectedElement.height / 2,
            }),
          ),
        )
        .concat(buildEquidistantGuides({ remainingElements }))
        .concat(
          remainingElements.flatMap(element =>
            List.of(
              //Center of element with center of existing elements
              GuideRecord({
                orientation: 'horizontal',
                magnetism: 'center',
                elementIds: Set.of(element.id),
                position: element.y + element.height / 2,
              }),
              GuideRecord({
                orientation: 'vertical',
                magnetism: 'center',
                elementIds: Set.of(element.id),
                position: element.x + element.width / 2,
              }),
            ),
          ),
        );
    },
  );

/* CUSTOMIZATION */

/* CUSTOM VALUE */
export const getCustomValue = (custoEnabled, property, fieldsAndValues) => {
  if (!custoEnabled) return '';
  return fieldsAndValues[property];
};

export const getSelectedElementCustomValue = createSelector(
  // tested through getCustomValue
  [getCustomizationIsEnabled, getSelectedElementCustomProperty, getCustomFieldsAndValuesFromUserEntity],
  getCustomValue,
);

export const getElementCurrentCustomValue = (
  id, // tested through getCustomValue
) =>
  createSelector(
    [getCustomizationIsEnabled, getElementCustomProperty(id), getCustomFieldsAndValuesFromUserEntity],
    getCustomValue,
  );

/* CUSTOM VALUE FROM OTHER ENTITIES */
export const getCustomValuesFromOtherEntities = (custoEnabled, property, entities) => {
  if (!custoEnabled) return [];
  return entities.map(entity => ({
    entityId: entity.id,
    name: entity.name,
    value: entity[property],
  }));
};

export const getSelectedElementCustomValueFromOtherEntities = createSelector(
  // tested through getCustomValuesFromOtherEntities
  [getCustomizationIsEnabled, getSelectedElementCustomProperty, getDCO],
  getCustomValuesFromOtherEntities,
);

export const getElementCustomValueFromOtherEntities = (
  id, // tested through getCustomValuesFromOtherEntities
) =>
  createSelector([getCustomizationIsEnabled, getElementCustomProperty(id), getDCO], getCustomValuesFromOtherEntities);

/* CUSTOM VALUE MOST LETTERS CASE */
export const getCustomValuesMostLettersCase = (defaultValue, values) => {
  const ifNoValue = { value: defaultValue, name: newIntlMessage('customization.field.defaultValue') };
  if (!values) return ifNoValue;
  const longestValue = values
    .filter(({ value }) => value)
    .sort((value1, value2) => (value1.value.length > value2.value.length ? -1 : 1));
  if (longestValue.length) return longestValue[0];
  return ifNoValue;
};

export const getSelectedElementCustomValueMostLettersCase = createSelector(
  // tested through getCustomValuesMostLettersCase
  [getSelectedElementText, getSelectedElementCustomValueFromOtherEntities],
  getCustomValuesMostLettersCase,
);

export const getElementCustomValueMostLettersCase = (
  id, // tested through getCustomValuesMostLettersCase
) => createSelector([getElementText(id), getElementCustomValueFromOtherEntities(id)], getCustomValuesMostLettersCase);

/* GET CUSTOM VALUE TO DISPLAY IN SCENE */
export const getElementCustomValue = id =>
  createSelector(
    [
      getCustomizationIsEnabled,
      getElementFromId(id),
      getCustomTestChoice,
      getElementCustomValueMostLettersCase(id),
      getElementCustomValueFromOtherEntities(id),
      getSelectedEntityIdForCusto,
      getElementCurrentCustomValue(id),
      isElementSelected(id, false),
      getSelectedElementIsPlayingAnimation,
      showTextAsBannerPlayingAnimation,
      getElementCustomProperty(id),
    ],
    (
      custoEnabled,
      element,
      customTestChoice,
      mostLettersCase,
      otherEntitiesValues,
      selectedEntityForCusto,
      currentCustomValue,
      selected,
      elementIsPlayingAnimation,
      bannerPlayingAnimation,
      property,
    ) => {
      const defaultValue = element.text;
      if (!custoEnabled) return defaultValue;
      try {
        switch (customTestChoice) {
          default:
            return defaultValue;
          case customTest.noTest: {
            const needToShowCustomValue = (selected && elementIsPlayingAnimation) || bannerPlayingAnimation;
            const fieldForNoProperty = newIntlMessage('customization.field.select');
            if (needToShowCustomValue) {
              return defaultValue;
            }
            return `‹${property || fieldForNoProperty}›`;
          }
          case customTest.currentEntity: {
            return currentCustomValue || defaultValue;
          }
          case customTest.selectEntity: {
            const valueForNoEntitySelected = newIntlMessage(`customization.live-test.${customTest.selectEntity}`);
            return selectedEntityForCusto
              ? otherEntitiesValues.find(cust => cust.entityId === selectedEntityForCusto).value || defaultValue
              : `‹${property || valueForNoEntitySelected}›`;
          }
          case customTest.worstCaseScenario: {
            return mostLettersCase.value;
          }
        }
      } catch (e) {
        console.error('cannot get element custom value by id', e);
        return defaultValue;
      }
    },
  );

/**
 * hack to simulate a banner as a present banner when generating images.
 */
export function getStateWithPresentBanner(state, bannerUniqueId) {
  return {
    ...state,
    banner: getBannerFromUniqueIdentification(bannerUniqueId)(state),
  };
}
