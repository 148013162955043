import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Menu, MenuItem, Divider, withStyles } from '@material-ui/core';
import { Range } from 'immutable';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getElementFromId } from '../banner/bannerSelectors';
import { getSelectedBannerIndex } from '../shared-selectors/sharedSelectors';

const styles = theme => ({
  menuSelected: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
    },
    '&$menuSelected': {
      backgroundColor: fade(theme.palette.primary.main, 0.8),
      color: '#FFF',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
      },
    },
  },
});

class MenuCopyToSlides extends React.Component {
  state = { selected: this.props.classes.menuSelected, root: this.props.classes.menuItem };

  onDuplicateClick = slideIndex => {
    const { menuElement, selectedSlideIndex, selectedBannerIndex, onMenuClose, onDuplicateClick } = this.props;
    onMenuClose();
    onDuplicateClick({
      id: menuElement.id,
      slideIndex,
      oldSlideIndex: selectedSlideIndex,
      bannerIndex: selectedBannerIndex,
      oldBannerIndex: selectedBannerIndex,
    });
  };

  render() {
    const {
      menuAnchorEl,
      menuElement,
      copyToOtherBanners,
      selectedSlideIndex,
      nbSlides,
      onMenuClose,
      intl,
    } = this.props;

    const canCopyOnOtherSlides = !copyToOtherBanners && menuElement && !menuElement.showOnAllSlides && nbSlides > 1;
    const canCopyOnCurrentSlide = Number.isInteger(selectedSlideIndex);
    return (
      <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={onMenuClose}>
        {canCopyOnCurrentSlide && (
          <MenuItem classes={this.state} onClick={() => this.onDuplicateClick(selectedSlideIndex)}>
            <FormattedMessage id="app.elements.duplicate.here" />
          </MenuItem>
        )}
        {canCopyOnOtherSlides && <Divider />}
        {(canCopyOnOtherSlides || copyToOtherBanners) &&
          Range(0, nbSlides)
            .filter(i => (canCopyOnCurrentSlide ? i !== selectedSlideIndex : true))
            .map(i => (
              <MenuItem key={i} classes={this.state} onClick={() => this.onDuplicateClick(i)}>
                {intl.formatMessage(
                  { id: 'app.elements.duplicate.to-slide' },
                  { slide: intl.formatNumber(i + 1, { minimumIntegerDigits: 2 }) },
                )}
              </MenuItem>
            ))}
      </Menu>
    );
  }
}

const makeStateToProps = () => (state, { id }) => ({
  menuElement: makeSelectorInstance(getElementFromId(id))(state),
  selectedBannerIndex: makeSelectorInstance(getSelectedBannerIndex)(state),
});

export default compose(
  connect(makeStateToProps),
  injectIntl,
  withStyles(styles),
)(MenuCopyToSlides);
