import { EditorState, ContentState, convertToRaw, convertFromRaw, Modifier, SelectionState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { List } from 'immutable';
import { fontSizeProperty } from '../reference/textProperties';

export const getRawContentStateFromEditorState = editorState => {
  const rawContentState = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
  return rawContentState;
};

export const checkStateAndReduxEquality = (editorState, rawContentStateFromRedux) => {
  const rawContentState = getRawContentStateFromEditorState(editorState);
  return JSON.stringify(rawContentState) === JSON.stringify(rawContentStateFromRedux);
};

export const getEditorStateFromRawContentState = rawContentState => {
  return EditorState.createWithContent(convertFromRaw(JSON.parse(rawContentState)));
};

export const getContentStateFromRaw = rawContentState => {
  return convertFromRaw(rawContentState);
};

export const getTextFromEditorState = editorState => editorState.getCurrentContent().getPlainText();

export const createRawContentStateFromText = text => JSON.stringify(convertToRaw(ContentState.createFromText(text)));

export const createHtmlTextElement = (contentState, text) =>
  draftToHtml(JSON.parse(contentState || createRawContentStateFromText(text)));

export const createEditorStateFromText = text => EditorState.createWithContent(ContentState.createFromText(text));

export const hasNoSelection = editorState => {
  const { anchorKey, focusKey, anchorOffset, focusOffset } = editorState.getSelection();
  return anchorKey === focusKey && anchorOffset === focusOffset;
};

export const hasFocus = editorState => editorState.getSelection().hasFocus;

export const selectAll = editorState => {
  const currentContent = editorState.getCurrentContent();
  const firstBlock = currentContent.getBlockMap().first();
  const lastBlock = currentContent.getBlockMap().last();
  const firstBlockKey = firstBlock.getKey();
  const lastBlockKey = lastBlock.getKey();
  const lengthOfLastBlock = lastBlock.getLength();

  const selection = new SelectionState({
    anchorKey: firstBlockKey,
    anchorOffset: 0,
    focusKey: lastBlockKey,
    focusOffset: lengthOfLastBlock,
  });
  return EditorState.acceptSelection(editorState, selection);
};

export const cleanInlineStylesFromSelection = (editorState, styles, all) => {
  if (!editorState) return null;
  const editorStateAllSelected = selectAll(editorState);
  const contentWithoutStyles = styles.reduce(
    (newContentState, style) =>
      Modifier.removeInlineStyle(
        newContentState,
        all ? editorStateAllSelected.getSelection() : editorState.getSelection(),
        style,
      ),
    editorState.getCurrentContent(),
  );

  return EditorState.push(editorState, contentWithoutStyles, 'change-inline-style');
};

export const getEditorStateCharacterList = editorState => {
  if (!editorState) return List();

  try {
    // 1. get all characters one by one

    const blockMap = editorState.getCurrentContent().getBlockMap();
    return blockMap
      .map(contentBlock => {
        const contentBlockMapped = contentBlock
          .getCharacterList()
          .map((characterMetadata, index) => ({
            styles: characterMetadata.style.filter(style => style.includes(fontSizeProperty)),
            contentBlockKey: contentBlock.getKey(),
            index,
            character: contentBlock.getText()[index], // for debug only
          }))
          .flatten();
        return contentBlockMapped;
      })
      .toList()
      .flatten(true);
  } catch (e) {
    console.error('getEditorStateCharacterList', e);
  }
};

export const removeFontInlineStyleFromSelection = editorState => {
  // because editorState.getCurrentInlineStyle() doesn't give what all the characters have, it can happen that some characters
  // keep there fontsize inline property. But we can't have multiple fontsize properties for one character, otherwise it kills all

  if (!editorState) return;
  if (!editorState.getSelection()) return;

  try {
    // 1. get all characters one by one
    const characterList = getEditorStateCharacterList(editorState);
    // 2. filter the selected characters
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey(); // contentBlockKey
    const endKey = selection.getEndKey(); // contentBlockKey
    const startOffset = selection.getStartOffset(); // position of the cursor: ALO|RS => startOffset === 3, whatever the selection is before or after
    const endOffset = selection.getEndOffset(); // idem

    const indexOfFirstCharacterSelected = characterList
      .map(({ index, contentBlockKey }, i) => (contentBlockKey === startKey && index === startOffset ? i : null))
      .filter(index => index !== null)
      .first();
    const indexOfLastCharacterSelected = characterList
      .map(({ index, contentBlockKey }, i) => (contentBlockKey === endKey && index === endOffset - 1 ? i : null))
      .filter(index => index !== null)
      .first();

    const selectedCharacterList = characterList.filter(
      (char, index) => index >= indexOfFirstCharacterSelected && index <= indexOfLastCharacterSelected,
    );
    const fontSizes = selectedCharacterList
      .map(({ styles }) => styles)
      .flatten()
      .toOrderedSet();
    return cleanInlineStylesFromSelection(editorState, fontSizes);
  } catch (e) {
    console.error('removeFontInlineStyleFromSelection', e);
  }
};
