import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { OpenBannerSetFromNuxeo } from 'drb-commons-front';

import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getUserEntityId, isOpenBannerSetFromNuxeoOpen } from '../shared-selectors/sharedSelectors';
import { openBannerSetFromNuxeo } from '../banners/bannersActionsCreators';
import { getConfig } from '../config/configDucks';
import { addError } from '../messages/messagesDucks';
import { closeOpenBannerSetDialog } from './openBannerSetDucks';

const OpenBannerSetFromNuxeoAdBuilder = ({
  open,
  userEntityId,
  mediaUrl,
  openBannerSetFromNuxeo,
  addError,
  onCloseDialog,
  intl,
}) => (
  <OpenBannerSetFromNuxeo
    /* INTL */
    intlMessages={{
      'no-results': intl.formatMessage({ id: 'open-bannerset-modal.no-results' }),
      'read-only': intl.formatMessage({ id: 'open-bannerset-modal.read-only' }),
      'not-available': intl.formatMessage({ id: 'open-bannerset-modal.not-available' }),
      title: intl.formatMessage({ id: 'open-bannerset-modal.title' }),
      subtitle: intl.formatMessage({ id: 'open-bannerset-modal.subtitle' }),
      validate: intl.formatMessage({ id: 'open-bannerset-modal.validate' }),
      cancel: intl.formatMessage({ id: 'alert.cancel' }),
      checkboxLabel: intl.formatMessage({ id: 'open-bannerset-modal.show-expired-bannerset' }),
    }}
    /* STATE TO PROPS */
    open={open}
    userEntityId={userEntityId}
    mediaUrl={mediaUrl}
    /* DISPATCH TO PROPS */
    openBannerSet={openBannerSetFromNuxeo}
    handleFetchingError={() => addError('nuxeo.bannerSet.error-fetching')}
    onCloseDialog={onCloseDialog}
    withValidBannerSet
  />
);

const makeStateToProps = () => state => ({
  mediaUrl: makeSelectorInstance(getConfig)(state).mediaUrl,
  userEntityId: makeSelectorInstance(getUserEntityId)(state),
  open: makeSelectorInstance(isOpenBannerSetFromNuxeoOpen)(state),
});

const dispatchToProps = {
  openBannerSetFromNuxeo,
  addError,
  onCloseDialog: closeOpenBannerSetDialog,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
)(OpenBannerSetFromNuxeoAdBuilder);
