import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { getSelectedElementFontSize, getSelectedElementFontSizes } from '../../banner/bannerSelectors';
import { setElementFontSize } from '../../banner/bannerActionsCreators';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { pixelEndornment } from '../constants';
import TextFieldDoped from '../../components/TextFieldDoped';
import {
  toggleFontSizeFocused,
  getTemporaryTextEditorState,
  getTemporaryFontSizeFocused,
} from '../../temporary-text-editor/temporaryTextEditorDucks';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

class ElementFontSize extends React.Component {
  state = { fontSize: this.props.fontSize }; // state not connected with redux

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.editorState || nextProps.fontSizeFocused) return prevState;

    let fontSize = null;
    for (let style of nextProps.editorState.getCurrentInlineStyle()) {
      if (style.includes('fontsize')) fontSize = parseInt(style.split('-')[1], 10);
    }
    if (fontSize) return { fontSize };
    return { fontSize: nextProps.fontSize };
  }

  componentDidMount() {
    this.props.toggleFontSizeFocused(false);
  }
  componentWillUnmount() {
    this.props.toggleFontSizeFocused(false);
  }

  onFontSizeChange = ({ value: fontSize, id }) => {
    if (this.state.fontSize === fontSize) return;
    this.props.setElementFontSize({ fontSize, id });
    this.setState({ fontSize });
  };

  onFontSizeBlur = () => this.props.toggleFontSizeFocused(false);
  onClick = () => this.props.toggleFontSizeFocused(true);

  render() {
    const { id, intl } = this.props;
    const { fontSize } = this.state;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        label={intl.formatMessage({ id: 'properties.text.font-size' })}
        value={Math.round(fontSize)}
        fullWidth
        InputProps={pixelEndornment}
        onChangeMethod={this.onFontSizeChange}
        onVerifyMethod={this.onFontSizeBlur}
        onClick={this.onClick}
        blurTextElement={false}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  fontSize: makeSelectorInstance(getSelectedElementFontSize)(state),
  fontSizes: makeSelectorInstance(getSelectedElementFontSizes)(state),
  editorState: makeSelectorInstance(getTemporaryTextEditorState)(state),
  fontSizeFocused: makeSelectorInstance(getTemporaryFontSizeFocused)(state),
});

const dispatchToProps = {
  setElementFontSize,
  toggleFontSizeFocused,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
)(ElementFontSize);
