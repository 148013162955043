import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Input, CircularProgress } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';

import { fetchThumbnailsFrom123RF } from '../resources/fetching123RF';
import { addImageElementFrom123RF } from '../banner/bannerActionsCreators';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { isUserAuthenticated } from '../shared-selectors/sharedSelectors';
import { getConfig } from '../config/configDucks';
import { LoggedOutMessage } from 'drb-commons-front';

const styles = {
  scrollArea: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: '60px',
    gridAutoRows: '150px',
    gridGap: '10px',
    width: '100%',
    height: 500,
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    marginRight: 10,
    marginBottom: 10,
    cursor: 'pointer',
  },
  input: {
    marginBottom: 10,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gridColumnEnd: 'span 3',
  },
  message: {
    gridColumnEnd: 'span 3',
  },
};

let debounceTimeout;
const debounce = action => (...args) => {
  clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(() => {
    action(...args);
  }, 300);
};

class NewImageFrom123RF extends React.PureComponent {
  state = {
    inputValue: '',
    keyword: '',
    images: null,
    hasMore: true,
  };

  fetchThumbnails = async page => {
    const { apiUrl } = this.props;
    const { keyword } = this.state;
    if (keyword.length === 0) {
      this.setState({ images: null, hasMore: false });
      return;
    }

    console.log(`Loading page ${page} for keyword ${keyword}`);
    // FIXME
    const { images, totalPages } = await fetchThumbnailsFrom123RF(apiUrl, keyword, page);
    this.setState({ images: [...(this.state.images || []), ...(images || [])], hasMore: totalPages > page + 1 });
  };

  handleInputChange = ({ target: { value: inputValue } }) => {
    this.setState({ inputValue });

    debounce(this.triggerSearch)(inputValue);
  };

  triggerSearch = keyword => {
    console.log('Setting keyword', keyword);
    // Reset the keyword
    this.setState({ keyword, images: null, hasMore: true });
  };

  render() {
    const { inputValue, keyword, images, hasMore } = this.state;
    const { classes, intl, addImageElementFrom123RF, authenticated } = this.props;

    return authenticated ? (
      <React.Fragment>
        <Input
          value={inputValue}
          fullWidth
          autoFocus
          placeholder={intl.formatMessage({ id: 'newImage.123rf.placeholder' })}
          classes={{ root: classes.input }}
          onChange={this.handleInputChange}
          onKeyDown={e => e.stopPropagation()}
        />
        <InfiniteScroll
          key={`images-${keyword}`}
          pageStart={-1} // So first page is 0
          hasMore={hasMore}
          loadMore={this.fetchThumbnails}
          loader={
            <div className={classes.loader} key="image-loader">
              <CircularProgress />
            </div>
          }
          useWindow={false}
          className={classes.scrollArea}
        >
          {images &&
            images.map((image, idx) => (
              <div
                key={`image-${idx}-${image.id}`}
                className={classes.thumbnail}
                onClick={() => addImageElementFrom123RF(image)}
                style={{
                  backgroundImage: `url(${image.thumbnailUrl})`,
                }}
              />
            ))}
          {images &&
            !images.length && (
              <div className={classes.message} key="no-image">
                <FormattedMessage id="newImage.123rf.no-results" />
              </div>
            )}
        </InfiniteScroll>
      </React.Fragment>
    ) : (
      <LoggedOutMessage />
    );
  }
}

const makeStateToProps = () => {
  const stateToProps = state => {
    const { apiUrl } = makeSelectorInstance(getConfig)(state);
    const authenticated = makeSelectorInstance(isUserAuthenticated)(state);
    return {
      apiUrl,
      authenticated,
    };
  };
  return stateToProps;
};

const dispatchToProps = {
  addImageElementFrom123RF,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(NewImageFrom123RF);
