import { checkWidthTooLow, checkHeightTooLow } from './checkDimensionTooLow';
import { calculateExtremeCursorCases } from './calculateExtremeCursorCases';
import { calculateMiddleHandlesCursorCases } from './calculateMiddleHandlesCursorCases';
import { calculateNewPosition } from './calculateNewPosition';
import { calculateNewDimensions } from './calculateNewDimensions';
import { calculateLockAspectRatioCursorCases } from './calculateLockAspectRatioCursorCases';
import { calculateLockAspectRatioMinCursorPosition } from './calculateLockAspectRatioMinCursorPosition';
import { calculateLockAspectRatioDiagonalPerpendicular } from './calculateLockAspectRatioDiagonalPerpendicular';
import { calculateLockAspectRatioDiagonal } from './calculateLockAspectRatioDiagonal';
import { getCornerPositionNotRotated } from './getCornerPositionNotRotated';
import { getFixedCornerWhileResizing } from './getFixedCornerWhileResizing';

import { calculateRotateCoordsInSystem, fromCSSToTrigo } from '../../interactions-helpers';

export const elementResizeGlobalMethod = ({
  cursorPosition,
  movingCorner,
  rotation,
  width,
  height,
  x,
  y,
  lockAspectRatio = false,
  phi,
  minHeight,
  bounds,
}) => {
  /*
    INPUT:
    movingCorner: string,
    cursorPosition: { x: y: },
    ALPHA: rad
    width: px,
    height: px,
    x: px,
    y: px,
    lockAspectRatio: bool,
    minHeight: px,
    bounds: { top: bottom: right: left: },
    root: DOMElement

    OUTPUT
    {
      x:
      y:
      width:
      height:
      cursorPositionChecked: { x, y } (in order to debug the calculated new cursor position)
    }
  */

  /*1.1 get the fixed corner*/
  const fixedCorner = getFixedCornerWhileResizing({ handleClicked: movingCorner, lockAspectRatio });

  /*1.2 get the fixed corner - css is clockwise, trigo is anti-clockwise */
  const ALPHA = fromCSSToTrigo(rotation);

  /*2 get fixed corner position not rotated first (this corner will move after resize)*/
  const fixedCornerPositionNotRotated = getCornerPositionNotRotated({ x, y, width, height, corner: fixedCorner });

  /*3 get fixed corner position rotated (this corner will NOT move after resize)*/
  const centerNotRotated = {
    X: x + width / 2,
    Y: y + height / 2,
  };

  const fixedCornerPositionRotated = calculateRotateCoordsInSystem({
    initCoords: fixedCornerPositionNotRotated,
    centerCoords: centerNotRotated,
    ALPHA: 2 * Math.PI - ALPHA,
  });

  /*4. get the diagonal and the angle between the cursor, the fixed corner
  and the side of element to check the extreme cases*/
  const diagonal = Math.sqrt(
    // pythagore
    Math.pow(fixedCornerPositionRotated.x - cursorPosition.x, 2) +
      Math.pow(fixedCornerPositionRotated.y - cursorPosition.y, 2),
  );

  /*5. get the angle between the 'base line rotated' and the diagonal... */
  const { omega } = calculateRotateCoordsInSystem({
    initCoords: cursorPosition,
    centerCoords: { X: fixedCornerPositionRotated.x, Y: fixedCornerPositionRotated.y },
    ALPHA,
  });
  /*... and the one between the minimum height diagonal and the 'base line rotated'*/
  const beta = Math.atan(minHeight / (diagonal * Math.cos(Math.PI - omega)));

  /*6. build the extreme cases (when lockAspectRatio === false)*/
  const widthTooLow = checkWidthTooLow({ omega, movingCorner });
  const heightTooLow = checkHeightTooLow({ omega, beta, movingCorner });

  /*------------------------------------*/
  /*7. CURSOR POSITION RECALCULATION*/
  /*we update the cursor position if needed, to where it should be within several step:
      7.1 - If we are clicking on a middle handle
      7.2 - If widthTooLow or heightTooLow are true

      OR

      8. - If LockAspectRatio

      We separate the methods when lockAspectRatio === true or not;*/

  /*------------------------------------*/

  /*7.1 fixed width or fixed height / middle handles dragging:
  we make as if we were dragging a corner, but with a fixed width / fixed height*/

  let cursorPositionChecked = { ...cursorPosition };
  let widthShouldBeConstant = false;
  let heightShouldBeConstant = false;

  ({ cursorPositionChecked, widthShouldBeConstant, heightShouldBeConstant } = calculateMiddleHandlesCursorCases({
    omega,
    widthTooLow,
    heightTooLow,
    movingCorner,
    cursorPosition: cursorPositionChecked,
    diagonal,
    height,
    width,
    ALPHA,
  }));

  /*7.2. extreme cases handling : small width or small height*/
  ({ cursorPositionChecked } = calculateExtremeCursorCases({
    fixedCornerPositionRotated,
    cursorPosition: cursorPositionChecked,
    ALPHA,
    movingCorner,
    diagonal,
    omega,
    beta,
    widthTooLow,
    heightTooLow,
    width,
    height,
    widthShouldBeConstant,
    heightShouldBeConstant,
    minHeight,
  }));

  /*8. handle lock aspect ratio*/
  let lockAspectRatioDiagonalPerpendicular = null;
  let lockAspectRatioDiagonal = null;
  let minCursorPosition = null;

  if (lockAspectRatio) {
    /*for handling the lock aspect ratio, calculate the minimum position of the cursor*/
    minCursorPosition = calculateLockAspectRatioMinCursorPosition({
      fixedCornerPositionRotated,
      minHeight,
      phi,
      ALPHA,
      movingCorner,
    });

    /*cf schéma : for debug, we want to show the perpendicular to the lock-aspect-ratio diagonal at minimum height,
    because this is the limit of stop dimensionsTooLow*/
    lockAspectRatioDiagonalPerpendicular = calculateLockAspectRatioDiagonalPerpendicular({
      minCursorPosition,
      phi,
      ALPHA,
      movingCorner,
    });

    lockAspectRatioDiagonal = calculateLockAspectRatioDiagonal({
      minCursorPosition,
      phi,
      ALPHA,
      movingCorner,
    });

    widthShouldBeConstant = false;
    heightShouldBeConstant = false;

    ({ cursorPositionChecked } = calculateLockAspectRatioCursorCases({
      lockAspectRatio,
      phi,
      omega,
      widthTooLow,
      heightTooLow,
      fixedCornerPositionRotated,
      movingCorner,
      minHeight,
      cursorPosition,
      diagonal,
      height,
      width,
      ALPHA,
      minCursorPosition,
    }));
  }

  /*9. get the new center of the element rotated :
    it is the middle of the line between fixed corner and moving one*/
  const newCenter = {
    X: (fixedCornerPositionRotated.x + cursorPositionChecked.x) / 2,
    Y: (fixedCornerPositionRotated.y + cursorPositionChecked.y) / 2,
  };

  /*10. the new center is the same for rotated or non rotated element:
      we get the new position of the non rotated fixed corner*/
  const newFixedCornerPositionNonRotated = calculateRotateCoordsInSystem({
    initCoords: fixedCornerPositionRotated,
    centerCoords: newCenter,
    ALPHA,
  });

  /*11. the moving corner is the symetric of the fixed corner with respect to the new center*/
  const cursorPositionNotRotated = {
    x: newCenter.X + (newCenter.X - newFixedCornerPositionNonRotated.x),
    y: newCenter.Y + (newCenter.Y - newFixedCornerPositionNonRotated.y),
  };

  /*12. Get the new dimensions in non rotated new element*/
  const newDimensions = calculateNewDimensions({
    widthShouldBeConstant,
    heightShouldBeConstant,
    cursorPositionNotRotated,
    newFixedCornerPositionNonRotated,
    width,
    height,
    lockAspectRatio,
    movingCorner,
  });

  /*14. New height can't be smaller than minimum height*/
  if (newDimensions.height < minHeight) {
    newDimensions.height = minHeight;
  }

  /*15. Get new position in non rotated new element*/
  const newPosition = calculateNewPosition({ x, y, newDimensions, fixedCorner, newFixedCornerPositionNonRotated });

  /*16. check if everything is within the bounds : TODO - or not, because we shouldn't check ?*/

  /*17. FINALLY, return proper values*/

  /*to avoid any bug, we put || 0*/
  if (widthTooLow && heightTooLow) {
    return {
      x: x || 0,
      y: y || 0,
      width: width || 0,
      height: height || 0,
      cursorPositionChecked,
      minCursorPosition,
      lockAspectRatioDiagonalPerpendicular,
      lockAspectRatioDiagonal,
    };
  } else {
    return {
      x: newPosition.x || 0,
      y: newPosition.y || 0,
      width: newDimensions.width || 0,
      height: newDimensions.height || 0,
      cursorPositionChecked,
      minCursorPosition,
      lockAspectRatioDiagonalPerpendicular,
      lockAspectRatioDiagonal,
    };
  }
};
