import fetchNuxeo from './fetchNuxeo';

const fetchNuxeoDeleteBannerSet = uid =>
  fetchNuxeo({
    path: '/id/' + uid,
    method: 'DELETE',
    errorMessage: "can't delete bannerset from nuxeo",
  });

export default fetchNuxeoDeleteBannerSet;
