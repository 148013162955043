import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, onlyUpdateForKeys } from 'recompose';
import { withStyles } from '@material-ui/core';
import {
  getBannerSlides,
  getSelectedSlide,
  getSelectedElements,
  getSelectedSlideElementIds,
} from '../../banner/bannerSelectors';
import {
  setElementDuration,
  setSlidesDuration,
  setSlideDuration,
  setElementsDuration,
} from '../../banner/bannerActionsCreators';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { durationInputProps, secondEndornment, parseFloatHelper } from '../constants';
import { addWarning, addError } from '../../messages/messagesDucks';
import { verifyElementsDuration, allElementsHasSameProperty, elementsPropertyAverage } from '../helpers';
import { styles } from '../propertyEditorStyles';
import { getSelectedSlideIndex } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementsDuration extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { classes, slide, slides, selectedSlideIndex, elements } = nextProps;
    return {
      durationAdditionalData: {
        slide,
        slides,
        selectedSlideIndex,
        elements,
      },
      InputProps: {
        ...secondEndornment,
        classes: {
          root: allElementsHasSameProperty(elements, 'duration') === false && classes.averageInput,
        },
      },
    };
  }

  onElementsDurationChange = ({ value: duration, untrack }) => {
    this.props.setElementsDuration({ ids: this.props.ids, duration, untrack }); //tracked
  };

  onElementsDurationVerify = ({ value: duration, additionalData: { slide, slides, selectedSlideIndex, elements } }) => {
    const { addError, addWarning, setElementDuration, setSlideDuration, setSlidesDuration } = this.props;
    verifyElementsDuration({
      slide,
      slides,
      duration,
      selectedSlideIndex,
      elements,
      addError,
      addWarning,
      setElementDuration,
      setSlideDuration,
      setSlidesDuration,
    });
  };

  render() {
    const { intl, ids, elements } = this.props;
    const { durationAdditionalData, InputProps } = this.state;
    return (
      <TextFieldDoped
        key={ids}
        type="number"
        label={intl.formatMessage({ id: 'properties.duration' })}
        value={elementsPropertyAverage(elements, 'duration', false)[0]}
        fullWidth
        inputProps={durationInputProps}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={InputProps}
        additionalData={durationAdditionalData}
        helper={parseFloatHelper}
        onChangeMethod={this.onElementsDurationChange} //tracked
        onVerifyMethod={this.onElementsDurationVerify}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  elements: makeSelectorInstance(getSelectedElements)(state),
  ids: makeSelectorInstance(getSelectedSlideElementIds)(state),
  slide: makeSelectorInstance(getSelectedSlide)(state),
  selectedSlideIndex: makeSelectorInstance(getSelectedSlideIndex)(state),
  slides: makeSelectorInstance(getBannerSlides)(state),
});

const dispatchToProps = {
  setElementDuration,
  addError,
  addWarning,
  setSlideDuration,
  setSlidesDuration,
  setElementsDuration,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  onlyUpdateForKeys(['id', 'duration']),
  injectIntl,
  withStyles(styles),
)(ElementsDuration);
