/**
 *
 * DrBannerThemeProvider
 *
 */

import { connect } from 'react-redux';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getUserThemeFromState } from './layoutDucks';
import { DrBannerThemeProvider } from 'drb-commons-front';

const makeStateToProps = () => state => ({
  userTheme: makeSelectorInstance(getUserThemeFromState)(state),
  adBuilder: true,
});

export default connect(makeStateToProps)(DrBannerThemeProvider);
