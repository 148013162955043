import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementText } from '../../banner/bannerSelectors';
import { setElementTextContent } from '../../banner/bannerActionsCreators';
import { checkKeyDiff } from '../../components/helpers';
import { noChangeHelper } from '../constants';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementText extends React.Component {
  shouldComponentUpdate(nextProps) {
    return Boolean(checkKeyDiff(nextProps, this.props) && checkKeyDiff(nextProps, this.props).includes('text'));
  }

  onTextChange = ({ value: text, id }) => this.props.setElementTextContent({ text, id });

  render() {
    const { id, text, intl } = this.props;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="text"
        label={intl.formatMessage({ id: 'properties.text.text' })}
        value={text}
        fullWidth
        multiline
        helper={noChangeHelper} //to prevent parseInt...
        onChangeMethod={this.onTextChange} //tracked
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  text: makeSelectorInstance(getSelectedElementText)(state),
});

const dispatchToProps = {
  setElementTextContent,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
)(ElementText);
