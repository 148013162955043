import undoable, { ActionTypes } from 'redux-undo';
import recycleState from 'redux-recycle';
import { BannerRecord } from '../reference/records';
import * as bannerActions from './bannerActions';
import * as bannersActions from '../banners/bannersActions';
import bannerReducer from './bannerDucks';
import { filter } from './bannerFilteredActions';

const undoableReducer = undoable(bannerReducer, {
  limit: 5,
  undoType: ActionTypes.UNDO,
  redoType: ActionTypes.REDO,
  filter,
  initTypes: ['@@redux-undo/INIT'],
});

const reducer = recycleState(
  undoableReducer,
  [bannerActions.BANNER_NEW, bannersActions.BANNERS_LOAD, bannersActions.BANNERS_CLOSE_ALL],
  (_, action) => {
    return (
      action.payload || {
        past: [],
        future: [],
        present: BannerRecord(),
      }
    );
  },
  {
    recycleActionType: false,
  },
);

export default reducer;
