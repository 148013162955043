import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, AppBar, Toolbar, Input, Tooltip } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import { appBarHeight } from '../reference/dimensions';
import keyboardShortcuts from '../reference/keyboardShortcuts';
import makeSelectorInstance from '../reference/makeSelectorInstance';

import { getBannerSetName, isBannerSetIsReadOnly, getBannerSetBreadcrumb } from './bannersSelectors';
import { toggleLocale } from '../i18n/i18nDucks';
import { setBannerSetName } from './bannersActionsCreators';
import { Lock } from '@material-ui/icons';
import { isAppLoaded } from '../shared-selectors/sharedSelectors';

const debugLocale = true;

const styles = theme => ({
  root: {
    fontSize: '0.8125rem',
    zIndex: 106,
  },
  appBar: {
    background: 'white',
    borderRadius: 0,
    height: appBarHeight,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toolBar: {
    minHeight: appBarHeight,
    height: appBarHeight,
    justifyContent: 'center',
    width: '100%',
  },
  inputContainer: {
    position: 'relative',
    flex: 0,
    flexBasis: 'auto',
    display: 'flex',
    alignItems: 'center',
    '&::after': {
      content: 'attr(data-crumb)',
      position: 'absolute',
      color: theme.palette.text.disabled,
      left: -505,
      width: 500,
      textAlign: 'right',
      fontSize: 13,
      lineHeight: '19px',
      fontStyle: 'italic',
      padding: '6px 0 7px',
    },
  },
  input: {
    textTransform: 'uppercase',
    cursor: 'text',
    fontSize: '0.8125rem',
  },
  underline: {
    '&::before': {
      borderColor: 'transparent',
      borderWidth: '1px !important',
    },
  },
  name: {
    textAlign: 'center',
  },
  date: {
    width: 170,
  },
  static: {
    opacity: 0.35,
    display: 'inline-flex',
    alignItems: 'center',
  },
  inputEditable: {
    cursor: 'text',
    background: 'white',
    fontStyle: 'normal',
    opacity: 1,
  },
  lockIcon: {
    opacity: 0.35,
    cursor: 'default',
    height: 15,
    width: 15,
    marginTop: -4,
    color: 'black',
  },
  toggleLanguageButton: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: 240,
    height: 20,
    zIndex: 100000,
    background: 'rgba(236, 236, 236, 1.00)',
    borderRadius: 5,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

class BannerSetNameBar extends React.PureComponent {
  state = {
    editable: false,
  };

  handleAllowEdit = () => {
    this.setState(
      ({ editable }) => ({ editable: !editable }),
      () => {
        this.state.editable && this.name.focus();
      },
    );
  };

  handleForbidEdit = () => {
    // timeout to prevent blur if click on the other input
    window.setTimeout(() => {
      if (!this.nameFocused && !this.dateFocused) {
        this.setState(() => ({ editable: false }));
      }
    }, 250);
  };

  handleNameChange = e => {
    this.props.setBannerSetName(e.target.value);
  };

  render() {
    const { classes, bannerSetName, appLoaded, toggleLocale, bannerSetCrumb, readOnly, intl } = this.props;

    const { editable } = this.state;

    return (
      <div className={classes.root}>
        {process.env.NODE_ENV === 'development' &&
          debugLocale && (
            <button type="button" className={classes.toggleLanguageButton} onClick={toggleLocale}>
              dev mode only: click to toggle language
            </button>
          )}
        <AppBar position="relative" classes={{ root: classes.appBar }} color="primary">
          {appLoaded && (
            <Toolbar classes={{ root: classes.toolBar }}>
              <div className={classes.inputContainer} data-crumb={bannerSetCrumb}>
                <Input
                  inputRef={input => (this.name = input)}
                  classes={{
                    root: classes.inputRoot,
                    input: classnames(classes.input, classes.name, editable && classes.inputEditable),
                    underline: classes.underline,
                  }}
                  value={bannerSetName || ''}
                  onChange={this.handleNameChange}
                  onKeyUp={e => {
                    e.stopPropagation();
                    if (keyboardShortcuts.cancel.includes(e.key) || keyboardShortcuts.enter.includes(e.key)) {
                      this.nameFocused = false;
                      this.handleForbidEdit();
                    }
                  }}
                  inputProps={{
                    style: {
                      minWidth: bannerSetName ? bannerSetName.length * 8 : 0,
                    },
                  }}
                  onKeyDown={e => e.stopPropagation()}
                  onFocus={() => (this.nameFocused = true)}
                  onBlur={() => {
                    this.nameFocused = false;
                    this.handleForbidEdit();
                  }}
                />
                {readOnly && (
                  <Tooltip title={intl.formatMessage({ id: 'open-bannerset-modal.read-only' })}>
                    <Lock classes={{ root: classes.lockIcon }} />
                  </Tooltip>
                )}
              </div>
            </Toolbar>
          )}
        </AppBar>
      </div>
    );
  }
}

const makeStateToProps = () => state => ({
  bannerSetName: makeSelectorInstance(getBannerSetName)(state),
  bannerSetCrumb: makeSelectorInstance(getBannerSetBreadcrumb)(state),
  readOnly: makeSelectorInstance(isBannerSetIsReadOnly)(state),
  appLoaded: makeSelectorInstance(isAppLoaded)(state),
});

const dispatchToProps = {
  setBannerSetName,
  toggleLocale,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(BannerSetNameBar);
