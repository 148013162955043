import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementPositionX, getSelectedElementPositionY } from '../../banner/bannerSelectors';
import { setElementPosition } from '../../banner/bannerActionsCreators';
import { getSelectedElementId, getSelectedElementCroppingStatus } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementPositionX extends React.Component {
  onChangePositionX = ({ value: x, untrack, id }) => {
    const { setElementPosition, y, isCropping } = this.props;
    setElementPosition({ x, y, id, isCropping, untrack }); //tracked
  };

  render() {
    const { id, x, intl } = this.props;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        label={intl.formatMessage({ id: 'properties.position.x' })}
        value={Math.round(x)}
        fullWidth
        onChangeMethod={this.onChangePositionX}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  x: makeSelectorInstance(getSelectedElementPositionX)(state),
  y: makeSelectorInstance(getSelectedElementPositionY)(state),
  isCropping: makeSelectorInstance(getSelectedElementCroppingStatus)(state),
});

const dispatchToProps = {
  setElementPosition,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
)(ElementPositionX);
