export const needToUpdateGuides = ({ currentGuides, guidesToShow }) => {
  let needToUpdateGuides = false;
  if (guidesToShow && guidesToShow.length < currentGuides.length) {
    needToUpdateGuides = true;
  } else {
    guidesToShow.forEach((guide, i) => {
      if (guide !== currentGuides[i] || currentGuides[i].length === 0) {
        needToUpdateGuides = true;
      }
    });
  }
  return needToUpdateGuides;
};
