import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, Dialog, DialogTitle } from '@material-ui/core';
import { isImportingBannerSet } from './openBannerSetDucks';
import makeSelectorInstance from '../reference/makeSelectorInstance';

const styles = theme => ({
  paper: {
    maxWidth: 650,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  noPadding: {
    padding: 0,
  },
  variantBody2: {
    color: theme.typography.body2.color,
    marginTop: theme.spacing(2),
  },
});

const ImportBannersDialog = ({ classes, importingBannerSet }) => {
  const dialogClasses = {
    paperWidthSm: classes.paper,
    paperWidthMd: classes.paper,
    paperWidthXs: classes.paper,
  };
  return (
    <Dialog open={importingBannerSet} classes={dialogClasses} disableBackdropClick disableEscapeKeyDown>
      <DialogTitle classes={{ root: classes.noPadding }}>
        <FormattedMessage id="banner.open.importing" />
      </DialogTitle>
    </Dialog>
  );
};

const makeStateToProps = () => state => ({
  importingBannerSet: makeSelectorInstance(isImportingBannerSet)(state),
});

export default compose(
  connect(makeStateToProps),
  withStyles(styles),
  React.memo,
)(ImportBannersDialog);
