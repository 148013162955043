import React from 'react';
import RectangleElement from './RectangleElement';
import ImageElement from './ImageElement';
import TextElement from './TextElement';
import ButtonElement from './ButtonElement';
import { setElementSnapshotAnimationProperties } from './animation-helpers';

class ElementSnapshotHidden extends React.Component {
  state = { style: {} };

  static getDerivedStateFromProps(nextProps) {
    const { type, width, height, x, y, currentFrame, rotation, framesPerSecond, noAnimation } = nextProps;
    let animation;
    if (!noAnimation) {
      animation = setElementSnapshotAnimationProperties(framesPerSecond, currentFrame)(null, nextProps).animation;
    } else {
      animation = {};
    }

    return {
      style: {
        left: x,
        top: y,
        width,
        height,
        minHeight: type === 'text' && 20,
        position: 'absolute',
        display: 'flex',
        pointerEvents: 'none',
        boxSizing: 'border-box',
        zIndex: 10,
        transform: `rotate(${-Math.abs(rotation)}deg)`,
        ...animation,
      },
      position: { x, y },
    };
  }

  render() {
    const {
      state: { style, position },
      props,
    } = this;
    const { type } = props;
    return (
      <div style={style}>
        {type === 'rectangle' && <RectangleElement {...props} />}
        {type === 'image' && <ImageElement forSnapshot {...props} position={position} />}
        {type === 'text' && <TextElement forSnapshot {...props} />}
        {type === 'button' && <ButtonElement {...props} />}
      </div>
    );
  }
}

export default ElementSnapshotHidden;
