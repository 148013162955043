import React from 'react';
import { connect } from 'react-redux';
import Morphable from './Morphable';

import { getBannerGuidesForElement } from '../banner/bannerSelectors';
import {
  setImagepicWhileCroppingPropertiesPosition,
  setImagepicWhileCroppingPropertiesRotation,
  setImagepicWhileCroppingPropertiesDimensions,
} from '../banner/bannerActionsCreators';

import { calculateCropElementPosition, needToUpdateGuides } from './interactions-helpers';
import makeSelectorInstance from '../reference/makeSelectorInstance';

import magnetismGrids from '../reference/magnetismGrids';
import { updateBannerStatus, updateElementStatus } from '../temporary-status/temporaryStatusDucks';
const debug = false;

class Crop extends React.Component {
  state = {
    rotation: -Math.abs(this.props.picWhileCroppingProperties.rotation),
    position: null, //cf getDerivedStateFromProps, less verbose than here
    size: {
      width: this.props.picWhileCroppingProperties.width,
      height: this.props.picWhileCroppingProperties.height,
    },
    backgroundProperties: {
      x: 0,
      y: 0,
      width: this.props.picWhileCroppingProperties.width,
      height: this.props.picWhileCroppingProperties.height,
    },
    status: { ...this.props.status, isCropping: false },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.position) {
      const { picWhileCroppingProperties, width, height, x, y, rotation } = nextProps;
      const position = calculateCropElementPosition({
        picWhileCroppingProperties,
        width,
        height,
        x,
        y,
        rotation,
      });

      return {
        ...prevState,
        position,
      };
    }
    return prevState;
  }

  componentDidMount() {
    this.guidesToShow = [];
  }

  /*------------------------------------*/
  /*DRAG METHODS*/
  /*------------------------------------*/
  onHandleMouseDown = () => {
    this.props.onHandleMouseDown();
  };

  onDragStart = () => {
    const { updateElementStatus, updateBannerStatus, id, selected } = this.props;

    this.guidesToShow = [];

    /*tell redux store we start the drag*/
    if (selected) {
      updateElementStatus({ isDragging: true, id });
      updateBannerStatus({ isDragging: true });
    }
  };

  onDrag = ({ x, y, guidesToShow = [] }) => {
    const { zoom, showGuides } = this.props;
    /*performance issue: if guides to show changed, we need to update the Scene component. if not, no need to update.*/
    if (needToUpdateGuides({ guidesToShow, currentGuides: this.guidesToShow })) {
      showGuides(guidesToShow);
      this.guidesToShow = guidesToShow;
    }

    /*update the component*/
    this.setState({ position: { x: x / zoom, y: y / zoom } });
  };

  onDragStop = () => {
    const {
      id,
      selected,
      setImagepicWhileCroppingPropertiesPosition,
      updateElementStatus,
      updateBannerStatus,
      showGuides,
    } = this.props;

    const {
      position: { x, y },
    } = this.state;

    if (selected) {
      setImagepicWhileCroppingPropertiesPosition({ x, y, id });
      updateElementStatus({ isDragging: false, id });
      updateBannerStatus({ isDragging: false });
      showGuides(undefined);
    }

    return false;
  };

  /*------------------------------------*/
  /*RESIZE METHODS*/
  /*------------------------------------*/

  onResizeStart = e => {
    const { updateElementStatus, updateBannerStatus, id } = this.props;

    /*tell redux store we start the resize*/
    updateElementStatus({ isResizing: true, id });
    updateBannerStatus({ isResizing: true });
  };

  onResize = ({ width, height, x, y, guidesToShow }) => {
    const { zoom, showGuides } = this.props;

    if (needToUpdateGuides({ guidesToShow, currentGuides: this.guidesToShow })) {
      showGuides(guidesToShow);
      this.guidesToShow = guidesToShow;
    }

    this.setState({
      size: {
        height: height / zoom,
        width: width / zoom,
      },
      backgroundProperties: {
        x: 0,
        y: 0,
        width: width / zoom,
        height: height / zoom,
      },
      position: {
        x: x / zoom,
        y: y / zoom,
      },
    });
  };

  onResizeStop = () => {
    debug && console.log('onResizeStop Crop');
    const {
      updateElementStatus,
      updateBannerStatus,
      setImagepicWhileCroppingPropertiesDimensions,
      setImagepicWhileCroppingPropertiesPosition,
      id,
    } = this.props;
    const {
      position: { x, y },
      size: { width, height },
    } = this.state;

    setImagepicWhileCroppingPropertiesDimensions({ id, width, height });
    setImagepicWhileCroppingPropertiesPosition({ id, x, y });
    updateElementStatus({ isResizing: false, id });
    updateBannerStatus({ isResizing: false });
  };

  /*------------------------------------*/
  /*ROTATE*/
  /*------------------------------------*/

  onRotateStart = () => {
    const { updateElementStatus, updateBannerStatus, id } = this.props;

    /*tell redux store we start the rotation*/
    updateElementStatus({ isRotating: true, id });
    updateBannerStatus({ isRotating: true });
  };

  onRotate = ({ rotation }) => {
    const { status } = this.props;
    if (status.isRotating) {
      this.setState({ rotation });
    } else {
      console.error('inside rotating method without rotating status');
    }
  };

  onRotateEnd = () => {
    const { id, setImagepicWhileCroppingPropertiesRotation, updateElementStatus, updateBannerStatus } = this.props;
    const { rotation } = this.state;
    setImagepicWhileCroppingPropertiesRotation({ id, rotation });
    updateElementStatus({ isRotating: false, id });
    updateBannerStatus({ isRotating: false });
  };

  /*------------------------------------*/
  /*RENDER*/
  /*------------------------------------*/

  render() {
    const { position, rotation, size, minHeight, status } = this.state;
    const { bannerRef, selected } = this.props;

    return (
      <Morphable
        onHandleMouseDown={this.onHandleMouseDown}
        onDragStart={this.onDragStart}
        onDrag={this.onDrag}
        onDragStop={this.onDragStop}
        onResizeStart={this.onResizeStart}
        onResize={this.onResize}
        onResizeStop={this.onResizeStop}
        onRotateStart={this.onRotateStart}
        onRotate={this.onRotate}
        onRotateEnd={this.onRotateEnd}
        minHeight={minHeight}
        magnetismGrids={magnetismGrids}
        position={position}
        size={size}
        selected={selected}
        {...this.props}
        status={status}
        rotation={rotation}
        parentRef={bannerRef}
        disableRotation
        disableBounds
        // disableGuides
        saveTopLeftCorner={this.props.saveTopLeftCornerImage}
      >
        {this.props.render(this.state)}
      </Morphable>
    );
  }
}
const makeStateToProps = () => {
  const stateToProps = (state, { id }) => {
    const guides = makeSelectorInstance(getBannerGuidesForElement(id))(state);
    return {
      guides,
    };
  };
  return stateToProps;
};

const dispatchToProps = {
  setImagepicWhileCroppingPropertiesPosition,
  setImagepicWhileCroppingPropertiesRotation,
  setImagepicWhileCroppingPropertiesDimensions,
  updateBannerStatus,
  updateElementStatus,
};

export default connect(
  makeStateToProps,
  dispatchToProps,
)(Crop);
