import { Set, Record, Map, List } from 'immutable';
import { slideTransitionTypesVar, transitionTypesVar } from './transitions';
import { createRawContentStateFromText } from '../element/textHelpers';
import { formatFontStyles } from './textProperties';

/*
    TEMPORARY STATUS RECORDS
TemporaryStatusRecord
ElementStatusRecord
BannerAnimationStatusRecord
BannerStatusRecord

    ELEMENTS RECORDS
ElementRecord
ElementTransitionRecord
DropShadowRecord
BlinkAnimationRecord
GuideRecord
TextElementRecord
SizeRecord
CroppingPropertiesRecord
ImageElementRecord
RectangleElementRecord
ButtonElementRecord

    SLIDE RECORDS
SlideTransitionRecord
SlideRecord

    BANNER RECORDS
BannerRecord
BannerFormatRecord

    BANNERS RECORDS
BannersRecord
BannerSetRecord

    METHODS
bannerSetFromJS
slideFromJS
getLastIdOfType
bannerFromJS

*/

/* TEMPORARY STATUS RECORDS */

export const ElementStatusRecord = Record(
  {
    isRotating: false,
    isDragging: false,
    isResizing: false,
    isCropping: false,
    textIsSelected: false,
    elementIsPlayingAnimation: false,
  },
  'ElementStatusRecord',
);

export const BannerAnimationStatusRecord = Record(
  {
    bannerIsPlayingHTMLAnimation: false,
    bannerIsPlayingGIFAnimation: false,
    currentFrame: 0,
    showAnimationSnapshot: false,
    slideIsPlayingAnimation: false,
  },
  'BannerAnimationStatusRecord',
);

export const BannerStatusRecord = Record(
  {
    isCropping: false, //to prevent drag and drop zone to be activated
    isResizing: false, //to prevent drag and drop zone to be activated
    isDragging: false, //to prevent drag and drop zone to be activated
    isRotating: false,
    isEyeDropping: false, //to modify the canvas and the cursor etc.
    isDraggingFrameSelector: false, //to modify the canvas and the cursor etc.
    textIsSelected: false, //to modify the canvas and the cursor etc.
    colorPickerIsOpen: false, //to prevent keyboard shortcuts
  },
  'BannerStatusRecord',
);

export const TemporaryStatusRecord = Record(
  {
    selectedElementId: null,
    selectedElementIds: Set(),
    selectedSlideIndex: 0,
    selectedBannerIndex: 0,
    bannerStatus: BannerStatusRecord(),
    animationStatus: BannerAnimationStatusRecord(),
    elementStatus: ElementStatusRecord(),
  },
  'TemporaryStatusRecord',
);

/* ELEMENTS RECORDS */

export const ElementTransitionRecord = Record(
  {
    type: transitionTypesVar.none,
    duration: 0,
    delay: 0,
    direction: null,
    distance: null,
    defaultDistance: true,
  },
  'ElementTransitionRecord',
);

export const DropShadowRecord = Record(
  {
    color: 'rgba(0, 0, 0, 0.5)',
    offsetX: 0,
    offsetY: 0,
    blurRadius: 5,
    spreadRadius: 0,
  },
  'DropShadowRecord',
);

export const BlinkAnimationRecord = Record(
  {
    minOpacity: 0.5,
    frequency: 1,
  },
  'BlinkAnimationRecord',
);

export const GuideRecord = Record(
  {
    orientation: undefined, // 'horizontal' | 'vertical'
    magnetism: undefined, // 'center' | 'top' | 'bottom' | 'left' | 'right' | 'rotation' | 'equidistant'
    elementIds: Set(),
    position: 0,
  },
  'GuideRecord',
);

export const ElementRecord = defaultValues =>
  Record(
    {
      id: undefined,
      type: undefined,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      opacity: 1,
      rotation: 0,
      dropShadowEnabled: false,
      dropShadow: DropShadowRecord(),
      blinkAnimationEnabled: false,
      blinkAnimation: BlinkAnimationRecord(),
      duration: 6,
      transitionIn: ElementTransitionRecord({ direction: 'fromLeft' }),
      transitionOut: ElementTransitionRecord({ direction: 'toRight' }),
      // status: ElementStatusRecord(),
      showOnAllSlides: false,
      transitionsOnAllSlides: false,
      ...defaultValues,
    },
    'ElementRecord',
  );

export const TextElementRecord = ElementRecord(
  {
    type: 'text',
    contentState: undefined, // contentState from draft-js
    text: '', //get from contentState
    customizable: false,
    property: null,
    localizable: false,
    localizationKey: null,
    fontFamily: 'Open Sans',
    fontStyle: Set(),
    fontSizes: Set(),
    fontSize: 16,
    horizontalAlign: 'center',
    verticalAlign: 'center',
    color: '#000',
  },
  'TextElementRecord',
);

export const SizeRecord = Record({ width: 0, height: 0 }, 'SizeRecord');

export const CroppingPropertiesRecord = Record(
  { x: 0, y: 0, width: 0, height: 0, rotation: 0 },
  'CroppingPropertiesRecord',
);

export const ImageElementRecord = ElementRecord(
  {
    type: 'image',
    fileName: undefined,
    customizable: false,
    property: null,
    resourceId: undefined,
    optimizedResourceId: undefined,
    thumbnailUrl: undefined,
    flipH: false,
    flipV: false,
    lockAspectRatio: true,
    borderColor: '#000000',
    borderWidth: 0,
    borderRadius: 0,
    borderRadiusUnit: 'px',
    originalSize: SizeRecord(),
    picWhileCroppingProperties: CroppingPropertiesRecord(),
  },
  'ImageElementRecord',
);

export const RectangleElementRecord = ElementRecord(
  {
    type: 'rectangle',
    backgroundColor: '#FFFFFF',
    borderColor: '#000000',
    borderWidth: 1,
    borderRadius: 0,
    borderRadiusUnit: 'px',
    lockAspectRatio: false,
    originalSize: SizeRecord(),
  },
  'RectangleElementRecord',
);

export const ButtonElementRecord = ElementRecord(
  {
    type: 'button',
    text: '',
    customizable: false,
    property: null,
    localizable: false,
    localizationKey: null,
    fontFamily: 'Open Sans',
    fontStyle: Set(),
    fontSize: 16,
    color: '#FFFFFF',
    backgroundColor: '#03a9f4',
    borderColor: '#01579b',
    borderWidth: 0,
    borderRadius: 10,
    borderRadiusUnit: 'px',
    appearance: 'flat',
  },
  'ButtonElementRecord',
);

/* SLIDE RECORDS */

export const SlideTransitionRecord = Record(
  {
    type: slideTransitionTypesVar.none,
    duration: 0,
  },
  'SlideTransitionRecord',
);

export const SlideRecord = Record(
  {
    id: undefined,
    duration: 6,
    // slideIsPlayingAnimation: false,
    transition: SlideTransitionRecord(),
    elementIds: List(),
  },
  'SlideRecord',
);

/* BANNER RECORDS */

export const BannerFormatRecord = Record(
  {
    name: 'medium_rectangle',
    width: 300,
    height: 250,
  },
  'BannerFormatRecord',
);

export const BannerRecord = Record(
  {
    name: undefined,
    format: BannerFormatRecord(),
    thumbnailResourceId: undefined,
    zoom: 1,
    backgroundColor: '#FFF',
    transparentColor: '#00f74b',
    repetitions: 1,
    elements: Map(), // Element, indexed by id
    selectedElementId: null,
    selectedElementIds: Set(),
    slides: List.of(SlideRecord({ id: 'slide-1' })),
    selectedSlideIndex: 0,
    dissociateFromMaster: false,
    isMaster: false,
    nextId: Map({
      slide: 2,
      text: 1,
      image: 1,
      rectangle: 1,
      button: 1,
    }),
    expirationDate: null,
  },
  'BannerRecord',
);

/* BANNERS RECORDS */

export const BannerSetRecord = Record(
  {
    name: undefined,
    framesPerSecond: process.env.REACT_APP_ANIMATED_GIF_FPS,
    thumbnailResourceId: undefined,
    uid: undefined,
    lockOwner: '',
    workspacePath: null,
    ownerName: undefined, // for having name of owner when sharing and banner-set is distributed read-only
    permissions: [],
    expirationDate: null,
  },
  'BannerSetRecord',
);

export const BannersRecord = Record(
  {
    banners: List(), // BannerRecord
    // selectedIndex: 0,
    bannerSet: BannerSetRecord(),
    // bannerMasterIndex: null,
  },
  'BannersRecord',
);

export const records = [
  ElementTransitionRecord,
  ElementStatusRecord,
  DropShadowRecord,
  BlinkAnimationRecord,
  TextElementRecord,
  SizeRecord,
  CroppingPropertiesRecord,
  ImageElementRecord,
  RectangleElementRecord,
  ButtonElementRecord,
  SlideTransitionRecord,
  SlideRecord,
  BannerFormatRecord,
  BannerAnimationStatusRecord,
  BannerStatusRecord,
  BannerRecord,
  GuideRecord,
  BannerSetRecord,
  BannersRecord,
  TemporaryStatusRecord,
];

export const bannerSetFromJS = js => BannerSetRecord(js);

export const slideFromJS = js =>
  SlideRecord({
    ...js,
    elementIds: List(js.elementIds),
    transition: SlideTransitionRecord(js.transition),
  });

export const elementFromJS = jsElement => {
  const baseElement = {
    ...jsElement,
    dropShadow: DropShadowRecord(jsElement.dropShadow || undefined),
    blinkAnimation: BlinkAnimationRecord(jsElement.blinkAnimation || undefined),
    transitionIn: ElementTransitionRecord(jsElement.transitionIn),
    transitionOut: ElementTransitionRecord(jsElement.transitionOut),
  };
  switch (jsElement.type) {
    case 'text':
      return TextElementRecord({
        ...baseElement,
        contentState: jsElement.contentState || createRawContentStateFromText(jsElement.text),
        fontStyle: Set(formatFontStyles(jsElement.fontStyle)),
        fontSizes: jsElement.fontSizes ? Set.of(jsElement.fontSizes) : Set(),
      });
    case 'image':
      return ImageElementRecord({
        ...baseElement,
        originalSize: SizeRecord(jsElement.originalSize),
        picWhileCroppingProperties: CroppingPropertiesRecord(jsElement.picWhileCroppingProperties),
      });
    case 'rectangle':
      return RectangleElementRecord({
        ...baseElement,
        originalSize: SizeRecord(jsElement.originalSize || { width: jsElement.width, height: jsElement.height }),
      });
    case 'button':
      return ButtonElementRecord({
        ...baseElement,
        fontStyle: Set(formatFontStyles(jsElement.fontStyle)),
      });
    default:
      return Map(jsElement);
  }
};

const getLastIdOfType = (type, elements) => {
  return (
    Object.keys(elements)
      .map(key => elements[key])
      .filter(element => element.type === type)
      .map(element => element.id)
      .map(id => parseInt(id.split('-')[1], 10))
      .sort((a, b) => a - b)
      .filter((_, ind, typedElements) => ind === typedElements.length - 1)[0] + 1 //eslint-disable-next-line  no-unexpected-multiline
  );
};

export const bannerFromJS = js => {
  const nextId = Map({
    slide: js.slides.length + 1,
    text: getLastIdOfType('text', js.elements) || 1,
    image: getLastIdOfType('image', js.elements) || 1,
    rectangle: getLastIdOfType('rectangle', js.elements) || 1,
    button: getLastIdOfType('button', js.elements) || 1,
  });
  return BannerRecord({
    ...js,
    format: BannerFormatRecord(js.format),
    nextId,
    dissociateFromMaster: js.dissociateFromMaster || false,
    isMaster: js.isMaster || false,
    // contentState: js.contentState ?
    selectedElementId: null,
    selectedElementIds: Set(),
    slides: List(js.slides.map(jsSlide => slideFromJS(jsSlide))),
    elements: Map(js.elements)
      .filter(jsElement => Boolean(jsElement.id))
      .map(jsElement => elementFromJS(jsElement)),
  });
};
