import React from 'react';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FormatVerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import FormatVerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter';
import FormatVerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';

const BOLD = 'BOLD';
const ITALIC = 'ITALIC';
const UNDERLINE = 'UNDERLINE';

export const fontStylesDefinition = {
  [BOLD]: BOLD,
  [ITALIC]: ITALIC,
  [UNDERLINE]: UNDERLINE,
};
export const fontStyles = Object.keys(fontStylesDefinition);

const fontStylesMigration = fontStyle => {
  switch (fontStyle) {
    case 'bold':
    case 'BOLD':
      return BOLD;
    case 'italic':
    case 'ITALIC':
      return ITALIC;
    case 'underlined':
    case 'UNDERLINED':
    case 'underline':
    case 'UNDERLINE':
      return UNDERLINE;
    default:
      return null;
  }
};

export const formatFontStyles = fontStyles => fontStyles.map(fontStyle => fontStylesMigration(fontStyle));

export const fontSizeProperty = 'fontsize';
export const textSelectedProperty = 'TEXTSELECTED';
export const horizontalAlignments = ['left', 'center', 'right', 'justify'];
export const horizontalSvg = {
  left: 'left',
  center: 'center',
  right: 'right',
};

export const computeTextShadow = dropShadow =>
  `${dropShadow.offsetX}px ${dropShadow.offsetY}px ${dropShadow.blurRadius}px ${dropShadow.color}`;

export const verticalAlignments = ['flex-start', 'center', 'flex-end'];
export const verticalSvg = {
  'flex-start': 'top',
  center: 'middle',
  'flex-end': 'bottom',
};
export const lineHeightFactor = 1.25;
export const paddingVertical = 3 + 3;

export const computeMinimalTextStyle = ({ color, fontFamily, fontSize }) => ({
  color,
  fontFamily: `"${fontFamily}"`,
  fontSize: `${fontSize}px`,
});

export const computeTextStyle = ({ color, fontFamily, fontSize, fontStyle }) => ({
  ...computeMinimalTextStyle({ color, fontFamily, fontSize }),
  fontStyle: formatFontStyles(fontStyle).includes(ITALIC) ? 'italic' : 'normal',
  fontWeight: formatFontStyles(fontStyle).includes(BOLD) ? 'bold' : 'normal',
  textDecoration: formatFontStyles(fontStyle).includes(UNDERLINE) ? 'underline' : 'none',
  lineHeight: `${Math.round(fontSize * lineHeightFactor)}px`,
});

export const fontStyleIcons = {
  [BOLD]: <FormatBoldIcon />,
  [ITALIC]: <FormatItalicIcon />,
  [UNDERLINE]: <FormatUnderlinedIcon />,
};

export const horizontalAlignmentIcons = {
  left: <FormatAlignLeftIcon />,
  center: <FormatAlignCenterIcon />,
  right: <FormatAlignRightIcon />,
  justify: <FormatAlignJustifyIcon />,
};

export const verticalAlignmentIcons = {
  'flex-start': <FormatVerticalAlignTopIcon />,
  center: <FormatVerticalAlignCenterIcon />,
  'flex-end': <FormatVerticalAlignBottomIcon />,
};
