import equal from 'fast-deep-equal';
import { isImmutable } from 'immutable';

const debugPerf = false;

export const checkKeyDiff = (nextProps, prevProps, debug = '') => {
  if (equal(nextProps, prevProps)) return ''; // are equals
  let keyChanged = [];
  for (let key of Object.keys(nextProps).filter(k => k !== 'children')) {
    if (isImmutable(nextProps[key])) {
      if (!nextProps[key].equals(prevProps[key])) {
        keyChanged.push(key);
      }
    } else {
      if (!equal(nextProps[key], prevProps[key])) {
        keyChanged.push(key);
      }
    }
  }

  if (keyChanged.length > 0) {
    //eslint-disable-next-line no-console
    Boolean(debug) && debugPerf && console.log('checkKeyDiff', debug, keyChanged.join(', '));
    return keyChanged.join(', ');
  }
  return '';
};
