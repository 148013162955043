import { ELEMENT_SET_FONT_SIZE, BANNER_CHANGE_FORMAT, BANNER_NEW, BANNER_ELEMENT_ADDED } from '../banner/bannerActions';
import { Record } from 'immutable';
import { fontSizeProperty, textSelectedProperty } from '../reference/textProperties';
import { ALERT_OPEN, ALERT_CANCEL, ALERT_CONFIRM } from '../alert/alertDucks';
import {
  SAVE_BANNERS_DIALOG_CLOSE,
  SAVE_BANNERS_IS_DONE,
  SAVE_BANNERS_DIALOG_OPEN,
} from '../save-banners-dialog/saveBannersDucks';
import {
  BANNER_FORMAT_SELECTOR_CLOSE,
  BANNER_FORMAT_SELECTOR_OPEN,
} from '../banner-format-selector/bannerFormatSelectorDucks';
import {
  EXPORT_BANNERSETS_DIALOG_CLOSE,
  EXPORT_BANNERSETS_DONE,
  EXPORT_BANNERSETS_DIALOG_OPEN,
} from '../export/exportActions';
import {
  MASTER_BANNER_DIALOG_OPEN,
  MASTER_BANNER_DIALOG_CONFIRM_STOP,
  MASTER_BANNER_DIALOG_CANCEL,
  MASTER_BANNER_DIALOG_CONFIRM_DISSOCIATE,
} from '../master-banner-dialog/masterBannerDialogDucks';
import { FONT_ADDED, FONT_UPLOAD_DIALOG_CLOSE, FONT_UPLOAD_DIALOG_OPEN } from '../fonts/fontsDucks';
import { NEW_IMAGE_DIALOG_CLOSE, NEW_IMAGE_DIALOG_OPEN } from '../new-image/newImageDucks';
import {
  BANNERSET_CLOSE_PREV_ADD_NEW_REQUESTED,
  BANNERSET_SAVE_PREV_ADD_NEW_REQUESTED,
  BANNERSET_ADD_TO_EXISTING_BANNERS_REQUESTED,
} from '../banners/bannersActions';
import { OPEN_BANNERSET_DIALOG_CLOSE, OPEN_BANNERSET_DIALOG_OPEN } from '../open-banner-set/openBannerSetDucks';
import {
  ENTITIES_CLOSE_DIALOG,
  ENTITIES_OPEN_MOVE_DIALOG,
  ENTITIES_OPEN_SAVE_AS_DIALOG,
  ENTITIES_OPEN_SHARE_DIALOG,
} from '../entities/entitiesActions';
// this temporaryTextEditorDucks is here to manage the text editors :
// the problem of the slate-js or draft-js or any rich texet editor is
// that they store there state internally to handle some undo-redo,
// and it gets veryh big very quickly, so we don't want to save them in local storage
// but we still need them in redux to share them between the TextComponent and the PropertyElement (changing styles and all)
// so we give them a reducer that we don't persist, and is here only temporarily.

// Actions
const TEMPORARY_TEXT_EDITOR_UPDATE = 'adbuilder/TEMPORARY_TEXT_EDITOR_UPDATE';
const TEMPORARY_TEXT_EDITOR_FONTSIZE_FOCUSED = 'adbuilder/TEMPORARY_TEXT_EDITOR_FONTSIZE_FOCUSED';
const TEMPORARY_TEXT_EDITOR_TOGGLE_TRANSITION_IN_MENU = 'adbuilder/TEMPORARY_TEXT_EDITOR_TOGGLE_TRANSITION_IN_MENU';
const TEMPORARY_TEXT_EDITOR_TOGGLE_TRANSITION_OUT_MENU = 'adbuilder/TEMPORARY_TEXT_EDITOR_TOGGLE_TRANSITION_OUT_MENU';
const TEMPORARY_TEXT_EDITOR_TOGGLE_FONT_FAMILY_MENU = 'adbuilder/TEMPORARY_TEXT_EDITOR_TOGGLE_FONT_FAMILY_MENU';
const TEMPORARY_TEXT_EDITOR_TOGGLE_CUSTOM_FIELD_MENU = 'adbuilder/TEMPORARY_TEXT_EDITOR_TOGGLE_CUSTOM_FIELD_MENU';
const TEMPORARY_TEXT_EDITOR_TOGGLE_CUSTOM_VALUES_MENU = 'adbuilder/TEMPORARY_TEXT_EDITOR_TOGGLE_CUSTOM_VALUES_MENU';
const TEMPORARY_TEXT_EDITOR_TOGGLE_MENU_OR_MODAL = 'adbuilder/TEMPORARY_TEXT_EDITOR_TOGGLE_MENU_OR_MODAL';

// Actions creators
export const updateTemporaryTextEditor = ({ editorState }) => ({
  type: TEMPORARY_TEXT_EDITOR_UPDATE,
  payload: editorState,
});

export const toggleFontSizeFocused = focus => ({
  type: TEMPORARY_TEXT_EDITOR_FONTSIZE_FOCUSED,
  payload: focus,
});

export const toggleTransitionInMenuOpen = focus => ({
  type: TEMPORARY_TEXT_EDITOR_TOGGLE_TRANSITION_IN_MENU,
  payload: focus,
});

export const toggleTransitionOutMenuOpen = focus => ({
  type: TEMPORARY_TEXT_EDITOR_TOGGLE_TRANSITION_OUT_MENU,
  payload: focus,
});

export const toggleFontFamilyMenuOpen = focus => ({
  type: TEMPORARY_TEXT_EDITOR_TOGGLE_FONT_FAMILY_MENU,
  payload: focus,
});

export const toggleCustomFieldSelectOpen = focus => ({
  type: TEMPORARY_TEXT_EDITOR_TOGGLE_CUSTOM_FIELD_MENU,
  payload: focus,
});

export const toggleCustomValuesSelectOpen = focus => ({
  type: TEMPORARY_TEXT_EDITOR_TOGGLE_CUSTOM_VALUES_MENU,
  payload: focus,
});

export const toggleMenuOrModalOpen = focus => ({
  type: TEMPORARY_TEXT_EDITOR_TOGGLE_MENU_OR_MODAL,
  payload: focus,
});

// Selectors
export const getTemporaryTextEditorState = ({ temporaryTextEditor: { editorState } }) => editorState;
export const getTemporaryInlineStyle = ({ temporaryTextEditor: { inlineStyle } }) => inlineStyle;
export const getTemporaryFontSizeFocused = ({ temporaryTextEditor: { fontSizeFocused } }) => fontSizeFocused;
export const getTemporaryTransitionInMenuOpen = ({ temporaryTextEditor: { transitionInMenuOpen } }) =>
  transitionInMenuOpen;
export const getTemporaryTransitionOutMenuOpen = ({ temporaryTextEditor: { transitionOutMenuOpen } }) =>
  transitionOutMenuOpen;
export const getTemporaryCustomFieldOpen = ({ temporaryTextEditor: { customFieldMenuOpen } }) => customFieldMenuOpen;
export const getTemporaryCustomValuesOpen = ({ temporaryTextEditor: { customValuesMenuOpen } }) => customValuesMenuOpen;
export const getTemporaryFontFamilyMenuOpen = ({ temporaryTextEditor: { fontFamilyMenuOpen } }) => fontFamilyMenuOpen;

// Sagas

// Reducer

const InlineStyleRecord = Record(
  {
    key: 0,
    property: '',
    value: '',
  },
  'InlineStyleRecord',
);

export const initialState = {
  editorState: undefined,
  inlineStyle: InlineStyleRecord(),
  fontSizeFocused: false,
  transitionInMenuOpen: false,
  transitionOutMenuOpen: false,
  fontFamilyMenuOpen: false,
  customFieldMenuOpen: false,
  customValuesMenuOpen: false,
  menuOrModalOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TEMPORARY_TEXT_EDITOR_UPDATE: {
      return {
        ...state,
        editorState: action.payload,
      };
    }
    case TEMPORARY_TEXT_EDITOR_FONTSIZE_FOCUSED: {
      return {
        ...state,
        fontSizeFocused: action.payload,
        inlineStyle: InlineStyleRecord({
          key: state.inlineStyle.key + 1,
          property: textSelectedProperty,
          value: action.payload,
        }),
      };
    }
    case TEMPORARY_TEXT_EDITOR_TOGGLE_TRANSITION_IN_MENU: {
      return {
        ...state,
        transitionInMenuOpen: action.payload,
        menuOrModalOpen: action.payload,
      };
    }
    case TEMPORARY_TEXT_EDITOR_TOGGLE_TRANSITION_OUT_MENU: {
      return {
        ...state,
        transitionOutMenuOpen: action.payload,
        menuOrModalOpen: action.payload,
      };
    }
    case TEMPORARY_TEXT_EDITOR_TOGGLE_CUSTOM_FIELD_MENU: {
      return {
        ...state,
        customFieldMenuOpen: action.payload,
        menuOrModalOpen: action.payload,
      };
    }
    case TEMPORARY_TEXT_EDITOR_TOGGLE_CUSTOM_VALUES_MENU: {
      return {
        ...state,
        customValuesMenuOpen: action.payload,
        menuOrModalOpen: action.payload,
      };
    }
    case TEMPORARY_TEXT_EDITOR_TOGGLE_FONT_FAMILY_MENU: {
      return {
        ...state,
        fontFamilyMenuOpen: action.payload,
        menuOrModalOpen: action.payload,
      };
    }
    case TEMPORARY_TEXT_EDITOR_TOGGLE_MENU_OR_MODAL: {
      return {
        ...state,
        menuOrModalOpen: action.payload,
      };
    }
    case SAVE_BANNERS_DIALOG_OPEN:
    case BANNER_FORMAT_SELECTOR_OPEN:
    case EXPORT_BANNERSETS_DIALOG_OPEN:
    case FONT_UPLOAD_DIALOG_OPEN:
    case NEW_IMAGE_DIALOG_OPEN:
    case OPEN_BANNERSET_DIALOG_OPEN:
    case MASTER_BANNER_DIALOG_OPEN:
    case ENTITIES_OPEN_MOVE_DIALOG:
    case ENTITIES_OPEN_SAVE_AS_DIALOG:
    case ENTITIES_OPEN_SHARE_DIALOG:
    case ALERT_OPEN: {
      return {
        ...state,
        menuOrModalOpen: true,
      };
    }
    case SAVE_BANNERS_IS_DONE:
    case SAVE_BANNERS_DIALOG_CLOSE:
    case BANNER_FORMAT_SELECTOR_CLOSE:
    case BANNER_NEW:
    case BANNER_CHANGE_FORMAT:
    case EXPORT_BANNERSETS_DONE:
    case EXPORT_BANNERSETS_DIALOG_CLOSE:
    case FONT_UPLOAD_DIALOG_CLOSE:
    case FONT_ADDED:
    case NEW_IMAGE_DIALOG_CLOSE:
    case BANNER_ELEMENT_ADDED:
    case OPEN_BANNERSET_DIALOG_CLOSE:
    case BANNERSET_ADD_TO_EXISTING_BANNERS_REQUESTED:
    case BANNERSET_SAVE_PREV_ADD_NEW_REQUESTED:
    case BANNERSET_CLOSE_PREV_ADD_NEW_REQUESTED:
    case MASTER_BANNER_DIALOG_CONFIRM_STOP:
    case MASTER_BANNER_DIALOG_CONFIRM_DISSOCIATE:
    case ENTITIES_CLOSE_DIALOG:
    case MASTER_BANNER_DIALOG_CANCEL:
    case ALERT_CONFIRM:
    case ALERT_CANCEL: {
      return {
        ...state,
        menuOrModalOpen: false,
      };
    }
    case ELEMENT_SET_FONT_SIZE: {
      return {
        ...state,
        inlineStyle: InlineStyleRecord({
          key: state.inlineStyle.key + 1,
          property: fontSizeProperty,
          value: action.payload.fontSize,
        }),
      };
    }
    default:
      return state;
  }
};

export default reducer;
