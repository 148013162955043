import React from 'react';
import { Tooltip } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';

const rootStyle = { display: 'inline-flex', width: '100%' };
const styleNoFlex = { ...rootStyle, flex: 0 };
const styleFlex = { ...rootStyle, flex: 1 };

const ToggleButtonWithTooltip = ({ classes, noFlex, tooltip, ...props }) => {
  return (
    <Tooltip title={tooltip}>
      <div style={noFlex ? styleNoFlex : styleFlex}>
        <ToggleButton
          classes={{
            root: classes.root,
            disabled: classes.disabled,
            selected: classes.selected,
            label: classes.label,
          }}
          {...props}
        />
      </div>
    </Tooltip>
  );
};

ToggleButtonWithTooltip.defaultProps = {
  classes: {
    root: '',
    disabled: '',
    selected: '',
    label: '',
  },
  noFlex: true,
};
export default ToggleButtonWithTooltip;
