export const updateElementSnapshotAnimationProperties = (framesPerSecond, frameNumber) => (
  { animation },
  { slideDuration, slideDurationForAnimation, animationDelay, transitionIn, transitionOut, duration },
) => {
  const animationTimeLine = (1 / framesPerSecond) * frameNumber;
  //cross-fade stretch factor : if the slide undergoes a cross-fade transition, its duration increases
  //   => we have to apply this increase proportionnaly to the slide duration
  const f = slideDurationForAnimation / slideDuration;

  const animationDelayIn = animationDelay + transitionIn.delay * f;
  const animationDelayOut = animationDelay + (duration - transitionOut.duration) * f;

  let animationInSnapshot = -0.01; // to have the element positionned while wiating to start animation
  let animationOutSnapshot = 0.01; // if set to 0, the effect above doesn't work

  if (animationTimeLine >= animationDelayIn) {
    animationInSnapshot = -animationTimeLine + animationDelayIn;
  }
  if (animationTimeLine >= animationDelayOut) {
    animationOutSnapshot = -animationTimeLine + animationDelayOut;
  }

  const nextAnimationState = {
    animation: {
      ...animation,
      animationDelay: `${animationInSnapshot}s, ${animationOutSnapshot}s`,
    },
  };

  return nextAnimationState;
};
