import fetchNuxeo, { queryParam, baseSearchUrl } from './fetchNuxeo';

const getFetchNuxeoUserWorkspaceParams = entity_attribute => {
  return {
    /* eslint-disable-next-line */
    [queryParam]: `SELECT * FROM Document WHERE ecm:primaryType = 'Workspace' AND ecm:name = '${entity_attribute}' AND ecm:isProxy = 0 AND ecm:isTrashed = 0`,
  };
};

export const fetchNuxeoUserWorkspaceErrorMessage = "fetchNuxeoUserWorkspace: can't fetch user workspace from nuxeo";

const fetchNuxeoUserWorkspace = async entity_attribute => {
  try {
    const path = baseSearchUrl;

    const documents = await fetchNuxeo({
      path,
      headers: { 'Content-type': 'application/json+nxrequest', 'X-NXproperties': 'dublincore' },
      params: getFetchNuxeoUserWorkspaceParams(entity_attribute),
      errorMessage: fetchNuxeoUserWorkspaceErrorMessage,
    })
      .then(res => res.json())
      .catch(err => console.error('fetchNuxeoUserWorkspace', err));

    if (documents.entries.length === 0) {
      return '';
    }
    return documents.entries[0].path;
  } catch (e) {
    console.error('fetchNuxeoUserWorkspace', e);
    throw new Error(fetchNuxeoUserWorkspaceErrorMessage);
  }
};

export default fetchNuxeoUserWorkspace;
