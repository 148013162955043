import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import elementIcons from '../reference/elementIcons';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { checkIfNoBanners } from '../shared-selectors/sharedSelectors';
import { getSelectedSlideDuration } from '../banner/bannerSelectors';
import { addElementRequested } from '../banner/bannerActionsCreators';
import MenuIconButton from './MenuIconButton';

const MenuElements = ({
  intl,
  duration,
  handleTextClick,
  handleImageClick,
  handleRectangleClick,
  handleButtonClick,
  noBanner,
}) => (
  <div>
    <MenuIconButton
      iconComponent={elementIcons.text}
      onClick={handleTextClick}
      tooltip={intl.formatMessage({ id: 'menu.buttons.text' })}
      label={intl.formatMessage({ id: 'menu.buttons.text.label' })}
      disabled={noBanner}
    />
    <MenuIconButton
      iconComponent={elementIcons.image}
      onClick={() => handleImageClick()}
      tooltip={intl.formatMessage({ id: 'menu.buttons.image' })}
      label={intl.formatMessage({ id: 'menu.buttons.image.label' })}
      disabled={noBanner}
    />
    <MenuIconButton
      iconComponent={elementIcons.rectangle}
      onClick={() => handleRectangleClick({ duration })}
      tooltip={intl.formatMessage({ id: 'menu.buttons.rectangle' })}
      label={intl.formatMessage({ id: 'menu.buttons.rectangle.label' })}
      disabled={noBanner}
    />
    <MenuIconButton
      iconComponent={elementIcons.button}
      onClick={() => handleButtonClick({ duration })}
      tooltip={intl.formatMessage({ id: 'menu.buttons.button' })}
      label={intl.formatMessage({ id: 'menu.buttons.button.label' })}
      disabled={noBanner}
    />
  </div>
);

const makeStateToProps = () => {
  const stateToProps = state => {
    const duration = makeSelectorInstance(getSelectedSlideDuration)(state);
    const noBanner = makeSelectorInstance(checkIfNoBanners)(state);
    return {
      duration,
      noBanner,
    };
  };
  return stateToProps;
};

const dispatchToProps = {
  handleTextClick: () => addElementRequested({ type: 'text' }),
  handleImageClick: () => addElementRequested({ type: 'new-image' }),
  handleRectangleClick: () => addElementRequested({ type: 'rectangle' }),
  handleButtonClick: () => addElementRequested({ type: 'button' }),
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  React.memo,
)(MenuElements);
