import { put, select, take, takeLatest, call } from 'redux-saga/effects';
import { isUserAuthenticated, needToSaveState } from '../shared-selectors/sharedSelectors';
import { BANNER_CLEAR_STATE } from '../banner/bannerActions';
import { isBannerSetIsReadOnly } from '../banners/bannersSelectors';
import { MESSAGE_ADD } from '../messages/messagesDucks';
import { exportBannersToZIP } from '../export/exportDucks';

// Actions
export const SAVE_BANNERS_DIALOG_OPEN = 'adbuilder/SAVE_BANNERS_DIALOG_OPEN';
export const SAVE_BANNERS_DIALOG_OPEN_REQUESTED = 'adbuilder/SAVE_BANNERS_DIALOG_OPEN_REQUESTED';
export const SAVE_BANNERS_DIALOG_CLOSE = 'adbuilder/SAVE_BANNERS_DIALOG_CLOSE';
export const SAVE_BANNERS_IS_DONE = 'adbuilder/SAVE_BANNERS_IS_DONE';
export const SAVE_BANNERS_NO_NEED = 'adbuilder/SAVE_BANNERS_NO_NEED';
export const SAVE_BANNERS_SAVE_REQUESTED = 'adbuilder/SAVE_BANNERS_SAVE_REQUESTED';
export const SAVE_BANNERS_SET_REMAINING_THUMBNAILS = 'adbuilder/SAVE_BANNERS_SET_REMAINING_THUMBNAILS';
export const SAVE_BANNERS_SET_TOTAL_THUMBNAILS = 'adbuilder/SAVE_BANNERS_SET_TOTAL_THUMBNAILS';
export const SAVE_BANNERS_SAVE_BANNERSET_TO_NUXEO_REQUESTED =
  'adbuilder/SAVE_BANNERS_SAVE_BANNERSET_TO_NUXEO_REQUESTED';
// Action creators
export const openSaveBannersDialog = ({ cancelable, message }) => ({
  type: SAVE_BANNERS_DIALOG_OPEN,
  payload: {
    cancelable,
    message,
  },
});

export const openSaveBannersDialogRequested = ({
  redirection = null,
  cancelable = true,
  message = 'banner.open.ask-before.call-to-action',
}) => ({
  type: SAVE_BANNERS_DIALOG_OPEN_REQUESTED,
  payload: {
    redirection,
    cancelable,
    message,
  },
});

export const closeSaveBannersDialog = openNextDialog => ({
  type: SAVE_BANNERS_DIALOG_CLOSE,
  payload: openNextDialog,
});

export const saveBannerIsDone = openNextDialog => ({
  type: SAVE_BANNERS_IS_DONE,
  payload: openNextDialog,
});

export const noNeedToSave = () => ({
  type: SAVE_BANNERS_NO_NEED,
});

export const saveBannerSetRequested = save => ({
  type: SAVE_BANNERS_SAVE_REQUESTED,
  payload: save,
});

export const setDefaultThumbnailsRemaining = remaining => ({
  type: SAVE_BANNERS_SET_REMAINING_THUMBNAILS,
  payload: remaining,
});

export const setDefaultThumbnailsTotal = remaining => ({
  type: SAVE_BANNERS_SET_TOTAL_THUMBNAILS,
  payload: remaining,
});

export const saveBannerSetToNuxeo = (userWorkspacePath = null) => ({
  type: SAVE_BANNERS_SAVE_BANNERSET_TO_NUXEO_REQUESTED,
  payload: userWorkspacePath,
});

// Selectors
export const isSavingBanners = ({ saveBanners }) => saveBanners.saving;
export const getSaveBannersDialogState = ({ saveBanners }) => saveBanners;
export const getDefaultThumbnailsToCreate = ({ saveBanners }) => saveBanners.defaultThumbnails;

// Sagas
export function* saveBannersDialogSaga({ payload: { redirection, cancelable, message } }) {
  const needToSave = yield select(needToSaveState);
  const authenticated = yield select(isUserAuthenticated);
  const readOnly = yield select(isBannerSetIsReadOnly);
  const toExport = !authenticated || readOnly;
  const toSaveInNuxeo = authenticated && !readOnly;
  if (!needToSave) {
    yield call(saveIsDoneAndRedirect, redirection);
    return;
  }

  yield put(openSaveBannersDialog({ cancelable, message }));
  const action = yield take([SAVE_BANNERS_SAVE_REQUESTED, SAVE_BANNERS_DIALOG_CLOSE]);
  switch (action.type) {
    case SAVE_BANNERS_SAVE_REQUESTED: {
      const save = action.payload;
      if (!save) break;
      if (toExport) yield put(exportBannersToZIP());
      if (toSaveInNuxeo) {
        yield put(saveBannerSetToNuxeo());
        const {
          payload: { level },
        } = yield take(MESSAGE_ADD);
        if (level !== 'info') {
          // error
          console.error('error while saving');
          yield put(saveBannerIsDone(false));
          return;
        }
      }
      break;
    }
    case SAVE_BANNERS_DIALOG_CLOSE:
    default: {
      yield put(saveBannerIsDone(false));
      return;
    }
  }
  yield call(saveIsDoneAndRedirect, redirection);
}

function* saveIsDoneAndRedirect(redirection) {
  // redirect or open the dialog
  if (redirection) {
    yield put(saveBannerIsDone(false));
    window.location.href = redirection;
  } else {
    yield put(saveBannerIsDone(true));
  }
}

export function* saga() {
  yield takeLatest(SAVE_BANNERS_DIALOG_OPEN_REQUESTED, saveBannersDialogSaga);
}

// Reducer
export const initialState = {
  dialogOpen: false,
  dialogKey: 0,
  cancelable: false,
  message: '',
  saving: false,
  defaultThumbnails: {
    total: 0,
    remaining: 0,
    done: 0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_BANNERS_DIALOG_OPEN: {
      return {
        ...state,
        dialogOpen: true,
        dialogKey: state.dialogKey + 1,
        ...action.payload,
      };
    }
    case SAVE_BANNERS_IS_DONE:
    case SAVE_BANNERS_DIALOG_CLOSE: {
      return {
        ...initialState,
        dialogOpen: false,
        dialogKey: state.dialogKey + 1,
        defaultThumbnails: {
          total: 0,
          remaining: 0,
          done: 0,
        },
      };
    }
    case SAVE_BANNERS_NO_NEED: {
      return {
        ...state,
      };
    }
    case SAVE_BANNERS_SET_REMAINING_THUMBNAILS: {
      return {
        ...state,
        defaultThumbnails: {
          ...state.defaultThumbnails,
          remaining: action.payload,
          done: state.defaultThumbnails.total - action.payload,
        },
      };
    }
    case SAVE_BANNERS_SET_TOTAL_THUMBNAILS: {
      return {
        ...state,
        defaultThumbnails: {
          total: action.payload,
          remaining: action.payload,
          done: 0,
        },
      };
    }
    case SAVE_BANNERS_SAVE_BANNERSET_TO_NUXEO_REQUESTED: {
      return {
        ...state,
        saving: true,
      };
    }
    case MESSAGE_ADD: {
      if (action.payload && action.payload.message === 'nuxeo.bannerSet.success-saving') {
        return {
          ...state,
          dialogOpen: false,
          dialogKey: state.dialogKey + 1,
          saving: false,
          defaultThumbnails: {
            total: 0,
            remaining: 0,
            done: 0,
          },
        };
      }
      return {
        ...state,
        dialogOpen: false,
        dialogKey: state.dialogKey + 1,
        saving: false,
        defaultThumbnails: {
          total: 0,
          remaining: 0,
          done: 0,
        },
      };
    }
    case BANNER_CLEAR_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
