import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tabs, Tab } from '@material-ui/core';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import SwipeableViews from 'react-swipeable-views';

import { getSelectedElementType } from '../banner/bannerSelectors';
import { getCustomizationIsEnabled } from '../entities/entitiesSelectors';
import { styles } from './propertyEditorStyles';
import CustomFieldsEditorElement from './CustomFieldsEditorElement';
import { canCustom } from './helpers';
import PropertyEditorElement from './PropertyEditorElement';
import intlPropType from '../prop-types/intl';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { compose } from 'recompose';

const rootStyle = { height: '100%', overflow: 'hidden' };

const useStyles = makeStyles(styles);

const PropertyEditorOrCustomFieldsElement = memo(({ intl, selectedElementType, isCustomizationEnabled }) => {
  /* Vars */

  const [propertiesOrCustomFields, setPropertiesOrCustomFields] = useState(0);
  const classes = useStyles();

  const handlePropertiesOrCustomFieldsChange = useCallback(
    (evt, value) => {
      setPropertiesOrCustomFields(value);
    },
    [setPropertiesOrCustomFields],
  );

  /* Render */

  return canCustom(selectedElementType) && isCustomizationEnabled ? (
    <>
      <Tabs
        value={propertiesOrCustomFields}
        onChange={handlePropertiesOrCustomFieldsChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        classes={{ root: classnames(classes.tabsRoot, classes.tabsRootHigh) }}
      >
        <Tab
          label={intl.formatMessage({ id: 'properties' })}
          classes={{
            root: classnames(classes.transitionTab, classes.transitionTabHigh),
            selected: classes.tabSelected,
          }}
        />
        <Tab
          label={intl.formatMessage({ id: 'customization.view.title' })}
          classes={{
            root: classnames(classes.transitionTab, classes.transitionTabHigh),
            selected: classes.tabSelected,
          }}
        />
      </Tabs>
      <SwipeableViews
        index={propertiesOrCustomFields}
        onChangeIndex={setPropertiesOrCustomFields}
        style={rootStyle}
        slideStyle={rootStyle}
      >
        <PropertyEditorElement />
        <CustomFieldsEditorElement />
      </SwipeableViews>
    </>
  ) : (
    <PropertyEditorElement />
  );
});

PropertyEditorOrCustomFieldsElement.propTypes = {
  intl: intlPropType.isRequired,
  selectedElementType: PropTypes.string,
  isCustomizationEnabled: PropTypes.bool.isRequired,
};

PropertyEditorOrCustomFieldsElement.defaultProps = {
  selectedElementType: undefined,
};

const makeStateToProps = () => state => ({
  selectedElementType: makeSelectorInstance(getSelectedElementType)(state),
  isCustomizationEnabled: makeSelectorInstance(getCustomizationIsEnabled)(state),
});

export default compose(connect(makeStateToProps, {}), injectIntl)(PropertyEditorOrCustomFieldsElement);
