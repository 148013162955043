export const elementsPropertyAverage = (elements, prop, round = true) => {
  const average = elements.map(el => el[prop]).reduce((propEl1, propEl2) => propEl1 + propEl2) / elements.size;
  if (round) {
    return [Math.ceil(average)];
  } else {
    return [average];
  }
};

export const elementsDeepPropertyAverage = (elements, prop, subProp, round = true) => {
  const average = elements.map(el => el[prop][subProp]).reduce((propEl1, propEl2) => propEl1 + propEl2) / elements.size;
  if (round) {
    return [Math.ceil(average)];
  } else {
    return [average];
  }
};

export const allElementsHasPropertyChecked = (elements, prop) => elements.filter(el => el[prop]).size === elements.size;
export const someElementsHasPropertyChecked = (elements, prop) => elements.filter(el => el[prop]).size > 0;
export const allElementsHasDeepPropertyChecked = (elements, prop, subProp) =>
  elements.filter(el => el[prop][subProp]).size === elements.size;
export const allElementsHasDeepPropertyUnchecked = (elements, prop, subProp) =>
  elements.filter(el => el[prop][subProp]).size === 0;

export const allElementsHasSameDeepProperty = (elements, prop, subProp) =>
  elements
    .toList()
    .filter((el, ind) => ind === 0 || el[prop][subProp] === elements.toList().get(ind - 1)[prop][subProp]).size ===
  elements.size
    ? elements.toList().get(0)[prop][subProp]
    : false;

export const allElementsHasSameProperty = (elements, prop) =>
  elements.toList().filter((el, ind) => ind === 0 || el[prop] === elements.toList().get(ind - 1)[prop]).size ===
  elements.size
    ? elements.toList().get(0)[prop]
    : false;

export const allElementsHasNotThisDeepProperty = (elements, prop, subProp, value) =>
  elements.filter(el => el[prop][subProp] === value).size === 0;
