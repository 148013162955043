// Actions
export const MASTER_BANNER_DIALOG_OPEN = 'adbuilder/MASTER_BANNER_DIALOG_OPEN';
export const MASTER_BANNER_DIALOG_CANCEL = 'adbuilder/MASTER_BANNER_DIALOG_CANCEL';
export const MASTER_BANNER_DIALOG_CONFIRM_STOP = 'adbuilder/MASTER_BANNER_DIALOG_CONFIRM_STOP';
export const MASTER_BANNER_DIALOG_CONFIRM_DISSOCIATE = 'adbuilder/MASTER_BANNER_DIALOG_CONFIRM_DISSOCIATE';

// Actions creators
export const openMasterBannerDialog = (todo = 'stop') => ({
  // or 'dissociate' or 'crop'
  type: MASTER_BANNER_DIALOG_OPEN,
  payload: todo,
});

export const stopMasterBannerConfirmStop = () => ({
  type: MASTER_BANNER_DIALOG_CONFIRM_STOP,
});

export const stopMasterBannerConfirmDissociate = () => ({
  type: MASTER_BANNER_DIALOG_CONFIRM_DISSOCIATE,
});

export const stopMasterBannerCancel = () => ({
  type: MASTER_BANNER_DIALOG_CANCEL,
});

// Selectors
export const getMasterBannerDialogOpen = ({ masterBannerDialog: { open } }) => open; // TESTME
export const getMasterBannerDialogKey = ({ masterBannerDialog: { key } }) => key; // TESTME
export const getMasterBannerDialogTodo = ({ masterBannerDialog: { todo } }) => todo; // TESTME

// Sagas

// Reducer

export const initialState = {
  open: false,
  key: 0,
  todo: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MASTER_BANNER_DIALOG_OPEN: {
      return {
        ...state,
        open: true,
        key: state.key + 1,
        todo: action.payload,
      };
    }
    case MASTER_BANNER_DIALOG_CONFIRM_STOP:
    case MASTER_BANNER_DIALOG_CONFIRM_DISSOCIATE:
    case MASTER_BANNER_DIALOG_CANCEL: {
      return {
        ...state,
        open: false,
        key: state.key + 1,
        todo: '',
      };
    }
    default:
      return state;
  }
};

export default reducer;
