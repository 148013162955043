import {
  resourcesFolderName,
  bannerThumbnailName,
  bannerSetThumbnailName,
  originalResourcesFolderName,
} from '../../reference/filesNames';
import { pngType, jpegType } from '../../reference/importParams'
import { Map } from 'immutable';
import { killCache } from './killCache';
import { unzipImages } from './unzipImages';

export const importResourcesFromNuxeo = async (
  attachments,
  banners,
  bannerSetInfos,
  unzippedPlayers,
  originalImagesUnzipped,
  token,
) => {
  try {
    let resources = [];
    // import thumbnails
    const thumbnailsUrls = attachments
      .filter(({file}) => (file['mime-type'] === pngType || file['mime-type'] === jpegType))
      .map(({file: {data: url, name, 'mime-type': type}}) => ({url, name, type}));

    for (const thumb of thumbnailsUrls) {
      const blob = await fetch(killCache(thumb.url), {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
        credentials: 'omit', // trying to fix the AD-358 bug
      })
        .then(res => res.blob())
        .catch(err => console.error('tryin to fetch resources from nuxeo', err));
      let type = thumb.type === jpegType ? 'jpeg' : 'png'
      let id =
        thumb.name === bannerSetThumbnailName(type)
          ? bannerSetInfos.thumbnailResourceId
          : banners
            .filter(banner => bannerThumbnailName(banner.format, type) === thumb.name)
              .map(({ thumbnailResourceId }) => thumbnailResourceId)[0];
      resources.push({ blob, id });
    }

    // import other resources
    for (const unzippedPlayer of unzippedPlayers) {
      const bannerImages = await unzipImages(unzippedPlayer, resourcesFolderName);
      resources.push(...bannerImages);
    }

    // import original images resources
    let originalImages = [];
    if (originalImagesUnzipped) {
      originalImages = await unzipImages(originalImagesUnzipped, originalResourcesFolderName);
    }
    resources.push(...originalImages);

    const resourcesObject = {};
    for (const resource of resources) {
      resourcesObject[resource.id] = resource;
    }

    const resourceMap = Map(resourcesObject)
      .valueSeq()
      .toArray();
    return resourceMap;
  } catch (e) {
    console.error('cant import resources from nuxeo', e);
  }
};
