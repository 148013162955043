import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { injectIntl } from 'react-intl';
import PlusCircle from 'mdi-material-ui/PlusCircle';
import MinusCircle from 'mdi-material-ui/MinusCircle';

import { menuButtonWidth, menuButtonHeight } from '../reference/dimensions';
import elementIcons from '../reference/elementIcons';
import { zoomStep } from '../reference/zoom';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { menuButtonBgColor } from '../reference/colors';
import { checkIfNoBanners } from '../shared-selectors/sharedSelectors';
import { getBannerZoom } from '../banner/bannerSelectors';
import { setBannerZoom } from '../banner/bannerActionsCreators';
import MenuIconButton from './MenuIconButton';

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    '& *': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  zoomValue: {
    width: menuButtonWidth,
    height: menuButtonHeight,
    fontSize: 11,
    background: menuButtonBgColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default',
  },
  shadow: {
    height: menuButtonHeight,
    width: '100%',
    boxShadow: theme.shadows[1],
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 10,
    pointerEvents: 'none',
  },
});

const MenuZoom = ({ classes, intl, zoom, noBanner, setBannerZoom }) => (
  <div className={classes.root}>
    <MenuIconButton
      iconComponent={<MinusCircle />}
      onClick={() => setBannerZoom(zoom - zoomStep)}
      tooltip={intl.formatMessage({ id: 'menu.buttons.zoomOut' })}
      label={intl.formatMessage({ id: 'menu.buttons.zoomOut' })}
      disabled={noBanner}
      noShadow
    />
    <div className={classes.shadow} />
    {!noBanner && (
      <Typography id="zoom" className={classes.zoomValue}>
        {Math.round(zoom * 100)}%
      </Typography>
    )}
    <MenuIconButton
      iconComponent={<PlusCircle />}
      onClick={() => setBannerZoom(zoom + zoomStep)}
      tooltip={intl.formatMessage({ id: 'menu.buttons.zoomIn' })}
      label={intl.formatMessage({ id: 'menu.buttons.zoomIn' })}
      disabled={noBanner}
      noShadow
    />
    <MenuIconButton
      iconComponent={elementIcons.resetZoom}
      onClick={() => setBannerZoom(1)}
      tooltip={intl.formatMessage({ id: 'menu.buttons.resetZoom' })}
      label={intl.formatMessage({ id: 'menu.buttons.resetZoom' })}
      disabled={noBanner}
      noShadow
    />
  </div>
);

const makeStateToProps = () => {
  const stateToProps = state => {
    const zoom = makeSelectorInstance(getBannerZoom)(state);
    const noBanner = makeSelectorInstance(checkIfNoBanners)(state);
    return {
      zoom,
      noBanner,
    };
  };
  return stateToProps;
};

const dispatchToProps = {
  setBannerZoom,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
  React.memo,
)(MenuZoom);
