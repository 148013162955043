import { corners } from '../../../reference/corners';

export const getFixedCornerWhileResizing = ({ handleClicked, lockAspectRatio }) => {
  /*store which corner won't move*/
  switch (handleClicked) {
    case corners.topLeft: {
      return corners.bottomRight;
    }
    case corners.topRight: {
      return corners.bottomLeft;
    }
    case corners.bottomLeft: {
      return corners.topRight;
    }
    case corners.bottomRight: {
      return corners.topLeft;
    }
    case corners.middleTop: {
      /*when it's a "middle corner" moving, three corners will stay fixed. we choose one convenient*/
      if (lockAspectRatio) {
        return corners.middleBottom;
      } else {
        return corners.bottomRight;
      }
    }
    case corners.middleRight: {
      if (lockAspectRatio) {
        return corners.middleLeft;
      } else {
        return corners.topLeft;
      }
    }
    case corners.middleBottom: {
      if (lockAspectRatio) {
        return corners.middleTop;
      } else {
        return corners.topLeft;
      }
    }
    case corners.middleLeft: {
      if (lockAspectRatio) {
        return corners.middleRight;
      } else {
        return corners.bottomRight;
      }
    }
    default: {
      return null;
    }
  }
};
