export const customTextKeyParam = 'textKeys';
export const openBannersetParam = 'openBannerSet';
export const bannerSetIdParam = 'bannerSetUid';
export const redirectUrlParam = 'redirectUrl';

export const getValueFromUrlParameter = (parameter, defaultValue = null) => {
  if (window.location.href.split('?').length < 2) return defaultValue;
  const paramValue = window.location.href
    .split('?')[1]
    .split('&')
    .map(param => ({
      [param.split('=')[0]]: param.split('=')[1],
    }))
    .filter(param => Object.keys(param).includes(parameter));

  if (paramValue.length === 0) return defaultValue;
  return decodeURIComponent(paramValue[0][parameter]);
};

export const removeParameterFromUrl = parameter => {
  if (window.location.href.split('?').length < 2) return;
  const newSearch = window.location.href
    .split('?')[1]
    .split('&')
    .filter(param => param.split('=')[0] !== parameter)
    .join('&');

  window.history.pushState({}, `Parameter ${parameter} deleted`, `/?${newSearch}`);
};
