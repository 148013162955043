import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { List } from 'immutable';

import morphableStyles, { handleSize } from './morphableStyles';
import { corners } from '../reference/corners';
import cursors from '../reference/cursors';
import keyboardShortcuts from '../reference/keyboardShortcuts';
import magnetismGrids from '../reference/magnetismGrids';
import {
  getGuidesToShowForDrag,
  getGuidesToShowForRotation,
  getGuidesToShowForResize,
  checkIfCornerIsMiddleCorner,
  checkInsideTheBounds,
  calculateRootTransformedBoundingRect,
  elementResizeGlobalMethod,
  calcInitRotation,
  calcRot,
} from './interactions-helpers';

const debug = false;

class Morphable extends React.Component {
  state = {
    stylesState: {},
    handleDragFor: 'resize',
    lockAspectRatioDiagonal: undefined,
    disableGuides: this.props.disableGuides,
    cursorPositionChecked: undefined, //for debug only
    rootBoundingRect: undefined, //for debug only
    goingOutsideTheBounds: undefined, //for debug only
    minCursorPosition: undefined, //for debug only
    lockAspectRatioDiagonalPerpendicular: undefined, //for debug only
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      classes,
      type,
      selected,
      multipleSelected,
      status,
      animation,
      blinkAnimation,
      position: { x, y },
      rotation,
      zoom,
      size: { width, height },
      transformOrigin,
      cornersSelectable,
      slideIsPlayingAnimation,
    } = nextProps;

    const { handleDragFor } = prevState;

    const showAsSelected =
      (selected || multipleSelected) && !(status.elementIsPlayingAnimation || slideIsPlayingAnimation);
    const showAsBeingCropped =
      selected &&
      !multipleSelected &&
      status.isCropping &&
      !(status.elementIsPlayingAnimation || slideIsPlayingAnimation);
    const showCorner = showAsSelected && cornersSelectable;
    const isText = type === 'text';
    return {
      ...prevState,
      stylesState: {
        rootStyle: {
          left: x,
          top: y,
          width,
          height: isText ? 'auto' : height,
          ...animation,
        },
        rootTransformedStyle: {
          transform: `rotate(${rotation}deg)`,
          transformOrigin:
            transformOrigin.x && transformOrigin.y ? `${transformOrigin.x}px ${transformOrigin.y}px` : transformOrigin,
          ...blinkAnimation,
          cursor: status.isRotating && cursors.middleTop({ rotation, handleDragFor: 'rotation' }),
        },
        topLeft: {
          cursor: cursors.topLeft({ rotation, handleDragFor }),
          transform: `scale(${1 / zoom})`,
          display: 'block',
          visibility: showCorner && !isText ? 'visible' : 'hidden',
          pointerEvents: showCorner && !isText ? 'auto' : 'none',
        },
        middleRight: {
          cursor: cursors.middleRight({ rotation, handleDragFor }),
          transform: `scale(${1 / zoom})`,
        },
        middleLeft: {
          cursor: cursors.middleLeft({ rotation, handleDragFor }),
          transform: `scale(${1 / zoom})`,
        },
        topRight: {
          cursor: cursors.topRight({ rotation, handleDragFor }),
          transform: `scale(${1 / zoom})`,
        },
        bottomLeft: {
          cursor: cursors.bottomLeft({ rotation, handleDragFor }),
          transform: `scale(${1 / zoom})`,
        },
        bottomRight: {
          cursor: cursors.bottomRight({ rotation, handleDragFor }),
          transform: `scale(${1 / zoom})`,
        },
        middleTop: {
          cursor: cursors.middleTop({ rotation, handleDragFor }),
          transform: `scale(${1 / zoom})`,
        },
        middleBottom: {
          cursor: cursors.middleBottom({ rotation, handleDragFor }),
          transform: `scale(${1 / zoom})`,
        },
        middleTopAbove: {
          cursor: cursors.middleTop({ rotation, handleDragFor: 'rotation' }),
          transform: `scale(${1 / zoom})`,
          top: (isText ? -18 : -20) / zoom,
        },
      },
      classNamesState: {
        root: classnames(classes.root, 'element'),
        rootTransformed: classnames(classes.rootTransformed, {
          [classes.active]: showAsSelected,
          [classes.isCropping]: showAsBeingCropped,
          [classes.addPadding]: isText,
        }),
        topLeft: classnames(classes.corner, classes.topLeft, { [classes.hideCorner]: isText }),
        middleRight: classnames(classes.corner, classes.middleRight),
        middleLeft: classnames(classes.corner, classes.middleLeft),
        topRight: classnames(classes.corner, classes.topRight),
        bottomLeft: classnames(classes.corner, classes.bottomLeft),
        bottomRight: classnames(classes.corner, classes.bottomRight),
        middleTop: classnames(classes.corner, classes.middleTop),
        middleBottom: classnames(classes.corner, classes.middleBottom),
        middleTopAbove: classnames(classes.corner, classes.middleTopAbove),
        lockAspectRatioDiagonal: classnames(classes.lockAspectRatioDiagonal),
      },
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleShortcuts);
    window.addEventListener('keyup', this.handleShortcuts);
    if (this.props.type === 'image') {
      this.topLeftCorner && this.props.saveTopLeftCorner(this.topLeftCorner, 'componentDidMount');
    }
  }

  componentWillUnmount() {
    this.props.saveTopLeftCorner(this.topLeftCorner, 'componentWillUnmount');
    document.removeEventListener('mousemove', this.onDrag);
    document.removeEventListener('mouseup', this.onDragStop);
    document.removeEventListener('mousemove', this.onResize);
    document.removeEventListener('mouseup', this.onResizeStop);
    if (!this.props.disableRotation) {
      document.removeEventListener('mousemove', this.onRotate);
      document.removeEventListener('mouseup', this.onRotateEnd);
    }
    window.removeEventListener('keydown', this.handleShortcuts);
    window.removeEventListener('keyup', this.handleShortcuts);
  }

  /*------------------------------------*/
  /*TOGGLE RESIZE OR ROTATE METHODS*/
  /*------------------------------------*/

  handleShortcuts = e => {
    const {
      selected,
      multipleSelected,
      type,
      setElementLockAspectRatio,
      lockAspectRatio,
      id,
      disableShortcuts,
      status: { textIsSelected },
    } = this.props;
    if ((!selected && !multipleSelected) || disableShortcuts) {
      return;
    }
    /* move */
    const {
      position: { x, y },
      moveWithArrows,
    } = this.props;
    if (keyboardShortcuts.moveLeft.includes(e.key) && !textIsSelected) {
      e.preventDefault();
      e.stopPropagation();
      e.type === 'keydown' && moveWithArrows({ x: x - 1, y });
      e.type === 'keyup' && moveWithArrows({ saveNewPosition: true });
    }
    if (keyboardShortcuts.moveRight.includes(e.key) && !textIsSelected) {
      e.preventDefault();
      e.type === 'keydown' && moveWithArrows({ x: x + 1, y });
      e.type === 'keyup' && moveWithArrows({ saveNewPosition: true });
    }
    if (keyboardShortcuts.moveUp.includes(e.key) && !textIsSelected) {
      e.preventDefault();
      e.type === 'keydown' && moveWithArrows({ x, y: y - 1 });
      e.type === 'keyup' && moveWithArrows({ saveNewPosition: true });
    }
    if (keyboardShortcuts.moveDown.includes(e.key) && !textIsSelected) {
      e.preventDefault();
      e.type === 'keydown' && moveWithArrows({ x, y: y + 1 });
      e.type === 'keyup' && moveWithArrows({ saveNewPosition: true });
    }

    /* the rest of the actions can only exist if the element is not part of a multiple selection*/
    if (!multipleSelected) {
      return;
    }

    /* toggle rotate or resize */
    if (keyboardShortcuts.toggleRotateResize.includes(e.key)) {
      if (this.props.disableRotation) {
        return;
      } else if (e.type === 'keydown') {
        this.setState({ handleDragFor: 'rotate' });
      } else {
        this.state.handleDragFor !== 'resize' && this.setState({ handleDragFor: 'resize' });
      }
    }
    /* toggle guides */
    if (keyboardShortcuts.disableGuides.includes(e.key)) {
      if (e.type === 'keydown') {
        !this.state.disableGuides && this.setState({ disableGuides: true });
      } else {
        this.setState({ disableGuides: this.props.disableGuides }); //for cropping element, guides are always disabled
      }
    }
    /* toggle lock aspect ratio */
    if (type === 'image' && keyboardShortcuts.lockAspectRatio.includes(e.key)) {
      if (e.type === 'keydown') {
        if (!this.state.lockAspectRatioTemporary) {
          this.setState({ lockAspectRatioTemporary: true });
          setElementLockAspectRatio &&
            setElementLockAspectRatio({ id, lockAspectRatio: !lockAspectRatio, untrack: true });
          const {
            size: { width, height },
          } = this.props;
          this.constantAngleLockAspectRatio = Math.atan(height / width);
        } else {
          return;
        }
      } else {
        this.setState({ lockAspectRatioTemporary: false });
        setElementLockAspectRatio &&
          setElementLockAspectRatio({ id, lockAspectRatio: !lockAspectRatio, untrack: true });
        const {
          size: { width, height },
        } = this.props;
        this.constantAngleLockAspectRatio = Math.atan(height / width);
      }
    }
    /* toggle lock aspect ratio with original size*/
    if (type === 'image' && keyboardShortcuts.lockAspectRatioOriginalSize.includes(e.key)) {
      if (e.type === 'keydown') {
        if (!this.state.lockAspectRatioTemporary) {
          this.setState({ lockAspectRatioTemporary: true });
          setElementLockAspectRatio && setElementLockAspectRatio({ id, lockAspectRatio: true, untrack: true });
          const {
            originalSize: { width, height },
          } = this.props;
          this.constantAngleLockAspectRatio = Math.atan(height / width);
        } else {
          return;
        }
      } else {
        this.setState({ lockAspectRatioTemporary: false });
        const {
          originalSize: { width, height },
        } = this.props;
        this.constantAngleLockAspectRatio = Math.atan(height / width);
      }
    }
  };

  /*------------------------------------*/
  /*START MORPHISM*/
  /*------------------------------------*/

  onHandleMouseDown = e => {
    !e.target.dataset.text && e.preventDefault();
    /*
     LAUNCHING THE PROPER INTERACTION HANDLER
    */
    const { selected, multipleSelected } = this.props;
    e.persist();
    if (selected && !multipleSelected && !e.shiftKey && !e.metaKey && !e.ctrlKey && !e.altKey) {
      if (e.target.dataset.corner === corners.inside) {
        this.onDragStart(e);
      } else if (e.target.dataset.corner !== undefined) {
        e.stopPropagation();
        this.state.handleDragFor === 'resize' && this.onResizeStart(e);
        this.state.handleDragFor === 'rotate' && this.onRotateStart(e);
      }
    }
    this.props.onHandleMouseDown(e);
  };

  /*------------------------------------*/
  /*DRAG METHODS*/
  /*------------------------------------*/

  onDragStart = e => {
    debug && console.log('onDragStart');
    e.persist();
    e.stopPropagation();
    /*
     GETTING SOME INITIAL DATA TO SHOW / HIDE GUIDES
    */
    const {
      size: { width, height },
      position: { x, y },
      rotation,
      onDragStart,
      zoom,
    } = this.props;

    this.dragPointerOffsetToCenter = {
      X:
        e.clientX -
        (this.elementRoot.getBoundingClientRect().left + (width * zoom) / 2) /*on the drag handle: negative*/,
      Y:
        e.clientY -
        (this.elementRoot.getBoundingClientRect().top + (height * zoom) / 2) /*on the drag handle: negative*/,
    };

    this.dragPointerOffsetToTopLeft = {
      X: e.clientX - this.elementRoot.getBoundingClientRect().left /*positive*/,
      Y: e.clientY - this.elementRoot.getBoundingClientRect().top /*positive*/,
    };

    this.dragPointerOffsetToBottomRight = {
      X: e.clientX - this.elementRoot.getBoundingClientRect().right /*negative*/,
      Y: e.clientY - this.elementRoot.getBoundingClientRect().bottom /*negative*/,
    };

    this.parentTopLeftOrigin = {
      X: this.parentRef.getBoundingClientRect().left,
      Y: this.parentRef.getBoundingClientRect().top,
    };

    this.rootTransformedBoundingRect = calculateRootTransformedBoundingRect({
      x: x * zoom,
      y: y * zoom,
      width: width,
      height: height,
      ALPHA: -(rotation * Math.PI) / 180, //css is clockwise, trigo is anti-clockwise
      centerCoords: {
        X: x * zoom + width / 2,
        Y: y * zoom + height / 2,
      },
      zoom,
    });

    this.rootTransformedBoundingsOffset = {
      top: (this.rootTransformedBoundingRect.top - y) * zoom,
      left: (this.rootTransformedBoundingRect.left - x) * zoom,
      bottom: (this.rootTransformedBoundingRect.bottom - y - height / zoom) * zoom,
      right: (this.rootTransformedBoundingRect.right - x - width / zoom) * zoom,
    };

    /*tell the parent we start the drag*/
    onDragStart();

    document.addEventListener('mousemove', this.onDrag);
    document.addEventListener('mouseup', this.onDragStop);
  };

  onDrag = e => {
    debug && console.log('onDrag');
    e.stopPropagation();
    e.preventDefault();

    const {
      selected,
      guides,
      size: { width, height },
      rotation,
      borderWidth,
      bounds,
      // position: { x, y },
      onDrag,
      magnetismGrids,
      disableBounds,
      zoom,
    } = this.props;

    const { disableGuides } = this.state;

    /*prevent dragging by mistake*/
    if (!selected) {
      return;
    }

    /*get theoretical coordoninates*/
    const theoreticalCenterCoords = {
      x: e.clientX - this.dragPointerOffsetToCenter.X - this.parentTopLeftOrigin.X - (borderWidth ? borderWidth : 0),
      y: e.clientY - this.dragPointerOffsetToCenter.Y - this.parentTopLeftOrigin.Y - (borderWidth ? borderWidth : 0),
    };

    const theoreticalTopLeftCoords = {
      x: e.clientX - this.dragPointerOffsetToTopLeft.X - this.parentTopLeftOrigin.X - (borderWidth ? borderWidth : 0),
      y: e.clientY - this.dragPointerOffsetToTopLeft.Y - this.parentTopLeftOrigin.Y - (borderWidth ? borderWidth : 0),
    };

    const theoreticalBottomRightCoords = {
      x:
        e.clientX -
        this.dragPointerOffsetToBottomRight.X -
        this.parentTopLeftOrigin.X -
        (borderWidth ? borderWidth : 0),
      y:
        e.clientY -
        this.dragPointerOffsetToBottomRight.Y -
        this.parentTopLeftOrigin.Y -
        (borderWidth ? borderWidth : 0),
    };

    /*get guides to show and real new coordinates*/
    const { guidesToShow, newX, newY } = getGuidesToShowForDrag({
      guides,
      theoreticalCenterCoords,
      theoreticalTopLeftCoords,
      theoreticalBottomRightCoords,
      width,
      height,
      rotation,
      magnetismGrid: magnetismGrids.drag,
      disableGuides,
      zoom,
    });

    /*check if new coordinates are outside the bounds*/
    const { rootTransformedBoundingRect, xInsideBounds, yInsideBounds } = checkInsideTheBounds({
      bounds,
      disableBounds,
      x: newX,
      y: newY,
      width: width * zoom,
      height: height * zoom,
      centerCoords: {
        X: newX + (width * zoom) / 2,
        Y: newY + (height * zoom) / 2,
      },
      zoom,
      ALPHA: -(rotation * Math.PI) / 180, //css is clockwise, trigo is anti-clockwise
      rootTransformedBoundingsOffset: this.rootTransformedBoundingsOffset,
    });

    debug && this.setState({ rootTransformedBoundingRect });

    /*update the component*/
    onDrag({
      x: xInsideBounds,
      y: yInsideBounds,
      guidesToShow,
    });
  };

  onDragStop = e => {
    debug && console.log('onDragStop');

    e.preventDefault();
    e.stopPropagation();
    document.removeEventListener('mousemove', this.onDrag);
    document.removeEventListener('mouseup', this.onDragStop);

    const { onDragStop, saveTopLeftCorner } = this.props;

    onDragStop();
    saveTopLeftCorner(this.topLeftCorner, 'onDragStop');

    return false;
  };

  /*------------------------------------*/
  /*RESIZE METHODS*/
  /*------------------------------------*/

  onResizeStart = e => {
    debug && console.log('onResizeStart');
    e.stopPropagation();

    const {
      size: { width, height },
      onResizeStart,
    } = this.props;

    /*store which corner has been triggered*/
    this.movingCorner = e.target.dataset.corner;
    this.constantAngleLockAspectRatio = Math.atan(height / width);
    if (checkIfCornerIsMiddleCorner({ movingCorner: this.movingCorner })) {
      if (this.movingCorner === corners.middleTop) {
        this.immobileCorner = {
          top:
            this.middleBottomCorner.getBoundingClientRect().top +
            handleSize / 2 -
            this.parentRef.getBoundingClientRect().top,
          left:
            this.middleBottomCorner.getBoundingClientRect().left +
            handleSize / 2 -
            this.parentRef.getBoundingClientRect().left,
        };
      } else if (this.movingCorner === corners.middleBottom) {
        this.immobileCorner = {
          top:
            this.middleTopCorner.getBoundingClientRect().top +
            handleSize / 2 -
            this.parentRef.getBoundingClientRect().top,
          left:
            this.middleTopCorner.getBoundingClientRect().left +
            handleSize / 2 -
            this.parentRef.getBoundingClientRect().left,
        };
      } else if (this.movingCorner === corners.middleLeft) {
        this.immobileCorner = {
          top:
            this.middleRightCorner.getBoundingClientRect().top +
            handleSize / 2 -
            this.parentRef.getBoundingClientRect().top,
          left:
            this.middleRightCorner.getBoundingClientRect().left +
            handleSize / 2 -
            this.parentRef.getBoundingClientRect().left,
        };
      } else {
        this.immobileCorner = {
          top:
            this.middleLeftCorner.getBoundingClientRect().top +
            handleSize / 2 -
            this.parentRef.getBoundingClientRect().top,
          left:
            this.middleLeftCorner.getBoundingClientRect().left +
            handleSize / 2 -
            this.parentRef.getBoundingClientRect().left,
        };
      }
    }

    /*tell the parent component we start the resize*/
    onResizeStart();

    document.addEventListener('mousemove', this.onResize);
    document.addEventListener('mouseup', this.onResizeStop);
  };

  onResize = e => {
    e.stopPropagation();

    const {
      position: { x, y },
      size: { width, height },
      lockAspectRatio,
      rotation,
      bounds,
      minHeight,
      onResize,
      magnetismGrids,
      disableGuides,
      guides,
      zoom,
    } = this.props;

    const cursorPosition = {
      x: e.clientX - this.parentRef.getBoundingClientRect().x,
      y: e.clientY - this.parentRef.getBoundingClientRect().y,
    };

    const newParams = elementResizeGlobalMethod({
      cursorPosition,
      movingCorner: this.movingCorner,
      width: width * zoom,
      height: height * zoom,
      x: x * zoom,
      y: y * zoom,
      rotation,
      lockAspectRatio,
      phi: this.constantAngleLockAspectRatio,
      minHeight: Math.max(minHeight, 1) * zoom,
      bounds,
    });

    /*get guides to show and real new coordinates*/
    let { guidesToShow, newX, newY, newWidth, newHeight } = getGuidesToShowForResize({
      guides,
      newParams,
      rotation,
      magnetismGrid: magnetismGrids.resize,
      disableGuides,
      movingCorner: this.movingCorner,
      immobileCorner: this.immobileCorner,
      zoom,
      lockAspectRatio,
    });

    onResize({
      height: newHeight,
      width: newWidth,
      x: newX,
      y: newY,
      guidesToShow,
    });

    let lockAspectRatioDiagonal;
    if (newParams.lockAspectRatioDiagonal !== null) {
      lockAspectRatioDiagonal = {
        ...newParams.lockAspectRatioDiagonal,
        top: newParams.lockAspectRatioDiagonal.top / zoom,
        left: newParams.lockAspectRatioDiagonal.left / zoom,
        width: newParams.lockAspectRatioDiagonal.width / zoom,
        height: newParams.lockAspectRatioDiagonal.height / zoom,
      };
    }

    this.setState({
      lockAspectRatioDiagonal,
      cursorPositionChecked: newParams.cursorPositionChecked, //for debug only
      rootBoundingRect: newParams.rootBoundingRect, //for debug only
      minCursorPosition: newParams.minCursorPosition, //for debug only
      lockAspectRatioDiagonalPerpendicular: newParams.lockAspectRatioDiagonalPerpendicular, //for debug only
    });
  };

  onResizeStop = e => {
    debug && console.log('onResizeStop');
    e && e.stopPropagation();

    this.props.onResizeStop();
    this.props.saveTopLeftCorner(this.topLeftCorner, 'onResizeStop');
    this.setState({
      lockAspectRatioDiagonal: undefined,
    });

    document.removeEventListener('mousemove', this.onResize);
    document.removeEventListener('mouseup', this.onResizeStop);
  };

  /*------------------------------------*/
  /*ROTATE*/
  /*------------------------------------*/

  onRotateStart = e => {
    debug && console.log('onRotateStart');
    const {
      size: { width, height },
      borderWidth,
      onRotateStart,
      zoom,
    } = this.props;
    e.stopPropagation();

    /*the rotation is calculated from the horizontal center axis of the div at rotation = 0.*/
    /*1. Get the constant angle between the handle with 0 rotation and the horizontal center axis*/

    this.rootCenterCoords = {
      X: this.elementRoot.getBoundingClientRect().left + (width * zoom) / 2,
      Y: this.elementRoot.getBoundingClientRect().top + (height * zoom) / 2,
    };

    this.initRotationOfHandleInRad = calcInitRotation({
      width: width * zoom,
      height: height * zoom,
      borderWidth: borderWidth * zoom,
      handleClicked: e.target.dataset.corner,
      rootCenterCoords: this.rootCenterCoords,
      elementRoot: this.elementRoot.getBoundingClientRect(),
    });

    /*tell the parent component we start the resize*/
    onRotateStart();

    document.addEventListener('mousemove', this.onRotate);
    document.addEventListener('mouseup', this.onRotateEnd);
  };

  onRotate = e => {
    e.stopPropagation();
    e.preventDefault();
    const { guides, magnetismGrids, onRotate, zoom } = this.props;
    const { disableGuides } = this.state;

    let rotation = calcRot({
      e,
      rootCenterCoords: this.rootCenterCoords,
      initRotationOfHandleInRad: this.initRotationOfHandleInRad,
    });

    let guidesToShow = [];

    ({ guidesToShow, rotation } = getGuidesToShowForRotation({
      angle: rotation,
      guides,
      magnetismGrid: magnetismGrids.rotation,
      disableGuides,
      zoom,
    }));
    onRotate({ guidesToShow, rotation });
  };

  onRotateEnd = e => {
    debug && console.log('onRotateEnd');

    e.stopPropagation();

    this.props.onRotateEnd();

    document.removeEventListener('mousemove', this.onRotate);
    document.removeEventListener('mouseup', this.onRotateEnd);
  };

  /*------------------------------------*/
  /*RENDER*/
  /*------------------------------------*/

  render() {
    const {
      type,
      selected,
      multipleSelected,
      status,
      children,
      disableRotation,
      cornersSelectable,
      elementIsPlayingAnimation,
      slideIsPlayingAnimation,
    } = this.props;

    const { lockAspectRatioDiagonal, stylesState, classNamesState } = this.state;

    const showAsSelected = (selected || multipleSelected) && !(elementIsPlayingAnimation || slideIsPlayingAnimation);
    const showCorner = showAsSelected && cornersSelectable;
    const isText = type === 'text';

    return (
      <React.Fragment>
        <div
          className={classNamesState.root}
          style={stylesState.rootStyle}
          onMouseDown={this.onHandleMouseDown}
          onAnimationEnd={this.props.onAnimationEnd}
          data-corner={corners.inside}
          ref={elementRoot => {
            this.elementRoot = elementRoot;
            if (elementRoot) {
              this.parentRef = elementRoot.parentNode;
              this.motherWidth = elementRoot.parentNode.getBoundingClientRect().width;
              this.motherHeight = elementRoot.parentNode.getBoundingClientRect().height;
            }
          }}
        >
          <div
            className={classNamesState.rootTransformed}
            style={stylesState.rootTransformedStyle}
            data-corner={corners.inside}
          >
            {children}
            {/*the top left corner is always in the DOM, in order for it to send its position all the time
            even when the element is not selected, for the crop to work properly*/}
            {!status.elementIsPlayingAnimation && (
              <div
                className={classNamesState.topLeft}
                style={stylesState.topLeft}
                onMouseDown={this.onHandleMouseDown}
                data-corner={corners.topLeft}
                ref={topLeftCorner => (this.topLeftCorner = topLeftCorner)}
              />
            )}
            {showCorner && (
              <React.Fragment>
                {/* HANDLES ON SIDES: the only handles for text */}
                <div
                  className={classNamesState.middleRight}
                  style={stylesState.middleRight}
                  onMouseDown={this.onHandleMouseDown}
                  data-corner={corners.middleRight}
                  ref={middleRightCorner => (this.middleRightCorner = middleRightCorner)}
                />
                <div
                  className={classNamesState.middleLeft}
                  style={stylesState.middleLeft}
                  onMouseDown={this.onHandleMouseDown}
                  data-corner={corners.middleLeft}
                  ref={middleLeftCorner => (this.middleLeftCorner = middleLeftCorner)}
                />
                {/* NOT FOR TEXT */}
                {!isText && (
                  <React.Fragment>
                    <div
                      className={classNamesState.topRight}
                      style={stylesState.topRight}
                      onMouseDown={this.onHandleMouseDown}
                      data-corner={corners.topRight}
                      ref={topRightCorner => (this.topRightCorner = topRightCorner)}
                    />
                    <div
                      className={classNamesState.bottomLeft}
                      style={stylesState.bottomLeft}
                      onMouseDown={this.onHandleMouseDown}
                      data-corner={corners.bottomLeft}
                      ref={bottomLeftCorner => (this.bottomLeftCorner = bottomLeftCorner)}
                    />
                    <div
                      className={classNamesState.bottomRight}
                      style={stylesState.bottomRight}
                      onMouseDown={this.onHandleMouseDown}
                      data-corner={corners.bottomRight}
                      ref={bottomRightCorner => (this.bottomRightCorner = bottomRightCorner)}
                    />
                    <div
                      className={classNamesState.middleTop}
                      style={stylesState.middleTop}
                      onMouseDown={this.onHandleMouseDown}
                      data-corner={corners.middleTop}
                      ref={middleTopCorner => (this.middleTopCorner = middleTopCorner)}
                    />
                    <div
                      className={classNamesState.middleBottom}
                      style={stylesState.middleBottom}
                      onMouseDown={this.onHandleMouseDown}
                      data-corner={corners.middleBottom}
                      ref={middleBottomCorner => (this.middleBottomCorner = middleBottomCorner)}
                    />
                  </React.Fragment>
                )}
                {/*rotation only*/}
                {!disableRotation && (
                  <div
                    className={classNamesState.middleTopAbove}
                    style={stylesState.middleTopAbove}
                    onMouseDown={this.onRotateStart}
                    data-corner={corners.middleTop}
                    ref={middleTopCornerAbove => (this.middleTopCornerAbove = middleTopCornerAbove)}
                  />
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        {lockAspectRatioDiagonal &&
          status.isResizing && (
            <div className={classNamesState.lockAspectRatioDiagonal} style={lockAspectRatioDiagonal} />
          )}
      </React.Fragment>
    );
  }
}

Morphable.propTypes = {
  minHeight: PropTypes.number,
  selected: PropTypes.bool,
  multipleSelected: PropTypes.bool,
  bounds: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    right: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
  }).isRequired,
  onHandleMouseDown: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrag: PropTypes.func,
  onDragStop: PropTypes.func,
  onResizeStart: PropTypes.func,
  onResize: PropTypes.func,
  onResizeStop: PropTypes.func,
  onRotateStart: PropTypes.func,
  onRotate: PropTypes.func,
  onRotateEnd: PropTypes.func,
  guides: PropTypes.instanceOf(List),
  size: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  rotation: PropTypes.number,
  borderWidth: PropTypes.number,
  position: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  magnetismGrids: PropTypes.shape({
    rotation: PropTypes.number,
    drag: PropTypes.number,
    resize: PropTypes.number,
  }),
  lockAspectRatio: PropTypes.bool,
  status: PropTypes.shape({
    isRotating: PropTypes.bool,
    isResizing: PropTypes.bool,
    elementIsPlayingAnimation: PropTypes.bool,
    isDragging: PropTypes.bool,
    isCropping: PropTypes.bool,
  }),
  animation: PropTypes.shape({
    animationName: PropTypes.string,
    animationDelay: PropTypes.string,
    animationDuration: PropTypes.string,
    animationIterationCount: PropTypes.string,
    animationTimingFunction: PropTypes.string,
    animationFillMode: PropTypes.string,
    animationDirection: PropTypes.string,
    '--marginTranslationIn': PropTypes.string,
    '--marginTranslationOut': PropTypes.string,
  }),
  disableRotation: PropTypes.bool,
  disableBounds: PropTypes.bool,
  disableGuides: PropTypes.bool,
  transformOrigin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  ]),
  cornersSelectable: PropTypes.bool,
};

Morphable.defaultProps = {
  minHeight: 0,
  selected: true,
  multipleSelected: false,
  lockAspectRatio: false,
  magnetismGrids,
  borderWidth: 0,
  rotation: 0,
  guides: [],
  status: {
    isRotating: false,
    isResizing: false,
    elementIsPlayingAnimation: false,
    isDragging: false,
    isCropping: false,
  },
  animation: {},
  disableRotation: false,
  disableBounds: false,
  disableGuides: false,
  transformOrigin: 'center center',
  cornersSelectable: true,
  onHandleMouseDown: () => {
    return;
  },
  onDragStart: () => {
    return;
  },
  onDrag: () => {
    return;
  },
  onDragStop: () => {
    return;
  },
  moveWithArrows: () => {
    return;
  },
  onResizeStart: () => {
    return;
  },
  onResize: () => {
    return;
  },
  onResizeStop: () => {
    return;
  },
  onRotateStart: () => {
    return;
  },
  onRotate: () => {
    return;
  },
  onRotateEnd: () => {
    return;
  },
};

export default withStyles(morphableStyles)(Morphable);
