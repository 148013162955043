import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { round2DigitsHelper, opacityInputProps } from '../constants';
import { getSelectedElementBlinkMinOpacity } from '../../banner/bannerSelectors';
import { updateElementBlink } from '../../banner/bannerActionsCreators';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementBlinkOpacity extends React.Component {
  onBlinkOpacityChange = ({ value: minOpacity, id }) => this.props.updateElementBlink({ minOpacity, id }); //tracked

  render() {
    const { id, minOpacity, intl } = this.props;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        label={intl.formatMessage({ id: 'properties.animation.blink-min-opacity' })}
        inputProps={opacityInputProps}
        value={minOpacity}
        fullWidth
        helper={round2DigitsHelper}
        onChangeMethod={this.onBlinkOpacityChange}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  minOpacity: makeSelectorInstance(getSelectedElementBlinkMinOpacity)(state),
});

const dispatchToProps = {
  updateElementBlink,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
)(ElementBlinkOpacity);
