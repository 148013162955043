import React from 'react';
import ElementSnapshotHidden from '../element/ElementSnapshotHidden';
import { connect } from 'react-redux';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { setSlideSnapshotsAnimationProperties, updateSlideGIFAnimation } from './helpers';
import { getBannerDuration } from '../banner/bannerSelectors';
import { getSlideElements, getBannerSetFPS } from '../banners/bannersSelectors';
import { isRenderingSlidesForExportToImages } from '../export/exportSelectors';

class SceneSnapshot extends React.Component {
  state = {
    animationProperties: undefined,
    currentFrame: this.props.currentFrame || 0, //only defined for rendering for export to animated GIF
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.animationProperties === undefined) {
      return {
        ...prevState,
        ...setSlideSnapshotsAnimationProperties(nextProps.framesPerSecond, prevState.currentFrame)(null, nextProps),
      };
    }
    return prevState;
  }

  componentDidMount() {
    if (this.props.bannerIsPlayingGIFAnimation) {
      this.playGIFAnimation();
    }
  }

  componentWillUnmount() {
    if (this.props.bannerIsPlayingGIFAnimation) {
      window.clearInterval(this.animInterval);
    }
  }

  playGIFAnimation = () => {
    const { framesPerSecond, bannerDuration } = this.props;
    const { currentFrame } = this.state;
    const interval = 1 / framesPerSecond;
    this.animInterval = window.setInterval(() => {
      if (!currentFrame || currentFrame < bannerDuration * framesPerSecond) {
        this.setState(updateSlideGIFAnimation(framesPerSecond));
      } else {
        window.clearInterval(this.animInterval);
      }
    }, interval * 1000);
  };

  render() {
    const {
      format: { width, height },
      slide,
      bannerUniqueId,
      elements,
      backgroundColor,
      animationGlobalDelay,
      isLastSlide,
      isFirstSlide,
      slideDurationForAnimation,
      framesPerSecond,
      bannerIsPlayingGIFAnimation,
      snapshotCurrentScene,
    } = this.props;

    const { animationProperties, currentFrame } = this.state;
    return (
      <div
        className="scenesnapshot"
        style={{
          width,
          height,
          backgroundColor,
          position: bannerIsPlayingGIFAnimation ? 'relative' : 'absolute',
          top: bannerIsPlayingGIFAnimation ? null : 0,
          left: bannerIsPlayingGIFAnimation ? null : 0,
          zIndex: bannerIsPlayingGIFAnimation ? 100 : -1,
          overflow: 'hidden',
          ...animationProperties,
        }}
        ref={slide => (this.slide = slide)}
      >
        {elements.reverse().map(element => {
          return (
            <ElementSnapshotHidden
              {...element.toJS()}
              bannerUniqueId={bannerUniqueId}
              key={element.id}
              animationDelay={animationGlobalDelay}
              isLastSlide={isLastSlide}
              isFirstSlide={isFirstSlide}
              slideDuration={slide.duration}
              slideDurationForAnimation={slideDurationForAnimation || slide.duration}
              framesPerSecond={framesPerSecond}
              currentFrame={currentFrame}
              snapshotCurrentScene={snapshotCurrentScene}
              bannerHeight={height}
              bannerWidth={width}
            />
          );
        })}
      </div>
    );
  }
}

const makeStateToProps = () => (state, { bannerUniqueId, slide }) => ({
  elements: makeSelectorInstance(getSlideElements(bannerUniqueId, slide))(state),
  framesPerSecond: makeSelectorInstance(getBannerSetFPS)(state),
  bannerDuration: makeSelectorInstance(getBannerDuration)(state),
  renderingSlidesForExportToImages: makeSelectorInstance(isRenderingSlidesForExportToImages)(state),
});

export default connect(makeStateToProps)(SceneSnapshot);
