import { List } from 'immutable';

export const getGuidesToShowForDrag = ({
  guides,
  theoreticalTopLeftCoords,
  theoreticalCenterCoords,
  theoreticalBottomRightCoords,
  rotation,
  width,
  height,
  magnetismGrid,
  disableGuides,
  zoom,
}) => {
  /*the transformation from zoomed ton non-zoomed is assumed by ElementRoot, not by Morphable
  therefore, apply the zoom in order to transform the non-zoomed dimensions into zoomed dimensions handled by Morphable
  zoomed dimensions:
      theoreticalTopLeftCoords
      theoreticalCenterCoords
      theoreticalBottomRightCoords
      non-zoomed dimensions:
      guides
      width
      height
      magnetismGrid
  */

  let newX = theoreticalTopLeftCoords.x;
  let newY = theoreticalTopLeftCoords.y;

  let guidesToShow = [];

  if (disableGuides) {
    return {
      guidesToShow,
      newX,
      newY,
    };
  }

  guides.forEach((guide, ind) => {
    /* equidistant guides */
    if (rotation === 0 && List.isList(guide)) {
      if (guide.get(0).orientation === 'horizontal') {
        const leftGuide = guide.filter(gui => gui.magnetism === 'equidistant-left').get(0);
        const rightGuide = guide.filter(gui => gui.magnetism === 'equidistant-right').get(0);

        if (
          leftGuide &&
          Math.abs(theoreticalBottomRightCoords.x - leftGuide.position.left * zoom) < magnetismGrid * zoom
        ) {
          newX = (leftGuide.position.left - width) * zoom;
          guidesToShow.push({ indexOfGuide: ind, x: newX, y: newY, width, height });
        }

        if (
          rightGuide &&
          Math.abs(theoreticalTopLeftCoords.x - (rightGuide.position.left + rightGuide.position.width) * zoom) <
            magnetismGrid * zoom
        ) {
          newX = (rightGuide.position.left + rightGuide.position.width) * zoom;
          guidesToShow.push({ indexOfGuide: ind, x: newX, y: newY, width, height });
        }
      }
      if (guide.get(0).orientation === 'vertical') {
        const topGuide = guide.filter(gui => gui.magnetism === 'equidistant-top').get(0);
        const bottomGuide = guide.filter(gui => gui.magnetism === 'equidistant-bottom').get(0);

        if (
          topGuide &&
          Math.abs(theoreticalBottomRightCoords.y - topGuide.position.top * zoom) < magnetismGrid * zoom
        ) {
          newY = (topGuide.position.top - height) * zoom;
          guidesToShow.push({ indexOfGuide: ind, x: newX, y: newY, width, height });
        }

        if (
          bottomGuide &&
          Math.abs(theoreticalTopLeftCoords.y - (bottomGuide.position.top + bottomGuide.position.height) * zoom) <
            magnetismGrid * zoom
        ) {
          newY = (bottomGuide.position.top + bottomGuide.position.height) * zoom;
          guidesToShow.push({ indexOfGuide: ind, x: newX, y: newY, width, height });
        }
      }
    } else {
      if (guide.orientation === 'vertical') {
        /* center guide */
        if (
          guide.magnetism === 'center' &&
          Math.abs(theoreticalCenterCoords.x - guide.position * zoom) < magnetismGrid * zoom
        ) {
          newX = (guide.position - width / 2) * zoom;
          guidesToShow.push(ind);
        }
        /* left guide */
        if (
          guide.magnetism === 'left' &&
          rotation === 0 &&
          Math.abs(theoreticalTopLeftCoords.x - guide.position * zoom) < magnetismGrid * zoom
        ) {
          newX = guide.position * zoom;
          guidesToShow.push(ind);
        }
        /* right guide */
        if (
          guide.magnetism === 'right' &&
          rotation === 0 &&
          Math.abs(theoreticalBottomRightCoords.x - guide.position * zoom) < magnetismGrid * zoom
        ) {
          newX = (guide.position - width) * zoom;
          guidesToShow.push(ind);
        }
      }
      if (guide.orientation === 'horizontal') {
        /* center guide */
        if (
          guide.magnetism === 'center' &&
          Math.abs(theoreticalCenterCoords.y - guide.position * zoom) < magnetismGrid * zoom
        ) {
          newY = (guide.position - height / 2) * zoom;
          guidesToShow.push(ind);
        }
        /* top guide */
        if (
          guide.magnetism === 'top' &&
          rotation === 0 &&
          Math.abs(theoreticalTopLeftCoords.y - guide.position * zoom) < magnetismGrid * zoom
        ) {
          newY = guide.position * zoom;
          guidesToShow.push(ind);
        }
        /* bottom guide */
        if (
          guide.magnetism === 'bottom' &&
          rotation === 0 &&
          Math.abs(theoreticalBottomRightCoords.y - guide.position * zoom) < magnetismGrid * zoom
        ) {
          newY = (guide.position - height) * zoom;
          guidesToShow.push(ind);
        }
      }
    }
  });

  return {
    guidesToShow,
    newX,
    newY,
  };
};
