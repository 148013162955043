import { is } from 'immutable';
import domtoimage from 'dom-to-image';
import { SizeRecord } from '../reference/records';
import { centerInBanner } from '../banner/bannerUtils';
import { objectUrlToBlob } from '../export/import-export-helpers';

export const calculateX = (toLeft, toRight, masterElementBounds, positionFactor, banner, width) => {
  if (toLeft) return masterElementBounds.left * positionFactor.X;
  if (toRight) return masterElementBounds.right * positionFactor.X - width;
  return banner.present.format.width / 2 - width / 2;
};

export const calculateY = (toTop, toBottom, masterElementBounds, positionFactor, banner, height) => {
  if (toTop) return masterElementBounds.top * positionFactor.Y;
  if (toBottom) return masterElementBounds.bottom * positionFactor.Y - height;
  return banner.present.format.height / 2 - height / 2;
};

export const setElementStartingPosition = (element, { bannerWidth, bannerHeight }, resourceId) => {
  // keep this code if we want to have image sized the size of the banner
  // const newWidth = element.originalSize
  //   ? calculateImageSize(bannerWidth, bannerHeight, element.originalSize).width
  //   : element.width;
  // const newHeight = element.originalSize
  //   ? calculateImageSize(bannerWidth, bannerHeight, element.originalSize).height
  //   : element.height;
  const newX = centerInBanner(bannerWidth, element.width);
  const newY = centerInBanner(bannerHeight, element.height);

  return element.withMutations(el =>
    el
      .set('x', newX)
      .set('y', newY)
      .set('width', element.width)
      .set('height', element.height)
      .set('originalSize', SizeRecord({ height: element.height, width: element.width }))
      .set('resourceId', resourceId)
      .update(
        'picWhileCroppingProperties',
        props =>
          !props
            ? null
            : props.merge({
                // only for image
                x: newX,
                y: newY,
                width: element.width,
                height: element.height,
              }),
      ),
  );
};

// TESTME (tested through selectors)
export const checkBannerIsDissociatedByDefault = ({ bannerSlave, bannerMaster }) => {
  if (bannerMaster === null) return false;
  const bannerMasterElementIds = bannerMaster.elements.map(({ id }) => id);
  const bannerSlaveElementIds = bannerSlave.elements.map(({ id }) => id);
  if (!is(bannerMasterElementIds, bannerSlaveElementIds)) return true;
  const bannerMasterSlides = bannerMaster.slides;
  const bannerSlaveSlides = bannerSlave.slides;
  if (!is(bannerMasterSlides, bannerSlaveSlides)) return true;
  return false;
};

export const checkBannerIsDissociated = ({ bannerSlave, bannerMaster, debug = false }) => {
  if (checkBannerIsDissociatedByDefault({ bannerSlave, bannerMaster, debug })) return true;
  if (bannerSlave.dissociateFromMaster) return true;
  return false;
};

export const updateSlaveBanners = ({ banners, bannerMaster, property, value }) =>
  banners.map(banner => {
    if (checkBannerIsDissociated({ bannerSlave: banner.present, bannerMaster })) return banner;
    return {
      ...banner,
      present: banner.present.set(property, value),
    };
  });

export const createJPEGImageFromDOM = async ({ DOMNode }) => {
  return await domtoimage
    .toJpeg(DOMNode)
    .then(imageUrl => objectUrlToBlob(imageUrl))
    .catch(e => console.log("couldn't export to JPEG", e));
};

export const extractPermissions = ({ contextParameters: { acls = [] } = {} }) => {
  return acls
    .map(({ aces }) => aces.map(({ id }) => id))
    .filter(val => Boolean(val))
    .reduce((acc, val) => acc.concat(val), []); // flatten
};
