import { List, Map } from 'immutable';
import {
  BannerFormatRecord,
  ButtonElementRecord,
  ImageElementRecord,
  RectangleElementRecord,
  TextElementRecord,
} from '../reference/records';
import { maxZoom, minZoom } from '../reference/zoom';
import { createSlide, createBanner, createShadow, createBlink } from './bannerUtils';

// Actions
import {
  BANNER_NEW,
  BANNER_CLEAR_STATE,
  BANNER_TRIGGER_UNDO_REDO,
  BANNER_SET_NAME,
  BANNER_SET_THUMBNAIL,
  BANNER_ELEMENT_ADDED,
  ELEMENT_SET_IMAGE_RESOURCE,
  ELEMENT_SET_OPTIMIZED_IMAGE_RESOURCE,
  BANNER_CHANGE_FORMAT,
  BANNER_SET_BACKGROUND_COLOR,
  BANNER_SET_TRANSPARENT_COLOR,
  BANNER_SET_ZOOM,
  BANNER_TOGGLE_DISSOCIATE_FROM_MASTER,
  BANNER_ANIMATION_SET_REPETITION,
  ELEMENT_UPDATE_DISPOSITION,
  ELEMENT_UPDATE_INDEX,
  ELEMENT_SET_FONT_SIZE,
  ELEMENT_SET_BACK_TO_ORIGINAL_SIZE,
  ELEMENT_SET_BACK_TO_ORIGINAL_IMAGE,
  ELEMENT_SET_IMAGE_PROPERTIES_POSITION,
  ELEMENT_SET_IMAGE_PROPERTIES_DIMENSIONS,
  ELEMENT_SET_IMAGE_PROPERTIES_ROTATION,
  ELEMENT_DELETE,
  ELEMENT_UPDATE,
  ELEMENT_UPDATE_FONT_STYLE,
  ELEMENT_UPDATE_DIMENSIONS,
  ELEMENT_UPDATE_POSITION,
  ELEMENT_UPDATE_ROTATION,
  ELEMENT_UPDATE_TRANSITION,
  ELEMENT_TOGGLE_SHADOW,
  ELEMENT_UPDATE_SHADOW,
  ELEMENT_TOGGLE_BLINK,
  ELEMENT_UPDATE_BLINK,
  ELEMENT_SET_SHOW_ON_ALL_SLIDES,
  ELEMENTS_UPDATE,
  ELEMENTS_UPDATE_DIFF,
  ELEMENTS_UPDATE_DEEP,
  ELEMENTS_SET_SHOW_ON_ALL_SLIDES,
  ELEMENTS_DELETE,
  SLIDE_ADD,
  SLIDE_DUPLICATE,
  SLIDE_SET_DURATION,
  SLIDES_SET_DURATION,
  SLIDE_SET_TRANSITION_TYPE,
  SLIDE_SET_TRANSITION_DURATION,
  SLIDE_DELETE,
  BANNER_SET_EXPIRATION_DATE,
} from './bannerActions';
import { BANNERS_SET_MASTER, BANNERS_LOAD } from '../banners/bannersActions';
import { MASTER_BANNER_DIALOG_CONFIRM_DISSOCIATE } from '../master-banner-dialog/masterBannerDialogDucks';
import {} from '../temporary-status/temporaryStatusDucks';

export const slideAddReducer = banner => {
  const slideIdNumber = banner.nextId.get('slide');
  const globalElementIds = banner.elements
    .valueSeq()
    .filter(element => element.showOnAllSlides)
    .map(element => element.id)
    .toList();
  return banner.withMutations(s =>
    s
      .update('slides', slides =>
        slides.push(createSlide({ id: `slide-${slideIdNumber}`, elementIds: globalElementIds })),
      )
      // .set('selectedSlideIndex', banner.slides.size)
      // .set('selectedElementId', null)
      .setIn(['nextId', 'slide'], slideIdNumber + 1),
  );
};

export const slideDuplicateReducer = (banner, { slide, elements, nextId }, forceSelfBanner = false) => {
  // why forceSelfBanner ? the "elements" included here are the original elements from the banner where the have been copied.
  // sometimes we don't need the original elements, we only needs the element ids
  // and get the banner-target get the other element properties.

  // why nextId in the payload ? I forgot why...

  const slideIdNumber = nextId.get('slide');
  let imageIdNumber = nextId.get('image');
  let textIdNumber = nextId.get('text');
  let rectangleIdNumber = nextId.get('rectangle');
  let buttonIdNumber = nextId.get('button');
  if (forceSelfBanner) {
    elements = elements.map(({ id }) => banner.elements.get(id) || undefined).filter(el => el);
  }
  const elementsToDuplicate = elements
    .map(element => {
      let id;
      switch (element.type) {
        case 'image': {
          id = `image-${imageIdNumber}`;
          imageIdNumber++;
          return ImageElementRecord({ ...element.toObject(), id, showOnAllSlides: false });
        }
        case 'text': {
          id = `text-${textIdNumber}`;
          textIdNumber++;
          return TextElementRecord({ ...element.toObject(), id, showOnAllSlides: false });
        }
        case 'rectangle': {
          id = `rectangle-${rectangleIdNumber}`;
          rectangleIdNumber++;
          return RectangleElementRecord({ ...element.toObject(), id, showOnAllSlides: false });
        }
        case 'button': {
          id = `button-${buttonIdNumber}`;
          buttonIdNumber++;
          return ButtonElementRecord({ ...element.toObject(), id, showOnAllSlides: false });
        }
        default: {
          return null;
        }
      }
    })
    .filter(el => el); // filter undefined

  const elementsDuplicatedIds = elementsToDuplicate
    .valueSeq()
    .map(element => element.id)
    .toList();

  const globalElementIds = banner.elements
    .valueSeq()
    .filter(element => element.showOnAllSlides)
    .map(element => element.id)
    .toList();

  return banner.withMutations(s =>
    s
      .update('slides', slides =>
        slides.push(
          createSlide({
            ...slide.toJS(),
            id: `slide-${slideIdNumber}`,
            elementIds: List.of(...globalElementIds, ...elementsDuplicatedIds),
          }),
        ),
      )
      .update('elements', elements =>
        elements.withMutations(e => {
          elementsToDuplicate.forEach(newEl => e.set(`${newEl.id}`, newEl));
          return e;
        }),
      )
      // .set('selectedSlideIndex', banner.slides.size)
      // .set('selectedElementId', null)
      // .set('selectedElementIds', List())
      .set(
        'nextId',
        Map({
          slide: slideIdNumber + 1,
          text: textIdNumber,
          image: imageIdNumber,
          rectangle: rectangleIdNumber,
          button: buttonIdNumber,
        }),
      ),
  );
};

export const slideDeleteReducer = (banner, { deletedIndex }) => {
  if (deletedIndex === 0) {
    //adjust new first slide transition if needed
    const firstSlideTransition = banner.slides.get(0).transition;
    return banner
      .update('slides', slides => slides.delete(deletedIndex))
      .setIn(['slides', 0, 'transition'], firstSlideTransition);
  } else {
    return banner.update('slides', slides => slides.delete(deletedIndex));
  }
};

export const updateElementDispositionReducer = ({ action, banner, slideElementIds, elementId, slideIndex }) => {
  const oldIndex = slideElementIds.indexOf(elementId);
  if (oldIndex < 0) {
    // Do nothing if the element is not in the current slide
    return banner;
  }
  let newIndex;
  switch (action.payload.disposition) {
    case 'forward':
      newIndex = Math.max(oldIndex - 1, 0);
      break;
    case 'backward':
      newIndex = Math.min(oldIndex + 1, slideElementIds.size - 1);
      break;
    case 'front':
      newIndex = 0;
      break;
    case 'back':
      newIndex = slideElementIds.size - 1;
      break;
    default:
      newIndex = oldIndex;
  }
  return banner.updateIn(['slides', slideIndex, 'elementIds'], elementIds =>
    elementIds.delete(oldIndex).insert(newIndex, elementId),
  );
};

export const updateElementIndexReducer = ({ action, banner, slideElementIds, slideIndex }) => {
  const { oldIndex, newIndex } = action.payload;
  const elementId = slideElementIds.get(oldIndex);
  return banner.updateIn(['slides', slideIndex, 'elementIds'], elementIds =>
    elementIds.delete(oldIndex).insert(newIndex, elementId),
  );
};

export const updateElementFontSizeReducer = ({ action, banner, selectedElement, elementId }) => {
  return selectedElement.type === 'text'
    ? banner
        .updateIn(['elements', elementId, 'fontSizes'], fontSizes => fontSizes.add(action.payload.fontSize))
        .setIn(['elements', elementId, 'fontSize'], action.payload.fontSize)
    : banner.updateIn(['elements', elementId, 'fontSize'], _ => action.payload.fontSize);
};
export const updateElementShowOnAllSlidesReducer = ({ action, banner: state, slideIndex, elementId }) => {
  const { showOnAllSlides } = action.payload;
  if (showOnAllSlides) {
    // Add to all other slides
    return state
      .update('slides', slides =>
        slides.map(
          (slide, idx) =>
            idx === slideIndex ? slide : slide.update('elementIds', elementIds => elementIds.unshift(elementId)),
        ),
      )
      .setIn(['elements', elementId, 'showOnAllSlides'], true);
  } else {
    // Remove from all other slides
    return state
      .update('slides', slides =>
        slides.map(
          (slide, idx) =>
            idx === slideIndex
              ? slide
              : slide.update('elementIds', elementIds => elementIds.filter(id => id !== elementId)),
        ),
      )
      .setIn(['elements', elementId, 'showOnAllSlides'], false);
  }
};

export const updateElementsShowOnAllSlidesReducer = ({ action, banner, slideIndex, elementIds }) => {
  const { showOnAllSlides } = action.payload;
  if (showOnAllSlides) {
    // Add to all other slides
    return banner
      .update('slides', slides =>
        slides.map((slide, idx) => {
          if (idx === slideIndex) return slide;
          return slide.update('elementIds', elIds => elIds.unshift(...elementIds.filter(id => !elIds.includes(id))));
        }),
      )
      .update('elements', els => els.map(el => (elementIds.includes(el.id) ? el.merge(action.payload) : el)));
  }
  // Remove from all other slides
  return banner
    .update('slides', slides =>
      slides.map((slide, idx) => {
        if (idx === slideIndex) return slide;
        return slide.update('elementIds', elIds => elIds.filter(id => !elementIds.includes(id)));
      }),
    )
    .update('elements', els =>
      els.map(el => {
        if (!elementIds.includes(el.id)) return el;
        return el.merge(action.payload);
      }),
    );
};

export const updateElementsReducer = ({ banner, action, elementIds }) => {
  /* if update position */
  if (action.payload.x || action.payload.y) {
    return banner.update('elements', els =>
      els.map(el => {
        if (!elementIds.includes(el.id)) return el;
        let { x, y } = action.payload;
        if (x === undefined) x = el.x;
        if (y === undefined) y = el.y;
        if (el.type !== 'image') return el.merge({ x, y });
        return el.merge({ x, y }).mergeIn(['picWhileCroppingProperties'], {
          x: el.picWhileCroppingProperties.x + (x - el.x),
          y: el.picWhileCroppingProperties.y + (y - el.y),
        });
      }),
    );
  }
  /* else */
  return banner.update('elements', els => els.map(el => (elementIds.includes(el.id) ? el.merge(action.payload) : el)));
};

// Reducer

export const initialState = createBanner();

const reducer = (state = initialState, action) => {
  const slideIndex = action.payload && action.payload.slideIndex;

  const slideElementIds =
    state.slides && state.slides.get(slideIndex) ? state.slides.get(slideIndex).elementIds : List();

  const elementId =
    (action.payload && action.payload.id) ||
    (action.payload && action.payload.ids && action.payload.ids.length === 1 && action.payload.ids[0]);

  const elementIds = action.payload && action.payload.ids;

  let selectedElement;
  if (elementId) {
    selectedElement = state.elements.get(elementId);
  }

  switch (action.type) {
    case BANNER_NEW:
    case BANNERS_LOAD: {
      return { ...action.payload };
    }
    case BANNER_CHANGE_FORMAT: {
      return state.set('format', BannerFormatRecord(action.payload));
    }
    case BANNER_SET_NAME: {
      return state.set('name', action.payload.name);
    }
    case BANNER_SET_THUMBNAIL: {
      const { currentIndex, bannerIndex, resourceId } = action.payload;
      // 3 cases :
      // 1. bannerIndex === undefined => we want to set the thumbnail to the current banner
      // 2. bannerIndex !== undefined => we want to set a default thumbnail to a banner :
      // 2.1 bannerIndex === currentIndex: we want to define a default thumbnail to the current banner
      // 2.2 bannerIndex !== currentIndex: we want to define a default thumbnail to an other banner

      if (bannerIndex !== undefined) {
        if (currentIndex !== undefined && bannerIndex === currentIndex) {
          return state.set('thumbnailResourceId', resourceId); // 2.1
        } else {
          return state; // 2.2
        }
      }
      return state.set('thumbnailResourceId', resourceId); // 1.
    }
    case BANNER_SET_ZOOM: {
      let zoom = action.payload;
      if (zoom <= minZoom) zoom = minZoom;
      if (zoom >= maxZoom) zoom = maxZoom;
      return state.set('zoom', zoom);
    }
    case BANNER_SET_BACKGROUND_COLOR: {
      return state.set('backgroundColor', action.payload);
    }
    case BANNER_TOGGLE_DISSOCIATE_FROM_MASTER: {
      return state.set('dissociateFromMaster', action.payload);
    }
    case BANNERS_SET_MASTER: {
      return state.set('dissociateFromMaster', false).set('isMaster', action.payload !== null ? true : false);
    }
    case MASTER_BANNER_DIALOG_CONFIRM_DISSOCIATE: {
      return state.set('dissociateFromMaster', true);
    }
    case BANNER_SET_TRANSPARENT_COLOR: {
      return state.set('transparentColor', action.payload);
    }
    case BANNER_SET_EXPIRATION_DATE: {
      const { expirationDate } = action.payload;
      return state.set('expirationDate', expirationDate);
    }
    case BANNER_ANIMATION_SET_REPETITION: {
      return state.set('repetitions', action.payload);
    }
    case BANNER_ELEMENT_ADDED: {
      const { element, resourceId } = action.payload;
      const { id, type } = element;
      return state.withMutations(s =>
        s
          .updateIn(['slides', slideIndex, 'elementIds'], elementIds => elementIds.unshift(id))
          .setIn(['elements', id], type === 'image' && resourceId ? element.set('resourceId', resourceId) : element)
          .updateIn(['nextId', type], typeId => typeId + 1),
      );
    }
    case ELEMENT_DELETE: {
      const idToDelete = action.payload;
      return state
        .update('slides', slides =>
          slides.map(slide => slide.update('elementIds', elementIds => elementIds.filter(id => id !== idToDelete))),
        )
        .deleteIn(['elements', idToDelete]);
    }
    case ELEMENT_UPDATE_DISPOSITION: {
      return updateElementDispositionReducer({ action, banner: state, slideElementIds, elementId, slideIndex });
    }
    case ELEMENT_UPDATE_INDEX: {
      return updateElementIndexReducer({ action, banner: state, slideElementIds, slideIndex });
    }
    case ELEMENT_UPDATE:
    case ELEMENT_SET_IMAGE_RESOURCE: {
      if (selectedElement) {
        return state.mergeIn(['elements', elementId], action.payload);
      }
      return state;
    }
    case ELEMENT_SET_OPTIMIZED_IMAGE_RESOURCE: {
      if (selectedElement) {
        return state.mergeIn(['elements', elementId], { optimizedResourceId: action.payload.resourceId });
      }
      return state;
    }
    case ELEMENTS_DELETE: {
      return state
        .update('slides', slides =>
          slides.map(slide => slide.update('elementIds', elIds => elIds.filter(id => !elementIds.includes(id)))),
        )
        .update('elements', elements => elements.filter(el => !elementIds.includes(el.id)));
    }
    case ELEMENTS_UPDATE: {
      return updateElementsReducer({ banner: state, action, elementIds });
    }
    case ELEMENT_UPDATE_FONT_STYLE: {
      return state.updateIn(['elements', elementId, 'fontStyle'], fontStyles => {
        if (fontStyles.has(action.payload.fontStyle)) {
          return fontStyles.delete(action.payload.fontStyle);
        }
        return fontStyles.add(action.payload.fontStyle);
      });
    }
    case ELEMENTS_UPDATE_DEEP: {
      return state.update('elements', els =>
        els.map(el => (elementIds.includes(el.id) ? el.mergeIn([action.payload.property], action.payload.value) : el)),
      );
    }
    case ELEMENTS_UPDATE_DIFF: {
      const prop = Object.keys(action.payload)[0];
      const value = action.payload[prop];
      return state.update('elements', els =>
        els.map(el => (elementIds.includes(el.id) ? el.merge({ [prop]: el[prop] + value }) : el)),
      );
    }
    case ELEMENT_UPDATE_POSITION: {
      const { x, y, isCropping } = action.payload;
      if (!selectedElement) return state;
      if (selectedElement.type === 'image' && !isCropping) {
        return state
          .mergeIn(['elements', elementId], { x, y })
          .mergeIn(['elements', elementId, 'picWhileCroppingProperties'], {
            x: selectedElement.picWhileCroppingProperties.x + (x - selectedElement.x),
            y: selectedElement.picWhileCroppingProperties.y + (y - selectedElement.y),
          });
      }
      return state.mergeIn(['elements', elementId], { x, y });
    }
    case ELEMENT_UPDATE_ROTATION: {
      const { rotation, isCropping } = action.payload;
      if (!selectedElement) return state;
      if (selectedElement.type === 'image' && !isCropping) {
        return state
          .setIn(['elements', elementId, 'rotation'], rotation)
          .updateIn(
            ['elements', elementId, 'picWhileCroppingProperties', 'rotation'],
            oldRotation => oldRotation + rotation - selectedElement.rotation,
          );
      }
      return state.setIn(['elements', elementId, 'rotation'], rotation);
    }
    case ELEMENT_UPDATE_DIMENSIONS: {
      const { x, y, width, height, isCropping } = action.payload;

      if (!selectedElement) return state;
      if (selectedElement.type === 'image' && !isCropping) {
        const resizeFactor = {
          width: width / selectedElement.width,
          height: height / selectedElement.height,
        };

        return state
          .mergeIn(['elements', elementId], {
            x,
            y,
            width,
            height,
          })
          .updateIn(['elements', elementId, 'picWhileCroppingProperties'], props =>
            props.merge({
              x: x - (selectedElement.x - props.x) * resizeFactor.width,
              y: y - (selectedElement.y - props.y) * resizeFactor.height,
              width: props.width * resizeFactor.width,
              height: props.height * resizeFactor.height,
            }),
          );
      }
      /* don't move the picture position while cropping ; no picWhileCroppingProperties when no image */
      return state.mergeIn(['elements', elementId], {
        x,
        y,
        width,
        height,
      });
    }
    case ELEMENT_SET_FONT_SIZE: {
      return updateElementFontSizeReducer({ action, banner: state, selectedElement, elementId });
    }
    case ELEMENT_SET_BACK_TO_ORIGINAL_SIZE: {
      if (!selectedElement) return state;
      return state
        .mergeIn(['elements', elementId], {
          height: selectedElement.originalSize.height,
          width: selectedElement.originalSize.width,
          x: Math.floor((state.format.width - selectedElement.originalSize.width) / 2),
          y: Math.floor((state.format.height - selectedElement.originalSize.height) / 2),
        })
        .mergeIn(['elements', elementId, 'picWhileCroppingProperties'], {
          height: selectedElement.originalSize.height,
          width: selectedElement.originalSize.width,
          x: Math.floor((state.format.width - selectedElement.originalSize.width) / 2),
          y: Math.floor((state.format.height - selectedElement.originalSize.height) / 2),
        });
    }
    case ELEMENT_SET_BACK_TO_ORIGINAL_IMAGE: {
      if (!selectedElement) return state;
      return state.mergeIn(['elements', elementId, 'picWhileCroppingProperties'], {
        height: selectedElement.height,
        width: selectedElement.width,
        x: selectedElement.x,
        y: selectedElement.y,
      });
    }
    case ELEMENT_UPDATE_TRANSITION: {
      const { transitionName, value } = action.payload;
      return state.mergeIn(['elements', elementId, transitionName], value);
    }
    case ELEMENT_TOGGLE_SHADOW: {
      if (!selectedElement) return state;
      return state.mergeIn(['elements', elementId], {
        dropShadowEnabled: action.payload.dropShadowEnabled,
        dropShadow: selectedElement.dropShadow || createShadow(),
      });
    }
    case ELEMENT_UPDATE_SHADOW: {
      return state.mergeIn(['elements', elementId, 'dropShadow'], action.payload);
    }
    case ELEMENT_TOGGLE_BLINK: {
      if (!selectedElement) return state;
      return state.mergeIn(['elements', elementId], {
        blinkAnimationEnabled: action.payload.blinkAnimationEnabled,
        blinkAnimation: selectedElement.blinkAnimation || createBlink(),
      });
    }
    case ELEMENT_UPDATE_BLINK: {
      return state.mergeIn(['elements', elementId, 'blinkAnimation'], action.payload);
    }
    case ELEMENT_SET_IMAGE_PROPERTIES_POSITION:
    case ELEMENT_SET_IMAGE_PROPERTIES_DIMENSIONS:
    case ELEMENT_SET_IMAGE_PROPERTIES_ROTATION: {
      return state.mergeIn(['elements', elementId, 'picWhileCroppingProperties'], action.payload);
    }
    case ELEMENT_SET_SHOW_ON_ALL_SLIDES: {
      return updateElementShowOnAllSlidesReducer({ action, banner: state, slideIndex, elementId });
    }
    case ELEMENTS_SET_SHOW_ON_ALL_SLIDES: {
      return updateElementsShowOnAllSlidesReducer({ action, banner: state, slideIndex, elementIds });
    }
    case SLIDE_ADD: {
      return slideAddReducer(state);
    }
    case SLIDE_DUPLICATE: {
      return slideDuplicateReducer(state, action.payload);
    }
    case SLIDE_DELETE: {
      return slideDeleteReducer(state, action.payload);
    }
    case SLIDE_SET_DURATION: {
      return state.setIn(['slides', action.payload.index, 'duration'], action.payload.duration);
    }
    case SLIDES_SET_DURATION: {
      return state.update('slides', slides =>
        slides.map(
          slide => (action.payload.indexes.includes(slide.id) ? slide.set('duration', action.payload.duration) : slide),
        ),
      );
    }
    case SLIDE_SET_TRANSITION_TYPE: {
      return state.setIn(['slides', action.payload.index, 'transition', 'type'], action.payload.type);
    }
    case SLIDE_SET_TRANSITION_DURATION: {
      return state.setIn(['slides', action.payload.index, 'transition', 'duration'], action.payload.duration);
    }
    case BANNER_CLEAR_STATE: {
      return initialState;
    }
    case BANNER_TRIGGER_UNDO_REDO:
    default: {
      return state;
    }
  }
};

export default reducer;
