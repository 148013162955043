import React from 'react';
import { AppBar, Toolbar, withStyles } from '@material-ui/core';
import MenuActionButtons from './MenuActionButtons';
import MenuFileButtons from './MenuFileButtons';
import MenuUser from './MenuUser';
import { compose } from 'recompose';
import { menuHeight } from '../reference/dimensions';
import MenuZoom from './MenuZoom';
import MenuElements from './MenuElements';
import CriticalError from './CriticalError';
import BannerSetThumbnail from './BannerSetThumbnail';

const styles = {
  toolbar: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingTop: 5,
    minHeight: menuHeight,
  },
  appBar: {
    background: 'white',
    boxShadow: 'none',
    borderRadius: 0,
  },
};

class Menu extends React.Component {
  state = {
    classesState: {
      appBar: {
        root: this.props.classes.appBar,
      },
    },
  };

  render() {
    const { classesState } = this.state;
    const { classes, backToDrBanner } = this.props;
    return (
      <AppBar position="static" classes={classesState.appBar}>
        <Toolbar className={classes.toolbar}>
          <BannerSetThumbnail thumbnailFor="bannerSet" />
          <CriticalError />
          <MenuFileButtons />
          <MenuActionButtons />
          <MenuElements />
          <MenuZoom />
          <MenuUser backToDrBanner={backToDrBanner} />
        </Toolbar>
      </AppBar>
    );
  }
}

export default compose(
  withStyles(styles),
  React.memo,
)(Menu);
