import JSZip from 'jszip';
import { saveBlob } from './saveBlob';
import { bannerSetZipName } from '../../reference/filesNames';

export const exportBannerSetToZip = async ({ fileContent, attachments }, bannerSetName) => {
  try {
    const zip = new JSZip();
    const fileName = bannerSetZipName(bannerSetName);

    zip.file(fileContent.fileName, fileContent.file);

    attachments.forEach(attachment => {
      zip.file(attachment.fileName, attachment.file);
    });

    zip.generateAsync({ type: 'blob' }).then(zipBlob => saveBlob(fileName, zipBlob));
  } catch (e) {
    console.error('error in makeBannersReadyToSaveOrExport', e);
  }
};
