import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { ToggleButtonGroup } from '@material-ui/lab';
import { withStyles, FormControl, InputLabel } from '@material-ui/core';
import ArrangeBringForwardIcon from 'mdi-material-ui/ArrangeBringForward';
import ArrangeSendBackwardIcon from 'mdi-material-ui/ArrangeSendBackward';
import ArrangeBringToFrontIcon from 'mdi-material-ui/ArrangeBringToFront';
import ArrangeSendToBackIcon from 'mdi-material-ui/ArrangeSendToBack';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementIndex, getSelectedSlideElementsCount } from '../../banner/bannerSelectors';
import { updateElementDisposition } from '../../banner/bannerActionsCreators';
import ToggleButtonWithTooltip from '../../components/ToggleButton/ToggleButtonWithTooltip';
import { styles } from '../propertyEditorStyles';
import { getSelectedElementId, getSelectedSlideIndex } from '../../temporary-status/temporaryStatusDucks';

class ElementDisposition extends React.Component {
  state = { classesState: {} };

  static getDerivedStateFromProps(nextProps) {
    const { classes } = nextProps;
    return {
      classesState: {
        labelShrink: { shrink: classes.labelShrink },
        toggleButtonGroup: { root: classes.toggleButtons },
        toggleButtonWithTooltip: {
          root: classes.toggleButton,
          disabled: classes.toggleButtonDisabled,
          selected: classes.toggleButtonsSelected,
        },
      },
    };
  }

  onDispositionChange = (e, dispositions) => {
    const { updateElementDisposition, id, slideIndex } = this.props;
    updateElementDisposition({ id, slideIndex, disposition: dispositions[0] });
  };

  render() {
    const { elementIndex, elementsCount, intl } = this.props;
    const { classesState } = this.state;
    return (
      <FormControl>
        <InputLabel shrink classes={classesState.labelShrink}>
          <FormattedMessage id="properties.disposition" />
        </InputLabel>
        <ToggleButtonGroup classes={classesState.toggleButtonGroup} selected onChange={this.onDispositionChange}>
          <ToggleButtonWithTooltip
            classes={classesState.toggleButtonWithTooltip}
            value="forward"
            disabled={elementIndex === 0}
            tooltip={intl.formatMessage({ id: 'properties.disposition.forward' })}
          >
            <ArrangeBringForwardIcon />
          </ToggleButtonWithTooltip>
          <ToggleButtonWithTooltip
            classes={classesState.toggleButtonWithTooltip}
            value="backward"
            disabled={elementIndex === elementsCount - 1}
            tooltip={intl.formatMessage({ id: 'properties.disposition.backward' })}
          >
            <ArrangeSendBackwardIcon />
          </ToggleButtonWithTooltip>
          <ToggleButtonWithTooltip
            classes={classesState.toggleButtonWithTooltip}
            value="front"
            disabled={elementIndex === 0}
            tooltip={intl.formatMessage({ id: 'properties.disposition.front' })}
          >
            <ArrangeBringToFrontIcon />
          </ToggleButtonWithTooltip>
          <ToggleButtonWithTooltip
            classes={classesState.toggleButtonWithTooltip}
            value="back"
            disabled={elementIndex === elementsCount - 1}
            tooltip={intl.formatMessage({ id: 'properties.disposition.back' })}
          >
            <ArrangeSendToBackIcon />
          </ToggleButtonWithTooltip>
        </ToggleButtonGroup>
      </FormControl>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  slideIndex: makeSelectorInstance(getSelectedSlideIndex)(state),
  elementIndex: makeSelectorInstance(getSelectedElementIndex)(state),
  elementsCount: makeSelectorInstance(getSelectedSlideElementsCount)(state),
});

const dispatchToProps = {
  updateElementDisposition,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(ElementDisposition);
