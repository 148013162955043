import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, onlyUpdateForKeys } from 'recompose';
import { withStyles } from '@material-ui/core';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import {
  getBannerSlides,
  getSelectedElement,
  getSelectedSlide,
  getSelectedElementDuration,
} from '../../banner/bannerSelectors';
import { setElementDuration, setSlidesDuration, setSlideDuration } from '../../banner/bannerActionsCreators';
import { durationInputProps, secondEndornment, parseFloatHelper } from '../constants';
import { addWarning, addError } from '../../messages/messagesDucks';
import { verifyElementsDuration } from '../helpers';
import { styles } from '../propertyEditorStyles';
import { getSelectedSlideIndex, getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementDuration extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { classes, slide, slides, selectedSlideIndex, element } = nextProps;
    return {
      classesState: { classes: { shrink: classes.labelShrinkXL } },
      durationAdditionalData: {
        slide,
        slides,
        selectedSlideIndex,
        elementSelected: element,
      },
    };
  }

  onElementDurationChange = ({ value: duration, id }) => this.props.setElementDuration({ duration, id }); //tracked

  onElementDurationVerify = ({
    value: duration,
    additionalData: { slide, slides, selectedSlideIndex, elementSelected },
  }) => {
    const { addError, addWarning, setElementDuration, setSlideDuration, setSlidesDuration } = this.props;
    verifyElementsDuration({
      slide,
      slides,
      selectedSlideIndex,
      elements: elementSelected,
      duration,
      addError,
      addWarning,
      setElementDuration,
      setSlideDuration,
      setSlidesDuration,
    });
  };

  render() {
    const { id, duration, intl } = this.props;
    const { classesState, durationAdditionalData } = this.state;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        label={intl.formatMessage({ id: 'properties.duration' })}
        value={duration}
        fullWidth
        inputProps={durationInputProps}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={secondEndornment}
        InputLabelProps={classesState}
        helper={parseFloatHelper}
        additionalData={durationAdditionalData}
        onChangeMethod={this.onElementDurationChange}
        onVerifyMethod={this.onElementDurationVerify}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  duration: makeSelectorInstance(getSelectedElementDuration)(state),
  element: makeSelectorInstance(getSelectedElement)(state),
  slide: makeSelectorInstance(getSelectedSlide)(state),
  selectedSlideIndex: makeSelectorInstance(getSelectedSlideIndex)(state),
  slides: makeSelectorInstance(getBannerSlides)(state),
});

const dispatchToProps = {
  setElementDuration,
  addError,
  addWarning,
  setSlideDuration,
  setSlidesDuration,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  onlyUpdateForKeys(['id', 'duration']),
  injectIntl,
  withStyles(styles),
)(ElementDuration);
