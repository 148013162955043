import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { allElementsHasPropertyChecked } from '../helpers';
import { getSelectedElements } from '../../banner/bannerSelectors';
import { setElementsShowOnAllSlides } from '../../banner/bannerActionsCreators';
import { isCurrentBannerSlaveBanner } from '../../banners/bannersSelectors';
import { getSelectedElementIds, getSelectedSlideIndex } from '../../temporary-status/temporaryStatusDucks';
import CheckboxDoped from '../../components/CheckboxDoped';

class ElementsShowOnAllSlides extends React.Component {
  render() {
    const { setElementsShowOnAllSlides, elements, ids, isBannerSlave, slideIndex } = this.props;
    return (
      <CheckboxDoped
        toggleFunction={setElementsShowOnAllSlides}
        checked={allElementsHasPropertyChecked(elements, 'showOnAllSlides')}
        disabled={isBannerSlave}
        intlId="properties.show-on-all-slides"
        id={ids}
        slideIndex={slideIndex}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  elements: makeSelectorInstance(getSelectedElements)(state),
  ids: makeSelectorInstance(getSelectedElementIds)(state),
  slideIndex: makeSelectorInstance(getSelectedSlideIndex)(state),
  isBannerSlave: makeSelectorInstance(isCurrentBannerSlaveBanner)(state),
});

const dispatchToProps = {
  setElementsShowOnAllSlides,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
)(ElementsShowOnAllSlides);
