import React from 'react';
import { connect } from 'react-redux';
import { TextField, MenuItem, withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import classNames from 'classnames';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { styles } from '../propertyEditorStyles';
import { customTest } from '../../reference/customization';
import {
  getSelectedElementCustomProperty,
  getSelectedElementCustomValueFromOtherEntities,
} from '../../banner/bannerSelectors';
import { loadAllFonts } from '../../resources/fonts';
import {
  toggleCustomValuesSelectOpen,
  getTemporaryCustomValuesOpen,
} from '../../temporary-text-editor/temporaryTextEditorDucks';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import { setCustomTestEntity } from '../../entities/entitiesActionCreators';
import { getSelectedEntityIdForCusto } from '../../entities/entitiesSelectors';

class ElementOtherEntitiesValues extends React.Component {
  state = {
    fontsLoaded: false,
  };

  componentDidMount() {
    // Load fonts in the background
    this.canChangeState = true;
    loadAllFonts().then(() => {
      if (this.canChangeState) {
        this.setState({ fontsLoaded: true });
      }
    });
  }

  componentWillUnmount() {
    this.fontsLoaded = false;
    this.canChangeState = false;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { classes } = nextProps;
    return {
      ...prevState,
      classesState: {
        menuItem: {
          selected: classes.menuSelected,
          root: classes.menuItem,
        },
        menuItemDisabled: {
          select: classes.menuItemDisabled,
        },
      },
    };
  }

  onCustomValuesOpen = () => {
    this.props.toggleCustomValuesSelectOpen(true);
  };

  onCustomValuesClose = () => {
    this.props.toggleCustomValuesSelectOpen(false);
  };

  onCustomValueChange = e => {
    this.props.setCustomTestEntity(e.target.value);
  };

  render() {
    const { intl, selectedEntityId, customValuesFieldOpen, customValues } = this.props;
    const { classesState } = this.state;
    const noCustomValues = !customValues || !customValues.length;
    const selectedName = selectedEntityId ? customValues.find(cust => cust.entityId === selectedEntityId).name : '';

    const valueDisplayed = customValue =>
      customValuesFieldOpen
        ? `${customValue.name}: ${customValue.value}`
        : customValue.value || intl.formatMessage({ id: 'customization.field.defaultValue' });

    const showAsDisabled =
      !customValues.find(({ entityId }) => entityId === selectedEntityId) ||
      !customValues.find(({ entityId }) => entityId === selectedEntityId).value;

    return (
      <TextField
        select
        label={
          selectedEntityId
            ? selectedName
            : intl.formatMessage({ id: `customization.live-test.${customTest.selectEntity}` })
        }
        value={noCustomValues || !selectedEntityId ? '' : selectedEntityId}
        disabled={noCustomValues}
        fullWidth
        onChange={this.onCustomValueChange}
        // https://next.material-ui.com/demos/text-fields/#limitations
        InputLabelProps={{ shrink: Boolean(!noCustomValues && selectedEntityId) }}
        SelectProps={{
          open: customValuesFieldOpen,
          onOpen: () => this.onCustomValuesOpen(),
          onClose: () => this.onCustomValuesClose(),
          classes: { root: classNames({ [classesState.menuItemDisabled]: showAsDisabled }) },
        }}
      >
        {customValues.map(customValue => (
          <MenuItem key={customValue.entityId} value={customValue.entityId} classes={classesState.menuItem}>
            <span>{valueDisplayed(customValue)}</span>
          </MenuItem>
        ))}
        {noCustomValues && (
          <MenuItem>
            <span>{intl.formatMessage({ id: 'customization.field.not-available' })}</span>
          </MenuItem>
        )}
      </TextField>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  property: makeSelectorInstance(getSelectedElementCustomProperty)(state),
  customValuesFieldOpen: makeSelectorInstance(getTemporaryCustomValuesOpen)(state),
  customValues: makeSelectorInstance(getSelectedElementCustomValueFromOtherEntities)(state), // { entityId: name: value: }
  selectedEntityId: makeSelectorInstance(getSelectedEntityIdForCusto)(state),
});

const dispatchToProps = {
  setCustomTestEntity,
  toggleCustomValuesSelectOpen,
};
export default compose(
  connect(makeStateToProps, dispatchToProps),
  injectIntl,
  withStyles(styles),
)(ElementOtherEntitiesValues);
