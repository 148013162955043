import fetchNuxeo from './fetchNuxeo';
import fetchNuxeoLogout from './fetchNuxeoLogout';
import fetchNuxeoMe from './fetchNuxeoMe';
import fetchNuxeoBannerSetPermissions from './fetchNuxeoBannerSetPermissions';
import fetchNuxeoDeletePermission from './fetchNuxeoDeletePermission';
import fetchNuxeoUserWorkspace from './fetchNuxeoUserWorkspace';
import fetchNuxeoDeleteBannerSet from './fetchNuxeoDeleteBannerSet';
import fetchNuxeoSetReadPermission from './fetchNuxeoSetReadPermission';
import fetchNuxeoBannerSetFromUid from './fetchNuxeoBannerSetFromUid';
import fetchNuxeoImageThumbnails from './fetchNuxeoImageThumbnails';
import fetchNuxeoImageAsBlob from './fetchNuxeoImageAsBlob';
import fetchNuxeoSaveBannerSet from './fetchNuxeoSaveBannerSet';
import fetchNuxeoBatchId from './fetchNuxeoBatchId';
import fetchNuxeoUploadFile from './fetchNuxeoUploadFile';
import fetchNuxeoMoveBannerSet from './fetchNuxeoMoveBannerSet';

/* Part of the API is not used yet, because the feature is not available yet */
export default {
  fetch: fetchNuxeo,
  bannerSetPermissions: fetchNuxeoBannerSetPermissions,
  batchId: fetchNuxeoBatchId,
  bannerSetFromUid: fetchNuxeoBannerSetFromUid,
  deletePermission: fetchNuxeoDeletePermission,
  deleteBannerSet: fetchNuxeoDeleteBannerSet,
  imageThumbnails: fetchNuxeoImageThumbnails, // unused
  imageAsBlob: fetchNuxeoImageAsBlob, // unused
  logout: fetchNuxeoLogout,
  me: fetchNuxeoMe,
  saveBannerSet: fetchNuxeoSaveBannerSet,
  setReadPermission: fetchNuxeoSetReadPermission,
  uploadFile: fetchNuxeoUploadFile,
  userWorkspace: fetchNuxeoUserWorkspace,
  moveBannerSet: fetchNuxeoMoveBannerSet,
};
