import { fromCSSToTrigo, calculateRotateCoordsInSystem } from '../../interactions-helpers';

export const calculateCropElementPosition = ({ picWhileCroppingProperties, width, height, x, y, rotation }) => {
  const ALPHA = fromCSSToTrigo(rotation);

  /* 1. get the picture center in not rotated system */
  const picCenter = {
    x: picWhileCroppingProperties.x + picWhileCroppingProperties.width / 2,
    y: picWhileCroppingProperties.y + picWhileCroppingProperties.height / 2,
  };

  /* 2. get the element center */
  const elementCenter = {
    X: x + width / 2,
    Y: y + height / 2,
  };

  /* 3. get the picture center in rotated system*/
  const rotatedPicCenter = calculateRotateCoordsInSystem({
    initCoords: picCenter,
    centerCoords: elementCenter,
    ALPHA: -ALPHA,
  });

  /* return the new position of picture */
  return {
    x: rotatedPicCenter.x - picWhileCroppingProperties.width / 2,
    y: rotatedPicCenter.y - picWhileCroppingProperties.height / 2,
  };
};
