import { put, takeLatest, take } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { bannerFormats, additionalFormats } from '../reference/bannerFormats';
import { initBannerSetName } from '../reference/bannerSetInitProperties';
import { BANNER_NEW, BANNER_CHANGE_FORMAT } from '../banner/bannerActions';
import { checkIfNoBanners, getBannerFormat } from '../shared-selectors/sharedSelectors';
import { openSaveBannersDialogRequested, SAVE_BANNERS_IS_DONE } from '../save-banners-dialog/saveBannersDucks';

//Actions
export const BANNER_FORMAT_SELECTOR_OPEN = 'adbuilder/BANNER_FORMAT_SELECTOR_OPEN';
const BANNER_FORMAT_SELECTOR_OPEN_REQUESTED = 'adbuilder/BANNER_FORMAT_SELECTOR_OPEN_REQUESTED';
export const BANNER_FORMAT_SELECTOR_CLOSE = 'adbuilder/BANNER_FORMAT_SELECTOR_CLOSE';
const BANNER_FORMAT_SELECTOR_UNFOLD = 'adbuilder/BANNER_FORMAT_SELECTOR_UNFOLD';
const BANNER_FORMAT_TOGGLE_SELECT = 'adbuilder/BANNER_FORMAT_TOGGLE_SELECT';
const BANNER_FORMAT_TOGGLE_SELECT_ALL = 'adbuilder/BANNER_FORMAT_TOGGLE_SELECT_ALL';
const BANNER_FORMAT_SET_BANNERSET_NAME = 'adbuilder/BANNER_FORMAT_SET_BANNERSET_NAME';

//Action creators

export const openFormatSelectorDialog = ({
  isForBannerSetCreation = false,
  isForNewBannerCreation = false,
  isForChangingFormat = false,
  isForCopyingBanner = false,
}) => ({
  type: BANNER_FORMAT_SELECTOR_OPEN,
  payload: {
    isForBannerSetCreation,
    isForNewBannerCreation,
    isForChangingFormat,
    isForCopyingBanner,
  },
});

export const openFormatSelectorDialogRequested = () => ({
  type: BANNER_FORMAT_SELECTOR_OPEN_REQUESTED,
});

export const openFormatSelectorForChangingFormatDialog = () => ({
  type: BANNER_FORMAT_SELECTOR_OPEN,
  payload: {
    isForBannerSetCreation: false,
    isForNewBannerCreation: false,
    isForChangingFormat: true,
  },
});

export const unfoldDialog = () => ({
  type: BANNER_FORMAT_SELECTOR_UNFOLD,
});

export const closeFormatSelectorDialog = () => ({
  type: BANNER_FORMAT_SELECTOR_CLOSE,
});

export const toggleSelectFormat = name => ({
  type: BANNER_FORMAT_TOGGLE_SELECT,
  payload: name,
});

export const selectAllFormats = (formats, selectAll) => ({
  type: BANNER_FORMAT_TOGGLE_SELECT_ALL,
  payload: { formats, selectAll },
});

//Selectors
export const getNewBannerSetBannerFormats = ({ bannerFormatSelectorDialog: { selectedFormats } }) => selectedFormats;
export const isForBannerSetCreationState = ({ bannerFormatSelectorDialog: { isForBannerSetCreation } }) =>
  isForBannerSetCreation;
export const isForNewBannerCreationState = ({ bannerFormatSelectorDialog: { isForNewBannerCreation } }) =>
  isForNewBannerCreation;
export const isForChangingFormatState = ({ bannerFormatSelectorDialog: { isForChangingFormat } }) =>
  isForChangingFormat;
export const getBannerFormatDialogState = ({ bannerFormatSelectorDialog }) => bannerFormatSelectorDialog;
export const getBannerFormatDialogOpen = ({ bannerFormatSelectorDialog }) => bannerFormatSelectorDialog.open;
export const selectSelectedBannerFormats = state => state.bannerFormatSelectorDialog.selectedFormats;

export const isFormatSelectorUnfolded = createSelector(
  // TESTME
  [getBannerFormatDialogState, getBannerFormat],
  ({ isUnfolded, isForBannerSetCreation }, currentFormat) =>
    isUnfolded ||
    (!isForBannerSetCreation &&
      additionalFormats.some(({ width, height }) => currentFormat.height === height && currentFormat.width === width)),
);

export const selectIsOpenForBannerSetCreation = createSelector(
  // TESTME
  [getBannerFormatDialogState, checkIfNoBanners],
  ({ isForBannerSetCreation }, noBanners) => isForBannerSetCreation || noBanners,
);

//Sagas
function* bannerFormatSelectorOpenRequested() {
  yield put(openSaveBannersDialogRequested({}));
  const { payload: open } = yield take(SAVE_BANNERS_IS_DONE);
  if (open) {
    yield put(openFormatSelectorDialog({ isForBannerSetCreation: true }));
  }
}

export function* saga() {
  yield takeLatest(BANNER_FORMAT_SELECTOR_OPEN_REQUESTED, bannerFormatSelectorOpenRequested);
}

//Reducer

export const initialState = {
  open: false,
  isUnfolded: false,
  isForBannerSetCreation: false,
  isForNewBannerCreation: false,
  isForChangingFormat: false,
  isForCopyingBanner: false,
  selectedFormats: bannerFormats.map(({ name }) => name),
  bannerSetName: initBannerSetName,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BANNER_FORMAT_SELECTOR_OPEN: {
      return {
        ...state,
        open: true,
        isUnfolded: false,
        bannerSetName: initBannerSetName,
        ...action.payload,
      };
    }
    case BANNER_FORMAT_TOGGLE_SELECT: {
      if (state.selectedFormats.includes(action.payload)) {
        return {
          ...state,
          selectedFormats: state.selectedFormats.filter(name => name !== action.payload),
        };
      }
      return {
        ...state,
        selectedFormats: [...state.selectedFormats, action.payload],
      };
    }
    case BANNER_FORMAT_TOGGLE_SELECT_ALL: {
      let selectedFormatsCopy = [...state.selectedFormats];
      for (let format of JSON.parse(action.payload.formats)) {
        if (action.payload.selectAll) {
          if (!selectedFormatsCopy.includes(format)) {
            selectedFormatsCopy = [...selectedFormatsCopy, format];
          }
        } else {
          selectedFormatsCopy = selectedFormatsCopy.filter(name => name !== format);
        }
      }
      return {
        ...state,
        selectedFormats: selectedFormatsCopy,
      };
    }
    case BANNER_FORMAT_SET_BANNERSET_NAME: {
      return {
        ...state,
        bannerSetName: action.payload,
      };
    }

    case BANNER_FORMAT_SELECTOR_UNFOLD: {
      return {
        ...state,
        isUnfolded: true,
      };
    }
    case BANNER_NEW:
    case BANNER_CHANGE_FORMAT:
    case BANNER_FORMAT_SELECTOR_CLOSE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
