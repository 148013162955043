import { bannerZipExtension, bannerSetZipExtension, playerZipExtension } from './importParams';

export const imageEndName = type => `thumbnail.${type}`
export const htmlName = 'index.html';
export const cssName = 'styles.css';
export const jsName = 'main.js';
export const bannerSetThumbnailName = type => imageEndName(type) // thumbnail.png or thumbnail.jpeg
export const bannerThumbnailName = (format, type) => `${format.width}_${format.height}_${imageEndName(type)}` // 300_250_thumbnail.png or 00_250_thumbnail.jpeg
export const playerName = format => `${format.width}_${format.height}${playerZipExtension}`; // 300_250.player.zip
export const bannerZipName = format => `${format.width}_${format.height}${bannerZipExtension}`; // 300_250.banner.zip
export const bannerSetZipName = name => `${name}${bannerSetZipExtension}`; // like NAME.bannerSet.zip;
export const resourcesFolderName = 'resources';
export const resourcesZipName = `${resourcesFolderName}.zip`; // resources.zip
export const originalResourcesFolderName = 'original-resources';
export const originalResourcesZipName = `${originalResourcesFolderName}.zip`; // resources.zip
export const fontsFolderName = 'fonts';
export const fontsZipName = `${fontsFolderName}.zip`; // fonts.zip
const metadata = 'metadata';
export const metadataJSONName = `${metadata}.json`;
export const metadataJSName = `${metadata}.js`;
