import { Map } from 'immutable';

export const unzipImages = async (zipFile, fileNameStartWith) =>
  await Promise.all(
    Map(zipFile.files)
      .valueSeq()
      .filter(file => !file.dir)
      .filter(file => file.name.startsWith(`${fileNameStartWith}/`))
      .toArray()
      .map(file => {
        const id = file.name.replace(/^[^/]+\/([^/]+)\/.*$/, '$1');
        return file.async('blob').then(blob => ({ id, blob }));
      }),
  );
