import { BannersRecord } from '../reference/records';
import { BANNERS_UPDATE } from './bannersActions';
import { getBannerMasterFromReducer } from './bannersSelectors';

export const updateDissociatedBannersState = reducer => (state, action) => {
  if (action.type !== BANNERS_UPDATE) return reducer(state, action);

  /*
  TARGET
  when the banner is dissociated, its history is not in bannerS reducer, but in banner_ reducer.
  but when there is still a master banner, when we undo something from the banner master, it changes the
  bannerS reducer, and therefore it could also change a dissociated banner.
  therefore, we need the bannerS reducer past to have only the latest dissociated banner version
  */
  const { bannerIndex, banner, isBannerDissociated } = action.payload;

  // no need if banner is not dissociated
  if (!isBannerDissociated) return reducer(state, action);

  // no need if there is no banner master
  const bannerMaster = getBannerMasterFromReducer({ banners: state });
  if (!bannerMaster) return reducer(state, action);

  const buildNew = pastOrFuture => {
    if (!pastOrFuture.length) return pastOrFuture;
    return pastOrFuture.map(timeFlies =>
      BannersRecord({
        ...timeFlies,
        banners: timeFlies.banners.map((ban, index) => (index === bannerIndex ? banner : ban)),
      }),
    );
  };

  return reducer(
    {
      ...state,
      past: buildNew(state.past),
      future: buildNew(state.future),
    },
    action,
  );
};
