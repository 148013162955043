import * as actions from './entitiesActions';

// Actions creators
export const openMoveDialog = () => ({
  type: actions.ENTITIES_OPEN_MOVE_DIALOG,
});

export const openSaveAsDialog = () => ({
  type: actions.ENTITIES_OPEN_SAVE_AS_DIALOG,
});

export const openShareDialog = () => ({
  type: actions.ENTITIES_OPEN_SHARE_DIALOG,
});

export const closemoveOrSaveAsDialog = () => ({
  type: actions.ENTITIES_CLOSE_DIALOG,
});

export const getEntitiesRequested = () => ({
  type: actions.ENTITIES_ENTITIES_REQUESTED,
});

export const successGetEntities = entities => ({
  type: actions.ENTITIES_GET_ENTITIES_SUCCESS,
  payload: entities,
});

export const failureGetEntities = () => ({
  type: actions.ENTITIES_GET_ENTITIES_FAILURE,
});

export const getDCORequested = () => ({
  type: actions.ENTITIES_DCO_REQUESTED,
});

export const successGetDCO = dco => ({
  type: actions.ENTITIES_GET_DCO_SUCCESS,
  payload: dco,
});

export const failureGetDCO = () => ({
  type: actions.ENTITIES_GET_DCO_FAILURE,
});

export const handleEntityClick = entityId => ({
  type: actions.ENTITIES_ENTITY_CLICK,
  payload: entityId,
});

export const toggleEntityPermission = giveReadPermission => ({
  type: actions.ENTITIES_TOGGLE_ENTITY_READ_PERMISSION,
  payload: {
    giveReadPermission,
  },
});

export const valideEntitiesPermissionsRequested = share => ({
  type: actions.ENTITIES_VALIDATE_PERMISSIONS_REQUESTED,
  payload: { share },
});

export const valideEntitiesPermissionsSuccess = () => ({
  type: actions.ENTITIES_VALIDATE_PERMISSIONS_SUCCESS,
});

export const validateMoveOrSaveAsRequested = saveAsName => ({
  type: actions.ENTITIES_VALIDATE_REQUESTED,
  payload: saveAsName,
});

export const getUserEntityRequested = () => ({
  type: actions.ENTITIES_USER_ENTITY_REQUESTED,
});

export const successGetUserOwnerEntity = entity => ({
  type: actions.ENTITIES_USER_ENTITY_SUCCESS,
  payload: entity,
});

export const failureGetUserOwnerEntity = () => ({
  type: actions.ENTITIES_USER_ENTITY_FAILURE,
});

export const setCustomTestChoice = choice => ({
  type: actions.ENTITIES_SET_CUSTOM_TEST_CHOICE,
  payload: choice,
});

export const setCustomTestEntity = entityId => ({
  type: actions.ENTITIES_SET_CUSTOM_TEST_ENTITY,
  payload: entityId,
});

export const validateMoveRequested = () => ({
  type: actions.ENTITIES_MOVE_REQUESTED,
});
