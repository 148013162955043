import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { Tooltip, ButtonBase } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import classNames from 'classnames';

import makeSelectorInstance from '../reference/makeSelectorInstance';
import { checkIfNoBanners } from '../shared-selectors/sharedSelectors';
import {
  showPropertyEditorElement,
  showPropertyEditorElements,
  showPropertyEditorBanner,
} from '../banner/bannerSelectors';
import { togglePropertyEditor, isPropertyEditorOpen } from '../layout/layoutDucks';
import { isBannerPlayingHTMLAnimation } from '../temporary-status/temporaryStatusDucks';
import { styles } from './propertyEditorStyles';
import PropertyEditorBanner from './PropertyEditorBanner';
import PropertyEditorOrCustomFieldsElement from './PropertyEditorOrCustomFieldsElement';
import PropertyEditorElements from './PropertyEditorElements';

class PropertyEditor extends React.PureComponent {
  onPropertiesToggleClick = () => this.props.togglePropertyEditor(!this.props.open);

  render() {
    const {
      classes,
      noBanners,
      showElement,
      showElements,
      showBanner,
      bannerIsPlayingHTMLAnimation,
      open,
      intl,
    } = this.props;

    return (
      <div className={classNames(classes.root, (!open || bannerIsPlayingHTMLAnimation) && classes.folded)}>
        <div className={classes.handle}>
          <Tooltip title={intl.formatMessage({ id: open ? 'properties.close' : 'properties.open' })} placement="left">
            <ButtonBase onClick={this.onPropertiesToggleClick}>
              {open ? <ArrowRightIcon /> : <ArrowLeftIcon />}
            </ButtonBase>
          </Tooltip>
        </div>
        {!noBanners && (
          <Fragment>
            {showBanner && <PropertyEditorBanner {...this.props} />}
            {showElement && <PropertyEditorOrCustomFieldsElement {...this.props} />}
            {showElements && <PropertyEditorElements {...this.props} />}
          </Fragment>
        )}
      </div>
    );
  }
}

const makeStateToProps = () => state => ({
  noBanners: makeSelectorInstance(checkIfNoBanners)(state),
  bannerIsPlayingHTMLAnimation: makeSelectorInstance(isBannerPlayingHTMLAnimation)(state),
  open: makeSelectorInstance(isPropertyEditorOpen)(state),
  showBanner: makeSelectorInstance(showPropertyEditorBanner)(state),
  showElement: makeSelectorInstance(showPropertyEditorElement)(state),
  showElements: makeSelectorInstance(showPropertyEditorElements)(state),
});

const dispatchToProps = {
  togglePropertyEditor,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(PropertyEditor);
