import { applyStyle, addTranslationKeyframe, addBlinkKeyframe } from '../../util/dom-helpers';

import renderText from './renderText';
import renderRectangle from './renderRectangle';
import renderButton from './renderButton';
import renderImage from './renderImage';

import './animations.css';
import { animationNamesWithParameter } from '../../../reference/transitions';

const renderElement = ({
  keyframes,
  element,
  slide,
  slideDelay = 0,
  slideDuration,
  isLastSlide = false,
  isFirstSlide = false,
  isLastSlideOfTour = false,
  isFirstSlideOfTour = false,
  bannerFormat,
}) => {
  const {
    x,
    y,
    width,
    height,
    rotation,
    transitionIn,
    transitionOut,
    duration,
    showOnAllSlides,
    transitionsOnAllSlides,
  } = element;

  const dom = document.createElement('div');
  dom.className = `element ${element.type}-container`;
  applyStyle(dom, {
    left: `${x}px`,
    top: `${y}px`,
    width: `${width}px`,
    height: `${height}px`,
    transform: `rotate(${-rotation}deg)`,
  });

  const f = slideDuration / slide.duration;

  const forceNoAnimationIn = !isFirstSlide && (showOnAllSlides && !transitionsOnAllSlides && !isFirstSlideOfTour);

  const forceNoAnimationOut = !isLastSlide && (showOnAllSlides && !transitionsOnAllSlides && !isLastSlideOfTour);

  let animation = {
    animationName: `${animationNamesWithParameter(
      transitionIn,
      bannerFormat.height,
      bannerFormat.width,
      false,
      forceNoAnimationIn,
    )}-in, ${animationNamesWithParameter(
      transitionOut,
      bannerFormat.height,
      bannerFormat.width,
      isLastSlide,
      forceNoAnimationOut,
    )}-out`,
    animationDelay: `${slideDelay + transitionIn.delay * f}s, ${slideDelay + (duration - transitionOut.duration) * f}s`,
    animationDuration: `${transitionIn.duration * f * !forceNoAnimationIn}s, ${transitionOut.duration *
      f *
      !forceNoAnimationOut}s`,
    animationFillMode: 'backwards, forwards',
    animationIterationCount: '1, 1',
  };

  applyStyle(dom, animation);
  element.blinkAnimationEnabled && addBlinkKeyframe(element.blinkAnimation.minOpacity, keyframes);
  transitionIn.type === 'translation' &&
    addTranslationKeyframe(transitionIn, bannerFormat.height, bannerFormat.width, keyframes);
  transitionOut.type === 'translation' &&
    addTranslationKeyframe(transitionOut, bannerFormat.height, bannerFormat.width, keyframes);

  switch (element.type) {
    case 'text':
      dom.appendChild(renderText(element));
      break;
    case 'rectangle':
      dom.appendChild(renderRectangle(element));
      break;
    case 'button':
      dom.appendChild(renderButton(element));
      break;
    case 'image':
      dom.appendChild(renderImage(element));
      break;
    default:
      console.error('Unknown element type', element.type);
  }
  return dom;
};

export default renderElement;
