import fetchNuxeo from './fetchNuxeo';

const fetchNuxeoBatchId = async () =>
  fetchNuxeo({
    path: '/upload',
    method: 'POST',
  })
    .then(res => res.json())
    .then(({ batchId }) => batchId)
    .catch(e => console.error("can't get a batch id", e));

export default fetchNuxeoBatchId;
