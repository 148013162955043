import { applyStyle, addBlinkAnimation } from '../../util/dom-helpers';

const renderRectangle = ({
  backgroundColor,
  borderWidth,
  borderColor,
  borderRadius,
  borderRadiusUnit,
  opacity,
  dropShadowEnabled,
  dropShadow,
  blinkAnimationEnabled,
  blinkAnimation,
}) => {
  const dom = document.createElement('div');
  dom.className = 'rectangle';

  applyStyle(dom, {
    backgroundColor,
    borderWidth: `${borderWidth}px`,
    borderColor,
    borderRadius: `${borderRadius}${borderRadiusUnit}`,
    opacity,
    ...addBlinkAnimation({ blinkAnimationEnabled, blinkAnimation }),
  });
  if (dropShadowEnabled) {
    dom.style.boxShadow = `${dropShadow.offsetX}px ${dropShadow.offsetY}px ${dropShadow.blurRadius}px ${
      dropShadow.color
    }`;
  }

  return dom;
};

export default renderRectangle;
