import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { blue, red, indigo } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles/colorManipulator';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { FormattedHTMLMessage } from 'react-intl';
import { compose } from 'recompose';

import { cancelAlert, confirmAlert, updateComment, getAlert } from './alertDucks';
import makeSelectorInstance from '../reference/makeSelectorInstance';

const styles = {
  blue: {
    color: blue[500],
    '&:hover': {
      backgroundColor: fade(blue[500], 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  red: {
    color: red[600],
    '&:hover': {
      backgroundColor: fade(red[600], 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  indigo: {
    color: indigo[500],
    '&:hover': {
      backgroundColor: fade(indigo[500], 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  text: {
    whiteSpace: 'pre-line',
  },
};

const isStandardColor = color => ['primary', 'secondary', 'inherit'].includes(color);

const Alert = ({
  classes,
  open,
  title,
  titleValues,
  actionLabel,
  actionColor,
  message,
  messageValues,
  withComment,
  commentLabel,
  commentLabelValues,
  commentRequired,
  comment,
  error,
  onCancel,
  onConfirm,
  onUpdateComment,
}) => (
  <Dialog open={open} onClose={onCancel}>
    {title && (
      <DialogTitle>
        <FormattedHTMLMessage id={title} values={titleValues} />
      </DialogTitle>
    )}
    <DialogContent>
      <DialogContentText className={classes.text}>
        <FormattedHTMLMessage id={message} values={messageValues} />
      </DialogContentText>
      {withComment && (
        <TextField
          autoFocus
          margin="dense"
          id="commentaire"
          label={<FormattedHTMLMessage id={commentLabel} values={commentLabelValues} />}
          fullWidth
          multiline
          value={comment}
          error={error}
          helperText={error && <FormattedHTMLMessage id="alert.comment.required" />}
          required={commentRequired}
          onChange={e => onUpdateComment(e.target.value)}
        />
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>
        <FormattedHTMLMessage id="alert.cancel" />
      </Button>
      <Button
        onClick={onConfirm}
        color={isStandardColor(actionColor) ? actionColor : 'default'}
        className={actionColor && !isStandardColor(actionColor) && classes[actionColor] ? classes[actionColor] : ''}
        autoFocus
      >
        <FormattedHTMLMessage id={actionLabel} />
      </Button>
    </DialogActions>
  </Dialog>
);

const makeStateToProps = () => {
  const stateToProps = state => {
    const {
      open,
      title,
      titleValues,
      actionLabel,
      actionColor,
      message,
      messageValues,
      withComment,
      commentLabel,
      commentLabelValues,
      commentRequired,
      comment,
      error,
    } = makeSelectorInstance(getAlert)(state);
    return {
      open,
      title,
      titleValues,
      actionLabel,
      actionColor,
      message,
      messageValues,
      withComment,
      commentLabel,
      commentLabelValues,
      commentRequired,
      comment,
      error,
    };
  };
  return stateToProps;
};

const dispatchToProps = {
  onCancel: cancelAlert,
  onConfirm: confirmAlert,
  onUpdateComment: updateComment,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  withStyles(styles),
  React.memo,
)(Alert);
