export const setBgProperties = from => (_, { x, y, picWhileCroppingProperties }) => {
  return {
    backgroundProperties: {
      x: picWhileCroppingProperties.x - x,
      y: picWhileCroppingProperties.y - y,
      width: picWhileCroppingProperties.width,
      height: picWhileCroppingProperties.height,
    },
  };
};
