import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { getSelectedElementDropShadowBlurRadius } from '../../banner/bannerSelectors';
import { updateElementShadow } from '../../banner/bannerActionsCreators';
import { pixelEndornment } from '../constants';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementDropShadowBlurRadius extends React.Component {
  onShadowBlurRadiusChange = ({ value: blurRadius, id }) => this.props.updateElementShadow({ blurRadius, id }); //tracked

  render() {
    const { id, blurRadius, intl } = this.props;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        label={intl.formatMessage({ id: 'properties.shadow.blur-radius' })}
        value={blurRadius}
        fullWidth
        InputProps={pixelEndornment}
        onChangeMethod={this.onShadowBlurRadiusChange}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  blurRadius: makeSelectorInstance(getSelectedElementDropShadowBlurRadius)(state),
});

const dispatchToProps = {
  updateElementShadow,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
)(ElementDropShadowBlurRadius);
