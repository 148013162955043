import { corners } from '../../../reference/corners';

export const calculateLockAspectRatioDiagonalPerpendicular = ({ minCursorPosition, phi, ALPHA, movingCorner }) => {
  switch (movingCorner) {
    case corners.topRight:
      return {
        top: minCursorPosition.y,
        left: minCursorPosition.x - 500,
        width: 1000,
        height: 0,
        transform: `rotate(${-(ALPHA + phi + Math.PI / 2)}rad)`, //trigo is anti-clockwise, CSS is clockwise
      };
    case corners.topLeft:
      return {
        top: minCursorPosition.y,
        left: minCursorPosition.x - 500,
        width: 1000,
        height: 0,
        transform: `rotate(${-(ALPHA - phi - Math.PI / 2)}rad)`, //trigo is anti-clockwise, CSS is clockwise
      };
    case corners.bottomRight:
      return {
        top: minCursorPosition.y,
        left: minCursorPosition.x - 500,
        width: 1000,
        height: 0,
        transform: `rotate(${-(ALPHA - phi + Math.PI / 2)}rad)`, //trigo is anti-clockwise, CSS is clockwise
      };
    case corners.bottomLeft:
      return {
        top: minCursorPosition.y,
        left: minCursorPosition.x - 500,
        width: 1000,
        height: 0,
        transform: `rotate(${-(ALPHA + phi - Math.PI / 2)}rad)`, //trigo is anti-clockwise, CSS is clockwise
      };
    case corners.middleTop:
      return {
        top: minCursorPosition.y,
        left: minCursorPosition.x - 500,
        width: 1000,
        height: 0,
        transform: `rotate(${-ALPHA}rad)`, //trigo is anti-clockwise, CSS is clockwise
      };
    case corners.middleBottom:
      return {
        top: minCursorPosition.y,
        left: minCursorPosition.x - 500,
        width: 1000,
        height: 0,
        transform: `rotate(${-(ALPHA + Math.PI)}rad)`, //trigo is anti-clockwise, CSS is clockwise
      };
    case corners.middleRight:
      return {
        top: minCursorPosition.y,
        left: minCursorPosition.x - 500,
        width: 1000,
        height: 0,
        transform: `rotate(${-(ALPHA + Math.PI / 2)}rad)`, //trigo is anti-clockwise, CSS is clockwise
      };
    case corners.middleLeft:
      return {
        top: minCursorPosition.y,
        left: minCursorPosition.x - 500,
        width: 1000,
        height: 0,
        transform: `rotate(${-(ALPHA + (3 * Math.PI) / 2)}rad)`, //trigo is anti-clockwise, CSS is clockwise
      };

    default:
      return null;
  }
};
