import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import elementIcons from '../reference/elementIcons';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { openMoveDialog, openSaveAsDialog, openShareDialog } from '../entities/entitiesActionCreators';
import { checkIfNoBanners, isUserAuthenticated } from '../shared-selectors/sharedSelectors';
import {
  isBannerSetSavable,
  isBannerSetMovable,
  isBannerSetSharable,
  isBannerSetSaveAsable,
} from '../banners/bannersSelectors';
import { isNoEntity } from '../entities/entitiesSelectors';
import { openOpenBannerSetDialogRequested } from '../open-banner-set/openBannerSetDucks';
import { openExportDialog } from '../export/exportDucks';
import { openFormatSelectorDialogRequested } from '../banner-format-selector/bannerFormatSelectorDucks';
import { saveBannerSetToNuxeo } from '../save-banners-dialog/saveBannersDucks';
import MenuIconButton from './MenuIconButton';

const hasRole = (roles, security) => {
  if (!security || !isUserAuthenticated({ security })) {
    return false;
  }
  return roles.some(role => security.user.roles.includes(role));
};

const MenuFileButtons = ({
  handleExportBannerSet,
  handleNewBannerSet,
  handleOpenBannerSet,
  handleSaveBannerSet,
  handleImportBannerSet,
  handleMoveBannerSet,
  handleSaveAsBannerSet,
  handleShareBannerSet,
  intl,
  noBanner,
  savable,
  movable,
  sharable,
  saveAsable,
  hasNoEntity,
  security,
}) => {
  const hasWriteProfile = hasRole(['CREATIVE_WRITE'], security);

  return (
    <div>
      <MenuIconButton
        iconComponent={elementIcons.newBannerSet}
        onClick={handleNewBannerSet}
        tooltip={intl.formatMessage({ id: 'app.menu.bannerSetNew' })}
        label={intl.formatMessage({ id: 'app.menu.bannerSetNew.label' })}
        disabled={noBanner || hasNoEntity || !hasWriteProfile}
      />
      <MenuIconButton
        iconComponent={elementIcons.openBannerSet}
        onClick={handleOpenBannerSet}
        tooltip={intl.formatMessage({ id: 'app.menu.bannerOpen' })}
        label={intl.formatMessage({ id: 'app.menu.bannerOpen.label' })}
        disabled={noBanner || !hasWriteProfile}
      />
      <MenuIconButton
        iconComponent={elementIcons.saveBannerSet}
        onClick={handleSaveBannerSet}
        tooltip={intl.formatMessage({ id: 'app.menu.bannerSave' })}
        label={intl.formatMessage({ id: 'app.menu.bannerSave.label' })}
        disabled={noBanner || !savable || !hasWriteProfile}
      />
      <MenuIconButton
        iconComponent={elementIcons.saveAsBannerSet}
        onClick={handleSaveAsBannerSet}
        tooltip={intl.formatMessage({ id: 'app.menu.bannerSaveAs' })}
        label={intl.formatMessage({ id: 'app.menu.bannerSaveAs.label' })}
        disabled={noBanner || !saveAsable || !hasWriteProfile}
      />
      <MenuIconButton
        iconComponent={elementIcons.moveBannerSet}
        onClick={handleMoveBannerSet}
        tooltip={intl.formatMessage({ id: 'app.menu.bannerMove' })}
        label={intl.formatMessage({ id: 'app.menu.bannerMove.label' })}
        disabled={noBanner || !movable || !hasWriteProfile}
      />
      <MenuIconButton
        iconComponent={elementIcons.shareBannerSet}
        onClick={handleShareBannerSet}
        tooltip={intl.formatMessage({ id: 'app.menu.bannerShare' })}
        label={intl.formatMessage({ id: 'app.menu.bannerShare.label' })}
        disabled={noBanner || !sharable || !hasWriteProfile}
      />
      <MenuIconButton
        iconComponent={elementIcons.importBannerSet}
        onClick={handleImportBannerSet}
        tooltip={intl.formatMessage({ id: 'app.menu.bannerImport' })}
        label={intl.formatMessage({ id: 'app.menu.bannerImport.label' })}
        disabled={noBanner || !hasWriteProfile}
      />
      <MenuIconButton
        iconComponent={elementIcons.exportBannerSet}
        onClick={handleExportBannerSet}
        tooltip={intl.formatMessage({ id: 'app.menu.bannerExport' })}
        label={intl.formatMessage({ id: 'app.menu.bannerExport.label' })}
        disabled={noBanner || !hasWriteProfile}
      />
    </div>
  );
};

const makeStateToProps = () => state => ({
  noBanner: makeSelectorInstance(checkIfNoBanners)(state),
  savable: makeSelectorInstance(isBannerSetSavable)(state),
  movable: makeSelectorInstance(isBannerSetMovable)(state),
  sharable: makeSelectorInstance(isBannerSetSharable)(state),
  saveAsable: makeSelectorInstance(isBannerSetSaveAsable)(state),
  hasNoEntity: makeSelectorInstance(isNoEntity)(state),
  security: makeSelectorInstance(state => state.security)(state),
});

const dispatchToProps = {
  handleNewBannerSet: openFormatSelectorDialogRequested,
  handleSaveBannerSet: saveBannerSetToNuxeo,
  handleOpenBannerSet: openOpenBannerSetDialogRequested,
  handleMoveBannerSet: openMoveDialog,
  handleSaveAsBannerSet: openSaveAsDialog,
  handleShareBannerSet: openShareDialog,
  handleImportBannerSet: () => openOpenBannerSetDialogRequested(null, true),
  handleExportBannerSet: openExportDialog,
};

export default compose(connect(makeStateToProps, dispatchToProps), injectIntl, React.memo)(MenuFileButtons);
