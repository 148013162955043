import { transitionTypesVar } from '../../reference/transitions';

export const updateTransitionDurationWhenChangingTransitionFromNoneToOther = ({
  prevElement,
  currentElement,
  setElementTransitionInDuration,
  setElementTransitionOutDuration,
}) => {
  if (
    prevElement.transitionIn.type === transitionTypesVar.none &&
    currentElement.transitionIn.type !== transitionTypesVar.none &&
    currentElement.duration - currentElement.transitionIn.delay - currentElement.transitionOut.duration > 1
  ) {
    setElementTransitionInDuration({ id: currentElement.id, duration: 1, untrack: true });
  }
  /*init duration for transition out from 'none' to any other one*/
  if (
    prevElement.transitionOut.type === transitionTypesVar.none &&
    currentElement.transitionOut.type !== transitionTypesVar.none &&
    currentElement.duration - currentElement.transitionIn.delay - currentElement.transitionIn.duration > 1
  ) {
    setElementTransitionOutDuration({ id: currentElement.id, duration: 1, untrack: true });
  }
};
