import React from 'react';
import classNames from 'classnames';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { getBannerDurationNoRepetitions, getBannerNumberOfDifferentImages } from '../banner/bannerSelectors';
import { getBannerSize } from '../banner/bannerSelectorsShared';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { maxBannerDuration, maxNumberImages } from '../reference/bannerIABParams';

const height = 20;

const styles = theme => ({
  snackBarRoot: {
    position: 'fixed',
    width: 200,
  },
  snackBarContent: {
    minWidth: 0,
    backgroundColor: theme.palette.error.main,
    position: 'fixed',
    top: 0,
  },
  message: {
    padding: 0,
  },
  oneError: {
    height,
  },
  twoErrors: {
    height: 2 * height,
  },
  threeErrors: {
    height: 3 * height,
  },
});

const CriticalError = ({ classes, duration, numberOfImages, bannerSize }) => {
  const durationError = duration > maxBannerDuration;
  const tooManyImages = numberOfImages > maxNumberImages;
  const tooBig = bannerSize.size > bannerSize.maxSize;
  const errors = durationError + tooManyImages + tooBig;
  return (
    (durationError || tooManyImages || tooBig) && (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ root: classes.snackBarRoot }}
        open={durationError || tooManyImages || tooBig}
        autoHideDuration={6000}
        ContentProps={{
          'aria-describedby': 'message-id',
          classes: {
            root: classNames(
              classes.snackBarContent,
              errors === 1 && classes.oneError,
              errors === 2 && classes.twoError,
              errors === 3 && classes.threeError,
            ),
            message: classes.message,
          },
        }}
        message={
          <span id="message-id">
            {durationError && (
              <FormattedMessage id="app.menu.animationDurationWarning" values={{ max: maxBannerDuration }} />
            )}
            {((durationError && tooManyImages) || (durationError && tooBig)) && <br />}
            {tooManyImages && <FormattedMessage id="app.menu.tooManyImagesWarning" values={{ max: maxNumberImages }} />}
            {tooBig && tooManyImages && <br />}
            {tooBig && <FormattedMessage id="app.menu.tooBig" values={{ max: bannerSize.maxSize }} />}
          </span>
        }
      />
    )
  );
};

const makeStateToProps = () => {
  const stateToProps = state => {
    const duration = makeSelectorInstance(getBannerDurationNoRepetitions)(state);
    const numberOfImages = makeSelectorInstance(getBannerNumberOfDifferentImages)(state);
    const bannerSize = makeSelectorInstance(getBannerSize)(state);
    return {
      duration,
      numberOfImages,
      bannerSize,
    };
  };
  return stateToProps;
};

export default compose(
  connect(makeStateToProps),
  withStyles(styles),
  React.memo,
)(CriticalError);
