export const checkPrevAndCurrentSituation = ({
  prevElement = null,
  currentElement = null,
  prevElements = null,
  currentElements = null,
}) => {
  /* CASES : what is PropertyEditor is showing before ? what it is showing now ?*/
  const wasShowingElement = prevElement !== null;
  const nowShowingElement = currentElement !== null;
  const wasShowingElements = prevElements !== null && prevElements.size > 0;
  const nowShowingElements = currentElements !== null && currentElements.size > 0;
  const nowNotShowingElement = !nowShowingElement;
  const nowNotShowingElements = !nowShowingElements;
  /*1. if it was showing the properties of an element*/
  /*1.1 if it is showing the properties of the same element than before*/
  const showingSameElement = wasShowingElement && nowShowingElement && prevElement.id === currentElement.id;
  const showingSameElements =
    wasShowingElements &&
    nowShowingElements &&
    prevElements.filter((el, ind) => currentElements.get(ind) && el.id === currentElements.get(ind).id).size ===
      prevElements.size &&
    currentElements.filter((el, ind) => prevElements.get(ind) && el.id === prevElements.get(ind).id).size ===
      currentElements.size;
  /*1.2 if it is showing the properties of a different element than before*/
  const showingDifferentElement = wasShowingElement && nowShowingElement && prevElement.id !== currentElement.id;
  const showingDifferentElements =
    wasShowingElements &&
    nowShowingElements &&
    (prevElements.filter((el, ind) => currentElements.get(ind) && el.id === currentElements.get(ind).id).size !==
      prevElements.size ||
      currentElements.filter((el, ind) => prevElements.get(ind) && el.id === prevElements.get(ind).id).size !==
        currentElements.size);
  /*1.3 if it is not showing the properties of any element*/
  const notShowingElementAnymore = wasShowingElement && nowNotShowingElement;
  const notShowingElementsAnymore = wasShowingElements && nowNotShowingElements;
  /*1.4 if it is not showing the properties of the element anymore, but we don't care what it is showing now*/
  const notShowingThePrevElement = showingDifferentElement || notShowingElementAnymore;
  const notShowingThePrevElements = showingDifferentElements || notShowingElementsAnymore;
  /*2. if it wasn't showing the properties of an element*/
  /*1.1 if it is now showing the properties of an element*/
  // const beforeNotElementNowElement = wasNotShowingElement && nowShowingElement;
  /*1.3 if it is not showing the properties of any element*/
  // const stillNotShowingElement = wasNotShowingElement && nowNotShowingElement;

  return {
    wasShowingElement,
    wasShowingElements,
    nowShowingElement,
    nowShowingElements,
    nowNotShowingElement,
    nowNotShowingElements,
    showingSameElement,
    showingSameElements,
    showingDifferentElement,
    showingDifferentElements,
    notShowingElementAnymore,
    notShowingElementsAnymore,
    notShowingThePrevElement,
    notShowingThePrevElements,
  };
};
