import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Menu, MenuItem, Divider, withStyles } from '@material-ui/core';
import MenuCopyToSlides from './MenuCopyToSlides';
import { MenuRight } from 'mdi-material-ui';
import { compose } from 'recompose';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getSelectedBannerIndex, getBannerPresent } from '../shared-selectors/sharedSelectors';
import { getPastableBanners } from '../banners/bannersSelectors';
import { formatI18nBannerNameId } from '../banner/bannerUtils'

const styles = theme => ({
  menuSelected: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
    },
    '&$menuSelected': {
      backgroundColor: fade(theme.palette.primary.main, 0.8),
      color: '#FFF',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
      },
    },
  },
});

class MenuCopyToBanners extends React.Component {
  state = {
    menuAnchorBanner: null,
    menuBanner: null,
    classesState: {
      menuItem: { root: this.props.classes.menuItem },
      menuItemSelectable: { selected: this.props.classes.menuSelected, root: this.props.classes.menuItem },
    },
  };

  onBannerClick = (menuBanner, bannerIndex) => e => {
    e.stopPropagation();
    //set the position of the slide menu
    const { top, left, width } = e.currentTarget.getBoundingClientRect();
    const menuAnchorBanner = document.createElement('div');
    menuAnchorBanner.style.transform = 'translateX(100%)';
    menuAnchorBanner.style.visibility = 'hidden';
    menuAnchorBanner.style.pointerEvents = 'none';
    menuAnchorBanner.style.zIndex = 1000000;
    menuAnchorBanner.style.position = 'fixed';
    menuAnchorBanner.style.top = `${top + 30}px`;
    menuAnchorBanner.style.left = `${left + width}px`;

    this.menuAnchorBanner = menuAnchorBanner;
    document.body.appendChild(this.menuAnchorBanner);
    this.setState({ menuAnchorBanner, menuBanner, bannerIndex });
  };

  onMenuClose = () => {
    try {
      this.setState({ menuAnchorBanner: null, menuBanner: null, bannerIndex: null });
      this.menuAnchorBanner && document.body.removeChild(this.menuAnchorBanner);
      this.menuAnchorBanner = null;
      this.props.onMenuClose();
    } catch (e) {
      console.error(e);
      this.setState({ menuAnchorBanner: null, menuBanner: null, bannerIndex: null });
    }
  };

  onDuplicateClick = ({ id, slideIndex }) => {
    this.props.onDuplicateClick({
      id,
      slideIndex,
      oldSlideIndex: this.props.selectedSlideIndex,
      bannerIndex: this.state.bannerIndex,
      oldBannerIndex: this.props.selectedBannerIndex,
    });
  };

  render() {
    const { menuAnchorEl, banners, currentBanner, selectedBannerIndex, selectedSlideIndex, id, intl } = this.props;

    const { menuAnchorBanner, menuBanner, classesState } = this.state;
    const menuBannerIsNotCurrentBanner = menuBanner !== currentBanner;

    return (
      <React.Fragment>
        <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={this.onMenuClose}>
          <MenuItem classes={classesState.menuItem} onClick={this.onBannerClick(currentBanner, selectedBannerIndex)}>
            <FormattedMessage id="app.elements.duplicate.currentBanner" />
            <MenuRight />
          </MenuItem>
          <Divider />
          {banners.map(banner => {
            return (
              <MenuItem
                classes={classesState.menuItemSelectable}
                key={banner.name}
                onClick={this.onBannerClick(banner.present, banner.index)}
              >
                {intl.formatMessage({ id: 'app.elements.duplicate.to-banner' }, { bannerName: formatI18nBannerNameId(intl, banner.name)})}
                <MenuRight />
              </MenuItem>
            );
          })}
        </Menu>
        <MenuCopyToSlides
          menuAnchorEl={menuAnchorBanner}
          id={id}
          copyToOtherBanners={menuBannerIsNotCurrentBanner}
          nbSlides={menuBanner && menuBanner.slides.size}
          selectedSlideIndex={!menuBannerIsNotCurrentBanner && selectedSlideIndex}
          onDuplicateClick={this.onDuplicateClick}
          onMenuClose={this.onMenuClose}
        />
      </React.Fragment>
    );
  }
}

const makeStateToProps = () => state => ({
  banners: makeSelectorInstance(getPastableBanners)(state),
  currentBanner: makeSelectorInstance(getBannerPresent)(state),
  selectedBannerIndex: makeSelectorInstance(getSelectedBannerIndex)(state),
});

export default compose(
  connect(makeStateToProps),
  injectIntl,
  withStyles(styles),
)(MenuCopyToBanners);
