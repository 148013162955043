export const iconSize = 20;
export const borderBoxWidth = 1;
export const borderRootWidth = 0;
export const showDots = false;
export const handleSize = 7;
export const borderSize = 50;

const styles = theme => ({
  root: {
    position: 'absolute',
    display: 'flex',
    border: `${borderRootWidth}px solid black`,
    pointerEvents: 'none',
    boxSizing: 'border-box',
    '& *': {
      boxSizing: 'border-box',
    },
    zIndex: 10,
  },
  rootTransformed: {
    width: '100%',
    height: '100%',
    pointerEvents: 'initial',
    position: 'relative',
    zIndex: 10,
  },
  corner: {
    position: 'absolute',
    width: handleSize,
    height: handleSize,
    backgroundColor: 'white',
    border: '1px solid black',
    // cursor: '-webkit-grab',
    display: 'none',
    zIndex: 1000,
  },
  hideCorner: {
    visibility: 'hidden',
    pointerEvents: 'none',
  },
  lockAspectRatioDiagonal: {
    position: 'absolute',
    zIndex: 1000,
    display: 'block',
    borderTop: '1px dashed rgba(232,206,42,1.00)',
  },
  insideBorders: {
    height: '100%',
    width: '100%',
    border: '1px dashed #000',
    boxSizing: 'border-box',
  },
  box: {
    boxSizing: 'border-box',
  },
  active: {
    cursor: 'move',
    // outline: `${borderBoxWidth}px dashed #000`, -> not worinking properly in Firefox
    '& $corner': {
      display: 'block',
    },
    //working properly everywhere
    '&:before': {
      content: '""',
      position: 'absolute',
      border: `${borderBoxWidth}px dashed #000`,
      /*adjust if needed if using border-box*/
      top: -borderBoxWidth,
      right: -borderBoxWidth,
      bottom: -borderBoxWidth,
      left: -borderBoxWidth,
      zIndex: -1,
    },
  },
  addPadding: {
    marginTop: -5,
    padding: 5,
  },
  isCropping: {
    zIndex: 9,
    '&$active': {
      zIndex: 10,
    },
    '& $corner': {
      backgroundColor: 'black',
      borderColor: 'white',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      border: `${borderBoxWidth}px dashed #000`,
      /*adjust if needed if using border-box*/
      top: -borderBoxWidth,
      right: -borderBoxWidth,
      bottom: -borderBoxWidth,
      left: -borderBoxWidth,
      zIndex: -1,
    },
    boxShadow: `${theme.shadows[2]}, 0 0 0 2000px rgba(255, 255, 255, 0.75)`,
  },
  centerDot: {
    position: 'absolute',
    backgroundColor: showDots ? 'red' : 'transparent',
    pointerEvents: 'none',
    width: showDots ? 10 : 0,
    height: showDots ? 10 : 0,
    zIndex: 10000,
  },
  topLeftOrigin: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: showDots ? 10 : 0,
    height: showDots ? 10 : 0,
    backgroundColor: showDots ? 'blue' : 'transparent',
    pointerEvents: 'none',
    zIndex: 100000,
  },
  boxBottomRight: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: showDots ? 10 : 0,
    height: showDots ? 10 : 0,
    backgroundColor: showDots ? 'green' : 'transparent',
    pointerEvents: 'none',
    zIndex: 100000,
  },
  topLeft: {
    top: -handleSize / 2,
    left: -handleSize / 2,
  },
  topRight: {
    top: -handleSize / 2,
    right: -handleSize / 2 + borderBoxWidth,
  },
  bottomLeft: {
    bottom: -handleSize / 2 + borderBoxWidth,
    left: -handleSize / 2,
  },
  bottomRight: {
    bottom: -handleSize / 2 + borderBoxWidth,
    right: -handleSize / 2 + borderBoxWidth,
  },
  middleTop: {
    top: -handleSize / 2,
    left: `calc(50% - ${handleSize / 2}px)`,
  },
  middleTopAbove: {
    left: `calc(50% - ${handleSize / 2}px)`,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: handleSize - borderBoxWidth,
      borderRight: '1px solid black',
      height: 20 - (3 * handleSize) / 2,
      width: 0,
      left: '50%',
    },
  },
  middleRight: {
    right: -handleSize / 2 + borderBoxWidth,
    top: `calc(50% - ${handleSize / 2}px)`,
  },
  middleBottom: {
    bottom: -handleSize / 2 + borderBoxWidth,
    left: `calc(50% - ${handleSize / 2}px)`,
  },
  middleLeft: {
    left: -handleSize / 2,
    top: `calc(50% - ${handleSize / 2}px)`,
  },
});

export default styles;
