import { animationNames, transitionTypesVar, defineDistance } from '../../reference/transitions';

export const setElementSnapshotAnimationProperties = (framesPerSecond, frameNumber) => (
  _,
  {
    isLastSlide,
    isFirstSlide,
    slideDuration,
    slideDurationForAnimation,
    animationDelay,
    transitionIn,
    transitionOut,
    duration,
    snapshotCurrentScene,
    bannerHeight,
    bannerWidth,
    showOnAllSlides,
    transitionsOnAllSlides,
  },
) => {
  if (snapshotCurrentScene) {
    return { animation: undefined };
  }

  const noAnimationIn = showOnAllSlides && !transitionsOnAllSlides && !isFirstSlide;
  const noAnimationOut = showOnAllSlides && !transitionsOnAllSlides && !isLastSlide;

  const animationTimeLine = (1 / framesPerSecond) * frameNumber;
  //cross-fade stretch factor : if the slide undergoes a cross-fade transition, its duration increases
  //   => we have to apply this increase proportionnaly to the slide duration
  const f = slideDurationForAnimation / slideDuration;

  let animationOutName;
  if (isLastSlide && transitionOut.type === transitionTypesVar.none && slideDuration === duration) {
    animationOutName = transitionTypesVar.lastSlide;
  } else if (noAnimationOut) {
    animationOutName = transitionTypesVar.none;
  } else {
    animationOutName = `${animationNames(transitionOut)}-out`;
  }

  let animationInName;
  if (noAnimationIn) {
    animationInName = transitionTypesVar.none;
  } else {
    animationInName = `${animationNames(transitionIn)}-in`;
  }

  const animationDelayIn = animationDelay + transitionIn.delay * f * !noAnimationIn;
  const animationDelayOut = animationDelay + (duration - transitionOut.duration * !noAnimationOut) * f;

  let animationInSnapshot = -0.01; // to have the element positionned while wiating to start animation
  let animationOutSnapshot = 0.01; // if set to 0, the effect above doesn't work

  if (animationTimeLine >= animationDelayIn) {
    animationInSnapshot = -animationTimeLine + animationDelayIn;
  }
  if (animationTimeLine >= animationDelayOut) {
    animationOutSnapshot = -animationTimeLine + animationDelayOut;
  }

  const animation = {
    animationName: `${animationInName}, ${animationOutName}`,
    animationDelay: `${Math.round(animationInSnapshot * 1000) / 1000}s, ${Math.round(animationOutSnapshot * 1000) /
      1000}s`,
    animationDuration: `${transitionIn.duration * f * !noAnimationIn}s, ${transitionOut.duration *
      f *
      !noAnimationOut}s`,
    animationIterationCount: '1, 1',
    animationTimingFunction: 'linear, linear',
    animationFillMode: 'both, forwards',
    animationDirection: 'normal, normal',
    animationPlayState: 'paused, paused',
    '--marginTranslationIn': `${defineDistance(
      transitionIn,
      bannerHeight,
      bannerWidth,
      transitionIn.defaultDistance,
    )}px`,
    '--marginTranslationOut': `${defineDistance(
      transitionOut,
      bannerHeight,
      bannerWidth,
      transitionOut.defaultDistance,
    )}px`,
  };
  return {
    animation,
  };
};
