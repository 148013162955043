import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import elementIcons from '../reference/elementIcons';

const styles = theme => ({
  root: {
    boxShadow: 'none',
    margin: 0,
    borderRadius: '0px !important',
    border: '1px solid transparent',
    background: 'transparent',
    '&:before': {
      display: 'none',
    },
  },
  summary: {
    borderTop: '1px solid #DDD',
    padding: `0 0 0 ${theme.spacing(3)}px`,
    height: theme.spacing(6),
    minHeight: theme.spacing(6),
    '&$summaryExpanded': {
      height: theme.spacing(6),
      minHeight: theme.spacing(6),
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        right: 0,
        height: 0,
        width: `calc(100% -  ${theme.spacing(3)}px)`,
        borderBottom: '1px solid #DDD',
      },
    },
    '& h4': {
      fontSize: 14,
      fontWeight: 700,
    },
  },
  summaryContent: {
    '&$summaryExpanded': {
      margin: 0,
    },
  },
  summaryExpanded: {},
  summaryExpandIcon: {
    right: 0,
    marginRight: 0,
  },
  details: {
    padding: theme.spacing(3),
    '&> div': {
      overflowX: 'unset !important',
    },
  },
});

class ExpansionPanel extends React.Component {
  state = {
    expanded: null,
    disabled: null,
    classes: null,
  };

  static getDerivedStateFromProps({ classes, expanded }, prevState) {
    if (!prevState.classes) {
      return {
        ...prevState,
        classes: {
          root: classes.summary,
          content: classes.summaryContent,
          expanded: classes.summaryExpanded,
          expandIcon: classes.summaryExpandIcon,
        },
        expanded,
        disabled: !expanded,
      };
    }
    return prevState;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({
        expanded: this.props.expanded,
        disabled: !this.props.expanded,
      });
    }
  }

  handleChange = () => {
    this.setState(({ expanded }) => ({ expanded: !expanded }));
  };

  render() {
    const { classes, title, children } = this.props;
    const { expanded, disabled } = this.state;
    return (
      <MuiExpansionPanel className={classes.root} expanded={expanded} disabled={disabled} onChange={this.handleChange}>
        <ExpansionPanelSummary expandIcon={elementIcons.expandMoreIcon} classes={this.state.classes}>
          <Typography variant="subtitle1" component="h4">
            {title}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>{children}</ExpansionPanelDetails>
      </MuiExpansionPanel>
    );
  }
}

ExpansionPanel.propTypes = {
  expanded: PropTypes.bool,
};

ExpansionPanel.defaultProps = {
  expanded: true,
};

export default withStyles(styles)(ExpansionPanel);
