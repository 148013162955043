import { put, call, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { setElementOptimizedImageResource } from '../banner/bannerActionsCreators';
import { BANNER_CLEAR_STATE } from '../banner/bannerActions';
import { BANNERS_CLOSE_ALL } from '../banners/bannersActions';
import { getBannersUsedResources } from '../banners/bannersSelectors';
import { backupBannerSaga } from '../banners/bannersSaga';
import { setBgProperties } from '../element/interactions-helpers';
import { objectUrlToBlob } from '../export/import-export-helpers';
import { domtoimageExecutionContext } from '../export/exportHelpers';
import domtoimage from 'dom-to-image';

// Actions

export const RESOURCE_ADD_REQUESTED = 'adbuilder/RESOURCE_ADD_REQUESTED';
const RESOURCE_CREATE_OPTIMIZED_REQUESTED = 'adbuilder/RESOURCE_CREATE_OPTIMIZED_REQUESTED';
const RESOURCE_ADD_SUCCESS = 'adbuilder/RESOURCE_ADD_SUCCESS';
const RESOURCE_UPDATE_SUCCESS = 'adbuilder/RESOURCE_UPDATE_SUCCESS';
const RESOURCE_DELETE = 'adbuilder/RESOURCE_DELETE';
const RESOURCES_CLEAN_UNUSED_REQUESTED = 'adbuilder/RESOURCES_CLEAN_UNUSED_REQUESTED';

// Utils

let resourceUrls = {};
let resourceSizes = {};
let resourcesSave = {};

export const getResourceUrl = (id, from, noCache = false) => {
  if (noCache) {
    const resource = resourcesSave[id];
    if (typeof resource === 'string') {
      return URL.createObjectURL(b64toBlob(resource));
    } else {
      return URL.createObjectURL(resource);
    }
  }

  return resourceUrls[id];
};

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = Array.from({ length: slice.length });
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

const cleanUrlsAndSizes = id => {
  if (resourceUrls[id] && URL.revokeObjectURL) {
    URL.revokeObjectURL(resourceUrls[id]);
  }
  delete resourceUrls[id];
  delete resourceSizes[id];
};

const createDomImage = ({ width, height, x, y, resourceId, picWhileCroppingProperties }) => {
  try {
    const dom = document.createElement('div');
    dom.style.width = `${width}px`;
    dom.style.height = `${height}px`;
    const { backgroundProperties: bgProps } = setBgProperties('createDomImage')(null, {
      x,
      y,
      picWhileCroppingProperties,
    });
    dom.style.background = `${bgProps.x}px ${bgProps.y}px / ${bgProps.width}px ${
      bgProps.height
    }px no-repeat url(${getResourceUrl(resourceId, 'createDomImage')})`;
    document.body.append(dom);
    return dom;
  } catch (e) {
    console.error('createDomImage', e);
    return null;
  }
};

function hasAlpha(pixelData) {
  for (let i = 3, n = pixelData.length; i < n; i += 4) {
    if (pixelData[i] < 255) {
      return true;
    }
  }

  return false;
}

// Action creators

export const addResource = (file, successAction, id) => ({
  type: RESOURCE_ADD_REQUESTED,
  payload: { file, successAction, id },
});

export const createOptimizedResource = payload => ({
  type: RESOURCE_CREATE_OPTIMIZED_REQUESTED,
  payload,
});

const addedResource = (id, base64) => ({
  type: RESOURCE_ADD_SUCCESS,
  payload: {
    id,
    data: base64,
  },
});

export const deleteResource = id => ({
  type: RESOURCE_DELETE,
  payload: id,
});

export const cleanUnusedResources = () => ({
  type: RESOURCES_CLEAN_UNUSED_REQUESTED,
});

// Selectors
export const getResourceSize = id => resourceSizes[id];
const getResources = ({ resources }) => Object.keys(resources);
export const getBestResourceId = (optimizedResourceId, resourceId) =>
  optimizedResourceId && getResourceSize(optimizedResourceId) < getResourceSize(resourceId)
    ? optimizedResourceId
    : resourceId;
export const getBestSize = (optimizedResourceId, resourceId) =>
  optimizedResourceId && getResourceSize(optimizedResourceId)
    ? Math.ceil(Math.min(getResourceSize(optimizedResourceId), getResourceSize(resourceId)) / 1000) //in Ko
    : Math.ceil(getResourceSize(resourceId) / 1000); //in Ko

// Sagas

const readBlobAsBase64 = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', () => resolve(reader.result.split(',')[1]));
    reader.addEventListener('error', () => reject());
    reader.readAsDataURL(blob);
  });

function* addResourceSaga({ payload: { file, successAction, id } }) {
  const resourceId = id || uuidv4();
  resourceUrls[resourceId] = URL.createObjectURL(file);
  resourceSizes[resourceId] = file.size;
  resourcesSave[resourceId] = file;

  const base64 = yield readBlobAsBase64(file);
  yield put(addedResource(resourceId, base64));
  if (successAction && typeof successAction.payload === 'object') {
    yield put({ ...successAction, payload: { ...successAction.payload, resourceId } });
  }
}

function* cleanUnusedResourcesSaga() {
  yield call(backupBannerSaga);
  const usedResourceIds = yield select(getBannersUsedResources);
  const resourceIds = yield select(getResources);
  const unusedResourceIds = resourceIds.filter(id => usedResourceIds.includes(id));
  for (const id of unusedResourceIds) {
    yield put(deleteResource(id));
    cleanUrlsAndSizes(id);
  }
}

function* createOptimizedResourceSaga({ payload: { element, file } }) {
  try {
    if (file) {
      yield put(setElementOptimizedImageResource({ id: element.id }));
      console.log('passe1');
    } else {
      const dom = createDomImage(element);

      if (!dom) return;

      const newFile = yield* domtoimageExecutionContext(function* () {
        try {
          const pixelData = yield domtoimage.toPixelData(dom);
          const imageHasAlpha = yield hasAlpha(pixelData);

          let imageUrl;

          if (imageHasAlpha) {
            imageUrl = yield domtoimage.toPng(dom);
            console.log('passe2', imageUrl);
          } else {
            imageUrl = yield domtoimage.toJpeg(dom);
            console.log('passe3', imageUrl);
          }

          return yield objectUrlToBlob(imageUrl);
        } catch (e) {
          console.error("Image optimization - couldn't export", e);
        }
      });

      document.body.removeChild(dom);

      if (newFile && newFile.size < getResourceSize(element.resourceId)) {
        yield put(
          addResource(newFile, setElementOptimizedImageResource({ id: element.id }), element.optimizedResourceId),
        );
        console.log('optimized');
      } else {
        yield put(setElementOptimizedImageResource({ id: element.id })); // reset the optimized resource id if any
        console.log('not optimized');
      }
    }
  } catch (e) {
    console.error('createOptimizedResourceSaga', e);
  }
}

export function* saga() {
  yield takeEvery(RESOURCE_ADD_REQUESTED, addResourceSaga);
  yield takeLatest(RESOURCES_CLEAN_UNUSED_REQUESTED, cleanUnusedResourcesSaga);
  yield takeLatest(RESOURCE_CREATE_OPTIMIZED_REQUESTED, createOptimizedResourceSaga);
}

// Reducer

// Key: resource id ; Value : data url
export const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESOURCE_ADD_SUCCESS:
    case RESOURCE_UPDATE_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: action.payload.data,
      };
    }
    case RESOURCE_DELETE: {
      const newState = { ...state };
      cleanUrlsAndSizes(action.payload);
      delete newState[action.payload];
      return newState;
    }
    case BANNER_CLEAR_STATE:
    case BANNERS_CLOSE_ALL: {
      Object.keys(state).forEach(id => cleanUrlsAndSizes(id));
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
