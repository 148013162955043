import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { parseFloatHelper, blinkFrequencyInputProps, frequencyEndornment } from '../constants';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { updateElementBlink } from '../../banner/bannerActionsCreators';
import { getSelectedElementBlinkFrequency } from '../../banner/bannerSelectors';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';
import TextFieldDoped from '../../components/TextFieldDoped';

class ElementBlinkFrequency extends React.Component {
  onBlinkFrequencyChange = ({ value: frequency, id }) => this.props.updateElementBlink({ frequency, id }); //tracked

  render() {
    const { id, frequency, intl } = this.props;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        label={intl.formatMessage({ id: 'properties.animation.blink-frequency' })}
        value={frequency}
        fullWidth
        inputProps={blinkFrequencyInputProps}
        helper={parseFloatHelper}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={frequencyEndornment}
        onChangeMethod={this.onBlinkFrequencyChange}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  frequency: makeSelectorInstance(getSelectedElementBlinkFrequency)(state),
});

const dispatchToProps = {
  updateElementBlink,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
)(ElementBlinkFrequency);
