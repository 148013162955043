import React from 'react';
import { Button, withStyles } from '@material-ui/core';

const styles = theme => ({
  addButtonWrapper: {
    paddingLeft: 24,
  },
  addButton: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

const AddButton = ({ onClick, children, classes }) => (
  <div className={classes.addButtonWrapper}>
    <Button variant="contained" color="secondary" onClick={onClick} className={classes.addButton}>
      {children}
    </Button>
  </div>
);

export default withStyles(styles)(AddButton);
