import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ActionCreators } from 'redux-undo';
import keyboardShortcuts from '../reference/keyboardShortcuts';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import elementIcons from '../reference/elementIcons';
import { checkIfNoBanners } from '../shared-selectors/sharedSelectors';
import { bannerFutureExists, bannerPastExists } from '../banner/bannerSelectors';
import { redoBannersRequested, undoBannersRequested } from '../banners/bannersActionsCreators';
import { isCurrentBannerMasterBanner, isCurrentBannerSlaveBanner } from '../banners/bannersSelectors';
import MenuIconButton from './MenuIconButton';

class MenuActionButtons extends React.PureComponent {
  componentDidMount() {
    window.addEventListener('keydown', this.handleShortcuts);
    window.addEventListener('keyup', this.handleShortcuts);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleShortcuts);
    window.removeEventListener('keyup', this.handleShortcuts);
  }

  handleShortcuts = e => {
    /* undo - redo*/
    if (e.type === 'keydown') {
      if (keyboardShortcuts.command.includes(e.key)) {
        this.command = true;
      }
      if (keyboardShortcuts.shift.includes(e.key)) {
        this.shift = true;
      }
    }
    if (e.type === 'keyup') {
      if (keyboardShortcuts.command.includes(e.key)) {
        this.command = false;
      }
      if (keyboardShortcuts.shift.includes(e.key)) {
        this.shift = false;
      }
    }
    if (keyboardShortcuts.undo.includes(e.key) && this.command && !this.shift) {
      this.props.undoBanner()
    }
    if (keyboardShortcuts.undo.includes(e.key) && this.command && this.shift) {
      this.props.redoBanner()
    }
  };

  render() {
    const {
      intl,
      undoBanner,
      redoBanner,
      isPast,
      isFuture,
      noBanner,
      isBannerMaster,
      undoBannersRequested,
      redoBannersRequested,
      isBannerSlave,
    } = this.props;
    return (
      <div>
        <MenuIconButton
          iconComponent={elementIcons.undo}
          disabled={!isPast || noBanner || isBannerSlave}
          onClick={isBannerMaster ? undoBannersRequested : undoBanner}
          tooltip={intl.formatMessage({ id: 'app.menu.actionUndo' })}
          label={intl.formatMessage({ id: 'app.menu.actionUndo.label' })}
        />
        <MenuIconButton
          iconComponent={elementIcons.redo}
          disabled={!isFuture || noBanner || isBannerSlave}
          onClick={isBannerMaster ? redoBannersRequested : redoBanner}
          tooltip={intl.formatMessage({ id: 'app.menu.actionRedo' })}
          label={intl.formatMessage({ id: 'app.menu.actionRedo.label' })}
        />
      </div>
    );
  }
}

const makeStateToProps = () => state => ({
  isPast: makeSelectorInstance(bannerPastExists)(state),
  isFuture: makeSelectorInstance(bannerFutureExists)(state),
  noBanner: makeSelectorInstance(checkIfNoBanners)(state),
  isBannerMaster: makeSelectorInstance(isCurrentBannerMasterBanner)(state),
  isBannerSlave: makeSelectorInstance(isCurrentBannerSlaveBanner)(state),
});

const dispatchToProps = {
  undoBanner: ActionCreators.undo,
  redoBanner: ActionCreators.redo,
  redoBannersRequested,
  undoBannersRequested,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
)(MenuActionButtons);
