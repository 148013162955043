import * as bannersActions from './bannersActions';
import { openAlert } from '../alert/alertDucks';

export const setBannerSetName = (name, untrack = false) => ({
  type: bannersActions.BANNERSET_SET_NAME,
  payload: {
    name: name.toUpperCase(),
  },
  untrack,
});

export const setBannerSetExpirationDate = expirationDate => ({
  type: bannersActions.BANNERSET_SET_EXPIRATION_DATE,
  payload: { expirationDate },
});

export const loadBanner = banner => ({
  type: bannersActions.BANNERS_LOAD,
  payload: banner,
});

export const loadBannerSetFromUrlParams = () => ({
  type: bannersActions.BANNERSET_LOAD_FROM_URL_PARAMS_REQUESTED,
});

export const loadBannerSetSuccess = () => ({
  type: bannersActions.BANNERSET_LOAD_FROM_URL_PARAMS_SUCCESS,
});

export const loadBannerSetFailure = () => ({
  type: bannersActions.BANNERSET_LOAD_FROM_URL_PARAMS_FAILURE,
});

export const addBanner = format => ({
  type: bannersActions.BANNERS_ADD_REQUESTED,
  payload: {
    format,
  },
});

export const undoBanners = () => ({
  type: bannersActions.BANNERS_UNDO,
});

export const redoBanners = () => ({
  type: bannersActions.BANNERS_REDO,
});

export const undoBannersRequested = () => ({
  type: bannersActions.BANNERS_UNDO_REQUESTED,
});

export const redoBannersRequested = () => ({
  type: bannersActions.BANNERS_REDO_REQUESTED,
});

export const addBannerCopy = () => ({
  type: bannersActions.BANNERS_COPY_REQUESTED,
});

export const setBannerCopyFormat = format => ({
  type: bannersActions.BANNERS_COPY_FORMAT,
  payload: {
    format,
  },
});

export const deleteElementInBanner = (bannerIndex, elementId) => ({
  type: bannersActions.BANNERS_DELETE_ELEMENT_AFTER_CUT,
  payload: { bannerIndex, elementId },
});

export const closePrevBannerSetOpenImported = zipFile => ({
  type: bannersActions.BANNERSET_CLOSE_PREV_ADD_NEW_REQUESTED,
  payload: {
    zipFile,
  },
});

export const createNewBannerSet = ({ name }) => ({
  type: bannersActions.BANNERSET_CREATE_NEW_BANNERSET_REQUESTED,
  payload: { name },
});

export const openBannerSetFromNuxeo = nuxeoBannerSet => ({
  type: bannersActions.BANNERSET_FROM_NUXEO_REQUESTED,
  payload: {
    nuxeoBannerSet,
  },
});

export const openBannerSetFromNuxeoSuccess = () => ({
  type: bannersActions.BANNERSET_FROM_NUXEO_SUCCESS,
});

export const setBannerSetFPS = (framesPerSecond, untrack = false) => ({
  type: bannersActions.BANNERSET_SET_FPS,
  payload: {
    framesPerSecond,
  },
  untrack,
});

export const takeScreenshotForThumbnail = ({ thumbnailFor, bannerIndex = null, slideIndex = null }) => ({
  type: bannersActions.BANNERS_TAKE_SCREENSHOT_TO_THUMBNAIL_REQUESTED,
  payload: {
    thumbnailFor,
    bannerIndex,
    slideIndex,
  },
});

export const addDefaultScreenshotToResources = ({ thumbnailFor, bannerIndex = null, slideIndex = null }) => ({
  type: bannersActions.BANNERS_SET_DEFAULT_THUMBNAIL,
  payload: {
    thumbnailFor,
    bannerIndex,
    slideIndex,
  },
});

export const setBannerSetThumbnail = () => ({
  type: bannersActions.BANNERSET_SET_THUMBNAIL,
  payload: {},
});

export const setBannerSetInfos = bannerSetInfos => ({
  type: bannersActions.BANNERSET_SET_INFOS,
  payload: bannerSetInfos,
});

export const setBannerSetPermissions = permissions => ({
  type: bannersActions.BANNERSET_SET_PERMISSIONS,
  payload: permissions,
});

export const setBannerSetLock = (lock = '') => ({
  type: bannersActions.BANNERSET_SET_LOCK,
  payload: lock,
});

export const resetBannerSetInfos = () => ({
  type: bannersActions.BANNERSET_RESET_INFOS,
});

export const updateBanner = ({
  bannerIndex,
  banner,
  selectedElementId,
  selectedElementIds,
  selectedSlideIndex,
  isBannerDissociated,
}) => ({
  type: bannersActions.BANNERS_UPDATE,
  payload: { bannerIndex, banner, selectedElementId, selectedElementIds, selectedSlideIndex, isBannerDissociated },
});

export const closeBanner = (deletedIndex, numberOfBanners) => ({
  type: bannersActions.BANNERS_CLOSE,
  payload: {
    deletedIndex,
    numberOfBanners,
  },
});

const closeBannerRequested = index => ({
  type: bannersActions.BANNERS_CLOSE_REQUESTED,
  payload: index,
});

export const openDeleteBannerDialog = index =>
  openAlert({
    title: 'banners.delete.title',
    message: 'banners.delete.text',
    actionLabel: 'banners.delete.action',
    actionColor: 'red',
    action: closeBannerRequested(index),
  });

export const closeAllBanners = () => ({
  type: bannersActions.BANNERS_CLOSE_ALL,
});

export const selectBanner = index => ({
  type: bannersActions.BANNERS_SELECT_REQUESTED,
  payload: index,
});

export const selectedBanner = bannerIndex => ({
  type: bannersActions.BANNERS_SELECTED,
  payload: { bannerIndex },
});

export const makeBannerMasterRequested = makeMaster => ({
  type: bannersActions.BANNERS_SET_MASTER_REQUESTED,
  payload: makeMaster,
});

export const makeBannerMaster = masterBannerIndex => ({
  type: bannersActions.BANNERS_SET_MASTER,
  payload: masterBannerIndex,
});

export const duplicatSlideInBanners = ({ bannerIndex, slide, elements, nextId }) => ({
  type: bannersActions.BANNERS_SLIDE_DUPLICATE,
  payload: { bannerIndex, slide, elements, nextId },
});

export const duplicateSlideRequested = ({ slide, elements, bannerIndexes }) => ({
  type: bannersActions.SLIDE_DUPLICATE_REQUESTED,
  payload: { slide, elements, bannerIndexes },
});

export const duplicateElementRequested = ({
  operation = 'copy',
  id,
  slideIndex,
  oldSlideIndex,
  bannerIndex,
  oldBannerIndex,
  element = null,
  deleteOriginalElement = false,
}) => ({
  type: bannersActions.ELEMENT_DUPLICATE_REQUESTED,
  payload: {
    operation,
    id,
    slideIndex,
    bannerIndex,
    oldSlideIndex,
    oldBannerIndex,
    element,
    deleteOriginalElement,
  },
});
