import fetchApi from '../fetchApi';

// mediaUrl in config.json: 'https://media.inte.mylocalad.org/nuxeo/site/api/v1';
// const baseSearchUrl = '/search/pp/nxql_search/execute';
export const baseSearchUrl = '/search/lang/NXQL/execute';
export const addPermissionUrl = '/automation/Document.AddPermission';
export const removePermissionUrl = '/automation/Document.RemovePermission';
export const logoutNuxeo = '/logout?noredirect=true';
export const meNuxeo = '/api/v1/me';
// const queryInit = 'queryParams';
export const queryParam = 'query';

export const pageSize = 30;

const fetchNuxeo = async args => {
  const errorMessage = args.errorMessage || "can't fetch nuxeo";
  try {
    return await fetchApi({
      baseUrl: `${window.aragoConfig.api.media}/site/api/v1`,
      credentials: 'omit',
      errorMessage,
      ...args,
    });
  } catch (e) {
    throw errorMessage;
  }
};
export default fetchNuxeo;
