export const fieldIsNotACustomField = [
  'id',
  'active',
  'payer',
  'primaryColor',
  'secondaryColor',
  'subDomain',
  'areas',
  'tags',
  'parent',
  'taxNumber',
  'childrenTagCounts',
  'countryCode',
];

export const fieldIsACustomFieldForImage = ['logoUrl'];

export const fieldIsNotACustomFieldForText = [...fieldIsNotACustomField, ...fieldIsACustomFieldForImage];

export const fieldIsNotACustomFieldForImage = [
  ...fieldIsNotACustomField.filter(field => !fieldIsACustomFieldForImage.includes(field)),
];

export const customTest = {
  noTest: 'no',
  currentEntity: 'current-entity',
  selectEntity: 'select-entity',
  worstCaseScenario: 'worst-case-scenario',
};
