import autoHideDurations from '../../reference/messagesDurations';
import { minSlideDurationNotTransiting, slideTransitionsDetails } from '../../reference/transitions';

export const checkSlideTransitionChange = ({
  slides,
  slide,
  index,
  transition,
  noRepetition,
  newTransitionValue = 0,
  setSlideTransitionDuration,
  setSlideTransitionType,
  addError,
}) => {
  if (newTransitionValue === 0) {
    setSlideTransitionDuration({ index, duration: 0 });
    setSlideTransitionType(index, slideTransitionsDetails.none.name);
    return;
  }
  //so here it is complicated, so we go step by step

  // 1. we get all the infos we do regarding the prevSlide
  const prevSlide = (index !== 0 && slides.get(index - 1)) || (!noRepetition && slides.get(slides.size - 1));
  const isFirstSlide = !prevSlide;

  // if it is the first slide, there is no transition, so...
  if (isFirstSlide) return;
  // by the way, no duration for no transition
  if (transition.type === slideTransitionsDetails.none.name) {
    addError('slide.transition.none.error');
    setSlideTransitionDuration({ index, duration: 0 });
    return;
  }
  // 1.1 we need to know prevSlide available time for this transition
  const prevSlideAvailableTimeForTransition =
    prevSlide.duration -
    minSlideDurationNotTransiting -
    slideTransitionsDetails[prevSlide.transition.type].influence.currentSlide * prevSlide.transition.duration;

  //2. we do the same with the next slide
  const nextSlide = slides.get(index + 1) || (!noRepetition && slides.get(0));
  const isLastSlide = !nextSlide;

  // 2.1 we need to know nextSlide available time for this transition
  const nextSlideInfluenceOnCurrentSlide =
    !isLastSlide &&
    slideTransitionsDetails[nextSlide.transition.type].influence.prevSlide * nextSlide.transition.duration;

  //3. we do the same with this slide
  const durationInfluenceOnPrevSlide =
    slideTransitionsDetails[slide.transition.type].influence.prevSlide * newTransitionValue;

  const durationInfluenceOnItself =
    slideTransitionsDetails[slide.transition.type].influence.currentSlide * newTransitionValue;

  const slideAvailableTimeForTransition =
    slide.duration - minSlideDurationNotTransiting - nextSlideInfluenceOnCurrentSlide;
  //SO.. here we go for real

  /*if the new transition duration is bigger than what the prev slide can accept*/
  if (durationInfluenceOnPrevSlide > prevSlideAvailableTimeForTransition) {
    /*if what the prev slide can accept is even more than what the slide can accept...*/
    if (durationInfluenceOnItself !== 0 && prevSlideAvailableTimeForTransition > slideAvailableTimeForTransition) {
      addError('slide.transition.duration.error.currentSlide', {
        allowedDuration: slideAvailableTimeForTransition,
        autoHideDuration: autoHideDurations.veryLongMessage,
      });
      /*...we set the value to the minimum value the slide can accept...*/
      setSlideTransitionDuration({
        index,
        duration: slideAvailableTimeForTransition,
        untrack: true,
      });
      return;
    }
    addError('slide.transition.duration.error.prevSlide', {
      allowedDuration: prevSlideAvailableTimeForTransition,
      autoHideDuration: autoHideDurations.veryLongMessage,
    });
    setSlideTransitionDuration({
      index,
      duration: prevSlideAvailableTimeForTransition,
      untrack: true,
    });
    return;
  }
  if (durationInfluenceOnItself > slideAvailableTimeForTransition) {
    /*if the new transition duration is bigger than what the slide can accept*/
    addError('slide.transition.duration.error.currentSlide', {
      allowedDuration: slideAvailableTimeForTransition,
      autoHideDuration: autoHideDurations.veryLongMessage,
    });
    setSlideTransitionDuration({
      index,
      duration: slideAvailableTimeForTransition,
      untrack: true,
    });
    return;
  }
  /*if nobody is offensed by this new value*/
  setSlideTransitionDuration({ index, duration: newTransitionValue });
};
