import autoHideDurations from '../../reference/messagesDurations';
import { elementLabel } from '../../element/elementLabel';
import { checkIfIsMapAndConvertIfNeeded } from './';

export const verifyElementsTransitionInDelay = ({
  elements,
  setElementTransitionInDelay,
  addError,
  oldTransitionInDelays,
}) => {
  //if we have only one element, we create a Map from it, to make the function more simple
  const elementsToIterate = checkIfIsMapAndConvertIfNeeded(elements);

  const elementsChangingTheirDuration = [];

  elementsToIterate.toList().forEach((element, ind) => {
    const newElementPotentialDuration =
      element.transitionIn.delay + element.transitionIn.duration + element.transitionOut.duration;
    // compare new element potential duration with element current duration
    if (newElementPotentialDuration > element.duration) {
      elementsChangingTheirDuration.push(elementLabel(element));

      setElementTransitionInDelay({
        id: element.id,
        delay: oldTransitionInDelays[ind] || 0,
        untrack: true,
      });
    }
  });

  if (elementsChangingTheirDuration.length === 1) {
    addError('properties.transition.in.delay.error.isChangingElementDuration', {
      name: elementsChangingTheirDuration[0],
      autoHideDuration: autoHideDurations.veryLongMessage,
    });
  } else if (elementsChangingTheirDuration.length > 1) {
    addError('properties.transition.in.delay.error.areChangingElementDuration', {
      names: elementsChangingTheirDuration.join(', '),
      autoHideDuration: autoHideDurations.veryLongMessage,
    });
  }
};
