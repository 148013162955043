import React from 'react';
import { connect } from 'react-redux';
import { compose, onlyUpdateForKeys } from 'recompose';
import { withStyles } from '@material-ui/core';
import ElementSnapshotHidden from '../element/ElementSnapshotHidden';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { getBannerFormat } from '../shared-selectors/sharedSelectors';
import { getElementsOfSlide } from '../banner/bannerSelectors';

const styles = {
  scenePreview: {
    pointerEvents: 'none',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 100,
    '& * ': {
      pointerEvents: 'none',
    },
  },
};

class ScenePreview extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const {
      format: { bannerHeight, bannerWidth },
      backgroundColor,
      zoom,
    } = nextProps;
    return {
      minHeight: bannerHeight,
      minWidth: bannerWidth,
      backgroundColor,
      transform: `scale(${zoom})`,
    };
  }

  render() {
    const {
      format: { bannerHeight, bannerWidth },
      elements,
      classes,
    } = this.props;
    return (
      <div className={classes.scenePreview} style={this.state} ref={slide => (this.slide = slide)}>
        {elements.reverse().map(element => {
          return (
            <ElementSnapshotHidden
              {...element.toJS()}
              key={element.id}
              bannerHeight={bannerHeight}
              bannerWidth={bannerWidth}
              noAnimation
            />
          );
        })}
      </div>
    );
  }
}

const makeStateToProps = () => (state, { slide }) => ({
  elements: makeSelectorInstance(getElementsOfSlide(slide))(state),
  format: makeSelectorInstance(getBannerFormat)(state),
});

export default compose(
  connect(makeStateToProps),
  onlyUpdateForKeys(['slide', 'bannerUniqueId', 'elements', 'format']),
  withStyles(styles),
)(ScenePreview);
