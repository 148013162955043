import fetchDrbApi from './fetchDrbApi';

const fetchDrbApiUserTheme = () =>
  fetchDrbApi({
    path: '/themes/@mine',
    credentials: 'omit',
  })
    .then(res => res.json())
    .catch(err => console.error(err));

export default fetchDrbApiUserTheme;
