import fetchApi from '../fetchApi';

const baseUrl = 'https://html5animationtogif.com/api';
const uploadPath = '/uploadzip.ashx';
const convertPath = '/convert.ashx';
const statusPath = '/checkstatus.ashx';
const apiKey = '6793c533-8598-7ie5-nhd7-gh78j89864c3';
const clientId = 1033;

const errors = [
  'Please check your URL in the browser. Also it should gets loaded inside the iframe or it is just displaying only white portion in the screen.',
];

const fetchHtml5ToGif = ({ method = 'GET', path, body = null, params = {} }) =>
  fetchApi({
    baseUrl,
    method,
    path,
    body,
    params,
    withToken: false,
  });

export default fetchHtml5ToGif;
export { baseUrl, uploadPath, convertPath, statusPath, apiKey, clientId, errors };
