import { animationNames, transitionTypesVar, defineDistance } from '../../reference/transitions';

export const setElementAnimationProperties = (
  _,
  {
    isLastSlide,
    isFirstSlide,
    isLastSlideOfTour,
    isFirstSlideOfTour,
    slideDuration,
    slideDurationForAnimation,
    animationDelay,
    transitionIn,
    transitionOut,
    duration,
    bannerHeight,
    bannerWidth,
    showOnAllSlides,
    transitionsOnAllSlides,
  },
) => {
  //cross-fade stretch factor : if the slide undergoes a cross-fade transition, its duration increases
  //   => we have to apply this increase proportionnaly to the slide duration
  const f = slideDurationForAnimation / slideDuration;

  const noAnimationIn = !isFirstSlide && (showOnAllSlides && !transitionsOnAllSlides && !isFirstSlideOfTour);

  const noAnimationOut = !isLastSlide && (showOnAllSlides && !transitionsOnAllSlides && !isLastSlideOfTour);

  let animationOutName;
  if (isLastSlide && transitionOut.type === transitionTypesVar.none && slideDuration === duration) {
    animationOutName = transitionTypesVar.lastSlide;
  } else if (noAnimationOut) {
    animationOutName = transitionTypesVar.none;
  } else {
    animationOutName = `${animationNames(transitionOut)}-out`;
  }

  let animationInName;
  if (noAnimationIn) {
    animationInName = transitionTypesVar.none;
  } else {
    animationInName = `${animationNames(transitionIn)}-in`;
  }

  return {
    animation: {
      animationName: `${animationInName}, ${animationOutName}`,
      animationDelay: `${animationDelay + transitionIn.delay * f * !noAnimationIn}s, ${animationDelay +
        (duration - transitionOut.duration * !noAnimationOut) * f}s`,
      animationDuration: `${transitionIn.duration * f * !noAnimationIn}s, ${transitionOut.duration *
        f *
        !noAnimationOut}s`,
      animationIterationCount: '1, 1',
      animationTimingFunction: 'ease-in-out, ease-in-out',
      animationFillMode: 'both, forwards',
      animationDirection: 'normal, normal',
      '--marginTranslationIn': `${defineDistance(
        transitionIn,
        bannerHeight,
        bannerWidth,
        transitionIn.defaultDistance,
      )}px`,
      '--marginTranslationOut': `${defineDistance(
        transitionOut,
        bannerHeight,
        bannerWidth,
        transitionOut.defaultDistance,
      )}px`,
    },
  };
};
