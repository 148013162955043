import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, onlyUpdateForKeys } from 'recompose';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import {
  getBannerSlides,
  getSelectedElement,
  getSelectedSlide,
  getSelectedElementTransitionOutDuration,
} from '../../banner/bannerSelectors';
import {
  setElementDuration,
  setElementsTransitionOutType,
  setElementTransitionOutDuration,
  setElementsTransitionOutDuration,
  setSlidesDuration,
  setSlideDuration,
} from '../../banner/bannerActionsCreators';
import { durationInputProps, secondEndornment, parseFloatHelper } from '../constants';
import TextFieldDoped from '../../components/TextFieldDoped';
import { addWarning } from '../../messages/messagesDucks';
import { verifyElementsTransitionOutDuration } from '../helpers';
import { getSelectedSlideIndex, getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

class ElementTransitionOutDuration extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    const { slide, slides, selectedSlideIndex, element } = nextProps;
    return {
      durationAdditionalData: {
        slide,
        slides,
        selectedSlideIndex,
        elementSelected: element,
      },
    };
  }

  onTransitionDurationOutChange = ({ value: duration, id }) =>
    this.props.setElementTransitionOutDuration({ duration, id }); //tracked
  onTransitionDurationOutVerify = ({ additionalData: { slide, slides, selectedSlideIndex, elementSelected } }) => {
    const {
      setElementDuration,
      setElementsTransitionOutType,
      setElementsTransitionOutDuration,
      setSlideDuration,
      setSlidesDuration,
      addWarning,
    } = this.props;
    verifyElementsTransitionOutDuration({
      slide,
      slides,
      selectedSlideIndex,
      elements: elementSelected,
      setElementDuration,
      setElementsTransitionOutType,
      setElementsTransitionOutDuration,
      setSlideDuration,
      setSlidesDuration,
      addWarning,
    });
  };

  render() {
    const { id, transitionOutDuration, intl } = this.props;
    const { durationAdditionalData } = this.state;
    return (
      <TextFieldDoped
        key={id}
        id={id}
        type="number"
        label={intl.formatMessage({ id: 'properties.transition.duration' })}
        value={transitionOutDuration}
        fullWidth
        inputProps={durationInputProps}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={secondEndornment}
        helper={parseFloatHelper}
        additionalData={durationAdditionalData}
        onChangeMethod={this.onTransitionDurationOutChange}
        onVerifyMethod={this.onTransitionDurationOutVerify}
      />
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  transitionOutDuration: makeSelectorInstance(getSelectedElementTransitionOutDuration)(state),
  element: makeSelectorInstance(getSelectedElement)(state),
  slide: makeSelectorInstance(getSelectedSlide)(state),
  selectedSlideIndex: makeSelectorInstance(getSelectedSlideIndex)(state),
  slides: makeSelectorInstance(getBannerSlides)(state),
});

const dispatchToProps = {
  setElementDuration,
  addWarning,
  setSlideDuration,
  setSlidesDuration,
  setElementTransitionOutDuration,
  setElementsTransitionOutDuration,
  setElementsTransitionOutType,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  onlyUpdateForKeys(['id', 'transitionOutDuration']),
  injectIntl,
)(ElementTransitionOutDuration);
