import renderElement from './element';
import { applyStyle } from '../util/dom-helpers';
import { calculateSlideOwnDelayForTransitionOut } from '../../scene/helpers/calculateSlideOwnDelayForTransitionOut';
import { slideTransitionsDetails } from '../../reference/transitions';
import { getAnimationIn } from '../../scene/helpers/getAnimationIn';
import { getAnimationOut } from '../../scene/helpers/getAnimationOut';

const renderSlide = ({
  slide,
  bannerFormat,
  keyframes,
  elements,
  slideDelay,
  slideDuration,
  slideIndex,
  allSlides,
  isLastSlide,
  isFirstSlide,
  isLastSlideOfTour,
  isFirstSlideOfTour,
  backgroundColor,
}) => {
  const dom = document.createElement('div');
  dom.className = 'slide';
  // Set slide animation

  const nextSlide = isLastSlide ? null : allSlides[slideIndex + 1].slide;
  let animationOwnDelayForTransitionOut = isLastSlide ? 0 : calculateSlideOwnDelayForTransitionOut({ nextSlide });

  const animationIn = getAnimationIn(slide);
  const animationOut = getAnimationOut(isLastSlide, nextSlide);

  const animationDurationIn =
    slideTransitionsDetails[slide.transition.type].influence.currentSlide * slide.transition.duration;

  const animationDurationOut = nextSlide
    ? slideTransitionsDetails[nextSlide.transition.type].influence.prevSlide * nextSlide.transition.duration
    : 0;

  const animationDelayOut = slideDelay + (slideDuration || slide.duration) - animationOwnDelayForTransitionOut;

  applyStyle(dom, {
    animationName: animationIn + ', ' + animationOut,
    animationDuration: animationDurationIn + 's, ' + animationDurationOut + 's',
    animationDelay: slideDelay + 's, ' + animationDelayOut + 's',
    backgroundColor,
  });

  // Render elements
  const { elementIds } = slide;
  const reversedElementIds = [...elementIds];
  reversedElementIds.reverse();

  for (const elementId of reversedElementIds) {
    const element = elements[elementId];
    if (element && element.type) {
      dom.appendChild(
        renderElement({
          keyframes,
          element,
          slide,
          slideDelay,
          slideDuration,
          isLastSlide,
          isFirstSlide,
          isLastSlideOfTour,
          isFirstSlideOfTour,
          bannerFormat,
        }),
      );
    }
  }
  return dom;
};

export default renderSlide;
