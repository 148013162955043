import { generateDistanceTransitionsKeyFrame, defineDistance } from '../../reference/transitions';

export const applyStyle = (dom, stylesToApply) => {
  for (const key of Object.keys(stylesToApply)) {
    if (key.startsWith('--')) {
      dom.style.setProperty(key, stylesToApply[key]);
    } else {
      dom.style[key] = stylesToApply[key];
    }
  }
};

export const addTranslationKeyframe = (transition, bannerHeight, bannerWidth, keyframes) => {
  var style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = generateDistanceTransitionsKeyFrame(transition.direction, defineDistance(transition, bannerHeight, bannerWidth, transition.defaultDistance));

  if (!keyframes.includes(style.innerHTML)) {
    keyframes.push(style.innerHTML);
  }
  return style.innerHTML;
};

export const addBlinkKeyframe = (opacity, keyframes) => {
  var style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = `@keyframes blink-${opacity * 100} {0%{opacity:1}50%{opacity:${opacity}}100% {opacity:1}}`
  if (!keyframes.includes(style.innerHTML)) {
    keyframes.push(style.innerHTML);
  }
  return style.innerHTML;
};

export const addBlinkAnimation = ({ blinkAnimationEnabled, blinkAnimation }) => {
  if (blinkAnimationEnabled) {
    return {
      animationName: `blink-${blinkAnimation.minOpacity * 100}`,
      animationDelay: '0s',
      animationFillMode: 'none',
      animationIterationCount: 'infinite',
      animationDuration: `${1 / blinkAnimation.frequency}s`,
    }
  } return {}
}

export const addGoogleFontLink = fonts => {
  var link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  const formattedFonts = fonts.map(font => `${font}|${font}:i|${font}:bi|${font}:b`).join('|');
  link.href = encodeURI(`https://fonts.googleapis.com/css?family=${formattedFonts}`);
  return link;
};

export const addCustomization = (dom, property) => {
  if (property) dom.dataset.property = property;
}
