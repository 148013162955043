import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { DialogContent, Dialog, DialogTitle, Button, DialogActions } from '@material-ui/core';
import { FormattedMessage, FormattedHTMLMessage, FormattedNumber, injectIntl } from 'react-intl';
import Dropzone from 'react-dropzone';

import keyboardShortcuts from '../reference/keyboardShortcuts';
import {
  dropzoneBorderColor,
  acceptDropzoneBgColor,
  acceptDropzoneBorderColor,
  rejectDropzoneBgColor,
  rejectDropzoneBorderColor,
  errorColor,
} from '../reference/colors';
import {
  acceptedBannerFileTypes,
  acceptedBannerSetFileTypes,
  isBannerSet,
  maxBannerUploadSize,
} from '../reference/importParams';
import makeSelectorInstance from '../reference/makeSelectorInstance';
import { isImportBannerSetFromComputerOpen, checkIfNoBanners } from '../shared-selectors/sharedSelectors';
import { closePrevBannerSetOpenImported } from '../banners/bannersActionsCreators';
import { closeOpenBannerSetDialog, getOpenBannerSetDialogKey, getOpenBannerSetFile } from './openBannerSetDucks';

const debug = false;

const styles = {
  dialogContent: {
    padding: 16,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      padding: 16,
      boxSizing: 'border-box',
    },
    width: 600,
  },
  dropZone: {
    borderColor: dropzoneBorderColor,
    borderStyle: 'dashed',
    color: 'rgba(0, 0, 0, 0.75)',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    padding: 16,
    width: '100%',
    minHeight: 150,
    backgroundImage:
      'repeating-linear-gradient(-45deg, transparent, transparent 20px, rgba(0, 0, 0, 0.05) 20px, rgba(0, 0, 0, 0.05) 40px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  acceptDropZone: {
    borderStyle: 'solid',
    backgroundColor: acceptDropzoneBgColor,
    borderColor: acceptDropzoneBorderColor,
  },
  rejectDropZone: {
    borderStyle: 'solid',
    backgroundColor: rejectDropzoneBgColor,
    borderColor: rejectDropzoneBorderColor,
  },
  acceptedDropZone: {
    borderStyle: 'solid',
  },
  error: {
    color: errorColor,
    backgroundColor: 'rgba(255, 128, 128, 0.25)',
    borderRadius: 4,
    padding: 16,
  },
  prevBannerActionGroup: {
    alignItems: 'center',
    marginTop: 30,
    padding: '10px 16px 0',
    width: '100%',
  },
  radioGroup: {},
  radioButton: {
    padding: '0 12px',
  },
};

class ImportBannerSetFromComputer extends React.PureComponent {
  state = {
    file: null,
  };

  componentDidMount() {
    if (this.props.open && this.props.file !== null && this.props.file.name) {
      this.setState({ file: this.props.file });
    }
    if (this.props.open) {
      window.addEventListener('keydown', this.handleShortcuts);
      window.addEventListener('keyup', this.handleShortcuts);
    }
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleShortcuts);
    window.removeEventListener('keyup', this.handleShortcuts);
  }

  handleShortcuts = e => {
    const { file } = this.state;
    const { onCloseDialog } = this.props;

    if (keyboardShortcuts.cancel.includes(e.key)) {
      onCloseDialog();
    }
    if (keyboardShortcuts.enter.includes(e.key) && file) {
      this.handleOpenBannerSet();
    }
  };

  handleOpenBannerSet = () => {
    const { file } = this.state;

    const importingBannerSet = file && isBannerSet(file);

    const { closePrevBannerSetOpenImported, noBanners } = this.props;

    noBanners && closePrevBannerSetOpenImported(file);
    if (importingBannerSet && !noBanners) {
      closePrevBannerSetOpenImported(file);
    }
  };

  render() {
    const { classes, open, onCloseDialog, intl } = this.props;
    const { file } = this.state;

    return (
      <Dialog open={open} onClose={onCloseDialog}>
        <DialogTitle>
          <FormattedMessage id="app.menu.bannerImport" />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Dropzone
            accept={[...acceptedBannerFileTypes, ...acceptedBannerSetFileTypes]}
            maxSize={maxBannerUploadSize}
            multiple={false}
            disablePreview={true}
            className={classes.dropZone}
            activeClassName={classes.acceptDropZone}
            onDropAccepted={acceptedFiles => {
              debug && console.log('Dropped banner', acceptedFiles);
              this.setState({ file: acceptedFiles[0] });
            }}
            onDropRejected={rejectedFiles => {
              console.error('Rejected files', rejectedFiles);
            }}
          >
            {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
              if (isDragActive) {
                return <FormattedMessage id="banner.open.dropzone.accept" />;
              }
              if (rejectedFiles.length) {
                const rejectedFile = rejectedFiles[0];
                if (rejectedFile.size > maxBannerUploadSize) {
                  return (
                    <span className={classes.error}>
                      <FormattedMessage
                        id="banner.open.dropzone.error.size"
                        values={{
                          name: rejectedFile.name,
                          maxSize: <FormattedNumber value={maxBannerUploadSize / (1024 * 1024)} />,
                        }}
                      />
                    </span>
                  );
                }
                return (
                  <span className={classes.error}>
                    <FormattedMessage id="banner.open.dropzone.error.type" values={{ name: rejectedFile.name }} />
                  </span>
                );
              }
              if (acceptedFiles.length) {
                return <span>{acceptedFiles[0].name}</span>;
              } else if (file !== null) {
                return <span>{file.name}</span>;
              }
              return (
                <FormattedHTMLMessage
                  id="banner.open.dropzone.message"
                  values={{ maxSize: intl.formatNumber(maxBannerUploadSize / (1024 * 1024)) }}
                />
              );
            }}
          </Dropzone>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog}>{intl.formatMessage({ id: 'alert.cancel' })}</Button>
          <Button color="primary" disabled={!file} onClick={this.handleOpenBannerSet}>
            {intl.formatMessage({ id: 'alert.import' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const makeStateToProps = () => state => ({
  file: makeSelectorInstance(getOpenBannerSetFile)(state),
  open: makeSelectorInstance(isImportBannerSetFromComputerOpen)(state),
  key: makeSelectorInstance(getOpenBannerSetDialogKey)(state),
  noBanners: makeSelectorInstance(checkIfNoBanners)(state),
});

const dispatchToProps = {
  onCloseDialog: closeOpenBannerSetDialog,
  closePrevBannerSetOpenImported,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  injectIntl,
  withStyles(styles),
)(ImportBannerSetFromComputer);
