import { Set, List } from 'immutable';
import { createSelector } from 'reselect';
import {
  BannerAnimationStatusRecord,
  BannerStatusRecord,
  ElementStatusRecord,
  TemporaryStatusRecord,
} from '../reference/records';
import {
  BANNER_UPDATE_STATUS,
  BANNER_UPDATE_ANIMATION_STATUS,
  BANNER_NEW,
  BANNER_SELECT_ELEMENT,
  BANNER_ELEMENT_ADDED,
  ELEMENT_DELETE,
  BANNER_SELECT_MULTIPLE_ELEMENTS,
  ELEMENTS_DELETE,
  ELEMENT_UPDATE_STATUS,
  SLIDE_ADD,
  SLIDE_DUPLICATE,
  SLIDE_DELETE,
  SLIDE_SELECT,
  BANNER_CLEAR_STATE,
  BANNER_TRIGGER_UNDO_REDO,
  BANNER_SET_CURRENT_FRAME,
  BANNER_SHOW_ANIMATION_SNAPSHOT,
} from '../banner/bannerActions';
import { getBannerStatus, getBannerPresent, getPastBanner, getFutureBanner } from '../shared-selectors/sharedSelectors';
import { EXPORT_BANNERSETS_LOAD_PROGRESS_BAR, EXPORT_BANNERSETS_RENDER_SLIDES } from '../export/exportActions';
import { BANNERS_LOAD, BANNERS_SELECTED, BANNERS_CLOSE } from '../banners/bannersActions';

// Actions
const TEMPORARY_FORCE_SELECTION = 'adbuilder/TEMPORARY_FORCE_SELECTION';
// Actions creators

export const updateBannerStatus = ({
  isCropping = null, //no default property: we can crop and do something else together
  isDragging = false,
  isResizing = false,
  isRotating = false,
  isEyeDropping = false,
  textIsSelected = false,
  isDraggingFrameSelector = false,
  colorPickerIsOpen = false,
}) => {
  const payload = {
    isResizing,
    isDragging,
    isRotating,
    isEyeDropping,
    textIsSelected,
    isDraggingFrameSelector,
    colorPickerIsOpen,
  };
  if (isCropping !== null) {
    payload.isCropping = isCropping;
  }
  return {
    type: BANNER_UPDATE_STATUS,
    payload,
  };
};

export const updateBannerAnimationStatus = ({
  bannerIsPlayingHTMLAnimation = false,
  bannerIsPlayingGIFAnimation = false,
  currentFrame = 0,
  showAnimationSnapshot = false,
  slideIsPlayingAnimation = false,
}) => {
  return {
    type: BANNER_UPDATE_ANIMATION_STATUS,
    payload: {
      bannerIsPlayingHTMLAnimation,
      bannerIsPlayingGIFAnimation,
      showAnimationSnapshot,
      slideIsPlayingAnimation,
      currentFrame,
    },
  };
};

export const selectElement = id => ({
  type: BANNER_SELECT_ELEMENT,
  payload: { id },
});

export const unselectElement = () => ({
  type: BANNER_SELECT_ELEMENT,
  payload: { id: null },
});

export const selectMultipleElements = ids => ({
  type: BANNER_SELECT_MULTIPLE_ELEMENTS,
  payload: { ids: [...ids], remove: false },
});

export const unselectMultipleElements = ids => ({
  type: BANNER_SELECT_MULTIPLE_ELEMENTS,
  payload: { ids: [...ids], remove: true },
});

export const setBannerCurrentFrame = (frame, untrack = false) => ({
  type: BANNER_SET_CURRENT_FRAME,
  payload: frame,
  untrack,
});

export const toggleShowAnimationSnapshot = (checked, untrack = false) => ({
  type: BANNER_SHOW_ANIMATION_SNAPSHOT,
  payload: checked,
  untrack,
});

export const updateElementStatus = ({
  id,
  isCropping = null, //no default property: we can crop and do something else together
  isResizing = false,
  isDragging = false,
  isRotating = false,
  elementIsPlayingAnimation = false,
  textIsSelected = false,
  from = null,
}) => {
  const payload = {};
  payload.status = {
    isResizing,
    isDragging,
    isRotating,
    elementIsPlayingAnimation,
    textIsSelected,
  };
  if (isCropping !== null) {
    payload.status.isCropping = isCropping;
  }
  if (id) {
    payload.id = id;
  }
  return {
    type: ELEMENT_UPDATE_STATUS,
    payload,
  };
};

export const selectSlide = index => ({
  type: SLIDE_SELECT,
  payload: index,
});

export const forceSelection = selection => ({
  type: TEMPORARY_FORCE_SELECTION,
  payload: selection,
});

// Selectors

export const getAnimationStatus = createSelector(
  [state => state.temporaryStatus],
  temporaryStatus => temporaryStatus.animationStatus,
);

export const getBannerIsCropping = createSelector([getBannerStatus], status => status.isCropping);
export const getBannerIsDragging = createSelector([getBannerStatus], status => status.isDragging);
export const getBannerIsRotating = createSelector([getBannerStatus], status => status.isRotating);
export const getBannerIsEyeDropping = createSelector([getBannerStatus], status => status.isEyeDropping);
export const getBannerTextIsSelected = createSelector([getBannerStatus], status => status.textIsSelected);

export const getSlideIsPlayingAnimation = createSelector(
  [getAnimationStatus],
  animationStatus => animationStatus && animationStatus.slideIsPlayingAnimation,
);

export const getBannerShowAnimationSnapshot = createSelector(
  [getAnimationStatus],
  animationStatus => animationStatus && animationStatus.showAnimationSnapshot,
);
export const getBannerCurrentFrame = createSelector(
  [getAnimationStatus],
  animationStatus => animationStatus && animationStatus.currentFrame,
);
export const isBannerPlayingHTMLAnimation = createSelector(
  [getAnimationStatus],
  animationStatus => animationStatus && animationStatus.bannerIsPlayingHTMLAnimation,
);

export const isBannerPlayingGIFAnimation = createSelector(
  [getAnimationStatus],
  animationStatus => animationStatus && animationStatus.bannerIsPlayingGIFAnimation,
);

export const getSelectedSlideIndex = createSelector(
  [state => state.temporaryStatus],
  temporaryStatus => temporaryStatus.selectedSlideIndex,
);

export const getSelectedBannerIndex = createSelector(
  [state => state.temporaryStatus],
  temporaryStatus => temporaryStatus.selectedBannerIndex,
);

export const getSelectedElementId = createSelector(
  [state => state.temporaryStatus],
  ({ selectedElementId, selectedElementIds }) => {
    if (selectedElementId) return selectedElementId;
    if (!selectedElementIds) return null;
    if (selectedElementIds.size === 1) return selectedElementIds.first();
    return null;
  },
);

export const getSelectedElementIds = createSelector(
  [state => state.temporaryStatus],
  temporaryStatus => temporaryStatus.selectedElementIds,
);

export const getSelectedElementStatus = createSelector(
  [state => state.temporaryStatus],
  temporaryStatus => temporaryStatus.elementStatus,
);

export const getSelectedElementIsPlayingAnimation = createSelector(
  [getSelectedElementStatus],
  status => status.elementIsPlayingAnimation,
);

export const getSelectedElementCroppingStatus = createSelector([getSelectedElementStatus], status => status.isCropping);

export const getCurrentSelections = createSelector(
  [getSelectedElementIds, getSelectedSlideIndex, getSelectedElementId, getSelectedBannerIndex],
  (selectedElementIds, selectedSlideIndex, selectedElementId, selectedBannerIndex) => ({
    selectedElementIds,
    selectedSlideIndex,
    selectedElementId,
    selectedBannerIndex,
  }),
);

export const showTextAsBannerPlayingAnimation = createSelector(
  [
    getSlideIsPlayingAnimation,
    getBannerShowAnimationSnapshot,
    isBannerPlayingHTMLAnimation,
    isBannerPlayingGIFAnimation,
  ],
  (a, b, c, d) => a || b || c || d,
);

const ensureSelections = (banner, selectedElementIds, selectedSlideIndex, selectedElementId, selectedBannerIndex) => {
  let ensuredSelection = {
    selectedElementIds,
    selectedSlideIndex,
    selectedElementId,
    selectedBannerIndex,
  };

  if (banner) {
    // slide
    if (selectedSlideIndex > banner.slides.size - 1) {
      ensuredSelection.selectedSlideIndex = banner.slides.size - 1
    }
    // elements
    const elementIds = banner.slides.get(ensuredSelection.selectedSlideIndex).elementIds
    // check if the selected element exists
    if (!elementIds.includes(selectedElementId)) {
      ensuredSelection.selectedElementId = null
    }

    // filter the selected elements if they exists only
    for (let id of selectedElementIds) {
      if (!elementIds.includes(id)) {
        ensuredSelection.selectedElementIds = selectedElementIds.filter(elId => elId !== id)
      }
    }
  }

  return ensuredSelection;
};

export const ensureCurrentSelections = createSelector(
  [getBannerPresent, getSelectedElementIds, getSelectedSlideIndex, getSelectedElementId, getSelectedBannerIndex],
  ensureSelections,
);

export const ensurePastSelections = createSelector(
  [getPastBanner, getSelectedElementIds, getSelectedSlideIndex, getSelectedElementId, getSelectedBannerIndex],
  ensureSelections,
);

export const ensureFutureSelections = createSelector(
  [getFutureBanner, getSelectedElementIds, getSelectedSlideIndex, getSelectedElementId, getSelectedBannerIndex],
  ensureSelections,
);

export const getBannerSelections = bannerState => {
  if (!bannerState) return {};
  const banner = bannerState.present ? bannerState.present : bannerState;
  if (!banner.selectedSlideIndex && banner.selectedSlideIndex !== 0) return {};

  // check if the selected slide exists
  let selectedSlideIndex = banner.selectedSlideIndex || 0;
  if (banner.selectedSlideIndex > banner.slides.size - 1) {
    selectedSlideIndex = banner.slides.size - 1;
  }

  const elementIds = banner.elements && banner.elements.size > 0 ? banner.elements.map(el => el.id).toList() : List();
  // check if the selected element exists
  let selectedElementId = banner.selectedElementId || null;
  if (!elementIds.includes(selectedElementId)) {
    selectedElementId = null;
  }

  // filter the selected elements if they exists only
  let selectedElementIds = banner.selectedElementIds || Set();
  for (let id of selectedElementIds) {
    if (!elementIds.includes(id)) {
      selectedElementIds = selectedElementIds.filter(elId => elId !== id);
    }
  }

  return {
    selectedElementId,
    selectedElementIds,
    selectedSlideIndex,
  };
};

// Reducer
export const initialState = TemporaryStatusRecord({
  selectedElementId: null,
  selectedElementIds: Set(),
  selectedSlideIndex: 0,
  selectedBannerIndex: 0,
  bannerStatus: BannerStatusRecord({
    // default
    isCropping: false, //to prevent drag and drop zone to be activated
    isResizing: false, //to prevent drag and drop zone to be activated
    isDragging: false, //to prevent drag and drop zone to be activated
    isRotating: false,
    isEyeDropping: false, //to modify the canvas and the cursor etc.
    isDraggingFrameSelector: false, //to modify the canvas and the cursor etc.
    textIsSelected: false, //to modify the canvas and the cursor etc.
    colorPickerIsOpen: false, //to prevent keyboard shortcuts
  }),
  animationStatus: BannerAnimationStatusRecord({
    // default
    bannerIsPlayingHTMLAnimation: false,
    bannerIsPlayingGIFAnimation: false,
    currentFrame: 0,
    showAnimationSnapshot: false,
    slideIsPlayingAnimation: false,
  }),
  elementStatus: ElementStatusRecord({
    // default
    isRotating: false,
    isDragging: false,
    isResizing: false,
    isCropping: false,
    textIsSelected: false,
    elementIsPlayingAnimation: false,
  }),
});

const reducer = (state = initialState, action) => {
  const elementId =
    (action.payload && action.payload.id) ||
    state.selectedElementId ||
    (action.payload && action.payload.ids && action.payload.ids.length === 1 && action.payload.ids[0]) ||
    (state.selectedElementIds && state.selectedElementIds.size === 1 && state.selectedElementIds.first());

  const elementIds = (action.payload && action.payload.ids) || state.selectedElementIds;

  switch (action.type) {
    case BANNER_NEW:
    case BANNERS_LOAD: {
      return TemporaryStatusRecord({
        ...getBannerSelections(action.payload),
        selectedBannerIndex: state.selectedBannerIndex,
      });
    }
    case BANNER_SELECT_ELEMENT: {
      return state
        .set('elementStatus', ElementStatusRecord())
        .set('animationStatus', BannerAnimationStatusRecord())
        .set('bannerStatus', BannerStatusRecord())
        .update('selectedElementId', id => (id === elementId ? null : elementId));
    }
    case TEMPORARY_FORCE_SELECTION: {
      return state.merge(action.payload);
    }
    case BANNER_ELEMENT_ADDED: {
      let id = action.payload.element.id;
      return state.set('selectedElementId', id);
    }
    case BANNER_UPDATE_STATUS: {
      return state.update('bannerStatus', bannerStatus => bannerStatus.merge(action.payload));
    }
    case BANNER_UPDATE_ANIMATION_STATUS: {
      return state.update('animationStatus', animationStatus => animationStatus.merge(action.payload));
    }
    case ELEMENT_DELETE:
    case ELEMENTS_DELETE: {
      return state.set('selectedElementId', null).set('selectedElementIds', Set());
    }
    case BANNER_SELECT_MULTIPLE_ELEMENTS: {
      return state.withMutations(s =>
        s
          .set('elementStatus', ElementStatusRecord())
          .set('animationStatus', BannerAnimationStatusRecord())
          .set('bannerStatus', BannerStatusRecord())
          .set('selectedElementId', null)
          .update('selectedElementIds', elIds => {
            if (action.payload.remove) return elIds.filter(id => !elementIds.includes(id));
            return elIds.union([elementId, ...elementIds]);
          }),
      );
    }
    case ELEMENT_UPDATE_STATUS: {
      return state.update('elementStatus', elementStatus => elementStatus.merge(action.payload.status));
    }
    case SLIDE_ADD: {
      return state
        .set('selectedSlideIndex', action.payload)
        .set('selectedElementId', null)
        .set('selectedElementIds', Set());
    }
    case SLIDE_DUPLICATE: {
      return state
        .set(
          'selectedSlideIndex',
          Math.min(action.maxAllowedSelectedSlideIndex + 1, action.payload.nextId.get('slide') - 1),
        )
        .set('selectedElementId', null)
        .set('selectedElementIds', Set());
    }
    case SLIDE_DELETE: {
      const { deletedIndex, numberOfSlides } = action.payload;
      let newSlideIndex = state.selectedSlideIndex;
      if (state.selectedSlideIndex > deletedIndex) {
        newSlideIndex--;
      } else if (state.selectedSlideIndex > numberOfSlides - 2) {
        newSlideIndex = numberOfSlides - 2; // Last index after deletion
      }
      return state.set('selectedSlideIndex', newSlideIndex).set('selectedElementId', null);
    }
    case SLIDE_SELECT: {
      return state.set('selectedSlideIndex', action.payload).set('selectedElementId', null);
    }
    case EXPORT_BANNERSETS_LOAD_PROGRESS_BAR:
    case EXPORT_BANNERSETS_RENDER_SLIDES: {
      return state.update('animationStatus', status => status.merge({ showAnimationSnapshot: false }));
    }
    case BANNER_CLEAR_STATE: {
      return initialState;
    }
    case BANNERS_SELECTED: {
      return state.set('selectedBannerIndex', action.payload.bannerIndex);
    }
    case BANNERS_CLOSE: {
      const { deletedIndex, numberOfBanners } = action.payload;
      let newBannerIndex = state.selectedBannerIndex;
      if (newBannerIndex > deletedIndex) {
        newBannerIndex--;
      } else if (newBannerIndex > numberOfBanners - 2) {
        newBannerIndex = numberOfBanners - 2; // Last index after deletion
      }
      return state.set('selectedBannerIndex', newBannerIndex);
    }
    case BANNER_TRIGGER_UNDO_REDO:
    default: {
      return state;
    }
  }
};

export default reducer;
