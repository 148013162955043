import banner from './banner/bannerUndoRedoDucks';
import { initialState as bannerInit } from './banner/bannerDucks';
import banners from './banners/bannersUndoRedoDucks';
import { initialState as bannersInit } from './banners/bannersDucks';
import bannerFormatSelectorDialog, {
  initialState as bannerFormatSelectorDialogInit,
} from './banner-format-selector/bannerFormatSelectorDucks';
import i18n, { initialState as i18nInit } from './i18n/i18nDucks';
import newImage, { initialState as newImageInit } from './new-image/newImageDucks';
import layout, { initialState as layoutInit } from './layout/layoutDucks';
import fonts, { initialState as fontsInit } from './fonts/fontsDucks';
import messages, { initialState as messagesInit } from './messages/messagesDucks';
import needToSaveState, { initialState as needToSaveStateInit } from './need-to-save-state/needToSaveDucks';
import openBannerSet, { initialState as openBannerSetInit } from './open-banner-set/openBannerSetDucks';
import saveBanners, { initialState as saveBannersInit } from './save-banners-dialog/saveBannersDucks';
import exportToImages, { initialState as exportToImagesInit } from './export/exportDucks';
import resources, { initialState as resourcesInit } from './resources/resourcesDucks';
import alert, { initialState as alertInit } from './alert/alertDucks';
import security, { initialState as securityInit } from './security/securityDucks';
import config, { initialState as configInit } from './config/configDucks';
import temporaryTextEditor, {
  initialState as temporaryTextEditorInit,
} from './temporary-text-editor/temporaryTextEditorDucks';
import masterBannerDialog, {
  initialState as masterBannerDialogInit,
} from './master-banner-dialog/masterBannerDialogDucks';
import entities, { initialState as entitiesInit } from './entities/entitiesDucks';
import temporaryStatus, { initialState as temporaryStatusInit } from './temporary-status/temporaryStatusDucks';

export const initialState = {
  banner: {
    past: [],
    future: [],
    present: bannerInit,
  },
  banners: {
    past: [],
    future: [],
    present: bannersInit,
  },
  bannerFormatSelectorDialog: bannerFormatSelectorDialogInit,
  i18n: i18nInit,
  newImage: newImageInit,
  layout: layoutInit,
  fonts: fontsInit,
  messages: messagesInit,
  needToSaveState: needToSaveStateInit,
  openBannerSet: openBannerSetInit,
  saveBanners: saveBannersInit,
  exportToImages: exportToImagesInit,
  resources: resourcesInit,
  alert: alertInit,
  security: securityInit,
  config: configInit,
  temporaryTextEditor: temporaryTextEditorInit,
  masterBannerDialog: masterBannerDialogInit,
  entities: entitiesInit,
  temporaryStatus: temporaryStatusInit,
};

export default {
  /* banner reducer #UNDO-REDO-REDUCER */
  banner,
  /* all banners #UNDO-REDO-REDUCER */
  banners,
  /* tracking the need to save the bannerset */
  needToSaveState,
  /* for changing banner format, duplicating banner, or creating banner-set */
  bannerFormatSelectorDialog,
  /* new image from 123rf or from nuxeo or from computer */
  newImage,
  /* slide panel or not, element properties or not */
  layout,
  /* available fonts */
  fonts,
  /* messages snackbar (error, warning, success) */
  messages,
  /* open from library or from computer */
  openBannerSet,
  /* check if a bannerset needs to be saved, */
  /* alert when closing a bannerset if we want to save it or not */
  saveBanners,
  /* export to html, gif, png, jpg, zip */
  exportToImages,
  /* uploaded resources (images, fonts) */
  resources,
  /* custom alert (delete elements, slides, etc.) */
  alert,
  /* user and tokens */
  security,
  /* all reltaed to entities: save-as, share and move */
  entities,
  /* all drb urls, client-id and realm */
  config,
  i18n,
  /* this reducer is here to control when a banner can or can't be master, when a banner can or must be dissociated. */
  masterBannerDialog,
  /*
  because draft-je save everything happening within an editor, we can't save the Editor, but we save the Content.
  Meanwhile, to share the Editor between components (FontSize, FontStyle, TextElement, etc.), we create this tmeporary reducer */
  temporaryTextEditor,
  /*
  The principle of master banner implies an undo-redo on banners reducer.
  In order not to mess-up with selected banner / selected slide / selected element,
  these values need to be taken away from banners and banner reducer and treated seperately.
  more-over, the banners and banner reducers are permanent data and should not been mixed with temporary data
   */
  temporaryStatus,
};
