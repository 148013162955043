import { corners } from '../../../reference/corners';

export const calculateInitRotationOfHandleInRad = ({
  width,
  height,
  borderWidth,
  handleClicked,
  rootCenterCoords,
  elementRoot,
}) => {
  /*the rotation is calculated from the horizontal center axis of the div at rotation = 0.*/
  /*Get the constant angle between the handle with 0 rotation and the horizontal center axis*/

  let handleClickedCoords;

  switch (handleClicked) {
    case corners.topLeft: {
      handleClickedCoords = {
        X: elementRoot.left,
        Y: elementRoot.top,
      };
      break;
    }
    case corners.topRight: {
      handleClickedCoords = {
        X: elementRoot.left + width,
        Y: elementRoot.top,
      };
      break;
    }
    case corners.bottomLeft: {
      handleClickedCoords = {
        X: elementRoot.left,
        Y: elementRoot.top + height,
      };

      break;
    }
    case corners.bottomRight: {
      handleClickedCoords = {
        X: elementRoot.left + width,
        Y: elementRoot.top + height,
      };
      break;
    }
    case corners.middleTop: {
      handleClickedCoords = {
        X: elementRoot.left + width / 2,
        Y: elementRoot.top,
      };
      break;
    }
    case corners.middleRight: {
      handleClickedCoords = {
        X: elementRoot.left + width,
        Y: elementRoot.top + height / 2,
      };
      break;
    }
    case corners.middleBottom: {
      handleClickedCoords = {
        X: elementRoot.left + width / 2,
        Y: elementRoot.top + height,
      };
      break;
    }
    case corners.middleLeft: {
      handleClickedCoords = {
        X: elementRoot.left,
        Y: elementRoot.top + height / 2,
      };
      break;
    }
    default: {
      handleClickedCoords = {
        X: 0,
        Y: 0,
      };
      break;
    }
  }

  const relativeX = handleClickedCoords.X - rootCenterCoords.X;
  const relativeY = -handleClickedCoords.Y + rootCenterCoords.Y;

  const diagCenterTopLeft = Math.sqrt(Math.pow(relativeX, 2) + Math.pow(relativeY, 2));

  const halfBoxWidth = width / 2 - (borderWidth ? borderWidth : 0);

  let initRotationOfHandleInRad;

  switch (handleClicked) {
    case corners.topLeft: {
      initRotationOfHandleInRad = Math.PI - Math.acos(halfBoxWidth / diagCenterTopLeft);
      break;
    }
    case corners.topRight: {
      initRotationOfHandleInRad = Math.acos(halfBoxWidth / diagCenterTopLeft);
      break;
    }
    case corners.bottomLeft: {
      initRotationOfHandleInRad = Math.PI + Math.acos(halfBoxWidth / diagCenterTopLeft);
      break;
    }
    case corners.bottomRight: {
      initRotationOfHandleInRad = 2 * Math.PI - Math.acos(halfBoxWidth / diagCenterTopLeft);
      break;
    }
    case corners.middleTop: {
      initRotationOfHandleInRad = Math.PI / 2;
      break;
    }
    case corners.middleBottom: {
      initRotationOfHandleInRad = (3 * Math.PI) / 2;
      break;
    }
    case corners.middleLeft: {
      initRotationOfHandleInRad = Math.PI;
      break;
    }
    case corners.middleRight:
    default: {
      initRotationOfHandleInRad = 0;
      break;
    }
  }

  return Math.round(initRotationOfHandleInRad * 10000) / 10000;
};
