import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withStyles, FormControl, InputLabel } from '@material-ui/core';
import makeSelectorInstance from '../../reference/makeSelectorInstance';
import { styles } from '../propertyEditorStyles';
import { getSelectedElementBackgroundColor } from '../../banner/bannerSelectors';
import { updateElement } from '../../banner/bannerActionsCreators';
import RgbaColorPicker from '../../components/ColorPicker/RgbaColorPicker';
import { getSelectedElementId } from '../../temporary-status/temporaryStatusDucks';

class ElementBackgroundColor extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    return { shrink: nextProps.classes.labelShrink };
  }

  onBackgroundColorChange = value => {
    const { id, updateElement, backgroundColor } = this.props;
    if (backgroundColor === value) return;
    updateElement({ id, backgroundColor: value });
  };

  render() {
    const { id, backgroundColor } = this.props;

    return (
      <FormControl fullWidth>
        <InputLabel shrink classes={this.state}>
          <FormattedMessage id="properties.rectangle.background.color" />
        </InputLabel>
        <RgbaColorPicker
          key={id}
          enableAlpha
          value={backgroundColor}
          initialColor={backgroundColor}
          onChange={this.onBackgroundColorChange}
        />
      </FormControl>
    );
  }
}

const makeStateToProps = () => state => ({
  id: makeSelectorInstance(getSelectedElementId)(state),
  backgroundColor: makeSelectorInstance(getSelectedElementBackgroundColor)(state),
});

const dispatchToProps = {
  updateElement,
};

export default compose(
  connect(
    makeStateToProps,
    dispatchToProps,
  ),
  withStyles(styles),
)(ElementBackgroundColor);
