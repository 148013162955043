export const calculateAbsoluteRotatingAngleInDeg = ({ e, rootCenterCoords, initRotationOfHandleInRad }) => {
  const relativeX = e.clientX - rootCenterCoords.X;
  const relativeY = rootCenterCoords.Y - e.clientY;

  const brutAngle = Math.atan(relativeY / relativeX);

  let adjustedAngle;

  if (relativeX >= 0) {
    if (relativeY >= 0) {
      adjustedAngle = brutAngle;
    } else {
      adjustedAngle = brutAngle + 2 * Math.PI;
    }
  } else {
    if (relativeY >= 0) {
      adjustedAngle = brutAngle + Math.PI;
    } else {
      adjustedAngle = brutAngle + Math.PI;
    }
  }

  const absoluteAngle = (adjustedAngle - initRotationOfHandleInRad + 2 * Math.PI) % (2 * Math.PI);

  /*rotation CSS is clockwise in degrees, whereas trigonometry is counter-clockwise in radians*/
  return -((absoluteAngle * 180) / Math.PI);
};
